<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row d-flex mx-0 px-0">
            <div class="mb-2 col-xl-9 col-md-12 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" id="name" v-model="data_detail.name" placeholder=" Nombre">
            </div>
            <div class="mb-2 col-xl-3 col-md-6 col-md-6 px-1">
                <label for="ruc" class="form-label">RUC</label>
                <input type="text" class="form-control form-control-sm" id="ruc" v-model="data_detail.ruc" placeholder="RUC" @keydown="filterKey" maxlength="11">

            </div>
            <div class="mb-2 col-xl-6 col-md-6 px-1">
                <label for="address" class="form-label">Dirección</label>
                <input type="text" class="form-control form-control-sm" id="address" v-model="data_detail.address" placeholder="Dirección">
            </div>
            <template v-if="page === 'Logistica'">
                <div class="mb-2 col-xl-6 col-md-12 px-1">
                    <label for="vendor" class="form-label">Representante</label>
                    <input type="text" class="form-control form-control-sm" id="vendor" v-model="data_detail.vendor" placeholder=" Representante">
                </div>
                <div class="mb-2 col-xl-6 col-md-9 px-1">
                    <label for="correo" class="form-label">Correo</label>
                    <input type="text" class="form-control form-control-sm" id="correo" v-model="data_detail.email" placeholder="Correo">

                </div>
                <div class="mb-2 col-xl-6 col-md-3 px-1">
                    <label for="telefono" class="form-label">Telefono</label>
                    <input type="text" class="form-control form-control-sm" id="telefono" v-model="data_detail.phone" placeholder="98765432" maxlength="9">
                </div>
                <div class="mb-2 col-xl-6 col-md-8 px-1">
                    <div class="row d-flex">
                        <div class="col-12" id="content-timelinetable">
                            <label class="form-label">Cuentas Bancarias</label>
                            <table class="w-100  table-border">
                                <caption class="div-text"></caption>
                                <thead class="div-text div-title text-center">
                                    <tr>
                                        <th class=" color-main">N°</th>
                                        <th class=" color-main"># CUENTA</th>

                                        <th class="color-main">CCI</th>
                                        <th class="color-main">TIPO</th>
                                        <th class="color-main">ACCION</th>
                                    </tr>
                                </thead>
                                <tbody class="div-text">
                                    <tr class="px-1 text-center">
                                        <td class=" ">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control form-control-input form-control-sm" v-model="cuentaUnit.number" />
                                        </td>
                                        <td>
                                            <input type="text" class="form-control form-control-input form-control-sm" v-model="cuentaUnit.account_number" />
                                        </td>
                                        <td>
                                            <select name="tipo" id="tipo" class="form-control form-control-input form-control-sm" v-model="cuentaUnit.type">
                                                <option selected disabled value="">Seleccione</option>
                                                <option value="soles">Soles</option>
                                                <option value="soles">Dolares</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button type="button" :disabled="validationInput" @click="mtdAddItem()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                                        </td>
                                    </tr>
                                    <tr class="px-1 pt-2 text-center border-bottom border-top border-secondary" v-for="(item, index) in data_detail.account_number">
                                        <td>{{ index + 1  }}
                                        </td>
                                        <td>
                                            {{item.number}}
                                        </td>
                                        <td>
                                            {{item.account_number}}
                                        </td>
                                        <td>{{item.type}}
                                        </td>
                                        <td>
                                            <button type="button" @click="mtddelete(index, 'mtddeleteItem')" class=" btn btn-danger btn-sm px-1 py-0"> <i class="fas fa-trash-alt text-white"></i> </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
            <div class="col-xl-4 col-md-4 col-sm-6 mt-3 pt-1" v-if="num === 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false" aria-checked="false" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdRegistrar" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleteItem="mtddeleteItem" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: "",
            data_detail: {
                name: '',
                ruc: '',
                address: '',
                vendor: '',
                phone: '',
                email: "",
                state: '',
                account_number: [],
            },
            cuentaUnit: {
                id: "",
                account_number: "",
                number: "",
                type: ""
            },
            number: "",
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                        
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        page: String,
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name != '' && this.data_detail.ruc && this.data_detail.ruc.length == 11);
        },
        validationInput: function () {
            let value = true
            if (this.cuentaUnit.account_number && this.cuentaUnit.number && this.cuentaUnit.type) {
                value = false
            }
            return value
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                name: '',
                ruc: '',
                address: '',
                vendor: '',
                phone: '',
                email: "",
                state: '',
                account_number: [],
            };
            this.cuentaUnit = {
                id: "",
                account_number: "",
                number: "",
                type: ""
            };
            this.$emit("closeModal");
        },
        mtdRegistrar() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdAddItem: function () {
            this.cuentaUnit.id = this.data_detail.account_number.length +1;
            
            this.data_detail.account_number.push(this.cuentaUnit);
            this.cuentaUnit = {
                id: "",
                account_number: "",
                number: "",
                type: ""
            };
        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
        },
        mtddeleteItem: function (index) {
            this.data_detail.account_number.splice(index, 1);
            for (let i = 0; i < this.data_detail.account_number.length; i++) {
                this.data_detail.account_number[i].order = i + 1;
            }
        },
    },

};
</script>
