<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center" v-if="this.type == 'garantia'">
            <div class="mb-3 row d-flex">
                <label class="col-sm-2 col-lg-2 col-form-label">Garantias</label>
                <div class="col-sm-10 col-lg-10">
                    <input type="text" readonly class="form-control" :value="cpGarantias" />
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <div class="mb-3 row d-flex">
                <label class="col-lg-2 col-md-2 col-sm-2 col-form-label">Estado</label>
                <div class="col-lg-10 col-md-10 col-sm-10">
                    <input type="text" readonly class="form-control" :value="cpSaldo" />
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 text-center container" v-if="pending > 0">
            <div class="row d-flex justify-content-around">
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <button id="efectivo" :class="
                bottonActiveM == 'efectivo'
                  ? 'myButton active-btn w-100'
                  : 'myButton w-100'
              " @click="activarMedio('efectivo')">
                        <div :class="
                  bottonActiveM == 'efectivo'
                    ? 'myButton-icon active-ico'
                    : 'myButton-icon'
                ">
                            <i class="far fa-money-bill-alt fa-2x mt-3"></i>
                        </div>
                        <div>Efectivo</div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <button id="tarjeta" :class="
                bottonActiveM == 'tarjeta'
                  ? 'myButton active-btn w-100'
                  : 'myButton w-100'
              " @click="activarMedio('tarjeta')">
                        <div :class="
                  bottonActiveM == 'tarjeta'
                    ? 'myButton-icon active-ico'
                    : 'myButton-icon'
                ">
                            <i class="fas fa-credit-card fa-2x mt-3"></i>
                        </div>
                        <div>Tarjeta</div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <button id="deposito" :class="
                bottonActiveM == 'deposito'
                  ? 'myButton active-btn w-100'
                  : 'myButton w-100'
              " @click="activarMedio('deposito')">
                        <div :class="
                  bottonActiveM == 'deposito'
                    ? 'myButton-icon active-ico'
                    : 'myButton-icon'
                ">
                            <i class="fas fa-money-check-alt fa-2x mt-3"></i>
                        </div>
                        <div>Deposito</div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 mt-1">
                    <button id="transferencia" :class="
                bottonActiveM == 'transferencia'
                  ? 'myButton active-btn w-100'
                  : 'myButton w-100'
              " @click="activarMedio('transferencia')">
                        <div :class="
                  bottonActiveM == 'transferencia'
                    ? 'myButton-icon active-ico'
                    : 'myButton-icon'
                ">
                            <i class="fas fa-exchange-alt fa-2x mt-3"></i>
                        </div>
                        <div>Transferencia</div>
                    </button>
                </div>
                <!-- <div class="col-md-2">
            <button
              id="planilla"
              :class="
                bottonActiveM == 'planilla'
                  ? 'myButton active-btn w-100'
                  : 'myButton w-100'
              "
              @click="activarMedio('planilla')"
            >
              <div
                :class="
                  bottonActiveM == 'planilla'
                    ? 'myButton-icon active-ico'
                    : 'myButton-icon'
                "
              >
                <i class="fas fa-user-minus fa-2x mt-3"></i>
              </div>
              <div>Desc. planilla</div>
            </button>
          </div> -->
                <div class="col-lg-2 col-md-4 col-sm-6 mt-1">
                    <button id="mixto" :class="
                bottonActiveM == 'mixto'
                  ? 'myButton active-btn w-100'
                  : 'myButton w-100'
              " @click="activarMedio('mixto')">
                        <div :class="
                  bottonActiveM == 'mixto'
                    ? 'myButton-icon active-ico'
                    : 'myButton-icon'
                ">
                            <i class="fas fa-wallet fa-2x mt-3"></i>
                        </div>
                        <div>Mixto</div>
                    </button>
                </div>
            </div>
            <!-- Activar los inputs para mixto -->
            <div class="col-md-12 col-lg-12 col-xs-12 mt-3" v-if="bottonActiveM == 'mixto'">
                <div class="row mt-4 px-5 d-flex justify-content-around">
                    <div :class="[bottonActiveM == 'efectivo' ? 'col-md-4' : 'col-md-3']">
                        <div class="mb-3 me-1">
                            <label for="efectivo" class="form-label border-custom-text-2 fw-bold">EFECTIVO</label>
                            <input style="text-align: center" class="form-control input-1 fw-bold" type="text" v-on:keypress="isNumber($event)" @input="mtdCalculate" v-model="amount.efectivo" placeholder="0" aria-label="default input example" />
                        </div>
                    </div>
                    <div :class="[bottonActiveM == 'tarjeta' ? 'col-md-4' : 'col-md-3']">
                        <!-----------TARJETA----------------------->
                        <div class="mb-3">
                            <label for="tarjeta" class="form-label border-custom-text-2 fw-bold">TARJETA</label>
                            <input style="text-align: center" class="form-control input-1 fw-bold" type="text" v-on:keypress="isNumber($event)" @input="mtdCalculate" v-model="amount.tarjeta" aria-label="default input example" placeholder="0" />
                        </div>
                    </div>
                    <div :class="[bottonActiveM == 'deposito' ? 'col-md-4' : 'col-md-3']">
                        <div class="mb-3">
                            <label for="deposito" class="form-label border-custom-text-2 fw-bold">DEPÓSITO</label>
                            <input style="text-align: center" class="form-control input-1 fw-bold" type="text" v-on:keypress="isNumber($event)" @input="mtdCalculate" v-model="amount.deposito" aria-label="default input example" placeholder="0" />
                        </div>
                    </div>
                    <div :class="[bottonActiveM == 'transferencia' ? 'col-md-4' : 'col-md-3']">
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label border-custom-text-2 fw-bold">TRANSFERENCIA</label>
                            <input style="text-align: center" class="form-control input-1 fw-bold" type="text" @input="mtdCalculate" v-model="amount.transferencia" v-on:keypress="isNumber($event)" aria-label="default input example" placeholder="0" />
                        </div>
                    </div>
                    <!-- <div :class="[bottonActiveM !== 'mixto' ? 'col-md-4' : 'col-md-3']">
                    <div class="mb-3">
                        <label for="input_total" class="form-label border-custom-text-2 fw-bold">TOTAL</label>
                        <input style="text-align: center" class="form-control fw-bold input_total" readonly type="text" v-model="totalPay" aria-label="default input example" />
                    </div>
                </div> -->

                </div>
                <div class="row text-center">
                    <span class="text-danger text-alert-modal" v-if="procede == false">El monto registrado no es igual al monto pendiente</span>
                </div>
            </div>
            <!-- TIPO DE DOCUMENTO -->
            <div class="row mt-5 mb-3 d-flex justify-content-around">
                <!-- <div class="d-sm-flex d-flex justify-content-around"> -->
                <div class="col-md-2"></div>
                <div class="col-md-4" v-for="(comprobante, index) in dataDocumentType" :key="index">
                    <button v-if="comprobante.name != 'TICKET'" v-bind:class="computedClass(comprobante.name)" v-on:click="activarTipo(comprobante.name)">
                        <div v-bind:class="activeClass(comprobante.name)">
                            <i class="far fa-file-invoice-dollar fa-2x mt-3"></i>
                        </div>
                        <div>{{ comprobante.name }}</div>
                    </button>
                </div>
                <!-- </div> -->
            </div>
            <div class="row d-flex px-4">
                <div class="input-group input-group-sm mb-3 b">
                    <div class="input-group-text">
                        <input :disabled="disabledChkDoc" class="form-check-input mt-0" @change="mtdSelectDoc" type="checkbox" v-model="chkDoc" aria-label="Checkbox for following text input" />&nbsp;{{
                chkDoc == true ? "A nombre del paciente " : "A nombre de: "
              }}
                    </div>
                    <input v-if="bottonActiveT == 'BOLETA'" type="text" class="form-control" v-on:keypress="isNumber($event)" @keyup="mtdSearchDocument" :maxlength="bottonActiveT == 'BOLETA' ? 8 : 11" id="txtDoc" v-model="documentoActual" :disabled="chkDoc" placeholder="Documento de paciente" />
                    <input v-if="bottonActiveT == 'FACTURA'" type="text" class="form-control" v-on:keypress="isNumber($event)" @keyup="mtdSearchDocument" :maxlength="bottonActiveT == 'BOLETA' ? 8 : 11" id="txtDoc" v-model="chkPatiente.document" :disabled="chkDoc" placeholder="Documento del paciente" />
                    <input type="text" aria-label="Last name" id="txtDoc2" placeholder="Nombre de paciente" disabled class="form-control" v-model="nombreActual" />
                </div>
                <div class="input-group input-group-sm mb-3 b" v-if="bottonActiveT == 'FACTURA'">
                    <div class="input-group-text">Dirección</div>
                    <input type="text" class="form-control" id="txtDoc" v-model="chkPatiente.address" :disabled="true" placeholder="Documento de paciente" />
                </div>
            </div>
        </div>
        <!-- <div class="col-md-12 col-lg-12 col-xs-12 text-center" v-if="pending < 0">
        <div class="mb-3 row">
          <label class="col-lg-2 col-md-2 col-sm-2 col-form-label"
            >Motivo</label
          >
          <div class="col-lg-10 col-md-10 col-sm-10">
            <input
              type="text"
              class="form-control"
              placeholder="Motivo o sustento"
              :v-model="sustento"
            />
          </div>
        </div>
      </div> -->
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mdtCanjear" :disabled="bottonActiveT !== null && bottonActiveM !== null && procede == false">
            <i class="fas fa-save" />&nbsp; Canjear
        </button>
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            bottonActiveM: null,
            bottonActiveT: null,
            sustento: null,
            dataDocumentType: [],
            filter: {
                filter_area_name: "ADMISION",
            },
            disabledChkDoc: true,
            chkDoc: false,
            chkPatiente: {
                document: "",
                fullName: "",
                address: "",
            },
            documentoActual: "",
            //MIXTO
            amount: {
                efectivo: "",
                tarjeta: "",
                deposito: "",
                transferencia: "",
            },
            procede: true,
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: [],
        pending: "",
        type: {
            type: String,
            default: "",
        },
        patient: {}
    },
    watch: {
        patient() {
            //if(this.patient){

                this.documentoActual = this.chkDoc == true ? this.patient.document : this.chkPatiente.document;
           // }
            //return documento;
        },
        chkDoc(val) {
            this.documentoActual = val ? this.patient.document.toString() : this.chkPatiente.document;

        }
    },
    computed: {
        cpGarantias() {
            let string = "";
            if (this.item.length > 0) {
                this.item.forEach((element) => {
                    if (element.advance.length > 0) {
                        string += element.serie + "-" + element.number + ",";
                    }
                });
            }
            return string.substring(0, string.length - 1);
        },
        cpSaldo() {
            let estado =
                this.pending == 0 ?
                "PAGADO COMPLETO" :
                this.pending < 0 ?
                "A FAVOR DEL CLIENTE - APLICA NOTA DE CREDITO" :
                "A FAVOR DE LA EMPRESA";
            let monto =
                this.pending == 0 ?
                "" :
                this.pending < 0 ?
                parseFloat(this.pending * -1) :
                parseFloat(this.pending);
            return "S/ " + monto + " " + estado;
        },
        nombreActual() {
            const nombre = this.chkDoc == true ? this.patient.name.toString() : this.chkPatiente.fullName;
            return nombre;
        }
    },
    created() {
        this.mtdGetDataComprobantes();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetDataComprobantes() {
            this.post({
                    url: this.$store.getters.get__url + "/VoucherType/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.dataDocumentType = response.data.data;

                })
                .catch((errors) => {});
        },
        computedClass(comprobante) {
            return this.bottonActiveT == comprobante ?
                "myButton active-btn w-75" :
                "myButton w-75";
        },
        activarTipo(value) {
            this.bottonActiveT = value;
            if (value == "BOLETA" || value == "FACTURA") {
                this.disabledChkDoc = false;
                if (value == "FACTURA") {
                    this.chkDoc = false;
                    this.disabledChkDoc = true;
                } else {
                    this.chkDoc = true;
                }
            } else {
                this.disabledChkDoc = true;
                this.chkDoc = true;
            }
            this.mtdSelectDoc();
        },
        activeClass(comprobante) {
            return this.bottonActiveT == comprobante ?
                "myButton-icon-2 active-ico" :
                "myButton-icon-2";
        },
        mtdCalculate() {

            let acum = 0;
            let efectivo = this.amount.efectivo == "" ? 0 : this.amount.efectivo;
            let tarjeta = this.amount.tarjeta == "" ? 0 : this.amount.tarjeta;
            let deposito = this.amount.deposito == "" ? 0 : this.amount.deposito;
            let transferencia = this.amount.transferencia == "" ? 0 : this.amount.transferencia;
            acum = parseFloat(efectivo) +
                parseFloat(tarjeta) +
                parseFloat(deposito) +
                parseFloat(transferencia);

            if (this.pending == parseFloat(acum).toFixed(2)) {
                this.procede = true;
            } else {
                this.procede = false;
            }
        },
        mtdSelectDoc() {
            if (this.chkDoc) {
                this.chkPatiente.document = this.patient.document;
                this.chkPatiente.fullName = this.patient.name;
            } else {
                this.chkPatiente.document = "";
                this.chkPatiente.fullName = "";
            }
        },
        mtdSearchDocument: function () {
            if (this.bottonActiveT == "BOLETA") {
                if (this.chkDoc == false) {
                    this.chkPatiente.document = this.documentoActual;
                }
                if (this.chkPatiente.document.length == 8 && this.chkDoc == false) {
                    this.get({
                            url: this.$store.getters.get__url +
                                "/reniec/" +
                                this.chkPatiente.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.client.document = "";
                                Swal.fire({
                                    text: "DNI no encontrado",
                                    icon: "warning",
                                    confirmButtonColor: "#900052",
                                });
                            } else {
                                this.chkPatiente.fullName =
                                    response.data.name + " " + response.data.last_name;
                            }
                        })
                        .catch((errors) => {});
                }
            } else if (this.bottonActiveT == "FACTURA") {
                if (this.chkPatiente.document.length == 11 && this.chkDoc == false) {
                    this.get({
                            url: this.$store.getters.get__url +
                                "/reniec/" +
                                this.chkPatiente.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.client.document = "";
                                Swal.fire({
                                    text: "RUC no encontrado",
                                    icon: "warning",
                                    confirmButtonColor: "#900052",
                                });
                            } else {
                                this.chkPatiente.fullName = response.data.razon_social;
                                this.chkPatiente.address = response.data.direccion;
                            }
                        })
                        .catch((errors) => {});
                }
            }
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mdtCanjear: function () {
            let array = [];
            if (this.bottonActiveM == 'mixto') {
                array = this.mtdSetMixto();
            }
            if (this.chkDoc) {
                this.chkPatiente.document = this.patient.document;
                this.chkPatiente.fullName = this.patient.name;
            }
            this.$emit(
                "mtdCommitCanjear",
                this.bottonActiveM,
                this.sustento,
                this.type,
                this.bottonActiveT,
                this.chkPatiente, array
            );
            this.mtdClose();
        },
        mtdSetMixto() {
            let array = [];
            const methods = ['efectivo', 'deposito', 'tarjeta', 'transferencia'];
            methods.forEach(method => {
                const amount = this.amount[method];
                if (amount !== "") {
                    array.push({
                        method,
                        amount
                    });
                }
            });
            return array;
        },
        mtdClose() {
            this.$emit("mtdCloseModalCanje");
            this.chkPatiente.document = "";
            this.chkPatiente.fullName = "";
            this.chkPatiente.address = "";
            this.chkDoc = false;
            this.bottonActiveM = null;
            this.bottonActiveT = null;
            this.amount = {
                efectivo: "",
                tarjeta: "",
                deposito: "",
                transferencia: "",
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        activarMedio: function (value) {
            this.bottonActiveM = value;
            this.amount = {
                efectivo: "",
                tarjeta: "",
                deposito: "",
                transferencia: "",
            };
            // this.payment.pending = 0;
            // this.payment.vuelto = 0;
            this.procede = value == 'mixto' ? false : true;
            // this.mtdCalculate(this.payment.method);
        },
    },
};
</script>

<style scoped>
.inpt_color {
    text-align: center !important;
    background-color: cadetblue !important;
    border-radius: 20px;
    color: white;
}

.inpt_color::placeholder {
    color: white;
}

#btn-close {
    width: 49%;
}

.btn-register {
    background: rgb(144, 0, 82);
}

.close {
    cursor: pointer;
}

.modal-header {
    background: rgb(144, 0, 82);
}

.input-1 {
    border: 1.5px solid #9c9a9b;
    color: #9c9a9b;
}

.btn-fin {
    border: none !important;
}

.myButton-div {
    border: 1.5px solid #9c9a9b;
    background-color: transparent;
    color: #9c9a9b;
    border-radius: 10px;
    width: 100px;
    padding: 4px 5px 4px 5px;
}

.myButton-div:hover {
    border: 1.5px solid #900052;
    color: #900052;
}

.border-custom {
    border-bottom: 1.5px solid #900052;
}

.border-custom-text {
    color: #900052;
}

.border-right {
    border-right: 1.5px solid #900052;
}

.myButton {
    border: none;
    background-color: transparent;
    color: #9c9a9b;
    align-content: center !important;
}

.active-btn {
    border-color: #900052 !important;
    color: #900052 !important;
}

.active-div-btn {
    border: 2.5px solid #900052 !important;
    color: #900052 !important;
    background-color: #f1f1f1;
}

.active-ico {
    border-color: #900052 !important;
    color: #900052 !important;
    border: 2.5px solid #900052 !important;
    background-color: #f1f1f1;
}

.myButton-icon {
    border: 1.5px solid #9c9a9b;
    border-radius: 15px;
    height: 65px;
    margin: 0px 35px 0px 35px;
}

.myButton-icon-2 {
    border: 1.5px solid #9c9a9b;
    border-radius: 15px;
    height: 65px;
    margin: 0px 10px 0px 10px;
}

.myButton:hover .myButton-icon {
    border-color: #900052;
}

.myButton:hover div {
    color: #900052;
}

.myButton:hover .myButton-icon-2 {
    border-color: #900052;
}

.myButton:hover div {
    color: #900052;
}

#form-input {
    border-radius: 0px !important;
}

.grupo {
    border-radius: 0px !important;
}

@media (max-width: 1199px) {
    .myButton-icon {
        margin: 0px 20px 0px 20px;
    }

    .myButton-icon-2 {
        margin: 0px 3px 0px 3px;
    }

    #row-3 {
        align-content: center;
        margin: 0;
    }
}

@media (max-width: 991px) {
    #row-3 {
        flex-wrap: wrap;
    }

    .col {
        flex-basis: 50%;
    }

    #row {
        flex-direction: column;
    }

    .border-right {
        border-right: none;
        border-bottom: 1.5px solid #900052;
    }

    #row-3 {
        align-content: center;
        margin: 0;
    }
}

@media (max-width: 767px) {
    #row-2 {
        flex-direction: column;
        align-content: center;
    }

    #row-3 {
        align-content: center;
        margin: 0;
    }

    .myButton-icon-2 {
        margin: 0px -11px 0px -11px;
    }

    .myButton-icon {
        margin: 0px 70px 0px 70px;
    }
}

@media (max-width: 575px) {
    .myButton-icon {
        margin: 0px 78px 0px 78px;
    }
}

@media (max-width: 531px) {
    .myButton-icon {
        margin: 0px 0px 0px 0px;
    }

    .myButton-icon-2 {
        margin: 0px -40px 0px -40px;
    }
}

@media (max-width: 414px) {
    .col2 {
        padding: 0px 15px 0px 15px;
    }

    .button-1 {
        margin-bottom: 15px !important;
    }
}

#txtDoc {
    width: 1px !important;
    border-radius: 0px !important;
}

#txtDoc2 {
    border-radius: 0px !important;
}
</style>
