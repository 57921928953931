<template>
  <div>
      <div class="px-3 py-2">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
          <div id="div-modal-cxc" class="d-flex justify-content-center">
              <div id="div-cxc" class="card w-100">
                  <div class="card-header text-white h4 text-center" style="background: #900052">
                      <strong>HISTORIAL DE MOVIMIENTOS</strong>
                  </div>
                  <div class="card-body w-100">
                      <div class="row">
                           <!--Filtro-->
                           <div class="col-md-12 py-2">
                              <div class="row d-flex">
                                <!--
                                <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">BUSCAR MARCA</span>
                                        <v-select class="text-dark form-control" label="name" :options="dataMarca" placeholder="- Buscar Marca (Escriba uno...) -" @search="searchBrand" v-model="selectedMarca" :reduce="(name) => name.id" required :clearable="false" @input="clearBrand">
                                          <template slot="no-options">
                                            No hay marcas para la busqueda.
                                          </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-8 col-md-12 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">BUSCAR PRODUCTO</span>
                                        <v-select
                                          id="productSelect"
                                          class="text-dark form-control"
                                          :options="filteredProducts"
                                          v-model="selectedProducto"
                                          :reduce="(product) => product.id"
                                          label="subsubcategorie_name"
                                          placeholder="- Buscar Producto-"
                                          required
                                          :clearable="false"
                                          @input="clearProd"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12 mb-1">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                         @click="mtdGetData"
                                        :disabled="mtddisable"
                                        >
                                        <i class="fas fa-search"></i>&nbsp;Buscar
                                        </button>
                                </div>
                                -->
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 px-1 mb-2">
                                    <div class="input-group input-group-sm w-100  ">
                                        <span class="input-group-text ">Desde</span>
                                        <input type="date"
                                        @input="mtdGetData()" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init"/>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 px-1 mb-2">
                                    <div class="input-group input-group-sm  w-100 ">
                                        <span class="input-group-text ">Hasta</span>
                                        <input  @input="mtdGetData()"
                                        type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end"/>
                                    </div>
                                </div>
                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><i class="fas fa-search"></i
                                        ></span>
                                        <input
                                        @keyup="calculateData(7)"
                                        v-model="search"
                                        type="text"
                                        class="form-control form-control"
                                        placeholder="Molécula / Comercial / Marca"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 px-1">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        @click="viewpdf()"
                                        :disabled="mtddisablePdf"
                                        >
                                        <i class="fal fa-file-pdf"  style="font-size: 18px;"></i>&nbsp; PDF
                                        </button>
                                </div>

                              </div>
                          </div>
                           <!--Fin Filtro-->

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-4 table-container" v-if="showTable">
                              <table  id="tb-anular" class="table table-bordered border-main mb-0 px-0 text-xs">
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col" colspan="1" rowspan="2">FECHA</th>
                                        <th scope="col" colspan="1" rowspan="2">COMERCIAL - MOLECULA - MARCA</th>
                                        <th scope="col" colspan="1" rowspan="2">LOTE</th>
                                        <th scope="col" colspan="4" >MOVIMIENTO</th>
                                    </tr>

                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">Almacen Origen</th>
                                        <th scope="col">Cantidad Origen</th>
                                        <th scope="col">Almacen Destino</th>
                                        <th scope="col">Cantidad Destino</th>
                                    </tr>

                                </thead>

                                <tbody class="custom-table">
                                  <tr v-if="cpData.length == 0">
                                      <td class="text-center align-middle" colspan="7">Sin Registros</td>
                                  </tr>
                                  <template v-for="(item, index) in cpData">
                                      <tr  class="text-center" style="vertical-align: middle;">
                                          <td>{{ item.date | formatDate }}</td>
                                          <td>{{ item.product ? item.product : '-'}}</td>
                                          <td>{{ item.lote ? item.lote : '-'}}</td>
                                          <td>{{ item.almacenOrigen ? item.almacenOrigen : '-'}}</td>
                                          <td>{{ item.quantity_origin }}</td>
                                          <td>{{ item.almacenDestino ? item.almacenDestino : '-'}}</td>
                                          <td>{{ item.quantity_destination }}</td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>

                            <!--pag-->
                            <div v-if="cpData.length > 0 && showTable" class="d-flex justify-content-center px-1 mb-4">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="
                                selectPage === 1 ? 'page-link active' : 'page-link'
                              " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="
                                selectPage === item ? 'page-link active' : 'page-link'
                              " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if="
                              limitedStepPagination[
                                limitedStepPagination.length - 1
                              ] <
                              stepPagination.length - 1
                            " class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if="
                              limitedStepPagination[
                                limitedStepPagination.length - 1
                              ] !== stepPagination.length
                            " class="page-item">
                                            <a :class="
                                selectPage === limitedStepPagination.length + 2
                                  ? 'page-link active'
                                  : 'page-link'
                              " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {
  mapActions
} from "vuex";
import moment from "moment";

export default {
  name: "kardex",
  components: {},
  data() {
      return {
        search: "",
        dataMarca: [],
        selectedMarca: "",
        dataProducto: [],
        selectedProducto: "",
        dataAlmacen: [],
        showTable: false,
        dataShow: [],
        page: 0,
        selectPage: 1,
        stepPagination: [],
        totalPagesToShow: 4,
        showPdf: false,
        filter: {
            init: null,
            end: null,
        },
      };
  },
  created() {
      moment.locale("es");
      this.filter.end =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
      this.filter.init = this.filter.end;
      this.mtdGetData();
  },
  watch: {
      selectPage() {
          this.calculateData(7);
      },
  },
  computed: {
        cpData() {
            return this.dataShow;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

      filteredProducts() {
        if (this.selectedMarca) {
          const selectedMarca = this.dataMarca.find(
            (brand) => brand.id === this.selectedMarca
          );
          if (selectedMarca) {
            /*
            return selectedMarca.productdetails.map(
              (productDetail) => productDetail.subsubcategorie
            );
            */
            return selectedMarca.productdetails;
          }
        }
        return [];
      },

      mtddisable() {
          return !(this.selectedMarca !== "" && this.selectedProducto !== "");
      },

      mtddisablePdf() {
          return (this.showPdf == false);
      },
  },
  methods: {
      ...mapActions(["get", "post"]),

      returnHome: function () {
            this.$emit("returnHome");
        },

        searchBrand: function (search, loading) {
            if (search.length > 2) {
                this.dataMarca = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/logistics/indexbrand/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataMarca = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

      clearBrand: function () {
          this.selectedProducto = "";
          this.showTable = false;
          this.showPdf = false;
      },

      clearProd: function () {
          this.showTable = false;
          this.showPdf = false;
      },

      mtdGetData: function () {
          this.post({
              url: this.$store.getters.get__url + "/logistics/mtdHistoryMov",
              token: this.$store.getters.get__token,
              /*
              params: {
                  brand: this.selectedMarca,
                  product: this.selectedProducto
              },
              */
              params: {
                  init: this.filter.init,
                  end: this.filter.end,
              },
          })
          .then((response) => {
              if (response.data.length === 0) {
                  this.showTable = false;
                  this.showPdf = false;
                  this.noRecordsMessage = "No se encontraron resultados.";
                  Swal.fire({
                  text: " Sin Datos",
                  icon: "warning",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#900052",
                  });
              } else {
                   this.dataAlmacen = response.data;
                  this.calculateData(7);
                  this.showTable = true;
                  this.showPdf = true;
                  this.noRecordsMessage = "";
              }

          })
          .catch((errors) => {
            Swal.fire({
              title: "Error",
              text: errors,
              icon: "error",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          });
      },

      calculateData(items) {
          if (!this.dataAlmacen || !this.dataAlmacen.length) {
              return (this.dataShow = []);
          }
          let filteredData = this.dataAlmacen;
          let indexInitial = this.selectPage;
          if (this.search.length >= 3 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataAlmacen.filter((element) => {
                    let commercial = element.commercial ? element.commercial.toString().toUpperCase() : "";
                    let molecule = element.molecule ? element.molecule.toString().toUpperCase() : "";
                    let marca = element.marca ? element.marca.toString().toUpperCase() : "";
                    return commercial.includes(query) || molecule.includes(query) || marca.includes(query);
                });
                indexInitial = 1;
            }
          let totalPages = Math.ceil(filteredData.length / items);
          this.stepPagination = Array.from({
                  length: totalPages,
              },
              (_, index) => index + 1
          );
          let startIndex = (indexInitial - 1) * items;
          let endIndex = startIndex + (items - 1);
          this.dataShow = filteredData.slice(startIndex, endIndex + 1);
          this.count = this.count + 1;
      },

      /** paginación */
      backPage() {
          this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
      },
      nextPage() {
          this.selectPage =
              this.selectPage < this.stepPagination.length ?
              this.selectPage + 1 :
              this.stepPagination.length;
      },
      selectedPage(page) {
          this.selectPage = page;
      },

      viewpdf: function () {
          window.open(
              this.$store.getters.get__url +
              "/logistics/viewpdf/mtdHistoryMov/" +
              this.filter.init + '/' + this.filter.end,
              "_blank"
          );
      },
  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>

<style scoped>
#dtb-cxc {
  max-height: 400px;
  overflow-y: auto;
}

#dtb-cxc {
  max-width: 1400px;
  overflow-x: auto;
}

#btn-edit {
  color: #39b54a;
  font-size: 25px;
  margin: 0px 2px;
}

#btn-edit:hover {
  color: #217b2d;
}

#btn-remove {
  color: #ff0000;
  font-size: 25px;
  margin: 0px 2px;
}

#btn-remove:hover {
  color: #a30404;
}

.btn-bm-noradius {
  margin-left: 10px;
  width: 150px;
  height: 38px;
}

.btn-bm-noradius:hover {
  background: rgb(170, 2, 95);
  background-color: rgb(170, 2, 95);
  color: white;
}

.btn-register {
  background: rgb(144, 0, 82);
}

.btn-register:hover {
  background: rgb(170, 2, 95);
}

.modal-header {
  background: rgb(144, 0, 82);
}

.close {
  cursor: pointer;
}

.text-start {
  text-align: left;
}

.tr-table>th>div {
  background: #900052;
  border-radius: 7px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
}

.tr-table>th {
  padding: 0px;
}

.tbody-table>td>div {
  background: #eaecee;
  border-radius: 15px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.tbody-table>td {
  padding: 0px;
}

.tbody-table>td>div>div {
  border-radius: 10px;
}

#btn-close {
  width: 49%;
}

.table-th {
  width: 50% !important;
}

.table-th-state {
  width: 18% !important;
}

.page-link {
  color: black !important;
}

.page-link.active {
  color: white !important;
  border-color: white !important;
  background: rgb(144, 0, 82);
}

.page {
  color: white !important;
  background: rgb(144, 0, 82);
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

@media (max-width: 480px) {
  #btn-edit {
      font-size: 13px !important;
  }

  #btn-remove {
      font-size: 13px !important;
  }

  #btn-close {
      width: 100%;
  }

  .container {
      font-size: 11px !important;
  }

  .btn-register {
      width: 100% !important;
      margin-top: 0 !important;
  }

  .td-div-a {
      padding-top: 10px !important;
  }

  .td-div-state>div>div {
      margin-top: 3px !important;
  }
}

@media (max-width: 415px) {
  #state_schedule {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
  }

  #state_schedule div {
      width: 100%;
  }
}

.custom-table th,
.custom-table td {
  font-size: 12px;
}

.table-container {
overflow-x: auto;
white-space: nowrap;
scrollbar-width: thin;
scrollbar-color: darkgray lightgray;
}

.large-text {
  font-size: 9px;
}
</style>
