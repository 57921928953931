<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname"/>
      </div>
      <div class="content h-100 " style="background:white">
        <HomeVue v-if="page=='home'" @changePage="changePage" @mtdBackCampus="mtdBackCampus" />
        <DocumentsVue v-if="page=='document'" @changePage="changePage" />
        <IngresosVue v-if="page=='ingreso'" @changePage="changePage" />
        <NotesCreditsVue v-if="page=='notas'" @changePage="changePage" />
        <VolverEmitir v-if="page=='volveremitir'" @changePage="changePage" />
        <ReporteIngresos v-if="page=='reporteIngreso'" @changePage="changePage" />
        <ReporteCaja v-if="page=='reporteCaja'" @returnHome="returnHome" />
      </div>
      <div class="footer">
        <FooterVue @mtdChangePage="mtdChangePage" :pageView='page' />
      </div>
    </div>
  </template>
  
  <script>
  import HomeVue from '@/components/Administracion/Home.vue';
  import Nav from "@/components/Administracion/Nav.vue";
  import FooterVue from '@/components/Administracion/Footer.vue';
  import DocumentsVue from '@/components/Administracion/pages/Documents.vue';
  import IngresosVue from '@/components/Administracion/pages/Ingresos.vue';
  import NotesCreditsVue from '@/components/Administracion/pages/NoteCredits.vue';
  import VolverEmitir from "@/components/Administracion/pages/VolverEmitir.vue";
  import ReporteIngresos from "@/components/Administracion/pages/ReporteIngresos.vue";
  import ReporteCaja from '@/components/JefeAdmision/pages/ReporteCajaView.vue'
  export default {
    name: "AdministracionView",
    components: {
      Nav,
      HomeVue,
      FooterVue,
      DocumentsVue,
      NotesCreditsVue,
      IngresosVue,
      VolverEmitir,
      ReporteIngresos,
      ReporteCaja
    },
    props: {
      rolname: String,
    },
    data() {
      return {
        page: "home",
      };
    },
    methods: {
      changePage: function (page) {
        this.page = page;
      },
      returnHome: function () {
        this.page = "home";
      },
      mtdBackCampus: function () {
        this.$emit("mtdBackCampus");
      },
      mtdChangePage: function(page){
        this.page = page;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  