<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus"/>
        <Planilla v-if="page=='planilla'" @mtdChangePage="mtdChangePage" />

    </div>
    <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
</div>
</template>

<script>
import Nav from '../../components/Asistencial/Nav.vue';
import Planilla from '../../components/RecusosHumanos/Pages/Planilla.vue'
import Home from '../../components/RecusosHumanos/Home.vue'
import Footer from '../../components/RecusosHumanos/Footer.vue'

export default {
    name: "v-recursosHumanos",
    components: {
        Nav,
        Home,
        Planilla,
        Footer
    },
    data() {
            return {
                page: "home",
                rol_name: null,
            };
        },
        props: {
            rolname: String,
            dataRoles: Array,
        },
        methods: {
    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.page = "home";
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdChangePage: function (page) {
      this.page = page;
    },
  },
}
</script>
