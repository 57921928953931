<template>
  <div>
    <div class="container">
      <div class="row">
        <div id="btn-home" class="col-md-12 mt-5 mb-4">
          <!--
            <button type="button" class="btn btn-outline-main" @click="retroceder"><i class="fas fa-home-lg-alt"></i>
            Inicio</button>
        --></div>
      </div>
      <div id="div-modal-imagen" class="d-flex justify-content-center mb-5">
        <div id="div-imagen" class="card w-100">
          <div
            align="center"
            class="card-header text-white h4"
            style="background: #900052"
          >
            <strong>IMÁGENES - {{ elementProcess.name }}</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12" style="color: #900052; text-align: center">
                <div class="d-flex justify-content-evenly">
                  <ul class="nav nav-pills">
                    <li
                      v-for="(item, index) in rooms"
                      :key="index"
                      class="nav-item"
                      @click="mtdSelectTab(item.id)"
                    >
                      <a
                        :class="tab == item.id ? 'nav-link active' : 'nav-link'"
                        aria-current="page"
                        href="javascript:void(0)"
                        >{{ item.name }}</a
                      >
                    </li>
                  </ul>
                  <!-- <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page"
                        href="javascript:void(0)">
                        <div class="form-check form-check-inline">
                        <input
                          v-model="elementProcess.reservadespues"
                          class="form-check-input"
                          type="checkbox"
                          id="checkboxreserva"
                          @click="reserva()"
                        />
                        <label class="form-check-label" for="checkboxFacturareserva">
                          Reservar Luego
                        </label>
                      </div>
                      </a>
                    </li>
                  </ul> -->
                </div>
              </div>

              <div class="col-md-10">
                <div class="card w-100 mt-2 border-main" style="width: 18rem">
                  <div class="card-body">
                    <div class="col-md-12 p-0" id="div-tabla-imagen">
                      <table
                        id="tb-cola"
                        class="table table-sm table-bordered p-0"
                      >
                        <thead>
                          <tr
                            align="left"
                            class="text-white border-white"
                            style="font-size: 12px; background: #900052"
                          >
                            <th scope="col">FECHA</th>
                            <th scope="col" :colspan="cpColspan">HORARIOS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            style="font-size: 12px"
                            v-for="(item, index) in data"
                            :key="index"
                          >
                            <td class="py-2" style="font-weight: bold">
                              <span
                                class="rounded-pill p-1"
                                style="background: #fff"
                                >{{ item.date }}</span
                              >
                            </td>
                            <td
                              class="py-2"
                              v-for="(item2, index2) in item.hours"
                              :key="index2"
                            >
                              <span
                                class="rounded-pill py-1 px-2"
                                style="background: #fff"
                                v-if="item2.state == 0"
                                >{{ item2.name }}</span
                              >
                              <div
                                v-if="
                                  elementProcess.data.hour == item2.name &&
                                  elementProcess.data.date == item.date && tab==elementProcess.data.room
                                "
                              >
                                <div v-if="mostrar == true">
                                  <span
                                    class="rounded-pill py-1 px-2"
                                    style="background: orange; color: #fff"
                                    v-if="
                                      elementProcess.data.hour == item2.name &&
                                      elementProcess.data.date == item.date
                                    "
                                    >{{ item2.name }}</span
                                  >
                                </div>
                                <div v-else>
                                  <span
                                    class="rounded-pill py-1 px-2"
                                    @click="
                                      mtdReserveHour(
                                        item.date,
                                        item2.name,
                                        index,
                                        index2
                                      )
                                    "
                                    style="background: #39b54a; color: #fff"
                                    v-if="item2.state == 1"
                                    >{{ item2.name }}</span
                                  >
                                </div>
                              </div>
                              <div v-else>
                                <span
                                  class="rounded-pill py-1 px-2"
                                  @click="
                                    mtdReserveHour(
                                      item.date,
                                      item2.name,
                                      index,
                                      index2
                                    )
                                  "
                                  style="background: #39b54a; color: #fff"
                                  v-if="item2.state == 1"
                                  >{{ item2.name }}</span
                                >
                                <span
                                  class="rounded-pill py-1 px-2"
                                  style="background: #fcee21; color: #fff"
                                  v-if="item2.state == 2"
                                  >{{ item2.name }}</span
                                >
                                <span
                                  class="rounded-pill py-1 px-2"
                                  style="background: #ff0000; color: #fff"
                                  v-if="item2.state == 3"
                                  >{{ item2.name }}</span
                                >
                                <span
                                  class="rounded-pill py-1 px-2"
                                  style="background: orange; color: #fff"
                                  v-if="item2.state == 4"
                                  >{{ item2.name }}</span
                                >
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div align="center" class="col-md-2 pt-3" style="color: #900052">
                <i class="fa fa-solid fa-caret-left"></i
                ><span><strong> MES </strong></span>
                <i class="fa fa-solid fa-caret-right"></i>
              </div>
            </div>
          </div>
          <div
            class="card-footer text-white bg-main text-lg"
          >
            <div class="row d-flex">
              <div class="col">
                <span
                  class="btn border border-white text-white"
                  @click="mtdBehindStep"
                  ><strong>ATRAS</strong></span
                >
              </div>
              <div align="right" class="col">
                <span
                  class="btn border border-white text-white"
                  @click="mtdNextStep"
                  ><strong>SIGUIENTE</strong></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "c-admision-pages-venta-imagenes",
  data() {
    return {
      tab: 0,
      rooms: [],
      data: [],
      verifSelectHour: false,
      oldPosSelectHour: [],
      mostrar: false,
    };
  },
  props: {
    elementProcess: {},
  },
  computed: {
    cpColspan(){
      if(!this.data[0]) return 0;
      return this.data[0].hours.length;
    }
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    reserva() {
    if (this.elementProcess.reservadespues===false) {
      this.elementProcess.reservadespues= true;
    } else {
      this.elementProcess.reservadespues = false; // No es necesario comparar, solo asigna el valor
    }

    },
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/Venta/imagenes/"+this.$store.getters.get__campus,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.rooms = response.data;
          if (this.elementProcess.programado == true) {
            this.mostrar = true;
            this.mtdSelectTab(this.elementProcess.data.room);
          } else {
            this.mtdSelectTab(this.rooms[0].id);
          }
        })
        .catch((errors) => {});
    },


    mtdSelectTab: function (id) {
      this.post({
        url: this.$store.getters.get__url + "/Venta/imagenes/filter",
        token: this.$store.getters.get__token,
        params: {
          subsub: this.elementProcess.id,
          room: id,
        },
      })
        .then((response) => {
          
          this.data = response.data;
          this.tab = id;
        })
        .catch((errors) => {});
    },
    mtdReserveHour: function (fecha, hora, pos1, pos2) {
      /** consultar con bd si aun está libre */
      if (this.verifSelectHour == true) {
        this.data[this.oldPosSelectHour[0]].hours[
          this.oldPosSelectHour[1]
        ].state = 1;
      }
      this.oldPosSelectHour = [pos1, pos2];
      this.verifSelectHour = true;
      this.data[pos1].hours[pos2].state = 4;
      this.elementProcess.data = {
        date: fecha,
        hour: hora,
        room: this.tab,
      };
      this.elementProcess.programado = true;
      this.mostrar = true;
    },
    mtdNextStep: function () {
      if(this.elementProcess.reservadespues===true){ 
        this.$emit('mtdNextProcess', this.elementProcess);
      }
      else{
      if (
        this.elementProcess.data.room != null &&
        this.elementProcess.data.room != undefined
      ) {
        this.$emit("mtdNextProcess", this.elementProcess);
        this.tab=0;
        this.rooms=[];
        this.data=[];
        this.verifSelectHour=false;
        this.oldPosSelectHour=[];
        this.mostrar=false;
        this.mtdGetData();
      } else {
        Swal.fire({
          text: "Seleccione un consultorio",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
      }
    }},
    mtdBehindStep: function () {
      //this.elementProcess.programado = false;
      this.$emit("mtdBehindProcess", this.elementProcess);
    },
    retroceder() {
      this.$emit("mtdChangePage", "home");
    },
  },
};
</script>

<style scoped>
#div-tabla-imagen {
  border: 1px solid #aaa;
  height: 300px;
  background: #f1f2f3;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#div-tabla-imagen::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-tabla-imagen::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-tabla-imagen::-webkit-scrollbar-button:increment,
#div-tabla-imagen::-webkit-scrollbar-button {
  display: none;
}

#div-tabla-imagen::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-tabla-imagen::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-tabla-imagen::-webkit-scrollbar-track {
  border-radius: 10px;
}

.circulo {
  vertical-align: middle;
  border-style: none;
}

#div-modal-imagen {
  margin-top: 10px;
}

@media (max-width: 800px) {
  /* #div-modal-imagen{
      margin-top: 20px;
    } */
  #btn-open-caja {
    justify-content: center !important;
  }

  #btn-registro-cola {
    text-align: center;
  }

  #div-tabla-imagen {
    overflow-x: auto;
  }
}

@media (min-width: 800px) {
  /* #div-modal-imagen{
      margin-top: 65px;
    } */
  #btn-registro-cola {
    text-align: left;
  }
}
</style>
