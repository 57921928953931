<template>
  <div class="px-5 pt-3 pb-5">
    <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
      <div id="div-modal-anular" class="w-100">
          <div id="div-anular" class="card w-100">
              <div class="card-header text-center text-white h4" style="background: #900052">
                  <strong>SIRE</strong>
              </div>
              <div class="card-body w-100">
                  <div class="row">
                      <div class="col-md-12 py-2">
                          <div class=" row max-0 px-0">
                              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-12 px-1">
                                  <div class="input-group input-group mb-1">
                                      <span class="input-group-text text-white" style="background: #900052"><i class="fas fa-search"></i></span>
                                      <input @keyup="calculateData(10)" v-model="search" type="text" class="form-control form-control" placeholder="Buscar Proveedor" />
                                  </div>
                              </div>
                              <!--
                              <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1 mb-1">
                                  <button type="button" class="btn btn-bm-noradius w-100">
                                    <i class="fas fa-plus"></i> Nueva
                                  </button>
                              </div>
                              -->
                              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 px-1">
                                  <button type="button" class="btn btn-bm-noradius w-100" @click="mtdModalEx">
                                    <i class="fas fa-upload"></i> Cargar
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-4 table-container" id="div-tabla-anular">
                          <table id="tb-anular" class="table tablaRe table-bordered align-middle">
                              <thead>
                                  <tr class="text-white table-th text-center">
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                          N°
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                          FECHA DE EMISION
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                         FECHA DE VCTO
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                         PROVEEDOR
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                         RUC
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                         TOTAL
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                         DETRACCION
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                        ESTADO
                                      </th>
                                      <th scope="col" class="align-middle border border-5 bg-main border-white">
                                          ACCIONES
                                      </th>
                                  </tr>
                              </thead>

                              <tbody>
                                  <tr class="table-body text-center" v-if="cpData.length === 0">
                                      <td colspan="9" class="text-center">No hay registros</td>
                                  </tr>
                                  <tr class="table-body" v-for="(item, index) in dataShow " :key="index">
                                      <td class="text-center border border-5 border-white align-middle">
                                          {{mtdNumber(index) }}
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          <template v-if="item.emison_date">
                                              {{ item.emison_date | formatDate }}
                                          </template>
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          {{ item.expiration_date ? item.expiration_date : '-'}}
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          {{ item.reason_social }}
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          {{ item.ruc }}
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          {{ item.total}}
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          {{ item.detraction }}
                                      </td>


                                      <td v-if="item.state == 0" class="text-center border border-5 border-white align-middle px-3">
                                          <span class="badge text-bg-warning">Pendiente</span>
                                      </td>
                                      <td v-else-if="item.state ==1" class="text-center border border-5 border-white align-middle px-3">
                                          <span class="badge text-bg-success">Aceptado</span>
                                      </td>
                                      <td v-else class="text-center border border-5 border-white align-middle px-3">
                                          <span class="badge text-bg-danger">Rechazado</span>
                                      </td>
                                      <td class="text-center border border-5 border-white align-middle">
                                          <div class="d-flex justify-content-center">
                                              <button class="btn btn-info btn-success text-white me-1" @click="mtdState(item.id)">
                                                  <i class="fas fa-edit"></i>
                                              </button>
                                              <button class="btn btn-info btn-warning text-white" v-if="item.state !== 0"  @click="mtdchangePending(item.id)">
                                                  <i class="fa fa-reply"></i>
                                              </button>
                                          </div>
                                      </td>
                                  </tr>

                              </tbody>
                          </table>
                      </div>
                      <div v-if="dataSide.length > 0 && cpData.length > 0" class="d-flex justify-content-center px-1">
                          <nav aria-label="Page navigation example">
                              <ul class="pagination mb-1">
                                  <li class="page-item">
                                      <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                  </li>
                                  <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                      <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                  </li>
                                  <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                      <span class="page-link">...</span>
                                  </li>

                                  <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                      <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                      </a>
                                  </li>
                                  <li v-if="
                limitedStepPagination[limitedStepPagination.length - 1] <
                stepPagination.length - 1
              " class="page-item disabled">
                                      <span class="page-link">...</span>
                                  </li>
                                  <li v-if="
                limitedStepPagination[limitedStepPagination.length - 1] !==
                stepPagination.length
              " class="page-item">
                                      <a :class="
                  selectPage === limitedStepPagination.length + 2
                    ? 'page-link active'
                    : 'page-link'
                " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                      </a>
                                  </li>
                                  <li class="page-item">
                                      <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <cModalSideExcelVue :title="modalEx.title" :boo_modal="modalEx.modal_form"
      @closeModal="closeModalEx" @mtdCommitReceipt="mtdCommitReceipt"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalSideExcelVue from '../modals/cModalSideExcel.vue';
export default {
    data() {
        return {
            all: [],
            dataSide: [],
            search: "",
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalEx: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        }
    },
    components: {
      cModalSideExcelVue
    },
    created() {
        this.mtdGetData();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/temporaryCollection/side",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataSide = response.data;
                    this.calculateData(10);

                })
                .catch((errors) => {});
        },

        calculateData(items) {
            let filteredData = this.dataSide;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataSide.filter((element) => {
                    let name = element.reason_social.toString().toUpperCase();
                    return name.includes(query);
                });

                indexInitial = filteredData.length > 10 ? this.selectPage : 1 ;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);

        },


        mtdModalShow: function (num, payload) {
            this.modal.title = (num == 0) ? 'Registrar Marca' : 'Editar Marca ';
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;

        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},

            }
        },

        mtdModalEx: function () {
            this.modalEx.title = 'Subir Archivo';
            this.modalEx.modal_form = true;
        },

        closeModalEx: function () {
            this.modalEx = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},

            }
        },

        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action : 'd',
                    id : payload_detail
                }
                Swal.fire({
                    title: "¿Estás seguro?",
                    text: "¡No podrás revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Eliminar",
                    width: "400px",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.addDatadetail(data, num_detail);
                    }
                });
            }
        },

        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/brand/Marca",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (num_detail == 2) {
                        Swal.fire({
                            title: "¡Eliminado!",
                            text: "El registro ha sido eliminado.",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        }).then(() => {
                            this.mtdGetData();
                        });
                    } else {

                        Swal.fire({
                            title: num_detail == 0 ? "Registro Exitoso" : 'Modificación Exitosa',
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.closeModal()
                        this.mtdGetData();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },

        mtdCommitReceipt: function (all) {
            this.post({
              url: this.$store.getters.get__url + "/temporaryCollection/sideExcel",
              token: this.$store.getters.get__token,
              params: {
                excel: all
              }
            })
            .then((response) => {
              if (response.state == 0) {
                Swal.fire({
                  title: "Carga de datos exito",
                  text: "Perfecto!",
                  icon: "success",
                  width: "400px",
                  confirmButtonColor: "rgb(170, 2, 95)",
                });
                this.mtdGetData();
                this.closeModalEx();
              } else if (response.state == 1) {
                Swal.fire({
                  title: "No hay datos por insertar",
                  icon: "warning",
                  width: "400px",
                  confirmButtonColor: "rgb(170, 2, 95)",
                });
              } else {
                Swal.fire({
                    title: "Error",
                    icon: "error",
                    width: "400px",
                    confirmButtonColor: "rgb(170, 2, 95)",
                });
              }
            })
            .catch((errors) => {
              Swal.fire({
                  title: "Error",
                  text: errors,
                  icon: "error",
                  width: "400px",
                  confirmButtonColor: "rgb(170, 2, 95)",
              });
            });
        },

        mtdState: function(id) {
          Swal.fire({
            icon: "warning",
            title: "¿Qué accción deseas tomar?",
            width: "400px",
            showDenyButton: true,
            denyButtonText: `Rechazar`,
            denyButtonColor: "#bb8a17",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#3085d6",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            cancelButtonText: "Cerrar",
          }).then((result) => {
            if (result.isConfirmed) {
              this.mtdStateCommit(id, 1);
            } else if (result.isDenied) {
              this.mtdStateCommit(id, 2);
            }
          });
        },

        mtdchangePending: function(id) {
          Swal.fire({
            icon: "warning",
            title: "¿Quieres cambiar el estado a Pendiente?",
            width: "400px",
            showDenyButton: false,
            denyButtonText: `Cancelar`,
            confirmButtonText: "Cambiar a Pendiente",
            confirmButtonColor: "#d33",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.mtdStateCommit(id, 0);
            }
          });
        },

        mtdStateCommit: function (id, state) {
          this.post({
            url: this.$store.getters.get__url + "/temporaryCollection/sideState/" + id,
            token: this.$store.getters.get__token,
            params: {
              state: state,
            },
          })
          .then((response) => {
            if (response.state == 0) {
              Swal.fire({
                text: state == 1 ? "Aceptado" : "Rechazado",
                icon: "success",
                confirmButtonColor: "#900052",
              });
              this.mtdGetData();
            } else {
              Swal.fire({
                text: "No se pudo cambiar State",
                icon: "warning",
                confirmButtonColor: "#900052",
              });
            }
          })
          .catch((errors) => {});
        },

        //Paginacion
        backPage() {
          this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
          this.selectPage =
          this.selectPage < this.stepPagination.length ?
          this.selectPage + 1 :
          this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
}
</script>

<style scoped>
.table-th>th {
    border-radius: 15px;
    background: #900052
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}

@media (max-width: 480px) {
  #btn-edit {
      font-size: 13px !important;
  }

  #btn-remove {
      font-size: 13px !important;
  }

  #btn-close {
      width: 100%;
  }

  .container {
      font-size: 11px !important;
  }

  .btn-register {
      width: 100% !important;
      margin-top: 0 !important;
  }

  .td-div-a {
      padding-top: 10px !important;
  }

  .td-div-state>div>div {
      margin-top: 3px !important;
  }
}

@media (max-width: 415px) {
  #state_schedule {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
  }

  #state_schedule div {
      width: 100%;
  }
}

.table-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: darkgray lightgray;
}

</style>
