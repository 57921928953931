<template>
    <div>
        <div class="container text-center mt-2 ">      
            <div class="d-flex "> 
                <h4
                  class=" h4-borde mt-5 mb-4 ms-5  bg-main text-white pt-1 px-4"
                >
                     SUSALUD
                </h4>
                <div  class="btn-home mt-5 mb-4 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
           
            
            <ul class="nav  pb-0 mt-3" id="myTab" role="tablist">
                <li class="nav-item  " role="presentation">
                    <button
                      @click="changePage('categorie')" 
                      :class="{'item-button':true, 'nav-link': true, 'active': page === 'categorie' }"
                      id="home-tab" 
                      type="button" 
                     >
                       Categoria
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button
                      @click="changePage('subcategorie')" 
                      :class="{'item-button':true, 'nav-link': true, 'active': page === 'subcategorie' }"
                      id="home-tab" 
                      type="button" 
                     >
                       SubCategorias
                    </button>
                </li>
               
                <li class="nav-item  " role="presentation">
                    <button 
                    @click="changePage('codcat')" 
                    :class="{ 'item-button':true,'nav-link': true, 'active': page === 'codcat' }"
                    id="profile-tab"  
                    type="button"  
                    >
                     Cod-Cat
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button 
                        @click="changePage('service')" 
                        :class="{ 'item-button':true,'nav-link': true, 'active': page === 'service' }"
                        id="home-tab" 
                        type="button" 
                    >
                      Servicios
                    </button>
                </li>
              
                
            </ul>
            <div class="tab-content   mb-4 " id="myTabContent">
                <br>
                <CodCat v-if="page=='codcat'"/>
                <Service v-if="page=='service'"/>
                <Categorie v-if="page=='categorie'"/>
                <Subcategories v-if="page=='subcategorie'"/>
            </div>
        </div>
    </div>
</template>

<script>
import CodCat from '@/components/Maintenance/pages/Susalud/Codcat.vue';
import Service from '@/components/Maintenance/pages/Susalud/Service.vue';
import Categorie from '@/components/Maintenance/pages/Susalud/Categories.vue';
import Subcategories from '@/components/Maintenance/pages/Susalud/SubCategories.vue'
export default {

name:"Susalud",

components:{
    CodCat,
    Service,
    Categorie,
    Subcategories
},

data(){
    return{
      page: 'categorie',
     
    }
  },

 methods:{
    returnHome: function () {
      this.$emit("returnHome");
    },
    changePage:function(page){
          this.page = page;
      
        } ,
    
 }
}
</script>

<style scoped>
.h4-borde{
    border-radius: 50px;
}
#myTabContent{
    border:#900052 1.9px solid;
}

.item-button{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
   /* margin-right: 5px !important;*/
    border: none !important;
    color:white !important;
    width: 155px;   
}
.item-button1{
    background: #A9A9A9 !important;
    border-radius: 10px 10px 0px 0px;   
   /* margin-right: 5px !important;*/
    border: none !important;
    color:white !important;
    width: 160px !important;    
}

.nav-link:hover{
    background:#900052 !important
}
.active {
  background-color: #900052 !important ;
 
}
.nav-item{
    width: 159.39px;
}

</style>