<template>
  <div>
      <div class="container text-center mt-2">
  
          <div class="d-flex title-option">
              <h2 class=" h2-borde mt-5 mb-4 ms-5 text-main pt-1 px-4">
                  <strong>SOP Y URPA</strong>
              </h2>
              <div class="btn-home mt-5 mb-4 ms-auto">
                  <button type="button" class="btn btn-outline-main" @click="returnHome()">
                      <i class="fas fa-home-lg-alt"></i> Inicio
                  </button>
              </div>
          </div>
  
          <ul class="nav pb-0 mt-3" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                  <button @click="changePage('cola')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'cola' }" id="home-tab" type="button">
                      <strong>COLA</strong>
                  </button>
              </li>
              <li class="nav-item" role="presentation">
                  <button @click="changePage('historia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'historia' }" id="profile-tab" type="button">
                      <strong>H. CLÍNICA</strong>
                  </button>
              </li>
          </ul>
  
          <div class="tab-content mb-3" id="myTabContent">
              <Cola v-if="page=='cola'" />
              <Historia v-if="page=='historia'" />
          </div>
  
      </div>
  </div>
  </template>

<script>
  import { mapActions } from 'vuex'
  import Cola from '@/components/Asistencial/Sop/ColaS.vue';
  import Historia from '@/components/Asistencial/Sop/HistoriaS.vue';
  export default {
    name: 'c-asistencial-sop',
    components:{
      Cola,
      Historia
    },
    data () {
      return {
        page: 'cola',
      }
    },
    created () {
    
    },
    methods: {
      ...mapActions(['get', 'post']),
      mtdGetStyle:function(option){
        if (option == this.page) return 'background: #900052';
        return '';
      },
      mtdGetStyleSpan:function(option){
        if (option == this.page) return 'color:white';
        return '';
      },
      mtdSendOption: function(page){
        this.page=page;
        this.$emit('mtdChangePage',this.page)
      },
      changePage: function (page) {
            this.page = page;
        },

      returnHome() {
            window.location.reload();
      }

    }
  }
</script>