<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :full-screen="true">
    <div class="px-2">
        <div class="row mt-3 d-flex">
            <div class="mb-lg-3 col-md-4 col-sm-6 mb-2">
                <div class="input-group input-group-sm">
                    <span class="input-group-text col-md-3">Desde</span>
                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="startdate" v-model="filter.date_start" @change="mtdShowData" />
                </div>
            </div>
            <div class="mb-lg-3 col-md-4 col-sm-6 mb-2">
                <div class="input-group input-group-sm">
                    <span class="input-group-text col-md-3">Hasta</span>
                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdShowData" />
                </div>
            </div>
            <div class="mb-3 col-md-4 col-sm-12">
                <div class="input-group input-group-sm">
                    <span class="input-group-text text-white bg-main "><i class="fas fa-search"></i></span>
                    <input v-model="search" type="text" class="form-control form-control-sm" placeholder="Buscar por Factura/Boleta" @input="mtdSearchFilter" />
                </div>
            </div>
            <hr>
            <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-end pe-5">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination mb-0 ">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-12 mt-3 px-2" v-for="(bill, index) in cpData" :key="index">
                <div class="row d-flex border border-1 border-main px-4 mx-4" style="background-color: #f2f4f4">
                    <div class="col-md-12 mx-1">
                        <div class="row d-flex mt-3 mx-0">
                            <div class="mb-3 col-lg-1 col-md-2 col-sm-2 border border-1 border-secondary bg-main">
                                <span class="me-4 fw-semibold text-white">N°:</span>
                                <span class=" text-white">{{ mtdNumber(index) }}</span>
                            </div>
                            <div class="mb-3 col-lg-2 col-md-4 col-sm-4 border border-1 border-secondary">
                                <span class="me-4 fw-semibold">{{ bill.type == 1 ? 'FACTURA' : (bill.type == 2 && type == 'oc' ? 'BOLETA' : 'RECIBO H.') }}:</span>
                                <span class="">{{ bill.number }}</span>
                            </div>
                            <div class="mb-3 col-lg-3 col-md-6 col-sm-6 border border-1 border-secondary">
                                <span class="me-4 fw-semibold">Total:</span>
                                <span class="">{{ bill.money == "SOLES" ? 'S/.' : '$.' }} {{ parseFloat(bill.total).toFixed(2) }}</span>

                            </div>
                            <div class="mb-3 col-lg-3 col-md-6 col-sm-6 border border-1 border-secondary">
                                <span class="me-4 fw-semibold">Fecha:</span>
                                <span class="">{{ bill.date | formatDate}}</span>

                            </div>
                            <div class="mb-3 col-lg-3 col-md-6 col-sm-6 border border-1 border-secondary">
                                <span class="me-4 fw-semibold">Estado:</span>
                                <span class="">{{ bill.state ==1 ? 'Pagado' : (bill.state == 2 ? 'Pagado Parcealmente' : 'Cerrado') }}</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 table-responsive" id="div-tabla-anular">
                        <table id="tb-anular" class="table table-bordered">
                            <caption class="mx-0 px-0"></caption>
                            <thead class="border border border-white text-xs">
                                <tr class="bg-main text-white">
                                    <th scope="col" class="align-middle">N°</th>
                                    <th scope="col" class="align-middle">MONTO PAGADO</th>
                                    <th v-if="type == 'os'" scope="col" class="align-middle">TIPO</th>
                                    <th scope="col" class="align-middle">FECHA</th>
                                    <th scope="col" class="align-middle">USUARIO</th>
                                    <th scope="col" class="align-middle">VOUCHER</th>
                                </tr>
                            </thead>
                            <tbody class="border border-main" style="font-size: 11px">
                                <tr class="tbody-table text-xs" v-for="(pay, index1) in bill.payments" :key="index1">
                                    <td class="align-middle">
                                        {{ index1 + 1 }}
                                    </td>
                                    <td class="align-middle">
                                        {{ pay.amount }}
                                    </td>
                                    <td class="align-middle" v-if="type == 'os'">
                                        {{ pay.type == 1? 'Pago de detraccion' : 'Pago del Total neto'}}
                                    </td>
                                    <td class="align-middle">
                                        {{ pay.date }}
                                    </td>
                                    <td class="align-middle">
                                        {{ pay.user.name }}
                                    </td>
                                    <td class="align-middle">
                                        <div v-if="pay.method_pay == 'BANCO'">
                                            <div class="text-center d-flex justify-content-center">
                                                <a v-if="pay.file" class=" ms-1 btn btn-sm btn-danger px-2 text-white " type="button" @click="openPdf(pay.file)">
                                                    <i class="fa fa-file-pdf" style="font-size: 13px;"></i>
                                                </a>
                                                <a v-else class=" ms-1 btn btn-sm btn-info px-2 text-white " type="button" @click="upload(bill,pay.id,index1)">
                                                    <i class="fas fa-upload" style="font-size: 13px;"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div v-else>
                                            PAGADO EN {{ pay.method_pay }}
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!data.length > 0" class="text-center">
                                    <th scope="col" colspan="5">NO HAY PAGOS REGISTRADOS</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
    <Register :boo_modal="modal.boo_modal" :title="modal.title" :type="type" :id="modal.id" @mtdcloseModal="mtdcloseModal"/>
    
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Register from '@/components/Tesoreria/modals/cModalUpload.vue';

import moment from "moment";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-modals-report",
    components: {
        CModal,
        SweetAlert,
        Register
    },
    data() {
        return {
            swal: null,
            filter: {
                date_start: "",
                date_end: "",
            },
            search: '',
            data: [],
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 5,
            modal:{
                boo_modal: false,
                title: '',
                id:0
            }

        };
    },
    props: {
        title: String,
        type: String,
        boo_modal: Boolean,
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    watch: {
        type: {
            handler(newVal) {
                // Realiza cualquier transformación necesaria en los datos aquí
                this.mtdShowData();
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        },
        selectPage() {
            this.calculateData(5);
        },
    },
    mounted() {
        this.getFirstDayOfMonth();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdSearchFilter() {
            this.calculateData(5);
        },
        mtdShowData: function () {
            if (this.type !== '') {
                this.post({
                        url: this.$store.getters.get__url +
                            "/Tesoreria/" + this.type + "/billPaymentReport",
                        token: this.$store.getters.get__token,
                        params: this.filter,
                    })
                    .then((response) => {
                        this.data = response.data.data;
                        this.calculateData(5);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }

        },
        upload: function(bill, id, index){
            this.modal = {
                boo_modal: true,
                title: 'AGREGAR COMPROBANTE N°' + (index + 1) + ' ' + bill.number,
                id: id,
            };
        },
        mtdcloseModal: function(op){
            this.modal.boo_modal = false;
            if(op == 0){
                this.mtdShowData();
            }
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = lastDayOfMonth.toISOString().split('T')[0];
        },
        mtdclosemodal() {
            this.$emit('mtdCloseModalReporte')
        },
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let number = element.number.toString().toUpperCase();
                    return number.includes(query);
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        openPdf: function (url) {
            //console.log(url);
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 5);
        }

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
};
</script>
