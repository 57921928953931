<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-4 row mx-0">
        <!-- <div class="col-md-12 col-lg-12 col-xs-12 mb-2">
            <label for="name" class="mb-2">Monto inicial</label>
            <div class="input-group">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text"><i class="fa fa-money-bill"></i></span>
                </div>
                <input type="text" class="form-control" name="amount" v-model="amount" placeholder="0.00" @input="validateAmount" />
            </div>
        </div> -->
        <div id="div-caja" class="card w-100 px-0" style="height: auto">
            <div class="card-header text-white bg-main h4">
                <strong>Arqueo</strong>
                <span class="btn btn-sm float-end bg-white color-main" style="font-weight: 1em;">
                    <strong>
                        Caja inicial: S/
                        {{ parseFloat(process.initial).toFixed(2) }}</strong>
                </span>
            </div>
            <div class="card-body" style="height: auto">
                <div class="row d-flex">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <div class="mb-3">
                            <label for="observation" class="form-label"><strong>Observación</strong></label>
                            <textarea v-model="process.observation" :readonly="this.inconforme==false" class="form-control" rows="1" placeholder="Observación" style="resize: none">
                </textarea>
                        </div>
                    </div>
                    <div class="row d-flex" style="justify-content: center">
                        <div class="col-md-12 col-lg-12 col col-sm-12 text-center">
                            <p><strong> TOTAL EFECTIVO </strong></p>
                        </div>
                        <div class="col-md-4 col-lg-4 h-100">
                            <button class="btn btn-sm w-100 bg-main text-white">
                                DEPOSITO: S/. {{ parseFloat(process.deposit).toFixed(2) }}
                            </button>
                        </div>
                        <div class="col-md-4 col-lg-4 h-100">
                            <button class="btn btn-sm w-100 bg-main text-white">
                                RETIROS: S/. {{ parseFloat(process.retirement).toFixed(2) }}
                            </button>
                        </div>
                        <div class="col-md-4 col-lg-4 h-100">
                            <button class="btn btn-sm w-100 bg-main text-white">
                                TOTAL: S/. {{ parseFloat(process.totEfectivo).toFixed(2) }}
                            </button>
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="process.vueltos.length > 0">
                            <table class="w-100">
                                <tbody>
                                    <tr v-for="(item, index) in process.vueltos" :key="index">
                                        <td class="border h-30" width="60%">Concepto de vuelto</td>
                                        <td colspan="2" class="border h-30" width="40%">Monto</td>
                                    </tr>
                                    <tr class="">
                                        <td></td>
                                        <td class="border" style="background: #900052; color: white">
                                            TOTAL
                                        </td>
                                        <td class="border">00.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row d-flex mt-3">
                        <div v-for="(item, index) in process.billetes" :key="index" class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-4">{{ item.label }}</span>
                                <input type="text" readonly class="form-control form-control-sm col-3" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                <span class="input-group-text">{{ item.total }}</span>
                            </div>
                        </div>
                        <div v-for="(item, index) in process.monedas" :key="index + 100" class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-4">{{ item.label }}</span>
                                <input type="text" readonly class="form-control form-control-sm col-3" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                <span class="input-group-text">{{ item.total }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-4"><strong>TOTAL</strong></span>
                                <input align="right" type="text" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" :value="parseFloat(process.totEfectivo).toFixed(2)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-arround">
                    <div class="input-group input-group-sm mb-3 p-2">
                        <span class="input-group-text" style="background: #900052; color: white">Falta</span>
                        <input type="text" v-model="process.falta" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" :placeholder="cpSobranteFaltante < 0 ? cpSobranteFaltante : '0.00'" />
                    </div>
                    <div class="input-group input-group-sm mb-3 p-2">
                        <span class="input-group-text" style="background: #900052; color: white">Sobra</span>
                        <input type="text" v-model="process.sobra" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" :placeholder="cpSobranteFaltante >= 0 ? cpSobranteFaltante : '0.00'" />
                    </div>

                    <div id="btn-inconforme" class="input-group input-group-sm mb-3 p-2 text-center float-center" v-if="process.status != 0">
                        <button class="btn btn-sm w-100" style="background: #900052; color: white">
                            Cerrado {{ process.status == 1 ? "Conforme" : "Inconforme" }}
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-arround">
                    <div class="input-group input-group-sm mb-3 p-2 text-center float-center">
                        <button @click="mtdOpenBead(1)" class="btn btn-sm w-100" style="background: #900052; color: white">
                            Abrir Inconforme
                        </button>
                    </div>
                    <div class="input-group input-group-sm mb-3 p-2 text-center float-center">
                        <button @click="mtdOpenBead(0)" class="btn btn-sm w-100" style="background: #900052; color: white">
                            Abrir Conforme
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdInsert" :disabled="mtdDisableButton">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template> -->
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-tesoreria-cajaFuerte-open",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            amount: "",
            inconforme: false,
            swal: null,
            verif: false,
            process: {
                box: 0,
                bead: 0,
                falta: 0,
                sobra: 0,
                arqueo: [{
                        label: "S/ 200.00x",
                        total: 0,
                        key: "dosCientos",
                        value: 0
                    },
                    {
                        label: "S/ 100.00x",
                        total: 0,
                        key: "unCiento",
                        value: 0
                    },
                    {
                        label: "S/ 50.00x",
                        total: 0,
                        key: "cinuenta",
                        value: 0
                    },
                    {
                        label: "S/ 20.00x",
                        total: 0,
                        key: "veinte",
                        value: 0
                    },
                    {
                        label: "S/ 10.00x",
                        total: 0,
                        key: "diez",
                        value: 0
                    },
                    {
                        label: "S/ 5.00x",
                        total: 0,
                        key: "cinco",
                        value: 0
                    },
                    {
                        label: "S/ 2.00x",
                        total: 0,
                        key: "dos",
                        value: 0
                    },
                    {
                        label: "S/ 1.00x",
                        total: 0,
                        key: "uno",
                        value: 0
                    },
                    {
                        label: "S/ 0.5x",
                        total: 0,
                        key: "cincuentaCentimos",
                        value: 0
                    },
                    {
                        label: "S/ 0.2x",
                        total: 0,
                        key: "veinteCentimos",
                        value: 0
                    },
                    {
                        label: "S/ 0.1x",
                        total: 0,
                        key: "diezCentimos",
                        value: 0
                    },
                ],
                status: 0,
                incomes: [],
                initial: 0,
                vueltos: [],
                totIncome: 0,
                totExpense: 0,
                observation: "",
                totEfectivo: 0,
                totalArqueo: 0,
                totalVueltos: 0,
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        box: Object,
    },
    watch: {
        boo_modal: {
            handler(newVal) {
                if (newVal == true) {
                    this.process = this.box.process;
                    console.log(this.process);
                }
            },
            immediate: true
        }
    },
    computed: {

        mtdDisableButton() {
            return this.amount === "";
        },
        cpSobranteFaltante() {
            let totalMonedasBilletes = 0;
            if (this.boo_modal == true) {
                // Sumar el total de monedas y billetes
                for (const billete of this.process.billetes) {
                    totalMonedasBilletes += parseFloat(billete.total);
                }

                for (const moneda of this.process.monedas) {
                    totalMonedasBilletes += parseFloat(moneda.total);
                }
            }

            // Calcular la diferencia
            let dif = totalMonedasBilletes - parseFloat(this.process.totEfectivo);

            return parseFloat(dif).toFixed(2);
        },
    },

    methods: {

        mtdclosemodal() {
            this.amount = "";
            this.$emit('mtdcloseModal');
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.amount = sanitizedValue;
        },
        mtdOpenBead: function (status) {
            
            if (status == 1 && !this.process.observation) {
                this.$refs.SweetAlert.showWarning("Anote una observacion");
                this.inconforme = true;
            } else {
                this.$emit('mtdOpenBead',this.process);
            }
        }

    },
};
</script>

<style scoped>
.text-alert {
    font-size: 9px;
}
</style>
