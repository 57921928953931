<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput1" class="form-label ">Codigo:</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="codigo" v-model="dx.codigo">
        </div>
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput2" class="form-label ">Nombre:</label>
            <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="Nombre" v-model="dx.name">
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="mtdadddx" :disabled="mtddisable">
            Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            dx: {
                codigo: '',
                name: ''
            },
            Cies: {},
            swal: null,
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    computed: {
        mtddisable: function () {
            return !this.dx.name
        }

    },
    props: {
        title: String,
        boo_modal: Boolean,

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodaldx')
        },
        mtdadddx: function () {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/cies/store",
                token: this.$store.getters.get__token,
                params: this.dx
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.Cies = response.data;
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('mtdcie', this.Cies);
                    this.mtdclosemodal()
                    this.dx = {
                        codigo: '',
                        name: ''
                    }
                }
            });
        }
    },
}
</script>
