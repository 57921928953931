<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
    <div class="dropdown-header" @click="toggleDropdown()">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='HistoriaSop'">
                <Hc :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></Hc>
            </div>
            <div id="myTabContentHijo" v-if="component=='Kardex'">
                <Kardex></Kardex>
            </div>
            <div id="myTabContentHijo" v-if="component=='Emedica'">
                <Emedica></Emedica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Rp'">
                <Rp></Rp>
            </div>
            <div id="myTabContentHijo" v-if="component=='Bhe'">
                <Bhe></Bhe>
            </div>
            <div id="myTabContentHijo" v-if="component=='Mhemodinamico'">
                <Mhemodinamico></Mhemodinamico>
            </div>
            <div id="myTabContentHijo" v-if="component=='Ordensop'">
                <Ordensop></Ordensop>
            </div>
            <div id="myTabContentHijo" v-if="component=='preanestecia'">
                <Preanestecia></Preanestecia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Rxqx'">
                <Rxqx></Rxqx>
            </div>
            <div id="myTabContentHijo" v-if="component=='Rxneumologico'">
                <Rxneumologico></Rxneumologico>
            </div>
            <div id="myTabContentHijo" v-if="component=='Ci'">
                <Ci></Ci>
            </div>
            <div id="myTabContentHijo" v-if="component=='Hojaanestecia'">
                <Hojaanestecia></Hojaanestecia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Postanestecia'">
                <Postanestecia></Postanestecia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Informeoperatorio'">
                <Informeoperatorio></Informeoperatorio>
            </div>
            <div id="myTabContentHijo" v-if="component=='Listaverificacion'">
                <Listaverificacion></Listaverificacion>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import Hc from '@/components/Asistencial/Sop/Historia/Hcsop.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue';
import Emedica from '@/components/Asistencial/Sop/Historia/Emedica.vue';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Bhe from '@/components/Asistencial/Sop/Historia/Bhe.vue';
import Mhemodinamico from '@/components/Asistencial/Sop/Historia/Mhemodinamico.vue';
import Ordensop from '@/components/Asistencial/Sop/Historia/Ordendesop.vue';
import Preanestecia from '@/components/Asistencial/Sop/Historia/Preanestecia.vue';
import Rxqx from '@/components/Asistencial/Sop/Historia/Rxqx.vue';
import Rxneumologico from '@/components/Asistencial/Sop/Historia/Rxneumologico.vue';
import Ci from '@/components/Asistencial/Sop/Historia/Ci.vue';
import Hojaanestecia from '@/components/Asistencial/Sop/Historia/Hojaanestecia.vue';
import Postanestecia from '@/components/Asistencial/Sop/Historia/Postanestecia.vue';
import Informeoperatorio from '@/components/Asistencial/Sop/Historia/Informeoperatorio.vue';
import Listaverificacion from '@/components/Asistencial/Sop/Historia/Listaverificacion.vue';

export default {
    name: 'dropdown-component-Sop',
    props: {
        title: String,
        component: String,
        viewMedico: Number,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Hc,
        Kardex,
        Emedica,
        Rp,
        Bhe,
        Mhemodinamico,
        Ordensop,
        Preanestecia,
        Rxqx,
        Rxneumologico,
        Ci,
        Hojaanestecia,
        Postanestecia,
        Informeoperatorio,
        Listaverificacion
    },
    data: () => ({
        dropdown: false
    }),
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },
    }
}
</script>
