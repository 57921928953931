<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'md'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xs-6 text-center">
          <label for="">Stock Mínimo</label>
          <div class="input-group">
              <input type="text" placeholder="0" class="form-control" v-model="item.minimum_stock" @keydown="filterKey" />
          </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xs-6 text-center">
          <label for="">Stock Máximo</label>
          <div class="input-group">
              <input type="text" placeholder="0" class="form-control" v-model="item.max_stock" @keydown="filterKey" />
          </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose"  class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button @click="mtdCommitCall" class="btn btn-success btn-sm">
        Registrar Stock
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import { mapActions } from "vuex";

export default {
  name: "c-logistica-modal-stockmin",
  components: {
    CModal,
  },
  data() {
    return {
      /* item :{
        id: null,
        name: "",
        stock_min: "",
        stock_max: ""
      } */
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: Object
  },
  computed: {},
  methods: {
    mtdClose() {
      this.$emit("closeModalStock");
    },
    mtdCommitCall: function(){
      this.$emit('mtdRegisterStock',this.item.minimum_stock,this.item.max_stock);
    },
    filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
  },
 
};
</script>
  <style scoped>
  #content-timeline {
    border: 1px solid #aaa;
    height: 100px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #content-timeline::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #content-timeline::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-button:increment,
  #content-timeline::-webkit-scrollbar-button {
    display: none;
  }
  
  #content-timeline::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #content-timeline::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .header-main {
    background-color: #900052;
  }
  
  @media (max-width: 992px) {
    .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
    }
  
    #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
    }
  }
  
  .text-doctor {
    font-weight: bold;
    font-size: 14px;
    color: #900052;
  }
  .selected {
    background-color: yellow;
  }
  
  .table-th-state {
    width: 20% !important;
  }
  
  #tb-lote th {
    font-size: 9.5px;
  }
  
  #tb-lote td {
    font-size: 11px;
  }
  
  .error-message {
    color: red;
    font-size: 9px;
  }
  
  /* X a la derecha*/
  .header-container {
    width: 100%;
    margin-right: 10px;
  }
  
  .close-button {
    border: none;
    background-color: transparent;
  }
  
  .modal-body {
    margin-right: 20px;
  }
  
  @media (max-width: 767px) {
    #table-body {
      font-size: 10px !important;
    }
  }
  </style>
  