<template>
<div>
    <div class="container ">
        <div id="btn-open-caja" class="d-flex justify-content-end pt-2 mt-2">
            <div class="btn-group btn-home">
                <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                    <i class="fas fa-recycle"></i> Cambiar de Sede
                </button>
            </div>
        </div>
        <div class="row px-3" id="div_conte2">
            <div id="div_conte" class="col-md-10 mx-auto">
                <div class="bg-secondary text-white bg-opacity-10">
                    <div class="text-center py-3 px-5 btn-home" id="scroll-div">
                        <div class="row">
                            <button class="border-0  rounded-3 col m-2 p-2 text-white" @click="changePage('pago')">
                                <strong>PAGOS</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br /><br />
</div>
</template>

<script>
export default {
    name: 'homeMaintenance',
    data() {
        return {
            page: null
        }
    },
    methods: {
        changePage(page) {
            this.$emit('changePage', page)
        },
        mtdBackCampus: function () {
            this.$emit('mtdBackCampus')
        },
    }
}
</script>
<style>
    @media (max-width: 415px) {
        #scroll-div {
            height: 140px;
        }
    }
</style>