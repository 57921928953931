<template>
    <div class="container pt-3">
        <div class=" row mb-4">
            <!--COLUMNA 1-->
            <div class="col-xl-6 col-md-12 pe-xl-5">
                <!--PARTE 1-->
                <div class="row mb-3">
                    <div class="col-md-4 div-title fw-bold d-flex align-items-center">
                        <span>CIRUGIA PROGRAMADA</span>
                    </div>
                    <div class="col-md-8 py-2 border">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                </div>
                <!--PARTE 2-->
                <div class="row">
                    <div class="col-md-7">
                        <div class="row div-title fw-bold py-1 justify-content-center">
                            <span>SIGNOS Y SINTOMAS PRINCIPALES</span>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div v-for="(item, index) in checklist" :key="index" class="mb-0 mt-0">
                                    <div class="form-check form-check-reverse">
                                        <input class="form-check-input align-middle" type="checkbox" value=""
                                            :id="'defaultCheck' + index" />
                                        <div class="w-75 text-start">
                                            <label class="form-check-label text-content mb-0" :for="'defaultCheck' + index">
                                                <!-- Añadida clase mb-0 para eliminar el espacio inferior -->
                                                {{ item }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 mb-2">
                                <div class="row">
                                    <div v-for="(label, field) in formFields" :key="field">
                                        <div class="d-flex flex-column flex-sm-row pt-1">
                                            <label class="text-content w-100 text-start mb-sm-0">{{ label }}</label>
                                            <input type="text" class="form-control form-control-input" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 ps-md-3">
                        <div class="row div-title fw-bold py-1 justify-content-center">
                            <span>EXAMEN FISICO</span>
                        </div>
                        <div class="row">
                            <div class="col-6" v-for="(item, field) in formFieldsexamen" :key="field">
                                <div class="d-flex flex-column flex-sm-row pt-1">
                                    <label class="text-content w-100 text-start mb-sm-0">{{ item }}</label>
                                    <input type="text" class="form-control form-control-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--PARTE 3-->
                <div class="row">
                    <div class="div-title fw-bold py-1 justify-content-center mt-2">
                        <span>CABEZA</span>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row" v-for="(item, index) in itemsCabeza" :key="index">
                                <div class="form-group d-flex flex-column flex-sm-row pt-0">
                                    <div class="col-xl-6 col-md-5 text-start">
                                        <label :for="item" class="form-label text-content">{{ item
                                        }}</label>
                                    </div>

                                    <div class="form-check form-check-reverse col-3">
                                        <input class="form-check-input" type="checkbox" :name="item" :id="'si' + index"
                                            value="si">
                                        <label class="form-check-label text-content" :for="'si' + index">Sí</label>
                                    </div>

                                    <div class="form-check form-check-reverse col-3">
                                        <input class="form-check-input" type="checkbox" :name="item" :id="'no' + index"
                                            value="no">
                                        <label class="form-check-label text-content ms-2" :for="'no' + index">No</label>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 pe-0">
                            <div class="row" v-for="(item, index1) in itemsCabezaDos" :key="index1">
                                <div class="form-group d-flex flex-column flex-sm-row">
                                    <div class="col-xl-6 col-md-4 text-start">
                                        <label :for="item" class="form-label text-content pt-1 text-start">{{ item
                                        }}</label>
                                    </div>

                                    <div class="form-check form-check-reverse col-xl-3 col-md-2">
                                        <input class="form-check-input" type="checkbox" :name="item" :id="'si1' + index1"
                                            value="si">
                                        <label class="form-check-label text-content" :for="'si1' + index1">Sí</label>
                                    </div>

                                    <div class="form-check form-check-reverse col-xl-3 col-md-2">
                                        <input class="form-check-input" type="checkbox" :name="item" :id="'no1' + index1"
                                            value="no">
                                        <label class="form-check-label text-content ms-2" :for="'no1' + index1">No</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-sm-row">
                                <label class="form-label text-content me-4 pt-1 text-start">Apertura de boca</label>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox11" value="option1">
                                    <label class="form-check-label text-content" for="inlineCheckbox11">Normal</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox21" value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckbox21">Anormal</label>
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-sm-row py-0">
                                <label class="form-label text-content w-100 pt-1 text-start">Úvula visible</label>
                                <div class="form-check form-check-inline form-check-reverse me-1">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label text-content" for="inlineCheckbox1">No</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse me-1">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckbox2">Parcial</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse me-1">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckbox2">Compleja</label>
                                </div>
                            </div>
                            <div class="form-group d-flex flex-column flex-sm-row py-0">
                                <label class="form-label text-content w-100 pt-1 text-start">Prob.de vía aéres Alta
                                    dificil</label>
                                <div class="form-check form-check-inline form-check-reverse me-1">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                    <label class="form-check-label text-content" for="inlineCheckbox1">Alta</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse me-1">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckbox2">Media</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse me-1">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckbox2">Baja</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!--PARTE 4-->
                <div class="row">
                    <div class="div-title fw-bold py-1 justify-content-center">
                        <span>TORÁX</span>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group d-flex flex-column flex-sm-row">
                                <label class="form-label text-content pt-1 pe-sm-4 text-start">Ruidos cardiacos</label>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckboxtorax1"
                                        value="option1">
                                    <label class="form-check-label text-content" for="inlineCheckboxtorax1">Rítmicos</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckboxtorax2"
                                        value="option2">
                                    <label class="form-check-label text-content"
                                        for="inlineCheckboxtorax2">Aritmicos</label>
                                </div>
                            </div>

                            <div class="form-group d-flex flex-column flex-sm-row">
                                <label class="form-label text-content pt-1 me-sm-4 text-start">Auscultación Pulmonar</label>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox11" value="option1">
                                    <label class="form-check-label text-content" for="inlineCheckbox11">Normal</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox21" value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckbox21">Anormal</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 ps-xl-0">
                            <div class="form-group d-flex flex-column flex-sm-row">
                                <label class="form-label text-content me-sm-4 pt-1 text-start">Soplo</label>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckboxtorax31"
                                        value="option1">
                                    <label class="form-check-label text-content" for="inlineCheckboxtorax31">Si</label>
                                </div>
                                <div class="form-check form-check-inline form-check-reverse">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckboxtorax32"
                                        value="option2">
                                    <label class="form-check-label text-content" for="inlineCheckboxtorax32">No</label>
                                </div>
                            </div>
                            <div class="w-100 mb-2">
                                <input type="text" class="form-control form-control-input" />
                            </div>
                        </div>
                    </div>

                </div>
                <!--PARTE 5-->
                <div class="row mb-2">
                    <div class="py-1 div-title fw-bold justify-content-center">
                        <span>ABDOMEN</span>
                    </div>
                    <div class="input-group px-0">
                        <textarea class="form-control text-content" id="div-textarea" rows="2"></textarea>
                    </div>
                </div>
            </div>
            <!--COLUMNA 2-->
            <div class="col-xl-6 col-md-12">
                <!--PARTE 1-->
                <div class="row mb-2">
                    <div class="py-1 div-title fw-bold justify-content-center">
                        <span>EXTREMIDADES</span>
                    </div>
                    <div class="input-group px-0">
                        <textarea class="form-control text-content" id="div-textarea" rows="2"></textarea>
                    </div>
                </div>
                <!--PARTE 2-->
                <div class="row mb-3">
                    <div class="col-xl-5 col-sm-12">
                        <div class="form-group d-flex flex-column flex-sm-row">
                            <label class="form-label text-content pt-1 pe-4 text-start">Acceso venoso</label>
                            <div class="form-check form-check-inline form-check-reverse">
                                <input class="form-check-input" type="checkbox" id="accesoVenosoSi" value="si">
                                <label class="form-check-label text-content" for="accesoVenosoSi">Si</label>
                            </div>
                            <div class="form-check form-check-inline form-check-reverse">
                                <input class="form-check-input" type="checkbox" id="accesoVenosoNo" value="no">
                                <label class="form-check-label text-content" for="accesoVenosoNo">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-sm-12">
                        <div class="form-group d-flex flex-column flex-sm-row">
                            <label class="form-label text-content pt-1 pe-4 text-start">Pulsos Periféricos</label>
                            <div class="form-check form-check-inline form-check-reverse">
                                <input class="form-check-input" type="checkbox" id="pulsosPerifericosSi" value="si">
                                <label class="form-check-label text-content" for="pulsosPerifericosSi">Si</label>
                            </div>
                            <div class="form-check form-check-inline form-check-reverse">
                                <input class="form-check-input" type="checkbox" id="pulsosPerifericosNo" value="no">
                                <label class="form-check-label text-content" for="pulsosPerifericosNo">No</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!--PARTE 3-->
                <div class="row mb-1">
                    <div class="py-1 div-title fw-bold justify-content-center">
                        <span>OTROS HALLAZGOS</span>
                    </div>
                    <div class="input-group px-0">
                        <textarea class="form-control text-content" id="div-textarea" rows="2"></textarea>
                    </div>
                </div>
                <!--PARTE 4-->
                <div class="row">
                    <div class="col-md-6">
                        <div class="row div-title fw-bold py-1 justify-content-center">
                            <span>LABORATORIO (resultados)</span>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div v-for="(item, index) in checkListLabUno" :key="index" class="mb-0 mt-0">
                                    <div class="form-check form-check-reverse">
                                        <input class="form-check-input align-middle" type="checkbox" value=""
                                            :id="'defaultCheckLab' + index" />
                                        <div class="w-75 text-start">
                                            <label class="form-check-label text-content mb-0"
                                                :for="'defaultCheckLab' + index">
                                                {{ item }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div v-for="(item, index) in checkListLabDos" :key="index" class="mb-0 mt-0">
                                    <div class="form-check form-check-reverse">
                                        <input class="form-check-input align-middle" type="checkbox" value=""
                                            :id="'defaultCheckL' + index" />
                                        <div class="w-75 text-start">
                                            <label class="form-check-label text-content mb-0"
                                                :for="'defaultCheckL' + index">
                                                {{ item }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 ps-md-4">
                        <div class="row div-title fw-bold py-1 justify-content-center">
                            <span>EXAMEN APOYO DIAGNÓSTICO (Resultados)</span>
                        </div>
                        <div>
                            <div class="row text-content text-start">
                                <span>Observaciones Rx</span>
                            </div>
                            <div class="row pt-1 mb-2">
                                <div class="input-group">
                                    <textarea class="form-control text-content" rows="2"></textarea>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row text-content text-start">
                                <span>Observaciones EKG</span>
                            </div>
                            <div class="row pt-1 mb-3">
                                <div class="input-group">
                                    <textarea class="form-control text-content" rows="2"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--PARTE 5-->
                <div class="row mb-2">
                    <div class="py-1 div-title fw-bold justify-content-center">
                        <span>DIÁGNOSTICO</span>
                    </div>
                    <div class="row align-items-center" v-for="index in 2" :key="index">
                        <div class="col-1">
                            <div class="d-flex flex-sm-row flex-column"><label for="inputPassword6"
                                    class="col-form-label text-content">1</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <input type="text" id="inputPassword6" class="form-control form-control-input"
                                aria-describedby="passwordHelpInline">
                        </div>
                        <div class="col-1">
                            <label for="cie" class="col-form-label text-content">CIE</label>
                        </div>
                        <div class="col-5">
                            <input type="text" id="cie" class="form-control form-control-input"
                                aria-describedby="passwordHelpInline">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1">
                            <div class="d-flex flex-sm-row flex-column"><label for="inputPassword6"
                                    class="col-form-label text-content">ASA</label>
                            </div>
                        </div>
                        <div class="col-3">
                            <input type="text" id="inputPassword6" class="form-control form-control-input"
                                aria-describedby="passwordHelpInline">
                        </div>
                        <div class="col-3">
                            <label for="cie" class="col-form-label text-content">Tipo de Anestecia Prevista</label>
                        </div>
                        <div class="col-5">
                            <input type="text" id="cie" class="form-control form-control-input"
                                aria-describedby="passwordHelpInline">
                        </div>
                    </div>
                </div>
                <!--PARTE 6-->
                <div class="row mb-2">
                    <div class="py-1 div-title fw-bold justify-content-center">
                        <span>RECOMENDACIONES</span>
                    </div>
                    <div class="input-group px-0">
                        <textarea class="form-control text-content" id="div-textarea" rows="2"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'c-asistencial-sop-historia-preanestecia',
    data() {
        return {
            checklist: [
                'Disnea', 'Agnina', 'Palpitaciones', 'Tos', 'Glasgow'
            ],
            formFields: ['Grado de Disnea', 'Grado de Angina', 'Precipitado por', 'Periocidad de Tos', 'Otros sintomas'],
            formFieldsexamen: ['PA', 'PESO', 'FC', 'Talla', 'FR', 'IMC', 'Sat02%', 'Posicion'],
            itemsCabeza: ['Lentes de contacto', 'Cuello normal', 'Masas', 'Pulsos', 'Prótesis', 'Removible'],
            itemsCabezaDos: ['Dientes flojos', 'Ausencia de dientes'],
            checkListLabUno: ['Hemoglobina', 'Hematrocito', 'Creatinina', 'TP', 'INR'],
            checkListLabDos: ['Glicemia', 'Urea', 'Albumina', 'TTP', 'Otros'],
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>