<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="w-100">
            <div class="card w-100">
                <div class="card-header text-center bg-main text-white h4">
                    <strong>MOVIMIENTOS CAJA</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex">
                        <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-2">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text col-lg-4 col-md-3">Desde</span>
                                <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.date_start" @change="mtdGetData" />
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 py-2">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text col-lg-4 col-md-3">Hasta</span>
                                <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row p-2">
                                <div class="col-12">
                                    <div class="row d-flex card-filter">
                                        <div class="col-md-3 col-sm-12">
                                            <span>Tipo</span>
                                        </div>
                                        <div class="col-md-9 col-sm-12 d-flex align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox1" value="1" v-model="filter.type" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineCheckbox1">Depósito</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox2" value="2" v-model="filter.type" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineCheckbox2">Retiro</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-md-12 col-lg-12 col-sm-12 py-2">
                            <div class="row d-flex">
                                <div class="col-12 col-md-4 mb-2 mb-md-0">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text">Depósito S/.</span>
                                        <input type="input" aria-label="shift_date" readonly class="form-control form-control-sm" v-model="bead.deposit" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 mb-2 mb-md-0">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text">Retiro S/.</span>
                                        <input type="input" aria-label="shift_date" readonly class="form-control form-control-sm" v-model="bead.retirement" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text">Saldo S/.</span>
                                        <input type="input" aria-label="shift_date" readonly class="form-control form-control-sm" id="bead" v-model="bead.final" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PAGINACION -->
                        <div class="col-md-12 py-2">
                            <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-end  mb-2 ml-5">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!--tabla-->
                        <div class="col-md-12 py-2 table-container table-responsive" id="content-timelinetable">
                            <table class="table table-borderless " style="font-size: 12px;">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main ">
                                    <tr class="text-white text-center tr-table text-xs">
                                        <th scope="col" class="border border-white">
                                            <div>N°</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>FECHA</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>TIPO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>MONTO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>DESCRIPCION</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>MOTIVO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>RESPONSABLE O BENEFICIARIO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>PDF</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in cpData" :key="index" class="border-table">
                                        <td>{{ mtdNumber(index) }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>
                                            <span class="badge w-100" :class="item.type == 'DEPOSITO' ? 'text-bg-success' : 'text-bg-danger'">{{item.type}}</span>
                                        </td>
                                        <td class="text-center">{{ item.amount }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.motive }}</td>
                                        <td>{{ item.type  == 'DEPOSITO' ? item.user : item.person }}</td>
                                        <td>
                                            <button @click="mtdViewPdfCaja(item.id)" class="btn btn-sm btn-primary text-white">
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="border-table text-center" v-if="cpData.length == 0">
                                        <td colspan="10">No se encontraron resultados</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            filter: {
                type: ["1", "2"],
                date_start: null,
                date_end: null
            },
            search: "",
            data: [],
            dataShow: [],
            bead: {},
            deposit: 0,
            retirement: 0,
            /* paginacion */
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 3,
        };
    },
    created() {
        this.getFirstDayOfMonth();
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            if (this.filter.type.length !== 0) {
                this.post({
                        url: this.$store.getters.get__url + "/Tesoreria/reportMovements",
                        token: this.$store.getters.get__token,
                        params: this.filter

                    })
                    .then((response) => {
                        this.data = response.data.data;
                        this.bead = response.data.bead;
                        this.calculateData(10);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            } else {
                this.data = [];
                this.dataShow = [];
            }
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                const changepage = this.selectPage;
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let number = element.number.toString().toUpperCase();
                    return number.includes(query);
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdViewPdfCaja: function (id) {
            window.open(
                this.$store.getters.get__url +
                "/Tesoreria/viewpdf/cajaFuerte/" +
                id,
                "_blank"
            );
        },
        /** pagination */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        changePage: function (page) {

            this.page = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    },
};
</script>

<style scoped>
.card-filter {
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid rgb(209, 206, 206);
    border-radius: 5px;
}

li>a {
    font-size: 13px;
}

.border-table>td {
    border: 1px solid #900052;
}
</style>
