var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3"},[_c('div',{staticClass:"row",attrs:{"id":"div-content-day"}},[_c('div',{staticClass:"col-xl-2 col-lg-12 pt-2",attrs:{"id":"div-cont-col"}},_vm._l((_vm.itemsNota),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 div-text text-start"},[_vm._v(_vm._s(item))]),_vm._m(0,true)])])])])}),0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"row mt-2",attrs:{"id":"div-content-night"}},[_c('div',{staticClass:"col-xl-2 col-lg-12 pt-2",attrs:{"id":"div-cont-col"}},_vm._l((_vm.itemsNota),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 div-text text-start"},[_vm._v(_vm._s(item))]),_vm._m(3,true)])])])])}),0),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control form-control-input",attrs:{"type":"text","autocomplete":"off"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-5 col-lg-12",attrs:{"id":"div-cont-col"}},[_c('div',{staticClass:"row pt-3"},[_c('div',{staticClass:"input-group-sm col-md-4 col-sm-6 mb-3"},[_c('input',{staticClass:"form-control form-control-input",attrs:{"type":"date","autocomplete":"off"}})]),_c('div',{staticClass:"input-group-sm col-md-6 col-sm-6 justify-content-end mb-3"},[_c('input',{staticClass:"form-control form-control-input text-content",attrs:{"type":"text","placeholder":"Lic.","autocomplete":"off"}})])]),_c('div',{staticClass:"row pt-4 mb-3"},[_c('div',{staticClass:"input-group"},[_c('textarea',{staticClass:"form-control text-content",attrs:{"rows":"5"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-5 col-lg-12"},[_c('div',{staticClass:"text-row text-start pt-2"},[_c('p',{staticClass:"fw-bold"},[_vm._v("Hemograma 01/01/23")])]),_c('div',{staticClass:"input-group pt-2"},[_c('textarea',{staticClass:"form-control text-content",attrs:{"rows":"3"}})]),_c('div',{staticClass:"row pt-xl-2"},[_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('label',{staticClass:"form-check-label text-content"},[_vm._v("Encaras: ")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"inlineCheckbox1","value":"option1"}}),_c('label',{staticClass:"form-check-label text-content",attrs:{"for":"inlineCheckbox1"}},[_vm._v("Si")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"inlineCheckbox2","value":"option2"}}),_c('label',{staticClass:"form-check-label text-content",attrs:{"for":"inlineCheckbox2"}},[_vm._v("No")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('input',{staticClass:"form-control form-control-input",attrs:{"type":"text","autocomplete":"off"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-5 col-lg-12",attrs:{"id":"div-cont-col"}},[_c('div',{staticClass:"row pt-3"},[_c('div',{staticClass:"input-group-sm col-md-4 col-sm-6 mb-3"},[_c('input',{staticClass:"form-control form-control-input",attrs:{"type":"date","autocomplete":"off"}})]),_c('div',{staticClass:"input-group-sm col-md-6 col-sm-6 justify-content-end mb-3"},[_c('input',{staticClass:"form-control form-control-input text-content",attrs:{"type":"text","placeholder":"Lic.","autocomplete":"off"}})])]),_c('div',{staticClass:"row pt-4 mb-3"},[_c('div',{staticClass:"input-group"},[_c('textarea',{staticClass:"form-control text-content",attrs:{"rows":"5"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-5 col-lg-12"},[_c('div',{staticClass:"text-row text-start pt-2"},[_c('p',{staticClass:"fw-bold"},[_vm._v("Hemograma 01/01/23")])]),_c('div',{staticClass:"input-group pt-2"},[_c('textarea',{staticClass:"form-control text-content",attrs:{"rows":"3"}})]),_c('div',{staticClass:"row pt-xl-2"},[_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('label',{staticClass:"form-check-label text-content"},[_vm._v("Encaras: ")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"inlineCheckbox1","value":"option1"}}),_c('label',{staticClass:"form-check-label text-content",attrs:{"for":"inlineCheckbox1"}},[_vm._v("Si")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","id":"inlineCheckbox2","value":"option2"}}),_c('label',{staticClass:"form-check-label text-content",attrs:{"for":"inlineCheckbox2"}},[_vm._v("No")])])])])])
}]

export { render, staticRenderFns }