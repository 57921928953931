<template>
<div>

    <div class="card-body">

        <div class="row">
            <div class="col-md-12 py-3" style="color: #900052; text-align: center; position: relative;">
                <div id="floorSelect" class="d-flex justify-content-evenly">
                    <ul class="nav nav-pills">
                        <li v-for="(item, index) in dataFloor" :key="index" class="nav-item" @click="mtdSelectGroup(item.id)">
                            <a :class="tab == item.id ? 'nav-link active' : 'nav-link'" aria-current="page" href="javascript:void(0)">{{ item.name }}</a>
                        </li>
                    </ul>
                </div>
                <div class="superior-derecha">
                    <span class="libre mt-2"></span> Libre &nbsp;
                    <span class="reservado mt-2"></span> Reservado &nbsp;
                    <span class="ocupado mt-2"></span> Ocupado &nbsp;
                    <span class="limpieza mt-2"></span> En Limpieza
                </div>
            </div>
        </div>

        <div class="row tab-content pb-3" id="camas" v-if="tab != 0">
            <div class="tab-pane fade show active" v-bind:id="tab + '-tab-pane'" role="tabpanel" v-bind:arialabeledby="tab + '-tab'" tabindex="0">
                <div align="center" class="row m-2 p-2 d-flex justify-content-center">
                    <div class="card m-3 p-2" v-for="(itemg, index) in dataBedGroupsD" :key="index" :style="'border: 2px solid #900052; max-width: 400px; max-height: ' +
                180 * itemg.beds.length+'px'" v-if="itemg.beds.length > 0">
                        <div v-for="(itemd, indexb) in itemg.beds" :key="indexb">
                            <div class="row g-0 align-items-center"  >
                                <div class="col-md-7">
                                    <img class="img-fluid rounded-start" src="../../../assets/cama_sin_fondo.png" width="180px" alt="" />
                                </div>
                                <div class="col-md-5" >
                                    <div class="card-body" >
                                        <div v-if="itemd.status == 4 ">
                                            <span class="room card-text h5 circle p-2 text-white" style="background: orange" >{{ itemd.name }}</span>
                                        </div>
                                        <div v-else-if="itemd.status == 2">
                                            <span class="room card-text h5 circle p-2 text-white" style="background: red" @click="mtdGetDataMedicalAct(itemd.patient_id, itemd.reservation_id, itemd.hospitalization_id, itemd.medical_act_id , itemd.status_history )">{{ itemd.name }}</span>
                                        </div>
                                        <div v-else-if="itemd.status == 3">
                                            <span class="card-text h5 circle p-2 text-white" style="background: blue">{{ itemd.name }}</span>
                                        </div>
                                        <div v-else>
                                            <span class="card-text h5 circle p-2 text-white" style="background: green">{{ itemd.name }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <span class="text-paciente">{{ itemd.patient }}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    name: 'c-hospitalizacion-cola',
    data() {
        return {
            tab: 0,
            dataFloor: [],
            dataBedGroupsD: [],
            verifSelectBed: false,
            oldPosSelectBed: [],
            mostrar: false,
            clinica_history: {
                id: null,
                user_id: null,
                biological_function: [{
                        id: 1,
                        label: "Apetito",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "Sed",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "Orina",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Heces",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Sueño",
                        value: ''
                    },

                ],
                physical_exam: [{
                        id: 1,
                        label: "Estado general",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "SNC",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "Respiratorio",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Tórax Cv Hemo",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Abdominal",
                        value: ''
                    },
                    {
                        id: 6,
                        label: "Renal",
                        value: ''
                    },
                    {
                        id: 7,
                        label: "Otros",
                        value: ''
                    }
                ],
                lab_question: '',
                symploms: [{
                        id: 1,
                        label: "Tos sin flema",
                        state: false
                    },
                    {
                        id: 2,
                        label: "Tos con flema",
                        state: false
                    },
                    {
                        id: 3,
                        label: "Moco",
                        state: false
                    },
                    {
                        id: 4,
                        label: "Dolor de pecho",
                        state: false
                    },
                    {
                        id: 5,
                        label: "Dolor de cabeza",
                        state: false
                    },
                    {
                        id: 6,
                        label: "Dolor de espalda",
                        state: false
                    },
                    {
                        id: 7,
                        label: "Nauseas / vómitos x/día",
                        state: false
                    },
                    {
                        id: 8,
                        label: "Diarrea x/día",
                        state: false
                    },
                    {
                        id: 9,
                        label: "Otros",
                        state: false,
                        value: ''
                    }
                ],
                vital_sings: [{
                        id: 1,
                        label: "FC",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "T (°C)",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "FR",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "SATO2",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "HGT",
                        value: ''
                    },
                    {
                        id: 6,
                        label: "FIO 2",
                        value: ''
                    },
                    {
                        id: 7,
                        label: "CAS",
                        value: ''
                    },
                    {
                        id: 8,
                        label: "PAS",
                        value: ''
                    },
                    {
                        id: 9,
                        label: "PAD",
                        value: ''
                    }
                ],
                fe: '',
                fi: '',
                course: '',
                story: '',
                another_file: '',
                diagnostics: [],
                plan: [],
                recipe: [],
                recomendations: '',
                physical_exam_text: '',
                background: [{
                        id: 1,
                        label: "DM",
                        state: false,
                        value: ''
                    },
                    {
                        id: 2,
                        label: "AR",
                        state: false,
                        value: ''
                    },
                    {
                        id: 3,
                        label: "HTA",
                        state: false,
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Otro",
                        state: false,
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Alergias",
                        state: false,
                        value: ''
                    },
                    {
                        id: 6,
                        label: "Cirugías",
                        state: false,
                        value: ''
                    },
                    {
                        id: 7,
                        label: "Medicamentos Frecuentes",
                        state: false,
                        value: ''
                    }
                ],
                patient_id: null,
                model_id: null,
                medical_act_id: null,
                dieta:'',
                insuline:'',
                grupo:'',
                factor:''
            },
            patient_id: null,
            reservation_id: null,
            hospitalization_id: null,
            medical_act_id: null
        }
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/admision/floor/" + this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataFloor = response.floors;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdGetDataMedicalAct: function (patient_id, reservation_id, hospitalization_id, medical_act_id = 0, status) {
            this.patient_id = patient_id;
            this.reservation_id = reservation_id;
            this.hospitalization_id = hospitalization_id;
            this.medical_act_id = medical_act_id;
            if ( status == 3 ) {
                // this.mtdNewAct(this.patient_id, this.reservation_id, this.hospitalization_id, this.medical_act_id);
                this.mtdViewAct(this.patient_id, this.medical_act_id);
            } else {
                this.mtdNewAct(this.patient_id, this.reservation_id, this.hospitalization_id, this.medical_act_id);
            }

        },
        mtdNewAct: function (patient_id, reservation_id, hospitalization_id, medical_act_id) {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/add",
                    token: this.$store.getters.get__token,
                    params: {
                        clinica_history: this.clinica_history,
                        patient_id: this.patient_id,
                        reservation_id: this.reservation_id,
                        hospitalization_id: this.hospitalization_id,
                        medical_act_id: this.medical_act_id
                    }
                })
                .then((response) => {
                    this.data = response.data;
                    this.patient_id = null;
                    this.$emit('mtdHistorial', {
                        patient: this.data
                    })
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdViewAct: function (patient_id, medical_act_id) {
             this.get({
                    url: this.$store.getters.get__url + "/Hospitalization/history/"+ medical_act_id,
                    token: this.$store.getters.get__token,   
                })
                .then((response) => {
                     let newhistory = response.data;
                    this.$emit('mtdHistorialtable', {
                        patient:newhistory 
                    })
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdSelectGroup: function (id) {
            this.post({
                    url: this.$store.getters.get__url + "/admision/bedgroup/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        floor_id: id,
                    },
                })
                .then((response) => {
                    this.dataBedGroupsD = response.bedgroups_details;
                    this.tab = id;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdBehindGroup: function (data) {
            this.post({
                    url: this.$store.getters.get__url + "/admision/bedgroup/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        floor_id: data[2],
                    },
                })
                .then((response) => {
                    let dataBedGroupsD;
                    dataBedGroupsD = response.data.bedgroups_details;
                    dataBedGroupsD[data[0]].beds[data[1]].status = 1;
                })
        },
        mtdReserveHour: function (bed, pos1, pos2) { //pos1->grupo , pos2->cama
            if (this.dataBedGroupsD[pos1].beds[pos2].status == 1) {
                if (this.verifSelectBed == true) {
                    //verificar si es un grupo del mismo piso
                    if (this.oldPosSelectBed[2] != this.tab) {
                        this.mtdBehindGroup(this.oldPosSelectBed);
                    } else {
                        this.dataBedGroupsD[this.oldPosSelectBed[0]].beds[this.oldPosSelectBed[1]].status = 1;
                    }
                }
                this.oldPosSelectBed = [pos1, pos2, this.tab];
                this.verifSelectBed = true;
                this.dataBedGroupsD[pos1].beds[pos2].status = 4;
            }
            this.mostrar = true;
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdSendOption: function (page) {
            this.page = page;
            this.$emit('mtdChangePage', this.page)
        }
    }
}
</script>

<style scoped>
.nav-link:hover {
    color: #ffffff !important;
}

.nav-link {
    color: #900052 !important;
    font-weight: bold;
}

.nav-link.active {
    background: #900052 !important;
    color: #ffffff !important;
}

.circle {
    background: lightblue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.superior-derecha {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.libre {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
}

.reservado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
}

.ocupado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}
.limpieza {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: blue;
  border-radius: 50%;
}
</style>
