<template>
<div>
    <div class="row pt-3">
        <div class="col-xl-6 col-lg-12">
            <div id="content-timelinetable" class="mb-2">

                <table id="table-historia" class="table table-bordered border-main mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-white border-white text-center bg-main text-xxs">
                            <th scope="col">N°</th>
                            <th scope="col">CONCEPTO</th>
                            <th scope="col">F. SOLICITUD</th>
                            <th scope="col">F. RESULTADO</th>
                            <th scope="col">MÉDICO SOLICITA</th>
                        </tr>
                    </thead>
                    <tbody class="text-xxs">
                        <tr v-for="(item, index) in dataPlanDetail" :key="index">
                            <th scope="row">{{ index + 1  }}</th>
                            <td class="concept">
                                <div class="left"><span>{{item.subsubcategory_name}}</span></div>
                                <div class="right"><span><a href="#" @click="toggleVisibilityGraph(index, $emit)"><i class="color-main fas fa-solid fa-eye"></i></a></span></div>
                            </td>
                            <td>{{item.aplication_date}}</td>
                            <td>{{item.result_date}}</td>
                            <td class="align-left">{{ item.doctor_name }}</td>
                        </tr>
                        <tr v-if="dataPlanDetail.length ===0">
                            <td colspan="5">No hay datos</td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
        <div v-if="isVisibleResultArray[pos] == true" class="col-xl-6 col-lg-12">
            <div id="div-result-interconsulta" class="mt-1 p-2">
                <div id="content-timelinetable">
                    <div class="row">
                        <div class="col-sm-5">
                            <p class="fw-bold color-main text-xs align-left">Motivo:</p>
                        </div>
                        <label for="inputPassword" class="col-sm-1 col-form-label text-xs pt-0 color-main fw-bolder">Médico</label>
                        <div class="col-sm-6">
                            <input type="text" class="form-control form-control-input" id="inputPassword">
                        </div>
                    </div>
                    <textarea class="form-control text-content" rows="2" :value="dataPlanDetail[pos].motive"></textarea>
                </div>
            </div>
            <div id="div-result-interconsulta" class="mt-1 p-2 mb-2">
                <div id="content-timelinetable">
                    <div class="row">
                        <div class="col-sm-5">
                            <p class="fw-bold color-main text-xs align-left">Respuesta:</p>
                        </div>
                        <label for="inputPassword" class="col-sm-1 col-form-label text-xs pt-0 color-main fw-bolder">Médico</label>
                        <div class="col-sm-6">
                            <input type="text" class="form-control form-control-input" id="inputPassword">
                        </div>
                    </div>
                    <textarea class="form-control text-content" rows="2" :value="dataPlanDetail[pos].answer"></textarea>
                    <div class="row mt-1">
                        <label for="inputPassword" class="col-sm-4 col-form-label text-xs pt-0 color-main fw-bold">Sugerencia de medicina</label>
                        <div class="col-sm-6">
                            <div class="input-group input-group mb-2 search-plan">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-xs">
                        <div class="panel-border my-1">
                            <div class="row my-1 px-3">
                                <div class="col-md-12" id="content-timelinetable">
                                    <table class="">
                                        <caption class="div-text"></caption>
                                        <thead class="div-text div-title">
                                            <tr>
                                                <th scope="col" class="color-main">Rp.</th>
                                                <th class=" color-main">Medicamentos</th>
                                                <th class="color-main ">Presentación</th>
                                                <th class="color-main ">Dosis</th>
                                                <th class="color-main">Vía</th>
                                                <th class="color-main">Frecuencia</th>
                                                <th class="color-main">Duración</th>
                                                <th class="color-main">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody class="div-text">
                                            <tr class="px-1">

                                                <td colspan="2" class=" w-25">
                                                    <select v-model="selectedProduct" class="form-select pb-0 pt-0">
                                                        <option value="">Buscar...</option>
                                                        <option v-for="(product, index) in productdetails" :key="index" :value="product">{{ product.commercial }}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input type="text" v-model=" selectedProduct.presentation" class="form-control form-control-input form-control-sm" disabled />
                                                </td>
                                                <td>
                                                    <input type="text" v-model=" selectedProduct.dosis" class="form-control form-control-input form-control-sm" disabled />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="recipe_unit.via" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="recipe_unit.frequency" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="recipe_unit.duration" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                
                                            </tr>
                                            <tr v-for="(recipeunit,index) in recipe" :key="index" class="px-1">
                                                <td>
                                                    <span>{{ recipeunit.other }}</span>
                                                </td>
                                                <td>
                                                    <span>{{ recipeunit.presentation }}</span>
                                                </td>
                                                <td>
                                                    <input type="text" v-model=" selectedProduct.presentation" class="form-control form-control-input form-control-sm" disabled />
                                                </td>
                                                <td>
                                                    <input type="text" v-model=" selectedProduct.dosis" class="form-control form-control-input form-control-sm" disabled />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="recipe_unit.via" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="recipe_unit.frequency" class="form-control form-control-input form-control-sm" />
                                                </td>
                                                <td>
                                                    <input type="text" v-model="recipe_unit.duration" class="form-control form-control-input form-control-sm" />
                                                </td>
                                               
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-border my-1 pb-2">
                        <div class="row px-3">
                            <div class="col-md-12 py-2">
                                <div class="input-group align-items-center">
                                    <span class="color-main fw-bold">PLAN</span>
                                </div>
                            </div>
                            <div class="col-md-4 py-2">
                                <div class="input-group input-group mb-2 search-plan">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Laboratorio" />
                                </div>
                            </div>
                            <div class="col-md-4 py-2">
                                <div class="input-group input-group mb-2 search-plan">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Imagen" />
                                </div>
                            </div>
                            <div class="col-md-4 py-2">
                                <div class="input-group input-group mb-2 search-plan">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input type="text" class="form-control" placeholder="Procedimiento/cirugía" />
                                </div>
                            </div>
                        </div>
                        <div class="row px-3">
                            <div class="col-md-2 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-4 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div class="row px-3">
                            <div class="col-md-2 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-4 py-1">
                                <div class="input-group input-group">
                                    <input type="text" class="form-control form-control-input" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    name: 'c-asistencial-consulta-historia-interconsulta',
    data() {
        return {
            isVisibleResultArray: [],
            pos: -1,
            productdetails: [],
            recipe_unit: {
                other: '',
                product_id: 0,
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: ''
            },
            selectedProduct: {
                presentation: '',
                dosis: '',
            },
            recipe: [],
            //page: null,
        }
    },
    computed: {
        lengthArray() {
            return this.dataPlanDetail.length;
        }
    },
    mounted() {
        for (let i = 0; i < this.lengthArray; i++) {
            let atributo = false;
            this.isVisibleResultArray.push(atributo);
        }

    },
    props: {
        dataPlanDetail: {
            type: Array,
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        toggleVisibilityGraph(pos) {
            this.$set(this.isVisibleResultArray, pos, !this.isVisibleResultArray[pos]);
            this.pos = pos;
        },
        mtdaddrecipe: function () {
            this.recipe_unit.other = this.recipe.length + 1;
            this.recipe_unit.product_id = this.selectedProduct.id
            this.recipe_unit.presentation = this.selectedProduct.presentation
            this.recipe_unit.dosis = this.selectedProduct.dosis
            this.recipe.push(Object.assign({}, this.recipe_unit));
        },
        mtdgetproductdetails: function () {

            this.post({
                    url: this.$store.getters.get__url + "/Consulting/productdetails",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.productdetails = response.data;

                })
                .catch((errors) => {

                    console.log(errors);
                });
        },
    }
}
</script>
