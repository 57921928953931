<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex mt-2 mb-4">
        <div class="input-group ">
          <input
            @change="getFile"
            type="file"
            class="form-control"
            id="file"
            lang="es"
            name="file"
            ref="fileInput"
          />
          <button type="button" class="btn btn-bm-noradius" @click="registerFile()">
            Agregar Archivos
          </button>
        </div>
    </div>

    <div class="row mx-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">N°</th>
                        <th scope="col">Fecha Subida</th>
                        <th scope="col">Archivo</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 12px">
                    <tr class="table-body text-center" v-if="payload.length === 0">
                        <td colspan="7" class="text-center">No hay registros</td>
                    </tr>
                    <tr v-for="(item, index) in payload" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{index + 1}}</td>
                        <td>{{item.date_upload | formatDateTime}}</td>
                        <td>
                          <a class="pdf text-center px-2 py-2 me-2" href="#" @click="openFile(item.file)">
                                <i class="fas fa-file text-white"></i>
                            </a>
                            <a class="pdf text-center px-2 py-2" href="#" @click="deleteFile(item.id)">
                                <i class="fas fa-trash-alt text-white"></i>
                            </a>
                        </td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import moment from "moment";
import {
  mapActions
} from "vuex";

export default {
  name: "c-asignaciones-modal-tasksPdf",
  components: {
      CModal,
  },
  data() {
      return {
        data_detail: {
            file: '',
        },
      };
  },
  watch: {
  },
  props: {
      title: String,
      boo_modal: Boolean,
      idTask: Number,
      payload: {
        type: Array,
        default: () => []
      },
  },
  computed: {
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.$emit("closeModalPdf");
      },

      getFile(e) {
        let fileInput = this.$refs.fileInput;
        let file = e.target.files[0];
        if (
          !/\.(pdf)$/i.test(file.name) &&
          !/\.(png)$/i.test(file.name) &&
          !/\.(jpg)$/i.test(file.name) &&  !/\.(jpeg)$/i.test(file.name)
        ) {
          Swal.fire({
            text: "Seleccione un archivo formato PDF, PNG O JPG",
            icon: "info",
            confirmButtonColor: "#900052",
          });
          fileInput.value = '';
          this.data_detail.file = '';
        } else {
          this.data_detail.file = file;
        }
      },

      registerFile() {
        if (!this.data_detail.file) {
          Swal.fire({
            text: "Seleccionar un archivo antes de Registrar",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
          return;
        }

        this.$emit('registerFile', this.data_detail, this.idTask);
        this.data_detail.file = '';
        let fileInput = this.$refs.fileInput;
        fileInput.value = '';
      },

      deleteFile(id) {
        Swal.fire({
            title: "¿Estas seguro de Eliminar este Archivo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            width: "400px",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit("deleteFile", id , this.idTask);
            }
          });
      },

      openFile(url) {
        window.open('assets/' + url, '_blank');
      },

      mtdModalFile() {
          this.$emit("mtdModalReFile", this.idTask);
      },

  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>
<style scoped>

.checkbox-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 210px;
    overflow-y: auto;
    font-size: 13px;
}

.form-check-label {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    border-radius: 20px;
    font-size: 13px;
}
</style>
