<template>
    <div>
        <div class="px-3 py-2">
            <div class="d-flex"> 
                <div  class="btn-home mb-4 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="card-header text-white h4 text-center" style="background: #900052">
                        <strong>KARDEX INTERNO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <!--Filtro-->
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">SELECCIÓN ALMACÉN</span>
                                        <v-select
                                        class="text-dark form-control"
                                        label="name"
                                        :options="dataAlmacen"
                                        placeholder="- Seleccionar Almacen -"
                                        v-model="selectedAlma"
                                        :reduce="(name) => name.id"
                                        >
                                        <template slot="no-options">
                                            No hay Almacen para la busqueda.
                                        </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-1">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        @click="mtdGetData"
                                        :disabled="mtddisable"
                                        >
                                        <i class="fas fa-search"></i>&nbsp;Buscar
                                        </button>
                                </div>
                                <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        @click="viewpdf()"
                                        :disabled="mtddisable"
                                        >
                                        <i class="fal fa-file-pdf"  style="font-size: 18px;"></i>
                                        </button>
                                </div>
                                </div>
                            </div>
                            <!--Fin Filtro-->

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-4" id="content-timelinetable"  v-if="showTable">
                            <table  id="tb-anular" class="table table-bordered border-main align-middle">
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col" colspan="1" rowspan="2">FECHA</th>
                                        <th scope="col" colspan="1" rowspan="2">LOTE</th>
                                        <th scope="col" colspan="1" rowspan="2">TIPO OPE.</th>
                                        <th scope="col" colspan="2" >ALMACEN</th>
                                        <th scope="col" colspan="1" rowspan="2">INGRESOS</th>
                                        <th scope="col" colspan="1" rowspan="2">SALIDAS</th>
                                        <th scope="col" colspan="1" rowspan="2">SALDO</th>
                                    </tr>

                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">Origen</th>
                                        <th scope="col">Destino</th>
                                    </tr>

                                </thead>

                                <tbody class="custom-table">

                                <tr class="text-center" style="vertical-align: middle; color: #900052; background: #f3e2ec">
                                    <th scope="col" colspan="5" style="font-style: italic;">*** TOTALES</th>
                                    <th scope="col">{{ totals.totalSumaCantidadEn }}</th>
                                    <th scope="col">{{  totals.totalSumaCantidadSa}}</th>
                                    <th scope="col" style="font-style: italic;"></th>
                                </tr>

                                <template v-for="(product, productId) in dataCxcs">
                                    <tr class="text-center" :key="'product_' + productId">
                                        <th scope="col" colspan="8">{{  product[0].name }}</th>
                                    </tr>

                                    <tr v-for="(item, index) in product" :key="'item_' + productId + '_' + index">
                                        <template v-if="item.type == 1">
                                            <td>{{ item.date | formatDate }}</td>
                                            <td>{{ item.lote ?  item.lote : '-'}}</td>
                                            <td>
                                                <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                            </td>
                                            <td>
                                                <span class="badge text-bg-secondary large-text">{{ item.almacen }}</span>
                                            </td>
                                            <td>
                                                <span class="badge text-bg-secondary large-text">{{item.almacenDes }}</span>
                                            </td>
                                            <td>{{ parseInt(item.quantity) }}</td>
                                            <td>-</td>
                                            <td>{{ item.saldoCantidad }}</td>
                                        </template>

                                        <template v-if="item.type == 2">
                                            <td>{{ item.date | formatDate }}</td>
                                            <td>{{ item.lote ?  item.lote : '-'}}</td>
                                            <td>
                                                <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                            </td>
                                            <td>
                                                <span class="badge text-bg-secondary large-text">{{ item.almacenDes }}</span>
                                            </td>
                                            <td>
                                                <span class="badge text-bg-secondary large-text">{{ item.almacen }}</span>
                                            </td>
                                            <td>-</td>
                                            <td>{{parseInt(item.quantity) }}</td>
                                            <td>{{ item.saldoCantidad }}</td>
                                        </template>
                                    </tr>
                                    <tr  class="text-center" style="vertical-align: middle; color: #900052; background: #fdfae3" :key="productId">
                                        <th scope="col" colspan="5" style="font-style: italic;">*** TOTAL</th>
                                        <th scope="col">{{ product.totales.sumaCantidadEn }}</th>
                                        <th scope="col">{{ product.totales.sumaCantidadSa }}</th>
                                        <th scope="col" style="font-style: italic;"></th>
                                    </tr>
                                </template>
                                </tbody>
                            </table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "kardexInterno",
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            dataCxcs: [],
            selectPage: 1,
            noRecordsMessage: "",
            filter: {
                init: "",
                end: "",
                monthRange: null
            },
            stepPagination: [],
            servicesProd: [],
            selectedProd: "",
            totals: [],

            selectedYear: null,
            nextThreeYears: [],
            showTable: false,

            //Interno
            dataAlmacen: [],
            selectedAlma: null,
        };
    },
    mounted() {
    this.populateNextThreeYears();
    },
    computed: {
        mtddisable() {
            return !(this.selectedAlma !== null);
        },
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;

        this.vercampus();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Kardex/kardexInter",
                token: this.$store.getters.get__token,
                params: {
                    alma: this.selectedAlma,
                },
            })
            .then((response) => {
                this.dataCxcs = response.data.dataCxcs;
                this.totals = response.data.totals;
                if (this.dataCxcs.length === 0) {
                    this.showTable = false;
                    this.noRecordsMessage = "No se encontraron resultados.";
                    this.$refs.SweetAlert.showWarning("Sin Datos");
                } else {
                    this.showTable = true;
                    this.noRecordsMessage = "";
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        viewpdf: function () {
            window.open(
                this.$store.getters.get__url +
                "/Kardex/viewpdfInterno/" +
                this.selectedAlma,
                "_blank"
            );
        },

        populateNextThreeYears() {
            const currentYear = new Date().getFullYear() - 2;
            for (let i = 0; i < 6; i++) {
                this.nextThreeYears.push(currentYear + i);
            }
        },

        vercampus: function () {
            this.filter.campus_id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Kardex/filterAlmacen/" +this.filter.campus_id,
                    token: this.$store.getters.get__token,

                })
                .then((response) => {
                    this.dataAlmacen = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        returnHome: function () {
            this.$emit("returnHome");
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>

<style scoped>
.custom-table th,
.custom-table td {
    font-size: 11px;
}

.large-text {
    font-size: 9px;
}
</style>
