<template>
    <div>
        <br>
        <br>
        <h1>BME</h1>
    </div>
    </template>
    <script>
         import {mapActions} from 'vuex'
         export default {
            name: 'c-asistencial-ucineo-historia-bme',
            components: {
                
            },
            data(){
                return {
                    //page:null
                }
            },
            methods: {
                ...mapActions(['get', 'post']),
            }
    
         }
    </script>