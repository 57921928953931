<template>
<div>
    <div class="container">
        <div class="row">
            <div id="btn-home" class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 text-center bg-main">
                    <strong>VOLVER A EMITIR</strong>
                </div>

                <div class="card-body w-100">
                    <div class="row d-flex">
                        <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12 mb-2">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text col-md-3">Desde</span>
                                <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" />
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12 mb-3">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text col-md-3">Hasta</span>
                                <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 col-sm-6 col-xs-12">
                            <button type="button" name="accion" class="btn btn-sm mb-3 w-100 btn-register text-white" @click="mtdGetData">
                                <i class="fas fa-search"></i> Buscar
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-3 col-sm-6 col-xs-12">
                            <button type="button" :disabled="this.emitirnuevos.length==0" name="enviar" class="btn btn-sm mb-3 w-100 btn-success text-white" @click="enviarremision()">
                                <i class="fas fa-save"></i> Volver Emitir
                            </button>
                        </div>
                    </div>

                    <div class="col-md-12" id="div-tabla-cxc">
                        <table id="tb-cxc" class="table table-bordered border-main">
                            <thead>
                                <tr align="center" class="text-white border-white" style="background: #900052">
                                    <th scope="col">N°</th>
                                    <th scope="col">FECHA</th>
                                    <th scope="col">TIPO DOC</th>
                                    <th scope="col">SERIE</th>
                                    <th scope="col">CORRELATIVO</th>
                                    <th scope="col">MENSAJE SUNAT</th>
                                    <th scope="col">VOLVER EMITIR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="dataCxcs.length == 0">
                                    <td class="text-center" colspan="13">{{noRecordsMessage}}</td>
                                </tr>
                                <tr class="" v-for="(item, index) in cpData" :key="index" v-if="item.state!=3">
                                    <td align="center">{{ index + 1 }}</td>
                                    <td align="center">{{item.date | formatDate}} </td>
                                    <td align="center">{{item.document.toUpperCase()}}</td>
                                    <td align="center">{{item.serie}} </td>
                                    <td align="center">{{ item.number }}</td>
                                    <td align="center">
                                        {{ item.mensaje }}
                                    </td>
                                    <td align="center">
                                        <input type="checkbox" class="form-check-input" v-model="item.emitir" @click="volveremitir(item)">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-center" style="align-items: center">
                        <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                </li>
                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                </li>
                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                </li>
                                <li v-if="
                      limitedStepPagination[limitedStepPagination.length - 1] <
                      stepPagination.length - 1
                    " class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-if="
                      limitedStepPagination[limitedStepPagination.length - 1] !==
                      stepPagination.length
                    " class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalSunatVue from '../modals/cModalSunat.vue';
import exportFromJson from "export-from-json";

export default {
    name: "c-administracion-document",
    components: {
        cModalSunatVue
    },
    data() {
        return {
            search: "",
            dataShow: [],
            dataCxcs: [],
            totalPagesToShow: 7,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            noRecordsMessage: "",
            filter: {
                begindate: null,
                enddate: null
            },
            modalSunat: {
                action: "",
                title: "",
                modal_form: false,
                item: [],
                garantias: [],
                doc: {}
            },
            excel: [],
            emitirnuevos: []
        };
    },
    computed: {
        cpData() {
            let filteredData = this.dataCxcs;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataCxcs.filter((element) => {
                    let name = element.vaucher_fullname.toString().toUpperCase();
                    return name.includes(query);
                });
            }

            let totalPages = Math.ceil(filteredData.length / 10);

            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * 10;
            let endIndex = startIndex + 9;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        // this.mtdGetData();
        moment.locale("es");
        this.filter.begindate =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-01";
        this.filter.enddate =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
    },
    methods: {
        ...mapActions(["get", "post"]),
        //metodo obtener data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Administracion/document/volveremitir/" + this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                    params: this.filter
                })
                .then((response) => {
                    this.dataCxcs = response.data;
                    if (this.dataCxcs.length === 0) {
                        this.noRecordsMessage = "No se encontraron resultados.";
                    } else {
                        this.noRecordsMessage = "";
                        this.selectPage = 1;
                    }

                    /** paginación */
                    let cantidad = parseInt(this.dataCxcs.length / 10);
                    let residuo = parseInt(this.dataCxcs.length % 10);
                    cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                    for (let index = 0; index < cantidad; index++) {
                        this.stepPagination.push(index + 1);
                    }
                })
                .catch((errors) => {});
        },
        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page;
        },
        backPAge: function () {
            this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
        },
        addPAge: function () {
            this.selectPage =
                this.selectPage == this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },
        /** coponent */
        changePage(page) {
            this.$emit("changePage", page);
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdBackRol: function () {
            this.$emit("mtdBackRol");
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        volveremitir(doc) {
            const existingIndex = this.dataCxcs.findIndex(item => item.id === doc.id);

            if (doc.emitir == 0) {
                if (existingIndex !== -1) {
                    this.dataCxcs[existingIndex].emitir = 1;
                    const docu = {
                        "serie": doc.serie,
                        "numero": doc.number
                    };
                    this.emitirnuevos.push(docu);
                }
            } else {
                if (existingIndex !== -1) {
                    this.dataCxcs[existingIndex].emitir = 0;
                }
                const indexToRemove = this.emitirnuevos.findIndex(item => item.serie === doc.serie && item.numero === doc.number);
                if (indexToRemove !== -1) {
                    this.emitirnuevos.splice(indexToRemove, 1);
                }
            }

        },
        enviarremision() {
            Swal.fire({
                title: '¿Está seguro de Volver a emitir los Documentos?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/indicents/reboletear",
                        token: this.$store.getters.get__token,
                        params: {
                            documents: this.emitirnuevos
                        },
                    }).then((response) => {
                        if (response.state === 0) {

                            this.mtdGetData();
                            // Limpiar el array emitirnuevos
                            this.emitirnuevos = [];

                            response.data.new_vouchers.forEach(voucher => {
                                window.open(voucher.sunat.json.enlace_del_pdf_ticket, '_blank');
                            });

                            let failedMessage = '';
                            if (response.data.failed_vouchers.length > 0) {
                                failedMessage = "Los siguientes documentos fallaron al volver emitirse:\n";
                                response.data.failed_vouchers.forEach(failedVoucher => {
                                    failedMessage += `${failedVoucher.serie}-${failedVoucher.numero}\n`;
                                });
                            } else {
                                failedMessage = "Todos los Documentos se Volvieron a Emitir.";
                            }

                            Swal.fire({
                                title: "¡Éxito!",
                                text: failedMessage,
                                icon: "success",
                                confirmButtonText: "Aceptar",
                                confirmButtonColor: "#900052",
                            });
                        }
                    }).catch((errors) => {
                        console.log("Error al enviar los datos");
                    });
                }
            });
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : '-';
        },
    },

};
</script>

  
<style scoped>
#btn-autorizar-cxc {
    color: #39b54a;
    font-size: 25px;
}

#btn-autorizar-cxc:hover {
    color: #217b2d;
}

#btn-error-cxc {
    color: #ff0000;
    font-size: 25px;
}

#btn-error-cxc:hover {
    color: #a30404;
}

#btn-pediente-cxc {
    color: #ffe600;
    font-size: 25px;
}

#btn-pediente-cxc:hover {
    color: #c0ac01;
}

#btn-registro-cxc a {
    color: #900052;
}

#btn-registro-cxc a:hover {
    color: #65013a;
    align-items: center;
    transform: scale(1.5);
}

.circulo {
    vertical-align: middle;
    border-style: none;
}

#div-modal-cxc {
    margin-top: 5px;
}

@media (max-width: 800px) {

    /* #div-modal-cxc{
        margin-top: 20px;
      } */
    #btn-open-caja {
        justify-content: center !important;
    }

    /* #div-cxc{
        width: 100% !important;
      } */
    #div-tabla-cxc {
        overflow-x: auto;
    }
}

@media (min-width: 800px) {
    /* #div-modal-cxc{
        margin-top: 65px;
      } */
}

#btn-close {
    width: 49%;
}


.close {
    cursor: pointer;
}

.modal-header {
    background: rgb(144, 0, 82);
}

.input-1 {
    border: 1.5px solid #9c9a9b;
    color: #9c9a9b;
}

.btn-fin {
    border: none !important;
}

.border-custom-text-2 {
    color: #900052;
    text-align: left !important;
    width: 150px !important;
}

.input_total {
    border: 1.5px solid #900052;
    color: #900052;
}

.myButton-div {
    border: 1.5px solid #9c9a9b;
    background-color: transparent;
    color: #9c9a9b;
    border-radius: 10px;
    width: 100px;
    padding: 4px 5px 4px 5px;
}

.myButton-div:hover {
    border: 1.5px solid #900052;
    color: #900052;
}

.border-custom {
    border-bottom: 1.5px solid #900052;
}

.border-custom-text {
    color: #900052;
}

.border-right {
    border-right: 1.5px solid #900052;
}

.myButton {
    border: none;
    background-color: transparent;
    color: #9c9a9b;
    align-content: center !important;
}

.active-btn {
    border-color: #900052 !important;
    color: #900052 !important;
}

.active-div-btn {
    border: 2.5px solid #900052 !important;
    color: #900052 !important;
    background-color: #f1f1f1;
}

.active-ico {
    border-color: #900052 !important;
    color: #900052 !important;
    border: 2.5px solid #900052 !important;
    background-color: #f1f1f1;
}

.myButton-icon {
    border: 1.5px solid #9c9a9b;
    border-radius: 15px;
    height: 65px;
    margin: 0px 0px 0px 0px;
}

.myButton-icon-2 {
    border: 1.5px solid #9c9a9b;
    border-radius: 15px;
    height: 65px;
    margin: 0px 10px 0px 10px;
}

.myButton:hover .myButton-icon {
    border-color: #900052;
}

.myButton:hover div {
    color: #900052;
}

.myButton:hover .myButton-icon-2 {
    border-color: #900052;
}

.myButton:hover div {
    color: #900052;
}

.grupo {
    border-radius: 0px !important;
}

@media (max-width: 1199px) {
    .myButton-icon {
        margin: 0px 20px 0px 20px;
    }

    .myButton-icon-2 {
        margin: 0px 3px 0px 3px;
    }

    #row-3 {
        align-content: center;
        margin: 0;
    }
}

@media (max-width: 991px) {
    #row-3 {
        flex-wrap: wrap;
    }

    .col {
        flex-basis: 50%;
    }

    #row {
        flex-direction: column;
    }

    .border-right {
        border-right: none;
        border-bottom: 1.5px solid #900052;
    }

    #row-3 {
        align-content: center;
        margin: 0;
    }
}

@media (max-width: 767px) {
    #row-2 {
        flex-direction: column;
        align-content: center;
    }

    #row-3 {
        align-content: center;
        margin: 0;
    }

    .myButton-icon-2 {
        margin: 0px -11px 0px -11px;
    }

    .myButton-icon {
        margin: 0px 70px 0px 70px;
    }
}

@media (max-width: 575px) {
    .myButton-icon {
        margin: 0px 78px 0px 78px;
    }
}

@media (max-width: 531px) {
    .myButton-icon {
        margin: 0px 0px 0px 0px;
    }

    .myButton-icon-2 {
        margin: 0px -40px 0px -40px;
    }
}

@media (max-width: 414px) {
    .col2 {
        padding: 0px 15px 0px 15px;
    }

    .button-1 {
        margin-bottom: 15px !important;
    }
}

#btn-edit {
    color: #39b54a;
    font-size: 25px;
    margin: 0px 2px;
    cursor: pointer;
}

#btn-edit:hover {
    color: #217b2d;
}

.btn-bm-noradius {
    margin-left: 10px;
    width: 150px;
    height: 38px;
}

.btn-bm-noradius:hover {
    background: rgb(170, 2, 95);
    background-color: rgb(170, 2, 95);
    color: white;
}

.btn-register {
    background: rgb(144, 0, 82);
}

.btn-register:hover {
    background: rgb(170, 2, 95);
}

.modal-header {
    background: rgb(144, 0, 82);
}

.close {
    cursor: pointer;
}

.text-start {
    text-align: left;
}

.tr-table>th>div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
}

.tr-table>th {
    padding: 0px;
}

.tbody-table>td>div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.tbody-table>td {
    padding: 0px;
}

.tbody-table>td>div>div {
    border-radius: 10px;
}

#btn-close {
    width: 49%;
}

.table-th {
    width: 65% !important;
}

.table-th-state {
    width: 13% !important;
}

.page-link {
    color: black !important;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}

@media (max-width: 480px) {
    #btn-edit {
        font-size: 13px !important;
    }

    #btn-remove {
        font-size: 13px !important;
    }

    #btn-close {
        width: 100%;
    }

    .container {
        font-size: 11px !important;
    }

    .btn-register {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .td-div-a {
        padding-top: 10px !important;
    }
}
</style>
