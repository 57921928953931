<template>
<div class="container pt-3 pb-3">
    <!--PARTE 1-->
    <div class="row" id="">
        <div class="col-xl-2 col-md-5 border">
            <div class="row div-title fw-bold py-1 justify-content-center">
                <span>SIGNOS VITALES</span>
            </div>
            <div class="pt-0 pb-2 row">
                <div class="col-md-6">
                    <div v-for="(item1, index) in signosVitalesUno" :key="index">
                        <div class="row pt-1">
                            <div class="col-md-3 div-text text-start">{{ item1 }}</div>
                            <div class="col-md-9">
                                <input type="text" class="form-control form-control-input" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div v-for="(item1, index) in signosVitalesDos" :key="index">
                        <div class="row pt-1 ms-lg-0">
                            <div class="col-md-4 div-text text-start">{{ item1 }}</div>
                            <div class="col-md-8">
                                <input type="text" class="form-control form-control-input" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-7 border">
            <div class="row">
                <div class="col-md-4 div-title fw-bold d-flex align-items-center">
                    <span>(O) OBJETIVO</span>
                </div>
                <div class="col-md-8 pt-2">
                    <div v-for="(obs, index) in observacion" :key="index" class="mb-1">
                        <div class="d-flex flex-column flex-sm-row">
                            <label class="text-content w-100 text-start mb-2 mb-sm-0">{{ obs }}</label>
                            <input type="text" class="form-control form-control-input" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-5">
            <div class="row border">
                <div class="col-xl-4 col-md-5 div-title fw-bold d-flex align-items-center text-center">
                    <span>(S) SUBJETIVO</span>
                </div>
                <div class="col-xl-8 col-md-7 py-2 px-1">
                    <input type="text" class="form-control form-control-input" />
                </div>
            </div>
            <div class="row border mt-1">
                <div class="col-xl-4 col-md-5 div-title fw-bold d-flex align-items-center text-center">
                    <span>(A)APRECIACION</span>
                </div>
                <div class="col-xl-8 col-md-7 py-2 px-1">
                    <input type="text" class="form-control form-control-input" />
                </div>
            </div>
            <div class="row border mt-1">
                <div class="col-xl-4 col-md-5 div-title fw-bold d-flex align-items-center">
                    <span>(P) PLAN</span>
                </div>
                <div class="col-xl-8 col-md-7 py-2 px-1">
                    <input type="text" class="form-control form-control-input" />
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-7">
            <div class="row pb-md-2">
                <div class="col-12 col-sm-6 px-0">
                    <table class="table-responsive table table-bordered">
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th scope="col">Fecha</th>
                                <th>PO2</th>
                                <th>PO2</th>
                                <th>FI02</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr>
                                <td>--/--/----</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>--/--/----</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>--/--/----</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 col-sm-6 border">
                    <div class="text-center div-text">
                        <span>CURVA DE PAO2/FIO2</span>
                    </div>
                    <div class="text-center">
                        <h5>GRAFICO XY</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--PARTE 2-->
    <div class="row mt-3 mb-3">
        <div class="col-xl-6 col-md-12 border">
            <div class="row justify-content-center">
                <div class="col-md-2 div-title fw-bold d-flex align-items-center">
                    <span>ANALITICA</span>
                </div>
                <div class="col-md-10">
                    <div v-for="(i, index) in analitica" :key="index" class="">
                        <div class="row div-text text-start">
                            <div class="col-md-3 border">{{ i }}</div>
                            <div class="col px-0">
                                <input type="text" class="form-control form-control-input rounded-0" />
                            </div>
                            <div class="col px-0">
                                <input type="text" class="form-control form-control-input rounded-0" />
                            </div>
                            <div class="col px-0">
                                <input type="text" class="form-control form-control-input rounded-0" />
                            </div>
                            <div class="col px-0">
                                <input type="text" class="form-control form-control-input rounded-0" />
                            </div>
                            <div class="col px-0">
                                <input type="text" class="form-control form-control-input rounded-0" />
                            </div>
                            <div class="col px-0">
                                <input type="text" class="form-control form-control-input rounded-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center align-items-center pt-2">
                <div class="table-responsive px-0">
                    <table class="table-bordered w-100">
                        <caption class="div-text"></caption>
                        <thead class="div-title">
                            <tr>
                                <th scope="col">Cultivos</th>
                                <th scope="col">Fecha de Solicitud</th>
                                <th scope="col">GRAM A LAS 24 HR.</th>
                                <th scope="col">Dia 1</th>
                                <th scope="col">Dia 2</th>
                                <th scope="col">Dia 3</th>
                                <th scope="col">Dia 4</th>
                                <th scope="col">Dia</th>
                                <th scope="col">Dia</th>
                                <th scope="col">Dia</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr>
                                <td>Hemocultivo</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                            <tr>
                                <td>Urocultivo</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                            <tr>
                                <td>Coprocultivo</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                            <tr>
                                <td>Cultivo</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-12">
            <div class="row table-emedica">
                <div class="table-responsive">
                    <table>
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th scope="col">Rp.</th>
                                <th class="w-25">Medicamentos</th>
                                <th>Presentacion</th>
                                <th>Dosis</th>
                                <th>Via</th>
                                <th>Frecuencia</th>
                                <th>Duracion</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr v-for="index in 17" :key="index" class="px-1">
                                <td class="border rounded-0">{{ index }}</td>
                                <td>
                                    <select selected class="form-select rounded-0 pb-0 pt-0">
                                        <option value="">Buscar...</option>
                                        <option>Opción 1</option>
                                    </select>
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row border mx-0 mt-2">
                <div class="col-xl-2 col-md-4 div-title fw-bold d-flex align-items-center">
                    <span>MÉDICO GENERAL</span>
                </div>
                <div class="col-xl-4 col-md-8 py-2">
                    <input type="text" class="form-control form-control-input" />
                </div>
                <div class="col-xl-2 col-md-4 div-title fw-bold d-flex align-items-center">
                    <span>MÉDICO ESPECIALISTA</span>
                </div>
                <div class="col-xl-4 col-md-8 py-2">
                    <input type="text" class="form-control form-control-input" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-asistencial-ucineo-historia-evolucionmedica',
    components: {

    },
    data() {
        return {
            signosVitalesUno: ["PC", "FR", "PA", "SO2", "FIO2"],
            signosVitalesDos: ["T*", "HGT", "DLURESIS", "BH"],
            observacion: [
                "Estudio General",
                "Neurologico",
                "Respiratorio",
                "Hemodinamico",
                "Renal",
                "Adbominal",
                "Metabólico",
            ],
            analitica: [
                "Fecha",
                "PCR",
                "LDH",
                "FERRTININA",
                "DIMERO 0",
                "LEUCOCITOS",
                "ABASTONADOS",
                "SEGMENTADOS",
                "LINFOCITOS",
                "HB/HCTO",
                "PLAQUETAS",
                "TBO/TGP",
                "TROPONINA",
                "UREA",
                "CREATININA",
                "MORD4",
                "PROCALCITONINA",
                "TP/INR",
                "COMPROMISO TEM",
            ],
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
    }

}
</script>
