<template>
<div>
    <div class="px-5 pt-2 pb-5">
        <div id="div-modal-anular" class="d-flex justify-content-center mt-4">
            <div class="card w-100 text-center">
                <div class="card-header text-white bg-main h4">
                    <div style="position: relative;">
                        <div class="">
                            <strong>SOLICITUDES DE AUTORIZACION DE CIERRE DE CAJA</strong>
                        </div>
                        <div class="btn-home-jefatura" style="right: 0;top: 0;position: absolute;">
                            <button type="button" class="btn btn-sm" @click="returnHome">
                                <i class="fas fa-home-lg-alt"></i> Inicio
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex">
                        <div v-if="cpData.length > 0" class="col-xl-9 col-lg-9 col-md-6 col-sm-6 mt-3">
                            <div class="d-flex justify-content-end">
                                <div class="input-group input-group-sm text-center btn-home">
                                    <button class="btn btn-sm  text-white " @click="mtdViewPdfCaja()">
                                        Ver PDF <i class="fa fa-file-pdf"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-3 text-center">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div v-if="cpData.length > 0" class="col-md-12 mt-3 mb-4" id="div-tabla-anular">
                            <div id="div-modal-caja" class="d-flex justify-content-center" v-for="(item, index) in cpData" :key="index">
                                <div id="div-caja" class="card w-100">
                                    <div class="card-header fw-bold text-xss text-white h4  bg-main">
                                        <div class="row d-flex py-2">
                                            <div class="col-10">
                                                {{ item.date_end | formatDate("%d/%m/%y") }}
                                                {{ item.date_end.split(" ")[1] }} -
                                                {{ item.user.name }}
                                                {{ item.user.last_name }} - 
                                                {{ item.box.name }}
                                            </div>
                                            <div class="col-2">
                                                <button @click="permision(item.id)" class="btn btn-sm btn-light color-main">
                                                    Ver Accion <i class="fa fa-edit"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12 col-xl-6 col-sm-12 col-lg-12 mt-0 text-center">
                                                <label class="form-label text-center"><strong>Billetes</strong></label>
                                                <div v-for="(billete, index) in billetes(item)" :key="index" class="">
                                                    <div class="input-group input-group-sm mb-2">
                                                        <span class="input-group-text col-4">{{ billete.label }}</span>
                                                        <input disabled type="text" class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="billete.cantidad" width="10px" />
                                                        <span v-if="billete.total == 0" class="input-group-text col-4">= S/. 0.00</span>
                                                        <span v-else class="input-group-text col-4">= S/{{ billete.total }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-xl-6 col-sm-12 col-lg-12 text-center">
                                                <label class="form-label text-center"><strong>Monedas</strong></label>
                                                <div v-for="(moneda, index) in monedas(item)" :key="index" class="">
                                                    <div class="input-group input-group-sm mb-2">
                                                        <span class="input-group-text col-4">{{ moneda.label }}</span>
                                                        <input disabled type="text" class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="moneda.cantidad" />
                                                        <span class="input-group-text col-4" v-if="moneda.total == 0">= S/. 0.00</span>
                                                        <span v-else class="input-group-text col-4">= S/ {{ moneda.total }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-xl-4 col-lg-12 col-sm-12 text-center  btn-home ">
                                                <strong> TOTAL EFECTIVO </strong>
                                                <button class="btn btn-sm w-100 mt-1">
                                                    <strong>APERTURA: S/.{{ item.initial }}</strong>
                                                </button>

                                                <button class="btn btn-sm w-100 mt-1">
                                                    <strong>TOTAL:S/.{{ item.final }} </strong>
                                                </button>
                                            </div>
                                            <div class="col-md-12 col-xl-4 col-lg-12 col-sm-12 text-center  btn-home">
                                                <strong> MOVIMIENTOS </strong>
                                                <button class="btn btn-sm w-100 mt-1">
                                                    <strong>Depósitos: S/.{{ depositos(item) }}</strong>
                                                </button>

                                                <button class="btn btn-sm w-100 mt-1">
                                                    <strong>Retiros: S/.{{ retiros(item) }}</strong>
                                                </button>
                                            </div>
                                            <div class="col-md-12 col-xl-4 col-lg-12 col-sm-12 text-center btn-home">
                                                <strong> SALDOS </strong>
                                                <button class="btn btn-sm w-100 mt-1">
                                                    <strong>Saldo efectivo S/{{ item.final}}</strong>
                                                </button>
                                                <button class="btn btn-sm w-100 mt-1">
                                                    <strong v-if="sobrafalta(item) < 0"> Faltante :S/{{ parseFloat(sobrafalta(item)) * -1 }}</strong>
                                                    <strong v-else> Sobrante: S/{{ sobrafalta(item) }}</strong>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-md-12 mt-3 mb-4 text-center fw-bold" id="div-tabla-anular">
                            <span>No hay Solicitudes de Permiso</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtdPermissionCommit="mtdPermissionCommit" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-gerencia-cierredecaja",
    components: {
        SweetAlert,
    },
    data() {
        return {
            filter: {
                init: null,
                end: null,
            },
            totalPagesToShow: 2,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataShow: [],
            positem: null,
            swal: null,
            data: [],
        };
    },
    created() {
        this.mtdGetData();
    },
    watch: {
        selectPage() {
            this.calculateData(1);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            let modulo = 'gerencia'
            this.post({
                    url: this.$store.getters.get__url + "/Bead/permisoCierreCaja",
                    token: this.$store.getters.get__token,
                    params: {
                        campus: this.$store.getters.get__campus,
                        modulo: modulo,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                    this.selectPage = 1;
                        this.calculateData(1);
                    

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        permision(id) {
            //let state = "";
            this.$refs.SweetAlert.showPermisions(id, null, false, false);

        },
        mtdPermissionCommit(id, obs, type, state) {
            
                //aceptar
                this.post({
                            url: this.$store.getters.get__url + "/Bead/permiso/respuesta",
                            token: this.$store.getters.get__token,
                            params: {
                                bead: id,
                                state: state,
                            },
                        })
                        .then((response) => {
                            if (response.statusCode == 200) {
                                this.mtdGetData();
                                this.$refs.SweetAlert.showSuccess(response.message);
                            }
                        })
                        .catch((errors) => {
                            console.log(errors);
                        });
           
              
        },
        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.positem = this.dataShow[0].id;
        },
        billetes: function (item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.billetes;
        },
        monedas: function (item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.monedas;
        },
        tdeposito(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.totDeposito;
        },
        general(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);

            let tot =
                parseFloat(cierre.totDeposito) +
                parseFloat(cierre.totTransferencia);
            return parseFloat(tot).toFixed(2);
        },
        sobrafalta(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            let tot = 0;
            cierre.billetes.forEach(element => {
                tot += parseFloat(element.total);
            });
            cierre.monedas.forEach(element => {
                tot += parseFloat(element.total);
            });
            let tot1 =
                parseFloat(tot) -
                parseFloat(cierre.totEfectivo) -
                parseFloat(cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00')
            return parseFloat(tot1).toFixed(2);
        },
        depositos(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.deposit;
        },
        retiros(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.retirement;
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage = this.selectPage < this.stepPagination.length ? this.selectPage + 1 : this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdViewPdfCaja: function () {
            window.open(
                this.$store.getters.get__url + "/ReportesCajas/viewpdf/cierre/" + this.positem,
                "_blank"
            );
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>
