<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true"
  :booTitile="true" >
    <div class="row mx-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">N°</th>
                        <th scope="col">Fecha Registro</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Obstaculos</th>
                        <th scope="col">Porcentaje</th>
                        <th scope="col">Acciones</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 12px">
                    <tr class="table-body text-center" v-if="cpData.length === 0">
                        <td colspan="7" class="text-center">No hay registros</td>
                    </tr>
                    <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{index + 1}}</td>
                        <td>{{item.date_registration | formatDateTime}}</td>
                        <td>{{item.description ? item.description : '-'}}</td>
                        <td>{{item.obstacles ? item.obstacles : '-'}}</td>
                        <td>
                          <div class="progress" style="height: 20px;">
                            <div
                              class="progress-bar"
                              role="progressbar"
                              :style="{ width: item.percentage + '%', backgroundColor: '#900052' }"
                              :aria-valuenow="item.percentage"
                              aria-valuemin="0"
                              aria-valuemax="100">
                              <span>{{item.percentage}}%</span>
                            </div>
                          </div>
                        </td>

                        <td class="" style="white-space: nowrap;">
                            <a class="accion-AsignFile text-center px-2 py-2 me-1" href="#" v-if="date_approval == 0" @click="openPer(item.id, item)">
                                <i class="fas fa-percent text-white"></i>
                            </a>
                            <a class="accion-AsignFile text-center px-2 py-2 me-1" href="#" @click="openAsignFile(item.id)">
                                <i class="fas fa-solid fa-file text-white"></i>
                            </a>
                            <a class="accion-AsignComment text-center px-2 py-2" href="#" @click="openAsignComment(item.id)">
                              <i class="fas fa-comment text-white"></i>
                            </a>
                        </td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" v-if="date_approval == 0" :disabled="numTer" @click="aproRe()">
          <strong>EVALUAR</strong>
        </button>
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import moment from "moment";
import {
  mapActions
} from "vuex";

export default {
  name: "c-asignaciones-modal-assig",
  components: {
      CModal,
  },
  data() {
      return {
      };
  },
  watch: {
  },
  props: {
      title: String,
      boo_modal: Boolean,
      idAsign: Number,
      status: Number,
      date_approval: Number,
      payload: {
        type: Array,
        required: true
      },
  },
  computed: {
    cpData() {
      return this.payload.map(element => element);
    },
    numTer() {
      return !(this.status == 3);
    }
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.$emit("closeModalAsign");
      },

      openPer(id, item){
        this.$emit("openPer", id, this.idAsign, item);
      },

      openAsignFile(id){
        this.$emit("openAd", id, 0, this.date_approval);
      },

      openAsignComment(id){
        this.$emit("openAd", id, 1, this.date_approval);
      },

      aproRe(){
        Swal.fire({
          icon: "warning",
          title: "¿Qué accción deseas tomar para esta Asignación?",
          width: "400px",
          showDenyButton: true,
          denyButtonText: `Rechazar`,
          denyButtonColor: "#bb8a17",
          confirmButtonText: "Aprobar",
          confirmButtonColor: "#3085d6",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cerrar",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "¿Seguro que desea APROBAR la Asignación?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Aprobar",
              width: "400px",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$emit("aproRe", this.idAsign, 0);
              }
            });
          } else if (result.isDenied) {
            Swal.fire({
              title: "¿Seguro que desea RECHAZAR la Asignación?",
              text: "Se cambirá el estado de la Asignación a EN PROGRESO",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Rechazar",
              width: "400px",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$emit("aproRe", this.idAsign, 1);
              }
            });
          }
        });
      },

  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>
<style>
.accion-AsignFile {
    background-color: #900052;
    border-radius: 10px;
    width: 15px;
    font-size: 12px;
}

.accion-AsignComment {
    background-color: #900052;
    border-radius: 10px;
    width: 15px;
    font-size: 12px;
}
</style>
