<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12">
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-4">
                    <span style="border: none" class="input-group-text"><i class="fa fa-hospital"></i></span>
                    Sede
                </div>
                <select class="form-control form-control-sm" aria-label="Default select example" v-model="data_detail.campu_id" :disabled="action == 'store'?false:true">
                    <option selected disabled value="">Seleccione ...</option>
                    <option v-for="(option, index) in dataCampu" :key="index" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2">
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-4">
                    <span style="border: none" class="input-group-text"><i class="fa fa-layer-group"></i></span>
                    Piso
                </div>
                <select name="selectFloor" v-model="data_detail.floor_id" class="form-control form-control-sm" aria-label="Default select example">
                    <option selected disabled value="">Seleccione ...</option>
                    <option v-for="(option, index) in cpDataFloor" :key="index" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2" v-if="action == 'store'">
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-4">
                    <span style="border: none" class="input-group-text"><i class="fas fa-bed"></i>&nbsp;Cantidad
                    </span>
                </div>
                <input type="text" class="form-control form-control-sm" @keydown="filterKey" v-model="data_detail.cantidad" placeholder="0" />
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2" v-else-if="action == 'edit'">
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-4">
                    <span style="border: none" class="input-group-text"><i class="fas fa-bed"></i>&nbsp;Nombre
                    </span>
                </div>
                <input type="text" class="form-control form-control-sm" name="correo" v-model="data_detail.name" />
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2">
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-4">
                    <span style="border: none" class="input-group-text"><i class="fas fa-bed"></i>&nbsp;Tipo
                    </span>
                </div>
                <select v-model="data_detail.type" class="form-control form-control-sm" aria-label="Default select example">
                    <option disabled value="">Seleccione ...</option>
                    <option :value="1">Hospitalizacion</option>
                    <option :value="2">UCI</option>
                    <option :value="3">Emergencia</option>
                </select>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2" v-if="action == 'edit' && data_detail.status === 1">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                <label class="form-check-label" for="flexSwitchCheckChecked">{{
            data_detail.state == 1 ? "Activo" : "Inactivo"
          }}</label>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button @click="mtdInsertData" color="danger" class="btn btn-success btn-sm">
            <i :class="action == 'store' ? 'fas fa-save' : 'fas fa-edit'" />&nbsp;{{
          action == "store" ? "Registrar" : "Editar"
        }}
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
        CButtonClose,
        SweetAlert
    },
    data() {
        return {
            campu: 0,
            swal: null,
            dataFloor: [],
            data_detail: {
                campu_id: "",
                floor_id: "",
                name: "",
                state: 1,
                type: 1,
                cantidad: "",
                action: "",

            }
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        action: String,
        dataCampu: {
            type: Array,
            default: [],
        },
        payload: {},
        filter: {},
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.action == 'edit') {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        cpDataFloor() {
            if (this.dataCampu.length > 0) {
                this.data_detail.campu_id = this.dataCampu[0].id;
                this.dataFloor = [];
                this.dataCampu.forEach(element => {
                    if (element.id == this.data_detail.campu_id) {
                        this.dataFloor = element.floor;
                    }
                });
            }
            return this.dataFloor;
        },
        btnClasses() {
            return [`btn btn-${this.color || "primary"}`];
        },
        cpButtonFormFloor: function () {
            if (this.Floor.name.length > 0 && this.Floor.campu_id != "") return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdSelectFloor: function () {
            this.dataCampu.forEach((element, index) => {
                if (element.id == this.bed.campu_id) {
                    this.dataFloor = element.floor;
                }
            });
        },
        mtdChangeState: function () {
            this.bed.state = this.bed.state == 1 ? 0 : 1;
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdInsertData: function () {

            //this.bed.campu_id = this.filter.filter_campu_id;
            this.data_detail.action = this.action == 'store' ? 'c' : 'u';
            this.post({
                    url: this.$store.getters.get__url + "/Bed/crudBeds",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        const floor_id =  this.data_detail.floor_id;
                        this.mtdClose();
                        this.$emit("mtdCommitBed",floor_id)
                    } else {
                        this.$refs.SweetAlert.showError(errors);
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });

        },
        //metodo modificar
        mtdModifyData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/bed/update",
                    token: this.$store.getters.get__token,
                    params: this.bed,
                })
                .then((response) => {
                    if (response.state == 0) {
                        /** todo correto */
                        Swal.fire({
                            title: "Modificación Exitosa",
                            text: "Perfecto!",
                            icon: "success",
                            //showConfirmButton: true,
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.filter.filter_floor_id = this.bed.floor_id;
                        this.mtdClose();
                    } else {}
                })
                .catch((errors) => {});
        },
        mtdClose() {
            this.dataFloor = [],
            this.data_detail = {
                campu_id: "",
                floor_id: "",
                name: "",
                state: 1,
                type: 1,
                cantidad: "",
                action: "",

            }
            this.$emit("mtdHideModalBed");
        },
    },
};
</script>
