<template>
  <div>
    <div class="row d-flex">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-lg-3 col-md-5" id="num_document">N° Comprobante</span>
          <input 
            type="text" 
            class="form-control" 
            placeholder="N° Recibo" 
            aria-describedby="num_document" 
            v-model="receipt.num_document" 
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
          <div class="input-group-text">
            <input 
              type="radio" 
              aria-label="Checkbox for selecting RUC" 
              v-model="receipt.type_document"
              value="RUC"
            />
            <label for="rucCheckbox">RUC</label>
          </div>
        </div>
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input 
              type="radio" 
              aria-label="Checkbox for selecting DNI" 
              v-model="receipt.type_document"
              value="DNI"
            />
            <label for="dniCheckbox">DNI</label>
          </div>
        </div>
          <input 
            type="text" 
            class="form-control" 
            placeholder="RUC/DNI" 
            aria-describedby="document" 
            v-model="receipt.document" 
            @keyup="mtdSearchDocument"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="name">Nombre Personal</span>
          <input 
            disabled
            type="text" 
            class="form-control" 
            placeholder="Nombres y Apellidos" 
            aria-describedby="name"  
            v-model="receipt.name" 
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="patient_id">Paciente</span>
          <v-select 
            :options="patients" 
            class="form-control" 
            :reduce="(name) => name.id" 
            label="name"  
            placeholder="-- Seleccione uno --"
            v-model="receipt.patient_id" 
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text col-md-3">Fecha Turno</span>
          <!-- <span class="input-group-text">Fecha y hora</span> -->
          <input
            type="date"
            aria-label="shift_date"
            class="form-control form-control-sm"
            v-model="receipt.shift_date"
          />
          <!-- <input
            type="time"
            aria-label="time"
            class="form-control form-control-sm"
            v-model="receipt.time"
          /> -->
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="shift">Turno</span>
          <v-select 
            :options="shifts" 
            class="form-control" 
            :reduce="(name) => name.id" 
            label="name"  
            placeholder="-- Seleccione uno --"
            v-model="receipt.shift" 
          />
          <!-- <select class="form-control form-control-sm" name="shift" id="shift">
            <option value="0">-- Seleccione uno --</option>
            <option value="1">6 Horas</option>
            <option value="2">12 Horas</option>
            <option value="3">24 Horas</option>
          </select> -->
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="category">Categoría</span>
          <v-select 
            :options="categories" 
            class="form-control" 
            :reduce="(name) => name.id" 
            label="name"  
            placeholder="-- Seleccione uno --"
            v-model="receipt.category" 
          />
          <!-- <select class="form-control form-control-sm" name="category" id="category">
            <option value="0">-- Seleccione uno --</option>
            <option value="1">Por Horas</option>
            <option value="2">Turnos por Pacientes</option>
          </select> -->
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3">Monto</span>
          <input
            type="text"
            aria-label="amount"
            placeholder="Monto"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-model="receipt.amount"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3">Monto Pagado</span>
          <input
            type="text"
            aria-label="amount_pay"
            placeholder="Monto Pagado"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-model="receipt.amount_pay"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text col-md-3">Fecha Pagada</span>
          <input
            type="date"
            aria-label="date_pay"
            class="form-control form-control-sm"
            v-model="receipt.date_pay"
          />
        </div>
      </div>

      <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="bank_id">Caja / Banco</span>
          <v-select 
            :options="banks" 
            class="form-control" 
            :reduce="(name) => name.id" 
            label="name"  
            placeholder="-- Seleccione uno --"
            v-model="receipt.bank_id" 
          />
        </div>
      </div> -->

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <input
            @change="getFile"
            type="file"
            class="form-control"
            id="file"
            lang="es"
            name="file"
            ref="fileInput"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="concept">Concepto</span>
          <textarea
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            placeholder="Concepto"
            aria-describedby="concept"
            v-model="receipt.concept"
          ></textarea>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center mb-3">
        <div
          class="btn-group"
          role="group"
          aria-label="Basic mixed styles example"
        >
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="mtdCancelar"
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-sm btn-success ml-1"
            @click="mtdVerifRecibo"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
//import { bus } from "../../main";
import moment from 'moment';

export default {
  name: "c-admsion-storerecibo",
  props:{
    bead:{}
  },
  components: {
        SweetAlert
  },
  data() {
    return {
      view: "table",
      swal: null,
      receipt: {
        bead_id:null,
        type_document:"",
        num_document: "",
        concept: "",
        file: "",
        document: "",
        name: "",
        patient_id: null,
        shift_date: null,
        shift: null,
        category: null,
        amount: 0.00,
        amount_pay: 0.00,
        date_pay: null,
        bank_id: null,
        state: ""
      },
      patients: [],
      banks: [],
      categories: [],
      shifts: [
        { id: 1, name: '6 Horas'},
        { id: 2, name: '12 Horas'},
        { id: 3, name: '24 Horas'}
      ]
    };
  },
  created() {
  
    moment.locale('es');
    this.receipt.shift_date=moment().format('L').substr(6,4)+'-'+moment().format('L').substr(3,2)+'-'+moment().format('L').substr(0,2);
    this.receipt.date_pay=moment().format('L').substr(6,4)+'-'+moment().format('L').substr(3,2)+'-'+moment().format('L').substr(0,2);
    //this.receipt.time=moment().format('LT');
    this.receipt.bead_id = this.bead.id;
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackTable: function () {
      this.$emit("mtdBackTable");
    },
    mtdGetData: function(){
      this.post({
        url: this.$store.getters.get__url + "/Expense/receipt",
        token: this.$store.getters.get__token,
        params:{
          bead:this.bead.id
        }
      })
        .then((response) => {
          this.patients = response.data.patients;
          this.banks = response.data.banks;
          this.categories = response.data.categories;
        })
        .catch((errors) => {});
    },
    getFile(e) {
      let fileInput = this.$refs.fileInput;
		  let file = e.target.files[0];
		  if (!/\.(pdf)$/i.test(file.name)) {
        this.$refs.SweetAlert.showWarning("Seleccione un archivo formato PDF");

        //$("#file").val('');
        fileInput.value = '';
		  } else {
    		this.receipt.file = file;
		  }
	  },
    mtdStore: function () {
      this.view = "store";
    },
    mtdCancelar: function () {
      this.mtdBackTable();
    },

    mtdVerifRecibo: function (){
      if(this.receipt.num_document == null || this.receipt.num_document == ""){
        this.$refs.SweetAlert.showWarning("Seleccione un N° de Comprobante");
      } else {
        if(parseFloat(this.receipt.amount).toFixed(2) <= 0){
          this.$refs.SweetAlert.showWarning("Ingrese un monto");
        } else {
          if(parseFloat(this.receipt.amount_pay).toFixed(2) <= 0){
            this.$refs.SweetAlert.showWarning("Ingrese monto a pagar");
          } else {
            if(parseFloat(this.receipt.amount_pay) > parseFloat(this.receipt.amount)){
              this.$refs.SweetAlert.showWarning("El monto a pagar no puede ser mayor que el monto total");
            } else {
              if(this.receipt.document == null || this.receipt.document == ""){
                this.$refs.SweetAlert.showWarning("Ingrese un ruc o Dni");
              } else {
                    this.mtdCommitReceipt();
              }
            }
          }
        }
      }
    },

    mtdCommitReceipt: function () {
      let formData = new FormData();
      const handleValue = (value) => {
        return value === null || value === "" || value === undefined ? 0 : value;
      };

      //formData.append("id", this.receipt.id);
      formData.append("campu_id",this.$store.getters.get__campus);
      formData.append("bead_id", this.receipt.bead_id);
      // formData.append("bank_id",this.receipt.bank_id);
      formData.append("num_document", this.receipt.num_document);
      formData.append("concept", this.receipt.concept);
      formData.append("file", this.receipt.file);
      formData.append("document", this.receipt.document);
      formData.append("name",this.receipt.name);
      formData.append("shift_date",this.receipt.shift_date);
      formData.append("patient_id", handleValue(this.receipt.patient_id));
      formData.append("shift", handleValue(this.receipt.shift));
      formData.append("category", handleValue(this.receipt.category));
      formData.append("amount",this.receipt.amount);
      formData.append("amount_pay",this.receipt.amount_pay);
      formData.append("date_pay",this.receipt.date_pay);
      formData.append("state",this.receipt.state);
      this.post({
        url: this.$store.getters.get__url + "/Expense/receipt/store",
        token: this.$store.getters.get__token,
        params: formData,
      })
        .then((response) => {
        
          if (response.statusCode == 200) {
            const whatsappPhone ='+51986495932';
            const gerente = 'Renato Ramos';
            const tipo = 'Ha registrado  una Solicitud de Egreso De R.Honorario';
            const monto =this.receipt.amount_pay;
            const admin =this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            window.open(
              this.generateWhatsAppLinks(gerente, whatsappPhone, tipo,monto,admin)
                 , '_blank');
            this.receipt = {
              bead_id:null,
              num_document: "",
              concept: "",
              file: "",
              document: "",
              name: "",
              patient_id: null,
              shift_date: null,
              shift: null,
              category: null,
              amount: 0.00,
              amount_pay: 0.00,
              date_pay: null,
              bank_id: null,
              state: ""
            };
            this.$emit('mtdAddReceipt',response.data);
          } else {
            alert(response.message);
          }
        })
        .catch((errors) => {});
    },
    generateWhatsAppLinks(gerente, whatsappPhone, tipo,monto,admin) {
      const message = `Hola ${gerente},el Admisionista ${admin} ${tipo} ,por un monto de  S/. ${monto}.`;
      const encodedMessage = encodeURIComponent(message);
      // const whatsappLink=`https://wa.me/${whatsappPhone}?text=${encodedMessage}`;
      const whatsappLink = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${encodedMessage}`;
      return whatsappLink;
    }, 
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSearchDocument: function(){
      if(!this.receipt.type_document){
        this.$refs.SweetAlert.showWarning("Seleccione un RUC o DNI");
      }
      else
      {
      if (this.receipt.document.length === 8  && this.receipt.type_document=='DNI') {
        this.get({
          url:
            this.$store.getters.get__url +
            "/reniec/" +
            this.receipt.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
         
            if (response.data.boo == 3) {
              this.receipt.document="";
              this.$refs.SweetAlert.showWarning("DNI no encontrado");
            } else {
              this.receipt.name = response.data.name;
            }
          })
          .catch((errors) => {});
      }else{
        if (this.receipt.document.length === 11 && this.receipt.type_document=='RUC') {
          this.get({
            url:
              this.$store.getters.get__url +
              "/reniec/" +
              this.receipt.document,
            token: this.$store.getters.get__token,
          })
            .then((response) => {
           
              if (response.data.boo == 3) {
                this.receipt.document= "";
                this.$refs.SweetAlert.showWarning("RUC no encontrado");
              } else {
                this.receipt.name = response.data.razon_social;
              }
            })
            .catch((errors) => {});
          }
      }
    }
    },
    
  },
};
</script>

<style scoped>

</style>