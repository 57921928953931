<template>
<div>

    <div id="app" class="container mt-3">
        <div class="row mb-1 mx-0" id="boton-arrow" v-if="stateHistoryShow === 0 || showHistoryComplet ==1">
            <div class="btn-success text-start p-0">
                <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackPage">
                    <i class="fas fa-arrow-left"></i> Atr&aacute;s
                </button>
            </div>
        </div>
        <div class="row" v-if="viewinputs!==0">
            <div class="col-lg-8 col-md-12 ">
                <form class="d-flex flex-row">
                    <div class="row mb-4">
                        <div id="nombre" class="input-group-sm col-md-4 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="nombre" placeholder="Nombre y Apellido" autocomplete="off" v-model="filterHistory.name" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="edad" class="input-group-sm col-md-1 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="edad" placeholder="Edad" autocomplete="off" v-model="filterHistory.year" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_inicio" placeholder="Fecha de inicio" autocomplete="off" v-model="filterHistory.startDate" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_fin" placeholder="Fecha Fin" autocomplete="off" v-model="filterHistory.endDate" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="dias" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="dias_transcurridos" placeholder="Dias transcurridos" autocomplete="off" v-model="filterHistory.dayPassed" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mx-0 text-white h-100  " v-if="viewinputs===0">
            <div class="mx-0 col-sm-12 col-md-12 col-xl-6 h-100 order-lg-1  rounded-3 px-0 bg-blue mb-2">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center  ">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-12 order-md-1 order-lg-2">
                <div class="row" v-if="viewMedico===0">
                    <div class="col-md-10 d-inline-flex w-100">
                        <div class="input-group input-group-sm w-100">
                            <span class="input-group-text text-white bg-main" id="inputGroup-sizing-sm">Médico</span>
                            <input type="text" class="form-control text-xs" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" v-model="patient.doctor_name" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container text-center mt-2 " v-if="stateHistoryShow === 0 || showHistoryComplet ==1">

        <ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoUci" role="tablist">
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('hcsop')" :class="{'item-buttonUci':true, 'nav-link': true, 'active': subpage === 'hcsop' }" id="home-tab" type="button">
                    <strong>Hc de Sop</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('emedica')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'emedica' }" id="profile-tab" type="button">
                    <strong>Evolución Medica</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rp')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'rp' }" id="profile-tab" type="button">
                    <strong>R.P</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('ordensop')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage=='ordensop' }" id="profile-tab" type="button">
                    <strong>Orden de Sop</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('preanestecia')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'preanestecia' }" id="profile-tab" type="button">
                    <strong>Pre anestecia</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rxqx')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'rxqx' }" id="profile-tab" type="button">
                    <strong>Rx qx</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rxneumologico')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'rxneumologico' }" id="profile-tab" type="button">
                    <strong>Rx neumologico</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('ci')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'ci' }" id="profile-tab" type="button">
                    <strong>C. I.</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('hojaanestecia')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'hojaanestecia' }" id="profile-tab" type="button">
                    <strong>H. de anestecia</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('postanestecia')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'postanestecia' }" id="profile-tab" type="button">
                    <strong>Post anestecia</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('informeoperatorio')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'informeoperatorio' }" id="profile-tab" type="button">
                    <strong>Informe operatorio</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('listaverificacion')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'listaverificacion' }" id="profile-tab" type="button">
                    <strong>Lista de verificacion</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('kardex')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'kardex' }" id="profile-tab" type="button">
                    <strong>Kardex</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('bhe')" :class="{ 'item-buttonUci':true,'nav-link': true, 'active': subpage === 'bhe' }" id="profile-tab" type="button">
                    <strong>bhe</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('mhemodinamico')" :class="{ 'item-buttonUci':true, 'nav-link': true, 'active': subpage === 'mhemodinamico' }" id="profile-tab" type="button">
                    <strong>Monitoreo Hemodinámico</strong>
                </button>
            </li>
        </ul>

        <div class="tab-content mb-5 d-none d-lg-block" id="myTabContentHijo">
            <Hc :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" v-if="subpage=='hcsop'" />
            <Emedica v-if="subpage=='emedica'" />
            <Rp v-if="subpage=='rp'" />
            <Kardex v-if="subpage=='kardex'" />
            <Bhe v-if="subpage=='bhe'" />
            <Mhemodinamico v-if="subpage=='mhemodinamico'" />
            <Ordensop v-if="subpage=='ordensop'" />
            <Preanestecia v-if="subpage=='preanestecia'" />
            <Rxqx v-if="subpage=='rxqx'" />
            <Rxneumologico v-if="subpage=='rxneumologico'" />
            <Ci v-if="subpage=='ci'" />
            <Hojaanestecia v-if="subpage=='hojaanestecia'" />
            <Postanestecia v-if="subpage=='postanestecia'" />
            <Informeoperatorio v-if="subpage=='informeoperatorio'" />
            <Listaverificacion v-if="subpage=='listaverificacion'" />
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="H. Clínica" component="HistoriaSop" :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></dropdown>
                <dropdown title="Evolución Médica" component="Emedica"></dropdown>
                <dropdown title="Rp" component="Rp"></dropdown>
                <dropdown title="Orden de Sop" component="Ordensop"></dropdown>
                <dropdown title="Pre anestecia" component="preanestecia"></dropdown>
                <dropdown title="Rx qx" component="Rxqx"></dropdown>
                <dropdown title="Rx neumologico" component="Rxneumologico"></dropdown>
                <dropdown title="C.I." component="Ci"></dropdown>
                <dropdown title="Hoja de anestecia" component="Hojaanestecia"></dropdown>
                <dropdown title="Post anestecia" component="Postanestecia"></dropdown>
                <dropdown title="Informe operatorio" component="Informeoperatorio"></dropdown>
                <dropdown title="Lista de verificacion" component="Listaverificacion"></dropdown>
                <dropdown title="Kardex" component="Kardex"></dropdown>
                <dropdown title="Bhe" component="Bhe"></dropdown>
                <dropdown title="Monitoreo Hemodinamico" component="Mhemodinamico"></dropdown>
            </div>
        </div>
        <!--  -->
    </div>
    <div v-if="showHistoryComplet !=1">
        <Tablehistory :resultFilter="resultFilter" @historyPlanPatient="getHistoryPatient" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Hc from '@/components/Asistencial/Sop/Historia/Hcsop.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue';
import Emedica from '@/components/Asistencial/Sop/Historia/Emedica.vue';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Bhe from '@/components/Asistencial/Sop/Historia/Bhe.vue';
import Mhemodinamico from '@/components/Asistencial/Sop/Historia/Mhemodinamico.vue';
import Ordensop from '@/components/Asistencial/Sop/Historia/Ordendesop.vue';
import Preanestecia from '@/components/Asistencial/Sop/Historia/Preanestecia.vue';
import Rxqx from '@/components/Asistencial/Sop/Historia/Rxqx.vue';
import Rxneumologico from '@/components/Asistencial/Sop/Historia/Rxneumologico.vue';
import Ci from '@/components/Asistencial/Sop/Historia/Ci.vue';
import Hojaanestecia from '@/components/Asistencial/Sop/Historia/Hojaanestecia.vue';
import Postanestecia from '@/components/Asistencial/Sop/Historia/Postanestecia.vue';
import Informeoperatorio from '@/components/Asistencial/Sop/Historia/Informeoperatorio.vue';
import Listaverificacion from '@/components/Asistencial/Sop/Historia/Listaverificacion.vue';
import Dropdown from '@/components/Asistencial/Sop/Dropdown.vue';
import Tablehistory from "@/components/Asistencial/Sop/TableHistory.vue";
export default {
    name: 'c-asistencial-sop-historia',
    components: {
        Hc,
        Kardex,
        Emedica,
        Rp,
        Bhe,
        Mhemodinamico,
        Ordensop,
        Preanestecia,
        Rxqx,
        Rxneumologico,
        Ci,
        Hojaanestecia,
        Postanestecia,
        Informeoperatorio,
        Listaverificacion,
        Dropdown,
        Tablehistory,
    },
    data() {
        return {
            subpage: 'hcsop',
            resultFilter: [],
            showHistoryComplet: 0,
            filterHistory: {
                dayPassed: null,
                startDate: null,
                endDate: null,
                year: null,
                name: null,
            },
            stateDisabled: false,
            patient: [],
            datapatients: {
                patient_id: '',
                model_id: '',
                patient_name: '',
                patient_year: ''
            },
            viewinputs: null,
            viewMedico: null,
            Clinicahistory: {},
        };
    },
    props: {
        stateHistoryShow: {
            type: Number,
        },
        patients: {
            type: Object,
            default: () => ({})
        },
        viewInput: {
            type: Number,
        }
    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.subpage) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.subpage) return 'color:white';
            return '';
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        getHistoryPatient: function (datos) {
            const {
                patient
            } = datos;
            this.changeSubPage('hcsop');
            this.showHistoryComplet = 1;
            this.patient = patient;
            this.mtdClear();

        },
        mtdGetDataFilter: function () {
            //Cargar data de acuerdo a lo buscado
            this.post({
                url: this.$store.getters.get__url + "/Sop/filter",
                token: this.$store.getters.get__token,
                params: this.filterHistory,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.resultFilter = response.data;
                }
            });
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            this.filterHistory.startDate = firstDayOfMonth.toISOString().split('T')[0];
        },
        mtdClear: function () {
            this.datapatients = {
                patient_id: this.patient.patient_id,
                model_id: this.patient.model_id,
                patient_year: this.patient.patient_year,
                patient_name: this.patient.patient_name,
            }
            this.viewinputs = 0
            this.viewMedico = 0
            this.Clinicahistory = this.patient
        },
        mtdBackPage: function () {

            if (this.stateHistoryShow === 1) {
                this.getFirstDayOfMonth();
                this.showHistoryComplet = 0;
                this.stateDisabled = false;
                this.mtdGetDataFilter();
                this.viewinputs = 1;
            } else {
                this.$emit('changePage', 'cola');
            }

        },
    },
}
</script>
