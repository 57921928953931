<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label id="miSelect" for="name" class="form-label">Tipo</label>
                    <select  v-model="type"  class="form-control form-control-sm" aria-label=".form-select-sm example">
                        <option value="" selected disabled>Seleccione...</option>
                        <option  v-for="(item,index) in cpData" :key="index" :value="item.id">{{ item.type }}</option>
                    </select>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="isDisabled">
                 OK
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-destinoCarro",
    components: {
        CModal,
    },
    data() {
        return {
            tabla: [],
            type: "",
        };
    },
    watch: {
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: [],
    },
    computed: {
        cpData() {
            let show = [];
            this.item.forEach((element) => {
                show.push(element);
            });
            this.tabla = show;
            return show;
        },
        isDisabled() {
            return this.type == '';
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.type = "";
            this.$emit("mtdCloseModalDestino");
        },
        DataDetail() {
            this.$emit("addReci", this.type);
            this.mtdClose();
        },
    },
    

};
</script>