<template>
<div>
    <div class="px-2 pb-5 mt-4">
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-xl-5">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>REPORTES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 mt-2">
                                    <button type="button" class="btn btn-sm btn-info" @click="mtdreturncxc">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </button>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-5">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 btn-home mt-2">
                                    <button type="button" class="btn btn-sm w-100" @click="mtdExportExcel" :disabled="data.length == 0 ? true : false">
                                        <i class="fas fa-plus"></i> Descargar
                                    </button>
                                </div>
                                <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input @keyup="calculateData(10)" v-model="search" type="text" class="form-control form-control-sm" placeholder="Buscar por motivo o por Serie - Numero" />
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-2 px-4">
                            <ul class="nav pb-0 mt-3" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button @click="changeTabReport(1)" :class="{
                        'item-button': true,
                        'nav-link': true,
                        active: tabReport === 1,
                      }" id="home-tab" type="button">
                                        Admision 1
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button @click="changeTabReport(2)" :class="{
                        'item-button': true,
                        'nav-link': true,
                        active: tabReport === 2,
                      }" id="home-tab" type="button">
                                        Admision 2
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center text-xs">
                                        <th>N°</th>
                                        <th>VENDEDOR/(A)</th>
                                        <th>SEDE</th>
                                        <th>FECHA DE INGRESO</th>
                                        <th>COMPROBANTE</th>
                                        <th>SERIE</th>
                                        <th>NÚMERO</th>
                                        <th class="table-th-cxc-report-2" v-if="tabReport == 2">
                                            TIPO DE DOCUMENTO DE REFERENCIA
                                        </th>
                                        <th class="table-th-cxc-report-2" v-if="tabReport == 2">
                                            FECHA DE DOCUMENTO DE REFERENCIA
                                        </th>
                                        <th class="table-th-cxc-report-2" v-if="tabReport == 2">
                                            SERIE DE DOCUMENTO DE REFERENCIA
                                        </th>
                                        <th class="table-th-cxc-report-2" v-if="tabReport == 2">
                                            NUMERO DE DOCUMENTO DE REFERENCIA
                                        </th>
                                        <th class="table-th-cxc-report-2" v-if="tabReport == 2">
                                            MOTIVO DE NOTA DE CREDITO
                                        </th>
                                        <th>TIPO PACIENTE</th>
                                        <th class="table-th-cxc-report">CONVENIO</th>
                                        <th>%</th>
                                        <th class="table-th-cxc-report">DNI O RUC DEL ADQUIRIENTE</th>
                                        <th class="table-th-cxc-report">RAZON SOCIAL</th>
                                        <th>DNI DEL PACIENTE</th>
                                        <th class="table-th-cxc-report">INFORMACIÓN DEL PACIENTE</th>
                                        <th class="table-th-cxc-report">
                                            FECHA DE INICIO DEL SERVICIO
                                        </th>
                                        <th class="table-th-cxc-report">
                                            FECHA DE CULMINACION DEL SERVICIO
                                        </th>
                                        <th>DR. ASIGNADO</th>
                                        <th v-if="tabReport == 1">
                                            CATEGORIA
                                        </th>
                                        <th class="table-th-cxc-report" v-if="tabReport == 1">
                                            SERVICIO ESPECIFICO
                                        </th>
                                        <th>REFERENCIA CON GARANTIA</th>
                                        <th>CONTROL DE GARANTIAS</th>
                                        <th v-if="tabReport == 1">
                                            CANTIDAD
                                        </th>
                                        <th>Monto sin IGV</th>
                                        <th>IGV</th>
                                        <th>MONTO</th>
                                        <th>EFECTIVO</th>
                                        <th>TARJETA</th>
                                        <th>DEPOSITO</th>
                                        <th>TRANSFERENCIA</th>
                                        <th>PLANILLA</th>
                                        <th v-if="tabReport == 1">
                                            S/ KAIROS X FARMACIA
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="8" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.vendedora }}</td>
                                        <td>{{ item.sede }}</td>
                                        <td>{{ item.fechaIngreso }}</td>
                                        <td>{{ item.comprobante }}</td>
                                        <td>{{ item.serie }}</td>
                                        <td>{{ item.numero }}</td>
                                        <td v-if="tabReport == 2">{{ item.tipoDocRef }}</td>
                                        <td v-if="tabReport == 2">{{ item.fechaDocRef }}</td>
                                        <td v-if="tabReport == 2">{{ item.serieDocRef }}</td>
                                        <td v-if="tabReport == 2">{{ item.numDocRef }}</td>
                                        <td v-if="tabReport == 2">
                                            {{ item.motivoNotaDebito }}
                                        </td>
                                        <td>{{ item.tipoVenta }}</td>
                                        <td>{{ item.convenio }}</td>
                                        <td>{{ item.porcentaje }}</td>
                                        <td>{{ item.documentoAdquiriente }}</td>
                                        <td>{{ item.nombreAdquiriente }}</td>
                                        <td>{{ item.pacienteDni }}</td>
                                        <td>{{ item.pacienteName }}</td>
                                        <td>{{ item.billingPeriod }}</td>
                                        <td>{{ item.admissionPeriod }}</td>
                                        <td>{{ item.doctor }}</td>
                                        <td v-if="tabReport == 1">{{ item.categoria }}</td>
                                        <td v-if="tabReport == 1">
                                            {{ item.servicioEspecifico }}
                                        </td>
                                        <td>{{ item.garantiaReferencia }}</td>
                                        <td>{{ item.garantiaControl }}</td>
                                        <td v-if="tabReport == 1">{{ item.cantidad }}</td>
                                        <td>{{ item.montonSinIgv }}</td>
                                        <td>{{ item.igv }}</td>
                                        <td>{{ item.monto }}</td>
                                        <td>{{ item.medio[0] }}</td>
                                        <td>{{ item.medio[1] }}</td>
                                        <td>{{ item.medio[2] }}</td>
                                        <td>{{ item.medio[3] }}</td>
                                        <td>{{ item.medio[4] }}</td>
                                        <td v-if="tabReport == 1">{{ item.kairos }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import { utils, writeFileXLSX } from "xlsx";
export default {
    name: "c-admision-pages-anular",
    components: {},
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            swal: null,
            search: "",
            data: [],
            dataShow: [],
            tabReport: 1,
            sheet1: [],
            sheet2: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        //Traer Data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/cxc/datareport",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.dataShow = [];
                    this.selectPage = 1;
                    this.data = response.data;
                    this.data.forEach((element) => {
                        this.sheet1.push({
                            "VENDEDOR/(A)": element.vendedora,
                            SEDE: element.sede,
                            "FECHA DE INGRESO": element.fechaIngreso,
                            COMPROBANTE: element.comprobante,
                            SERIE: element.serie,
                            NÚMERO: element.numero,
                            "TIPO PACIENTE": element.tipoVenta,
                            CONVENIO: element.convenio,
                            "%": element.porcentaje,
                            "DNI O RUC DEL ADQUIRIENTE": element.documentoAdquiriente,
                            "RAZON SOCIAL": element.nombreAdquiriente,
                            "DNI DEL PACIENTE": element.pacienteDni,
                            "INFORMACIÓN DEL PACIENTE": element.pacienteName,
                            "FECHA DE INICIO DEL SERVICIO": element.billingPeriod,
                            "FECHA DE CULMINACION DEL SERVICIO": element.admissionPeriod,
                            "DR. ASIGNADO": element.doctor,
                            CATEGORIA: element.categoria,
                            "SERVICIO ESPECIFICO": element.servicioEspecifico,
                            "REFERENCIA CON GARANTIA": element.garantiaReferencia,
                            "CONTROL DE GARANTIAS": element.garantiaControl,
                            CANTIDAD: element.cantidad,
                            "Monto sin IGV": element.montonSinIgv,
                            IGV: element.igv,
                            MONTO: element.monto,
                            EFECTIVO: element.medio[0],
                            TARJETA: element.medio[1],
                            DEPOSITO: element.medio[2],
                            TRANSFERENCIA: element.medio[3],
                            PLANILLA: element.medio[4],
                            "S/ KAIROS X FARMACIA": element.kairos,
                        });
                        this.sheet2.push({
                            "VENDEDOR/(A)": element.vendedora,
                            SEDE: element.sede,
                            "FECHA DE INGRESO": element.fechaIngreso,
                            COMPROBANTE: element.comprobante,
                            SERIE: element.serie,
                            NÚMERO: element.numero,
                            "TIPO DE DOCUMENTO DE REFERENCIA": element.tipoDocRef,
                            "FECHA DE DOCUMENTO DE REFERENCIA": element.fechaDocRef,
                            "SERIE DE DOCUMENTO DE REFERENCIA": element.serieDocRef,
                            "NUMERO DE DOCUMENTO DE REFERENCIA": element.numDocRef,
                            "MOTIVO DE NOTA DE CREDITO": element.motivoNotaDebito,
                            "TIPO PACIENTE": element.tipoVenta,
                            CONVENIO: element.convenio,
                            "%": element.porcentaje,
                            "DNI O RUC DEL ADQUIRIENTE": element.documentoAdquiriente,
                            "RAZON SOCIAL": element.nombreAdquiriente,
                            "DNI DEL PACIENTE": element.pacienteDni,
                            "INFORMACIÓN DEL PACIENTE": element.pacienteName,
                            "FECHA DE INICIO DEL SERVICIO": element.billingPeriod,
                            "FECHA DE CULMINACION DEL SERVICIO": element.admissionPeriod,
                            "DR. ASIGNADO": element.doctor,
                            "REFERENCIA CON GARANTIA": element.garantiaReferencia,
                            "CONTROL DE GARANTIAS": element.garantiaControl,
                            "Monto sin IGV": element.montonSinIgv,
                            IGV: element.igv,
                            MONTO: element.monto,
                            EFECTIVO: element.medio[0],
                            TARJETA: element.medio[1],
                            DEPOSITO: element.medio[2],
                            TRANSFERENCIA: element.medio[3],
                            PLANILLA: element.medio[4],
                        });
                    });
                    this.calculateData(10);
                })
                .catch((errors) => {
                    //this.$refs.SweetAlert.showError(errors);
                });
        },
        changeTabReport: function (tab) {
            this.tabReport = tab;
        },
        mtdExportExcel: function () {
            var sheet1 = utils.json_to_sheet(this.sheet1);
            var sheet2 = utils.json_to_sheet(this.sheet2);
            var wb = utils.book_new();
            utils.book_append_sheet(wb, sheet1, "admision1");
            utils.book_append_sheet(wb, sheet2, "admision2");
            writeFileXLSX(wb, "CUENTAS POR COBRAR.xlsx");
        },
        mtdreturncxc(){
            this.$emit('viewReportes','data')
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.motive_cancel.toString().toUpperCase();
                    let number = element.voucher.number.toString().toUpperCase();
                    let serie = element.voucher.serie.toString().toUpperCase();
                    return name.includes(query) || number.includes(query) || serie.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>
