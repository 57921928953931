<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label for="name" class="form-label"><strong>Nombre</strong></label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder=" Nombre">
                </div>

                <div class="mb-2 col-xl-12 px-1">
                    <label for="codSunat" class="mb-2"><strong>Código Sunat</strong></label>
                    <input type="text" class="form-control" id="codSunat" name="correo" v-model="data_detail.cod_sunat"
                    maxlength="5" placeholder="12345" @keydown="filterNumber"/>
                </div>

                <div class="col-md-12 col-lg-12 col-xs-12">
                    <label class="text-center mb-2"><strong>Tipo</strong></label>
                    <div class="d-flex justify-content-center">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="me-2" id="inlineCheck1" value="Ingresos"
                            v-model="data_detail.checkBoxTipo" />
                            <label class="form-check-label" for="inlineCheck1"> Ingresos</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="me-2" id="inlineCheck2" value="Egresos"
                            v-model="data_detail.checkBoxTipo" />
                            <label class="form-check-label" for="inlineCheck2"> Egresos</label>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12 col-xs-12">
                    <label class="text-center mb-2"><strong>Areas</strong></label>
                    <br />
                    <div class="form-check form-check-inline" v-for="(option,key) in dataAreas" :key="key">
                        <input type="checkbox" class="me-2" id="option.id" v-bind:value="option.id"
                        v-model="data_detail.checkBoxArea" />
                        <label> {{ option.name }}</label>
                    </div>
                </div>

                <div class="col-md-12 mt-3 pt-1" v-if="num === 1">
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            v-model="data_detail.state"
                            :checked="data_detail.state == 1 ? true : false"
                            aria-checked="false"
                        />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="mtdInsertData" :disabled="cpButton">
                {{ (num == 0 ) ? 'Registrar':'Editar' }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data_detail: {
                name: '',
                cod_sunat: '',
                type: '',
                state: '',
                checkBoxArea: [],
                checkBoxTipo: [],
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num === 1 && newVal) {
                    this.data_detail = {
                        ...newVal,
                        checkBoxArea: [],
                        checkBoxTipo: [],
                    };
                    this.data_detail.checkBoxArea =  newVal.areas.split(", ");
                    this.data_detail.checkBoxTipo = newVal.type.split(", ");
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataAreas: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
                return !(this.data_detail.name !='' && this.data_detail.checkBoxArea.length > 0 && this.data_detail.checkBoxTipo.length > 0 && this.data_detail.cod_sunat !='');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.name ='';
            this.data_detail.cod_sunat ='';
            this.data_detail.type ='';
            this.data_detail.state ='';
            this.data_detail.checkBoxArea = [];
            this.data_detail.checkBoxTipo = [];
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.mtdClose();
        },
        filterNumber: function(e){
            let b = false;
            b = /^\d$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

        mtdInsertData() {
            let cadena = "";
            let tipos="";
            this.data_detail.checkBoxArea.forEach((element) => {
                if (cadena === "") {
                cadena = element;
                } else {
                cadena = cadena + ", " + element;
                }
            });
            this.data_detail.checkBoxTipo.forEach((element) => {
                if (tipos === "") {
                tipos = element;
                } else {
                tipos = tipos + ", " + element;
                }
            });
            this.data_detail.areas = cadena;
            this.data_detail.type=tipos;
            
            if (this.num == 0) {
                this.post({
                    url: this.$store.getters.get__url + "/VoucherType/store",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.data.state == 0) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.$emit('mtdGetData');
                        this.mtdClose();
                    } else {
                        console.log(this.data_detail);
                        this.$refs.SweetAlert.showError("Error");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                 });
            } else {
                this.mtdModifyData();
            }
        },

        mtdModifyData: function () {
            this.post({
                url: this.$store.getters.get__url + "/VoucherType/update",
                token: this.$store.getters.get__token,
                params: this.data_detail,
            })
            .then((response) => {
            if (response.data.state == 0) {
                this.$refs.SweetAlert.showSuccess("Modificacion Exitosa");
                this.$emit('mtdGetData');
                this.mtdClose();
            } else {
                this.$refs.SweetAlert.showError("Error");
            }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
    },
    

};
</script>