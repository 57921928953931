<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true"
  :booTitile="true">
    <div class="row d-flex mt-2 mb-2">
        <div class="input-group ">
          <input type="number" class="form-control form-control-sm" v-model.number="data_detail.percentage" min="0" step="0.01" placeholder="Porcentaje" @input="validateExtension">
          <button type="button" class="btn btn-bm-noradius" @click="rePer()">
            Agregar Porcentaje
          </button>
        </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
  mapActions
} from "vuex";

export default {
  name: "c-asignaciones-modal-assig",
  components: {
      CModal,
  },
  data() {
      return {
        data_detail: {
          percentage: '',
        },
      };
  },
  watch: {
    payload: {
          handler(newVal) {
              if (newVal) {
                this.data_detail = {
                    ...newVal
                };
              }
          },
          immediate: true,
      },
  },
  props: {
      title: String,
      boo_modal: Boolean,
      id: Number,
      idAsign: Number,
      payload: {
          type: Object,
          default: null
      },
  },
  computed: {
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.data_detail.percentage = '';
          this.$emit("closeModalPer");
      },

      validateExtension() {
        if (this.data_detail.percentage < 0) {
          this.data_detail.percentage = 0;
        } else {
          this.data_detail.percentage = parseFloat(this.data_detail.percentage).toFixed(2);
        }
      },

      rePer() {
        if (!this.data_detail.percentage) {
          Swal.fire({
            text: "Ingrese una Cantidad",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
          return;
        }
        this.data_detail.percentage = parseFloat(this.data_detail.percentage).toFixed(2);
        this.$emit('rePer', this.data_detail, this.id, this.idAsign);
        this.data_detail.percentage = '';
      },
  },
};
</script>
<style>

.pdf {
    background-color: #900052;
    border-radius: 10px;
    width: 15px;
    font-size: 12px;
}
</style>
