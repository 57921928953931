<template>
<div>
    <div class="container">
        <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
            <div class="btn-home" role="group">
                <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                    <i class="fas fa-recycle"></i> Cambiar de Sede
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="container mt-4">
                    <div id="div-modal-venta" class="">
                        <div id="div-venta" class="card w-100">
                            <div class="card-body justify-content-center bg-gris-claro">
                                <div id="chart-container">
                                    <canvas id="myChart"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="container mt-4 mb-lg-2">
                    <div id="div-modal-venta" class="">
                        <div id="div-venta" class="card w-100">
                            <div class="card-body justify-content-center bg-gris-claro">
                                <div id="chart-container">
                                    <canvas id="myChart2"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import Chart from 'chart.js/auto';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
    name: 'HomeGerenciaComponent',
    props: {},
    components: {},
    data() {
        return {
            page: null,
            swal: 'TITULO POR  PROPS',
            graficoVentas: {
                type: 'line',
                data: {
                    labels: [],
                    datasets: [{
                        label: 'Ventas S/.',
                        data: [],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            },
            grafico2: {
                type: 'pie',
                data: {
                    labels: [],
                    datasets: [{
                        label: 'Ventas S/.',
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4,
                    }],
                },
                options: {
                    aspectRatio: 1,
                    maintainAspectRatio: false,
                },
                title: {
                    display: true,
                    text: 'Ventas en S/. por Categoria',
                    fontSize: 16, // Tamaño de fuente del título
                    padding: 20 // Espaciado interno del título
                }
            }
        }
    },
    created() {

    },
    mounted() {
        this.mtdGetDataSales();
        this.mtdGetSalesbyCategory();
        const ctx = document.getElementById('myChart');
        const ctx2 = document.getElementById('myChart2');

        let g1 = new Chart(ctx, this.graficoVentas);
        let g2 = new Chart(ctx2, this.grafico2);
        setTimeout(function () {
            g1.update();
            g1.render();
            g1.resize();
            g2.update();
            g2.render();
            g2.resize();
        }, 1000);
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetDataSales: function () {
            const id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Gerencia/MonthlySalesReport/" + id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let temp = [""],
                        temp2 = [100];
                    response.data.forEach(element => {
                        temp.push(element.mes);
                        temp2.push(element.total);
                    });
                    this.graficoVentas.data.labels = temp;
                    this.graficoVentas.data.datasets.data = temp2;
                    (this.graficoVentas.data.datasets[0].data) = temp2;

                })
                .catch(() => {});
        },
        mtdGetSalesbyCategory: function () {
            const id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Gerencia/reportByCategory/" + id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let temp = [],
                        temp2 = [],
                        temp3 = [];
                    response.data.forEach(element => {
                        if (element.total > 0) {
                            temp.push(element.categoria);
                            temp2.push(element.total);
                            temp3.push(this.mtdRandomRgbColor());
                        }
                    });
                    this.grafico2.data.labels = temp;
                    (this.grafico2.data.datasets[0].data) = temp2;
                    (this.grafico2.data.datasets[0].backgroundColor) = temp3;
                })
                .catch(() => {});
        },
        mtdRandomRgbColor() {
            var r = Math.floor(Math.random() * 256); // Valor aleatorio para el componente rojo (0-255)
            var g = Math.floor(Math.random() * 256); // Valor aleatorio para el componente verde (0-255)
            var b = Math.floor(Math.random() * 256); // Valor aleatorio para el componente azul (0-255)
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdSendOption: function (page) {
            this.page = page;
            this.$emit('mtdChangePage', this.page)
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    },
}
</script>
