<template>
<div>

    <div class="row pt-3 text-center">
        <div class="col-12 z-index-button" v-if="viewMedico === 0">
            <div class="py-1 d-flex justify-content-start">
                <button type="button" class="btn text-white btn-sm bg-main text-xs" @click="limpiarhistorial">
                    Vaciar Historial
                </button>
            </div>
        </div>
        <div class="col-xl-4 col-lg-12">
            <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(0)">
                <h6 class="fw-bold color-main d-inline-block">Antecedentes</h6>
                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[0].state && 'active']">
            </div>
            <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[0].state">
                <div class="row d-inline-flex">
                    <div class="col-md-6 mt-1">
                        <div id="div-dm" class="form-group row align-left">
                            <input type="checkbox" id="switch-dm" v-model="background[0].state">
                            <label for="switch-dm" class="col-sm-2 lbl"> </label>
                            <label for="dm" class="col-sm-2 color-main fw-bold">DM</label>
                            <div class="col-sm-7">
                                <input type="text" v-model="background[0].value" class="form-control form-control-input form-control-sm" id="dm" :disabled="validationinputdm">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-1">
                        <div id="div-ar" class="form-group row align-left">
                            <input type="checkbox" id="switch-ar" v-model="background[1].state">
                            <label for="switch-ar" class="col-sm-2 lbl "></label>
                            <label for="ar" class="col-sm-2 color-main fw-bold">AR</label>
                            <div class="col-sm-7">
                                <input type="text" v-model="background[1].value" class="form-control form-control-input form-control-sm" id="ar" :disabled="validationinputar">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-1">
                        <div id="div-hta" class="form-group row align-left  ">
                            <input type="checkbox" id="switch-hta" v-model="background[2].state">
                            <label for="switch-hta" class="col-sm-2 lbl "> </label>
                            <label for="hta" class="col-sm-2 color-main fw-bold">HTA</label>
                            <div class="col-sm-7">
                                <input type="text" v-model="background[2].value" class="form-control form-control-input form-control-sm" id="hta" :disabled="validationinputhta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-1">
                        <div id="div-otro" class="form-group row align-left">
                            <input type="checkbox" id="switch-otro" v-model="background[3].state">
                            <label for="switch-otro" class="col-sm-2 lbl "></label>
                            <label for="otro" class="col-sm-3 color-main fw-bold">Otro</label>
                            <div class="col-sm-6">
                                <input type="text" v-model="background[3].value" class="form-control form-control-input form-control-sm" id="otro" :disabled="validationinputotro">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-1">
                        <div id="div-alergia" class="form-group row align-left">
                            <input type="checkbox" id="switch-alergia" v-model="background[4].state">
                            <label for="switch-alergia" class="col-sm-2 lbl "> </label>
                            <label for="alergia" class="col-sm-6 color-main fw-bold">Alergias</label>
                            <div class="col-sm-12 mt-1 mb-1">
                                <textarea v-model="background[4].value" class="form-control form-control-sm" id="alergia" rows="3" style="resize: none;" :disabled="validationinputalergias" />
                                </div>
          </div>
        </div>

        <div class="col-md-12 mt-1">
          <div id="div-cirugia" class="form-group row align-left">
            <input type="checkbox" id="switch-cirugia" v-model="background[5].state">
            <label for="switch-cirugia" class="col-sm-2 lbl "> </label>
            <label for="cirugia" class="col-sm-6 color-main fw-bold">Cirugías</label>
            <div class="col-sm-12 mt-1 mb-1">
              <textarea v-model="background[5].value" class="form-control form-control-sm" id="cirugia" rows="3"
                style="resize: none;" :disabled="validationinputcirugias" />
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-1" id="content-timelinetable">
          <div id="div-medicamento" class="form-group row align-left">
            <input type="checkbox" id="switch-medicamento" v-model="background[6].state">
            <label for="switch-medicamento" class="col-sm-2 lbl "> </label>
            <label for="medicamento" class="col-sm-10 color-main fw-bold">Medicamentos Frecuentes</label>
            <div class="col-sm-12 mt-1 mb-2">
              <textarea v-model="background[6].value" class="form-control form-control-sm" id="medicamento" rows="3"
                style="resize: none;" :disabled="validationinputmedicamentos" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(1)">
      <h6 class="fw-bold color-main d-inline-block">Examen Físico</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[1].state && 'active']">
    </div>
    <div class="panel-border mb-0 pt-1 px-3 text-xs" v-if="titleDropdown[1].state">
      <div class="row px-3" v-for="(itemE, indexE) in physicalExam" :key="indexE">
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label :for="itemE.label" class="col-sm-5 color-main fw-bold">{{ itemE.label }}</label>
            <div class="col-sm-7">
              <input type="text" class="form-control form-control-input form-control-sm" v-model="itemE.value"
                :id="itemE.label">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="panel-border my-1 text-xs">
      <div class="row px-3">

        <div class="col-md-12 mt-1 ">
          <div id="div-otro" class="form-group row align-left">
            <label for="otro" class="col-sm-5 color-main fw-bold">S/S Pregunta Lab. Emg</label>
            <div class="col-sm-7 mb-2">
              <input type="text" class="form-control form-control-input form-control-sm" id="otro"
                v-model="clinica_history.lab_question">
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

  <div class="col-xl-3 col-lg-12 text-xs">
    <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(2)">
      <h6 class="fw-bold color-main d-inline-block">Signos / Síntomas</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[2].state && 'active']">
    </div>
    <div class="panel-border mb-1" v-if="titleDropdown[2].state">
      <div id="content-timelinetable" class="d-sm-flex d-lg-block my-1">
        <div v-for="(item, index) in checklist" :key="index"
          class="custom-control custom-checkbox color-main align-left px-2">
          <input type="checkbox" class="custom-control-input visually-hidden" :id="'checkbox' + index"
            v-model="item.state">
          <label class="custom-control-label fw-bold" :for="'checkbox' + index">
            <span class="checkmark" :style="{ backgroundColor: item.state ? '#900052' : 'transparent' }"></span>
            {{ item.label }}
          </label>
        </div>
      </div>

      <div v-if="checklist[8].state == true">

        <div class="row mb-1">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <textarea class="form-control" v-model="checklist[8].value" rows="2" placeholder="" style="resize: none;" />
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>

    </div>
    <div class="panel-border mt-1 px-3 pt-1 d-inline-block" @click="toggleDropdown(3)">
      <h6 class="fw-bold color-main d-inline-block">Signos Vitales</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[3].state && 'active']">
    </div>
    <div class="panel-border mb-1" v-if="titleDropdown[3].state">
      <div class="row px-3">
        <div class="col-md-12 mt-1 align-left" v-for="(item2, index) in signosVitales" :key="index">
          <div id="div-estado" class="form-group row">
            <label :for="'input2' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
            <div class="col-xl-7 col-sm-12">
              <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm"
                :id="'input2' + index">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-xl-5 col-lg-12 text-xs">
    <div class="panel-border my-1">
      <div class="row px-3 d-sm-flex ">
        <div class="col-sm-4 my-1">
          <div id="div-te" class="form-group row align-left">
            <label for="te" class="col-sm-4 color-main fw-bold ">T.E.</label>
            <div class="col-xl-8 col-sm-12">
              <input type="text" v-model="clinica_history.fe" class="form-control form-control-input form-control-sm"
                id="te">
            </div>
          </div>
        </div>
        <div class="col-sm-4 my-1">
          <div id="div-fi" class="form-group row align-left">
            <label for="fi" class="col-sm-4 color-main fw-bold">F.I.</label>
            <div class="col-xl-8 col-sm-12">
              <input type="text" v-model="clinica_history.fi" class="form-control form-control-input form-control-sm"
                id="fi">
            </div>
          </div>
        </div>
        <div class="col-sm-4 my-1">
          <div id="div-curso" class="form-group row align-left">
            <label for="curso" class="col-sm-4 color-main fw-bold">Curso</label>
            <div class="col-xl-8 col-sm-12">
              <input type="text" v-model="clinica_history.course"
                class="form-control form-control-input form-control-sm" id="curso">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(6)">
      <h6 class="fw-bold color-main d-inline-block">Relato</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[6].state && 'active']">
    </div>
    <div class="panel-border mb-0 pt-1" v-if="titleDropdown[6].state">
      <div class="row px-3">
        <div class="col-md-12 my-1">
          <textarea id="relato" v-model="clinica_history.story" class="form-control form-control-sm" rows="2"
            placeholder="" style="resize: none;" />
        </div>
      </div>
    </div>

    <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(7)">
      <h6 class="fw-bold color-main d-inline-block">Otros examenes</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[7].state && 'active']">
    </div>
    <div class="panel-border mb-0 pt-1" v-if="titleDropdown[7].state">
      <div class="row px-3">
        <div class="col-md-12 my-1">
          <textarea id="otros" v-model="clinica_history.another_file" class="form-control form-control-sm" rows="3"
            placeholder="" style="resize: none;" />
        </div>
      </div>
    </div>
    <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(5)">
      <h6 class="fw-bold color-main d-inline-block">Diagnosticos</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[5].state && 'active']">
    </div>
    <div class="panel-border mb-0 pt-1" v-if="titleDropdown[5].state">
      <div class="row my-1 px-3">
        <div class="col-md-12">
          <table class="w-100  ">
            <caption class="div-text"></caption>
            <thead class="div-text div-title">
              <tr>
                <th scope="col"></th>
                <th class=" color-main w-25">OTROS</th>
                <th class=" color-main">Dx</th>

                <th class="color-main">CIE10</th>
                <th class="color-main">TIPO</th>
              </tr>
            </thead>
            <tbody class="div-text">
              <tr v-for="index in 3" :key="index" class="px-1">
                <td class=" ">

                  {{ index }}

                </td>
                <td>

                  <input v-model="inputchecked[index]" class="form-check-input me-1" type="checkbox"
                    id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    Otros
                  </label>

                </td>

                <td v-if="inputchecked[index] == false">
                  <select selected class="form-select pb-0 pt-0">
                    <option value="">Buscar...</option>
                    <option>Opción 1</option>
                  </select>
                </td>
                <td v-else>
                  <input type="text" class="form-control form-control-input form-control-sm" />

                </td>
                <td>
                  <input type="text" class="form-control form-control-input form-control-sm" />
                </td>
                <td>
                  <input type="text" class="form-control form-control-input form-control-sm" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(4)">
      <h6 class="fw-bold color-main d-inline-block">Plan</h6>
      <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt=""
        class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[4].state && 'active']">
    </div>
    <div class="panel-border mb-0 pt-1" v-if="titleDropdown[4].state">
      <div class="row mx-0 px-3">
        <div class="col-md-12 py-2">
          <div class="input-group align-items-center">
            <span class="color-main fw-bold">PLAN</span>
          </div>
        </div>
        <div class="col-xl-12 col-sm-12 py-2 ">
          <div class=" flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
            <span class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                class="fas fa-search"></i></span>
            <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataLaboratorio"
              :reduce="(laboratorio) => laboratorio" label="name" placeholder="Laboratorio" required
              @input="mtdaddPlan" v-model="plan_unit" />
          </div>
        </div>
        <div class="col-xl-12 col-sm-12 py-2  ">
          <div class="flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
            <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                class="fas fa-search"></i></span>
            <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataImagenes"
              :reduce="(Imagenes) => Imagenes" label="name" placeholder="Imagenes" required @input="mtdaddPlan"
              v-model="plan_unit" />
          </div>
        </div>
        <div class="col-xl-12 col-sm-12 py-2  ">
          <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
            <span class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                class="fas fa-search"></i></span>
            <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" :options="dataProcedimiento"
              :reduce="(Procedimiento) => Procedimiento" label="name" placeholder="Procedimiento" required
              @input="mtdaddPlan" v-model="plan_unit" />
          </div>
        </div>
      </div>
      <div class="row mx-0 px-3">
        <div v-for="(planunit, index) in plan " :key="index" class="col-md-12 py-1  ">
          <div @click="mtddelete(index, 'mtddeleteplan')"
            class="input-group input-group btn-home px-0 div-pointer text-white fw-bold border bg-main h-100 border-2 align-middle  ">
            <button type=" button" class="w-100 text-start">{{ planunit.name }} </button>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="col-xl-8 col-lg-12 text-xs ">
    <div class="panel-border my-1">
      <div class="row my-1 px-3">
        <div class="col-md-12" id="content-timelinetable">
          <table class="">
            <caption class="div-text"></caption>
            <thead class="div-text div-title">
              <tr>
                <th scope="col" class="color-main">Rp.</th>
                <th class=" color-main">Medicamentos</th>
                <th class="color-main ">Presentación</th>
                <th class="color-main ">Dosis</th>
                <th class="color-main">Vía</th>
                <th class="color-main">Frecuencia</th>
                <th class="color-main">Duración</th>
                <th class="color-main">Acciones</th>
              </tr>
            </thead>
            <tbody class="div-text ">
              <tr class="px-1">
                <td colspan="2" class="w-25 z-index-select">
                  <v-select :options="productdetails" :reduce="(product) => product" label="molecule" :clearable="false"
                    placeholder="molecula" required v-model="selectedProduct" />
                </td>
                <td>
                  <input type="text" v-model="selectedProduct.presentation"
                    class="form-control form-control-input form-control-sm" disabled />
                </td>
                <td>
                  <input type="text" v-model="selectedProduct.dosis"
                    class="form-control form-control-input form-control-sm" disabled />
                </td>
                <td>
                  <input type="text" v-model="recipe_unit.via"
                    class="form-control form-control-input form-control-sm" />
                </td>
                <td>
                  <input type="text" v-model="recipe_unit.frequency"
                    class="form-control form-control-input form-control-sm" />
                </td>
                <td>
                  <input type="text" v-model="recipe_unit.duration"
                    class="form-control form-control-input form-control-sm" />
                </td>
                <td>
                  <button type="button" :disabled="validationInputrecipe" @click="mtdaddrecipe()"
                    class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                </td>
              </tr>
              <tr v-for="(recipeunit, index) in recipe" :key="index" class="px-1">
                <td>
                  <span>{{ index + 1 }}</span>
                </td>
                <td>
                  <span>{{ recipeunit.molecule }}</span>
                </td>
                <td>
                  <span>{{ recipeunit.presentation }}</span>
                </td>
                <td>
                  <span>{{ recipeunit.dosis }}</span>
                </td>
                <td>
                  <span>{{ recipeunit.via }}</span>
                </td>
                <td>
                  <span>{{ recipeunit.frequency }}</span>
                </td>
                <td>
                  <span>{{ recipeunit.duration }}</span>
                </td>
                <td>
                  <button type="button" @click="mtddelete(index, 'mtddeleterecipe')"
                    class=" btn btn-danger btn-sm px-1 py-0 "> <i class="fas fa-trash-alt text-white"></i> </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-12 text-xs">
    <div class="panel-border my-1">
      <div class="row px-3">
        <div class="col-md-12 my-1">
          <div id="div-recomendacion" class="form-group row align-left">
            <label for="recomendacion" class="col-sm-5 color-main fw-bold">Recomendaciones</label>
            <div class="col-xl-7 col-sm-12">
              <textarea id="recomendacion" class="form-control form-control-sm" rows="3"
                v-model="clinica_history.recomendations" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>

  </div>

</div>
<div @click="mtdopenmodal()"
  class=" z-index-button div-pointer sticky-bottom navbar-brand px-0  py-0 bg-main footer-historial text-center nav-footer w-100 ">
  <span class=" w-100 px-0 py-0 text-white">
    <strong><i class="fas fa-search"></i> DESTINO</strong>
  </span>
</div>
<SweetAlert :swal="swal" ref="SweetAlert" @mtddeleteplan="mtddeleteplan" @mtddeleterecipe="mtddeleterecipe" />
<cModaldestino :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodal="mtdclosemodal"
  @mtdstoredata="mtdstoredata" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModaldestino from '../modals/cModaldestino.vue';
export default {
    name: 'c-asistencial-ucineo-historia-hcucineo',
    components: {
        SweetAlert,
        cModaldestino
    },
    data() {
        return {
            productdetails: [],
            swal: null,
            checklist: [{
                    id: 1,
                    label: 'Tos sin flema',
                    state: false
                },
                {
                    id: 2,
                    label: 'Tos con flema',
                    state: false
                },
                {
                    id: 3,
                    label: 'Moco',
                    state: false
                },
                {
                    id: 4,
                    label: 'Dolor de pecho',
                    state: false
                },
                {
                    id: 5,
                    label: 'Dolor de cabeza',
                    state: false
                },
                {
                    id: 6,
                    label: 'Dolor de espalda',
                    state: false
                },
                {
                    id: 7,
                    label: 'Nauseas / vómitos x/día',
                    state: false
                },
                {
                    id: 8,
                    label: 'Diarrea x/día',
                    state: false
                },
                {
                    id: 9,
                    label: 'Otros',
                    state: false,
                    value:''
                }
            ],
            signosVitales: [{
                    id: 1,
                    label: 'FC',
                    value: ''
                },
                {
                    id: 2,
                    label: 'T (°C)',
                    value: ''
                },
                {
                    id: 3,
                    label: 'FR',
                    value: ''
                },
                {
                    id: 4,
                    label: 'SATO2',
                    value: ''
                },
                {
                    id: 5,
                    label: 'HCT',
                    value: ''
                },
                {
                    id: 6,
                    label: 'FIO 2',
                    value: ''
                },
                {
                    id: 7,
                    label: 'CAS',
                    value: ''
                },
                {
                    id: 8,
                    label: 'PAS',
                    value: ''
                },
                {
                    id: 9,
                    label: 'PAD',
                    value: ''
                },
            ],
            physicalExam: [{
                    id: 1,
                    label: 'Estado General',
                    value: ''
                },
                {
                    id: 2,
                    label: 'SNC',
                    value: ''
                },
                {
                    id: 3,
                    label: 'Respiratorio',
                    value: ''
                },
                {
                    id: 4,
                    label: 'Tórax Cv Hemo',
                    value: ''
                },
                {
                    id: 5,
                    label: 'Abdominal',
                    value: ''
                },
                {
                    id: 6,
                    label: 'Renal',
                    value: ''
                },
                {
                    id: 7,
                    label: 'Otros',
                    value: ''
                },
            ],
            inputchecked: [false, false, false, false],
            clinica_history: {
                id: null,
                user_id: null,
                physical_exam: null,
                lab_question: '',
                symploms: null,
                vital_sings: null,
                fe: '',
                fi: '',
                course: '',
                story: '',
                another_file: '',
                diagnostics: null,
                plan: null,
                recipe: null,
                recomendations: '',
                physical_exam_text: null,
                background: null,
                patient_id: null,
                model_id: null
            },
            background: [{
                    id: 1,
                    label: 'DM',
                    state: false,
                    value: ''
                },
                {
                    id: 2,
                    label: 'AR',
                    state: false,
                    value: ''
                },
                {
                    id: 3,
                    label: 'HTA',
                    state: false,
                    value: ''
                },
                {
                    id: 4,
                    label: 'Otro',
                    state: false,
                    value: ''
                },
                {
                    id: 5,
                    label: 'Alergias',
                    state: false,
                    value: ''
                },
                {
                    id: 6,
                    label: 'Cirugías',
                    state: false,
                    value: ''
                },
                {
                    id: 7,
                    label: 'Medicamentos Frecuentes',
                    state: false,
                    value: ''
                }
            ],
            selectedProduct: {
                molecule: '',
                presentation: '',
                dosis: '',
            },
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: ''
            },
            titleDropdown: [{
                    title: 'Antecedentes',
                    state: false,
                }, {
                    title: 'Examen Físico',
                    state: false,
                }, {
                    title: 'Signos',
                    state: false,
                }, {
                    title: 'Signos vitales',
                    state: false,
                },
                {
                    title: 'Plan',
                    state: false,
                },
                {
                    title: 'DX',
                    state: false,
                }, {
                    title: 'Relato',
                    state: false,
                }, {
                    title: 'Otros examenes',
                    state: false,
                }
            ],
            recipe: [],
            dataLaboratorio: [],
            dataImagenes: [],
            dataProcedimiento: [],
            plan_unit: null,
            plan: [],
            modal: {
                title: "",
                modal_form: false,
            },
        }
    },
    created() {
        this.mtdgetproductdetails();
        this.mtdgetSubsubcategories();
        this.mtdfilldata();
    },
    computed: {
        validationInputrecipe: function () {
            let value = true
            if (this.selectedProduct.molecule && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        estadoChecklist: function () {
            const completadas = this.checklist.filter(item => item.checked).length;
            return `${completadas} de ${this.checklist.length} tareas completadas.`;
        },
        validationinputdm: function () {
            let value = true
            if (this.background[0].state) {
                value = false
            }
            return value

        },
        validationinputar: function () {
            let value = true
            if (this.background[1].state) {
                value = false
            }
            return value
        },
        validationinputhta: function () {
            let value = true
            if (this.background[2].state) {
                value = false
            }
            return value
        },
        validationinputotro: function () {
            let value = true
            if (this.background[3].state) {
                value = false
            }
            return value
        },
        validationinputalergias: function () {
            let value = true
            if (this.background[4].state) {
                value = false
            }
            return value
        },
        validationinputcirugias: function () {
            let value = true
            if (this.background[5].state) {
                value = false
            }
            return value
        },
        validationinputmedicamentos: function () {
            let value = true
            if (this.background[6].state) {
                value = false
            }
            return value
        }
    },
    props: {
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        },
        viewMedico: Number,
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdopenmodal: function () {
            this.modal = {
                title: "DESTINO",
                modal_form: true,
            }
        },
        mtdstoredata: function () {
            this.clinica_history.user_id = this.$store.getters.get__user.id;
            this.clinica_history.patient_id = this.datapatients.patient_id
            this.clinica_history.model_id = this.datapatients.model_id
            this.clinica_history.background = this.background;
            this.clinica_history.symploms = this.checklist;
            this.clinica_history.vital_sings = this.signosVitales;
            this.clinica_history.recipe = this.recipe;
            this.clinica_history.physical_exam = this.physicalExam;
            this.clinica_history.plan = this.plan;
            this.post({
                    url: this.$store.getters.get__url + "/Ucineo/store",
                    token: this.$store.getters.get__token,
                    params: this.clinica_history,
                })
                .then((response) => {
                    this.clinica_history = response.data;
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('changePage')

                })
                .catch((errors) => {
                    console.log(errors);
                });
            this.mtdclosemodal();
            this.mtdhideHistoryal();
            this.mtdhiderecipeunit();
            // this.$emit('changePage')
        },
        mtdgetproductdetails: function () {

            this.post({
                    url: this.$store.getters.get__url + "/Consulting/productdetails",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.productdetails = response.data;

                })
                .catch((errors) => {

                    console.log(errors);
                });
        },

        mtdhiderecipeunit: function () {
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: ''
            }

            this.selectedProduct = {
                molecule: '',
                presentation: '',
                dosis: '',
            }
            this.plan_unit = null
        },

        mtdhideHistoryal: function () {
            this.clinica_history = {
                id: null,
                physical_exam: null,
                lab_question: '',
                symploms: null,
                vital_sings: null,
                fe: '',
                fi: '',
                course: '',
                story: '',
                another_file: '',
                diagnostics: null,
                plan: null,
                recipe: null,
                recomendations: '',
                physical_exam_text: '',
                background: null,
                patient_id: null,
                model_id: null
            }
            this.recipe = null,
                this.plan = null
        },
        mtdaddrecipe: function () {
            const exists = this.recipe.some(item => item.product_id === this.selectedProduct.id);

            if (exists) {
                this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
                this.mtdhiderecipeunit();
                return;
            }
            if (this.selectedProduct.dosis == null) {
                this.$refs.SweetAlert.showWarning("Medicamento sin dosis");
                this.mtdhiderecipeunit();
                return;
            }
            this.recipe_unit.order = this.recipe.length + 1;
            this.recipe_unit.product_id = this.selectedProduct.id;
            this.recipe_unit.presentation = this.selectedProduct.presentation;
            this.recipe_unit.dosis = this.selectedProduct.dosis;
            this.recipe_unit.molecule = this.selectedProduct.molecule;
            this.recipe.push(Object.assign({}, this.recipe_unit));
            this.mtdhiderecipeunit();

        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
            // this.recipe.splice(index, 1);
            // for (let i = 0; i < this.recipe.length; i++) {
            //     this.recipe[i].order = i + 1;
            // }
        },
        mtddeleterecipe: function (index) {
            this.recipe.splice(index, 1);
            for (let i = 0; i < this.recipe.length; i++) {
                this.recipe[i].order = i + 1;
            }
        },
        mtddeleteplan: function (index) {
            this.plan.splice(index, 1);
        },

        toggleDropdown(title) {
            this.titleDropdown[title].state = !this.titleDropdown[title].state;
        },
        mtdgetSubsubcategories: function () {
            this.post({
                url: this.$store.getters.get__url + "/Consulting/subsubcategorie",
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.dataLaboratorio = response.data.dataLaboratorio;
                    this.dataImagenes = response.data.dataImagenes;
                    this.dataProcedimiento = response.data.dataProcedimiento;
                }
            });
        },
        mtdaddPlan: function (laboratorioSeleccionado) {
            const exist = this.plan.some(item => item.subsubcategory_id === laboratorioSeleccionado.subsubcategory_id);

            if (exist) {
                this.$refs.SweetAlert.showWarning("El item seleccionado a sido agregado");
                this.mtdhiderecipeunit();
                return;
            }
            this.plan_unit = laboratorioSeleccionado;
            this.plan.push(Object.assign({}, this.plan_unit));
            this.mtdhiderecipeunit();
        },

        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            }
        },
        mtdfilldata: function () {
            if (Object.keys(this.Clinicahistory).length !== 0) {
                this.clinica_history.id = this.Clinicahistory.id;
                this.background = JSON.parse(this.Clinicahistory.background)
                this.plan = JSON.parse(this.Clinicahistory.plan);
                this.recipe = JSON.parse(this.Clinicahistory.recipe);
                this.checklist = JSON.parse(this.Clinicahistory.symploms);
                this.signosVitales = JSON.parse(this.Clinicahistory.vital_sings);
                this.physicalExam = JSON.parse(this.Clinicahistory.physical_exam);
                this.clinica_history.fe = this.Clinicahistory.fe;
                this.clinica_history.fi = this.Clinicahistory.fi;
                this.clinica_history.course = this.Clinicahistory.course;
                this.clinica_history.story = this.Clinicahistory.story;
                this.clinica_history.another_file = this.Clinicahistory.another_file;
                this.clinica_history.recomendations = this.Clinicahistory.recomendations;
                this.clinica_history.lab_question = this.Clinicahistory.lab_question;
            }
        },

        limpiarhistorial() {
            this.mtdhideHistoryal(),
                this.mtdhiderecipeunit(),
                this.checklist = [{
                        id: 1,
                        label: 'Tos sin flema',
                        state: false
                    },
                    {
                        id: 2,
                        label: 'Tos con flema',
                        state: false
                    },
                    {
                        id: 3,
                        label: 'Moco',
                        state: false
                    },
                    {
                        id: 4,
                        label: 'Dolor de pecho',
                        state: false
                    },
                    {
                        id: 5,
                        label: 'Dolor de cabeza',
                        state: false
                    },
                    {
                        id: 6,
                        label: 'Dolor de espalda',
                        state: false
                    },
                    {
                        id: 7,
                        label: 'Nauseas / vómitos x/día',
                        state: false
                    },
                    {
                        id: 8,
                        label: 'Diarrea x/día',
                        state: false
                    },
                    {
                        id: 9,
                        label: 'Otros',
                        state: false,
                        value:''
                    }
                ],

                this.signosVitales = [{
                        id: 1,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'T (°C)',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'FR',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SATO2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'HCT',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'FIO 2',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'CAS',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'PAS',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'PAD',
                        value: ''
                    },
                ],
                this.background = [{
                        id: 1,
                        label: 'DM',
                        state: false,
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'AR',
                        state: false,
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'HTA',
                        state: false,
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'Otro',
                        state: false,
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'Alergias',
                        state: false,
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'Cirugías',
                        state: false,
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'Medicamentos Frecuentes',
                        state: false,
                        value: ''
                    }
                ],
                this.physicalExam = [{
                        id: 1,
                        label: 'Estado General',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'SNC',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'Respiratorio',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'Tórax Cv Hemo',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'Abdominal',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'Renal',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'Otros',
                        value: ''
                    },
                ]

        }
    }
}
</script>
