<template>
<div>
    <div class="d-flex justify-content-center mt-2" v-for="(item, index) in cpData" :key="index">
        <div id="div-caja" class="card w-100" style="height: auto">
            <div class="card-header text-white h5" style="background: #900052; display: flex; justify-content: space-between; align-items: center;">
                <strong style="margin: 0;">Arqueo de Fin de Mes de  {{ mtdGetMes(item.mes) }} - Fecha: {{ item.date }}
                </strong>
            </div>

            <div class="card-body" style="max-height: 485px; overflow-y: auto">
                                        <div id="row div-dataMoney" class="container">
                                            <div class="row">
                                                 <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mt-0 text-center">
                                                  <label class="form-label text-center"><strong>Billetes</strong></label>
                                                  <div v-for="(billete, index) in billetes(item)" :key="index" class="">
                                                      <div class="input-group input-group-sm mb-2">
                                                          <span class="input-group-text col-4">{{ billete.label }}</span>
                                                          <input style="align-items:center;text-align:center" type="text" class="form-control form-control-sm" disabled aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="billete.cantidad" width="10px" />
                                                          <span v-if="billete.total == 0" class="input-group-text col-4">= S/. 0.00</span>
                                                          <span v-else class="input-group-text col-4">= S/.{{ billete.total }}</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-center">
                                                  <label class="form-label text-center"><strong>Monedas</strong></label>
                                                  <div v-for="(moneda, index) in monedas(item)" :key="index" class="">
                                                      <div class="input-group input-group-sm mb-2">
                                                          <span class="input-group-text col-4">{{ moneda.label }}</span>
                                                          <input type="text" style="align-items:center;text-align:center" class="form-control form-control-sm" disabled aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="moneda.cantidad" />
                                                          <span class="input-group-text col-4" v-if="moneda.total == 0">= S/. 0.00</span>
                                                          <span v-else class="input-group-text col-4">= S/. {{ moneda.total }}</span>
                                                      </div>
                                                  </div>
                                              </div>
                                                <div class="col-md-2 col-lg-2 col col-sm-12" style="text-align: center">
                                                    <strong> TOTAL EFECTIVO </strong>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>APERTURA: S/.{{ item.details[0].initial }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>INGRESOS: S/.{{ cpIncome(item) }}</strong>
                                                    </button>

                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>EGRESOS: S/.{{ cpExpense(item) }} </strong>
                                                    </button>

                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>TOTAL: S/.{{ item.details[item.details.length-1].final }} </strong>
                                                    </button>
                                                </div>
                                                <div class="col-md-2 col-lg-2 col col-sm-12" style="text-align: center">
                                                    <strong> OTROS INGRESOS </strong>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Trans.
                                                                : S/.
                                                                {{
                                  ttransferencia(item)
                                  }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Tarjeta:
                                                                : S/.
                                                                {{
                                  ttarjeta(item)
                                  }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Deposito
                                                                : S/.
                                                                {{
                                  tdeposito(item)
                                  }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Planilla
                                                                : S/.
                                                                {{
                                  tplanilla(item)
                                  }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-lg-2 col col-sm-12" style="text-align: center">
                                                    <strong> SALDOS </strong>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Saldo Efec. S/{{ item.details[item.details.length-1].final }}</strong>
                                                    </button>

                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Saldo General:  S/.{{ general(item) }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Vueltos Pte S/{{ vueltos(item) }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong v-if="sobrafalta(item) < 0">
                                                            Faltante :S/{{ parseFloat(sobrafalta(item)) * -1 }}</strong>
                                                        <strong v-else> Sobrante: S/{{ sobrafalta(item) }}</strong>
                                                    </button>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="col-md-4">
                                                        <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>Vueltos Rec: S/.{{ turnedRec(item) }}</strong>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>Depósitos: S/.{{ depositos(item) }}</strong>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>Retiros: S/.{{ retiros(item) }}</strong>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        </div>
    </div>
     <!-- PAGINACION -->
     <div class="col-md-12 py-2">
                            <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-center  mb-2 ml-5">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
</div>
</template>

<script>
export default {
    name: 'c-reporte-mensual-arqueo',
    data() {
        return {
            totalPagesToShow: 5,
            dataShow: [],
            data: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            months: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
        };
    },
    created() {},
    props: {
        dataMes: Array,
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    watch: {
        selectPage() {
            this.calculateData(1);
        },
        dataMes: {
            immediate: true, 
            handler(newArrayDetalle) {
                this.data = newArrayDetalle;
                this.calculateData(1);
            }
        }

    },
    methods: {
        mtdGetMes(item) {
            return this.months[item - 1];

        },

        cpIncome: function (item) {
            let details = item.details;
            let sum = 0;
            details.forEach(element => {
                sum += parseFloat(element.income);
            });
            return parseFloat(sum).toFixed(2);
        },
        cpExpense: function (item) {
            let details = item.details;
            let sum = 0;
            details.forEach(element => {
                sum += parseFloat(element.expense);
            });
            return parseFloat(sum).toFixed(2);
        },
        billetes: function (item) {
            let details = item.details;
            let json =  JSON.parse(details[0].transaction[0].json_cierre);
            let cierrejson = json.billetes;
            let cierre; 
            let dosCientos  = 0;
            let ciento  = 0;
            let cincuenta  = 0;
            let veinte  = 0;
            let diez  = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                dosCientos += cierre.billetes[0].cantidad ? parseInt(cierre.billetes[0].cantidad) : 0; //200
                ciento += cierre.billetes[1].cantidad ? parseInt(cierre.billetes[1].cantidad) : 0; //100
                cincuenta += cierre.billetes[2].cantidad ? parseInt(cierre.billetes[2].cantidad) : 0; //50
                veinte += cierre.billetes[3].cantidad ? parseInt(cierre.billetes[3].cantidad) : 0; //20
                diez += cierre.billetes[4].cantidad ? parseInt(cierre.billetes[4].cantidad) : 0; //10
            });
            cierrejson[0].cantidad = dosCientos;
            cierrejson[1].cantidad = ciento;
            cierrejson[2].cantidad = cincuenta;
            cierrejson[3].cantidad = veinte;
            cierrejson[4].cantidad = diez;
            //cambiamos el valor total
            cierrejson[0].total = parseFloat(cierrejson[0].value * dosCientos).toFixed(2);
            cierrejson[1].total = parseFloat(cierrejson[1].value * ciento).toFixed(2) ;
            cierrejson[2].total = parseFloat(cierrejson[2].value * cincuenta).toFixed(2) ;
            cierrejson[3].total = parseFloat(cierrejson[3].value * veinte).toFixed(2) ;
            cierrejson[4].total = parseFloat(cierrejson[4].value * diez).toFixed(2) ;
            return cierrejson;
        },
        monedas: function (item) {
            let details = item.details;
            //sacar una copia
            let json =  JSON.parse(details[0].transaction[0].json_cierre);
            let cierrejson = json.monedas;
            let cierre; 
            let cinco  = 0;
            let dos  = 0;
            let uno  = 0;
            let cincuenta  = 0;
            let veinte  = 0;
            let diez  = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                cinco += cierre.monedas[0].cantidad ? parseInt(cierre.monedas[0].cantidad) : 0; //200
                dos += cierre.monedas[1].cantidad ? parseInt(cierre.monedas[1].cantidad) : 0; //100
                uno += cierre.monedas[2].cantidad ? parseInt(cierre.monedas[2].cantidad) : 0; //50
                cincuenta += cierre.monedas[3].cantidad ? parseInt(cierre.monedas[3].cantidad) : 0; //20
                veinte += cierre.monedas[4].cantidad ? parseInt(cierre.monedas[4].cantidad) : 0; //10
                diez += cierre.monedas[5].cantidad ? parseInt(cierre.monedas[5].cantidad) : 0; //0.1
            });
            cierrejson[0].cantidad = cinco;
            cierrejson[1].cantidad = dos;
            cierrejson[2].cantidad = uno;
            cierrejson[3].cantidad = cincuenta;
            cierrejson[4].cantidad = veinte;
            cierrejson[5].cantidad = diez;
            //calculamos los valores total 
            cierrejson[0].total = parseFloat(cierrejson[0].value * cinco).toFixed(2);
            cierrejson[1].total = parseFloat(cierrejson[1].value * dos).toFixed(2);
            cierrejson[2].total = parseFloat(cierrejson[2].value * uno).toFixed(2);
            cierrejson[3].total = parseFloat(cierrejson[3].value * cincuenta).toFixed(2);
            cierrejson[4].total = parseFloat(cierrejson[4].value * veinte).toFixed(2);
            cierrejson[5].total = parseFloat(cierrejson[5].value * diez).toFixed(2);
            return cierrejson;
        },
        ttarjeta(item) {
            let details = item.details;
            let cierre;
            let sum = 0;
            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totTarjeta);
            });
            return parseFloat(sum).toFixed(2);
        },
        tdeposito(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totDeposito);
            });
            return parseFloat(sum).toFixed(2);
        },
        ttransferencia(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totTransferencia);
            });
            return parseFloat(sum).toFixed(2);
        },
        tplanilla(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totPlanilla);
            });
            return parseFloat(sum).toFixed(2);
        },
        vueltos(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                let vuelto = cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00'
                sum += parseFloat(vuelto)
            });
            return parseFloat(sum).toFixed(2);
        },
        depositos(item) {
            let details = item.details;
            let cierre;
            let sum = 0;
            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.deposit);
            });
            return parseFloat(sum).toFixed(2);
        },
        retiros(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.retirement);
            });
            return parseFloat(sum).toFixed(2);
        },
        turnedRec(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                let turnedReceived = cierre.turned_received ? parseFloat(cierre.turned_received) : 0;
                sum += turnedReceived;
            });
            return parseFloat(sum).toFixed(2);
        },
        
        general(item) {
            let details = item.details;
            let cierre;
            let sum = 0; let sub = 0;
            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sub = parseFloat(cierre.totTarjeta) +
                parseFloat(cierre.totDeposito) +
                parseFloat(cierre.totTransferencia) +
                parseFloat(cierre.totPlanilla);
                sum += sub;
            });
            return parseFloat(sum).toFixed(2);
        },
        sobrafalta(item) {
            let cierre = JSON.parse(item.details[item.details.length - 1].transaction[0].json_cierre);
            let tot = 0;
            cierre.billetes.forEach(element => {
                tot += parseFloat(element.total);
            });
            cierre.monedas.forEach(element => {
                tot += parseFloat(element.total);
            });
            let tot1 =
                parseFloat(tot) -
                parseFloat(cierre.totEfectivo) -
                parseFloat(cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00')
            return parseFloat(tot1).toFixed(2);
        },
        /** pagination */
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
};
</script>
