<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
      <div class="px-3 mb-2">
          <div class="row d-flex mx-0 px-0">
            <div class="mb-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="description" class="form-label">Fecha de Asignación</label>
                <input type="datetime-local" class="form-control form-control-sm" v-model="data_detail.date_registration" placeholder="FechaAsignación" disabled>
            </div>
            <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="description" class="form-label">Descripción de Tarea</label>
                <textarea class="form-control form-control-sm" v-model="data_detail.desTasks" placeholder="Descripción" rows="1" disabled></textarea>
            </div>
            <div class="mb-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="extension_days" class="form-label">Días Extensión</label>
                <input type="number" id="extension_days" class="form-control form-control-sm" v-model="data_detail.days_extension" min="0" placeholder="Días"  disabled>
            </div>
          </div>
      </div>
      <div class="row mx-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                <table id="tb-anular" class="table table-bordered">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 13px">
                        <tr class="text-white text-center align-middle">
                          <th scope="col">N°</th>
                          <th scope="col">Fecha Subida</th>
                          <th scope="col">Archivo</th>
                        </tr>
                    </thead>
                    <tbody class="border-main" style="font-size: 12px">
                      <tr class="table-body text-center" v-if="item.length === 0">
                          <td colspan="7" class="text-center">No hay registros</td>
                      </tr>
                      <tr v-for="(item, index) in item" :key="index" class="text-center align-middle">
                        <template>
                          <td>{{index + 1}}</td>
                          <td>{{item.date_upload | formatDateTime}}</td>
                          <td>
                            <a class="pdf text-center px-2 py-2" href="#" @click="openFile(item.file)">
                                  <i class="fas fa-file text-white"></i>
                              </a>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
      <template #footer>
        <fieldset class="btn-group" aria-labelledby="button-group-label">
          <button class="btn btn-success btn-sm" @click="statusCurso">
            <strong>A EN PROCESO</strong>
          </button>
        </fieldset>
      </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
  mapActions
} from "vuex";
import moment from "moment";

export default {
  name: "c-modal-porHacer",
  components: {
      CModal,
  },
  data() {
      return {
          servicesPa: [],
          selectedPa: "",
          data_detail: {
              desTasks: '',
              days_extension: '',
              date_registration: '',
          },
      };
  },
  watch: {
      payload: {
          handler(newVal) {
            if (newVal) {
                this.data_detail = {
                    ...newVal
                };
              }
          },
          immediate: true,
      },
  },
  props: {
      title: String,
      boo_modal: Boolean,
      payload: {
        type: Object,
        default: null
      },
      item: {
          type: Array,
          default: () => []
      },

  },
  computed: {
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.$emit("closeModalHacer");
      },
      openFile(url) {
        window.open('assets/' + url, '_blank');
      },
      statusCurso() {
        Swal.fire({
          title: "¿Seguro de cambiar esta Tarea a EN CURSO?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Cambiar",
          width: "400px",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("statusCurso", this.payload.idAsign);
          }
        });
      },
  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>
<style>
.custom-button {
    font-size: 10px;
    padding: 7px;
    border-radius: 70%;
    border: 1px solid #ccc;
}
.accion-2 {
    background-color: #e50303;
    border-radius: 10px;
    width: 15px;
    font-size: 10px;
}
</style>
