<template>
  <div>
    <nav
      id="nav-menu"
      class="fixed-bottom py-0 d-flex justify-content-around mt-2"
      style="background: rgb(162, 162, 162); overflow-x: auto; white-space: nowrap;"
    >
    <div
        :style="mtdGetStyle('anulaciones')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('anulaciones')"
      >
        <span :style="mtdGetStyleSpan('anulaciones')" class="btn w-100"
          ><strong>PERMISOS DE ANULACIÓN</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('egresoingreso')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('egresoingreso')"
      >
        <span :style="mtdGetStyleSpan('egresoingreso')" class="btn w-100"
          ><strong>PERMISOS DE CAJA</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('repcaja')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('repcaja')"
      >
        <span :style="mtdGetStyleSpan('repcaja')" class="btn w-100"
          ><strong>REPORTES DE CAJA</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('ingresoegreso')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('ingresoegreso')"
      >
        <span :style="mtdGetStyleSpan('ingresoegreso')" class="btn w-100"
          ><strong>REPORTES DE INGRESO/EGRESO</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('consultas')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('consultas')"
      >
        <span :style="mtdGetStyleSpan('consultas')" class="btn w-100"
          ><strong>CONSULTAR DOCUMENTO</strong></span
        >
      </div>
      <div
      :style="mtdGetStyle('egresos')"
      class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      @click="mtdSendOption('egresos')"
    >
      <span :style="mtdGetStyleSpan('egresos')" class="btn w-100"
        ><strong>RENDIR EGRESOS</strong></span
      >
    </div>
    <div
    :style="mtdGetStyle('otrosper')"
    class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
    @click="mtdSendOption('otrosper')"
    >
      <span :style="mtdGetStyleSpan('otrosper')" class="btn w-100"><strong>OTROS PERMISOS</strong></span>
    </div>
   <div
    :style="mtdGetStyle('permisocierrecaja')"
    class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
    @click="mtdSendOption('permisocierrecaja')"
    >
      <span :style="mtdGetStyleSpan('permisocierrecaja')" class="btn w-100"><strong>AUT. DE CIERRE DE CAJA</strong></span>
    </div>
    <div
      :style="mtdGetStyle('passage')"
      class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      @click="mtdSendOption('passage')"
      >
        <span :style="mtdGetStyleSpan('passage')" class="btn w-100"><strong>PASAJES</strong></span>
    </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarComponent",
  props: {
    pageView: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: null,
    };
  },
  created() {},
  methods: {
    mtdGetStyle: function (option) {
      if (
        option == this.page &&
        this.pageView != "home" &&
        this.pageView != "close" 
      )
        return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (
        option == this.page &&
        this.pageView !== "home" &&
        this.pageView !== "close"
      )
        return "color:white";
      return "text-dark";
    },
    mtdSendOption: function (page) {
      this.page = page;
      this.$emit("mtdChangePage", this.page);
    },
  },
};
</script>

<style scoped>
#contenedor-menu {
  justify-content: center;
}

#lista_menu_home {
  font-family: Arial;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}

li {
  background-color: #900052;
  float: left;
  text-align: center;
  text-decoration: none;
}

li a {
  color: #ffffff;
  font-weight: bold;
  margin: 0;
}

.nav-footer:hover {
  color: #000000;
  /* background-color:rgb(162, 162, 162); */
  background: #900052;
}

#nav {
  position: absolute;
  float: left;
  bottom: 0px;
}
#img_logo {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
}

@media (max-width: 800px), 
       (max-width: 820px) and (min-height: 1180px), 
       (max-width: 1024px) and (min-height: 1366px) {
  #title-user,
  #title-cash,
  #title,
  #title-date {
    text-align: right !important;
  }
  #img_logo img {
    width: 175px !important;
  }

  li {
    float: none;
  }

  #nav-menu {
    position: inherit;
  }
  /**  */
  #nav-menu {
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  #title-cash,
  #title,
  #title-date {
    text-align: center !important;
  }
  #title-user {
    text-align: right !important;
  }
}

strong {
  font-size: 14px;
}
</style>
