var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"btn-home col-md-12 mt-3 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-outline-main",attrs:{"type":"button"},on:{"click":_vm.returnHome}},[_c('i',{staticClass:"fas fa-home-lg-alt"}),_vm._v(" Inicio ")])])]),_c('div',{staticClass:"w-100 mt-2 pb-5",attrs:{"id":"div-modal-anular"}},[_c('ul',{staticClass:"nav pb-0",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{class:{
                  'item-button': true,
                  'nav-link': true,
                  active: _vm.page === 'oc',
                },attrs:{"id":"home-tab","type":"button"},on:{"click":function($event){return _vm.changePage('oc')}}},[_vm._v(" Facturas OC ")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{class:{
                  'item-button': true,
                  'nav-link': true,
                  active: _vm.page === 'os',
                },attrs:{"id":"profile-tab","type":"button"},on:{"click":function($event){return _vm.changePage('os')}}},[_vm._v(" Facturas OS ")])])]),_c('div',{staticClass:"border border-main mb-4"},[(_vm.page == 'oc' )?_c('PagoOc',{attrs:{"type":_vm.page}}):_vm._e(),(_vm.page == 'os' )?_c('PagoOs',{attrs:{"type":_vm.page}}):_vm._e()],1)])]),_c('SweetAlert',{ref:"SweetAlert",attrs:{"swal":_vm.swal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }