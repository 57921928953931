<template>
  <CModal
   @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'md'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="mb-3" v-if="action == 'editGroup'">
        Pertenece al Grupo: [ {{ nameBeds }} ]
      </div>
      <label class="mb-3">Escoge una opcion: </label>
      <div v-if="action != 'store'"></div>
      <div class="input-group mb-3" v-else>
        <div class="input-group-text">
          <span style="border: none" class="input-group-text"
            ><i class="fas fa-hand-pointer"></i
          ></span>
        </div>
        <select
          v-model="selectedOption"
          class="form-control form-control-sm"
          aria-label="Default select example"
        >
          <option disabled value="0">Seleccione ...</option>
          <option v-bind:value="1">Nueva Agrupacion</option>
          <option v-bind:value="2">Agrupar con un grupo existente</option>
        </select>
      </div>
      <div id="select" v-if="selectedOption == 1" class="center-text">
        <hr />
        <!--radio-->
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="tres"
            value="1"
            v-model="radio"
          />
          <label class="form-check-label" for="tres">Cama sola</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            id="cuatro"
            value="2"
            v-model="radio"
          />
          <label class="form-check-label" for="cuatro">Con otras camas</label>
        </div>
        <!--Fin de bloque grupo-->
      </div>
      <div v-else-if="selectedOption == 2">
        <hr />
        <label v-if="nameBedsGroup.length != 0" class="mb-2"
          >Agregar al grupo:
        </label>
        <label v-else class="text-center mb-2">No hay grupos existentes </label>
        <br />
        <div
          class="form-check form-check-inline"
          v-for="(group, index) in nameBedsGroup"
          :key="index"
        >
          <input
            class="form-check-input"
            type="radio"
            :id="'radio'+index"
            v-bind:value="group.id"
            v-model="radioGrupo"
          />
          <label class="form-check-label"
            ><b>Grupo: {{ group.item }} </b> [ {{ group.nameBeds }} ]</label
          >
        </div>
      </div>
      <div id="chooseBeds" class="mb-1" v-show="mostrar">
        <!--camas sin agrupar -->
        <hr />
        <div v-if="messageGroup == true" class="text-center">
          <label class="mb-1">Todas las camas están agrupadas</label>
        </div>
        <div v-else>
          <label class="mb-2">Agrupar con: </label>
        </div>
        <br />
        <div
          class="form-check form-check-inline"
          v-for="(option, ind) in bedNoAgrup"
          :key="ind"
        >
          <div v-if="option.agrupation === 0">
            <input
              type="checkbox"
              class="me-2 form-check-input"
              v-bind:value="option.id"
              v-model="checkBoxBed"
            />
            <label> {{ option.name }}</label>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      &nbsp;
      <button :disabled="cpButtonFormGroup" @click="mtdCreateBedGroup"
        color="danger" class="btn btn-success btn-sm">
        <i :class="action == 'store' ? 'fas fa-save' : 'fas fa-edit'" />&nbsp;{{
          action == "store" ? "Registrar" : "Editar"
        }}
      </button>
      &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
  name: "c-vigilancia-modal-control",
  components: {
    CModal,
    CButtonClose,
    SweetAlert,
  },
  data() {
    return {
      selectedOption: 0,
      radio: 0,
      swal: null,
      mostrar: false,
      checkBoxBed:[],
      bedGroup: {
        id: "",
        floor_id: "",
        bed: "",
      },
      radioGrupo:""
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    nameBedsGroup: [],
    size: String,
    action: String,
    messageGroup:Boolean,
    bedNoAgrup:[],
    item:{}
  },
  computed: {
    btnClasses() {
      return [`btn btn-${this.color || "primary"}`];
    },
    cpButtonFormGroup: function () {
      if (this.selectedOption == 1 || this.selectedOptionEdit == 2) {
        if (this.radio == 2) {
          this.mostrar = true;
          if (this.checkBoxBed.length == 0) {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.radio == 1) {
            this.mostrar = false;
            return false;
          }
          if (this.radio == "" || this.radioGrupo == "") {
            this.mostrar = false;
            if (this.radioGrupo != "") {
              return false;
            }
            return true;
          } else {
            this.mostrar = false;
            return false;
          }
        }
      } else {
        if (this.selectedOption == "") {
          if (this.selectedOptionEdit == 1) {
            this.mostrar = false;
            return false;
          }
          return true;
        } else {
          if (this.radioGrupo == "") {
            this.mostrar = false;
            return true;
          } else {
            this.mostrar = false;
            return false;
          }
        }
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdCreateBedGroup: function () {
      this.bedGroup.floor_id = this.item.floor_id;
      if (this.action == "store") {
        //comprobar si es nuevo grupo o en ya existente
        if (this.selectedOption == 1) {
          //nuevo grupo
          let beds = "".concat(this.item.id);
          if (this.checkBoxBed.length !== 0) { //verificar si se agrupa con mas camas
            this.checkBoxBed.forEach((element) => {
              if (beds === "") {
                beds = element;
              } else {
                beds = beds + ", " + element;
              }
            });
          }
          this.bedGroup.bed = beds; //guardamos con el id de las camas
          this.mtdInsertBedGroup();
        } else {
          //registrar con un grupo ya existente
          this.bedGroup.id = this.radioGrupo;
          this.bedGroup.bed = this.item.id;
          this.mtdEditBedGroup(1);
        }
      } else {
        if (this.selectedOptionEdit == 1) {
          //colocar cama en un nuevo grupo y borramos de bed del grupo anterior
          this.bedGroup.bed = this.item.id;
          this.mtdInsertBedGroup(); //se crea grupo nuevo
          this.bedGroup.id = this.bedRoom.id;
          this.bedGroup.bed = this.item.id; //guardamos con el id de las camas
          this.mtdEditBedGroup(3);
        } else {
          //cambiar a un grupo existente
          let idgrupoActual = this.bedRoom.id;
          if (this.cantBedRoom == 1) {
            //cambiar al grupo seleccionado y mi grupo era de 1
            this.bedGroup.id = this.radioGrupo;
            this.bedGroup.bed = this.item.id;
            this.mtdEditBedGroup(2);
            this.mtdDeleteBedGroup(); //eliminar el grupo anterior
          } else {
            this.bedGroup.id = this.radioGrupo; //registrar con un grupo de la bd
            this.bedGroup.bed = this.item.id;
            this.mtdEditBedGroup(2);
            this.bedGroup.id = idgrupoActual; //Elimarla del grupo anterior
            this.bedGroup.bed = this.item.id;
            this.mtdEditBedGroup(3);
          }
        }
      }
    },
    mtdInsertBedGroup: function () {
      this.post({
        url: this.$store.getters.get__url + "/BedGroup/store",
        token: this.$store.getters.get__token,
        params: this.bedGroup,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.selectedOption= 0,
            this.radio= 0,
            this.mostrar= false,
            this.checkBoxBed=[],
            this.bedGroup= {
              id: "",
              floor_id: "",
              bed: "",
            },
            this.$refs.SweetAlert.showSuccess("Cama agrupada correctamente");
            //this.$emit('sendDataGroup',response.groups_details,this.item.id)
            this.mtdClose(response.data.floor_id);
          }
        })
        .catch((errors) => { });
    },
    mtdEditBedGroup: function (action) {
      this.post({
        url: this.$store.getters.get__url + "/BedGroup/update",
        token: this.$store.getters.get__token,
        params: this.bedGroup,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            if (action == 2) {
              /** todo correcto **/
              this.$refs.SweetAlert.showSuccess("Cambio registrado correctamente");
            } else {
              if (action == 1) {
                /** todo correcto **/
                this.$refs.SweetAlert.showSuccess(response.message);
              }
            }
            //this.$emit('sendDataGroup',response.groups_details,this.item.id);
            this.mtdClose(response.data.floor_id);
          } else {
          }
        })
        .catch((errors) => { });
    },
    mtdClose(floor_id) {
      this.checkBoxBed = [];
      this.bedGroup = {
        id: "",
        floor_id: "",
        bed: "",
      };
      this.radioGrupo = "";
      this.$emit("mtdHodeModalAddGroup",floor_id);
    },
  },
};
</script>

<style scoped>
.inpt_color {
  text-align: center !important;
  background-color: cadetblue !important;
  border-radius: 20px;
  color: white;
}
.inpt_color::placeholder {
  color: white;
}
.form-check-input:checked {
  background-color: #900052;
  border-color: #900052;
}
</style>
