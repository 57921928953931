<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title"
        :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label for="titulo" class="form-label">Título</label>
                    <input type="text" id="titulo" name="titulo" v-model="data_detail.title"
                        class="form-control form-control-sm" placeholder="Ingrese el título">
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label class="form-label">Correo saliente</label>
                    <select v-model="data_detail.email" class="form-control form-control-sm">
                        <option value="0">[Seleccione un correo...]</option>
                        <option v-for="(item, index) in emails" :key="index" :value="item.id">{{ item.email }}</option>
                    </select>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="subject" class="form-label">Asunto</label>
                    <input type="text" id="subject" name="subject" v-model="data_detail.subject"
                        class="form-control form-control-sm" placeholder="Ingrese el asunto">
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="description" class="form-label">Descripción</label>
                    <textarea name="description" id="description" class="form-control form-control-sm"
                        placeholder="Ingrese el mensaje" v-model="data_detail.description"></textarea>
                </div>

                <div class="mb-2 col-xl-12 px-1">
                    <label for="" class="form-label">Archivos</label>
                    <div class="form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchPDF"
                            v-model="data_detail.uploadPDF" />
                        <label class="form-check-label mx-2 mb-2" for="flexSwitchPDF">PDF</label>
                    </div>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-regular fa-file"></i>
                            </span>
                        </div>
                        <div class="input-group-text">
                            <input @change="getFilePdf" name="file" ref="fileInput" type="file"
                                class="form-control form-control-sm" :disabled="!data_detail.uploadPDF">
                        </div>
                    </div>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <div class="form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchImage"
                            v-model="data_detail.uploadImage" />
                        <label class="form-check-label mx-2 mb-2" for="flexSwitchImage">
                            IMAGEN
                        </label>
                    </div>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-regular fa-image"></i>
                            </span>
                        </div>
                        <div class="input-group-text">
                            <input name="file" @change="getFileImg" type="file" class="form-control form-control-sm"
                                :disabled="!data_detail.uploadImage" ref="fileInputImg">
                        </div>

                    </div>
                </div>
                <div class="col-md-12 mt-3 pt-1" v-if="num === 1">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false"
                            aria-checked="false" />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                        </label>
                    </div>
                </div>

            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
                {{ (num == 0) ? 'Registrar' : 'Editar' }}
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-emailContent",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                title: '',
                email:0,
                subject: '',
                description: '',
                state: '',
                uploadPDF: '',
                uploadImage: '',
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        emails:{
            type: Array,
            default:[]
        }
    },
    computed: {
        cpButton() {
            return !(this.data_detail.title != '' && this.data_detail.subject != '' && this.data_detail.description != '');

        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                title: '',
                subject: '',
                description: '',
                state: '',
            };
            this.clearFile();
            this.$emit("closeModal");
        },
        getFilePdf(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (
                !/\.(pdf)$/i.test(file.name)
            ) {
                Swal.fire({
                    text: "Seleccione un archivo formato PDF",
                    icon: "info",
                    confirmButtonColor: "#900052",
                });
                fileInput.value = '';
                this.data_detail.uploadPDF = '';
            } else {
                this.data_detail.uploadPDF = file;
            }
        },
        getFileImg(e) {
            let fileInputImg = this.$refs.fileInputImg;
            let file = e.target.files[0];
            if (
                !/\.(png)$/i.test(file.name) &&
                !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)
            ) {
                Swal.fire({
                    text: "Seleccione un archivo formato PNG, JPG o JPEG",
                    icon: "info",
                    confirmButtonColor: "#900052",
                });
                fileInputImg.value = '';
                this.data_detail.uploadImage = '';
            } else {
                this.data_detail.uploadImage = file;
            }
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.data_detail.uploadPDF = '';
            this.clearFile();
            this.mtdClose();
        },

        clearFile(){
            let fileInput = this.$refs.fileInput;
            fileInput.value = '';
            this.data_detail.uploadImage = '';
            let fileInputImg = this.$refs.fileInputImg;
            fileInputImg.value = '';
        }
    },
};
</script>