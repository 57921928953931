<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <div class="input-group">
                      <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i
                            class="fas fa-barcode"></i></span> <strong>Serie Actual</strong>
                      </div>
                      <input type="text" class="form-control" v-model="data_detail.input"
                        maxlength="5" placeholder="B001" />
                    </div>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <div class="input-group">
                      <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i
                            class="fas fa-barcode"></i></span> <strong>Series Usadas</strong>
                      </div>
                      <input type="text" class="form-control" v-model="data_detail.old"  disabled/>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="mtdInsertSerie" :disabled="cpButton">
                {{ (num == 3 ) ? 'Guardar':'Guarda' }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data_detail: {
                actual: '',
                old: '',
                input: '',
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 3){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
                return !(this.data_detail.input !='' );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.actual ='';
            this.data_detail.old ='';
            this.data_detail.input ='';
            this.$emit("closeModalSe");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
        mtdInsertSerie: function(){
            this.post({
                url: this.$store.getters.get__url + "/VoucherType/store/serie",
                token: this.$store.getters.get__token,
                params: this.data_detail,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit('mtdGetData');
                    this.mtdClose();
                }
            })
            .catch((errors) => { 
                this.$refs.SweetAlert.showError(errors);
            });
        },
    },
    

};
</script>