<template>
<div class="px-5">
    <div class="px-4 pb-5 pt-2">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <ul class="nav" id="myTab" role="tablist">
            <li class="nav-item  " role="presentation">
                <button @click="changePage('medico')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'medico' }" id="home-tab" type="button">
                    Medico
                </button>
            </li>
            <li class="nav-item  " role="presentation">
                <button @click="changePage('enfermera')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'enfermera' }" id="profile-tab" type="button">
                    Enfermera
                </button>
            </li>
            <li class="nav-item " role="presentation">
                <button @click="changePage('enfermeria')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'enfermeria' }" id="home-tab" type="button">
                    Téc. Enfermeria
                </button>
            </li>
            <li class="nav-item " role="presentation">
                <button @click="changePage('laboratorio')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'laboratorio' }" id="profile-tab" type="button">
                    Téc. Laboratorio
                </button>
            </li>
            <li class="nav-item  " role="presentation">
                <button @click="changePage('farmacia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'farmacia' }" id="home-tab" type="button">
                    Téc. Farmacia
                </button>
            </li>
            <li class="nav-item  " role="presentation">
                <button @click="changePage('biologia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'biologia' }" id="profile-tab" type="button">
                    Biólogia
                </button>
            </li>
            <li class="nav-item " role="presentation">
                <button @click="changePage('qft')" :class="{ 'item-button1':true,'nav-link': true, 'active': page === 'qft' }" id="profile-tab" type="button">
                    QFt
                </button>
            </li>

        </ul>
        <div class="tab-content" id="myTabContent">
            <Medico v-if="page=='medico'" />
            <Enfermera v-if="page=='enfermera'" />
            <Enfermeria v-if="page=='enfermeria'" />
            <Laboratorio v-if="page=='laboratorio'" />
            <Farmacia v-if="page=='farmacia'" />
            <Biologia v-if="page=='biologia'" />
            <Qft v-if="page=='qft'" />
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import Medico from '@/components/Maintenance/pages/Honorarios-Asistencial/Medico.vue';
import Enfermera from '@/components/Maintenance/pages/Honorarios-Asistencial/Enfermera.vue';
import Enfermeria from '@/components/Maintenance/pages/Honorarios-Asistencial/Tec-Enfermeria.vue';
import Laboratorio from '@/components/Maintenance/pages/Honorarios-Asistencial/Laboratorio.vue';
import Biologia from '@/components/Maintenance/pages/Honorarios-Asistencial/Biologia.vue';
import Farmacia from '@/components/Maintenance/pages/Honorarios-Asistencial/Farmacia.vue';
import Qft from '@/components/Maintenance/pages/Honorarios-Asistencial/Qft.vue';
export default {
    name: "c-mantenedores-pages-bed",
    components: {
        SweetAlert,
        Medico,
        Enfermera,
        Enfermeria,
        Laboratorio,
        Biologia,
        Farmacia,
        Qft
    },
    data() {
        return {
            swal: null,
            page: 'medico',
        };
    },
    computed: {

    },
    created() {
        //this.mtdGetCampus();
    },
    methods: {
        ...mapActions(["get", "post"]),

        changePage: function (page) {
            this.page = page;

        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    }
}
</script>
