<template>
<div>
    <div class="px-5 pt-2 pb-5">
        <div class="row">
            <div id="btn-home" class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <!-- <div id="div-modal-cxc" class="d-flex justify-content-center"> -->
        <div id="div-modal-cxc" class="card w-100 d-flex justify-content-center">
            <div class="card-header text-white h4 text-center bg-main">
                <strong>REPORTE DE NOTAS DE CREDITO</strong>
            </div>

            <div class="card-body w-100">
                <div class="row">
                    <div class="col-md-12 px-3">
                        <div class="row d-flex">
                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-3">Desde</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" />
                                </div>
                            </div>

                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-3">Hasta</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" />
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <button type="button" name="accion" class="btn btn-sm mb-3 w-100 btn-register text-white" @click="mtdGetData">
                                    <i class="fas fa-search"></i> Buscar
                                </button>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                <button @click="exportExcel" type="button" name="accion" class="btn btn-sm mb-3 w-100 btn-register text-white">
                                    <i class="fas fa-file-excel"></i> Exportar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" id="content-timelinetable">
                        <table id="tb-cxc" class="table table-bordered border-main">
                            <thead>
                                <tr align="center" class="text-white border-white bg-main text-xxs" style="vertical-align: middle;">
                                    <th scope="col" colspan="1" rowspan="2">
                                        N°
                                    </th>
                                    <th scope="col" colspan="2" rowspan="2">
                                        NUMERO CORRELATIO DEL REGISTRO CODIGO
                                        UNICO DE LA OPERACION</th>
                                    <th scope="col" colspan="1" rowspan="2">
                                        FECHA EMISION DE COMPROBANTE
                                        DE PAGO DE DOCUMENTO
                                    </th>
                                    <th scope="col" colspan="1" rowspan="2">
                                        FECHA DE VENCIMIENTO
                                    </th>
                                    <th scope="col" colspan="1" rowspan="2">
                                        COD.
                                    </th>
                                    <th scope="col" colspan="1" rowspan="2">
                                        MOTIVO
                                    </th>
                                    <th scope="col" colspan="3">COMPROBANTE DE PAGO
                                        O DOCUMENTO
                                    </th>
                                    <th scope="col" colspan="1">BASE</th>
                                    <th scope="col" colspan="1" rowspan="2">IGV Y/O IPM
                                    </th>
                                    <th scope="col" colspan="1" rowspan="2">IMPORTE TOTAL DEL
                                        COMPROBANTE DE PAGO
                                    </th>
                                    <th scope="col" colspan="4">REFERENCIA DE COMPROBANTE O
                                        DOCUMENTO ORIGINAL QUE NO SE MODIFICA
                                    </th>
                                    <!-- columnas de  ingresos -->
                                    <th cope="col" colspan="1" rowspan="2">CUENTA CONTABLE</th>
                                    <th cope="col" colspan="1" rowspan="2">TIPO CLIENTE</th>
                                    <th cope="col" colspan="1" rowspan="2">COD. ANEXO</th>
                                    <th cope="col" colspan="1" rowspan="2">ACREEDOR</th>
                                    <th cope="col" colspan="1" rowspan="2">NOM. ACREEDOR</th>
                                    <th cope="col" colspan="1" rowspan="2">DNI PACIENTE</th>
                                    <th cope="col" colspan="1" rowspan="2">NOM. PACIENTE</th>
                                    <th cope="col" colspan="1" rowspan="2">TIPO DOCUMENTO</th>
                                    <th cope="col" colspan="1" rowspan="2">N° DOCUMENTO</th>
                                    <th cope="col" colspan="1" rowspan="2">FECH. EMISION</th>
                                    <th cope="col" colspan="1" rowspan="2">FECH. VENCIMIENTO</th>
                                    <th cope="col" colspan="1" rowspan="2">FECH. COMPUTO DE COBRO</th>
                                    <th cope="col" colspan="1" rowspan="2">FECH. DE INICIO DE SERVICIO</th>
                                    <th cope="col" colspan="1" rowspan="2">FECH. DE FIN DE SERVICIO</th>
                                    <th cope="col" colspan="1" rowspan="2">COD. EPS</th>
                                    <th cope="col" colspan="1" rowspan="2">PERIODO FACTURO</th>
                                    <th cope="col" colspan="1" rowspan="2">PERIODO DEVENGADO</th>
                                    <th cope="col" colspan="1" rowspan="2">REFERENCIA CON GARANTIA</th>
                                    <th cope="col" colspan="1" rowspan="2">CONTROL DE GARANTIAS</th>
                                    <th cope="col" colspan="1" rowspan="2">TIPO ANEXO</th>
                                    <th cope="col" colspan="1" rowspan="2">CLASIFICACION BM</th>
                                    <th cope="col" colspan="1" rowspan="2">DESCRIPCION</th>
                                    <th cope="col" colspan="1" rowspan="2">CANTIDAD</th>
                                    <th cope="col" colspan="1" rowspan="2">BASE IMPONIBLE GRAVADA</th>
                                    <th cope="col" colspan="1" rowspan="2">BASE IMPONIBLE NO GRAVADA</th>
                                    <th cope="col" colspan="1" rowspan="2">IGV</th>
                                    <th cope="col" colspan="1" rowspan="2">TOTAL FACTURADO</th>
                                    <th cope="col" colspan="1" rowspan="2">IMPORTE AFECTO A RENTA</th>
                                    <th cope="col" colspan="1" rowspan="2">
                                        PDF
                                    </th>

                                </tr>
                                <tr align="center" class="text-white border-white text-xs" style="background: #900052 ; vertical-align: middle;">
                                    <th scope="col">TP</th>
                                    <th scope="col">SERIE</th>
                                    <th scope="col">NUMERO</th>
                                    <th scope="col">IMPONIBLE DE LA OPERACIÓN GRAVADA
                                    </th>
                                    <th scope="col">FECHA</th>
                                    <th scope="col">TP</th>
                                    <th scope="col">SERIE</th>
                                    <th scope="col">NUMERO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in cpData" :key="index" class="text-xss">
                                    <td align="center">{{ index+1 }}</td>
                                    <td align="center">-</td>
                                    <td align="center">-</td>
                                    <td align="center">{{item.note_credit_date}}</td>
                                    <td align="center">-</td>
                                    <td align="center">{{ item.note_credit_type_code }}</td>
                                    <td align="center">{{ item.note_credit_type_name }}</td>
                                    <td align="center">{{ tipo(item)}}</td>
                                    <td align="center">{{ item.note_credit_serie }}</td>
                                    <td align="center">{{ item.note_credit_number}}</td>
                                    <td align="center">{{ base(item)}}</td>
                                    <td align="center">{{ '-'+igv(item) }}</td>
                                    <td align="center">{{ '-'+total(item) }}</td>
                                    <td align="center">{{ item.voucher_date }}</td>
                                    <td align="center">{{ item.voucher_document =='FACTURA'? '01':'03' }}</td>
                                    <td align="center">{{ item.voucher_serie }}</td>
                                    <td align="center">{{ item.voucher_number }}</td>
                                    <td align="center">{{ '-' }}</td>
                                    <td align="center">{{ item.sale_type_patient || '-' }}</td>
                                    <td align="center">{{ 'C' }}</td>
                                    <td align="center">{{ item.voucher_vaucher_document || '-' }}</td>
                                    <td align="center">{{ item.voucher_vaucher_fullname.toUpperCase() || '-' }}</td>
                                    <td align="center">{{ item.patient_document || '-' }}</td>
                                    <td align="center">{{ item.patient_name || '-' }}</td>
                                    <td align="center">{{ item.voucher_document || '-' }}</td>
                                    <td align="center">{{ item.voucher_serie + '-' + item.voucher_number || '-' }}</td>
                                    <td align="center">{{ item.voucher_date || '-' }}</td>
                                    <td align="center">{{ item.voucher_date || '-' }}</td>
                                    <td align="center">{{ item.voucher_date || '-' }}</td>
                                    <td align="center">{{ item.sale_date_ind || '-' }}</td>
                                    <td align="center">{{ item.sale_date_out || '-' }}</td>
                                    <td align="center">{{ '-' }}</td>
                                    <td align="center">{{ item.sale_billing_period || '-' }}</td>
                                    <td align="center">{{ '-' }}</td>
                                    <td align="center">{{ item.sale_type=='garantia'? 'SI' : 'NO' }}</td>
                                    <td align="center">
                                        {{ item.voucher_canje === 0 && item.sale_type === 'garantia' ?'GARANTIA' : (item.sale_type !== 'garantia' ? '-' : 'APLICACION') }}
                                    </td>
                                    <td align="center">{{ item.sale_type=='garantia'?'001' : '002'}}</td>
                                    <td align="center">{{ item.subsub_categorie_name }}</td>
                                    <td align="center">{{ item.subategorie_name }}</td>
                                    <td align="center">{{ item.detail_quantity }}</td>
                                    <td align="center">{{ (item.detail_quantity * item.detail_price / 1.18).toFixed(2) }}</td>
                                    <td align="center">0</td>
                                    <td align="center">{{ (item.detail_quantity * item.detail_price - item.detail_quantity * item.detail_price / 1.18).toFixed(2) }}</td>
                                    <td align="center">{{ (item.detail_quantity * item.detail_price).toFixed(2) }}</td>
                                    <td align="center">{{ (item.detail_quantity * item.detail_price / 1.18).toFixed(2) }}</td>
                                    <td align="center">
                                        <a @click="pdf(item)" rel="noopener noreferrer">
                                            <i class="fas fa-eye" style="color: #900052;"></i>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="46" class="text-center text-xs">No se encontraron resultados</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex justify-content-center" style="align-items: center">
                        <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                </li>
                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                </li>
                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                </li>
                                <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{
                                    stepPagination.length }}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>
</template>

  
<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import * as XLSX from 'xlsx';

export default {
    name: "c-administracion-ingreso",
    components: {

    },
    data() {
        return {
            search: "",
            dataShow: [],
            dataCxcs: [],
            selectPage: 1,
            noRecordsMessage: "",
            filter: {
                begindate: null,
                enddate: null
            },
            totalPagesToShow: 7,
            page: 0,
            stepPagination: [],
            excel: []
        };
    },
    computed: {
        cpData() {
            let filteredData = this.dataCxcs;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataCxcs.filter((element) => {
                    let name = element.vaucher_fullname.toString().toUpperCase();
                    return name.includes(query);
                });
            }

            let totalPages = Math.ceil(filteredData.length / 10);

            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * 10;
            let endIndex = startIndex + 9;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        moment.locale("es");
        this.filter.begindate =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-01";
        this.filter.enddate =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData() {
            this.post({
                    url: this.$store.getters.get__url + "/Administracion/notescredit/" +
                        this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                    params: this.filter
                })
                .then((response) => {
                    this.dataCxcs = response.data;
                    this.excel = [];
                    if (this.dataCxcs.length === 0) {
                        this.noRecordsMessage = "No se encontraron resultados.";
                    } else {
                        this.noRecordsMessage = "";
                    }
                    this.dataCxcs.forEach((element, index) => {
                        this.excel.push({
                            "N°": index + 1,
                            "FECHA EMISION DE COMPROBANTE DE PAGO DE DOCUMENTO": element.note_credit_date,
                            "FECHA DE VENCIMIENTO": '-',
                            "COMPROBANTE DE PAGO O DOCUMENTO": this.tipo(element) + '-' + element.note_credit_serie + '-' + element.note_credit_number,
                            "CODIGO": element.note_credit_type_code,
                            "MOTIVO": element.note_credit_type_name,
                            "BASE": this.base(element),
                            "IGV Y/O IPM": '-' + this.igv(element),
                            "IMPORTE TOTAL DEL COMPROBANTE DE PAGO": '-' + this.total(element),
                            "REFERENCIA DE COMPROBANTE O DOCUMENTO ORIGINAL QUE NO SE MODIFICA": element.voucher_date + '-' + (element.voucher_document == 'FACTURA' ? '01' : '03') + '-' + element.voucher_serie + '-' + element.voucher_number,
                            "FECHA DE REFERENCIA DE COMPROBANTE": element.voucher_date,
                            "CUENTA CONTABLE": '-',
                            "TIPO CLIENTE": element.sale_type_patient || '-',
                            "COD. ANEXO": 'C',
                            "ACREEDOR": element.voucher_vaucher_document || '-',
                            "NOM. ACREEDOR": element.voucher_vaucher_fullname.toUpperCase() || '-',
                            "DNI PACIENTE": element.patient_document || '-',
                            "NOM. PACIENTE": element.patient_name || '-',
                            "TIPO DOCUMENTO": element.voucher_document || '-',
                            "N° DOCUMENTO": element.voucher_serie + '-' + element.voucher_number || '-',
                            "FECH. EMISION": element.voucher_date || '-',
                            "FECH. VENCIMIENTO": element.voucher_date || '-',
                            "FECH. COMPUTO DE COBRO": element.voucher_date || '-',
                            "FECH. DE INICIO DE SERVICIO": element.sale_date_ind || '-',
                            "FECH. DE FIN DE SERVICIO": element.sale_date_out || '-',
                            "COD. EPS": '-',
                            "PERIODO FACTURO": element.sale_billing_period || '-',
                            "PERIODO DEVENGADO": '-',
                            "REFERENCIA CON GARANTIA": element.sale_type == 'garantia' ? 'SI' : 'NO',
                            "CONTROL DE GARANTIAS": element.voucher_canje === 0 && element.sale_type === 'garantia' ? 'GARANTIA' : (element.sale_type !== 'garantia' ? '-' : 'APLICACION'),
                            "TIPO ANEXO": element.sale_type == 'garantia' ? '001' : '002',
                            "CLASIFICACION BM": element.subsub_categorie_name,
                            "DESCRIPCION": element.subategorie_name,
                            "CANTIDAD": element.detail_quantity,
                            "BASE IMPONIBLE GRAVADA": (element.detail_quantity * element.detail_price / 1.18).toFixed(2),
                            "BASE IMPONIBLE NO GRAVADA": 0,
                            "IGV": (element.detail_quantity * element.detail_price - element.detail_quantity * element.detail_price / 1.18).toFixed(2),
                            "TOTAL FACTURADO": (element.detail_quantity * element.detail_price).toFixed(2),
                            "IMPORTE AFECTO A RENTA": (element.detail_quantity * element.detail_price / 1.18).toFixed(2),
                        });
                    });

                    let cantidad = parseInt(this.dataCxcs.length / 10);
                    let residuo = parseInt(this.dataCxcs.length % 10);
                    cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                    for (let index = 0; index < cantidad; index++) {
                        this.stepPagination.push(index + 1);
                    }
                })
                .catch((errors) => {});
        },
        mtdSearch() {
            if (this.search.length >= 2 && this.search != "") {
                let q = this.search.toString().toUpperCase();
                this.dataShow = [];
                let i = 0;
                this.dataCxcs.forEach((element) => {
                    let name = element.patient.name.toString().toUpperCase();
                    if (name.includes(q)) {
                        this.dataShow.push(element);
                    }
                });
                this.selectPage = 1;
            } else {
                // this.data_product_filter = this.dataSubSubCategories;
            }
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        backPAge() {
            this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
        },
        addPAge() {
            this.selectPage =
                this.selectPage == this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },
        /** coponent */
        changePage(page) {
            this.$emit("changePage", page);
        },
        mtdBackCampus() {
            this.$emit("mtdBackCampus");
        },
        mtdBackRol() {
            this.$emit("mtdBackRol");
        },
        pdf(item) {
            this.post({
                    url: this.$store.getters.get__url + "/Administracion/consultnotecredit/" + item.note_credit_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.mtdGetData();
                })
                .catch((errors) => {});
            let json = JSON.parse(item.json);
            const pdfLink = json.enlace_del_pdf_ticket;
            if (pdfLink) {
                window.open(pdfLink, '_blank');
            }
        },
        tipo(item) {
            let json = JSON.parse(item.json);
            return json.tipo_de_comprobante || '-';
        },
        base(item) {
            let json = JSON.parse(item.json);
            const qrData = json.codigo_qr;
            if (!qrData) return "-";

            const qrDataArray = qrData.split('|');
            const montoTotal = qrDataArray[4] ? qrDataArray[4].trim() : '';
            const montoNeto = qrDataArray[5] ? qrDataArray[5].trim() : '';

            if (montoTotal && montoNeto) {
                const diferencia = parseFloat(montoTotal) - parseFloat(montoNeto);
                const diferenciaFormateada = diferencia.toFixed(2);
                return parseFloat(diferenciaFormateada);
            }

            return '-';
        },
        igv(item) {
            let json = JSON.parse(item.json);
            const qrData = json.codigo_qr;
            if (!qrData) return "-";

            const qrDataArray = qrData.split('|');
            return qrDataArray[4] ? qrDataArray[4].trim() : '-';
        },
        total(item) {
            let json = JSON.parse(item.json);
            const qrData = json.codigo_qr;
            if (!qrData) return "-";

            const qrDataArray = qrData.split('|');
            return qrDataArray[5] ? qrDataArray[5].trim() : '-';
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        exportExcel() {
            const ws = XLSX.utils.json_to_sheet(this.excel);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Reporte de Notas de Crédito');
            XLSX.writeFile(wb, 'ReporteNotasDeCredito.xlsx');
        },

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : '-';
        },
    },
};
</script>

<style scoped>
.btn-bm-noradius:hover {
    background: rgb(170, 2, 95);
    background-color: rgb(170, 2, 95);
    color: white;
}

.btn-register {
    background: rgb(144, 0, 82);
}

.btn-register:hover {
    background: rgb(170, 2, 95);
}

.modal-header {
    background: rgb(144, 0, 82);
}

.close {
    cursor: pointer;
}

.text-start {
    text-align: left;
}

.tr-table>th>div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
}

.tr-table>th {
    padding: 0px;
}

.tbody-table>td>div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.tbody-table>td {
    padding: 0px;
}

.tbody-table>td>div>div {
    border-radius: 10px;
}

#btn-close {
    width: 49%;
}

.page-link {
    color: black !important;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}

.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

@media (max-width: 480px) {
    #btn-edit {
        font-size: 13px !important;
    }

    #btn-remove {
        font-size: 13px !important;
    }

    #btn-close {
        width: 100%;
    }

    .container {
        font-size: 11px !important;
    }

    .btn-register {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .td-div-a {
        padding-top: 10px !important;
    }

    .td-div-state>div>div {
        margin-top: 3px !important;
    }
}

@media (max-width: 415px) {
    #state_schedule {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px !important;
    }

    #state_schedule div {
        width: 100%;
    }
}
</style>
