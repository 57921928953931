<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true"
  :booTitile="true">
    <div class="row mx-0 mt-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">N°</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Descripción</th>
                        <th scope="col" v-if="type == 1">Responsable</th>
                        <th scope="col" v-if="type == 2">Beneficiario</th>

                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 12px">
                    <tr class="table-body text-center" v-if="cpData.length === 0">
                        <td colspan="5" class="text-center">No hay registros</td>
                    </tr>
                    <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{index + 1}}</td>
                        <td>{{item.created_at | formatDateTime}}</td>
                        <td>{{item.amount}}</td>
                        <td>{{item.description}}</td>
                        <td v-if="type == 1">{{item.user}}</td>
                        <td v-if="type == 2">{{item.person}}</td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import moment from "moment";
import {
  mapActions
} from "vuex";

export default {
  name: "c-asignaciones-modal-assig",
  components: {
      CModal,
  },
  data() {
      return {
        data_detail: {
        },
      };
  },
  watch: {
  },
  props: {
      title: String,
      boo_modal: Boolean,
      type: Number,
      payload: {
        type: Array,
        required: true
      },
  },
  computed: {
    cpData() {
        return this.payload.map(element => element);
      },
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.$emit("closeModal");
      },
  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>
<style>

.pdf {
    background-color: #900052;
    border-radius: 10px;
    width: 15px;
    font-size: 12px;
}
</style>
