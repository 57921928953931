<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2 row mx-0 mb-2">
        <div class="col-md-12 col-lg-12 col-xs-12 mb-2">
            <div class="input-group">
                <input type="file" class="form-control" ref="fileInput" name="amount" placeholder="0.00" @change="getFileCo" />
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdInsert" :disabled="mtdDisableButton">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-upload",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            file: "",
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id: Number,
    },
    computed: {

        mtdDisableButton() {
            return this.file === "";
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        getFileCo(e) {
            let fileInputCo = this.$refs.fileInput;
            let file = e.target.files[0];
            if (
                !/\.(pdf)$/i.test(file.name)
            ) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF');
                fileInputCo.value = "";
            } else {
                this.file = file;
            }
        },
        mtdclosemodal(opt) {
            this.file = '';
            this.$emit('mtdcloseModal', opt);
        },
        mtdInsert: function () {
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("id", this.id);
            formData.append("type", this.type);
            this.post({
                    url: this.$store.getters.get__url + '/Inventario/uploadPdfVerificacion',
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess('Se subió el archivo correctamente');
                        this.mtdclosemodal(0);
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });

        }

    },
};
</script>
