<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label for="name" class="mb-2 text-uppercase">Cod_cxc</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <span
                          style="font-size: 20px; border: none"
                          class="input-group-text"
                          ><i class="fas fa-regular fa-id-card"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="cod_cxc"
                        id="cod_cxc"
                        v-model="data_detail.cod_cxc"
                        placeholder="cod_cxc"
                      />
                    </div>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="ruc" class="mb-2 text-uppercase">Ruc</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <span
                          style="font-size: 20px; border: none"
                          class="input-group-text"
                          ><i class="fas fa-solid fa-barcode"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="ruc"
                        id="ruc"
                        v-model="data_detail.ruc"
                        placeholder="ruc"
                        maxlength="11"
                        @keydown="filterKey"
                      />
                    </div>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="SocialName" class="mb-2 text-uppercase">Razón Social</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <span
                          style="font-size: 20px; border: none"
                          class="input-group-text"
                          ><i class="fas fa-regular fa-building"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        name="socialName"
                        id="socialName"
                        placeholder="razón social"
                        v-model="data_detail.social_name"
                      />
                    </div> 
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="inputtext" class="form-label mb-3 text-uppercase">Moneda</label>
                    <div class="input-group mb-2">
                      <div class="input-group-text">
                        <span
                          style="font-size: 20px; border: none"
                          class="input-group-text"
                        >
                          <i class="fas fa-solid fa-sack-dollar"></i>
                        </span>
                      </div>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-Live-search="true"
                        required
                        v-model="data_detail.currency"
                      >
                        <option class="text-muted" selected disabled value="">Seleccionar..</option>
                        <option value="SOLES">SOLES</option>
                        <option value="DOLARES">DOLARES</option>
                      </select>
                    </div>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="inputtext" class="mb-3 text-uppercase">Tipo de Documento</label>
                    <div class="input-group mb-2">
                      <div class="input-group-text">
                        <span
                          style="font-size: 20px; border: none"
                          class="input-group-text"
                        >
                            <i class="fas fa-regular fa-file"></i>
                        </span>
                      </div>
                      <select
                        class="form-control form-control-sm selectpicker"
                        data-Live-search="true"
                        required
                        v-model="data_detail.document_type"
                      >
                        <option class="text-muted" selected disabled value="">Seleccionar..</option>
                        <option value="BOLETA">BOLETA</option>
                        <option value="FACTURA">FACTURA</option>
                      </select>
                    </div> 
                </div>
            </div>
            <div class="col-md-12 mt-3 pt-1" v-if="num === 1">
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        v-model="data_detail.state"
                        :checked="data_detail.state == 1 ? true : false"
                        aria-checked="false"
                    />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                    </label>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
               {{ (num == 0 ) ? 'Registrar':'Editar' }}
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                cod_cxc: '',
                ruc: '',
                social_name: '',
                currency: '',
                document_type:'',
                state: '',
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
                return !(this.data_detail.cod_cxc !=''  && this.data_detail.ruc.length ==11 && this.data_detail.social_name !='' && this.data_detail.currency !='' && this.data_detail.document_type !='');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.cod_cxc ='';
            this.data_detail.ruc ='';
            this.data_detail.social_name ='';
            this.data_detail.currency ='';
            this.data_detail.document_type = '';
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
            e.preventDefault();
            }
        },
    },
    

};
</script>