<template>
<div>
    <div class="container">
        <div class="row">
            <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-lg-5">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>RENDIR EGRESO</strong>
                </div>
                <div class="card-body">
                    <div class="row d-flex">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-md-6 col-lg-3 col-sm-6 col-xs-6 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-6 col-xs-6 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text text-white bg-main cursor-pointer">
                                            <i class="fas fa-search"></i>
                                        </span>
                                        <input type="text" class="form-control form-control-sm" placeholder="Buscar RUC/DNI o Nombre/Compañia" v-model="search" @input="mtdSearchFilter" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12" id="content-timelinetable">
                            <table aria-describedby="mydesc" class="table table-bordered border-main text-center">
                                <thead>
                                    <tr class=" table-th text-white text-center text-xs">
                                        <th scope="col">N°</th>
                                        <th scope="col">MODULO</th>
                                        <th scope="col">TIPO DOC</th>
                                        <th scope="col">DNI / RUC</th>
                                        <th scope="col">
                                            NOMBRES / RAZÓN SOCIAL
                                        </th>
                                        <th scope="col">
                                            SERIE - NÚMERO
                                        </th>
                                        <th scope="col">FECHA</th>
                                        <th scope="col">MÉTODO PAGO</th>
                                        <th scope="col">TOTAL</th>
                                        <th scope="col">PAGADO</th>
                                        <th scope="col">PENDIENTE</th>
                                        <th scope="col">MOTIVO</th>
                                        <th scope="col">ESTADO</th>
                                        <th scope="col">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in cpData" :key="index" class="table-body text-center text-xs">
                                        <td>{{mtdNumber(index)}}</td>
                                        <td>
                                            {{
                              item.modulo === "expense"
                                ? "Egreso"
                                : item.modulo === "receipt"
                                ? "Recibo"
                                : item.modulo === "retiro"
                                ? "Retiro"
                                : item.modulo === "passage"
                                ? "Pasajes"
                                : "Otro"
                            }}
                                        </td>
                                        <td>
                                            {{ item.document_type ? item.document_type.name : "-" }}
                                        </td>
                                        <td>
                                            {{
                              item.modulo === "receipt"
                                ? item.receipt.document
                                : item.document
                            }}
                                        </td>
                                        <td>
                                            {{
                              item.modulo === "receipt" ? item.receipt.name : item.company
                            }}
                                        </td>
                                        <td>
                                            {{ item.serie }}{{ item.number ? " - " + item.number : "" }}
                                        </td>
                                        <td>{{ item.date | formatDate }}</td>
                                        <td>{{ item.method }}</td>
                                        <td style="">S/ {{ item.total }}</td>
                                        <td>S/ {{ item.amount }}</td>
                                        <td>S/ {{ item.pending }}</td>
                                        <td>{{ item.description }}</td>
                                        <td class=" align-middle">
                                            <a href="javascript:void(0)" v-if="item.state == 2" @click="mtdViewPdfEgreso(item.id)" class=" a-success">
                                                <i class="fas fa-check-circle"></i>
                                            </a>
                                            <a href="javascript:void(0)" v-if="item.state == 3" class=" a-danger">
                                                <i class="fas fa-times-circle"></i>
                                            </a>
                                            <a href="javascript:void(0)" v-if="item.state == 1" class=" a-warning">
                                                <i class="far fa-circle"></i>
                                            </a>
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                <button :title="mtdTitle(item)" v-if="item.modulo === 'expense'" @click="mtdOpenModalExpense(item, index)" class="btn btn-sm btn-success text-white mx-1">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button :title="mtdTitle(item)" v-if="item.modulo === 'receipt'" @click="mtdGetDataRe(item, index)" class="btn btn-sm btn-warning text-white mx-1">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <!--
                                                <button title="A RH" v-if="item.modulo === 'expense'" @click="mtdGetConvertir(item, index)" class="btn btn-sm btn-info text-white mx-1">
                                                    <i class="fas fa-solid fa-share"></i>
                                                </button>
                                                -->
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="cpData.length == 0" class="table-body text-center text-xxs">
                                        <td colspan="14">No se han encontrado resultados</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalEditarEgreso :title="modalExpense.title" :boo_modal="modalExpense.modal_form" :egreso="egreso" :documents_type="documents_type" @mtdclosemodal="mtdclosemodal" />
    <cModalEditarRh :title="modalRh.title" :boo_modal="modalRh.modal_form" :egreso="egreso" :patients="dataPatients" :documents_type="documents_type" @mtdclosemodal="mtdclosemodal" :categories="categories" />
    <cModalConvertir :title="modalConvertir.title" :boo_modal="modalConvertir.modal_form" :egreso="egreso" :patients="dataPatients" :documents_type="documents_type" @mtdclosemodal="mtdclosemodal" :categories="categories" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalEditarEgreso from "@/components/JefeAdmision/modal/cModalEditarEgreso.vue";
import cModalEditarRh from "@/components/JefeAdmision/modal/cModalEditarRH.vue";
import cModalConvertir from "@/components/JefeAdmision/modal/cModalConvertirRH.vue";
export default {
    name: "c-jefatura-rendirEgreso",
    components: {
        cModalEditarEgreso,
        cModalEditarRh,
        cModalConvertir
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            search: "",
            dataRe: [],
            data: [],
            dataPatients: [],
            dataShow: [],
            categories: [],
            documents_type: [],
            /* paginacion */
            totalPagesToShow: 2,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            egreso: {
                idRe: "",
                id: "",
                campu_id: null,
                type_document: "",
                document_type_id: null,
                date: "",
                date_doc: "",
                serie: "",
                number: "",
                document: "",
                company: "",
                total: 0.0,
                amount: 0.0,
                pending: 0.0,
                method: "",
                description: "",
                state: "",
                file: "",
                user: "",
                //RECEIPT
                patient_id: null,
                shift_date: null,
                shift: null,
                category: null,
                //FIN RECEIPT
                user_autorize: "",
            },
            modalExpense: {
                title: "",
                modal_form: false,
            },
            modalRh: {
                title: "",
                modal_form: false,
            },
            modalConvertir: {
                title: "",
                modal_form: false,
            }
        }
    },
    created() {
        this.getFirstDayOfMonth();
        this.mtdGetData()
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome() {
            this.$emit("returnHome", "home");
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            let week = currentDate.getDate() - 7;
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), week);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Jefatura/egreso/list/edit",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        enddate: this.filter.enddate,
                        begindate: this.filter.begindate,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                    //this.dataRe=response.dataRe;
                    this.documents_type = response.documents_type;
                    this.dataPatients = response.patients;
                    this.categories = response.categories;
                    this.selectPage = 1;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        mtdTitle(item) {
            return item.modulo === "expense" ?
                "Editar Egreso" :
                item.modulo === "receipt" ?
                "Editar Recibo" :
                item.modulo === "retiro" ?
                "Editar Retiro" :
                item.modulo === "passage" ?
                "Editar Pasajes" :
                "Editar Otro";
        },
        calculateData(items) {
            let selectPage = this.selectPage;
            let filteredData = this.data;
            if (this.search.length >= 2 && this.search != "") {

                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = ""
                    element.company ? name = element.company.toString().toUpperCase() : element.receipt.name.toString().toUpperCase();
                    if (element.serie && element.document) {
                        let doc = element.document.toString().toUpperCase();
                        let serie = element.serie.toString().toUpperCase();
                        return (
                            doc.includes(query) || name.includes(query) || serie.includes(query)
                        );
                    } else {
                        return (
                            name.includes(query)
                        );
                    }
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        mtdOpenModalExpense: function (item) {
            this.modalExpense = {
                title: "EDITAR EGRESO",
                modal_form: true,
            };
            this.egreso = {
                id: item.id,
                type_document: item.modulo === "receipt" ?
                    item.receipt.document.length === 8 ?
                    "DNI" :
                    "RUC" : item.document.length === 8 ?
                    "DNI" : "RUC",
                campu_id: item.campu_id,
                document_type_id: item.document_type_id,
                date: item.date,
                date_doc: item.date_doc,
                serie: item.serie,
                number: item.number,
                document: item.modulo === "receipt" ? item.receipt.document : item.document,
                company: item.modulo === "receipt" ? item.receipt.name : item.company,
                total: item.total,
                amount: item.amount,
                pending: item.pending,
                method: item.method,
                description: item.description,
                state: item.state,
                file: item.file,
                bead_id: item.bead_id,
                user: this.$store.getters.get__user.id,
            };
        },
        mtdViewPdfEgreso: function (expense) {
            window.open(
                this.$store.getters.get__url + "/Permisions/viewpdf/expense/" + expense,
                "_blank"
            );
        },
        mtdGetDataRe(item) {
            this.egreso = {
                idRe: item.receipt.id,
                id: item.id,
                type_document: item.modulo === "receipt" ?
                    item.receipt.document.length === 8 ?
                    "DNI" :
                    "RUC" : item.document.length === 8 ?
                    "DNI" : "RUC",
                campu_id: item.campu_id,
                document_type_id: item.document_type_id,
                date: item.date,
                date_doc: item.date_doc,
                serie: item.serie,
                number: item.number,
                document: item.modulo === "receipt" ? item.receipt.document : item.document,
                company: item.modulo === "receipt" ? item.receipt.name : item.company,
                shift_date: item.modulo === "receipt" ? item.receipt.shift_date : item.date,
                patient_id: item.modulo === "receipt" ? item.receipt.patient_id : "",
                shift: item.modulo === "receipt" ? item.receipt.shift : "",
                category: item.modulo === "receipt" ? item.receipt.category : "",
                total: item.total,
                amount: item.amount,
                pending: item.pending,
                method: item.method,
                description: item.description,
                state: item.state,
                file: item.file,
                bead_id: item.bead_id,
                user: this.$store.getters.get__user.id,
            };
            //abrir modal
            this.modalRh = {
                modal_form: true,
                title: 'EDITAR RH',
            }
            console.log(this.egreso);

        },
        mtdGetConvertir(item) {
            this.egreso = {
                id: item.id,
                type_document: item.modulo === "receipt" ?
                    item.receipt.document.length === 8 ?
                    "DNI" :
                    "RUC" : item.document.length === 8 ?
                    "DNI" : "RUC",
                campu_id: item.campu_id,
                document_type_id: item.document_type_id,
                date: item.date,
                date_doc: item.date_doc,
                serie: item.serie,
                number: item.number,
                document: item.modulo === "receipt" ? item.receipt.document : item.document,
                company: item.modulo === "receipt" ? item.receipt.name : item.company,
                total: item.total,
                amount: item.amount,
                pending: item.pending,
                method: item.method,
                description: item.description,
                state: item.state,
                file: item.file,
                bead_id: item.bead_id,
                user: this.$store.getters.get__user.id,
                user_autorize: item.user_autoriza_id,
            };
            //abrir modal
            this.modalConvertir = {
                modal_form: true,
                title: 'CONVERTIR A RH'
            }

        },
        mtdclosemodal(option) {
            this.modalExpense.modal_form = false;
            this.modalRh.modal_form = false;
            this.modalConvertir.modal_form = false;
            if (option == 0) {
                this.mtdGetData();
            }
        },
        /* PAGINACION */
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage = this.selectPage < this.stepPagination.length ? this.selectPage + 1 : this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },

}
</script>
