<template>
<div>
    <div class="container">
        <div id="btn-open-caja" class="d-flex justify-content-end pt-2">
            <div class="btn-group" role="group">
                <button type="button" class="btn w-100 btn-bm-noradius"  @click="mtdBackCampus">
                    <i class="fas fa-recycle"></i> Cambiar de Sede
                </button>
            </div>
            &nbsp;
            <div id="btn-open-caja" class="d-flex justify-content-end" v-if="verifBead == false && verifBox == true">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-bm-noradius" @click="mtdRegisterAmount">
                        <i class="fas fa-cash-register"></i><br />Abrir
                    </button>
                </div>
            </div>
            <div id="btn-open-caja" class="d-flex justify-content-end" v-if="verifBead == true && verifBox == true">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-bm-noradius" @click="mtdCloseBead">
                        <i class="fas fa-cash-register"></i><br />Cerrar
                    </button>
                </div>
            </div>
        </div>
        <div class="row" v-if="verifBead">
            <div class="col-md-12 col-lg-12 col-sm-6">
                <div class="text-center p-5">
                    <div class="d-grid gap-2 mx-auto col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <button class="btn btn-lg btn-bm mt-100" type="button" @click="mtdOpenModalDeposit('Depósito')">
                            DEPOSITOS
                        </button>
                        <button class="btn btn-lg btn-bm" type="button" @click="mtdOpenModalDeposit('Retiro')">
                            RETIROS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Open :boo_modal="modal.boo_modal" :title="modal.title" @mtdcloseModal="mtdcloseModal" @mtdOpenBead="mtdOpenBead" :box="boxes" />
    <Deposit :boo_modal="modalDeposit.boo_modal" :title="modalDeposit.title" @mtdcloseModal="mtdcloseModal" :type="modalDeposit.type" :bead="bead" />
</div>
</template>

<script>
import Open from '@/components/Tesoreria/modals/cModalOpen.vue';
import Deposit from '@/components/Tesoreria/modals/cModalRegisterDepositRetirement.vue';
import {
    mapActions
} from "vuex";
export default {
    components: {
        Open,
        Deposit
    },
    data() {
        return {
            page: null,
            modulo: "tesoreria",
            bead: {},
            newBead: {
                box: 0,
                status: 0,
                totEfectivo: 0,
                observation : "",
                totEfectivo: 0,
                totTurned: 0,
                totIncome: 0,
                totExpense: 0,
                totAnulacion: 0,
                totTransferencia: 0,
                totDeposito: 0,
                totTarjeta: 0,
                totPlanilla: 0,
                totalArqueo: 0,
                retirement: 0,
                deposit: 0,
                initial: 0,
            },
            modal: {
                boo_modal: false,
                title: "",
            },
            modalDeposit: {
                boo_modal: false,
                title: "",
                type: ""
            }
        };
    },
    
    props:{
      verifBead: Boolean,
      verifBox: Boolean,
      boxes: Object,
    },
    methods: {
        ...mapActions(["get", "post"]),
        changePage(page) {
            this.$emit("changePage", page);
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdRegisterAmount: function () {
            this.newBead.box = this.boxes.id;
            this.modal = {
                boo_modal: true,
                title: "Registrar monto de inicio",
            }
        },
        mtdOpenBead: function (process) {
            //this.newBead.totEfectivo = amount;
            this.post({
                    url: this.$store.getters.get__url + "/Bead/open",
                    token: this.$store.getters.get__token,
                    params: {
                        data: process,
                        campus: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {
                  if(response.data.id){
                    this.bead = response.data;
                    this.mtdcloseModal();
                    this.$emit("mtdRefreshPage");
                  }
                })
                .catch((errors) => {});
        },
        mtdOpenModalDeposit: function(type){
            this.modalDeposit= {
                boo_modal: true,
                title: "Registro de "+type,
                type: type,
            };
            this.bead = this.boxes.beads[0];
        },
        mtdcloseModal: function (show) {
            this.modal = {
                boo_modal: false,
                title: "",
            };
            this.modalDeposit =  {
                boo_modal: false,
                title: "",
                type: ""
            };
            if(show == 0){
                this.$emit("mtdRefreshPage");
            }
            
        },
        mtdCloseBead: function (){
            this.$emit("changePage", 'close');
        }
    },
};
</script>

<style lang="scss" scoped></style>
