<template>
  <div>
    <br>
    <div v-if="this.type_patient == 'particular'" class="">

      <div class="row p-3 mb-2">
        <div>
          <span class="color-main fw-bold h4">PACIENTE: </span><span class="h5">{{ this.patient_name }}</span>
        </div>
        <div>
          <span class="color-main fw-bold h4">FECHA INGRESO: </span><span class="h5">{{ formatDate(this.date_in) }}</span>
        </div>
        <div>
          <span class="color-main fw-bold h4">FECHA SALIDA: </span><span class="h5">{{ formatDate(this.date_out) }}</span>
        </div>
      </div>

    </div>

    <div v-if="this.type_patient == 'eps'" class="">

      <div class="row p-3 mb-2">
        <div>
          <span class="color-main fw-bold h4">PACIENTE: </span><span class="h5">{{ this.patient_name }}</span>
        </div>
        <div>
          <span class="color-main fw-bold h4">CONVENIO: </span><span class="h5">{{ this.insurance_name }}</span>
        </div>
        <div>
          <span class="color-main fw-bold h4">FECHA INGRESO: </span><span class="h5">{{ formatDate(this.date_in) }}</span>
        </div>
        <div>
          <span class="color-main fw-bold h4">FECHA SALIDA: </span><span class="h5">{{ formatDate(this.date_out) }}</span>
        </div>
      </div>

    </div>

    <div class="row px-5 mb-2">
      <table class="table table-bordered border-main mb-0 px-0 text-xs">
        <caption class=" my-0 py-0"></caption>
        <thead class="">
          <tr class="text-white border-white text-center bg-main">
            <th rowspan="2" scope="col">N°</th>
            <th rowspan="2" scope="col">FECHA</th>
            <th rowspan="2" scope="col">DISPOSITIVO</th>
            <th colspan="5" scope="col">M</th>
            <th colspan="5" scope="col">T</th>
            <th colspan="5" scope="col">N</th>
            <th rowspan="2" scope="col">TOTAL</th>
          </tr>
          <tr class="text-center text-white border-white text-center bg-main">
            <th scope="col">FIO2 %</th>
            <th scope="col">MINUTOS</th>
            <th scope="col">LITROS</th>
            <th scope="col">COSTO</th>
            <th scope="col">SUBTOTAL</th>
            <th scope="col">FIO2 %</th>
            <th scope="col">MINUTOS</th>
            <th scope="col">LITROS</th>
            <th scope="col">COSTO</th>
            <th scope="col">SUBTOTAL</th>
            <th scope="col">FIO2 %</th>
            <th scope="col">MINUTOS</th>
            <th scope="col">LITROS</th>
            <th scope="col">COSTO</th>
            <th scope="col">SUBTOTAL</th>
          </tr>
        </thead>
        <tbody v-if="this.dataOxygen.length > 0">
          <tr v-for="(oxygen, index) in this.dataOxygen" :key="index" class="px-1 border-bottom">
            <td class="text-center"><span>{{ index + 1 }}</span></td>
            <td class="text-center"><span>{{ formatDate(oxygen.date) }}</span></td>
            <td class="text-center"><span>{{ oxygen.device }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.fio2_m) > 0 ? parseFloat(oxygen.fio2_m)+'%' : '-' }}</span></td>
            <td class="text-center" v-if="oxygen.type_time=1"><span>{{ parseInt(oxygen.minute_m) > 0 ? parseInt(oxygen.minute_m)+' mins' : '-' }}</span></td>
            <td class="text-center" v-else><span>{{ parseInt(oxygen.minute_m) > 0 ? parseInt(oxygen.minute_m)+' hrs' : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.liter_m) > 0 ? parseFloat(oxygen.liter_m)+' lts' : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.coste_m) > 0 ? parseFloat(oxygen.coste_m) : '-' }}</span></td>
            <td class="text-end" v-if="oxygen.type_time=1"><span>{{ parseInt(oxygen.fio2_m) > 0 ? 'S/ '+ subTotal(parseFloat(oxygen.liter_m),parseInt(oxygen.minute_m),parseFloat(oxygen.coste_m)) : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.fio2_t) > 0 ? parseFloat(oxygen.fio2_t)+'%' : '-' }}</span></td>
            <td class="text-center" v-if="oxygen.type_time=1"><span>{{ parseInt(oxygen.minute_t) > 0 ? parseInt(oxygen.minute_t)+' mins' : '-' }}</span></td>
            <td class="text-center" v-else><span>{{ parseInt(oxygen.minute_t) > 0 ? parseInt(oxygen.minute_t)+' hrs' : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.liter_t) > 0 ? parseFloat(oxygen.liter_t)+' lts' : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.coste_t) > 0 ? parseFloat(oxygen.coste_t) : '-' }}</span></td>
            <td class="text-end" v-if="oxygen.type_time=1"><span>{{ parseInt(oxygen.fio2_t) > 0 ? 'S/ '+ subTotal(parseFloat(oxygen.liter_t),parseInt(oxygen.minute_t),parseFloat(oxygen.coste_t)) : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.fio2_n) > 0 ? parseFloat(oxygen.fio2_n)+'%' : '-' }}</span></td>
            <td class="text-center" v-if="oxygen.type_nime=1"><span>{{ parseInt(oxygen.minute_n) > 0 ? parseInt(oxygen.minute_n)+' mins' : '-' }}</span></td>
            <td class="text-center" v-else><span>{{ parseInt(oxygen.minute_n) > 0 ? parseInt(oxygen.minute_n)+' hrs' : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.liter_n) > 0 ? parseFloat(oxygen.liter_n)+' lts' : '-' }}</span></td>
            <td class="text-center"><span>{{ parseFloat(oxygen.coste_n) > 0 ? parseFloat(oxygen.coste_n) : '-' }}</span></td>
            <td class="text-end" v-if="oxygen.type_nime=1"><span>{{ parseInt(oxygen.fio2_n) > 0 ? 'S/ '+ subTotal(parseFloat(oxygen.liter_n),parseInt(oxygen.minute_n),parseFloat(oxygen.coste_n)) : '-' }}</span></td>
            <td class="text-end"><span class="fw-bold">S/ {{ sumSubTotal(subTotal(parseFloat(oxygen.liter_m),parseInt(oxygen.minute_m),parseFloat(oxygen.coste_m)), subTotal(parseFloat(oxygen.liter_t),parseInt(oxygen.minute_t),parseFloat(oxygen.coste_t)), subTotal(parseFloat(oxygen.liter_n),parseInt(oxygen.minute_n),parseFloat(oxygen.coste_n))) }}</span></td>
          </tr>
        </tbody>
        <tbody class=" text-center" v-else>
          <tr>
            <td colspan="19" class=" text-center">
              <strong> No hay resultados</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import moment from "moment";

  export default {
    name: 'c-asistencial-uciadulto-historia-oxigeno',
    data () {
      return {
        dataOxygen: [],
        patient_name: '',
        insurance_id: '',
        insurance_name: '',
        sale: [],
        type_patient: null,
        date_in: '',
        date_out: '',
        upss_particular: '',
        hours_particular: '',
        liter_particular: '',
        coste_particular: '',
        device_eps: '',
        fio2_eps: '',
        minutes_eps: '',
      }
    },
    props: {
      medical_act_id: {
        type: Number,
        default: 0
      },
      datapatients: {
        type: Object,
        default: () => ({})
      },
    },
    created () {
      this.mtdtypepatient();
      this.mtdGetData();
    },
    methods: {
      ...mapActions(['get', 'post']),
      mtdGetData: function () {
        this.post({
            url: this.$store.getters.get__url + "/Uciadulto/oxygen",
            token: this.$store.getters.get__token,
            params:{
              medical_act_id: this.medical_act_id
            },
        })
        .then((response) => {
            this.dataOxygen = response.data;
        })
        .catch((errors) => {
            console.log(errors);
        });
      },
      mtdtypepatient: function () {
        this.patient_name = this.datapatients.patient_name
        this.post({
          url: this.$store.getters.get__url + "/Uciadulto/salePatient",
          token: this.$store.getters.get__token,
          params:{
            medical_act_id: this.medical_act_id
          },
        })
        .then((response) => { //   
          this.sale = response.data,
          this.type_patient = response.data.type_patient,
          this.date_in = response.data.date_ind,
          this.date_out = response.data.date_out,
          this.insurance_id = response.data.insurance ? response.data.insurance.id : null,
          this.insurance_name = response.data.insurance ? response.data.insurance.name : ''
        })
        .catch((errors) => {
          console.log(errors);
        });
      },
      validateDecimal: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      subTotal(liter, time, coste) {
        const result = liter * time * coste;
        return parseFloat(result.toFixed(2));
      },
      sumSubTotal(subtotal1, subtotal2, subtotal3) {
        const result = (parseFloat(subtotal1) > 0 ? parseFloat(subtotal1) : 0.00) + (parseFloat(subtotal2) > 0 ? parseFloat(subtotal2) : 0.00) + (parseFloat(subtotal3) > 0 ? parseFloat(subtotal3) : 0.00);
        return (parseFloat(result) > 0 ? parseFloat(result.toFixed(2)) : '0.00');
      },
      formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
    }
  }
</script>

<style>
  .text-end {
    text-align: end;
  }
</style>