<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
      <div class="px-3">
          <div class="row mx-0 px-0">
                <div class="col-md-12 col-lg-12 col-xs-12">
                  <label class="mb-3"> Archivo</label>
                  <div>
                    <div class="mb-3">
                      <input @change="getFile" type="file" class="form-control" ref="fileInput" id="file" lang="es"
                        name="file" />
                    </div>
                  </div>
                </div>
          </div>
      </div>
      <template #footer>
          <button class="btn btn-success btn-sm" :disabled="!fileLoaded" @click="mtdCommitReceipt">
              Guardar
          </button>
          &nbsp;
      </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
  mapActions
} from "vuex";
import readXlsFile from "read-excel-file";

export default {
  name: "c-maintenance-modal-side-excel",
  components: {
      CModal,
  },
  data() {
      return {
          all: [],
          data_detail: {
          },
          fileLoaded: false,
      };
  },
  watch: {
      payload: {
          handler(newVal) {
              if (newVal) {
                this.data_detail = {
                  ...newVal
                };
              }
          },
          immediate: true,
      },
  },
  props: {
      title: String,
      boo_modal: Boolean,
  },
  computed: {
      cpButton() {
      }
  },
  methods: {
      ...mapActions(["get", "post"]),

      mtdClose() {
        this.fileLoaded = false;
        this.$refs.fileInput.value = '';
        this.$emit("closeModal");
      },

      getFile(e) {
        let file = e.target.files[0];
        if (!/\.(xlsx|xls|csv)$/i.test(file.name)) {
          Swal.fire({
            text: "Seleccione un archivo formato Excel",
            icon: "info",
            confirmButtonColor: "#900052",
          }).then((result) => {
            if (result.isConfirmed) {
              const input = this.$refs.fileInput;
              input.type = "text";
              input.type = "file";
              this.fileLoaded = false;
            }
          });
        } else {
          this.file = file;
          this.fileLoaded = true;
          const input = document.getElementById("file");
          readXlsFile(input.files[0]).then((rows) => {
            this.all = rows;
          });
        }
      },

      mtdCommitReceipt() {
        this.$emit("mtdCommitReceipt", this.all);
        this.mtdClose();
      },
  },


};
</script>
