<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :fullScreen="true"
  >

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div id="table-detalle" v-if="Object.keys(item).length > 0">


            <div class="row d-flex mb-2">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                  <span class="badge custom">TIPO :</span>
                    <div class="input-group input-group">
                        <v-select
                          class="text-dark form-control"
                          label="name"
                          :options="servicesType"
                          placeholder="- Seleccionar Tipo de Dev. -"
                          @search="searchType"
                          v-model="type"
                          :reduce="(name) => name.id"
                          required
                          :clearable="false"
                        >
                          <template slot="no-options">
                            No hay Tipos de Devolución
                          </template>
                        </v-select>
                    </div>
                </div>
            </div>

            <div class="row d-flex mb-3">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                  <span class="badge custom">MOTIVO :</span>
                    <div class="input-group input-group">
                        <v-select
                          class="text-dark form-control"
                          label="name"
                          :options="servicesMotive"
                          placeholder="- Seleccionar Motivo de Dev. -"
                          @search="searchMotive"
                          v-model="motivo"
                          :reduce="(name) => name.id"
                          required
                          :clearable="false"
                        >
                          <template slot="no-options">
                            No hay Motivos de Devolución
                          </template>
                        </v-select>
                    </div>
                </div>
            </div>


            <div v-for="(product, index) in Object.values(item)" :key="index">
              <div class="card mb-4">
                <div class="card-header bg-main text-white">
                  <h5 class="card-title" style="font-size: 11px">{{ product[0].product_name }}</h5>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered mb-0" style="font-size: 12px">
                      <tbody>
                        <tr  class="text-xs" v-for="(dispatch, dispatchIndex) in product" :key="dispatchIndex">
                          <th>{{ dispatch.dispatch_name }}</th>
                          <td>{{ dispatch.quantity }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-muted">No hay productos disponibles.</p>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <button class="btn btn-success btn-sm" @click="registrarDev">
        <i class="fas fa-save" />&nbsp; GUARDAR
      </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
  name: "c-devoluciones-register-detalleModal",
  components: {
    SweetAlert,
    CModal,
  },
  data() {
    return {
      swal: null,
      motivo: "",
      type: "",
      idNote: [],

      servicesType: [],
      servicesMotive: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    itemPdf: [],
    patient_id: Number
  },
  computed: {
    cpData() {
    },
    itemPdfWithMotivo() {
      const itemPdfWithMotivo = {};

      for (const dispatchName in this.itemPdf) {
        if (Object.hasOwnProperty.call(this.itemPdf, dispatchName)) {
          const productsWithMotivo = this.itemPdf[dispatchName].map(product => ({ ...product, motivo: "" }));
          // ^^^ Agregar la propiedad 'motivo' a cada objeto dentro del array
          itemPdfWithMotivo[dispatchName] = productsWithMotivo;
        }
      }
      return itemPdfWithMotivo;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.motivo = "",
      this.type = "",
      this.$emit("mtdClose");
    },

    registrarDev() {
      if (!this.motivo || !this.type) {
          this.$refs.SweetAlert.showWarning("Seleccione un Motivo y Tipo de Dev.");
          return;
      }

      Swal.fire({
        icon: "warning",
        title: "¿Está seguro de realizar la Devolución?",
        confirmButtonText: "Permitir",
        confirmButtonColor: "#3085d6",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cerrar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.post({
            url: this.$store.getters.get__url + "/Pharmacy/return/registerDev",
            token: this.$store.getters.get__token,
            params: {
              data: this.itemPdf,
              patient_id: this.patient_id,
              motivo: this.motivo,
              type: this.type,
            },
          })
          .then((response) => {
            if(response.data.state == 0){
              this.motivo = "",
              this.type = "",
              this.idNote = response.data.allNoteCreditIds;
              this.idNote.forEach(id => {
               
                  window.open(
                      this.$store.getters.get__url + "/Pharmacy/return/noteCrePdf/" + id,
                      "_blank"
                  );
              });
              this.$refs.SweetAlert.showSuccess("Devolucion Registrada Correctamente");
              this.mtdClose();
            }else{
              this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
        }
      });
    },

    searchType: function (search, loading) {
      if (search.length > 2) {
        this.servicesType = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/Pharmacy/return/searchType/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.servicesType = response.data;
            loading(false);
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
      }
    },


    searchMotive: function (search, loading) {
      if (search.length > 2) {
        this.servicesMotive = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/Pharmacy/return/searchMotive/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.servicesMotive = response.data;
            loading(false);
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
      }
    },
  },

};
</script>
<style scoped>
#table-detalle {
  margin-top: 20px;
}

.table-responsive {
  overflow-x: auto;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table th,
.table td {
  border: 1px solid #dee2e6;
  padding: 10px;
}

.bg-main {
  background-color: #900052 !important;
}

.text-white {
  color: #fff !important;
}

.text-muted {
  color: #6c757d !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.custom{
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  background-color: #900052;
  margin-bottom: 5px;
}
</style>

