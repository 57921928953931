<template>
  <div>
    <div class="row my-3">

      <div class="col-md-6">
        <div class="col-md-12 mt-1 align-left">
          <div id="div-diag-pre" class="form-group row">
            <label for="diag-pre" class="col-sm-5 fw-bold text-xs">Diagnóstico(s) Pre Operatorio(s)</label>
            <div class="col-sm-7 mb-1">
                <textarea class="form-control input-textarea" id="diag-pre" rows="2" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-diag-post" class="form-group row">
            <label for="diag-post" class="col-sm-5 fw-bold text-xs">Diagnóstico(s) Post Operatorio(s)</label>
            <div class="col-sm-7 mb-1">
                <textarea class="form-control input-textarea" id="diag-post" rows="2" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-c-programada" class="form-group row">
            <label for="c-programada" class="col-sm-5 fw-bold text-xs">Cirugía(s) programada(s)</label>
            <div class="col-sm-7 mb-1">
                <textarea class="form-control input-textarea" id="c-programada" rows="2" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-c-realizada" class="form-group row">
            <label for="c-realizada" class="col-sm-5 fw-bold text-xs">Cirugía(s) realizada(s)</label>
            <div class="col-sm-7 mb-1">
                <textarea class="form-control input-textarea" id="c-realizada" rows="2" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-doc-responsable" class="form-group row">
            <label for="doc-responsable" class="col-sm-5 fw-bold text-xs">Cirujano/Médico resposable</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="doc-responsable">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-anastesiologo" class="form-group row">
            <label for="anastesiologo" class="col-sm-5 fw-bold text-xs">Anastesiólogos</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="anastesiologo">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-primer-ayudante" class="form-group row">
            <label for="primer-ayudante" class="col-sm-5 fw-bold text-xs">Primer ayudante</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="primer-ayudante">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-segundo-ayudante" class="form-group row">
            <label for="segundo-ayudante" class="col-sm-5 fw-bold text-xs">Segundo ayudante</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="segundo-ayudante">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-instrumentista" class="form-group row">
            <label for="instrumentista" class="col-sm-5 fw-bold text-xs">Instrumentista</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="instrumentista">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-circulante" class="form-group row">
            <label for="circulante" class="col-sm-5 fw-bold text-xs">Circulante</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="circulante">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-persufionista" class="form-group row">
            <label for="persufionista" class="col-sm-5 fw-bold text-xs">Persufionista</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="persufionista">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-neonatologo" class="form-group row">
            <label for="neonatologo" class="col-sm-5 fw-bold text-xs">Neonatólogo</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="neonatologo">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-tipo-anestesia" class="form-group row">
            <label for="tipo-anestesia" class="col-sm-5 fw-bold text-xs">Tipo de anestesia</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="tipo-anestesia">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 d-flex">
          <div id="div-hora-inicio" class="form-group row">
            <label for="hora-inicio" class="col-sm-6 fw-bold text-xs mt-1">Hora de inicio</label>
            <div class="col-sm-6">
                <input type="text" class="form-control input-text" id="hora-inicio">
            </div>
          </div>
          <div id="div-hora-termino" class="form-group row">
            <label for="hora-termino" class="col-sm-7 fw-bold text-xs mt-1">Hora de término</label>
            <div class="col-sm-5">
                <input type="text" class="form-control input-text" id="hora-termino">
            </div>
          </div>
          <div id="div-lateralidad" class="form-group row">
            <label for="lateralidad" class="col-sm-5 fw-bold text-xs mt-1 ml-1">Lateralidad</label>
            <div class="col-sm-7">
                <input type="text" class="form-control input-text" id="lateralidad">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12 mt-1 align-left">
          <div id="div-hallazgo" class="form-group">
            <label for="hallazgo" class="col-sm-5 fw-bold text-xs">Hallazgos operatorios</label>
            <div class="col-sm-12 mb-1">
                <textarea class="form-control input-textarea" id="hallazgo" rows="3" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-procedimiento" class="form-group">
            <label for="procedimiento" class="col-sm-5 fw-bold text-xs">Descripción del procedimiento</label>
            <div class="col-sm-12 mb-1">
                <textarea class="form-control input-textarea" id="procedimiento" rows="6" style="resize: none;" />
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-complicacion" class="form-group">
            <label for="complicacion" class="col-sm-5 fw-bold text-xs">Complicaciones intraoperatorias</label>
            <div class="col-sm-12 mb-1">
                <textarea class="form-control input-textarea" id="complicacion" rows="2" style="resize: none;" />
            </div>
          </div>
        </div>

        <div class="col-md-12 d-md-block d-lg-flex">
          <div class="col-md-12 col-lg-6">
            <div class="mt-1 align-left">
              <div id="div-hora-inicio" class="form-group row">
                <label for="hora-inicio" class="col-sm-8 fw-bold text-xs mt-1">Volumen de sangrado</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control input-text" id="hora-inicio">
                </div>
              </div>
            </div>
            <div class="mt-1 align-left">
              <div id="div-hora-inicio" class="form-group row">
                <label for="hora-inicio" class="col-sm-8 fw-bold text-xs mt-1">Contaje de gasas completo</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control input-text" id="hora-inicio">
                </div>
              </div>
            </div>
            <div class="mt-1 align-left">
              <div id="div-hora-inicio" class="form-group row">
                <label for="hora-inicio" class="col-sm-8 fw-bold text-xs mt-1">Contaje de instrumental completo</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control input-text" id="hora-inicio">
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-6 pl-1">
            <div class="mt-1 align-left">
              <div id="div-solicitud" class="form-group row">
                <label for="solicitud" class="col-sm-4 fw-bold text-xs mt-1 ml-1">Solicitud de AP</label>
                <div class="col-sm-2 px-0">
                    <input type="text" class="form-control input-text" id="solicitud-pre">
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control input-text" id="solicitud">
                </div>
              </div>
            </div>
            <div class="mt-1 align-left">
              <div id="div-congelacion" class="form-group row">
                <label for="congelacion" class="col-sm-4 fw-bold text-xs mt-1 ml-1">Congelación</label>
                <div class="col-sm-2 px-0">
                    <input type="text" class="form-control input-text" id="congelacion-pre">
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control input-text" id="congelacion">
                </div>
              </div>
            </div>
            <div class="mt-1 align-left">
              <div id="div-cultivo" class="form-group row">
                <label for="cultivo" class="col-sm-4 fw-bold text-xs mt-1 ml-1">Cultivo</label>
                <div class="col-sm-2 px-0">
                    <input type="text" class="form-control input-text" id="cultivo-pre">
                </div>
                <div class="col-sm-6">
                    <input type="text" class="form-control input-text" id="cultivo">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="mt-1 align-left">
            <div id="div-cultivo" class="form-group row">
              <label for="cultivo" class="col-md-12 col-lg-7 fw-bold text-xs mt-1 ml-1">Estado hemodinámico al salir de quirófano</label>
              <div class="col-md-12 col-lg-5">
                <div class="d-flex">
                  <div v-for="(item3, index) in checklist3" :key="index" class="custom-control custom-checkbox-3 align-left px-2">
                      <input type="checkbox" class="custom-control-input visually-hidden" :id="'checkbox' + index" v-model="item3.checked">
                      <label class="custom-control-label text-xs" :for="'checkbox' + index">
                          <span class="checkmark" :style="{ backgroundColor: item3.checked ? '#900052' : 'transparent' }"></span>
                          {{ item3.label }}
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="mt-1 align-left">
              <div id="div-hora-inicio" class="form-group row">
                <label for="hora-inicio" class="col-md-7 col-lg-6 fw-bold text-xs mt-1">Destino del paciente al salir de quirófano </label>
                <div class="col-md-5 col-lg-6">
                    <input type="text" class="form-control input-text" id="hora-inicio">
                </div>
              </div>
            </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  export default{
    name: 'c-asistencial-sop-historia-informeoperatorio',
    data() {
      return {
        checklist3: [{
                    label: 'Estable',
                    checked: false
                },
                {
                    label: 'Inestable',
                    checked: false
                }
            ],
      }
    },
    computed: {
        estadoChecklist3: function () {
            const completadas3 = this.checklist3.filter(item3 => item3.checked).length;
            return `${completadas3} de ${this.checklist3.length} tareas completadas.`;
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
  }
</script>