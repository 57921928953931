<template>
  <div>
    <div class="container" v-if="page == 'venta'">
      <div class="row" style="text-align: end">
        <div id="" class="col-md-12 mt-1 mb-4 float-right btn-home">
          <button
            type="button"
            class="btn btn-outline-main"
            @click="retroceder"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-venta" class="d-flex justify-content-center">
        <div id="div-venta" class="card w-100">
          <div class="card-header text-white h5 text-center bg-main">
            <strong>VENTA </strong>
          </div>
          <div class="card-body" style="background-color: #e6e6e6">
            <div class="row d-flex" v-if="page == 'venta'">
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-1">
                    <span class="input-group-text bg-main text-white"
                      ><i class="fas fa-search"></i
                    ></span>
                    <input
                      type="search"
                      id="search_product"
                      class="form-control"
                      placeholder="Digitar Venta"
                      aria-describedby="search_product"
                      v-model="search_product"
                      @keyup="mtd_filter_product"
                    />
                  </div>
                </div>

                <div class="row py-1" id="list-product">
                  <div
                    v-for="(item, index) in data_product_filter"
                    :key="index"
                    class="col-md-12 py-1 d-flex align-items-center"
                    id="item-product"
                    style="background-color: #e6e6e6; height: 80px"
                  >
                    <div
                      class="p-1 d-flex flex-column w-100 justify-content-center h-100 bg-white"
                      @click="mtdSelectSubsubcatergorie(item)"
                    >
                      <div class="align-items-center" id="name-product">
                        <p class="d-flex align-items-center mb-0 m-1">
                          <strong
                            class="mb-0 bg-secondary text-white text-center"
                            style="width: 12%; border-radius: 10px"
                          >
                            S/{{ item.precio_farmacia_particular }}
                          </strong>
                          <span style="width: 5%">&nbsp;</span>
                          <!-- Espacio entre precio y nombre -->
                          <strong
                            class="mb-0 item-name custom-tooltip"
                            data-toggle="tooltip"
                            :data-placement="'top'"
                            :title="item.name"
                            style="width: 88%"
                          >
                            {{ item.name }}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- doctores -->
              <div class="col-md-3" v-if="this.doctors.length != 0">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100 bg-main text-white title-carrito"
                    >
                      <strong>Médico</strong>
                    </span>
                    <strong>Seleccione un médico</strong>
                  </div>
                </div>
                <div class="col-md-12 list-doctor">
                  <div class="container-list">
                    <div class="" v-if="doctors.length > 0">
                      <div
                        class="box item-doctor"
                        v-for="(item, index) in doctors"
                        :key="index"
                        @click="mtdSelectDoctor(item)"
                      >
                        <p class="text-doctor">
                          DR(A).
                          {{
                            item.doctor.last_name != null
                              ? item.doctor.last_name.toUpperCase()
                              : ""
                          }}
                          {{ item.doctor.name.toUpperCase() }}
                        </p>
                      </div>
                    </div>
                    <div class="" v-else>
                      <!-- no hay data -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- carrito -->
              <div
                v-if="this.doctors.length == 0"
                class="col-md-6"
                id="div-venta-body"
              >
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100 title-carrito bg-main text-white"
                    >
                      <strong>Monto</strong></span
                    >
                  </div>
                </div>
                <div class="col-md-12 list-monto">
                  <div class="container-list">
                    <div class="" v-if="carrito.length > 0">
                      <div
                        class="box item-monto"
                        v-for="(item, index) in carrito"
                        :key="index"
                      >
                        <p class="text-monto">
                          ({{ item.quantity }}) {{ item.name }}
                        </p>
                        <div class="input-group">
                          <span
                            class="input-group-text"
                            :class="
                              posEditPrice != index &&
                              posobs != index &&
                              itemDiscount != index
                                ? ''
                                : 'col-md-12'
                            "
                            style="color: #8a8a97"
                          >
                            <strong
                              v-if="
                                posEditPrice != index &&
                                posobs != index &&
                                itemDiscount != index
                              "
                            >
                              Precio S/ {{ parseFloat(item.price).toFixed(2) }}
                              <br />
                              Desc. S/
                              {{ parseFloat(item.montodescuento).toFixed(2)
                              }}<br />
                              SubTotal S/
                              {{ parseFloat(item.subtotal).toFixed(2) }}</strong
                            >
                            <input
                              v-if="posEditPrice == index"
                              type="text"
                              style="background: white"
                              class="form-control-sm"
                              @keyup.enter="mtdOutFocusEditPrice(index)"
                              v-model="item.price"
                              @keypress="validateNumberInput"
                            />
                            <input
                              v-if="posobs == index"
                              type="text"
                              style="background: white; width: 200%"
                              class="form-control-sm"
                              @keyup.enter="mtdOutFocusEditObs(index)"
                              @blur="mtdOutFocusEditObs(index)"
                              v-model="item.obs"
                              placeholder="Motivo de Descuento."
                            />
                            <div
                              v-if="itemDiscount == index"
                              style="display: flex; align-items: center"
                            >
                              <input
                                type="text"
                                style="
                                  background: white;
                                  width: 90px;
                                  margin-right: 5px;
                                "
                                class="form-control-sm"
                                @keyup.enter="AplicarDescuento(index)"
                                v-model="item.descuento"
                                placeholder="Descuento"
                              />
                              <label style="margin: 0 5px">
                                <input
                                  type="radio"
                                  v-model="item.tipodescuento"
                                  value="porcentaje"
                                />
                                %
                              </label>
                              <label style="margin: 0 5px">
                                <input
                                  type="radio"
                                  v-model="item.tipodescuento"
                                  value="monto"
                                />
                                Monto
                              </label>
                            </div>
                          </span>
                          &nbsp;
                          <div
                            class="form-control text-right"
                            v-if="
                              posEditPrice != index &&
                              posobs != index &&
                              itemDiscount != index
                            "
                          >
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdAddQuantity(index)"
                            >
                              <i class="fas fa-plus text-white"></i>
                            </button>
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdDelQuantity(index)"
                            >
                              <i class="fas fa-minus text-white"></i>
                            </button>
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdChangePrice(index)"
                            >
                              <i class="fas fa-triangle text-white"></i>
                            </button>
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdChangeDiscount(index)"
                            >
                              <i
                                class="fa fa-percent text-white"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12"></div>
            </div>
            <div class="row" v-else-if="page == 'programar'">
              escoger camas y citas
            </div>
          </div>
          <div align="right" class="card-footer text-white bg-main text-lg">
            <span
              v-if="!posDesc && !posMotivo"
              class="btn border border-white text-white"
              style="margin-left: 20px"
              @click="addDsc()"
            >
              <strong>DESC.</strong>
            </span>
            &nbsp;
            <strong v-if="!posDesc && !posMotivo"
              >SUBTOTAL: S/ {{ cpTotal }}</strong
            >
            &nbsp;
            <strong v-if="!posDesc && !posMotivo"
              >DESC: - S/ {{ parseFloat(montoDescuento).toFixed(2) }}</strong
            >
            &nbsp;
            <!-- <strong v-if="!posDesc && !posMotivo">IGV:  S/  {{igv}}</strong
            > &nbsp; -->
            <strong v-if="!posDesc && !posMotivo"
              >TOTAL: S/ {{ cpTotalDesc }}</strong
            >
            <strong v-if="posDesc">
              DESC:&nbsp;
              <input
                type="text"
                style="background: white"
                class="form-control-sm"
                placeholder="Descuento"
                @keyup.enter="mtdOutFocusDesc()"
                v-model="dsc"
                @keypress="validateNumberInput"
              />
              <label style="margin: 0 5px">
                <input
                  type="radio"
                  v-model="TypeDiscount"
                  value="porcentaje"
                />%
              </label>
              <label style="margin: 0 5px">
                <input type="radio" v-model="TypeDiscount" value="monto" />
                Monto
              </label>
            </strong>
            <strong v-if="posMotivo">
              <textarea
                class="form-control-xl"
                style="background: white"
                placeholder="Motivo"
                v-model="motivo"
                @keyup.enter="mtdOutFocusMotivo()"
                @blur="mtdOutFocusMotivo()"
              ></textarea>
              <!-- <input
              type=""
                style="background: white"
                class="form-control-sm"
                placeholder="Motivo"
                v-model="motivo"               
                @keyup.enter="mtdOutFocusMotivo()"
                @blur="mtdOutFocusMotivo()"
              /> -->
            </strong>
            <span
              v-if="!posDesc && !posMotivo"
              class="btn border border-white text-white"
              style="margin-left: 20px"
              @click="verifyZero()"
            >
              <strong>OK</strong></span
            >
          </div>
        </div>
      </div>
      <br />
    </div>
    <ConsultorioVue
      v-if="page == 'consultas'"
      :elementProcess="elementProcess"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <CamasVue
      v-if="page == 'camas'"
      :elementProcess="elementProcess"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <SopVue
      v-if="page == 'sop'"
      :elementProcess="elementProcess"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <Laboratorio
      v-if="page == 'laboratorio'"
      :elementProcess="elementProcess"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <Imagenes
      v-if="page == 'imagenes'"
      :elementProcess="elementProcess"
      @retroceder="retroceder"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <PagoVue
      v-if="page == 'pago'"
      :castCarrito="castCarrito"
      :permissionValue="permissionValue"
      @mtdBehindProcess="mtdBehindProcess"
      @mdtCommitPayment="mdtCommitPayment"
      :client="client"
      :dsc="dsc"
      :montodescuento="montoDescuento"
      :users="users"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Imagenes from "../ventas/Imagenes.vue";
import Topico from "../ventas/Topico.vue";
import Laboratorio from "../ventas/Laboratorio.vue";
import ConsultorioVue from "../ventas/Consultorio.vue";
import CamasVue from "../ventas/Camas.vue";
import SopVue from "../ventas/Sop.vue";
import PagoVue from "../ventas/Pago.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

export default {
  name: "c-admision-page-venta",
  components: {
    Imagenes,
    Topico,
    Laboratorio,
    ConsultorioVue,
    CamasVue,
    SopVue,
    PagoVue,
    SweetAlert,
  },
  data() {
    return {
      swal: null,
      TypeDiscount: "porcentaje",
      validaDescuento: 0,
      validaDescuentoGeneral: 0,
      itemDiscount: null,
      dsc: "0",
      montoDescuento: "0",
      motivo: "",
      page: "venta",
      view: "table",
      posDesc: false,
      posMotivo: false,
      dataSubSubCategories: [],
      data_product_filter: [],
      search_product: "",
      setTimeoutSearch: "",
      posobs: null,
      doctors: [],
      carrito: [],
      item_carrito: {},
      castCarrito: [],
      elementProcess: {},
      users: [],
      /** temporal editar precio */
      posEditPrice: null,
      permissionValue: null,
      isconfirmed: null,
      dataCorreo: [],
    };
  },
  props: {
    boxes: [],
    bead: {},
    client: {},
  },
  computed: {
    cpTotal() {
      if (this.carrito.length == 0) {
        this.dsc = 0;
      }
      let total = 0;
      this.validaDescuento = 0;
      this.carrito.forEach((element) => {
        total += element.subtotal;
        if (parseFloat(element.montodescuento) > 0) {
          this.validaDescuento = 1;
        }
      });
      if (parseFloat(this.montoDescuento) > 0) {
        this.validaDescuentoGeneral = 1;
      } else {
        this.validaDescuentoGeneral = 0;
      }
      // return parseFloat((total/1.18)).toFixed(2);
      return parseFloat(total).toFixed(2);
    },
    cpTotalDesc() {
      this.validaDescuento = 0;
      if (this.carrito.length == 0) {
        this.dsc = "0";
      }
      let total = 0;
      this.carrito.forEach((element) => {
        total += element.subtotal;
        if (parseFloat(element.montodescuento) > 0) {
          this.validaDescuento = 1;
        }
      });
      let totalsig = total / 1.18;
      let final = parseFloat(totalsig - this.montoDescuento);
      let TotalFinal = final + final * 0.18;
      return parseFloat(TotalFinal).toFixed(2);
    },
    igv() {
      if (this.carrito.length == 0) {
        this.dsc = "0";
      }
      let total = 0;
      this.carrito.forEach((element) => {
        total += element.subtotal;
        if (parseFloat(element.montodescuento) > 0) {
          this.validaDescuento = 1;
        } else {
          this.validaDescuento = 0;
        }
      });
      let totalsig = total / 1.18;
      let final = parseFloat(totalsig - this.montoDescuento);
      return parseFloat(final * 0.18).toFixed(2);
    },
  },
  created() {
    // this.mtdGetDataSubSubCategories();
    this.mtdGetDataPerAndData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetDataSubSubCategories: function (q) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/Venta/" +
          this.client.type +
          "/" +
          this.client.insurance +
          "/subsubcategories?search_product=" +
          q,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          let data = [];
          if (this.client.type == "eps") {
            if (this.client.copago.length > 0) {
              response.data.data.forEach((element) => {
                let tmpPrice = element.precio_farmacia_particular;
                let newPrice = (tmpPrice * (100 - this.client.copago)) / 100;
                element.precio_farmacia_particular =
                  parseFloat(newPrice).toFixed(2);
                data.push(element);
              });
            } else {
              response.data.data.forEach((element) => {
                if (
                  element.catId == 1 ||
                  element.catId == 2 ||
                  element.catId == 8
                ) {
                  element.precio_farmacia_particular =
                    this.client.fijo.length == 0
                      ? element.precio_farmacia_particular
                      : parseFloat(this.client.fijo).toFixed(2);
                }
                data.push(element);
              });
            }
          } else {
            data = response.data.data;
          }
          this.dataSubSubCategories = data;
          this.data_product_filter = data;
          this.users = response.data.user;
        })
        .catch((errors) => {});
    },
    retroceder() {
      this.$emit("mtdChangePage", "home");
    },
    mtd_filter_product: function () {
      if (search_product.value.length >= 7 && search_product.value != "") {
        let q = search_product.value.toString().toUpperCase();
        this.mtdGetDataSubSubCategories(q);
        this.data_product_filter = [];
        let i = 0;
        this.dataSubSubCategories.forEach((element) => {
          let name = element.name.toString().toUpperCase();
          if (name.includes(q)) {
            this.data_product_filter.push(element);
          }
        });
      } else {
        this.data_product_filter = this.dataSubSubCategories;
      }
    },
    search() {
      clearTimeout(this.setTimeoutSearch);
      this.setTimeoutSearch = setTimeout(this.mtdGetDataSubSubCategories, 360);
    },
    addDsc() {
      if (this.carrito.length > 0) {
        if (this.validaDescuento == 1) {
          this.$refs.SweetAlert.showWarning("Ya Realizo Descuentos por Item.");
          return false;
        }
        Swal.fire({
          title: "Va Registrar Un Descuento Global",
          text: "Si, Confirmar!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          width: "400px",
        }).then((result) => {
          if (result.value) {
            const todosMayoresQueCero = this.carrito.every(
              (item) => parseFloat(item.price) > 0
            );
            if (todosMayoresQueCero) {
              this.posDesc = true;
              return true;
            } else {
              Swal.fire({
                text: "Hay valores iguales o menores que 0.00",
                icon: "warning",
                confirmButtonColor: "#900052",
              });
              return false;
            }
          }
        });
      } else {
        this.$refs.SweetAlert.showWarning("No hay productos en el carrito");
        return false;
      }
    },
    /** carrito */
    mtdSelectSubsubcatergorie: function (item) {
      let bo = false;
      if (this.carrito.length == 0) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          price: item.precio_farmacia_particular,
          price_v: item.precio_farmacia_particular,
          obs: "",
          descuento: "0",
          subtotal: item.precio_farmacia_particular * 1,
          tipodescuento: "porcentaje",
          montodescuento: "0",
          quantity: 1,
          subategorie: {
            id: item.subCatId,
            name: item.subCatName,
          },
          categorie: {
            id: item.catId,
            name: item.catName,
            type: item.catType,
          },
          doctor: {
            id: null,
            name: null,
          },
          reservadespues: false,
        };
        if (item.catId == 1 || item.catId == 8 || item.catId == 10) {
          if (this.doctors.length == 0) {
            this.doctors =
              item.catId == 1 || item.catId == 8 || item.catId == 10
                ? item.doctors
                : [];
            //  this.doctors = item.catId==1?item.doctors: [];
            // this.carrito.push(this.item_carrito);
          } else {
            this.$refs.SweetAlert.showWarning(
              "Este servicio no tiene medicos asignados."
            );
            this.doctors = [];
          }
        } else {
          this.doctors = [];
          this.carrito.push(this.item_carrito);
          this.actulizarDscGlobal();
        }
        bo = true;
      } else {
        let index = this.carrito.findIndex((element) => element.id == item.id);
        if (index !== -1) {
          this.carrito[index].quantity += 1;
          this.carrito[index].subtotal =
            this.carrito[index].quantity * this.carrito[index].price;
          this.actulizarDscGlobal();
          this.AplicarDescuento1(index);
          bo = true;
        }
      }
      if (!bo) {
        // this.doctors = item.catId==1?[]: item.doctors;
        this.doctors =
          item.catId == 1 || item.catId == 8 || item.catId == 10
            ? item.doctors
            : [];
        this.item_carrito = {
          id: item.id,
          name: item.name,
          price: item.precio_farmacia_particular,
          price_v: item.precio_farmacia_particular,
          obs: "",
          descuento: "0",
          subtotal: item.precio_farmacia_particular * 1,
          tipodescuento: "porcentaje",
          montodescuento: "0",
          quantity: 1,
          subategorie: {
            id: item.subCatId,
            name: item.subCatName,
          },
          categorie: {
            id: item.catId,
            name: item.catName,
            type: item.catType,
          },
          doctor: {
            id: null,
            name: null,
          },
          reservadespues: false,
        };
        if (this.doctors.length == 0) {
          this.carrito.push(this.item_carrito);
        }
        bo = false;
      }
      this.search_product = "";
      this.data_product_filter = [];
    },
    mtdSelectDoctor: function (item) {
      this.item_carrito.doctor.id = item.doctor.id;
      this.item_carrito.doctor.name =
        item.doctor.name + " " + item.doctor.last_name;
      this.doctors = [];
      //this.carrito.push(this.item_carrito), (this.item_carrito = []);
      this.carrito.push(this.item_carrito);
    },
    mtdAddQuantity: function (pos) {
      this.carrito[pos].quantity = this.carrito[pos].quantity + 1;
      this.carrito[pos].subtotal =
        this.carrito[pos].quantity * this.carrito[pos].price;
      this.AplicarDescuento1(pos);
    },
    mtdDelQuantity: function (pos) {
      let cantidad = this.carrito[pos].quantity;
      if (cantidad > 1) {
        this.carrito[pos].quantity = this.carrito[pos].quantity - 1;
        this.carrito[pos].subtotal =
          this.carrito[pos].quantity * this.carrito[pos].price;
        this.AplicarDescuento1(pos);
      } else {
        let temp = [];
        this.carrito.forEach((element, index) => {
          if (index != pos) {
            temp.push(element);
          }
        });
        this.carrito = temp;
      }
    },
    /** programas citas o reservas */
    mtdNextStep: function (step) {
      if (this.carrito.length > 0) {
        if (step == "2") {
          this.castCarrito = [];
          this.carrito.forEach((element) => {
            element.data = [];
            element.programado = false; // false cuando funcione reserva
            element.actualPage = false;
            if (element.categorie.id == 1) {
              /** consultorio */
              element.component = "consultas";
            } else if (
              element.categorie.id == 2 ||
              element.categorie.id == 4 ||
              element.categorie.id == 5 ||
              element.categorie.id == 6 ||
              element.categorie.id == 7 ||
              element.categorie.id == 3
            ) {
              /** Hospitalización */
              element.component = "camas";
            } else if (
              element.categorie.id == 8 ||
              element.categorie.id == 10
            ) {
              /** sop */
              element.component = "sop";
            } else if (element.categorie.id == 11) {
              /** laboratorio */
              element.component = "laboratorio";
            } else if (element.categorie.id == 13) {
              /** imagenes */
              element.component = "imagenes";
            } else {
              element.programado = true;
              element.component = "pago";
            }
            this.castCarrito.push(element);
          });
          //poner  los de pago al ultimo
          this.castCarrito.sort((a, b) => {
            if (a.component === "pago" && b.component !== "pago") {
              return 1;
            } else if (a.component !== "pago" && b.component === "pago") {
              return -1;
            } else {
              return 0;
            }
          });

          this.mtdProcessCastCarrito();
          // falta arreglar el orden de componente de carrito ejemplo: primer item == pago pasa directo son validar que los demas sean otros componentes
        }
      } else {
        this.$refs.SweetAlert.showWarning("Seleccione un Producto o Servicio");
      }
    },
    verifyZero: function () {
      const todosMayoresQueCero = this.carrito.every(
        (item) => parseFloat(item.price) > 0
      );
      if (todosMayoresQueCero) {
        this.mtdNextStep("2");
        return true;
      } else {
        this.$refs.SweetAlert.showWarning(
          "Hay valores iguales o menores que 0.00"
        );
        return false;
      }
    },
    mtdProcessCastCarrito: function () {
      let boo = false;
      for (let index = 0; index < this.castCarrito.length; index++) {
        const element = this.castCarrito[index];
        if (element.actualPage == true) {
          boo = true;
          element.actualPage = false;
          if (index == this.castCarrito.length - 1) {
            //ir a la página de pagos
            this.page = "pago";
          } else {
            this.elementProcess = {};
            this.elementProcess = this.castCarrito[index + 1];
            this.castCarrito[index + 1].actualPage = true;
            this.page = this.castCarrito[index + 1].component;
            index = this.castCarrito.length;
          }
        }
      }
      if (boo == false) {
        this.castCarrito[0].actualPage = true;
        this.elementProcess = {};
        this.elementProcess = this.castCarrito[0];
        // this.page = "pago";  sin sreserva
        this.page = this.castCarrito[0].component;
      }
    },
    mtdNextProcess: function (process) {
      this.page = "";
      for (let index = 0; index < this.castCarrito.length; index++) {
        let element = this.castCarrito[index];
        if (element.id == process.id) {
          this.castCarrito[index] = process;
          for (let i = 0; i < this.castCarrito.length; i++) {
            if (
              this.castCarrito[index].categorie.id ==
              this.castCarrito[i].categorie.id
            ) {
              this.castCarrito[i].data = this.castCarrito[index].data;
              this.castCarrito[i].reservadespues =
                this.castCarrito[index].reservadespues;
            }
          }
        }
      }
      this.mtdProcessCastCarrito();
    },
    mtdBehindProcess: function () {
      let boo = false;
      for (let index = 0; index < this.castCarrito.length; index++) {
        const element = this.castCarrito[index];
        if (element.actualPage == true) {
          if (index != 0) {
            boo = true;
            this.elementProcess = this.castCarrito[index - 1];
            element.actualPage = false;
            this.castCarrito[index - 1].actualPage = true;
            this.page = this.castCarrito[index - 1].component;
            break;
          } else {
            break;
          }
        }
      }
      if (this.page == "pago") {
        let cat = this.castCarrito[this.castCarrito.length - 1].categorie.id;
        if (cat == 17 || cat == 12 || cat == 3 || cat == 14) {
          this.page = "venta";
          this.search_product = "";
        } else {
          this.castCarrito[this.castCarrito.length - 1].actualPage = true;
          this.elementProcess = this.castCarrito[this.castCarrito.length - 1];
          this.page = this.castCarrito[this.castCarrito.length - 1].component;
        }
      } else if (boo == false) {
        //alert
        Swal.fire({
          title: "¿Desea volver al menú?",
          text: "¡Se perderá todo los datos seleccionados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          width: "400px",
        }).then((result) => {
          if (result.isConfirmed) {
            this.page = "venta";
            this.search_product = "";
          }
        });
      }
    },

    // mtdGetEmailContentByTitle: async function (title) {
    //   try {
    //     const response = await this.get({
    //       url: this.$store.getters.get__url + "/EmailContent/index",
    //       token: this.$store.getters.get__token,
    //     });

    //     if (response.data) {
    //       const emailContent = response.data.find(item => item.title === title);
    //       return emailContent || null;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   return null;
    // },

    //Inicio correo - Whatsapp
    mdtCommitPayment: async function (
      payment,
      chkPatiente,
      planilla,
      garantia,
      impuestos,
      selectedVoucherCancel
    ) {
      try {
        const response = await this.post({
          url: this.$store.getters.get__url + "/Venta/store",
          token: this.$store.getters.get__token,
          params: {
            data: this.castCarrito,
            payment: payment,
            patient: this.client,
            bead: this.bead,
            campu_id: this.$store.getters.get__campus,
            chkPatiente: chkPatiente,
            planilla: planilla,
            garantia: garantia,
            impuestos: impuestos,
            motivoDesc: this.motivo,
            descuento: this.dsc,
            tipodescuento: this.TypeDiscount,
            montodescuento: this.montoDescuento,
            validardescuento: this.validaDescuentoGeneral,
            selectedVoucherCancel: selectedVoucherCancel,
          },
        });

        if (response.state === 0) {
          if (response.data != null) {
            let res = response.data.sunat;

            const result = await Swal.fire({
              title: "Venta Finalizada",
              text: "Seleccione una opción",
              icon: "success",
              showCancelButton: true,
              showDenyButton: true,
              confirmButtonText: '<i class="fas fa-file-pdf"></i> Imprimir PDF',
              cancelButtonText:
                '<i class="fas fa-envelope"></i> Enviar a Correo',
              denyButtonText:
                '<i class="fab fa-whatsapp"></i> Enviar a WhatsApp',
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-secondary",
                denyButton: "btn btn-success",
                actions: "d-flex justify-content-between gap-2",
              },
              buttonsStyling: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: true,
              showCloseButton: true,
              // didOpen: () => {
              //   const denyButton = Swal.getDenyButton();
              //   if (denyButton) {
              //     denyButton.disabled = true;
              //   }
              // }
            });

            const emailTitle = "Envio de comprobante al cliente";
            if (result.isConfirmed) {
              window.open(res.json.enlace_del_pdf_ticket, "_blank");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              let email = this.client.email;
              if (!email) {
                await Swal.fire({
                  title: "No hay correo registrado",
                  text: "Por favor, ingresa un correo electrónico para enviar el comprobante.",
                  icon: "warning",
                  input: "email",
                  inputPlaceholder: "example@domain.com",
                  showCancelButton: true,
                }).then(async (inputResult) => {
                  if (inputResult.isConfirmed && inputResult.value) {
                    email = inputResult.value;
                  } else {
                    return;
                  }
                });
              } else {
                await Swal.fire({
                  title: "Confirmar correo electrónico",
                  text: "Por favor, revisa o edita el correo antes de enviar el comprobante.",
                  icon: "info",
                  input: "email",
                  inputValue: email,
                  showCancelButton: true,
                }).then(async (inputResult) => {
                  if (inputResult.isConfirmed && inputResult.value) {
                    email = inputResult.value;
                  } else {
                    return;
                  }
                });
              }
              await this.post({
                url: this.$store.getters.get__url + "/send/email",
                token: this.$store.getters.get__token,
                params: {
                  type: 1,
                  title: emailTitle,
                  dni: this.client.document,
                  destinatario: [email],
                  body: `Hola ${this.client.fullName} `,
                  archivo_path: res.json.enlace_del_pdf_ticket,
                  xml_path: res.json.enlace_del_xml,
                },
              });

              await Swal.fire({
                title: "Correo enviado",
                text:
                  "El comprobante electrónico se ha enviado correctamente a " +
                  email,
                icon: "success",
              });
            } else if (result.dismiss === Swal.DismissReason.deny) {
              let phone = this.client.celphone;
              if (!phone) {
                await Swal.fire({
                  title: "No hay número registrado",
                  text: "Por favor, ingresa un número de teléfono para enviar el comprobante.",
                  icon: "warning",
                  input: "text",
                  inputPlaceholder: "Ingrese su número de teléfono",
                  showCancelButton: true,
                }).then(async (inputResult) => {
                  if (inputResult.isConfirmed && inputResult.value) {
                    phone = inputResult.value;
                  } else {
                    return;
                  }
                });
              } else {
                await Swal.fire({
                  title: "Confirmar número de teléfono",
                  text: "Por favor, revisa o edita el número de teléfono antes de enviar el comprobante.",
                  icon: "info",
                  input: "text",
                  inputValue: phone,
                  showCancelButton: true,
                }).then(async (inputResult) => {
                  if (inputResult.isConfirmed && inputResult.value) {
                    phone = inputResult.value;
                  } else {
                    return;
                  }
                });
              }

              await this.post({
                url: this.$store.getters.get__url + "/send/wsp",
                token: this.$store.getters.get__token,
                params: {
                  dni: this.client.document,
                  phone: phone,
                  // phone: "51" + phone,
                  fileUrl: res.json.enlace_del_pdf_ticket,
                  filename: `${response.data.serie}-${response.data.number}.pdf`,
                  message: `Hola ${this.client.fullName}, clínica BM te envía tu comprobante de venta electrónica.`,
                },
              });

              await Swal.fire({
                title: "Mensaje enviado",
                text:
                  "El comprobante electrónico se ha enviado correctamente a " +
                  phone,
                icon: "success",
              });
            }

            this.$emit("mtdCommitPayment", this.bead);
          } else {
            //credito
            Swal.fire({
              text: "Venta Finalizada",
              icon: "success",
              confirmButtonColor: "#900052",
            });
            this.$emit("mtdCommitPayment", this.bead);
          }
        } else {
          alert(response.exception);
        }
      } catch (error) {
        console.error(error);
      }
    },

    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    validatePhoneNumber(phone) {
      const phoneRegex = /^[0-9]{9}$/;
      return phoneRegex.test(phone);
    },

    //fin Correo - whatsapp

    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
    /** temporal cambiar precio */
    mtdChangePrice: function (pos) {
      this.posEditPrice = pos;
    },
    mtdOutFocusEditPrice: function (index) {
      let carrito = this.carrito[index];
      this.carrito[index].subtotal = carrito.price * carrito.quantity;
      this.AplicarDescuento1(index);
      this.posEditPrice = null;
    },
    AplicarDescuento: function (index) {
      let item = null;
      item = this.carrito[index];
      item.subtotal = item.quantity * item.price;
      if (item.tipodescuento === "monto" && item.descuento != "0") {
        let subtotalSING = item.subtotal / 1.18;
        subtotalSING = subtotalSING - parseFloat(item.descuento);
        item.montodescuento = parseFloat(item.descuento);
        item.subtotal = subtotalSING + subtotalSING * 0.18;
      } else if (item.tipodescuento === "porcentaje" && item.descuento != "0") {
        let subtotalSING = item.subtotal / 1.18;
        const porcentajeDescuento = parseFloat(item.descuento) / 100;
        subtotalSING = subtotalSING - subtotalSING * porcentajeDescuento;
        item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
        item.subtotal = subtotalSING + subtotalSING * 0.18;
      } else if (item.descuento == "0") {
        item.montodescuento = "0";
        item.subtotal = item.quantity * parseFloat(item.price);
      }
      if (item.price < 0) {
        item.price = 0;
      }
      this.carrito[index] = item;
      this.itemDiscount = null;
      this.actulizarDscGlobal();
      if (parseFloat(item.montodescuento) > 0) {
        this.posobs = index;
      }
    },
    mtdChangeDiscount: function (index) {
      if (this.validaDescuentoGeneral == 1) {
        this.$refs.SweetAlert.showWarning("Ya Registro Un descuento Global");
        return false;
      }
      this.itemDiscount = index;
    },
    mtdOutFocusEditObs(index) {
      if (this.carrito[index].obs != "") {
        this.posobs = null;
      } else {
        this.$refs.SweetAlert.showWarning("Registre un motivo");
        return;
      }
    },
    mtdOutFocusDesc() {
      if (parseFloat(this.dsc) > 0) {
        this.posDesc = false;
        let total = 0;
        this.carrito.forEach((element) => {
          total += element.subtotal;
        });
        let TotalSIG = total / 1.18;
        if (this.TypeDiscount == "monto" && this.dsc != "0") {
          this.montoDescuento = parseFloat(this.dsc);
        } else if (this.TypeDiscount == "porcentaje" && this.dsc != "0") {
          const porcentajeDescuento = parseFloat(this.dsc) / 100;
          this.montoDescuento = TotalSIG * porcentajeDescuento;
        }
        this.$refs.SweetAlert.showWarning("Registre un motivo");
        this.posMotivo = true;
      } else {
        this.dsc = "0";
        this.montoDescuento = "0";
        this.posDesc = false;
        this.posMotivo = false;
        this.motivo = "";
      }
    },
    mtdOutFocusMotivo() {
      if (this.motivo != "") {
        this.posMotivo = false;
      } else {
        this.$refs.SweetAlert.showWarning("Registre un motivo");
      }
    },
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdGetDataPerAndData: function () {
      this.post({
        url: this.$store.getters.get__url + "/Jefatura/otrosPermisosJefa",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.permissionValue = response.data[0].permission;
        })
        .catch((errors) => {});
    },
    AplicarDescuento1: function (index) {
      let item = null;
      item = this.carrito[index];

      if (item.tipodescuento === "monto" && item.descuento != "0") {
        let subtotalSING = item.subtotal / 1.18;
        subtotalSING = subtotalSING - parseFloat(item.descuento);
        item.montodescuento = parseFloat(item.descuento);
        item.subtotal = subtotalSING + subtotalSING * 0.18;
      } else if (item.tipodescuento === "porcentaje" && item.descuento != "0") {
        let subtotalSING = item.subtotal / 1.18;
        const porcentajeDescuento = parseFloat(item.descuento) / 100;
        subtotalSING = subtotalSING - subtotalSING * porcentajeDescuento;
        item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
        item.subtotal = subtotalSING + subtotalSING * 0.18;
      } else if (item.descuento == "0") {
        item.montodescuento = "0";
        item.subtotal = item.quantity * parseFloat(item.price);
      }
      if (item.price < 0) {
        item.price = 0;
      }
      this.carrito[index] = item;
      this.actulizarDscGlobal();
    },
    actulizarDscGlobal: function () {
      if (parseFloat(this.dsc) > 0) {
        this.posDesc = false;
        let total = 0;
        this.carrito.forEach((element) => {
          total += element.subtotal;
        });
        let TotalSIG = total / 1.18;
        if (this.TypeDiscount == "monto" && this.dsc != "0") {
          this.montoDescuento = parseFloat(this.dsc);
        } else if (this.TypeDiscount == "porcentaje" && this.dsc != "0") {
          const porcentajeDescuento = parseFloat(this.dsc) / 100;
          this.montoDescuento = TotalSIG * porcentajeDescuento;
        }
      } else {
        this.dsc = "0";
        this.montoDescuento = "0";
        this.posDesc = false;
        this.posMotivo = false;
        this.motivo = "";
      }
    },
  },
};
</script>
