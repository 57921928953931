<template>
  <div>
    <div id="div-anular" class="px-2 w-100">
      <div class="card-body w-100">
        <div class="row">
          <div class="row d-flex">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                  <div class="input-group input-group">
                      <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                      <v-select
                        class="text-dark form-control"
                        label="name"
                        :options="servicesPa"
                        placeholder="- Buscar Paciente (Escriba uno...) -"
                        @search="searchPa"
                        v-model="selectedPa"
                        :reduce="(name) => name.id"
                        required
                        :clearable="false"
                        @input="mtdGetData"
                      >
                        <template slot="no-options">
                          No hay Pacientes para la busqueda.
                        </template>
                      </v-select>
                  </div>
              </div>
          </div>

          <div class="mt-2"></div>

          <!-- Segundo select -->
          <div class="row d-flex" v-if="showSecondSelect">
            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-sm-12 mt-2">
              <div class="input-group input-group mb-2">
                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>

                <v-select id="serviceSelect" class="text-dark form-control" label="name" :options="serviceOptions"
                placeholder="- Buscar Medicamento (Escriba uno...) -"  v-model="selectedProd"  @input="mtdSearchFilter"/>
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 btn-home mt-2">
              <button type="button" class=" btn btn-bm-noradius w-100" style="padding: 10px;" @click="mtdAddDev()">
                  <i class="fas fa-plus"></i> Registrar
              </button>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-2" v-if="showTable && cpData.length !== 0">
            <div class="w-100 pt-2" style="background-color: #ffffff">
              <div class="cuerpo">
                <div class="col-md-12 mt-2" id="content-timelinetable">
                    <table id="tb-cxc" class="table table-bordered border-main">
                        <thead>
                            <tr class="text-center text-white border-white bg-main align-middle">
                                <th scope="col">N°</th>
                                <th scope="col">MEDICAMENTO</th>
                                <th scope="col">CANTIDAD</th>
                                <th scope="col">CANT. DEVOLVER</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr  v-for="(item, index) in cpData" :key="index" class="text-center font-size">
                            <td>{{ index + 1}}</td>
                            <td>{{ item.name}}</td>
                            <td>{{ item.total_quantity}}</td>
                            <td class="table-th-state">
                              <input
                                type="text"
                                class="input-p form-control form-control-sm text-center"
                                v-model="item.quantity_dev"
                                @keydown="filterKey"
                                placeholder="Ingrese Cant."
                                @input="validateInput(item.id, item.quantity_dev)"
                                style="font-size: 10px; padding: 0.25rem 0.5rem"
                                :disabled="item.present === 1"
                              />
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>

          <!--pag-->
          <div v-if="showTable && cpData.length > 0" class="d-flex justify-content-center px-1 mb-4">
              <nav aria-label="Page navigation example">
                  <ul class="pagination mb-1">
                      <li class="page-item">
                          <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                      </li>
                      <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                          <a :class="
                selectPage === 1 ? 'page-link active' : 'page-link'
              " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                      </li>
                      <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                          <span class="page-link">...</span>
                      </li>

                      <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                          <a :class="
              selectPage === item ? 'page-link active' : 'page-link'
            " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                          </a>
                      </li>
                      <li v-if="
            limitedStepPagination[
              limitedStepPagination.length - 1
            ] <
            stepPagination.length - 1
          " class="page-item disabled">
                          <span class="page-link">...</span>
                      </li>
                      <li v-if="
            limitedStepPagination[
              limitedStepPagination.length - 1
            ] !== stepPagination.length
          " class="page-item">
                          <a :class="
              selectPage === limitedStepPagination.length + 2
                ? 'page-link active'
                : 'page-link'
            " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                          </a>
                      </li>
                      <li class="page-item">
                          <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                      </li>
                  </ul>
              </nav>
          </div>
        </div>
      </div>
    </div>

    <cModalDetalleDevVue
      :title="modalDet.title"
      :boo_modal="modalDet.boo_modal"
      :item="modalDet.item"
      :itemPdf="modalDet.itemPdf"
      :patient_id="modalDet.patient_id"
      @mtdClose="mtdCloseModalDet"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalDetalleDevVue from '../../Modals/cModalDetalleDev.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from "moment";

export default {
  name: "c-devoluciones-register",
  components:{SweetAlert, cModalDetalleDevVue},
  data() {
    return {
      swal: null,
      search: "",
      dataDev:[],
      dataShow: [],
      dataProd: [],
      selectedProd: "",
      servicesPa: [],
      selectedPa: "",
      showTable: false,
      modalDev: {
        title: "Detalle de Devoluciones",
        item: [],
        boo_modal: false,
        pos: null,
      },
      modalDet: {
        title: "DEVOLUCIONES",
        item: [],
        itemPdf: [],
        boo_modal: false,
        pos: null,
        patient_id: null
      },
      //Pag
      page: 0,
      selectPage: 1,
      stepPagination: [],
      totalPagesToShow: 4,
      showSecondSelect: false,
    }
  },
  created() {
  },
  watch: {
    selectPage() {
        this.calculateData(10);
    },
  },
  computed: {
    cpData() {
      return this.dataShow;
    },

    limitedStepPagination() {
        const totalPages = this.stepPagination.length;
        const currentPage = this.selectPage;

        let startPage = 1;
        let endPage = totalPages;
        if (totalPages > this.totalPagesToShow) {
            const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

            if (currentPage > halfPagesToShow) {
                startPage = currentPage - halfPagesToShow;
                endPage = currentPage + halfPagesToShow - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = totalPages - this.totalPagesToShow + 1;
                }
            } else {
                endPage = this.totalPagesToShow;
            }
        }

        return Array.from({
                length: endPage - startPage + 1,
            },
            (_, i) => startPage + i
        );
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("returnHome");
    },

    searchPa: function (search, loading) {
      if (search.length > 2) {
        this.servicesPa = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/Pharmacy/return/searchDevoluPa/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.servicesPa = response.data;
            loading(false);
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
      }
    },

    mtdGetData: function() {
      this.selectedProd = "";
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/return/getDevolver",
        token: this.$store.getters.get__token,
        params: {
          selectedProd: this.selectedProd,
          selectedPa: this.selectedPa,
        },
      })
      .then((response) => {
        if(response.data.length > 0){
          this.dataDev = response.data;
          this.showTable = true;
          this.showSecondSelect = true;

          let uniqueServiceNames = new Set();
          this.dataDev.forEach(item => {
              uniqueServiceNames.add(item.name);
          });
          this.serviceOptions = ["TODOS", ...Array.from(uniqueServiceNames)];

          this.calculateData(10);
        }else{
          this.showTable = false;
          this.$refs.SweetAlert.showWarning("No hay Datos");
        }
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },

    calculateData(items) {
        if (!this.dataDev || !this.dataDev.length) {
            return (this.dataShow = []);
        }
        let filteredData = this.dataDev;
        let indexInitial = this.selectPage;

        if (this.selectedProd && this.selectedProd !== 'TODOS') {
            filteredData = filteredData.filter((element) => {
                return element.name === this.selectedProd;
            })
            indexInitial = 1;
        }

        let totalPages = Math.ceil(filteredData.length / items);
        this.stepPagination = Array.from({
                length: totalPages,
            },
            (_, index) => index + 1
        );
        let startIndex = (indexInitial - 1) * items;
        let endIndex = startIndex + (items - 1);
        this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        this.count = this.count + 1;
    },

    mtdSearchFilter() {
        this.calculateData(10);
    },

    mtdOpenDev: function () {
        this.post({
          url: this.$store.getters.get__url + "/Pharmacy/return/modalDev",
          token: this.$store.getters.get__token,
          params: {
            selectedPa: this.selectedPa,
          },
        })
        .then((response) => {
          if(response.data.length > 0){
            this.modalDev.item = response.data;
            this.modalDev.boo_modal = true;
          }else{
            this.$refs.SweetAlert.showWarning("No hay Devoluciones");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    mtdCloseModal: function () {
      this.modalDev = {
        title: "Detalle de Devoluciones",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdCloseModalDet: function () {
      this.modalDet = {
        title: "DEVOLUCIONES",
        item: [],
        itemPdf: [],
        boo_modal: false,
        pos: null,
      };
      this.mtdGetData();
    },

    filterKey: function (e) {
      let b = false;
      b = /^[0-9]+$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },

    validateInputt: function (id, value) {
        let sum = 0;
        this.dataDev.forEach(item => {
          if (item.id === id) {
            item.quantity_dev = value;
            sum += parseInt(item.total_quantity);
          }
        });

        if (parseInt(value) > sum) {
          value = "";
          this.dataDev.forEach(item => {
            if (item.id === id) {
              item.quantity_dev = "";
              this.$refs.SweetAlert.showWarning("La Cantidad a Devolver supera a la cantidad Despachada");
            }
          });
        }
    },

    validateInput: function(id, value) {
        let sum = 0;
        let remaining = value;

        this.dataDev.forEach(item => {
            if (item.id === id) {
                sum += parseInt(item.total_quantity);
            }
        });

        this.dataDev.forEach(item => {
            if (item.id === id) {
                if (remaining > 0) {
                    if (remaining >= parseInt(item.total_quantity)) {
                        item.quantity_dev = item.total_quantity;
                        remaining -= parseInt(item.total_quantity);
                    } else {
                        item.quantity_dev = remaining;
                        remaining = 0;
                    }
                } else {
                    item.quantity_dev = "";
                }
            }
        });

        if (remaining > 0) {
          this.dataDev.forEach(item => {
                if (item.id === id) {
                    item.quantity_dev = "";
                }
            });
          this.$refs.SweetAlert.showWarning("La Cantidad a Devolver supera a la cantidad Despachada del Medicamento");
        }
    },

    mtdAddDev: function () {
      const dataQuantityDev = this.dataDev
      .filter(item => item.quantity_dev)
      .map(item => ({
        quantity_dev: item.quantity_dev,
        id: item.id
      }));
      if (dataQuantityDev.length === 0) {
        this.$refs.SweetAlert.showWarning("Ingrese una Cantidad a Devolver");
        return;
      }
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/return/devolver",
        token: this.$store.getters.get__token,
        params: {
          data: dataQuantityDev,
          idPa: this.selectedPa,
        },
      })
      .then((response) => {
        if(response.data.state ==  0){
          this.modalDet.item = response.data.data;
          this.modalDet.itemPdf = response.data.dataPdf;
          this.modalDet.patient_id=this.selectedPa;
          this.modalDet.boo_modal = true;

          // Limpiar los inputs
          this.dataDev.forEach(item => {
            item.quantity_dev = "";
          });
        }else{
          this.$refs.SweetAlert.showError("Error");
        }
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },

    /** paginación */
    backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
    nextPage() {
        this.selectPage =
            this.selectPage < this.stepPagination.length ?
            this.selectPage + 1 :
            this.stepPagination.length;
    },
    selectedPage(page) {
        this.selectPage = page;
    },


  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatDateTime(dateTime) {
      return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: darkgray lightgray;
}
.font-size{
  font-size: 12px;
  vertical-align: middle;
}
</style>
