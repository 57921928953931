<template>
  <div>    
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
      <div :style="mtdGetStyle('anular')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('anular')"><span :style="mtdGetStyleSpan('anular')" class="btn w-100"><strong>ANULAR</strong></span></div>
      <div :style="mtdGetStyle('rendirEgresos')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('rendirEgresos')"><span :style="mtdGetStyleSpan('rendirEgresos')" class="btn w-100"><strong>RENDIR EGRESOS</strong></span></div>
      <div :style="mtdGetStyle('altas')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('altas')"><span :style="mtdGetStyleSpan('altas')" class="btn w-100"><strong>ALTAS DE CHECKOUT</strong></span></div>
      <div :style="mtdGetStyle('vueltos')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('vueltos')"><span :style="mtdGetStyleSpan('vueltos')" class="btn w-100"><strong>VUELTOS</strong></span></div>
      <div :style="mtdGetStyle('cxc')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cxc')"><span :style="mtdGetStyleSpan('cxc')" class="btn w-100"><strong>CXC</strong></span></div>
      <div :style="mtdGetStyle('planillas')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('planillas')"><span :style="mtdGetStyleSpan('planillas')" class="btn w-100"><strong>PLANILLAS</strong></span></div>
      <div :style="mtdGetStyle('consultar')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('consultar')"><span :style="mtdGetStyleSpan('consultar')" class="btn w-100"><strong>CONSULTAR DOCUMENTO</strong></span></div>
      <div :style="mtdGetStyle('cola')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cola')"><span :style="mtdGetStyleSpan('cola')" class="btn w-100"><strong>COLA DE PACIENTES</strong></span></div>
      <div :style="mtdGetStyle('cierre_caja')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cierre_caja')"><span :style="mtdGetStyleSpan('cierre_caja')" class="btn w-100"><strong>CIERRES DE CAJA</strong></span></div>
      <div :style="mtdGetStyle('pasajes')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('pasajes')"><span :style="mtdGetStyleSpan('pasajes')" class="btn w-100"><strong>PASAJES</strong></span></div>
      <div :style="mtdGetStyle('cxp')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cxp')"><span :style="mtdGetStyleSpan('cxp')" class="btn w-100"><strong>CXP</strong></span></div>
      <div :style="mtdGetStyle('plan')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('plan')"><span :style="mtdGetStyleSpan('plan')" class="btn w-100"><strong>PLANES</strong></span></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'FooterGerenciaComponent',
  props: {
    pageView: String
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home" && this.pageView != "venta" && this.pageView != "close") return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home" && this.pageView != "venta" && this.pageView != "close") return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>