<template>
  <div>
    <div class="w-100 h-100">
      <Nav :rolname="rolname" />
    </div>
    <div class="content h-100" style="background: white">
      <Home
        v-if="page == 'home' && verifBead == true" 
        @changePage="changePage"
        @mtdBackCampus="mtdBackCampus"
      />
      <Open
        :boxes="boxes"
        v-if="verifBead == false"
        @mtdOpen="mtdOpen"
        @mtdBackCampus="mtdBackCampus"
      />
      <Close
        v-if="verifBead == true && page == 'reporteClose'"
        @mtdChangePage="changePage"
        :bead="bead"
        :originPage="area"
        @mtdBackCampus="mtdBackCampus"
      />
      <Recetas v-if="page == 'recetas'" @returnHome="returnHome" />
      <Directa v-if="page == 'directa'" @returnHome="returnHome" @changePageVen="changePageVen"/>
      <Venta v-if="page == 'venta'" @returnHome="returnHome" :bead="bead" :client="client" @mtdCommitPayment="mtdCommitPayment" @changePageVen="changePageVen"/>
      <Devoluciones v-if="page == 'return'" @returnHome="returnHome" />
      <Anular v-if="page == 'anular'" @returnHome="returnHome" />
      <Inventario v-if="page == 'inventario'" @returnHome="returnHome" />
    </div>
    <div class="footer">
      <FooterVue v-if="verifBead == true" @changePage="changePage" :pageView="page" />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Close from "../../components/Admision/pages/ReportClose.vue";
  import Nav from "../../components/Farmacia/Nav.vue";
  import FooterVue from "../../components/Farmacia/Footer.vue";
  import Home from "../../components/Farmacia/Home.vue";
  import Recetas from "../../components/Farmacia/pages/Recipes.vue";
  import Directa from "../../components/Farmacia/pages/Directa.vue";
  import Venta from "../../components/Farmacia/pages/Venta.vue";
  import Devoluciones from "../../components/Farmacia/pages/Devoluciones.vue";
  import Anular from "../../components/Admision/pages/Anular.vue";
  import Open from '@/components/Admision/pages/Open.vue';
  import Inventario from '@/components/Farmacia/pages/Inventory.vue'

  export default {
    name: "FarmaciaView",
    components: {
      Nav,
      Close,
      FooterVue,
      Home,
      Recetas,
      Directa,
      Venta,
      Devoluciones,
      Anular,
      Open,
      Inventario
    },
    data () {
      return {
        prefix: "Pharmacy",
        area: 'Farmacia',
        bead: {},
        verifBead: false,
        boxes: [],
        page: "home",
        client: null,
      }
    },
    props: {
      rolname: String,
    },
    created () {
      this.rol_name = this.rolname;
      this.mtdDataBead();
    },
    methods: {
      ...mapActions(['get', 'post']),
      mtdDataBead() {
        this.get({
            url: this.$store.getters.get__url + "/" + this.prefix + "/listBeads/" +
            this.$store.getters.get__campus,
            token: this.$store.getters.get__token,
        })
        .then((response) => {
            this.boxes = response.data.boxes;
            this.bead = response.data.bead;
            this.verifBead = response.data.bead != null ? true : false;
        })
        .catch((errors) => {
            console.log(errors);
        });
      },
      changePageVen: function (page, client) {
        this.client = page == "venta" ? client : page == "home" ? this.client : null;
        this.page = page;
      },
      changePage: function (page) {
        this.page = page;
      },
      returnHome: function () {
        this.page = "home";
      },
      mtdBackCampus: function () {
        this.$emit("mtdBackCampus");
      },
      mtdBackRol: function () {
        this.$emit("mtdBackRol");
      },
      mtdCommitPayment: function (res) {
        this.bead = res;
        this.page = "directa";
      },
      /** apertura */
    mtdOpen: function (data) {

      this.verifBead = true;
      this.page = "home";
      this.bead = data;
},
    }
    
  }
</script>
