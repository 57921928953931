<template>
  <div>
    <div class="w-100 h-100">
      <Nav :rolname="rolname" />
    </div>
    <div class="content h-100" style="background: rgb(255, 255, 255)">
       <HomeVue 
        v-if="page == 'home'"
        :pageView = "page"
        @changePage="changePage"
        @mtdBackCampus="mtdBackCampus"
      />
      <Anulaciones v-if="page == 'anulaciones'" @returnHome="returnHome" />
      <ConsultarDocumento v-if="page == 'consultas'" @returnHome="returnHome" />
      <PermisionsVue v-if="page == 'egresoingreso'" @returnHome="returnHome" />
      <RepCajaVue v-if="page == 'repcaja'" @returnHome="returnHome" />
      <OtrosPermiso v-if="page == 'otrosper'" @returnHome="returnHome" />
      <RepIngresoEgreso v-if="page == 'ingresoegreso'" @returnHome="returnHome" />
      <EgresoEdit v-if="page == 'egresos'" @returnHome="returnHome"/>
      <PermisoCierreCaja v-if="page == 'permisocierrecaja'" @returnHome="returnHome"/> 
      <Passage v-if="page == 'passage'" @returnHome="returnHome"/>
      <!--
      <RepCajaVue v-if="page == 'repcaja'" @returnHome="returnHome" />
      
     <EgresoIngreso v-if="page == 'egresoingreso'" @returnHome="returnHome" />
    
      <OtrosPermiso v-if="page == 'otrosper'" @returnHome="returnHome" />
      -->
    </div>
    <div class="footer">
      <FooterVue @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
  </div>
</template>

<script>
import HomeVue from "@/components/JefeAdmision/Home.vue";
import Nav from "@/components/JefeAdmision/Nav.vue";
import FooterVue from "@/components/JefeAdmision/Footer.vue";
import Anulaciones from "@/components/JefeAdmision/pages/AnulacionesView.vue";
import ConsultarDocumento from "@/components/JefeAdmision/pages/ConsultarDocumento.vue";
import PermisionsVue from "@/components/JefeAdmision/pages/PermisionsView.vue";
import RepCajaVue from "@/components/JefeAdmision/pages/ReporteCajaView.vue";
import OtrosPermiso from "@/components/JefeAdmision/pages/OtrosPerView.vue";
import RepIngresoEgreso from "@/components/JefeAdmision/pages/EgresoIngreso.vue";
import EgresoEdit from "@/components/JefeAdmision/pages/EgresoEdit.vue";
import PermisoCierreCaja from "@/components/JefeAdmision/pages/PermisoCierreCaja.vue";
import Passage from "@/components/JefeAdmision/pages/Passage.vue";
/*import EgresoIngreso from "@/components/JefeAdmision/pages/EgresoIngresocaja.vue";*/

export default {
  name: "v-contabilidad",
  components: {
    HomeVue,
    Nav,
    FooterVue,
    Anulaciones,
    ConsultarDocumento,
    PermisionsVue,
    RepCajaVue,
    OtrosPermiso,
    RepIngresoEgreso,
    EgresoEdit,
    PermisoCierreCaja,
    Passage
    /*RepCajaVue,
    EgresoIngreso,
    OtrosPermiso,
     */
  },

  data() {
    return {
      page: "home",
      rol_name: null,
    };
  },
  props: {
      rolname: String,
      dataRoles: Array,
    },
  methods: {
    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.page = "home";
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdChangePage: function (page) {
      this.page = page;
    },
  },
};
</script>

<style lang="scss" scoped></style>
