<template>
<div class="px-3">
    <div class="row mx-0 px-0">
        <div class=" col-xl-12  mb-4">

            <div  class="d-flex justify-content-between  mt-xl-0 mt-3 ">
                <div class="d-flex mb-1">
                    <h6 class="mt-2 me-2 "><i class="fas fa-cog"></i> SERVICIOS</h6>
                    <button v-if="ordendeservicio.length > 0" type="button" @click="mtdviewcarrito()" class="border-0 btn rounded-2 bg-main btn-ver text-white btn-sm">
                        <i class="fas fa-eye"></i> Ver Carrito
                    </button>
                </div>
                <div v-if=" servicio.length > 0 && cpData.length > 0">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-1" style="font-size: 11px;">
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                            </li>
                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                            </li>
                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>

                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                </a>
                            </li>
                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>
                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                            </li>
                        </ul>
                    </nav>

                </div>

            </div>

            <div class="col-md-12 px-0 table-responsive mt-2" id="content-timelinetable">
                <table id="tb-anular" class="table table-bordered" style="font-size: 11px;">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main text-xxs">
                        <tr class="text-white text-center">
                            <th scope="col" class="align-middle">
                                PROVEEDOR
                            </th>
                            <th scope="col" class="align-middle">
                                RUC
                            </th>
                            <th scope="col" class="align-middle">
                                SERVICIO ESPECIFICO
                            </th>
                            <th scope="col" colspan="1" class="align-middle">
                                S/PRECIO UNITARIO
                            </th>
                        </tr>
                    </thead>
                    <tbody class="border-main text-xs">
                        <tr class="tbody-table" v-for="(servicio, index) in cpData" :key="index">
                            <td class="align-middle div-pointer clickCarrito" @click="mtdAddItemCarrito(servicio)">
                                {{ servicio.proveedor.name }}
                            </td>
                            <td class="align-middle">
                                {{ servicio.proveedor.ruc }}
                            </td>
                            <td class="align-middle">
                                {{ servicio.servicio_name }}
                            </td>
                            <td class="align-middle text-end">
                                {{ servicio.proveedor.price_purchase }}
                            </td>
                        </tr>
                        <tr v-if="!cpData.length > 0" class="text-center">
                            <th scope="col" colspan="4">NO SE ENCONTRARON RESULTADOS</th>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-pages-ordendeservicio-servicio",
    data() {
        return {
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataProveedor: []
        }
    },
    props: {
        servicio: {
            type: Array,
            default: () => [],
        },
        ordendeservicio: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
        servicio: {
            handler: 'mtdGetData',
            deep: true,
            immediate: true
        }

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        cpRecommendedProducts() {
            let productosOrdenados = [...this.medicaments].sort((a, b) => a.presentation.localeCompare(b.presentation));
            let productosAgrupados = {};
            productosOrdenados.forEach(producto => {
                let clave = producto.presentation + '_' + producto.concentration + '_' + producto.molecule;
                if (!productosAgrupados[clave]) {
                    productosAgrupados[clave] = [];
                }
                productosAgrupados[clave].push(producto);
            });
            let recommendedProducts = [];
            for (let clave in productosAgrupados) {
                let productosDeGrupo = productosAgrupados[clave];
                let productosRecomendados = productosDeGrupo.slice(0, 2);
                recommendedProducts.push(...productosRecomendados);
            }
            return recommendedProducts;
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.calculateData(6)
        },
        calculateData(items) {
            let filteredData = this.servicio;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdAddItemCarrito: function (payload) {
            this.$emit("mtdAddItemCarrito", payload);
        },
        mtdviewcarrito: function () {
            this.$emit("mtdviewcarrito");
        },

    }
}
</script>

<style scoped>
.clickCarrito {
    cursor: pointer;
}

.clickCarrito:hover {
    background-color: #D5DBDB;

}
</style>
