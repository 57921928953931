<template>
  <div id="app">
    <router-view />
    <Loading v-show="loading"></Loading>
  </div>
</template>

<script>
import Loading from './components/loading/Modal.vue'
import { bus } from './main'
import { mapActions } from 'vuex'

export default {
  name: 'app',
  components: {
    Loading
  },
  data () {
    return {
      loading: false,
      check: false,
      rol: null,
      rolname: null,
      dataCampus: [],
      dataRoles: [],
    }
  },
  created () {
    const user = this.$store.getters.get__user
    if(user.roles.length == 1){
      this.rol = user.roles[0].role_id;
      this.mtdgetcheck();
    }

    bus.$on('loading', (data) => {
      this.loading = data
    })
    if (this.$store.getters.get__token) {
      this.$router.push('/home')
    } else {
      this.$router.push('/login')
    }
  },
  methods: {
    ...mapActions(['get', 'post']),

    mtdgetcheck: function () {
      this.get({
          url: this.$store.getters.get__url + "/Authentication/check",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.check = response.data.check;
          if(this.check){
            if(this.rol == 1){
              this.$router.push('/gerencia')
            }else
            if(this.rol == 4){
              this.$router.push('/maintenance')
            }else
            if(this.rol == 5){
              this.$router.push('/logistica')
            }else
            if(this.rol == 6){
              this.$router.push('/medico')
            }else
            if(this.rol == 7){
              this.$router.push('/enfermera')
            }else
            if(this.rol == 8){
              this.$router.push('/contabilidad')
            }else
            if(this.rol == 10){
              this.$router.push('/farmacia')
            }else
            if(this.rol == 11){
              this.$router.push('/rh')
            }else
            if(this.rol == 12){
              this.$router.push('/tesoreria')
            }else
            if(this.rol == 13){
              this.$router.push('/facturacion')
            }else
            if(this.rol == 14){
              this.$router.push('/director')
            }else{
              this.$router.push('/home')
            }
          }else{
            this.$router.push('/login')
          }
        })
    },
  }
}
</script>

<style lang="scss">
  @import "@/scss/main.scss";
</style>