<template>
<div class="h-100 py-3">
    <div class="   container  px-5   ">
        <div class="w-100 text-xs ">
            <div class="row px-5 responsive-div-monitoreo mx-0 ">
                <div id="content-timelinetable" class=" col-xl-6 col-lg-12 ">
                    <span>TOTAL INGRESOS </span>
                    <table class="mt-2 table mb-0 table-borderless border border-2 ">
                        <caption></caption>
                        <thead>
                            <tr class="text-center text-primary-hover ">
                                <th scope="col">NF</th>
                                <th scope="col">TTO</th>
                                <th scope="col">DER</th>
                                <th scope="col">VO</th>
                                <th scope="col">SNG</th>
                                <th scope="col">H2O</th>
                                <th scope="col">QUMO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row " class="">
                                    <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                                </th>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="content-timelinetable" class="col-xl-6 col-lg-12">
                    <span>TOTAL EGRESOS </span>
                    <table class=" mt-2 table  mb-0 table-borderless border border-2  ">
                        <caption></caption>
                        <thead>
                            <tr class="text-center text-primary-hover ">
                                <th scope="col">ORINA</th>
                                <th scope="col">DEPO</th>
                                <th scope="col">SNG</th>
                                <th scope="col">DREN</th>
                                <th scope="col">SERO</th>
                                <th scope="col">PI</th>
                                <th scope="col">VOMIT</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row " class="">
                                    <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                                </th>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>
                                <td>
                                    <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                </td>

                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="responsive-div d-flex justify-content-evenly w-100  ">
                    <div class="  row mx-1 ">
                        <label class="col-xl-5 col-lg-12  col-form-label px-0">TOTAL INGRESOS </label>
                        <div class="col-xl-7  col-lg-12  px-1 ">
                            <input type=" text" class=" px-0 form-control form-control-sm w-100">
                        </div>
                    </div>
                    <div class="   row  mx-1">
                        <label class="col-xl-5 col-lg-12  col-form-label px-0">TOTAL EGRESOS </label>
                        <div class="col-xl-7 col-lg-12  px-1 ">
                            <input type=" text" class=" px-0 form-control form-control-sm w-100">
                        </div>
                    </div>
                    <div class="  row  mx-1">
                        <label class="col-xl-4 col-lg-12  col-form-label px-0">TOTAL BH </label>
                        <div class="col-xl-8  col-lg-12 px-1 ">
                            <input type=" text" class=" px-0 form-control form-control-sm w-100">
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mt-3 px-2   ">
                <div class="col-lg-12 col-xl-4 col-md-12 px-1 pt-3">
                    <div id="content-timelinetable" class="mb-2">

                        <table class=" mt-2 table  mb-0 table-borderless  ">
                            <caption></caption>
                            <thead>
                                <tr class="text-center text-primary-hover ">
                                    <th scope="col">Fcha</th>
                                    <th scope="col">Hora</th>
                                    <th scope="col">F.Endovenoso</th>
                                    <th scope="col">CVC</th>
                                    <th scope="col">Perfil</th>
                                    <th scope="col">Port</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row " class="">
                                        <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                                    </th>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <div class="col-lg-12 col-xl-4 col-md-12 px-1 ">
                    <div id="content-timelinetable" class="mb-2">
                        <div class=" border-bottom border-2"> INGRESOS </div>
                        <table class=" mt-1 table  mb-0 table-borderless  ">
                            <caption></caption>
                            <thead>
                                <tr class="text-center text-primary-hover ">
                                    <th scope="col">Infus</th>
                                    <th scope="col">Tto</th>
                                    <th scope="col">San</th>
                                    <th scope="col">V.O</th>
                                    <th scope="col">SNG</th>
                                    <th scope="col">Oxid</th>
    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row " class="">
                                        <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                                    </th>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                </tr>
    
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                <div class="col-lg-12 col-xl-4 col-md-12 px-1">
                    <div id="content-timelinetable" class="mb-2">
                        <div class=" border-bottom border-2 "> EGRESOS </div>
                        <table class=" mt-1 table  mb-0 table-borderless  ">
                            <caption></caption>
                            <thead>
                                <tr class="text-center text-primary-hover ">
                                    <th scope="col">Orina</th>
                                    <th scope="col">Depo</th>
                                    <th scope="col">SNG</th>
                                    <th scope="col">Dren</th>
                                    <th scope="col">Sero</th>
                                    <th scope="col">P.hs</th>
                                    <th scope="col">Vomit</th>
    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row " class="">
                                        <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                                    </th>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                    <td>
                                        <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                                    </td>
                                </tr>
    
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="responsive-div d-flex justify-content-around col-md-12 w-100 ">
                    <div class=" mx-1 row ">
                        <label class="col-xl-5  col-lg-12 col-form-label px-0">Tiempo Operatorio </label>
                        <div class="col-xl-7 col-lg-12  px-1 ">
                            <input type=" text" class=" px-0 form-control form-control-sm w-100">
                        </div>
                    </div>
                    <div class=" mx-1 row ">
                        <label class="col-xl-2 col-lg-12 col-form-label px-0"> Q.T </label>
                        <div class="col-xl-7 col-lg-12 px-1 ">
                            <input type=" text" class=" px-0 form-control form-control-sm w-100">
                        </div>
                    </div>
                    <div class=" mx-1 row ">
                        <label class="col-xl-5 col-lg-12 col-form-label px-0">Tipo de Drenaje </label>
                        <div class="col-xl-7 col-lg-12 px-1 ">
                            <input type=" text" class=" px-0 form-control form-control-sm w-100">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="text-white  w-100  ">
            <div id="content-timelinetable" class="mb-2">
                <table class=" table  table-borderless px-0 text-xs mb-0">
                    <caption class="py-1"></caption>
                    <thead>
                        <tr class="text-center text-primary-hover ">
                            <th scope="col">Fecha</th>
                            <th scope="col">Hora</th>
                            <th scope="col">Temp</th>
                            <th scope="col">PAS</th>
                            <th scope="col">PAD</th>
                            <th scope="col">PVC</th>
                            <th scope="col">PCWP</th>
                            <th scope="col">FC</th>
                            <th scope="col">Sat 02</th>
                            <th scope="col">Fi 02</th>
                            <th scope="col">Diuresis</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row " class="">
                                <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                            </th>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>

        </div>

    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
export default {
    name: 'c-asistencial-uciadulto-historia-monitoreohemodinamico',
    data() {
        return {
            //page: null,
        }
    },
    created() {

    },
    methods: {
        ...mapActions(['get', 'post']),
    }
}
</script>
