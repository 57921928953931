<template>
    <div class="container pb-5">
        <!--
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12 mb-3 text-center">
                <h4 class=" card-header modal-title text-dark" id="exampleModalLabel">
                    <i class="far fa-money-bill-alt"></i> Reporte de Deposito / Retiro
                </h4>
            </div>
            <hr>
            <div class="col-md-12">
                <div class="row d-flex">
                    <div class="col-md-6 col-lg-3 col-xl-2 col-sm-6 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3 col-lg-4">Sede</span>
                            <select id="campu_id" class="form-control form-control" v-model="filter.campu_id" @change="mtdSelectCampusToBox">
                                <option selected value="0" disabled>Seleccione ...</option>
                                <option v-for="(item, index) in dataCampus" :key="index" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-6 col-lg-3 col-xl-2 col-sm-6 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3 col-lg-4">Caja</span>
                            <select id="bead_id" class="form-control form-control" v-model="filter.bead_id">
                                <option selected value="0" disabled>Seleccione ...</option>
                                <option v-for="(item, index) in dataBeadsList" :key="index" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-5 col-lg-2 col-xl-3 col-sm-6 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" />
                        </div>
                    </div>
    
                    <div class="col-md-5 col-lg-2 col-xl-3 col-sm-6 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" />
                        </div>
                    </div>
    
                    <div class="col-md-2 col-xl-1 col-lg-2 col-sm-6 col-xs-12 mb-2">
                        <button type="button" name="accion" class="btn btn-sm w-100 btn-register text-white" @click="mtdDataReport">
                            <i class="fas fa-search"></i> Buscar
                        </button>
                    </div>
                    <div class="col-md-2 col-xl-1 col-lg-2 col-sm-6 col-xs-12 mb-2">
                        <button type="button" name="accion2" class="btn btn-sm w-100 btn-register text-white" @click="mtdExportExcel">
                            <i class="fas fa-file-excel"></i> Exportar
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-12 px-5 py-2 mt-1 table-responsive">
                <table class="table table-bordered text-center">
                    <thead class="bg-main">
                        <tr class="text-white text-center">
                            <th :class="cpStyle">DIA</th>
                            <th :class="cpStyle">DEPOSITO</th>
                            <th :class="cpStyle">RETIRO</th>
                        </tr>
                    </thead>
                    <tbody class="" style="font-size: 13px">
                        <tr v-for="(item, index) in data" :key="index">
                            <td :class="cpStyleBody" style="--bs-bg-opacity: .10;">{{ item.date }}</td>
                            <td :class="cpStyleBody" style="--bs-bg-opacity: .10;">S/. {{ parseFloat(item.deposit).toFixed(2) }}</td>
                            <td :class="cpStyleBody" style="--bs-bg-opacity: .10;">S/. {{ parseFloat(item.retirement).toFixed(2) }}</td>
                        </tr>
                        <tr>
                            <td :class="cpStyleBody" style="--bs-bg-opacity: .10;" colspan="3" v-if="data.length ==0">
                                No se encontraron resultados
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        -->
        <div id="div-modal-cxc" class="d-flex justify-content-center mt-4">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>Reporte de Deposito / Retiro</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3 col-lg-4">Sede</span>
                                        <select id="campu_id" class="form-control form-control" v-model="filter.campu_id" @change="mtdSelectCampusToBox">
                                            <option selected value="0" disabled>Seleccione ...</option>
                                            <option v-for="(item, index) in dataCampus" :key="index" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3 col-lg-4">Caja</span>
                                        <select id="bead_id" class="form-control form-control" v-model="filter.bead_id">
                                            <option selected value="0" disabled>Seleccione ...</option>
                                            <option v-for="(item, index) in dataBeadsList" :key="index" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" />
                                    </div>
                                </div>
                
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" />
                                    </div>
                                </div>
                
                                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <button type="button" name="accion" class="btn btn-sm w-100 btn-register text-white" @click="mtdDataReport">
                                        <i class="fas fa-search"></i> Buscar
                                    </button>
                                </div>
                                <div class="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <button type="button" name="accion2" class="btn btn-sm w-100 btn-register text-white" @click="mtdExportExcel">
                                        <i class="fas fa-file-excel"></i> Exportar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table border-main">
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center">
                                        <th>DIA</th>
                                        <th>DEPOSITO</th>
                                        <th>RETIRO</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main" style="font-size: 13px">
                                    <tr class="table-body text-center" v-for="(item, index) in data" :key="index">
                                        <td :class="cpStyleBody" style="--bs-bg-opacity: .10;">{{ item.date }}</td>
                                        <td :class="cpStyleBody" style="--bs-bg-opacity: .10;">S/. {{ parseFloat(item.deposit).toFixed(2) }}</td>
                                        <td :class="cpStyleBody" style="--bs-bg-opacity: .10;">S/. {{ parseFloat(item.retirement).toFixed(2) }}</td>
                                    </tr>
                                    <tr class="table-body text-center" >
                                        <td :class="cpStyleBody" style="--bs-bg-opacity: .10;" colspan="3" v-if="data.length ==0">
                                            No se encontraron resultados
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import exportFromJson from "export-from-json";
    export default {
        data() {
            return {
                filter: {
                    campu_id: 0,
                    bead_id: 0,
                    begindate: null,
                    enddate: null
                },
                dataBeadsList: [],
                data: [],
                excelData: [],
            }
        },
        props: {
            dataCampus: Array,
        },
        watch: {},
        mounted() {
            this.getFirstDayOfMonth();
        },
        computed: {
            cpStyle() {
                return 'align-middle border rounded-3 border-3 bg-main border-white ';
            },
            cpStyleBody() {
                return 'border border-3 rounded-3 border-white align-middle bg-secondary';
            }
        },
        methods: {
            ...mapActions(["get", "post"]),
            mtdSelectCampusToBox: function () {
                this.dataBeadsList = [];
                this.dataCampus.forEach((element) => {
                    if (element.id == this.filter.campu_id) {
                        this.dataBeadsList = element.boxes;
                    }
                });
            },
            getFirstDayOfMonth: function () {
                const currentDate = new Date();
                const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
                this.filter.enddate = today.toISOString().split('T')[0];
                this.filter.campu_id = 1;
                this.mtdSelectCampusToBox();
                this.filter.bead_id = this.dataBeadsList[0].id;
                this.mtdDataReport();
            },
            mtdDataReport: function () {
                if (this.filter.campu_id == 0) {
                    Swal.fire({
                        text: "Escoja una sede!!",
                        icon: "warning",
                        confirmButtonColor: "#900052",
                    });
                    return;
                }
                if (this.filter.bead_id == 0) {
                    Swal.fire({
                        text: "Escoja una caja!!",
                        icon: "warning",
                        confirmButtonColor: "#900052",
                    });
                    return;
                }
                this.post({
                        url: this.$store.getters.get__url + "/Jefatura/listDepositRetirement",
                        token: this.$store.getters.get__token,
                        params: this.filter,
                    })
                    .then((response) => {
                        this.data = response.data;
                        this.datosExcel();
                    })
                    .catch((errors) => {});
            },
            datosExcel: function () {
                this.data.forEach(element => {
                    let deposit = parseFloat(element.deposit).toFixed(2);
                    this.excelData.push({
                        "FECHA": element.date,
                        "DEPOSITO": deposit ,
                        "RETIRO": parseFloat(element.retirement).toFixed(2)
                    });
                });
            },
            mtdExportExcel: function () {
                const datos = this.excelData;
                const fileName = "DEPOSITOS_RETIROS";
                const typeExport = exportFromJson.types.xls;
                exportFromJson({
                    data: datos,
                    fileName: fileName,
                    exportType: typeExport,
                });
            }
        }
    }
    </script>
    