<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
        <div class="row mx-0 px-0">
            <div>
                
                <div class="tab-content" id="">
                        <h5> <i class="fas fa-cog"></i> Listado de Servicios</h5>        
                        <div class="px-3 mt-4">
                            <div class=" table-responsive" id="div-tabla-anular">
                                <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                    <caption class="py-0 my-0"></caption>
                                    <thead class="bg-main text-xs">
                                        <tr class="text-white text-center" style="vertical-align: middle">
                                            <th scope="col">N°</th>
                                            <th scope="col" colspan="1">SERVICIO ESPECIFICO</th>
                                            <th scope="col" colspan="1">CANTIDAD</th>
                                            <th scope="col" colspan="1">PRECIO SIN S/IGV</th>
                                            <th scope="col" colspan="1">PREC. UNIT. SIN S/IGV</th>
                                            <th scope="col" colspan="1">SUBTOTAL</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody class="border-main text-xs align-middle">
                                        <tr class="tbody-table1 text-center" v-for="(items, ind) in dataservicedetails" :key="ind">
                                            <td>
                                                {{ ind + 1 }}
                                            </td>
                                            <td>
                                                {{ items.service.name }}
                                            </td>
                                            <td >
                                                {{ items.quantity }}
                                            </td>
                                            
                                            <td>
                                                {{ items.price }}
                                            </td>
                                            <td>
                                                {{ items.price }}
                                            </td>
                                            <td>
                                                {{ calculateSubtotal( ind)}}
                                            </td>
                                        </tr>
    
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex mt-3 justify-content-end">
                                <div class="px-2 py-1 bg-white">
                                    VALOR VENTA S/: {{ mtdValor() }}
                                </div>
                                <div class="px-2 py-1 bg-white">
                                    IGV S/: {{ mtdGetIgv() }}
                                </div>
                                <div class="px-2 py-1 mx-1 bg-white">
                                    TOTAL C/ IGV: {{ mtdGetTotal() }}
                                </div>
                                
                            </div>
    
                        </div>
                    </div>
              
            </div>
        </div>
        <template #footer>
    
        </template>
    </CModal>
    </template>
    
    <script>
    import CModal from "@/components/shared/modals/CModal.vue";
    import {
        mapActions
    } from "vuex";
    
    export default {
        name: "c-logistica-modals-cmodalviewcarritoordenservicio",
        components: {
            CModal,
        },
        data() {
            return {
                posOc: 0,
                tablaDetail: [],
                posEditQuantity1: null,
                posEditQuantity: null,
                posEditDiscount: null,
                selectOcExecuted: false
            };
        },
      
        props: {
            title: String,
            boo_modal: Boolean,
            dataservicedetails :{
                type:Array,
                default: []
            }
        },
    
        methods: {
            ...mapActions(["get", "post"]),
    
            mtdClose() {
                this.$emit("closeModalService");
            },
           
            calculateSubtotal(ind) {
                const precioUnitario =
                    parseFloat(this.dataservicedetails[ind].price) || 0;
                const cantidad =
                    parseInt(this.dataservicedetails[ind].quantity) || 0;
                const subtotal = precioUnitario * cantidad;
                // this.tablaDetail[ind].detalle[index].price_purchase = subtotal.toFixed(2);
                return subtotal.toFixed(2);
            },
            
           
            mtdGetTotal: function () {
                if (this.dataservicedetails.length > 0) {
                    let valor = 0;
                    this.dataservicedetails.forEach((element) => {
                        valor += parseFloat(element.price) * element.quantity;
                    });
                    let igv = valor * 0.18;
                    let total = parseFloat(valor) + parseFloat(igv);
                    // this.dataservicedetails[this.posOc].cabecera.total =parseFloat(total).toFixed(2) 
                    return parseFloat(total).toFixed(2);
                } else {
                    return parseFloat(0.00).toFixed(2);
                }
            },
          
            mtdValor: function () {
                if (this.dataservicedetails.length > 0) {
                    let valor = 0;
                    this.dataservicedetails.forEach((element) => {
    
                        valor += parseFloat(element.price) * element.quantity;
    
                    });
                    // this.dataservicedetails[this.posOc].cabecera.total_sin_igv =parseFloat(valor).toFixed(2) 
                    return parseFloat(valor).toFixed(2);
                } else {
                    return parseFloat(0.00).toFixed(2);
                }
            },
    
            
            mtdGetIgv: function () {
                if (this.dataservicedetails.length > 0) {
                    let valor = 0;
                    this.dataservicedetails.forEach((element) => {
                        valor += parseFloat(element.price) * element.quantity;
                    });
                    let igv = parseFloat(valor * 0.18);
                    // this.dataservicedetails[this.posOc].cabecera.igv =parseFloat(igv).toFixed(2) 
                    return parseFloat(igv).toFixed(2);
                } else {
                    return parseFloat(0.00).toFixed(2);
                }
            },
          
    
        },
    
    };
    </script>
    <style scoped>
    #btn-remove {
        color: #ff0000;
        font-size: 25px;
        margin: 0px 2px;
    }
    
    #btn-remove:hover {
        color: #a30404;
    }
    </style>