<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
       
        <div class="row">
            <div
            v-for="(item, index) in dataRoles"
            :key="index"
            class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
            >
            <div class="checkbox-grid">
                <label class="checkbox-label">
                <input type="checkbox" :value="item.id" class="form-check-input" v-model="data_detail.checkbox" :disabled="item.id == 7 || item.id == 6"/>
                <span class="ms-2">{{ item.name }}</span>
                </label>
            </div>
            </div>
        </div>
        
        <template #footer>
            <button class="btn btn-success btn-sm" @click="mtdAddRolCommit" :disabled="cpButton">
                Editar
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data_detail: {
                checkbox: [],
                userId: null,
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                //if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        checkbox : newVal
                    };
                //}
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        userId: Number,
        payload: {
            type: Array,
            default: null
        },
        dataRoles: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
            return this.data_detail.checkbox.length === 0;
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.userId = null;
            this.data_detail.checkbox = [];
            this.$emit("closeModalRol");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail)
            this.mtdClose();
        },

        mtdAddRolCommit: function () {
            this.data_detail.userId = this.userId;
            this.post({
                url: this.$store.getters.get__url + "/Users/addrol",
                token: this.$store.getters.get__token,
                params: this.data_detail,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit("mtdSearchData");
                    this.mtdClose();
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
    },
    

};
</script>
<style scoped>
    .checkbox-grid {
        padding: 10px;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

    .checkbox-label {
        display: flex;
        align-items: center;
    }

    .checkbox-label .form-check-input {
        margin-top: 0;
        margin-bottom: 0;
    }

    .ms-2 {
        margin-left: 0.5rem !important;
    }
</style>