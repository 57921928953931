<template>
  <div>
    <div class="container">
      <div class="row">
        <div id="btn-home" class="col-md-12 mt-5 mb-4">
          <button type="button" class="btn btn-outline-main" @click="retroceder"><i class="fas fa-home-lg-alt"></i> Inicio</button>
        </div>
      </div>
      <div id="div-modal-imagen" class="d-flex justify-content-center">
        <div id="div-imagen" class="card w-100">
          <div align="center" class="card-header text-white h4" style="background: #900052">
            <strong>TÓPICOS / PROCEDIMIENTOS -{{ elementProcess.name }}</strong>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-md-4">
                <div class="input-group mb-2">
                  <label class=" btn w-100 rounded-pill" for="endoscopia" style="background: #900052; color: #fff;"><strong>ENDOSCOPÍAS</strong></label>
                  <div class="visually-hidden">
                    <input type="file" @click="getFileImg" class="form-control" id="endoscopia" lang="es" name="endoscopia">
                  </div>
                </div>
                <div id="contentendoscopia" class="">

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-group mb-2">
                  <label class=" btn w-100 rounded-pill" for="legrado" style="background: #900052; color: #fff;"><strong>LEGRADO</strong></label>
                  <div class="visually-hidden">
                    <input type="file" @click="getFileImg" class="form-control" id="legrado" lang="es" name="legrado">
                  </div>
                </div>
                <div id="contentlegrado" class="">

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-group mb-2">
                  <label class=" btn w-100 rounded-pill" for="otro" style="background: #900052; color: #fff;"><strong>OTROS</strong></label>
                  <div class="visually-hidden">
                    <input type="file" @click="getFileImg" class="form-control" id="otro" lang="es" name="otro">
                  </div>
                </div>
                <div id="contentotro" class="">

                </div>
              </div>

              <br>
              <div class="col-md-10">
                <div class="card w-100 mt-2 border-main" style="width: 18rem;">
                  <div class="card-body">
                    <div class="col-md-12 p-0" id="div-tabla-imagen">
                      <table id="tb-cola" class="table table-sm table-bordered p-0">
                        <thead>
                          <tr align="left" class="text-white border-white" style="font-size: 12px; background: #900052">
                            <th scope="col">FECHA</th>
                            <th scope="col" colspan="13">HORARIOS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="font-size: 12px;" v-for="(item, index) in process.horario" :key="index">
                            <td class="py-2" style=" font-weight:bold;"><span class="rounded-pill p-1" style="background: #fff;">{{ item.dia }}</span></td>
                            <td class="py-2" v-for="(item2, index2) in process.rango" :key="index2">
                              <span class="rounded-pill py-1 px-2" style="background: #fff;" v-if="item.status == 0">{{ item2.hora }}</span>
                              <span class="rounded-pill py-1 px-2" style="background: #39b54a; color: #fff;" v-if="item.status == 1">{{ item2.hora }}</span>
                              <span class="rounded-pill py-1 px-2" style="background: #fcee21; color: #fff;" v-if="item.status == 2">{{ item2.hora }}</span>
                              <span class="rounded-pill py-1 px-2" style="background: #ff0000; color: #fff;" v-if="item.status == 3">{{ item2.hora }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              
              <div align="center" class="col-md-2 pt-3" style="color: #900052">
                <i class="fa fa-solid fa-caret-left"></i><span><strong> MES </strong></span> <i class="fa fa-solid fa-caret-right"></i>
              </div>

            </div>
          </div>
          <div
            align="right"
            class="card-footer text-white"
            style="background: #900052; font-size: 20px"
          >
            <span class="btn border border-white text-white"><strong>OK</strong></span>
          </div>
        </div>
      </div><br><br><br>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'

export default {
  name: 'c-admision-imagenes',
  data () {
    return {
      process: {
        box: 0,
        initial: 0,
        horario: [
          { dia: '01/01/2023', status: '0', key: '1', value: 1 },
          { dia: '02/01/2023', status: '1', key: '2', value: 2 },
          { dia: '03/01/2023', status: '2', key: '3', value: 3 },
          { dia: '04/01/2023', status: '3', key: '4', value: 4 },
          { dia: '05/01/2023', status: '1', key: '5', value: 5 },
          { dia: '06/01/2023', status: '1', key: '6', value: 6 },
          { dia: '07/01/2023', status: '0', key: '7', value: 7 },
          { dia: '08/01/2023', status: '0', key: '8', value: 8 },
          { dia: '09/01/2023', status: '0', key: '9', value: 9 },
          { dia: '10/01/2023', status: '0', key: '10', value: 10 },
          { dia: '11/01/2023', status: '0', key: '11', value: 11 },
          { dia: '12/01/2023', status: '0', key: '12', value: 12 },
          { dia: '13/01/2023', status: '0', key: '13', value: 13 },
          { dia: '14/01/2023', status: '0', key: '14', value: 14 },
          { dia: '15/01/2023', status: '0', key: '15', value: 15 },
          { dia: '16/01/2023', status: '0', key: '16', value: 16 },
          { dia: '17/01/2023', status: '0', key: '17', value: 17 },
          { dia: '18/01/2023', status: '0', key: '18', value: 18 },
          { dia: '19/01/2023', status: '0', key: '19', value: 19 },
          { dia: '20/01/2023', status: '0', key: '20', value: 20 },
          { dia: '21/01/2023', status: '0', key: '21', value: 21 },
          { dia: '22/01/2023', status: '0', key: '22', value: 22 },
          { dia: '23/01/2023', status: '0', key: '23', value: 23 },
          { dia: '24/01/2023', status: '0', key: '24', value: 24 },
          { dia: '25/01/2023', status: '0', key: '25', value: 25 },
          { dia: '26/01/2023', status: '0', key: '26', value: 26 },
          { dia: '27/01/2023', status: '0', key: '27', value: 27 },
          { dia: '28/01/2023', status: '0', key: '28', value: 28 },
          { dia: '29/01/2023', status: '0', key: '29', value: 29 },
          { dia: '30/01/2023', status: '0', key: '30', value: 30 }
        ],
        rango: [
          { hora: '08:00', key: '1', value: 1 },
          { hora: '09:00', key: '2', value: 2 },
          { hora: '10:00', key: '3', value: 3 },
          { hora: '11:00', key: '4', value: 4 },
          { hora: '12:00', key: '5', value: 5 },
          { hora: '13:00', key: '6', value: 6 },
          { hora: '14:00', key: '7', value: 7 },
          { hora: '15:00', key: '8', value: 8 },
          { hora: '16:00', key: '9', value: 9 },
          { hora: '17:00', key: '10', value: 10 },
          { hora: '18:00', key: '11', value: 11 },
          { hora: '19:00', key: '12', value: 12 },
          { hora: '20:00', key: '13', value: 13 }
          /* { hora: '08:00:00', key: '1', value: 1 },
          { hora: '09:00:00', key: '2', value: 2 },
          { hora: '10:00:00', key: '3', value: 3 },
          { hora: '11:00:00', key: '4', value: 4 },
          { hora: '12:00:00', key: '5', value: 5 },
          { hora: '13:00:00', key: '6', value: 6 },
          { hora: '14:00:00', key: '7', value: 7 },
          { hora: '15:00:00', key: '8', value: 8 },
          { hora: '16:00:00', key: '9', value: 9 },
          { hora: '17:00:00', key: '10', value: 10 },
          { hora: '18:00:00', key: '11', value: 11 },
          { hora: '19:00:00', key: '12', value: 12 },
          { hora: '20:00:00', key: '13', value: 13 } */
        ],
        observation: '',
        status: 0
      }
    }
  },
  props: {
    boxes: []
  },
  methods: {
    ...mapActions(['get', 'post']),
    mtdSelectBox: function (item) {
      this.process = item.process.length > 0 ? item.process : this.process
      this.process.box = item.id
    },

    retroceder(){
      this.$emit('mtdChangePage','home')
    },
    /* mtdList: function(){
      this.view='list';
    },
    mtdBackTable: function(){
      this.view='table';
    }, */
    getFileImg(e) {
     
		  let file = e.target.files[0];
		  let type = e.target.id;
		  if (!/\.(png)$/i.test(file.name)) {
		    Swal.fire({
          text:'Seleccione un archivo tipo imagen',
          icon:'info', 
          confirmButtonColor: '#900052',
        });
        $("#"+type).val('');
		  } else {
    		this.receipt.file = file;
		  }
	  }/* ,
    mtdColaBead: function (status) {
      this.process.status = status
      this.post({
        url: this.$store.getters.get__url + '/bead/listcola',
        token: this.$store.getters.get__token,
        params: {
          data: this.process
        }
      })
        .then((response) => {
          this.$emit('mtdCola', response)
        })
        .catch((errors) => {})
    } */
  }
}
</script>

<style scoped>
  #div-tabla-imagen {
    border: 1px solid #aaa;
    height: 300px;
    background: #f1f2f3;
    overflow:auto;
    box-sizing: border-box;
    padding:0 1rem;
  }
  #div-tabla-imagen::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  #div-tabla-imagen::-webkit-scrollbar:vertical {
      width:10px;
  }
  #div-tabla-imagen::-webkit-scrollbar-button:increment,#div-tabla-imagen::-webkit-scrollbar-button {
      display: none;
  } 
  #div-tabla-imagen::-webkit-scrollbar:horizontal {
      height: 10px;
  }
  #div-tabla-imagen::-webkit-scrollbar-thumb {
      background-color: #797979;
      border-radius: 20px;
      border: 2px solid #f1f2f3;
  }
  #div-tabla-imagen::-webkit-scrollbar-track {
      border-radius: 10px;  
  }
  .circulo{
    vertical-align: middle;
    border-style: none;
  }
  #div-modal-imagen{
    margin-top: 10px;
  }
  @media(max-width: 800px){
    /* #div-modal-imagen{
      margin-top: 20px;
    } */
    #btn-open-caja{
      justify-content: center !important;
    }
    #btn-registro-cola{
      text-align: center;
    }
    #div-tabla-imagen{
      overflow-x: auto
    }
  }
  @media(min-width: 800px){
    /* #div-modal-imagen{
      margin-top: 65px;
    } */
    #btn-registro-cola{
      text-align: left;
    }
  }
</style>