<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="row" v-if="view == 'req'">
        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered">
                <caption></caption>
                <thead class="bg-main" style="font-size: 11px;">
                    <tr class="text-white text-center">
                        <th scope="col" class="align-middle">
                            PROVEEDOR
                        </th>
                        <th scope="col" class="align-middle">
                            MOLECULA
                        </th>
                        <th scope="col" class="align-middle">
                            COMERCIAL
                        </th>
                        <th scope="col" class="align-middle">
                            MARCA
                        </th>
                        <th scope="col" class="align-middle">
                            PRESENTACIÓN
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            CONCENTRACION
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            FORMA
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            S/PRECIO DE COMPRA
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            S/KAIROS UNITARIO
                        </th>
                        <th scope="col" colspan="1" class="align-middle">
                            UTILIDAD UNITARIO
                        </th>
                    </tr>
                </thead>

                <tbody class="border-main" style="font-size: 11px;">
                    <tr class="tbody-table" v-for="(producto, index) in medicaments" :key="index">
                        <td class="clickCarrito" @click="mtdAddItemCarrito(producto)">
                            {{ producto.proveedor.name }}
                        </td>
                        <td>
                            {{ producto.molecule }}
                        </td>
                        <td>
                            {{ producto.commercial }}
                        </td>
                        <td>
                            {{ producto.brand }}
                        </td>
                        <td>
                            {{ producto.presentation }}
                        </td>
                        <td>
                            {{ producto.concentration }}
                        </td>
                        <td>
                            {{ producto.format }}
                        </td>
                        <td>
                            <div>{{ producto.precio_purchase }}</div>
                        </td>
                        <td>
                            <div>{{ producto.precio_farmacia_kairos }}</div>
                        </td>
                        <td>
                            {{ producto.utilidad }}
                        </td>
                    </tr>
                    <tr v-if="!medicaments.length > 0" class="text-center">
                        <th scope="col" colspan="13">NO SE ENCONTRARON RESULTADOS</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- carrito de ordenes de compra  -->
    <div class="row" v-else>
        <div class="row mx-0 px-0">
            <div>
                <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
                    <li v-for="(item, index) in carrito" :key="index" class="nav-item p-1" role="presentation">
                        <button @click="selectOc(index)" :class="['item-button', 'nav-link', { active: index === posOc }]" :id="'tab-' + index" type="button">
                            {{ item.cabecera.name }}
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent" v-if="tablaDetail.length > 0">
                    <div v-for="(item, index) in tablaDetail" :key="index">
                        <div class="d-flex mod-h py-3 px-4 mx-1">
                            <div class="modal-title d-flex w-100 mt-4 responsive-proveedor" id="exampleModalLabel">
                                <div class="d-flex">
                                    <div class="me-3 text-center" style="
                      background-color: #900052;
                      width: 45px;
                      border-radius: 50%;
                    ">
                                        <i class="fas fa-shopping-cart text-white" style="padding-top: 16px"></i>
                                    </div>
                                </div>

                                <div class="bg-white d-flex w-25 px-2 py-2 me-2 responsive-contador" style="width: 200px; border-radius: 10px">
                                    <div>OBSERVACIONES:</div>
                                    <div class="ms-2 w-100">
                                        <input v-model="item.cabecera.observation" class="form-control w-100 form-control-sm" id="exampleFormControlTextarea1" />
                                    </div>
                                </div>
                                <div class="bg-white d-flex px-2 py-2 me-2 responsive-contador" style="border-radius: 10px">
                                    <div>COSTO ENVIO S/:</div>
                                    <div class="ms-2">
                                        <input v-model="item.cabecera.send" class="form-control form-control-sm costo-de-envio" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4">
                            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                                <p id="mydesc">&nbsp;</p>
                                <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                    <thead class="bg-main" style="font-size: 11px">
                                        <tr class="text-white text-center" style="vertical-align: middle">
                                            <th scope="col">N°</th>
                                            <th scope="col">GRABADO/ NO GRABADO</th>
                                            <th scope="col">MOLECULA</th>
                                            <th scope="col">COMERCIAL</th>
                                            <th scope="col">MARCA</th>
                                            <th scope="col">PRESENTACIÓN</th>
                                            <th scope="col" colspan="1">CONCENTRACION</th>
                                            <th scope="col" colspan="1">FORMA</th>
                                            <th scope="col" colspan="1">CANTIDAD</th>
                                            <th scope="col" colspan="1">UNIDAD</th>
                                            <th scope="col" colspan="1">PRECIO SIN S/IGV</th>
                                            <th scope="col" colspan="1">PREC. UNIT. SIN S/IGV</th>
                                            <th scope="col" colspan="1">SUBTOTAL</th>
                                            <th scope="col" colspan="1" class="align-middle">DESCUENTO%</th>
                                            <th scope="col" colspan="1" class="align-middle">S/SUBTOTAL CON DESC</th>
                                            <th scope="col" colspan="1">ACCIONES</th>
                                        </tr>
                                    </thead>

                                    <tbody class="border-main" style="font-size: 11px; vertical-align: middle">
                                        <tr class="tbody-table1 text-center" v-for="(carro, ind) in item.detalle" :key="ind">
                                            <td>
                                                {{ ind + 1 }}
                                            </td>
                                            <td v-if="carro.cargado_state === null">
                                                <div class="form-check form-switch">
                                                    <input v-model="carro.grabado" class="form-check-input" @change="mtdChangeState(index, ind)" type="checkbox" />
                                                    {{ carro.cargado == 1 ? "GRAVADO" : "NO GRAVADO" }}
                                                </div>
                                            </td>
                                            <td v-else-if="carro.cargado === 1">GRAVADO</td>
                                            <td v-else>NO GRAVADO</td>
                                            <td>
                                                {{ carro.molecule }}
                                            </td>
                                            <td>
                                                {{ carro.commercial }}
                                            </td>
                                            <td>
                                                {{ carro.brand }}
                                            </td>
                                            <td>
                                                {{ carro.presentation }}
                                            </td>
                                            <td v-if="carro.concentration">
                                                {{ carro.concentration }}
                                            </td>
                                            <td v-else>
                                                <input v-model="carro.concentrationedit" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Concentracion" />
                                            </td>
                                            <td v-if="carro.format">
                                                {{ carro.format }}
                                            </td>
                                            <td v-else>
                                                <input v-model="carro.formatedit" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Forma" />
                                            </td>
                                            <td v-if="posEditQuantity1 != ind" @dblclick="mtdEditQuantity(index, ind, 'cantidad')">
                                                {{ carro.quantity }}
                                            </td>
                                            <td v-else>
                                                <input id="input-cantidad" type="text" v-model="carro.editQuantity" @keyup.enter="
                            mtdOutFocusEditPrice(index, ind, 'cantidad')
                          " @blur="mtdOutFocusEditPrice(index, ind, 'cantidad')" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="CANTIDAD" />
                                            </td>
                                            <td>
                                                <select class="form-select form-select-sm select-p" aria-label=".form-select-sm example" v-model="carro.unit" @change="mtdSelectUnit(index, ind)">
                                                    <option v-for="(un, pos) in carro.units" :key="pos" :value="un.id">
                                                        {{ un.unit.name }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td v-if="posEditQuantity != ind" @dblclick="mtdEditQuantity(index, ind, 'Precio')">
                                                {{ carro.precio_purchase }}
                                            </td>
                                            <td v-else>
                                                <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="PRECIO UNITARIO" v-model="carro.editPrecio" @keyup.enter="
                            mtdOutFocusEditPrice(index, ind, 'Precio')
                          " @blur="mtdOutFocusEditPrice(index, ind, 'Precio')" />
                                            </td>
                                            <td>
                                                {{ carro.precio_purchase }}
                                            </td>

                                            <td>
                                                {{ calculateSubtotal(index, ind)}}
                                            </td>
                                            <!--Descuento-->
                                            <td v-if="posEditDiscount != ind" @dblclick="mtdEditQuantity(index, ind, 'Descuento')">
                                                {{ carro.discount !== null ? carro.discount + '%' : '0%' }}
                                            </td>
                                            <td v-else>
                                                <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Descuento" v-model="carro.editDiscount" @keydown="filterKey" @keyup.enter="
                            mtdOutFocusEditPrice(index, ind, 'Descuento')
                          " @blur="mtdOutFocusEditPrice(index, ind, 'Descuento')" />
                                            </td>
                                            <!--Fin Descuento-->
                                            <td>
                                                {{
                          carro.price_discount = carro.editDiscount
                            ? (carro.price_purchase - (carro.editDiscount / 100 * carro.price_purchase)).toFixed(2)
                            : '0.00'
                        }}
                                            </td>
                                            <td>
                                                <a @click="mtdBeforeDelete(index, ind)" id="btn-remove" style="font-size: 15px">
                                                    <i class="fas fa-trash-alt"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex mt-3 justify-content-end">
                                <div class="px-2 py-1 bg-white">
                                    NO GRAVADA S/: {{ mtdValornograbado() }}
                                </div>
                                <div class="px-2 py-1 bg-white">
                                    VALOR VENTA S/: {{ mtdValor() }}
                                </div>
                                <div class="px-2 py-1 bg-white">IGV S/: {{ mtdGetIgv() }}</div>
                                <div class="px-2 py-1 mx-1 bg-white">
                                    TOTAL C/ IGV: {{ mtdGetTotal() }}
                                </div>
                                <button @click="mtdInsertData()" type="button" class="px-3 border-0 text-white bg-main">
                                    ENVIAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-modal-requerimiento",
    components: {
        CModal,
    },
    data() {
        return {
            transaction: {
                type: 1,
                motive: "",
            },
            agregarprovider: {
                comments: "",
                shipping_cost: "",
                payment_type: "",
            },
            dataShow: [],
            //carrito
            posOc: 0,
            tablaDetail: [],
            posEditQuantity1: null,
            posEditQuantity: null,
            posEditDiscount: null,
            selectOcExecuted: false,
            newQuantity: null,
            oldQuantity: null,
            editingField: "",
            newValue: "",
        };
    },
    watch: {
        // carrito: {
        //     handler: function (newValue, oldValue) {
        //         if (newValue.length > 0 && !this.selectOcExecuted) {
        //             this.selectOc(0);
        //             this.selectOcExecuted = true;
        //         }
        //     },
        //     deep: true,
        //     immediate: true,
        // }
        numcarrito :{
            handler: function (newValue, oldValue) {
                if (newValue > 0 ) {
                    this.selectOc(newValue -1);
                  
                }
            },
            deep: true,
            immediate: true,
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        dataRequerimientosDetails: {
            type: Array,
            default: () => ({}),
        },
        view: {
            type: String,
            default: "OC", // OC -> ver oc, req->escoger proveedores
        },
        medicaments: Array,
        //carrito
        carrito: {
            type: Array,
            default: () => [],
        },
        numcarrito:{
            type: Number,
            default: 0
        }
    },
    computed: {},
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit("mtdcloseModal");
        },
        mtdClose() {
            this.$emit("mtdcloseModal");
        },
        // metodos de carrito de orden de compra
        mtdAddItemCarrito: function (payload) {
            this.$emit("mtdAddItemCarrito", payload);
        },

        mtdInsertData: function () {
            this.selectOcExecuted = false
            this.carrito.forEach((carro) => {
                carro.detalle.forEach((detalleItem, ind) => {
                    detalleItem.formatedit = !detalleItem.format ?
                        detalleItem.formatedit :
                        detalleItem.format;
                    detalleItem.concentrationedit = !detalleItem.concentration ?
                        detalleItem.concentrationedit :
                        detalleItem.concentration;
                });
            });
          
            this.post({
                    url: this.$store.getters.get__url + "/logistics/oc/store",
                    token: this.$store.getters.get__token,
                    params: {
                        carrito: this.carrito,
                    },
                })
                .then((response) => {
                    if (response.state == 0) {
                        for (let index = 0; index < response.data.length; index++) {
                            const element = response.data[index];

                            window.open(
                                this.$store.getters.get__url +
                                "/logistica/viewpdf/oc/" +
                                element,
                                "_blank"
                            );
                        }
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.selectOcExecuted = false
                        this.$emit("registro-exitoso");
                        this.mtdClose();
                        this.tablaDetail = [];
                       
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto!",
                            text: "Error!",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {});
        },
        mtdEditQuantity: function (index, pos, edit) {
            if (edit === "Precio") {
                this.posEditQuantity = pos;
                this.posEditQuantity1 = -1;
                // Inicializa editPrecio con el valor actual
                this.tablaDetail[index].detalle[pos].editPrecio =
                    this.tablaDetail[index].detalle[pos].precio_purchase;
            } else if (edit === "cantidad") {
                this.posEditQuantity1 = pos;
                this.posEditQuantity = -1;
                // Inicializa editQuantity con el valor actual
                this.tablaDetail[index].detalle[pos].editQuantity =
                    this.tablaDetail[index].detalle[pos].quantity;
            } else if (edit === 'Descuento') {
                this.posEditDiscount = pos;
                this.posEditQuantity1 = -1;
                this.tablaDetail[index].detalle[pos].editDiscount =
                    this.tablaDetail[index].detalle[pos].discount;
            }
        },
        calculateSubtotal(ind, index) {
            const precioUnitario =
                parseFloat(this.tablaDetail[ind].detalle[index].precio_purchase) || 0;
            const cantidad =
                parseInt(this.tablaDetail[ind].detalle[index].quantity) || 0;
            const subtotal = precioUnitario * cantidad;
            this.tablaDetail[ind].detalle[index].price_purchase = subtotal.toFixed(2);
            return subtotal.toFixed(2);
        },
        mtdOutFocusEditPrice(ind, index, field) {
            if (index >= 0 && index < this.tablaDetail[ind].detalle.length) {
                if (field === "Precio") {
                    const newPrecio = parseFloat(
                        this.tablaDetail[ind].detalle[index].editPrecio
                    );
                    if (!isNaN(newPrecio)) {
                        this.tablaDetail[ind].detalle[index].precio_purchase =
                            newPrecio.toFixed(2);
                        this.mtdSelectUnit(ind, index);
                    }
                } else if (field === "cantidad") {
                    const newQuantity = parseInt(
                        this.tablaDetail[ind].detalle[index].editQuantity
                    );
                    if (!isNaN(newQuantity)) {
                        this.tablaDetail[ind].detalle[index].quantity = newQuantity;
                    }
                    this.mtdSelectUnit(ind, index);
                } else if (field === 'Descuento') {
                    const newDiscount = parseInt(this.tablaDetail[ind].detalle[index].editDiscount);
                    if (!isNaN(newDiscount)) {
                        this.tablaDetail[ind].detalle[index].discount = newDiscount.toFixed(2);
                    }
                }
                this.calculateSubtotal(ind, index);
                this.posEditDiscount = -1;
                this.posEditQuantity = -1;
                this.posEditQuantity1 = -1;
            }
        },
        mtdChangeState: function (index, ind) {
            this.tablaDetail[index].detalle[ind].cargado = this.tablaDetail[index]
                .detalle[ind].grabado ?
                1 :
                0;
        },
        mtdBeforeDelete: function (index, ind) {
            if (this.tablaDetail[index].detalle.length === 1) {
                this.selectOcExecuted = false
                if (this.carrito.length === 1) {
                    this.selectOcExecuted = false
                    this.carrito.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    this.mtdClose();
                } else {
                    this.carrito.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    if( this.posOc-1 < 0){
                        this.selectOc(this.posOc)
                    }else{
                        this.selectOc(this.posOc-1)
                    }
                }
            } else {
                this.tablaDetail[index].detalle.splice(ind, 1);
            }

        },
        /** nuevo oc */
        selectOc: function (pos) {
            if (pos >= 0 && pos < this.carrito.length) {
                this.tablaDetail = [];
                this.posOc = pos;
                this.tablaDetail.push(this.carrito[this.posOc]);
            }
        },
        mtdGetTotal: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                let valornogravada = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        valornogravada += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                    if (element.cargado == 1) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                let igv = valor * 0.18;
                let total =
                    parseFloat(valor) + parseFloat(igv) + parseFloat(valornogravada);
                return parseFloat(total).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValornograbado: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValor: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdGetIgv: function () {
            if (this.carrito.length > 0) {
                let valor = 0;
                this.carrito[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                let igv = parseFloat(valor * 0.18);
                return parseFloat(igv).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdSelectUnit: function (index, pos) {
            let cantidadUnidades = 1;
            this.tablaDetail[index].detalle[pos].units.forEach((element) => {
                if (element.id == this.tablaDetail[index].detalle[pos].unit) {
                    cantidadUnidades = element.unit.quantity;
                }
            });
            this.tablaDetail[index].detalle[pos].precio_unitario = parseFloat(
                this.tablaDetail[index].detalle[pos].precio_purchase / cantidadUnidades
            ).toFixed(2);
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
    },

};
</script>

<style scoped>
.clickCarrito {
    cursor: pointer;
}

.clickCarrito:hover {
    background-color: #D5DBDB;

}

</style>
