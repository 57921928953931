<template>
    <div>
      <div class="container">

        <div class="d-flex flex-row-reverse">
          <div class="btn-home mt-3 mb-4">
            <button
              type="button"
              class="btn btn-outline-main"
              @click="returnHome"
            >
              <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
            &nbsp;
            <button
              type="button"
              class="btn btn-bm-noradius"
              @click="mtdModalShow(0,null)"
            >
              <i class="fas fa-plus"></i> Nueva
            </button>
          </div>
        </div>

        <div id="div-modal-anular" class="w-100">
          <div id="div-anular" class="card w-100">
            <div
              class="card-header text-center text-white h4"
              style="background: #900052"
            >
              <strong>ASEGURADORAS</strong>
            </div>
            <div class="card-body w-100"  >
                <div  id="content-timelinetable">
                <div class="d-flex justify-content-start"  >
                    <ul
                    v-for="(item, index) in data"
                    :key="index"
                    class="list-group text-center"
                    >
                    <li
                        :class="
                        index > 0
                            ? item.state == 1
                            ? 'list-group-item list-group-item-success'
                            : 'list-group-item-warning list-group-item'
                            : 'list-group-item-info list-group-item'
                        "
                        style="height: 55px"
                    >
                        <div v-if="index > 0" class="btn-group">
                        <button type="button" class="btn btn-sm">
                            {{ item.value }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                            <a
                                @click="mtdModalShow(1, item)"
                                class="dropdown-item"
                                href="javascript:void(0)"
                                >Editar</a
                            >
                            </li>
                            <li>
                            <a
                                @click="mtdDelete(index)"
                                class="dropdown-item"
                                href="javascript:void(0)"
                                >Eliminar</a
                            >
                            </li>
                        </ul>
                        </div>
                        <span v-else> {{ item.value }}</span>
                    </li>
                    <li
                        v-for="(it, ind) in item.data"
                        :key="ind"
                        class="list-group-item"
                        @dblclick="mtdActiveInput(index, ind, item.id)"
                        style="height: 79px"
                    >
                        <template v-if="it.key != 15">
                        <span v-if="pos[0] == index && pos[1] == ind"
                            ><input
                            type="text"
                            class="form-control form-control-sm text-center"
                            placeholder="Valor"
                            v-model="it.new"
                            @blur="mtdOutFocusInput"
                            @keyup.enter="$event.target.blur()"
                            
                            
                        /></span>
                        <span v-else>{{ it.value != null ? it.value : "-" }}</span>
                        </template>
                        <template v-else>
                        <div class="form-check text-center">
                            <input
                            class="form-check-input"
                            type="checkbox"
                            :checked="item.boolean"
                            @change="mtdChangeEquipe(index, ind)"
                            />
                        </div>
                        </template>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <cModalInsuranceEquipeVue
        :title="modal_equipe.title"
        :boo_modal="modal_equipe.modal_form"
        @close_modal_equipe="close_modal_equipe"
        @mtd_action_equipe="mtd_action_equipe"
        :dataEquipe="modal_equipe.dataEquipe"
      />

      <cModalInsuranceVue
        :title="modalIn.title"
        :boo_modal="modalIn.modal_form"
        :payload="modalIn.payload"
        :num="modalIn.num"
        @closeModalIn="closeModalIn"
        @Datadetail="Datadetail"
      />

      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalInsuranceEquipeVue from "../Modals/cModalInsuranceEquipe.vue";
import cModalInsuranceVue from "../Modals/cModalInsurance.vue";

export default {
    name: "c-maintenance-insurance",
    components: { 
        cModalInsuranceEquipeVue,
        SweetAlert,
        cModalInsuranceVue
    },
    data() {
        return {
            swal: null,
            data: [],
            insurance: {
                name: "",
                id: "",
                state: "",
            },
            modal: {
                title: "",
                action: "",
            },
            posEdit: null,
            pos: [null, null],
            modal_equipe: {
                action: "",
                title: "",
                modal_form: false,
                dataEquipe: [],
                pos: [null, null],
            },
            modalIn: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
        };
    },
    computed: {
        cpButtonForm: function () {
            if (this.insurance.name.length > 0) return false;
            return true;
        },
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Insurance",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                let res = response.data;
                this.data = [
                    {
                        value: "CONSULTA",
                        name: "CONSULTA",
                        data: [
                            {
                                value: "CONSULTA AMBULATORIA DE EMERGENCIA",
                                new: "",
                                state: 1,
                            },
                            { value: "CONSUTLA AMBULATORIA EXTERNA", new: "", state: 1 },
                            { value: "TOPICO DE EMERGENCIA", new: "", state: 1 },
                            { value: "I/C CUALQUIER ESPECIALIDAD", new: "", state: 1 },
                            { value: "CAMA INDIVUDUAL COVID", new: "", state: 1 },
                            { value: "CAMA BIPERSONAL", new: "", state: 1 },
                            { value: "BOXN UCI", new: "", state: 1 },
                            { value: "CONSULTA INTENSIVA", new: "", state: 1 },
                            { value: "FACTOR HONORARIOS", new: "", state: 1 },
                            { value: "FACTOR AUXILIARES", new: "", state: 1 },
                            { value: "KAIROS", new: "", state: 1 },
                            { value: "OXIGENO", new: "", state: 1 },
                            { value: "PRUEBAS RAPIDAS", new: "", state: 1 },
                            { value: "MOLECULARES", new: "", state: 1 },
                            { value: "ANTIGENO", new: "", state: 1 },
                            { value: "USO DE EQUIPOS", new: "", state: 1 },
                            { value: "FACTOR CONTRATO", new: "", state: 1 },
                        ],
                    },
                ];
                res.forEach((element) => {
                    this.data.push({
                    id: element.id,
                    state: element.state,
                    value: element.name,
                    name: element.name,
                    data: element.amount,
                    boolean: element.boolean_equipos,
                    });
                });
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        returnHome: function () {
            this.$emit("returnHome");
        },
        
        mtdModalShow: function (num, payload) {
            this.modalIn.title = (num == 0) ? 'Registrar Aseguradora' : 'Editar Aseguradora';
            this.modalIn.modal_form = true;
            this.modalIn.num = num;
            this.modalIn.payload = payload;
        },

        closeModalIn: function () {
            this.modalIn = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },

        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                this.mtdInsertData(payload_detail, 'store');
            } else if (num_detail == 1) {
                this.mtdInsertData(payload_detail, 'edit');
            } 
        },

        mtdInsertData: function (payload_detail, actionCrud) {
            this.post({
                url: this.$store.getters.get__url + "/Insurance/" + actionCrud,
                token: this.$store.getters.get__token,
                params: payload_detail,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.closeModalIn();
                    this.mtdGetData();
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdActiveInput: function (index, ind, id) {
            if (index > 0) {
                this.pos = [index, ind, id];
            } else {
                this.pos = [null, null, null];
            }
        },

        mtdEditValor: function () {
            this.data[this.pos[0]].data[this.pos[1]].value =
                this.data[this.pos[0]].data[this.pos[1]].new;
            this.post({
                url: this.$store.getters.get__url + "/Insurance/edit/jsonamount",
                token: this.$store.getters.get__token,
                params: {
                    id: this.pos[2],
                    amount: this.data[this.pos[0]].data,
                },
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.data[this.pos[0]].data = response.data.data[0].amount;
                    this.pos = [null, null, null];
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
        
        onEnter: function() {
            this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro que desea editar este valor?","warning","Editar") 
            .then((result) => {
                if (result.value) {
                    this.mtdEditValor();
                } else {
                    this.pos = [null, null, null];
                }
            });
        },

        mtdOutFocusInput: function () {
            this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro que desea editar este valor?","warning","Editar") 
            .then((result) => {
                if (result.value) {
                    this.mtdEditValor();
                } else {
                    this.pos = [null, null, null];
                }
            });
        },

        /** delete insurance */
        mtdDelete: function (pos) {
            this.$refs.SweetAlert.showConfirmSimple2("¿Estás seguro que desea eliminar esta aseguradora?","Esta acción no puede ser revertida","warning","Eliminar") 
            .then((result) => {
                if (result.value) {
                    this.mtdDeleteData(pos);
                } 
            });
        },

        mtdDeleteData: function (pos) {
            this.post({
                    url: this.$store.getters.get__url + "/Insurance/destroy",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.data[pos].id,
                    },
            })
            .then((response) => {
                if(response.data.state == 0){
                    this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                    this.mtdGetData();
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        /* Uso de Equipos */

        mtdCommitProccess: function (index,action,ind) {
            this.post({
                url: this.$store.getters.get__url + "/Insurance/equipe",
                token: this.$store.getters.get__token,
                params: {
                    id: this.data[index].id,
                    action: action
                },
            })
            .then((response) => {
                if (response.data.state == 0) {
                    if (action == 'data') {
                        this.modal_equipe.title = "Agregar precio de equipos";
                        this.modal_equipe.dataEquipe= response.data.equipos;
                        this.modal_equipe.pos = [index, ind];
                        this.data[index].boolean = 1;
                        this.modal_equipe.modal_form = true;
                    } else {
                        this.modal_equipe.pos = [null, null];
                        this.modal_equipe.modal_form = false;
                        this.data[index].boolean = 0;
                        this.$refs.SweetAlert.showSuccess("El registro ha sido editado correctamente");
                    }
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdChangeEquipe: function (index, ind) {
            this.data[index].data[ind].value = !this.data[index].data[ind].value;
            if (this.data[index].boolean == 0) {
                this.mtdCommitProccess( index,'data',ind);
            } else {
                this.mtdCommitProccess(index,'action',ind);
            }
        },

        close_modal_equipe: function () {
            this.modal_equipe.modal_form = false;
            this.data[this.modal_equipe.pos[0]].boolean = 0;
        },

        mtd_action_equipe: function (res) {
            this.post({
                url: this.$store.getters.get__url + "/Insurance/equipe/json",
                token: this.$store.getters.get__token,
                params: {
                    json: res,
                    id: this.data[this.modal_equipe.pos[0]].id,
                },
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.data[this.modal_equipe.pos[0]].boolean = 1;
                    this.modal_equipe = {
                        action: "",
                        title: "",
                        modal_form: false,
                        dataEquipe: [],
                        pos: [null, null],
                    };
                    this.$refs.SweetAlert.showSuccess("El registro ha sido registrado correctamente");
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
    },
};
</script>

<style scoped>
#btn-edit {
color: #39b54a;
font-size: 25px;
margin: 0px 2px;
}

#btn-edit:hover {
color: #217b2d;
}

#btn-remove {
color: #ff0000;
font-size: 25px;
margin: 0px 2px;
}

#btn-remove:hover {
color: #a30404;
}

.btn-bm-noradius {
margin-left: 10px;
width: 150px;
height: 38px;
}

.btn-bm-noradius:hover {
background: rgb(170, 2, 95);
background-color: rgb(170, 2, 95);
color: white;
}

.btn-register {
background: rgb(144, 0, 82);
}

.btn-register:hover {
background: rgb(170, 2, 95);
}

.modal-header {
background: rgb(144, 0, 82);
}

.close {
cursor: pointer;
}

.text-start {
text-align: left;
}

.tr-table > th > div {
background: #900052;
border-radius: 7px;
padding: 0px;
margin: 4px;
height: 45px;
padding-top: 10px;
}

.tr-table > th {
padding: 0px;
}

.tbody-table > td > div {
background: #eaecee;
border-radius: 15px;
padding: 0px;
margin: 4px;
height: 45px;
padding-top: 10px;
padding-left: 10px;
padding-right: 10px;
text-align: center;
}

.tbody-table > td {
padding: 0px;
}

.tbody-table > td > div > div {
border-radius: 10px;
}

#btn-close {
width: 49%;
}

.table-th {
width: 40% !important;
}

.table-th-porcent {
width: 10% !important;
}

.table-th-state {
width: 15% !important;
}

.page-link {
color: black !important;
}

.page-link.active {
color: white !important;
border-color: white !important;
background: rgb(144, 0, 82);
}

.page {
color: white !important;
background: rgb(144, 0, 82);
}

@media (max-width: 480px) {
#btn-edit {
    font-size: 13px !important;
}

#btn-remove {
    font-size: 13px !important;
}

#btn-close {
    width: 100%;
}

.container {
    font-size: 11px !important;
}

.btn-register {
    width: 100% !important;
    margin-top: 0 !important;
}

.td-div-a {
    padding-top: 10px !important;
}

.td-div-state > div > div {
    margin-top: 3px !important;
}

}

@media (max-width: 900px) {
#div-table {
    overflow-x: scroll !important;
}
}
@media (max-width: 415px) {
#state_speciality {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}

#state_speciality div {
    width: 100%;
}
}
</style>
