
<template>
  <div>
    <nav
      id="nav-menu"
      class="fixed-bottom py-0 d-flex justify-content-around mt-2"
      style="background: rgb(162, 162, 162)"
    >
      <div
        :style="mtdGetStyle('tareas_asignadas')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('tareas_asignadas')"
      >
        <span :style="mtdGetStyleSpan('tareas_asignadas')" class="btn w-100"><strong>TAREAS ASIGNADAS</strong></span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarComponent",
  props: {
    pageView: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: null,
    };
  },
  created() {},
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home") return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home") return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
};
</script>

<style scoped>
#contenedor-menu {
  justify-content: center;
}

#lista_menu_home {
  font-family: Arial;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}

li {
  background-color: #900052;
  float: left;
  text-align: center;
  text-decoration: none;
}

li a {
  color: #ffffff;
  font-weight: bold;
  margin: 0;
}

.nav-footer:hover {
  color: #000000;
  /* background-color:rgb(162, 162, 162); */
  background: #900052;
}

#nav {
  position: absolute;
  float: left;
  bottom: 0px;
}
#img_logo {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
}

@media (max-width: 800px) {
  #title-user,
  #title-cash,
  #title,
  #title-date {
    text-align: right !important;
  }
  #img_logo img {
    width: 175px !important;
  }

  li {
    float: none;
  }

  #nav-menu {
    position: inherit;
  }
  /**  */
  #nav-menu {
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  #title-cash,
  #title,
  #title-date {
    text-align: center !important;
  }
  #title-user {
    text-align: right !important;
  }
}
</style>
