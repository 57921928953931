<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100 mb-lg-5">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>CONSULTAR DOCUMENTO</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 mt-2">
                                    <div class="input-group input-group-sm">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="checkboxBoleta" v-model="filter.boleta" @change="mtdSearchFilter" />
                                            <label class="form-check-label" for="checkboxBoleta"> Boleta </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="checkboxFactura" v-model="filter.factura" @change="mtdSearchFilter" />
                                            <label class="form-check-label" for="checkboxFactura"> Factura </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12 mt-3">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text text-white bg-main cursor-pointer">
                                            <i class="fas fa-search"></i>&nbsp;&nbsp;Filtrar
                                        </span>
                                        <input type="text" class="form-control form-control-sm" placeholder="Buscar DNI o nombre de Paciente o tipo de documento" v-model="search" @input="mtdSearchFilter" />
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12 mt-3 text-center">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-funnel-dollar"></i>&nbsp;Total</span>
                                        <input type="text" class="form-control form-control-sm" placeholder="Buscar dni o nombre de paciente, o por número de boleta..." disabled v-model="cpTotalFiltro" />
                                    </div>
                                </div>
                                </div>
                            </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-anular" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class=" table-th text-white text-center text-xs">
                                        <th scope="col">N°</th>
                                        <th scope="col">
                                            PACIENTE
                                        </th>
                                        <th scope="col">FECHA</th>
                                        <th scope="col">
                                            TIPO DE DOCUMENTO
                                        </th>
                                        <th scope="col">
                                            N° DE DOCUMENTO
                                        </th>
                                        <th scope="col">
                                            TOTAL
                                        </th>
                                        <th scope="col">ESTADO</th>
                                        <th scope="col" colspan="1">
                                            ACCIONES
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-xs">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="10">No se han encontrado resultados</td>
                                    </tr>
                                    <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                        <td scope="row">{{ mtdNumber(index) }}</td>
                                        <td>{{ item.sale.patient.document }} - {{ item.sale.patient.name }}</td>
                                        <td class="align-center">
                                            {{ item.date | formatDate }}
                                        </td>
                                        <td class="align-center">{{ item.document }}</td>
                                        <td class="align-center">{{ item.serie }}-{{ item.number }}</td>
                                        <td class="align-center">
                                            S/ {{ parseFloat(item.amount).toFixed(2) }}
                                        </td>
                                        <td class="align-center">
                                            <span v-if="item.state == 1" class="badge text-bg-success">ACTIVO</span>
                                            <span v-if="item.state == 2" class="badge text-bg-danger"> {{ item.notecredit == null ? 'ANULADO' : 'CON NOTA DE CREDITO' }}</span>
                                            <span v-if="item.state==3" class="badge text-bg-warning">SIN PROCESAR</span>
                                        </td>
                                        <td class="align-center">
                                            <button type="button" title="Ver ticket" class="btn btn-info btn-sm" @click="mtdViewTicket(item.sunat)">
                                                <i class="fas fa-ticket-alt"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
export default {
    name: "c-jefatura-consultarDocumentos",
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
                boleta: true, // Estado inicial de Boleta
                factura: true, // Estado inicial de Factura
            },
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataShow: [],
            data: [],
            search: "",
        };
    },
    created() {
        this.getFirstDayOfMonth();
        this.mtdGetData()
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        cpTotalFiltro() {
            let tot = 0;
            this.dataShow.forEach((element) => {
                tot = parseFloat(tot) + parseFloat(element.amount);
            });
            return parseFloat(tot).toFixed(2);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()-1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Jefatura/consultarDocumento",
                    token: this.$store.getters.get__token,
                    params: {
                        begindate: this.filter.begindate,
                        enddate: this.filter.enddate,
                    },
                })
                .then((response) => {
                    this.dataShow = [];
                    this.selectPage = 1;
                    this.data = response.data;
                    if (this.data.length > 0) {
                        this.calculateData(10)
                    }

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {

                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let doc = element.sale.patient.document.toString().toUpperCase();
                    let name = element.sale.patient.name.toString().toUpperCase();
                    let serie = element.serie.toString().toUpperCase();
                    let number = element.number.toString().toUpperCase();
                    return (
                        doc.includes(query) || name.includes(query) || serie.includes(query) || number.includes(query)
                    );
                });
                selectPage = 1;
            }
            if (!this.filter.boleta) {
                filteredData = filteredData.filter((item) => item.document !== 'BOLETA');
            }
            if (!this.filter.factura) {
                filteredData = filteredData.filter((item) => item.document !== 'FACTURA');
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        mtdViewTicket: function (sunat) {
            let json = JSON.parse(sunat);
            window.open(json.json.enlace_del_pdf_ticket, "_blank");
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage = this.selectPage < this.stepPagination.length ? this.selectPage + 1 : this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>
