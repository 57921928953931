<template>
<div class="container pb-5">
    <div id="div-modal-cxc" class="d-flex justify-content-center mt-4">
        <div id="div-cxc" class="card w-100 text-center">
            <div class="card-header text-white bg-main h4">
                <div style="position: relative;">
                    <div class="">
                        <strong>REPORTES DE CAJA</strong>
                    </div>
                    <div class="btn-home-jefatura" style="right: 0;top: 0;position: absolute;">
                        <button type="button" class="btn btn-sm" @click="returnHome">
                            <i class="fas fa-home-lg-alt"></i> Inicio
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body w-100">
                <div class="row d-flex mt-1">
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="view == 'dia' ? true : false" @change="mtdChangeState" />
                            <label class="form-check-label" for="flexSwitchCheckChecked">{{ view == 'dia' ? "Por Días" :
                            "Por Meses" }}</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-2" v-if="view == 'dia'">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-4">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init" />
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-2" v-if="view == 'dia'">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-4">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end" />
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-center" v-if="view == 'dia'">
                        <div class="input-group input-group-sm text-center w-100">
                            <button class="btn btn-sm w-100 text-white w-100" style="background: #900052" @click="mtdGetData">
                                <i class="fas fa-search"></i>&nbsp;Buscar
                            </button>
                        </div>
                    </div>
                    <template v-if="view == 'dia'">

                        <div class="col-md-12 mt-2" id="div-tabla-anular">
                            <div id="div-modal-caja" class="d-flex justify-content-center" v-for="(item, index) in cpData" :key="index">
                                <div id="div-caja" class="card w-100" style="height: auto">
                                    <div class="card-header text-white h5" style="background: #900052; display: flex; justify-content: space-between; align-items: center;">
                                        <strong style="margin: 0;">Arqueo del
                                            {{ item.date | formatDate("%d/%m/%y") }}
                                        </strong>
                                    </div>
                                    <div class="card-body" style="max-height: 485px; overflow-y: auto">
                                        <div id="row div-dataMoney" class="container">
                                            <div class="row d-flex">
                                                <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12 mt-0 text-center">
                                                    <label class="form-label text-center"><strong>Billetes</strong></label>
                                                    <div v-for="(billete, index) in billetes(item)" :key="index" class="">
                                                        <div class="input-group input-group-sm mb-2">
                                                            <span class="input-group-text col-4">{{ billete.label }}</span>
                                                            <input style="align-items:center;text-align:center" type="text" class="form-control form-control-sm" disabled aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="billete.cantidad" width="10px" />
                                                            <span v-if="billete.total == 0" class="input-group-text col-4">= S/. 0.00</span>
                                                            <span v-else class="input-group-text col-4">= S/{{ parseFloat(billete.total).toFixed(2) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12 text-center">
                                                    <label class="form-label text-center"><strong>Monedas</strong></label>
                                                    <div v-for="(moneda, index) in monedas(item)" :key="index" class="">
                                                        <div class="input-group input-group-sm mb-2">
                                                            <span class="input-group-text col-4">{{ moneda.label }}</span>
                                                            <input type="text" style="align-items:center;text-align:center" class="form-control form-control-sm" disabled aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="moneda.cantidad" />
                                                            <span class="input-group-text col-4" v-if="moneda.total == 0">= S/. 0.00</span>
                                                            <span v-else class="input-group-text col-4">= S/ {{ parseFloat(moneda.total).toFixed(2) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-lg-2 col col-sm-12" style="text-align: center">
                                                    <strong> TOTAL EFECTIVO </strong>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>APERTURA: S/.{{ item.details[0].initial }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>INGRESOS: S/.{{ cpIncome(item) }}</strong>
                                                    </button>

                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>EGRESOS: S/.{{ cpExpense(item) }} </strong>
                                                    </button>

                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>TOTAL: S/.{{ item.details[item.details.length-1].final }} </strong>
                                                    </button>
                                                </div>
                                                <div class="col-md-4 col-lg-2 col col-sm-12" style="text-align: center">
                                                    <strong> OTROS INGRESOS </strong>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Trans.
                                                                : S/.
                                                                {{
                                      ttransferencia(item)
                                      }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Tarjeta:
                                                                : S/.
                                                                {{
                                      ttarjeta(item)
                                      }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Deposito
                                                                : S/.
                                                                {{
                                      tdeposito(item)
                                      }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>
                                                                Planilla
                                                                : S/.
                                                                {{
                                      tplanilla(item)
                                      }}
                                                            </strong>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-lg-2 col col-sm-12" style="text-align: center">
                                                    <strong> SALDOS </strong>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Saldo Efec. S/{{ item.details[item.details.length-1].final }}</strong>
                                                    </button>

                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Saldo General: S/.{{ general(item) }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Vueltos Pte S/{{ vueltos(item) }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong v-if="sobrafalta(item) < 0">
                                                            Faltante :S/{{ parseFloat(sobrafalta(item)) * -1 }}</strong>
                                                        <strong v-else> Sobrante: S/{{ sobrafalta(item) }}</strong>
                                                    </button>
                                                </div>
                                                <div class="row d-flex mt-2">
                                                    <div class="col-md-4">
                                                        <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>Vueltos Rec: S/.{{ turnedRec(item) }}</strong>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>Depósitos: S/.{{ depositos(item) }}</strong>
                                                        </label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                            <strong>Retiros: S/.{{ retiros(item) }}</strong>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center" v-if="cpData.length == 0">
                                <div class="p-2">
                                    <h6>No hay registros para estas fechas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-3" v-if="dataCaja.length > 0">
                            <div class="d-flex justify-content-center" style="align-items: center">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li class="page-item" v-if="selectPage > 3">
                                            <a class="page-link " href="javascript:void(0)" @click="selectedPage(1)">
                                                {{ 1 }}
                                            </a>
                                        </li>
                                        <li class="page-item" v-if="selectPage > 3">
                                            <span class="page-link">...</span>
                                        </li>
                                        <template v-for="(item, index) in stepPagination">
                                            <li :key="index" class="page-item" v-if="(index >= Math.max(selectPage - 2, 0) &&
                            index < selectPage + 3 &&
                            index < stepPagination.length) ||
                            (stepPagination.length <= 4 && index === stepPagination.length - 1) ||
                            (index === 0 && selectPage <= 3)
                            ">
                                                <a :class="selectPage == item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                            </li>
                                        </template>
                                        <li class="page-item" v-if="selectPage < stepPagination.length - 4">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li class="page-item" v-if="selectPage < stepPagination.length - 3">
                                            <a class="page-link" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">
                                                {{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </template>
                    <repCajaxMes v-if="view == 'mes'" :dataMes="dataxMes" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import repCajaxMes from '../pages/RepCajaxMes.vue'
export default {
    data() {
        return {
            view: "dia",
            filter: {
                init: null,
                end: null,
                mes: "0"
            },
            months: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            itemsPerPage: 1,
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataCaja: [],
            dataxMes: [],
            posEdit: null,
            noRecordsMessage: "",
            datePos: 0,
        };
    },
    props: {
        bead: {},
    },
    components: {
        repCajaxMes,
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        this.mtdGetData();
        this.mtdGetDataMes();
    },
    computed: {
        cpData() {
            const startIndex = (this.selectPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.dataCaja.slice(startIndex, endIndex);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
            this.view = this.view == 'dia' ? 'mes' : 'dia';
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        changeView: function (view) {
            this.view = view;
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/ReportesCajas/reportecaja",
                    token: this.$store.getters.get__token,
                    params: {
                        init: this.filter.init,
                        end: this.filter.end,
                    },
                })
                .then((response) => {
                    this.dataCaja = [];
                    this.selectPage = 1;
                    this.stepPagination = [];
                    this.dataCaja = response.data;
                    if (this.dataCaja.length === 0) {
                        this.noRecordsMessage = "No hay registros";
                    } else {
                        this.noRecordsMessage = "";
                    }
                    let cantidad = parseInt(this.dataCaja.length);
                    for (let index = 0; index < cantidad; index++) {
                        this.stepPagination.push(index + 1);
                    }
                })
                .catch((errors) => {});
        },
        mtdGetDataMes: function () {
            this.get({
                    url: this.$store.getters.get__url + "/ReportesCajas/arqueoCajaXMes",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataxMes = response.data;
                })
                .catch((errors) => {});
        },
        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page;
        },
        backPage: function () {
            this.selectPage = this.selectPage === 1 ? 1 : this.selectPage - 1;
        },
        nextPage: function () {
            this.selectPage =
                this.selectPage === this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },
        cpIncome: function (item) {
            let details = item.details;
            let sum = 0;
            details.forEach(element => {
                sum += parseFloat(element.income);
            });
            return parseFloat(sum).toFixed(2);
        },
        cpExpense: function (item) {
            let details = item.details;
            let sum = 0;
            details.forEach(element => {
                sum += parseFloat(element.expense);
            });
            return parseFloat(sum).toFixed(2);
        },
        billetes: function (item) {
            let details = item.details;
            //sacar una copia
            let json = JSON.parse(details[details.length-1].transaction[0].json_cierre);
            let cierrejson = json.billetes;
            return cierrejson;
        },
        monedas: function (item) {
            let details = item.details;
            //sacar una copia
            let json = JSON.parse(details[details.length-1].transaction[0].json_cierre);
            let cierrejson = json.monedas;
            return cierrejson;
        },
        ttarjeta(item) {
            let details = item.details;
            let cierre;
            let sum = 0;
            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totTarjeta);
            });
            return parseFloat(sum).toFixed(2);
        },
        tdeposito(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totDeposito);
            });
            return parseFloat(sum).toFixed(2);
        },
        ttransferencia(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totTransferencia);
            });
            return parseFloat(sum).toFixed(2);
        },
        tplanilla(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.totPlanilla);
            });
            return parseFloat(sum).toFixed(2);
        },
        vueltos(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                let vuelto = cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00'
                sum += parseFloat(vuelto)
            });
            return parseFloat(sum).toFixed(2);
        },
        depositos(item) {
            let details = item.details;
            let cierre;
            let sum = 0;
            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.deposit);
            });
            return parseFloat(sum).toFixed(2);
        },
        retiros(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sum += parseFloat(cierre.retirement);
            });
            return parseFloat(sum).toFixed(2);
        },
        turnedRec(item) {
            let details = item.details;
            let cierre;
            let sum = 0;

            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                let turnedReceived = cierre.turned_received ? parseFloat(cierre.turned_received) : 0;
                sum += turnedReceived;
            });
            return parseFloat(sum).toFixed(2);
        },
        general(item) {
            let details = item.details;
            let cierre;
            let sum = 0;
            let sub = 0;
            details.forEach(element => {
                cierre = JSON.parse(element.transaction[0].json_cierre);
                sub = parseFloat(cierre.totTarjeta) +
                    parseFloat(cierre.totDeposito) +
                    parseFloat(cierre.totTransferencia) +
                    parseFloat(cierre.totPlanilla);
                sum += sub;
            });
            return parseFloat(sum).toFixed(2);
        },
        sobrafalta(item) {
            let cierre = JSON.parse(item.details[item.details.length - 1].transaction[0].json_cierre);
            let tot = 0;
            cierre.billetes.forEach(element => {
                tot += parseFloat(element.total);
            });
            cierre.monedas.forEach(element => {
                tot += parseFloat(element.total);
            });
            let tot1 =
                parseFloat(tot) -
                parseFloat(cierre.totEfectivo) -
                parseFloat(cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00')
            return parseFloat(tot1).toFixed(2);
        },
        mtdViewPdfCaja: function (beadId) {
            window.open(
                this.$store.getters.get__url + "/ReportesCajas/viewpdf/cierre/" + beadId,
                "_blank"
            );
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },

};
</script>
