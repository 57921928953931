<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true"
  :booTitile="true">
    <div class="row d-flex mt-2 mb-2" v-if="apro == 0">
        <div class="input-group ">
          <textarea class="form-control form-control-sm" v-model="data_detail.description" placeholder="Descripción" rows="1"></textarea>
          <button type="button" class="btn btn-bm-noradius" @click="registerComen()">
            Agregar Mensaje
          </button>
        </div>
    </div>
    <div class="row mx-0 mt-3">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">N°</th>
                        <th scope="col">Fecha Subida</th>
                        <th scope="col">Mensaje</th>
                        <th scope="col">Subido</th>
                        <th scope="col" v-if="apro  == 0">Acción</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 12px">
                    <tr class="table-body text-center" v-if="cpData.length === 0">
                        <td colspan="7" class="text-center">No hay registros</td>
                    </tr>
                    <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{index + 1}}</td>
                        <td>{{item.date_upload | formatDateTime}}</td>
                        <td>{{item.description}}</td>
                        <td>
                          <div>
                            <span v-if="item.status == 1" class="badge bg-success rounded-pill">RESPONSABLE</span>
                            <span v-else-if="item.status == 0" class="badge bg-info rounded-pill text-black">SUPERVISOR</span>
                          </div>
                        </td>
                        <td v-if="apro == 0">
                          <template v-if="item.status == 0">
                            <a class="pdf text-center px-2 py-2" href="#" @click="deleteComen(item.id)">
                                <i class="fas fa-trash-alt text-white"></i>
                            </a>
                          </template>
                          <template v-else>
                            Sin Acción
                          </template>
                        </td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import moment from "moment";
import {
  mapActions
} from "vuex";

export default {
  name: "c-asignaciones-modal-assig",
  components: {
      CModal,
  },
  data() {
      return {
        data_detail: {
          description: '',
        },
      };
  },
  watch: {
  },
  props: {
      title: String,
      boo_modal: Boolean,
      idAd: Number,
      apro: Number,
      payload: {
        type: Array,
        required: true
      },
  },
  computed: {
    cpData() {
        return this.payload.map(element => element);
      },
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.$emit("closeModalAsignComment");
      },

      registerComen() {
        if (!this.data_detail.description) {
          Swal.fire({
            text: "Ingrese una Descripción",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
          return;
        }
        this.$emit('registerComen', this.data_detail, this.idAd, this.apro);
        this.data_detail.description = '';
      },

      deleteComen(id) {
        Swal.fire({
            title: "¿Estas seguro de Eliminar este Mensaje?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
            width: "400px",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit("deleteComen", id , this.idAd, this.apro);
            }
          });
      },

  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>
<style>

.pdf {
    background-color: #900052;
    border-radius: 10px;
    width: 15px;
    font-size: 12px;
}
</style>
