<template>
  <div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <div class="row">
            <div class="col-12 mt-3 mb-4 d-flex justify-content-end">
              <button type="button" class="btn btn-outline-main text-white" @click="changePage('home')">
                <i class="fas fa-home-lg-alt"></i> Inicio
              </button>
            </div>
          </div>
          <div class="card">
            <div class="card-header text-white h4 text-center" style="background: #900052">
              <strong>REPORTE DE INGRESOS</strong>
            </div>
            <div class="card-body">
              <div class="row d-flex g-3">
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text">Desde</span>
                    <input type="date" class="form-control" id="begindate" v-model="filter.begindate"/>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text">Hasta</span>
                    <input type="date" class="form-control" id="enddate" v-model="filter.enddate"/>
                  </div>
                </div>
                <div class="col-12 col-md-4 col-lg-2 text-center">
                  <button type="button" name="accion" class="btn btn-sm btn-register text-white w-100" @click="mtdGetData">
                    <i class="fas fa-search"></i> Buscar
                  </button>
                </div>
                <div class="col-12 col-md-4 col-lg-2 text-center">
                  <button data-bs-dismiss="modal" aria-label="Close" type="button" name="accion" class="btn btn-sm btn-register text-white w-100" @click="exportExcel">
                    <i class="fas fa-file-excel"></i> Exportar
                  </button>
                </div>
                <div class="col-12 col-md-4 col-lg-2 text-center">
                  <button type="button" name="accion" class="btn btn-sm btn-register text-white w-100" @click="downloadXML">
                    <i class="fas fa-file-download"></i> Descargar XML
                  </button>
                  <Loading v-show="loading"></Loading>
                </div>
                <div class="col-12 py-2">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" style="background: #900052"><i class="fas fa-search"></i></span>
                    <input v-model="search" type="text" class="form-control" placeholder="Nombre y Apellido"/>
                  </div>
                </div>
                <!-- Tabla -->
                <div class="col-12">
                  <div class="table-responsive" id="dtb-cxc">
                    <table id="dtb-cxc" class="table table-bordered border-main">
                      <thead>
                        <tr class="text-white border-white text-center text-xs" style="background: #900052">
                          <th scope="col">N°</th>
                          <th scope="col">CUENTA CONTABLE</th>
                          <th scope="col">TIPO CLIENTE</th>
                          <th scope="col">COD. ANEXO</th>
                          <th scope="col">ACREEDOR</th>
                          <th scope="col">NOM. ACREEDOR</th>
                          <th scope="col">DNI PACIENTE</th>
                          <th scope="col">NOM. PACIENTE</th>
                          <th scope="col">TIPO DOCUMENTO</th>
                          <th scope="col">N° DOCUMENTO</th>
                          <th scope="col">FECH. EMISION</th>
                          <th scope="col">FECH. VENCIMIENTO</th>
                          <th scope="col">FECH. COMPUTO DE COBRO</th>
                          <th scope="col">FECH. DE INICIO DE SERVICIO</th>
                          <th scope="col">FECH. DE FIN DE SERVICIO</th>
                          <th scope="col">COD. EPS</th>
                          <th scope="col">PERIODO FACTURO</th>
                          <th scope="col">PERIODO DEVENGADO</th>
                          <th scope="col">REFERENCIA CON GARANTIA</th>
                          <th scope="col">CONTROL DE GARANTIAS</th>
                          <th scope="col">TIPO ANEXO</th>
                          <th scope="col">CLASIFICACION BM</th>
                          <th scope="col">DESCRIPCION</th>
                          <th scope="col">CANTIDAD</th>
                          <th scope="col">BASE IMPONIBLE GRAVADA</th>
                          <th scope="col">BASE IMPONIBLE NO GRAVADA</th>
                          <th scope="col">IGV</th>
                          <th scope="col">TOTAL FACTURADO</th>
                          <th scope="col">IMPORTE AFECTO A RENTA</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="dataShow.length === 0">
                          <td class="text-center" colspan="29">{{ noRecordsMessage }}</td>
                        </tr>
                        <tr class="" v-for="(item, index) in cpData" :key="index">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td class="text-center">{{ "-" }}</td>
                          <td class="text-center">{{ item.sale.type_patient || "-" }}</td>
                          <td class="text-center">C</td>
                          <td class="text-center">{{ item.sale.voucher[0].vaucher_document || "-" }}</td>
                          <td class="text-center">{{item.sale.voucher[0].vaucher_fullname.toUpperCase() || "-"}}</td>
                          <td class="text-center">{{ item.patient.document || "-" }}</td>
                          <td class="text-center">{{ item.patient.name || "-" }}</td>
                          <td class="text-center">{{ item.sale.voucher[0].document || "-" }}</td>
                          <td class="text-center">{{item.sale.voucher[0].serie + "-" + item.sale.voucher[0].number || "-" }}</td>
                          <td class="text-center">{{ item.sale.voucher[0].date || "-" }}</td>
                          <td class="text-center">{{ item.sale.voucher[0].date || "-" }}</td>
                          <td class="text-center">{{ item.sale.voucher[0].date || "-" }}</td>
                          <td class="text-center">{{ item.sale.date_ind || "-" }}</td>
                          <td class="text-center">{{ item.sale.date_out || "-" }}</td>
                          <td class="text-center">{{ "-" }}</td>
                          <td class="text-center">{{ item.sale.voucher[0].billing_period || "-" }}</td>
                          <td class="text-center">{{ "-" }}</td>
                          <td class="text-center">{{ item.sale.type == "garantia" ? "SI" : "NO" }}</td>
                          <td class="text-center">{{item.sale.voucher[0].canje === 0 && item.sale.type === "garantia" ? "GARANTIA" : item.sale.type !== "garantia" ? "-" : "APLICACION"}}</td>
                          <td class="text-center">{{ item.sale.type == "garantia" ? "001" : "002" }}</td>
                          <td class="text-center">{{ item.subsub_categorie.subategorie.categorie.name }}</td>
                          <td class="text-center">{{ item.subsub_categorie.name }}</td>
                          <td class="text-center">{{ item.quantity }}</td>
                          <td class="text-center">{{((Number(item.quantity) * Number(item.price)) / 1.18).toFixed(2)}}</td>
                          <td class="text-center">0</td>
                          <td class="text-center">{{(Number(item.quantity) * Number(item.price) - (Number(item.quantity) * Number(item.price)) / 1.18).toFixed(2)}}</td>
                          <td class="text-center">{{(Number(item.quantity) * Number(item.price)).toFixed(2)}}</td>
                          <td class="text-center">{{((Number(item.quantity) * Number(item.price)) / 1.18).toFixed(2)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Paginación -->
                <div class="d-flex justify-content-center mb-4" style="align-items: center">
                  <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a  class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                      </li>
                      <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                      </li>
                      <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                      </li>
                      <li v-if="limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length " class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)" >{{ stepPagination.length }}</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapActions } from "vuex";
import moment from "moment";
import exportFromJson from "export-from-json";
import Loading from '@/components/loading/Modal.vue';

export default {
  name: "c-administracion-ingreso",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      search: "",
      dataShow: [],
      dataCxcs: [],
      selectPage: 1,
      noRecordsMessage: "",
      filter: {
        begindate: null,
        enddate: null,
      },
      modalSunat: {
        action: "",
        title: "",
        modal_form: false,
        item: [],
        garantias: [],
        doc: {},
      },
      stepPagination: [],
      totalPagesToShow: 10,
      page: 0,
      excelCxcs: [],
    };
  },
  computed: {    
    cpData() {
      let filteredData = this.dataCxcs;
      if (this.search.length >= 2 && this.search != "") {
        let query = this.search.toString().toUpperCase();
        filteredData = this.dataCxcs.filter((element) => {
          let name = element.patient.name.toString().toUpperCase();
          return name.includes(query) || element.sale.voucher[0].number.includes(query);
        });
      }
      /** paginación */
      let totalPages = Math.ceil(filteredData.length / 10);
      this.stepPagination = Array.from(
        { length: totalPages },
        (_, index) => index + 1
      );
      let startIndex = (this.selectPage - 1) * 10;
      let endIndex = startIndex + 9;
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      return this.dataShow;
    },

    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
  },
  created() {
    // this.mtdGetData();
    moment.locale("es");
    this.filter.begindate =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-01";
    this.filter.enddate =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
  },
  methods: {
    ...mapActions(["get", "post"]),
    //metodo obtener data
    mtdGetData: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/Administracion/ingreso/" +
          this.$store.getters.get__campus,
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          // console.log(response.data);
          this.dataCxcs = response.data;
          if (this.dataCxcs.length === 0) {
            this.noRecordsMessage = "No se encontraron resultados.";
          } else {
            this.noRecordsMessage = "";
            this.selectPage = 1;
          }
          this.dataCxcs.forEach((element, index) => {
            this.excelCxcs.push({
              "N°": index + 1,
              "CUENTA CONTABLE":
                '<div style="text-align: center;">' + "-" + "</div>",
              "TIPO CLIENTE":
                '<div style="text-align: center;">' +
                  element.sale.type_patient || "-" + "</div>",
              "COD. ANEXO":
                '<div style="text-align: center;">' + "C" + "</div>",
              ACREEDOR:
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].vaucher_document || "-" + "</div>",
              "NOM. ACREEDOR":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].vaucher_fullname.toUpperCase() ||
                "-" + "</div>",
              "DNI PACIENTE":
                '<div style="text-align: center;">' +
                  element.patient.document || "-" + "</div>",
              "NOM. PACIENTE":
                '<div style="text-align: center;">' + element.patient.name ||
                "-" + "</div>",
              "TIPO DOCUMENTO":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].document || "-" + "</div>",
              "N° DOCUMENTO":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].serie +
                  "-" +
                  element.sale.voucher[0].number || "-" + "</div>",
              "FECH. EMISION":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].date || "-" + "</div>",
              "FECH. VENCIMIENTO":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].date || "-" + "</div>",
              "FECH. COMPUTO DE COBRO":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].date || "-" + "</div>",
              "FECH. DE INICIO DE SERVICIO":
                '<div style="text-align: center;">' +
                (element.sale.date_ind !== null ? element.sale.date_ind : "-") +
                "</div>",
              "FECH. DE FIN DE SERVICIO":
                '<div style="text-align: center;">' +
                (element.sale.date_out !== null ? element.sale.date_out : "-") +
                "</div>",
              "COD. EPS": '<div style="text-align: center;">' + "-" + "</div>",
              "PERIODO FACTURO":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].billing_period || "-" + "</div>",
              "PERIODO DEVENGADO":
                '<div style="text-align: center;">' + "-" + "</div>",
              "REFERENCIA CON GARANTIA":
                '<div style="text-align: center;">' + element.sale.type ==
                "garantia"
                  ? "SI"
                  : "NO" + "</div>",
              "CONTROL DE GARANTIAS":
                '<div style="text-align: center;">' +
                  element.sale.voucher[0].canje ===
                  0 && element.sale.type === "garantia"
                  ? "GARANTIA"
                  : (element.sale.type !== "garantia" ? "-" : "APLICACION") +
                    "</div>",
              "TIPO ANEXO":
                '<div style="text-align: center;">' +
                (element.sale.type === "garantia" ? `'001'` : `'002'`) +
                "</div>",
              "CLASIFICACION BM":
                '<div style="text-align: center;">' +
                element.subsub_categorie.subategorie.categorie.name +
                "</div>",
              DESCRIPCION:
                '<div style="text-align: center;">' +
                element.subsub_categorie.name +
                "</div>",
              CANTIDAD:
                '<div style="text-align: center;">' +
                element.quantity +
                "</div>",
              "BASE IMPONIBLE GRAVADA":
                '<div style="text-align: center;">' +
                (
                  (Number(element.quantity) * Number(element.price)) /
                  1.18
                ).toFixed(2) +
                "</div>",
              "BASE IMPONIBLE NO GRAVADA":
                '<div style="text-align: center;">' + 0 + "</div>",
              IGV:
                '<div style="text-align: center;">' +
                (
                  Number(element.quantity) * Number(element.price) -
                  (Number(element.quantity) * Number(element.price)) / 1.18
                ).toFixed(2) +
                "</div>",
              "TOTAL FACTURADO":
                '<div style="text-align: center;">' +
                (Number(element.quantity) * Number(element.price)).toFixed(2) +
                "</div>",
              "IMPORTE AFECTO A RENTA":
                '<div style="text-align: center;">' +
                (
                  (Number(element.quantity) * Number(element.price)) /
                  1.18
                ).toFixed(2) +
                "</div>",
            });
          });

          /** paginación */
          let cantidad = parseInt(this.dataCxcs.length / 10);
          let residuo = parseInt(this.dataCxcs.length % 10);
          cantidad = residuo > 0 ? cantidad + 1 : cantidad;
          for (let index = 0; index < cantidad; index++) {
            this.stepPagination.push(index + 1);
          }
        })
        .catch((errors) => {});
    },
    exportExcel: function () {
      const datos = this.excelCxcs;
      const fileName = "INGRESOS";
      const typeExport = exportFromJson.types.xls;
      const formatting = {
        sheet: {
          defaultCellStyle: {
            alignment: {
              horizontal: "center",
            },
          },
        },
      };
      exportFromJson({
        data: datos,
        fileName: fileName,
        exportType: typeExport,
        formatting: formatting,
      });
    },

    async downloadXML() {
      const { begindate, enddate } = this.filter;
      if (!begindate || !enddate) {
        alert('Por favor, seleccione las fechas de inicio y fin.');
        return;
      }

      try {
        this.loading=true;
        // const response = await fetch('http://127.0.0.1:8000/api/downloadXML/', {   //Local
        const response = await fetch('https://drfdownloadxml.onrender.com/api/downloadXML/', {
        
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.$store.state.token}`
          },
          body: JSON.stringify({ start_date: begindate, end_date: enddate })
        });

        if (!response.ok) {
          throw new Error('Error en la descarga del archivo XML');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `xml_files_${begindate}_${enddate}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error al descargar el archivo XML:', error);
      } finally{
        this.loading=false;
      }
    },

    //Paginación
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length
          ? this.selectPage + 1
          : this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
    addPAge: function () {
      this.selectPage =
        this.selectPage == this.stepPagination.length
          ? this.stepPagination.length
          : this.selectPage + 1;
    },
    /** coponent */
    changePage(page) {
      this.$emit("changePage", page);
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdBackRol: function () {
      this.$emit("mtdBackRol");
    },
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
  },
};
</script>
  
<style scoped>
input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn-register,
.btn-outline-main {
  background: #900052 !important;
}
.btn-register:hover,
.btn-outline-main:hover {
  background: #72003b !important;
}
.border-main {
  border-color: #900052 !important;
}

.table-responsive {
  overflow-x: auto;
}
</style>