import { render, staticRenderFns } from "./Tareas.vue?vue&type=template&id=0fcc1f21"
import script from "./Tareas.vue?vue&type=script&lang=js"
export * from "./Tareas.vue?vue&type=script&lang=js"
import style0 from "./Tareas.vue?vue&type=style&index=0&id=0fcc1f21&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports