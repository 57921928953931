import Vue from 'vue'
import Router from "vue-router";

import HomeView from '../views/HomeView.vue'
import LoginView from "../views/Login.vue"

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: '',
    component: LoginView
  },
  {
    path: '/home',
    name: 'inicio',
    component: HomeView
  },
  {
    path: '/admision',
    name: 'admision',
    component: HomeView
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: HomeView
  },
  {
    path: '/gerencia',
    name: 'gerencia',
    component: HomeView
  },
  {
    path: '/jefatura',
    name: 'jefatura',
    component: HomeView
  },
  {
    path: '/medico',
    name: 'medico',
    component: HomeView
  },
  {
    path: '/enfermera',
    name: 'enfermera',
    component: HomeView
  },
  {
    path: '/farmacia',
    name: 'farmacia',
    component: HomeView
  },
  {
    path: '/facturacion',
    name: 'facturacion',
    component: HomeView
  },
  {
    path: '/director',
    name: 'director',
    component: HomeView
  },
  {
    path: '/logistica',
    name: 'logistica',
    component: HomeView
  },
  {
    path: '/tesoreria',
    name: 'tesoreria',
    component: HomeView
  },
  {
    path: '/administracion',
    name: 'administracion',
    component: HomeView
  },
  {
    path: '/rh',
    name: 'rh',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
];

export default new Router({
  mode: 'hash',
  // base: process.env.BASE_URL,
  // mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes
})
