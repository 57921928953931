<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
    <div class="dropdown-header" @click="toggleDropdown()">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='HistoriaUci'">
                <HistoriaUci :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory">
                </HistoriaUci>
            </div>
            <div id="myTabContentHijo" v-if="component=='Evolucionmedica'">
                <Evolucionmedica></Evolucionmedica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Rp'">
                <Rp></Rp>
            </div>
            <div id="myTabContentHijo" v-if="component=='Hojagrafica'">
                <Hojagrafica></Hojagrafica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Interconsulta'">
                <Interconsulta></Interconsulta>
            </div>
            <div id="myTabContentHijo" v-if="component=='Notaenfermeria'">
                <Notaenfermeria></Notaenfermeria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Kardex'">
                <Kardex></Kardex>
            </div>
            <div id="myTabContentHijo" v-if="component=='Oxigeno'">
                <Oxigeno></Oxigeno>
            </div>
            <div id="myTabContentHijo" v-if="component=='Bme'">
                <Bme></Bme>
            </div>
            <div id="myTabContentHijo" v-if="component=='Monitoreohemodinamico'">
                <Monitoreohemodinamico></Monitoreohemodinamico>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import HistoriaUci from '@/components/Asistencial/Ucineo/Historia/Hcucineo.vue'
import Evolucionmedica from '@/components/Asistencial/Ucineo/Historia/Emedica.vue'
import Rp from '@/components/Asistencial/Ucineo/Historia/Rp.vue'
import Hojagrafica from '@/components/Asistencial/Ucineo/Historia/Hojagrafica.vue'
import Notaenfermeria from '@/components/Asistencial/Ucineo/Historia/Notaenfermeria.vue'
import Kardex from '@/components/Asistencial/Ucineo/Historia/Kardex.vue'
import Oxigeno from '@/components/Asistencial/Ucineo/Historia/Oxigeno.vue'
import Bme from '@/components/Asistencial/Ucineo/Historia/Bme.vue'
import Monitoreohemodinamico from '@/components/Asistencial/Ucineo/Historia/Mhemodinamico.vue'

export default {
    name: 'dropdown-component',
    props: {
        title: String,
        component: String,
        viewMedico: Number,
        dataPlan: Array,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        HistoriaUci,
        Evolucionmedica,
        Rp,
        Hojagrafica,
        Notaenfermeria,
        Kardex,
        Oxigeno,
        Bme,
        Monitoreohemodinamico
    },
    data: () => ({
        dropdown: false
    }),
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },
    }
}
</script>
