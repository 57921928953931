<template>
    <div>
      <div class="container" v-if="page == 'venta'">
        <div class="d-flex title-option">
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
          <div id="div-cxc" class="card w-100">
              <div class="text-center card-header text-white h6 bg-main">
                  <strong>MEDICAMENTOS SELECCIONADOS</strong>
              </div>
              <div class="card-body w-100">
                  <div class="row">
                      <div class="col-md-12 mt-2" id="content-timelinetable">
                          <table id="tb-cxc" class="table table-bordered border-main">
                              <caption class="py-0 my-0"></caption>
                              <thead>
                                  <tr class="text-center text-white border-white bg-main align-middle">
                                      <th scope="col">MEDICAMENTO</th>
                                      <th scope="col">CANTIDAD</th>
                                      <th scope="col">UBICACIÓN</th>
                                      <th scope="col">ACCIÓN</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr v-if="datos.length === 0">
                                  <td colspan="4" class="text-center">No hay registros</td>
                                </tr>
                                <template v-else>
                                    <tr  v-for="(item, index) in datos" :key="index" class="text-center align-middle font-size">
                                        <td class="table-td-state">{{ item.producto }}</td>
                                        <td class="table-td-state">{{ calculateTotalQuantityDispatch(item.tabla) }}</td>
                                        <td class="table-td-state">{{ item.location ? item.location : '-'}}</td>
                                        <td>
                                            <a class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052;"
                                              @click="mtdDelete(item.idProducto)">
                                              <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </template>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div class="d-flex mt-2 justify-content-end">
                <button class="bg-main border-0 text-white px-4 py-1" @click="addmedicament()">SIGUIENTE</button>
              </div>
          </div>
        </div>
      </div>
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
  <script>
  import { mapActions } from "vuex";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  export default {
    name: "c-Pharmacy-cCarroDispath",
    components: {
      SweetAlert,
    },
    props: {
        datos: [],
        recipeId: Number,
        patientId: Number,
        saleId: Number,
        destino: String,
    },
    data() {
      return {
        swal: null,
        page: "venta",
        view: "table",
        posEditPrice: null,
        componentKey:0,
        castCarrito: [],
        permissionValue: null,
        file: null,
      };
    },
    created() {
          this.datos.forEach(item => {
            if (!item.priceMultiplied) {
                item.price *= 1.18;
                item.price = item.price.toFixed(2);
                item.priceMultiplied = true;
            }
        });
    },
    computed: {
    },
    methods: {
      ...mapActions(["get", "post"]),

        returnHome: function () {
            this.$emit("mtdBack");
        },

        calculateTotalQuantityDispatch(tabla) {
            let total = 0;
            if (tabla && Array.isArray(tabla)) {
                tabla.forEach(obj => {
                    if (obj.dates && Array.isArray(obj.dates)) {
                        obj.dates.forEach(dateObj => {
                            total += parseFloat(dateObj.quantity_dispath) || 0;
                        });
                    }
                });
            }
            return total;
        },

       mtdDelete: function (item) {
        this.$emit("mtdDelete", item);
       },

       mtdChangePrice: function (pos) {
        this.posEditPrice = pos;
      },

      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },

      mtdOutFocusEditPrice: function () {
        this.posEditPrice = null;
      },

      verifyZero: function () {
        const todosMayoresQueCero = this.datos.every(
          (item) => parseFloat(item.price) > '0.00'
        );
        if (todosMayoresQueCero) {
          this.mtdNextStep("2");
          return true;
        } else {
          this.$refs.SweetAlert.showWarning("Hay valores iguales o menores que 0.00");
          return false;
        }
      },

      addmedicament() {
        if (!this.datos || Object.keys(this.datos).length === 0) {
            this.$refs.SweetAlert.showWarning("No hay Medicamentos para Despachar");
            return;
        }
        Swal.fire({
          icon: "warning",
          title: "¿Está seguro de despachar el medicamento?",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
            placeholder: "NOTA",
          },
          width: "400px",
          confirmButtonText: "Permitir",
          confirmButtonColor: "#3085d6",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cerrar",
        }).then((result) => {
          if (result.isConfirmed) {
            const note = result.value;
            this.post({
              url: this.$store.getters.get__url + "/Pharmacy/dispatchMedicament",
              token: this.$store.getters.get__token,
              params: {
                data: this.datos,
                campu_id: this.$store.getters.get__campus,
                recipe_id: this.recipeId,
                patient_id: this.patientId,
                sale_id: this.saleId,
                note: note,
                destino: this.destino,
              },
            })
              .then((response) => {
                if (response.data.state == 0) {
                  //this.$emit("mtdMediDespachado", response.data.recipedetil);
                  this.$emit("mtdMediDespachado", response.data.idRecipe);
                  this.$refs.SweetAlert.showSuccess("Medicamento Despachado");
                  window.open(
                      this.$store.getters.get__url + "/Pharmacy/recetasDispathPdf/" + response.data.idDis,
                      "_blank"
                  );
                }

              })
              .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
              });
          }
        });
      },

    },
  };
  </script>
 <style scoped>
 @media (max-width: 992px) {
   .flex-row {
     flex-direction: column !important;
     align-items: center;
     padding-top: 20px !important;
   }
 
   #div-listcola {
     width: 300px !important;
     margin-bottom: 20px !important;
   }
 }
 .bootom {
   background-color: #900052 !important; 
   border: 0.5px solid #900052 !important;
 }
 .font-size {
  font-size: 12px !important;
 }

 </style>