<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" :bead="bead" />
    </div>
    <div class="content">
        <Open v-if="verifBead == false" :boxes="boxes" @mtdOpen="mtdOpen" @mtdBackCampus="mtdBackCampus" />
        <div v-if="verifBead == true">
            <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
            <Venta v-if="page=='venta'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" :bead="bead" :client="client" @mtdCommitPayment="mtdCommitPayment" />
            <Close v-if="page=='close'" @mtdChangePage="mtdChangePage" :bead="bead" :originPage="prefix" @mtdBackCampus="mtdBackCampus" />
            <Egresos v-if="page == 'egreso'" @mtdChangePage="mtdChangePage" :bead="bead" />
            <Anular v-if="page == 'anular'" @returnHome="returnHome" />
            <Vueltos v-if="page == 'vueltos'" @mtdChangePage="mtdChangePage" :bead="bead" @mtdCommitBead="mtdCommitBead" />
            <Cxc v-if="page == 'cxc'" @mtdChangePage="mtdChangePage" :bead="bead" @mtdCommitBead="mtdCommitBead" />
            <ConsultarDocumento v-if="page == 'consultar'" @returnHome="returnHome" />
            <Planilla v-if="page == 'planillas'" @mtdChangePage="mtdChangePage" @mtdCommitBead="mtdCommitBead" />
            <RendirEgreso v-if="page == 'rendirEgresos'" @returnHome="returnHome" />
            <Cierre v-if="page == 'cierre_caja'" @mtdChangePage="mtdChangePage" />
            <Cola v-if="page == 'cola'" @mtdChangePage="mtdChangePage" />
            <Cxp v-if="page == 'cxp'" @mtdChangePage="mtdChangePage" :bead="bead" />
            <Planes v-if="page == 'plan'" @mtdChangePage="mtdChangePage" />
            <Pasajes v-if="page == 'pasajes'" @returnHome="returnHome" />
        </div>
    </div>
    <div class="footer">
        <Footer v-if="verifBead == true" @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Nav from '../../components/Admision/Nav.vue'
import Home from '../../components/Admision/Home.vue'
import Footer from '../../components/Admision/Footer.vue'
import Open from '../../components/Admision/pages/Open.vue'
import Close from '../../components/Admision/pages/ReportClose.vue'
import Venta from '../../components/Admision/pages/Venta.vue';
import Egresos from "../../components/Admision/pages/EgresoView.vue";
import Anular from "../../components/Admision/pages/Anular.vue";
import Vueltos from "../../components/Admision/pages/Vueltos.vue";
import Cxc from "../../components/Admision/pages/Cxc.vue";
import RendirEgreso from "@/components/Admision/pages/EgresoEdit.vue";
import ConsultarDocumento from "@/components/Admision/pages/ConsultarDocumento.vue";
import Planilla from "../../components/Admision/pages/Planilla.vue";
import Cierre from "../../components/Admision/pages/Cierre.vue";
import Cxp from "../../components/Admision/pages/CuentasxPagar.vue";
import Cola from "../../components/Admision/pages/Cola.vue";
import Planes from "../../components/Admision/pages/Planes.vue";
import Pasajes from "../../components/Admision/pages/PasajesList.vue";
export default {
    name: "AsistencialView",
    components: {
        Nav,
        Home,
        Footer,
        Open,
        Close,
        Egresos,
        Venta,
        Anular,
        Vueltos,
        Cxc,
        Cola,
        Planilla,
        Cierre,
        ConsultarDocumento,
        RendirEgreso,
        Cxp,
        Planes,
        Pasajes
    },
    data() {
        return {
            prefix: 'Admision',
            page: 'home',
            rol_name: null,
            stateRolAdmin: false,
            verifBead: false,
            bead: {},
            client: null,
            boxes: []
        }
    },
    props: {
        rolname: String,
        dataRoles: Array
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdDataBead();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function (page, client) {
            this.client = page == "venta" ? client : page == "home" ? this.client : null;
            this.page = page;
        },
        returnHome: function () {
            this.page = "home";
        },
        mtdDataBead() {
            this.get({
                    url: this.$store.getters.get__url +
                        "/" +
                        this.prefix +
                        "/listBeads/" +
                        this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.boxes = response.data.boxes;
                    this.bead = response.data.bead;
                    this.verifBead = response.data.bead != null ? true : false;
                    console.log(this.verifBead);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        /** apertura de caja*/
        mtdOpen: function (data) {

            this.bead = data;
            this.verifBead = true;
            this.page = "home";
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdCommitBead: function (res) {
            this.bead = res;
        },
        /** confirma pago */
        mtdCommitPayment: function (res) {
            this.bead = res;
            this.page = "home";
        },
    }

}
</script>
