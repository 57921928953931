<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
      <div class="px-3">
          <div class="row d-flex mx-0 px-0">
              <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                  <label for="name" class="form-label">Nombre</label>
                  <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder="Nombre">
              </div>
              <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                  <label class="form-label">Prioridad</label>
                  <div class="d-flex justify-content-center">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="priority"
                        id="priority1"
                        value="1"
                        v-model="data_detail.priority">
                      <label class="form-check-label" for="priority1">Alta</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="priority"
                        id="priority2"
                        value="2"
                        v-model="data_detail.priority">
                      <label class="form-check-label" for="priority2">Media</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="priority"
                        id="priority3"
                        value="3"
                        v-model="data_detail.priority">
                      <label class="form-check-label" for="priority3">Baja</label>
                    </div>
                  </div>
              </div>
          </div>
          <div class="row d-flex mx-0 px-0 mb-1">
            <div class="mb-2 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="description" class="form-label">Descripción</label>
                <textarea class="form-control form-control-sm" v-model="data_detail.description" placeholder="Descripción" rows="1"></textarea>
            </div>
            <div class="mb-2 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="datetime" class="form-label">Fecha de Expiración</label>
                <input type="datetime-local" class="form-control form-control-sm" v-model="data_detail.date_expiration" placeholder="Fecha Expiración">
            </div>
            <div class="mb-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="extension_days" class="form-label">Días Extensión</label>
                <input type="number" id="extension_days" class="form-control form-control-sm" v-model="data_detail.days_extension" min="0" placeholder="Días"  @input="validateExtensionDays">
            </div>
          </div>
          <div class="row mx-0 px-0 mb-1">
              <div class="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="extension_days" class="form-label">Supervisor Encargado</label>
                <div class="d-flex align-items-center">
                    <v-select
                      :options="subSupervisor"
                      class="text-dark flex-grow-1"
                      :reduce="(name) => name.id"
                      label="name"
                      placeholder="-- Seleccione Supervisor --"
                      v-model="data_detail.subSupervisor_id"
                    />
                </div>
            </div>
          </div>
          <div class="row d-flex mx-0 px-">
            <div class="mb-2 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="extension_days" class="form-label">Responsables</label>
                <div class="d-flex align-items-center">
                    <v-select
                        class="text-dark flex-grow-1"
                        label="name"
                        :options="servicesPa"
                        placeholder="-- Buscar Dni o Nombre --"
                        @search="searchPa"
                        v-model="selectedPa"
                        :reduce="(name) => name.id"
                        required
                        :clearable="false"
                    >
                        <template slot="no-options">
                            No hay Responsables para la busqueda.
                        </template>
                    </v-select>
                    <button type="button" class="btn btn-bm-noradius custom-button ml-2" @click="addResponsable()">
                      <i class="fas fa-solid fa-plus"></i>
                    </button>
                </div>
            </div>
            <div class="mb-2 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1">
              <div class="col-md-12 table-responsive" id="div-tabla-anular">
                  <table id="tb-anular" class="table table-bordered">
                      <caption class="mx-0 px-0"></caption>
                      <thead class="bg-main" style="font-size: 13px">
                          <tr class="text-white text-center align-middle">
                            <th scope="col">N°</th>
                            <th scope="col">Responsables</th>
                            <th scope="col">Acción</th>
                          </tr>
                      </thead>
                      <tbody class="border-main" style="font-size: 10px">
                        <tr v-if="res.length === 0" class="text-center">
                            <td colspan="3" class="text-center py-2">Sin Responsables</td>
                        </tr>
                        <tr v-for="(responsable, index) in res" :key="responsable.id" class="text-center align-middle">
                            <td>{{ index + 1 }}</td>
                            <td>{{ responsable.supervised_name }}</td>
                            <td class="" style="white-space: nowrap;">
                                <a @click="removeResponsable(index)" class="accion-2 text-center px-2 py-2" href="#">
                                    <i class="fas fa-trash-alt text-white"></i>
                                </a>
                            </td>
                        </tr>
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
      </div>
      <template #footer>
        <fieldset class="btn-group" aria-labelledby="button-group-label">
          <button class="btn btn-primary btn-sm"  @click="openArchivos">
            <strong>Archivos</strong>
          </button>
          <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            <strong>{{ (num == 0) ? 'Registrar' : 'Guardar' }}</strong>
          </button>
        </fieldset>
      </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
  mapActions
} from "vuex";

export default {
  name: "c-maintenance-modal-documento",
  components: {
      CModal,
  },
  data() {
      return {
          servicesPa: [],
          selectedPa: "",
          res: [],
          data_detail: {
              name: '',
              priority: null,
              description: '',
              date_expiration: '',
              days_extension: 0,
              responsables: [],
              subSupervisor_id: '',
          },
      };
  },
  watch: {
      payload: {
          handler(newVal) {
              if(this.num == 1){
                  if (newVal) {
                  this.data_detail = {
                      ...newVal
                  };
                  this.res = [...this.data_detail.responsables];
              }
              }
          },
          immediate: true,
      },
  },
  props: {
      title: String,
      boo_modal: Boolean,
      num: Number,
      idTask: Number,
      user: Number,
      payload: {
          type: Object,
          default: null
      },
      subSupervisor: {
        type: Array,
        default: () => []
      },
  },
  computed: {
      cpButton() {
              return !(this.data_detail.name !='' && this.data_detail.priority != null
               && this.data_detail.description !='' && this.data_detail.date_expiration !='');

      }
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.res = [];
          this.selectedPa = '';
          this.servicesPa =  [];
          this.$emit("closeModal");
      },

      searchPa: function (search, loading) {
        if (search.length > 4) {
          this.servicesPa = [];
          this.selectedPa =  "",
          loading(true);
          this.get({
            url: this.$store.getters.get__url + "/Assignments/supervised/" + search,
            token: this.$store.getters.get__token,
          })
            .then((response) => {
              this.servicesPa = response.data;
              loading(false);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },

      validateExtensionDays() {
        if (this.data_detail.extension_days < 0) {
          this.data_detail.extension_days = 0;
        }
      },

      openArchivos() {
          this.$emit('openArchivos', this.idTask);
      },

      DataDetail() {
        this.data_detail.responsables = [...this.res];
        this.$emit('Datadetail', this.data_detail, this.num);
        this.mtdClose();
      },

      addResponsable() {
         const selectedResponsable = this.servicesPa.find(service => service.id === this.selectedPa);
        if (selectedResponsable) {
            const exists = this.res.some(r => r.id === selectedResponsable.id);
            if (exists) {
                Swal.fire({
                  title: "Este responsable ya está en la Lista",
                  icon: "warning",
                  confirmButtonColor: "rgb(170, 2, 95)",
                });
            } else {
                this.res.push({
                    id: selectedResponsable.id,
                    supervised_name: selectedResponsable.name
                });
                this.selectedPa = '';
            }
        } else {
            Swal.fire({
              title: "Responsable no Encontrado",
              icon: "warning",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
        }
      },

      removeResponsable(index) {
          this.res.splice(index, 1);
      },
  },
};
</script>
<style>
.custom-button {
    font-size: 10px;
    padding: 7px;
    border-radius: 70%;
    border: 1px solid #ccc;
}
.accion-2 {
    background-color: #e50303;
    border-radius: 10px;
    width: 15px;
    font-size: 10px;
}
</style>
