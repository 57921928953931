<template>
<div>
    <div class="px-5 pt-2 pb-5">
        <div id="div-modal-anular" class="d-flex justify-content-center mt-4">
            <div class="card w-100 text-center">
                <div class="card-header text-white bg-main h4">
                    <div style="position: relative;">
                        <div class="">
                            <strong>OTROS PERMISOS</strong>
                        </div>
                        <div class="btn-home-jefatura" style="right: 0;top: 0;position: absolute;">
                            <button type="button" class="btn btn-sm" @click="returnHome">
                                <i class="fas fa-home-lg-alt"></i> Inicio
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <!--tabla-->
                        <div class="col-md-12" id="div-tabla-cxc">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <thead>
                                    <tr class="text-white border-white" style="background: #900052">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">NOMBRE</th>
                                        <th scope="col" class="align-middle">ESTADO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="cpData.length === 0">
                                        <td colspan="3" class="text-center">No hay datos disponibles</td>
                                    </tr>
                                    <tr class="" v-for="(item, index) in cpData" :key="index">
                                        <td class="align-middle">{{ index + 1 }}</td>
                                        <td class="align-middle">
                                            {{ item.name.toUpperCase() }}
                                        </td>
                                        <td class="align-middle">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" :id="'permission_' + index" v-model="item.permission" @change="updatePermission(item)" />
                                                <label class="form-check-label" :for="'permission_' + index">
                                                    {{
                                item.permission ? "SI TIENE PERMISO" : "NO TIENE PERMISO"
                              }}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--pag-->
                        <div v-if="cpData.length > 0" class="d-flex justify-content-center px-1 mb-4">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination mb-1">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="
                    selectPage === 1 ? 'page-link active' : 'page-link'
                  " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="
                    selectPage === item ? 'page-link active' : 'page-link'
                  " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if="
                  limitedStepPagination[
                    limitedStepPagination.length - 1
                  ] <
                  stepPagination.length - 1
                " class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if="
                  limitedStepPagination[
                    limitedStepPagination.length - 1
                  ] !== stepPagination.length
                " class="page-item">
                                        <a :class="
                    selectPage === limitedStepPagination.length + 2
                      ? 'page-link active'
                      : 'page-link'
                  " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

export default {
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            data: [],

            noRecordsMessage: "",
            isChecked: false,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        returnHome: function () {
            this.$emit("returnHome");
        },

        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Jefatura/otrosPermisosJefa",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(7);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        updatePermission(item) {

            this.$refs.SweetAlert.showConfirmSimple2("", "Esta acción cambiará el estado. ¿Estás seguro?", "warning", "Aceptar")
                .then((result) => {
                    if (result.value) {
                        this.post({
                                url: this.$store.getters.get__url + "/Jefatura/cambiarPermisosJefa",
                                token: this.$store.getters.get__token,
                                params: {
                                    id: item.id,
                                    permission: item.permission,
                                },
                            })
                            .then((response) => {
                                this.$refs.SweetAlert.showSuccess(response.message);
                            })
                            .catch((errors) => {
                                this.$refs.SweetAlert.showError(errors);
                            });
                    } else {
                        item.permission = !item.permission;
                    }
                });
        },

        /** pagination */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
};
</script>

<style lang="scss" scoped></style><style>
/*Paginacion*/
.page-link {
    color: black !important;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}

@media (max-width: 800px) {
    #div-tabla-cxc {
        overflow-x: auto;
    }
}

.form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.form-check-input:checked {
    background-color: #900052;
    border-color: #900052;
}

.form-check-input:checked+.form-check-label {
    color: #900052;
}

.form-check-input {
    transform: scale(1.15);
}
</style>
