
<template>
  <div>
    <div id="btn_home" class="c-app align-items-center">
      <div class="d-grid gap-2 mx-auto text-center col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <h2>HOME ASISTENCIAL</h2>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeComponent',
  props: {
  },
  components: {
  },
  data () {
    return {
      page: null,
      swal:'TITULO POR  PROPS',
    }
  },
  created () {
    
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page) return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page) return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>