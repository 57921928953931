<template>
  <div>
    <div class="px-3" v-if="view == 'data'">
      <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
          <button type="button" class="btn btn-outline-main" @click="returnHome">
              <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
      </div>
      <div id="div-modal-cxc" class="d-flex justify-content-center">
          <div id="div-cxc" class="card w-100">
            <div class="d-flex px-4 responsive-search mt-4">
            <div
              v-if="acto.patient.name"
              class="input-group input-group mb-3 custom-margin"
            >
              <span class="input-group-text spam" id="basic-addon1"
                ><strong>PACIENTE</strong></span
              >
              <input
                class="form-control form-control-sm text-center"
                style="background-color: #ffffff; font-size: 15px"
                disabled
                :value="
                acto.patient
                    ? acto.patient.name
                    : ''
                "
              />
            </div>
            <div class="d-flex">
              <div class="input-group-append" style="margin-left: 10px">
                <button
                  @click="mtdAddService()"
                  class="btn btn-primary rounded-circle"
                  type="button"
                  style="background-color: #900052; border: 2px solid #900052"
                >
                  <i class="far fa-cart-plus fa-sm"></i>
                </button>
              </div>

              <div class="input-group-append" style="margin-left: 10px">
                <button
                  @click="mtdOpenDispath()"
                  class="btn btn-primary rounded-circle"
                  type="button"
                  style="background-color: #900052; border: 2px solid #900052"
                >
                <i class="fas fa-solid fa-file-pdf"></i>
                </button>
              </div>
            </div>
          </div>
              <div class="card-body w-100 mb-3">
                  <div class="row">
                      <div class="col-md-12 mt-2" id="content-timelinetable">
                          <table id="tb-cxc" class="table table-bordered border-main">
                              <caption class="py-0 my-0"></caption>
                              <thead>
                                  <tr class="text-center text-white border-white bg-main align-middle">
                                      <th scope="col">N°</th>
                                      <th scope="col">VIA</th>
                                      <th scope="col">DOSIS</th>
                                      <th scope="col">MOLÉCULA</th>
                                      <th scope="col">PRESENTACION</th>
                                      <th scope="col">DURACIÓN</th>
                                      <th scope="col">FRECUENCIA</th>
                                      <th scope="col">TOTAL</th>
                                      <th scope="col">PENDIENTE</th>
                                      <th scope="col">ESTADO</th>
                                      <th scope="col">UBICACIÓN</th>
                                      <th scope="col">ACCIONES</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-if="
                                    recipe === null ||
                                    (recipe &&
                                      recipe.recipe_details &&
                                      recipe.recipe_details.length === 0)
                                  "
                                >
                                  <td colspan="12" class="text-center align-middle">No hay registros</td>
                                </tr>
                                <template v-else>
                                  <tr
                                    v-for="(item, index) in recipe ? recipe.recipe_details : []"
                                    :key="index"
                                    class="text-center align-middle font-size"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.via ? item.via.toUpperCase() : "-" }}</td>
                                    <td>{{ item.dosis ? item.dosis : "-" }}</td>
                                    <td>
                                      {{ item.molecule ? item.molecule.toUpperCase() : "-" }}
                                    </td>
                                    <td>
                                      {{
                                        item.presentation ? item.presentation.toUpperCase() : "-"
                                      }}
                                    </td>
                                    <td>
                                      {{ item.duration ? item.duration.toUpperCase() : "-" }}
                                    </td>
                                    <td>
                                      {{ item.frequency ? item.frequency.toUpperCase() : "-" }}
                                    </td>
                                    <td>
                                      <span v-if="!isEditing(index)">
                                        {{item.total}}
                                      </span>
                                      <input v-else
                                        id="input-cantidad"  class="form-control form-control-sm input-cantidad" style="text-align: center"
                                        type="text"
                                        v-model="editQuantity"
                                        @keyup.enter="saveEdit(index)"
                                        @blur="saveEdit(index)"
                                        @keydown="filterKey"
                                        placeholder="TOTAL"
                                      />
                                    </td>
                                    <td>
                                      {{
                                        item.duration && item.frequency && item.state == 1
                                          ? item.total
                                          : item.pending
                                      }}
                                    </td>
                                    <td class="text-center">
                                      <div class="badge text-bg-danger" v-if="item.state == 1">
                                        <div class=""><strong>SIN ATENDER</strong></div>
                                      </div>
                                      <div
                                        class="badge text-bg-warning"
                                        v-else-if="item.state == 2"
                                      >
                                        <div class="">
                                          <strong>ATENDIDO PARCIAL</strong>
                                        </div>
                                      </div>
                                      <div
                                        class="badge text-bg-success"
                                        v-else-if="item.state == 3"
                                      >
                                        <div class="">
                                          <strong>TOTAL</strong>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {{
                                        item.productdetail && item.productdetail.subsubcategorie
                                        ? item.productdetail.subsubcategorie.location ?
                                        item.productdetail.subsubcategorie.location : "-"
                                        : "-"
                                      }}
                                    </td>
                                    <td class="text-nowrap"> 
                                      <div class="d-flex d-md-block justify-content-center">
                                        <button v-if="item.state != 3 && item.product_id != null"
                                            @click="traerLote(item, index)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                            <i class="far fa-plus"></i>
                                        </button>
                                        <button  v-if="item.state != 1"
                                            @click="verDetalle(item, index)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button v-if="(item.state == 1)"
                                            @click="mtdAddEdit(item, index)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button v-if="(item.state == 1)"
                                            @click="toggleEditing(index)" class="btn btn-primary btn-sm rounded-circle bootom">
                                            <i class="fas fa-triangle"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="container" v-else-if="view == 'carrito'">
      <cCarrito
      ref="modalCarroComponent"
        @mtdBack="mtdBack"
        @mtdSendCarrito="mtdSendCarritoCliEdit"
        :recipeId="carrito.recipeId"
        :molecule="carrito.molecule"
        :dosis="carrito.dosis"
        :presentation="carrito.presentation"
        :name="carrito.name"
        :type="carrito.type"
        :detailId="carrito.detailId"
        :nameMedic="carrito.nameMedic"
        :total="carrito.total"
        :dataSubSubCategories="dataSubSubCategories"
        :data_product_filter="dataSubSubCategories"
      />
    </div>
    <div class="container" v-else-if="view == 'carritoDesp'">
      <cCarritoDesp
        @mtdBack="mtdBack"
        :datos="carroDispath.item"
        :recipeId="carroDispath.recipeId"
        :patientId="carroDispath.patientId"
        :destino="carroDispath.destino"
        :saleId="carroDispath.saleId"
        @mtdDelete="mtdDelete"
        @mtdMediDespachado="mtdMediDespachar"
      />
    </div>
    <cModalLoteVue
    ref="modalLoteComponent"
      :title="modalLote.title"
      :boo_modal="modalLote.boo_modal"
      :item="modalLote.item"
      :total="modalLote.total"
      :producto="modalLote.producto"
      :idProducto="modalLote.idProducto"
      :idRecipeDetail="modalLote.idRecipeDetail"
      :location="modalLote.location"
      @mtdCloseModalLote="mtdCloseModalLote"
      @datos-agregados="handleDatosAgregados"
    />
    <cModalDetalleVue
      :title="modalDeta.title"
      :boo_modal="modalDeta.boo_modal"
      :item="modalDeta.item"
      :producto="modalDeta.producto"
      @mtdCloseModalDeta="mtdCloseModalDeta"
    />
    <cModalMedicDespachadoVue
      :title="modalDispath.title"
      :boo_modal="modalDispath.boo_modal"
      :item="modalDispath.item"
      @mtdCloseModalDispath="mtdCloseModalDispath"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cCarrito from "./cCarroAddPd.vue";
import cCarritoDesp from "./cCarroDispath.vue";
import cModalLoteVue from "../Modals/cModalLoti.vue";
import cModalDetalleVue from "../Modals/cModalDetalle.vue";
import cModalMedicDespachadoVue from "../Modals/cModalMedicDespachado.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
  name: "c-Pharmacy-cHospi",
  components: {
    SweetAlert,
    cCarrito,
    cModalLoteVue,
    cModalDetalleVue,
    cCarritoDesp,
    cModalMedicDespachadoVue
  },
  props: {
    acto: [],
    recipe: [],
    emit: Number,
  },
  data() {
    return {
      swal: null,
      view: "data",
      search: "",
      page: 0,
      sale: {
        id: "",
        patient_id: "",
        total: "",
        pending: "",
        state: "",
      },
      /** carrito */
      carrito: {
        type_patient: "",
        data: [],
        saleId: null,
        amount: [0, 0, 0],
        recipeId: 0,
        molecule: "",
        dosis: "",
        presentation: "",
        name: "",
        type: "",
        detailId: 0,
        nameMedic: 0,
        total: 0,
      },
      doctors: [],
      posEditQuantity: null,
      posEditDespacho: null,
      editedQuantity: "",
      editedDespacho: "",
      modalLote: {
        title: "Seleccionar Lote",
        item: [],
        boo_modal: false,
        pos: null,
        total: null,
        producto: "",
        idProducto: null,
        idRecipeDetail: null,
        price: null,
        id: null,
        location: null,
      },
      modalDeta: {
        title: "Detalles de Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
        producto: "",
      },
      modalDispath: {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      },
      tablaOculta: true,
      posEditPrice: null,

      editingIndex: null,
      editQuantity: '',
      carroDispath: {
        item: [],
        recipeId: null,
        patientId: null,
        destino: null,
        saleId: null,
      },
      dispath: [],
      destino: 'CONSULTAS',
      dataSubSubCategories: [],
    };
  },
  created() {
  },
  computed: {
    isPdfButtonDisabled() {
      return Array.isArray(this.recipe.recipe_details) && this.recipe.recipe_details.length === 0;
    }
  },
  methods: {
    ...mapActions(["get", "post"]),

    //metodo retornar a Home
    returnHome: function () {
      this.$emit("mtdBack", this.emit);
    },

    mtdGetDispath: function() {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/mtdGetDispath",
        token: this.$store.getters.get__token,
        params: {
          recipeId: this.recipe.id,
          patientId: this.recipe.patient_id
        },
      })
        .then((response) => {
          this.dispath = response.data.data;
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    toggleEditing(index) {
      this.editingIndex = index;
      this.editQuantity = this.recipe.recipe_details[index].total.toString();
    },

    isEditing(index) {
      return this.editingIndex === index;
    },

    saveEdit: function (index) {
      if (parseFloat(this.editQuantity) > 0) {
        this.recipe.recipe_details[index].total = parseFloat(this.editQuantity);
      }
      this.editingIndex = -1;
    },

    filterKey: function (e) {
      let b = false;
      b = /^[0-9]$/.test(e.key);
      if (!b && e.key != "Backspace") {
        e.preventDefault();
      }
    },

    mtdAddService: function () {
      this.view = "carritoDesp";
    },

    mtdSendCarritoCliEdit: function (carrito, total, recipeId) {
      this.carrito.data = carrito;
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/carroAddPd",
        token: this.$store.getters.get__token,
        params: this.carrito, total
      })
        .then((response) => {
          if (response.data.state == 0) {
            this.$refs.SweetAlert.showSuccess(response.message);
            this.mtdBack();
            this.$emit("updateDataCon", recipeId, this.recipe);
          } else if(response.data.state == 2){
              this.$refs.SweetAlert.showWarning("Ya se encuentra agregado el medicamento");
          } else {
            this.$refs.SweetAlert.showError("Registro Incorrecto");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    traerLote(item, pos) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/stockProductId",
        params: { productdetail: item.product_id },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 0 && response.data.data.length > 0) {
            this.modalLote.item = response.data.data;
            this.modalLote.total =
              item.duration && item.frequency && item.state == 1
                ? item.total
                : item.pending;
            this.modalLote.producto = item.productdetail.subsubcategorie.name;
            this.modalLote.idProducto = item.product_id;
            this.modalLote.idRecipeDetail = item.id;
            this.modalLote.location = item.productdetail && item.productdetail.subsubcategorie
                                ? item.productdetail.subsubcategorie.location
                                : "-";
            this.modalLote.title =  "Seleccionar Lote: " + item.productdetail.subsubcategorie.name;
            this.modalLote.boo_modal = true;
            this.modalLote.pos = pos;
            this.$nextTick(() => {
              this.$refs.modalLoteComponent.validarAutomaticamente();
              });
          } else {
            this.modalLote.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Stocks");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    verDetalle(item, pos) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recetasDetalle",
        params: { productdetail: item.id },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.res.length > 0) {
            this.modalDeta.item = response.data.res;
            this.modalDeta.producto = response.data.product;
            this.modalDeta.boo_modal = true;
            this.modalDeta.pos = pos;
          } else {
            this.modalDeta.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Medicamentos Despachados");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    mtdCloseModalDeta: function () {
      this.modalDeta = {
        title: "Detalles de Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdCloseModalLote: function (item) {
      this.modalLote = {
        title: "Seleccionar Lote",
        item: [],
        boo_modal: false,
        pos: null,
      };
      if (item && Object.keys(item).length > 0) {
        const index = this.recipe.recipe_details.findIndex((date) => date.id == item.id);
        if (index !== -1) {
          this.recipe.recipe_details[index].state = item.state;
          this.recipe.recipe_details[index].pending = item.pending;
        }
      }
    },

    mtdMediDespacharr: function (data) {
      //this.view = "data";
      if(data){
        this.carroDispath.item = [];
      }
      this.$emit("updateDataCon");
      this.mtdGetDispath();
    },

    mtdMediDespachar: function (data) {
      if(data){
        this.carroDispath.item = [];
      }
      this.view = "data";
      this.$emit("updateDataCon", data,  this.recipe);
      this.mtdGetDispath();
    },

    mtdAddEdit: function (item) {
      this.carrito.saleId = this.recipe.sale_id;
      this.carrito.recipeId = this.recipe.id;
      if(item.type == 2){
        this.carrito.name =  (item.molecule ? item.molecule.toUpperCase() : '');
      }else{
        this.carrito.name =  (item.dosis ? item.dosis.toUpperCase() : '') + ' - ' +(item.molecule ? item.molecule.toUpperCase() : '') + ' - ' + (item.presentation ? item.presentation.toUpperCase() : '');
      }

      this.carrito.molecule = item.molecule ? item.molecule.toUpperCase() : '';
      this.carrito.dosis = item.dosis ? item.dosis.toUpperCase() : '';
      this.carrito.presentation = item.presentation ? item.presentation.toUpperCase() : '';
      this.carrito.type = item.type;

      this.carrito.detailId= item.id;
      this.carrito.nameMedic = item.product_id;
      this.carrito.total = item.total;

      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/mtdAddPd",
        token: this.$store.getters.get__token,
        params: {
          molecule: this.carrito.molecule,
          dosis: this.carrito.dosis,
          presentation: this.carrito.presentation,
          nameMedic: this.carrito.nameMedic,
          type: this.carrito.type,
        },
      })
      .then((response) => {
        if(response.data.data.length > 0){
          this.dataSubSubCategories = response.data.data;
          this.view = "carrito";
        }else{
          this.$refs.SweetAlert.showWarning("Sin Medicamentos Similares");
        }
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },

    handleDatosAgregados(datos) {
      const existe = this.carroDispath.item.some(item => item.idRecipeDetail === datos.idRecipeDetail);

      if (existe) {
          this.$refs.SweetAlert.showWarning("Ya existe agregado ese Medicamento en el Carrito");
          this.mtdCloseModalLote();
      } else {
          this.carroDispath.item.push(datos);
          this.carroDispath.recipeId = this.recipe.id;
          this.carroDispath.patientId = this.acto.patient_id;
          this.carroDispath.destino = this.destino;
          this.carroDispath.saleId =  this.recipe.sale_id;
          this.view = "carritoDesp";
          this.mtdCloseModalLote();
      }
    },

    mtdBack(data) {
      this.view = "data";
      if(data){
        this.carroDispath.item = [];
        this.recipe.recipe_details=data;
      }
    },

    mtdDelete(idProducto) {
      const indice = this.carroDispath.item.findIndex(item => item.idProducto === idProducto);

      if (indice !== -1) {
        this.carroDispath.item.splice(indice, 1);
      }
    },

    mtdCloseModalDispath: function () {
      this.modalDispath = {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdOpenDispath() {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/mtdGetDispath",
        token: this.$store.getters.get__token,
        params: {
          recipeId: this.recipe.id,
          patientId: this.recipe.patient_id
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          this.modalDispath.boo_modal = true;
          this.modalDispath.item = response.data.data;
        } else {
            this.modalDispath.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Medicamentos Despachados");
          }
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },
  },
};
</script>
<style scoped>
@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}
.bootom {
  background-color: #900052 !important; 
  border: 0.5px solid #900052 !important;
}
.spam {
  background: #900052;
  color: #ffffff;
  width: 90px;
  line-height: 20px;
  justify-content: center;
  border-radius: 1rem;
}
.font-size {
  font-size: 12px !important;
 }
</style>