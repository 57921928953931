<template>
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true"> 
  <div class="row d-flex">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <div class="d-flex justify-content-center">
                <div class="col-xl-2 form-check form-check-inline">
                    <input class="form-check-input" type="radio" checked name="flexRadioDefault" @change="mtdSelectType(1)" />
                    <label class="form-check-label"> Aceptar </label>
                </div>
                <div class="col-xl-2 form-check form-check-inline">
                    <input @change="mtdSelectType(2)" class="form-check-input" type="radio" name="flexRadioDefault" />
                    <label class="form-check-label"> Rechazar </label>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <div class="align-left my-1">
                <label for="">Motivo:</label>
            </div>
            <textarea class="form-control form-control-sm px-2" v-model="transaction.motive" placeholder="Motivo" rows="5"></textarea>
            <div id="passwordHelpBlock" class="form-text" v-if="data.days > 3">
                Esta anulación está sujeta a una nota de credito.
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdSendPermission" :disabled="cpDisabled">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";

export default {
    name: "c-gerencia-modal-anular",
    components: {
        CModal,
    },
    data() {
        return {
            transaction: {
                type: 1,
                motive: "",
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        data: {},

    },
    computed: {
        cpDisabled() {
            if (this.transaction.motive.length > 0) return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdSelectType: function (value) {
            this.transaction.type = value;
        },
        mtdSendPermission: function () {
            this.$emit("mtdSendPermission", this.transaction);
            this.transaction = {
                type: 1,
                motive: "",
            };
        },
        mtdclosemodal() {
            this.transaction = {
                type: 1,
                motive: "",
            };
            this.$emit('mtdclosemodal')
        },
        mtdstoredata() {
            this.$emit('mtdstoredata')
        }
    },
};
</script>

