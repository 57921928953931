import { render, staticRenderFns } from "./Venta.vue?vue&type=template&id=62a839f6&scoped=true"
import script from "./Venta.vue?vue&type=script&lang=js"
export * from "./Venta.vue?vue&type=script&lang=js"
import style0 from "./Venta.vue?vue&type=style&index=0&id=62a839f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a839f6",
  null
  
)

export default component.exports