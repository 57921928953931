<template>
<div>
    <div class="px-5 pt-2 pb-5">
        <div class="row">
            <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 bg-main align-center">
                    <strong>REPORTE DE INGRESOS</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex">
                        <div class="col-md-6 col-lg-2 col-sm-6 col-xs-6 mb-2">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text col-md-4">Desde</span>
                                <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.date_start" @change="mtdGetData" />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 col-sm-6 col-xs-6 mb-2">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text col-md-4">Hasta</span>
                                <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                            </div>
                        </div>
                        <div class="col-md-9 col-lg-6 col-sm-12 col-xs-12 mb-2">
                            <div class="input-group input-group-sm">
                                <span class="input-group-text text-white bg-main cursor-pointer" @click="mtdGetData">
                                    <i class="fas fa-search"></i>&nbsp;&nbsp;Filtrar
                                </span>
                                <input type="text" class="form-control form-control-sm" placeholder="Buscar serie , N° o paciente" v-model="search" @input="calculateData(10)" />
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-3 col-sm-12 mb-3">
                            <button type="button" name="accion" class="btn btn-sm mb-3 w-100 bg-main text-white" @click="exportExcel">
                                <i class="fas fa-file-excel"></i> Exportar
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination mb-0 ">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-md-12 mb-4 px-2" id="content-timelinetable">
                            <table class="table table-bordered border-main" aria-describedby="mydesc">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center bg-main text-xxs">
                                        <th style="vertical-align: middle" scope="col">N°</th>
                                        <th style="vertical-align: middle" scope="col">ESTADO</th>
                                        <th style="vertical-align: middle" scope="col">ADM</th>
                                        <th style="vertical-align: middle" scope="col">SEDE</th>
                                        <th style="vertical-align: middle" scope="col">FECHA</th>
                                        <th style="vertical-align: middle" scope="col">COMPROBANTE</th>
                                        <th style="vertical-align: middle" scope="col">A NOMBRE DE</th>
                                        <th style="vertical-align: middle" scope="col">SERIE</th>
                                        <th style="vertical-align: middle" scope="col">NÚMERO</th>
                                        <th style="vertical-align: middle" scope="col">PACIENTE TIPO</th>
                                        <th style="vertical-align: middle" scope="col">CONVENIO</th>
                                        <th style="vertical-align: middle" scope="col">%</th>
                                        <th style="vertical-align: middle" scope="col">
                                            INFORMACIÓN DEL PACIENTE
                                        </th>
                                        <th style="vertical-align: middle" scope="col">CATEGORÍA</th>
                                        <th style="vertical-align: middle" scope="col">
                                            SERVICIO ESPECÍFICO
                                        </th>
                                        <th style="vertical-align: middle" scope="col">CANTIDAD</th>
                                        <th style="vertical-align: middle" scope="col">PRECIO UNI</th>
                                        <th style="vertical-align: middle" scope="col">IGV</th>
                                        <th style="vertical-align: middle" scope="col">SUB-TOTAL SIN IGV</th>
                                        <th style="vertical-align: middle" scope="col">SUB TOTAL</th>
                                        <th style="vertical-align: middle" scope="col">MEDIO DE PAGO</th>
                                        <th style="vertical-align: middle" scope="col">FECHA EMITIDO</th>
                                        <th style="vertical-align: middle" scope="col">ACTUALIZAR</th>
                                        <th style="vertical-align: middle" scope="col">DOCUMENTO</th>
                                    </tr>
                                </thead>
                                <tbody class="text-xxs">
                                    <tr v-if="cpData.length === 0">
                                        <td colspan="24" class="text-center">No se encontraron resultados</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index">
                                        <td>{{ mtdNumber(index) }}</td>
                                        <td>{{ item.state == 1 ? 'ACTIVO' : (item.state == 2 ? (item.count_nc == null ? 'ANULADO' : 'CON NOTA DE CREDITO') : 'Sin Respuesta')}}</td>
                                        <td>{{ item.last_name }} {{ item.user_name }}</td>
                                        <td>{{ item.campus_name }} </td>
                                        <td>{{ item.date | formatDate }} </td>
                                        <td>{{ item.document }} </td>
                                        <td>{{ item.vaucher_document }} {{ item.vaucher_fullname }} </td>
                                        <td>{{ item.serie }} </td>
                                        <td>{{ item.number }} </td>
                                        <td>{{ item.type_patient }} </td>
                                        <td>{{ item.insurance_name != null ?item.insurance_name :"Particular" }} </td>
                                        <td>{{ item.payment_patient }} </td>
                                        <td>{{ item.patient_name }} </td>
                                        <td>{{ item.category_name }} </td>
                                        <td>{{ item.subsubcategory_name }} </td>
                                        <td>{{ item.quantity }} </td>
                                        <td>{{ item.state === 2 ? 0.00 : item.validation == 0 ? item.price : (item.price - parseFloat(item.monto_desc / item.quantity)).toFixed(2) }}</td>
                                        <td>{{ igv(item) }}</td>
                                        <td>{{ sinigv(item) }}</td>
                                        <td>{{ subTotal(item) }}</td>
                                        <td>{{ item.method ? item.method : '-' }}</td>
                                        <td>{{ fechaSunat(item) }}</td>
                                        <td>
                                            <div class="text-center">

                                                <button @click="mtdActualizar(item)" class="btn btn-sm btn-success text-white" :disabled="JSON.parse(item.sunat).json.fecha_de_emision">
                                                    <i class="fa fa-sync"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center">
                                                <button @click="mtdViewPdf(item)" class="btn btn-sm btn-primary text-white">
                                                    <i class="fa fa-file-pdf"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <cModalSunatVue :title="modalSunat.title" :boo_modal="modalSunat.modal_form" :item="modalSunat.item" :garantias="modalSunat.garantias" :doc="modalSunat.doc" @closeModalSunat="closeModalSunat"></cModalSunatVue>

    </div>

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalSunatVue from '../modals/cModalSunat.vue';
import exportFromJson from "export-from-json";

export default {
    name: "c-contabilidad-reporteIngresos",
    components: {
        cModalSunatVue
    },
    data() {
        return {
            filter: {
                date_start: "",
                date_end: "",
                campu_id: "",
                box_id: "",
            },
            type: "INGRESO",
            data: [],
            dataShow: [],
            search: "",
            modalSunat: {
                action: "",
                title: "",
                modal_form: false,
                item: [],
                garantias: [],
                doc: {}
            },
            excel: [],
            //paginado
            totalPagesToShow: 4,
            selectPage: 1,
            stepPagination: [],
        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    created() {
        this.getFirstDayOfMonth();
    },
    computed: {
        //cpData() {}
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        mtdGetData: function () {
            this.filter.box_id = 1;
            this.filter.campu_id = this.$store.getters.get__campus;
            this.post({
                    url: this.$store.getters.get__url + "/Administracion/reportIncome",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(10);
                    this.completedData();
                })
                .catch((errors) => {});
        },
        completedData: function () {
            this.excel = [];
            this.data.forEach((element, index) => {

                let model = element.category_name !=
                    null ? element.category_name :
                    "-";
                let insurance = element.insurance_name != null ?
                    element.insurance_name : "Particular";
                let price = element.state === 2 ? 0.00 : element.validation == 0 ? element.price : (element.price - parseFloat(element.monto_desc / element.quantity));
                let sub_total = (element.quantity * price).toFixed(2);
                let igv = (sub_total - (sub_total / 1.18)).toFixed(2);
                let sin_igv = (sub_total / 1.18).toFixed(2);
                let dateFormat = element.date != null ? moment(element.date).format("DD/MM/YYYY") : "";
                this.excel.push({
                    "N°": index + 1,
                    "ESTADO": element.state == 1 ? 'ACTIVO' : (element.state == 2 ? (element.count_nc == null ? 'ANULADO' : 'CON NOTA DE CREDITO') : 'Sin Respuesta'),
                    "ADMISIONISTA": element.last_name +
                        " " +
                        element.user_name,
                    "SEDE": element.campus_name,
                    "FECHA": dateFormat,
                    "COMPROBANTE": element.document,
                    "A NOMBRE DE": element.vaucher_document +
                        "-" +
                        element.vaucher_fullname,
                    "SERIE": element.serie,
                    "NÚMERO": element.number,
                    "PACIENTE TIPO": element.type_patient,
                    "CONVENIO": insurance,
                    "%": element.payment_patient,
                    "INFORMACIÓN DEL PACIENTE": element.patient_name,
                    "CATEGORÍA": model,
                    "SERVICIO ESPECÍFICO": element.subsubcategory_name,
                    "CANTIDAD": element.quantity,
                    "PRECIO": price,
                    "IGV": igv,
                    "SUBTOTAL SIN IGV": sin_igv,
                    "SUBTOTAL": sub_total,
                    "MEDIO DE PAGO": element.method ? element.method : '-',
                });

            });
        },
        //paginado
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        igv(item) {
            let price = item.state === 2 ? 0.00 : item.validation == 0 ? item.price : (item.price - parseFloat(item.monto_desc / item.quantity));
            let sub_total = (item.quantity * price).toFixed(2);
            let igv = (sub_total - (sub_total / 1.18)).toFixed(2);
            return igv
        },
        sinigv(item) {
            let price = item.state === 2 ? 0.00 : item.validation == 0 ? item.price : (item.price - parseFloat(item.monto_desc / item.quantity));
            let sub_total = (item.quantity * price).toFixed(2);
            let sin_igv = (sub_total / 1.18).toFixed(2);
            return sin_igv;
        },
        subTotal(item) {
            let price = item.state === 2 ? 0.00 : item.validation == 0 ? item.price : (item.price - parseFloat(item.monto_desc / item.quantity));
            let sub_total = (item.quantity * price).toFixed(2);
            return sub_total;
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {

                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let doc = element.vaucher_document.toString().toUpperCase();
                    //let name = element.vaucher_name.toString().toUpperCase();
                    let serie = element.serie.toString().toUpperCase();
                    let number = element.number.toString().toUpperCase();
                    return (
                        doc.includes(query) || serie.includes(query) || number.includes(query)
                    );
                });
                if (filteredData.length > 10) {
                    selectPage = this.selectPage
                } else {
                    selectPage = 1;
                }
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdViewPdf: function (item) {
            let ticket = JSON.parse(item.sunat);
            let enlace = ticket.json.enlace_del_pdf_ticket;
            window.open(
                enlace,
                "_blank"
            );

        },
        fechaSunat: function (item) {
            let ticket = JSON.parse(item.sunat);
            let fecha = ticket.json.fecha_de_emision ? ticket.json.fecha_de_emision : 'Aun no ha sido enviado';
            return fecha;
        },
        closeModalSunat: function () {
            this.modalSunat = {
                action: "",
                title: "",
                modal_form: false,
                item: [],
            };
            this.mtdGetData();
        },
        mtdActualizar: function (item) {

            this.post({
                    url: this.$store.getters.get__url + "/Administracion/consult/" + item.v_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.modalSunat.title = "DOCUMENTOS DE SUNAT";
                    this.modalSunat.modal_form = true;
                    this.modalSunat.item = JSON.parse(response.data);
                    this.modalSunat.doc = item;
                    this.modalSunat.garantias = [];
                    /* if (item.sale.type == 'garantia') {
                        this.dataShow.forEach(element => {
                            if (element.sale.id == item.sale.id) {
                                this.modalSunat.garantias.push({
                                    "tipoDocumento": "02",
                                    "serie": element.serie,
                                    "correlativo": element.number.padStart(8, '0'),
                                    "monto": parseFloat(element.amount).toFixed(2)
                                });
                            }
                        });
                    } */
                })
                .catch((errors) => {});
        },
        exportExcel: function () {
            const datos = this.excel;
            const fileName = "INGRESO";
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("changePage", 'home');
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },

}
</script>
