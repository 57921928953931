<template>
    <div>
        <br>
        <br>
        <br>
        <h1>KARDEX</h1>
    </div>
</template>
<script>
import {mapActions} from 'vuex'
export default{
    name: 'c-asistencial-sop-historia-kardex',
    data() {
        return {
            //property: 'value',
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>