<template>
<div>
    <br>
    <br>
    <h1>COLA DE UCINEO</h1>
</div>
</template>
<script>
     import {mapActions} from 'vuex'
     export default {
        name: 'c-asistencial-ucineo-cola',
        components: {
            
        },
        data(){
            return {
                //page:null
            }
        },
        methods: {
            ...mapActions(['get', 'post']),
        }

     }
</script>