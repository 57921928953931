<template>
  <div>
    <div class="w-100 h-100">
      <Nav />
    </div>
    <div class="content h-100" style="background: white">
      <Home
        v-if="page == 'home'"
        @changePage="changePage"
        @mtdBackCampus="mtdBackCampus"
      />
      <Especialidades v-if="page == 'especialidad'" @returnHome="returnHome" />
      <Subespecialidad v-if="page == 'subespecialidad'" @returnHome="returnHome" />
      <Categoria v-if="page == 'categoria'" @returnHome="returnHome" />
      <Consultorios v-if="page == 'consultorios'" @returnHome="returnHome" />
      <Sop v-if="page == 'SoP'" @returnHome="returnHome" />
      <Schedule v-if="page == 'schedule'" @returnHome="returnHome" />
      <Sede v-if="page == 'sede'" @returnHome="returnHome" />
      <Boxes v-if="page == 'boxes'" @returnHome="returnHome" />
      <Area v-if="page == 'area'" @returnHome="returnHome" />
      <Marca v-if="page == 'marca'" @returnHome="returnHome" />
      <Sector v-if="page == 'sector'" @returnHome="returnHome" />
      <Medicine v-if="page == 'medicine'" @returnHome="returnHome" />
      <Susalud v-if="page == 'susalud'" @returnHome="returnHome" />
      <VoucherType v-if="page == 'voucherType'" @returnHome="returnHome" />
      <Almacen v-if="page == 'almacen'" @returnHome="returnHome" />
      <Proveedores v-if="page == 'proveedor'" @returnHome="returnHome" />
      <Convenios v-if="page == 'convenio'" @returnHome="returnHome" />
      <PassageReasons v-if="page == 'passagereasons'" @returnHome="returnHome" />
      <Asistencial v-if="page == 'asistencial'" @returnHome="returnHome" />
      <Subsubcategoria v-if="page == 'subsubcategoria'" @returnHome="returnHome"/>
      <Bed v-if="page == 'bed'" @returnHome="returnHome" />
      <Roles v-if="page == 'roles'" @returnHome="returnHome" />
      <Usuarios v-if="page == 'usuarios'" @returnHome="returnHome" />
      <Insurances v-if="page == 'Insurances'" @returnHome="returnHome" />
      <Insumos v-if="page == 'insumos'" @returnHome="returnHome" />
      <SubSector v-if="page == 'subSector'" @returnHome="returnHome" />
      <Emails v-if="page == 'email'" @returnHome="returnHome" />
    </div>
  </div>
</template>

<script>
import Nav from "../../components/Maintenance/Nav.vue";
import Home from "../../components/Maintenance/Home.vue";
import Especialidades from "../../components/Maintenance/pages/Especialidad.vue";
import Subespecialidad from "../../components/Maintenance/pages/Subespecialidad.vue";
import Categoria from "../../components/Maintenance/pages/Categoria.vue";
import Consultorios from "@/components/Maintenance/pages/Consultorios.vue";
import Sop from "../../components/Maintenance/pages/Sop.vue";
import Schedule from "../../components/Maintenance/pages/Schedule.vue";
import Sede from "../../components/Maintenance/pages/Sede.vue";
import Boxes from "../../components/Maintenance/pages/Boxes.vue";
import Area from "../../components/Maintenance/pages/Area.vue";
import Marca from '../../components/Maintenance/pages/Brand.vue';
import Sector from '../../components/Maintenance/pages/Sector.vue';
import Medicine from "../../components/Maintenance/pages/Medicine.vue";
import Susalud from "../Maintenance/Susalud/Susalud.vue";
import VoucherType from "../../components/Maintenance/pages/VoucherType.vue";
import Almacen from "../../components/Maintenance/pages/Almacen.vue";
import PassageReasons from '../../components/Maintenance/pages/PassageReasons.vue';
import Proveedores from '../../components/Maintenance/pages/Proveedores.vue';
import Convenios from '../../components/Maintenance/pages/Convenios.vue';
import Asistencial from '../../components/Maintenance/pages/Asistencial.vue';
import Subsubcategoria from "../../components/Maintenance/pages/Subsubcategoria.vue";
import Bed from "../../components/Maintenance/pages/BedGrafic.vue";
import Roles from "@/components/Maintenance/pages/Roles.vue";
import Usuarios from "@/components/Maintenance/pages/Usuarios.vue";
import Insurances from "../../components/Maintenance/pages/Insurances.vue";
import Insumos  from "../../components/Maintenance/pages/Insumos.vue"
import SubSector from '../../components/Maintenance/pages/SubSector.vue';
import Emails from '../../components/Maintenance/pages/Email.vue';
export default {
  name: "v-maintenance",
  components: {
    Nav,
    Home,
    Especialidades,
    Subespecialidad,
    Categoria,
    Consultorios,
    Sop,
    Schedule,
    Sede,
    Boxes,
    Area,
    Marca,
    Sector,
    Medicine,
    Susalud,
    VoucherType,
    Almacen,
    Proveedores,
    Convenios,
    Asistencial,
    PassageReasons,
    Subsubcategoria,
    Bed,
    Roles,
    Usuarios,
    Insurances,
    Insumos,
    SubSector,
    Emails
  },

  data() {
    return {
      page: "home",
    };
  },

  methods: {
    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.page = "home";
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdBackRol: function () {
      this.$emit("mtdBackRol");
    },
  },
};
</script>

<style scoped></style>
