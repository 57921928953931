<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'md'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row d-flex">
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="mb-3 row d-flex">
          <label class="col-sm-4 col-form-label">Fecha Ingreso</label>
          <div class="col-sm-8">
            <input
              type="date"
              class="form-control"
              v-model="ind"            
            />
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="mb-3 row d-flex">
          <label class="col-sm-4 col-form-label">Fecha de alta</label>
          <div class="col-sm-8">
            <input
              type="date"
              class="form-control"
              v-model="end"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      &nbsp;
      <button class="btn btn-success btn-sm" @click="mtdEditValor">
        <i class="fas fa-save" />&nbsp; Editar
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";

export default {
  name: "c-vigilancia-modal-control",
  components: {
    CModal,
    CButtonClose,
  },
  data() {
    return {
      ind:null,
      end:null
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    date_ind: null,
    date_end: null
  },
  methods: {
    mtdEditValor: function () {
      this.$emit("mtdEditValor",this.ind,this.end);
      this.ind=null;
      this.end=null;
    },
    mtdClose() {
      this.$emit("mtdCloseModalPeriodo");
    },    
  },
  updated() {
    this.ind = this.ind==null? this.date_ind:this.ind;
    this.end = this.end==null? this.date_end:this.end;
  },
};
</script>