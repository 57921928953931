<template>
<div>
    <div class="container">
        <div id="btn-open-caja" class="d-flex justify-content-end pt-2 mt-2">
            <div class="btn-group btn-home" role="group">
                <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                    <i class="fas fa-recycle"></i> Cambiar de Sede
                </button>
            </div>
        </div>
        <div class="row row-home" id="div_conte_2">
            <div id="div_conte-home" class="col-md-10 mx-auto">
                <div class="bg-secondary text-white bg-opacity-10">
                    <div class="text-center py-4 px-5" id="scroll-div-home">
                        <div class=" row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('almacen')">
                                ALMACENES
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('area')">
                                ÁREA
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('Insurances')">
                                ASEGURADORAS
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('boxes')">
                                CAJAS
                            </button>
                        </div>
                        <div class=" row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('bed')">
                                CAMAS
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('categoria')">
                                CATEGORÍA
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('consultorios')">
                                CONSULTORIOS
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('convenio')">
                                CONVENIOS
                            </button>
                        </div>
                        <div class=" row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('especialidad')">
                                ESPECIALIDAD
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('asistencial')">
                                HONORARIOS ASISTENCIAL
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('schedule')">
                                HORARIOS
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('marca')">
                                MARCAS
                            </button>
                        </div>
                        <div class=" row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('medicine')">
                                MEDICAMENTOS
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('passagereasons')">
                                MOTIVOS-PASAJE
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('proveedor')">
                                PROVEEDORES
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('roles')">
                                ROLES
                            </button>
                        </div>
                        <div class=" row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('SoP')">
                                SALA DE OPERACIONES
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('sector')">
                                SECTOR
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('sede')">
                                SEDES
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('subsubcategoria')">
                                SERVICIO ESPECIFICO
                            </button>
                        </div>
                        <div class=" row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('subespecialidad')">
                                SUBESPECIALIDAD
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('susalud')">
                                SUSALUD
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('voucherType')">
                                TIPO DE COMPROBANTES
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('usuarios')">
                                USUARIOS
                            </button>
                        </div>
                        <div class="row row-home">
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('subSector')">
                               SUB SECTOR
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('insumos')">
                                INSUMOS
                            </button>
                            <button class="div-campus fw-bold col m-2 p-2 text-white" @click="changePage('email')">
                                CONTENIDO DE CORREO
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'homeMaintenance',
    data() {
        return {
            page: null
        }
    },
    methods: {
        changePage(page) {
            this.$emit('changePage', page)
        },
        mtdBackCampus: function () {
            this.$emit('mtdBackCampus')
        },
    }
}
</script>
