<template>
  <div>
    <div class="px-5 pt-2 pb-5">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12">
          <div class="row">
            <div class="col-12 mt-3 mb-4 d-flex justify-content-end">
              <button type="button" class="btn btn-outline-main text-white" @click="changePage('home')">
                <i class="fas fa-home-lg-alt"></i> Inicio
              </button>
            </div>
          </div>
          <div class="card">
            <div class="card-header text-white h4 text-center" style="background: #900052">
              <strong>DOCUMENTOS DE VENTA</strong>
            </div>
            <div class="card-body">
              <div class="row d-flex g-3">
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text">Desde</span>
                    <input type="date" aria-label="shift_date" class="form-control" id="begindate" v-model="filter.begindate"/>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text">Hasta</span>
                    <input type="date" aria-label="shift_date" class="form-control" id="enddate" v-model="filter.enddate"/>
                  </div>
                </div>
                <div class="col-12 col-md-4 col-lg-2 text-center">
                  <button type="button" name="accion" class="btn btn-sm btn-register text-white w-100" @click="mtdGetData">
                    <i class="fas fa-search"></i> Buscar
                  </button>
                </div>
                <div class="col-12 col-md-4 col-lg-2 text-center">
                  <button type="button" name="accion" class="btn btn-sm btn-register text-white w-100" @click="exportExcel">
                    <i class="fas fa-file-excel"></i> Exportar
                  </button>
                </div>
                <div class="col-12 py-2">
                  <div class="input-group mb-3">
                    <span class="input-group-text text-white" style="background: #900052"><i class="fas fa-search"></i></span>
                    <input @keyup="mtdSearch" v-model="search" type="text" class="form-control" placeholder="Nombre y Apellido"/>
                  </div>
                </div>
                 <!-- tabla -->
                <div class="col-12">
                  <div class="table-responsive" id="dtb-cxc">
                    <table id="tb-cxc" class="table table-bordered border-main">
                      <thead>
                        <tr class="text-white border-white text-center" style="background: #900052">
                          <th scope="col">N°</th>
                          <th scope="col">TIPO VENTA</th>
                          <th scope="col">FECHA</th>
                          <th scope="col">TIPO DOC</th>
                          <th scope="col">SERIE</th>
                          <th scope="col">CORRELATIVO</th>
                          <th scope="col">DNI</th>
                          <th scope="col">A NOMBRE</th>
                          <th scope="col">MONTO</th>
                          <th scope="col">PER. FACTURACIÓN</th>
                          <th scope="col">PER. INGRESO</th>
                          <th scope="col">SUJETO A</th>
                          <th scope="col">ESTADO</th>
                          <th scope="col">ESTADO SUNAT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="dataShow.length === 0">
                          <td class="text-center" colspan="29">{{noRecordsMessage}}</td>
                        </tr>
                        <tr v-for="(item, index) in cpData" :key="index">
                          <td class="text-center">{{ index + 1 }}</td>
                          <td class="text-center">{{ item.sale.type ? item.sale.type.toUpperCase() : '-' }}</td>
                          <td class="text-center">{{item.date | formatDate}} </td>
                          <td class="text-center">{{item.document.toUpperCase()}}</td>
                          <td class="text-center">{{item.serie}} </td>
                          <td class="text-center">{{ item.number }}</td>
                          <td class="text-center">{{item.vaucher_document}}</td>
                          <td class="text-center">{{item.vaucher_fullname.toUpperCase()}}</td>
                          <td class="text-center">S/ {{ item.amount }}</td>
                          <td class="text-center">{{item.billing_period}}</td>
                          <td class="text-center">{{item.admission_period ? item.admission_period : '-'}}</td>
                          <td class="text-center">{{item.detraccion ? 'DETRACCIÓN' : (item.retencion ? 'RETENCIÓN' : '-')}}</td>
                          <td class="text-center">{{ item.state == 1 ? '-' : (item.state == 2 ? (item.notecredit == null ? 'ANULADO' : 'CON NOTA DE CREDITO') : 'Sin Respuesta')}}</td>
                          <td class="text-center">
                            <button v-if="item.sunat != null" class="btn btn-sm btn-info text-white" @click="mtdViewSunat(item.sunat, item)"><i class="fa fa-eye"></i></button>
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- paginate -->
                <div class="d-flex justify-content-center mb-4">
                  <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                      </li>
                      <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                      </li>
                      <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                      </li>
                      <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1" class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cModalSunatVue :title="modalSunat.title" :boo_modal="modalSunat.modal_form" :item="modalSunat.item" :garantias="modalSunat.garantias" :doc="modalSunat.doc" @closeModalSunat="closeModalSunat">
    </cModalSunatVue>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalSunatVue from '../modals/cModalSunat.vue';
import exportFromJson from "export-from-json";

export default {
  name: "c-administracion-document",
  components:{
    cModalSunatVue
  },
  data() {
    return {
        search:"",
        dataShow: [],
        dataCxcs:[],
        totalPagesToShow: 7,
        page: 0,
        selectPage: 1,
        stepPagination: [],
        noRecordsMessage:"",
        filter:{
          begindate:null,
          enddate:null
        },
        modalSunat: {
          action: "",
          title: "",
          modal_form: false,
          item: [],
          garantias:[],
          doc:{}
        },
        excel:[]
    };
  },
  computed: {
    cpData() {
      let filteredData = this.dataCxcs;
      if (this.search.length >= 2 && this.search != "") {
        let query = this.search.toString().toUpperCase();
        filteredData = this.dataCxcs.filter((element) => {
          let name = element.vaucher_fullname.toString().toUpperCase();
          return name.includes(query);
        });
      }

      
      let totalPages = Math.ceil(filteredData.length / 10);
      
      this.stepPagination = Array.from(
        {
          length: totalPages,
        },
        (_, index) => index + 1
      );
      let startIndex = (this.selectPage - 1) * 10;
      let endIndex = startIndex + 9;
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      return this.dataShow;
    },
   
    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        {
          length: endPage - startPage + 1,
        },
        (_, i) => startPage + i
      );
    },





  },
  created() {
    // this.mtdGetData();
    moment.locale("es");
      this.filter.begindate =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-01";
      this.filter.enddate =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
  },
  methods: {
    ...mapActions(["get", "post"]),
    //metodo obtener data
    mtdGetData: function () {
      this.post({
        url: this.$store.getters.get__url + "/Administracion/document/"+this.$store.getters.get__campus,
        token: this.$store.getters.get__token,
        params:this.filter
      })
        .then((response) => {
          this.dataCxcs = response.data;
          if (this.dataCxcs.length === 0) {
            this.noRecordsMessage = "No se encontraron resultados.";
          } else {
            this.noRecordsMessage = "";
            this.selectPage = 1;
          }
          this.dataCxcs.forEach((element, index) => {
              this.excel.push({
                "N°": index + 1,
                "TIPO VENTA":element.sale.type?element.sale.type.toUpperCase():'-',
                FECHA:element.date,
                "TIPO DOC": element.document.toUpperCase(),
                "SERIE":element.serie,
                CORRELATIVO:element.number ,
                "A NOMBRE": element.vaucher_fullname.toUpperCase(),
                MONTO:  element.amount ,
                "PER. FACTURACIÓN": element.billing_period,
                "PER. INGRESO":element.admission_period?element.admission_period:'-',
                "SUJETO A": element.detraccion?'DETRACCIÓN':(element.retencion?'RETENCIÓN':'-'),
                ESTADO: element.state==1?'-':'ANULADO',
               });
            });
           
          /** paginación */
          let cantidad = parseInt(this.dataCxcs.length / 10);
          let residuo = parseInt(this.dataCxcs.length % 10);
          cantidad = residuo > 0 ? cantidad + 1 : cantidad;
          for (let index = 0; index < cantidad; index++) {
            this.stepPagination.push(index + 1);
          }
        })
        .catch((errors) => {});
    },
    mtdSearch: function () {
      if (this.search.length >= 4 && this.search != "") {
        let q = this.search.toString().toUpperCase();
        this.dataShow = [];
        let i = 0;
        this.excel=[];
        this.dataCxcs.forEach((element) => {
          let name = element.vaucher_fullname.toString().toUpperCase();
          if (name.includes(q)) {
            this.dataShow.push(element);
              this.excel.push({
                "N°": index + 1,
                "TIPO VENTA":element.sale.type?element.sale.type.toUpperCase():'-',
                FECHA:element.date,
                "TIPO DOC": element.document.toUpperCase(),
                "SERIE":element.serie,
                CORRELATIVO:element.number ,
                "A NOMBRE": element.vaucher_fullname.toUpperCase(),
                MONTO:  element.amount ,
                "PER. FACTURACIÓN": element.billing_period,
                "PER. INGRESO":element.admission_period?element.admission_period:'-',
                "SUJETO A": element.detraccion?'DETRACCIÓN':(element.retencion?'RETENCIÓN':'-'),
                ESTADO: element.state==1?'-':'ANULADO',
               });
          }
        });
        this.selectPage = 1;
      } else {
        // this.data_product_filter = this.dataSubSubCategories;
      }
    },
    /** modal */
    mtdViewSunat: function(sunat,item){
      this.post({
        url: this.$store.getters.get__url + "/Administracion/consult/"+item.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
      this.modalSunat.title="DOCUMENTOS DE SUNAT";
      this.modalSunat.modal_form=true;
      this.modalSunat.item=JSON.parse(response.data);
      this.modalSunat.doc=item;
      this.modalSunat.garantias=[];
      if (item.sale.type == 'garantia') {
        this.dataShow.forEach(element => {
          if (element.sale.id == item.sale.id) {
            this.modalSunat.garantias.push({
              "tipoDocumento" : "02",
              "serie" : element.serie,
              "correlativo" : element.number.padStart(8, '0'),
              "monto" : parseFloat(element.amount).toFixed(2)
            });
          }
        });
      }
        })
        .catch((errors) => {});
      
     
    },
    closeModalSunat: function(){
      this.modalSunat= {
        action: "",
        title: "",
        modal_form: false,
        item: [],
      };
    },
    /** pagination */
    selectedPage: function (page) {
      this.selectPage = page;
    },
    backPAge: function () {
      this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
    },
    addPAge: function () {
      this.selectPage =
        this.selectPage == this.stepPagination.length
          ? this.stepPagination.length
          : this.selectPage + 1;
    },
    /** coponent */
    changePage(page) {
      this.$emit("changePage", page);
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdBackRol: function () {
      this.$emit("mtdBackRol");
    },
    exportExcel: function () {
      const datos =this.excel;
      const fileName = 'Reporte Documentos';
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: datos,
        fileName: fileName,
        exportType: typeExport,
      });
    },
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length
          ? this.selectPage + 1
          : this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
  },
  filters: {
    formatDate(date) {
      return date!=null?moment(date).format("DD/MM/YYYY"):'-';
    },
  },
  
};
</script>

<style scoped>
input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn-register,
.btn-outline-main {
  background: #900052 !important;
}
.btn-register:hover,
.btn-outline-main:hover {
  background: #72003b !important;
}
.border-main {
  border-color: #900052 !important;
}

.table-responsive {
  overflow-x: auto;
}
</style>