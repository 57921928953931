<template>
    <div>
        <div class="px-3 py-2">
            <div class="d-flex"> 
                <div  class="btn-home mb-4 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="card-header text-white h4 text-center" style="background: #900052">
                        <strong>KARDEX</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <!--Filtro-->
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-4">SELECCIÓN MES</span>
                                            <v-select
                                              class="text-dark form-control"
                                              label="name"
                                              :options="months"
                                              placeholder="- Seleccionar Mes -"
                                              v-model="filter.monthRange"
                                              :reduce="(name) => name.id"
                                              required
                                              :clearable="false"
                                            >
                                              <template slot="no-options">
                                                No hay Meses para la busqueda.
                                              </template>
                                            </v-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                      <div class="input-group input-group-sm">
                                          <span class="input-group-text col-md-4">SELECCIÓN AÑO</span>
                                          <v-select
                                          class="text-dark form-control"
                                          label="Year"
                                          :options="nextThreeYears"
                                          placeholder="- Seleccionar Año -"
                                          v-model="selectedYear"
                                          required
                                          :clearable="false"
                                          >
                                          <template slot="no-options">
                                            No hay Años para la busqueda.
                                          </template>
                                          </v-select>
                                      </div>
                                  </div>
                                  <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-1">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100 py-2"
                                          style="background: #900052"
                                          @click="mtdGetData"
                                          :disabled="mtddisable"
                                          >
                                          <i class="fas fa-search"></i>&nbsp;Buscar
                                          </button>
                                  </div>
                                  <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100 py-2"
                                          style="background: #900052"
                                          @click="viewpdf()"
                                          :disabled="mtddisable"
                                          >
                                          <i class="fal fa-file-pdf"  style="font-size: 18px;"></i>
                                          </button>
                                  </div>
                                </div>
                            </div>
                             <!--Fin Filtro-->
  
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-4" id="content-timelinetable"  v-if="showTable">
                              <table  id="tb-cxc" class="table table-bordered border-main align-middle">
                                <thead>
                                      <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                          <th scope="col" colspan="1" rowspan="2">FECHA</th>
                                          <th scope="col" colspan="1" rowspan="2">TIPO DOC.</th>
                                          <th scope="col" colspan="1" rowspan="2">SERIE Y N° DE DOC.</th>
                                          <th scope="col" colspan="1" rowspan="2">LOTE</th>
                                          <th scope="col" colspan="1" rowspan="2">TIPO OPE.</th>
                                          <th scope="col" colspan="3">INGRESOS</th>
                                          <th scope="col" colspan="3">SALIDAS</th>
                                          <th scope="col" colspan="3">SALDO</th>
                                      </tr>
                                      <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Costo Unit.</th>
                                          <th scope="col">Costo Total</th>
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Costo Unit.</th>
                                          <th scope="col">Costo Total</th>
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Costo Unit.</th>
                                          <th scope="col">Costo Total</th>
                                      </tr>
                                  </thead>
  
                                <tbody class="custom-table">
  
                                  <tr class="text-center" style="vertical-align: middle; color: #900052; background: #f3e2ec">
                                      <th scope="col" colspan="5" style="font-style: italic;">*** TOTALES</th>
                                      <th scope="col">{{ totals.totalSumaCantidadEn }}</th>
                                      <th scope="col" colspan="2">{{  totals.totalSumaTotalEn }}</th>
                                      <th scope="col">{{  totals.totalSumaCantidadSa}}</th>
                                      <th scope="col" colspan="2">{{  totals.totalSumaTotalSa}}</th>
                                      <th scope="col" colspan="3" style="font-style: italic;"></th>
                                  </tr>
  
                                  <template v-for="(product, productId) in dataCxcs">
                                    <tr class="text-center" :key="'product_' + productId">
                                        <th scope="col" colspan="14">{{  product[0].name }}</th>
                                    </tr>
  
                                    <tr v-for="(item, index) in product" :key="'item_' + productId + '_' + index">
                                          <template v-if="item.type == 16">
                                                <td>{{ item.date | formatDate }}</td>
                                                <td colspan="3"> {{'-'}}</td>
                                                <td>
                                                    <span v-if="item.type == 16" class="badge text-bg-primary">INVENTARIO</span>
                                                </td>
                                                <td>{{ parseInt(item.quantity) }}</td>
                                                <td>{{ item.price_cost }}</td>
                                                <td>{{ (item.quantity * item.price_cost).toFixed(2) }}</td>
                                                <td colspan="3">-</td>
                                                <td>{{ item.saldoCantidad }}</td>
                                                <td>{{ item.division }}</td>
                                                <td>{{ item.saldoTotal.toFixed(2) }}</td>
                                            </template>
  
                                          <template v-if="item.type == 1">
                                              <td>{{ item.date | formatDate }}</td>
                                              <td>{{stateType(item.typeBills)}}</td>
                                              <td>{{ item.number }}</td>
                                              <td>{{ item.lote}}</td>
                                              <td @click="openModal(item.dispatch_detail_id, item.sale_id, item.name, item.lote, item.id, item.product_detail_id)"
                                              style="cursor: pointer;">
                                                  <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                  <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                  <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                  <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                              </td>
                                              <td>{{ parseInt(item.quantity) }}</td>
                                              <td>{{ item.price_cost }}</td>
                                              <td>{{ (item.quantity * item.price_cost).toFixed(2) }}</td>
                                              <td colspan="3">-</td>
                                              <td>{{ item.saldoCantidad }}</td>
                                              <td>{{ item.division }}</td>
                                              <td>{{ item.saldoTotal.toFixed(2) }}</td>
                                          </template>
  
                                          <template v-if="item.type == 2">
                                              <td>{{ item.date | formatDate }}</td>
                                              <td>{{ item.document !== '' ? item.document : '-' }}</td>
                                              <td>{{item.serie}}-{{item.numberVou}}</td>
                                              <td>{{ item.lote}}</td>
                                              <td @click="openModal(item.dispatch_detail_id, item.sale_id, item.name, item.lote, item.id, item.product_detail_id)"
                                              style="cursor: pointer;">
                                                  <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                  <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                  <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                  <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                              </td>
                                              <td colspan="3">-</td>
                                              <td>{{parseInt(item.quantity) }}</td>
                                              <td>{{ item.price_cost }}</td>
                                              <td>{{ (item.quantity * item.price_cost).toFixed(2) }}</td>
                                              <td>{{ item.saldoCantidad }}</td>
                                              <td>{{ item.division }}</td>
                                              <td>{{ item.saldoTotal.toFixed(2)}}</td>
                                          </template>
                                    </tr>
                                    <tr  class="text-center" style="vertical-align: middle; color: #900052; background: #fdfae3" :key="productId">
                                          <th scope="col" colspan="5" style="font-style: italic;">*** TOTAL</th>
                                          <th scope="col">{{ product.totales.sumaCantidadEn }}</th>
                                          <th scope="col" colspan="2">{{ product.totales.sumaTotalEn}}</th>
                                          <th scope="col">{{ product.totales.sumaCantidadSa }}</th>
                                          <th scope="col" colspan="2">{{ product.totales.sumaTotalSa}}</th>
                                          <th scope="col" colspan="3" style="font-style: italic;"></th>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
  
                            </div>
  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalDetKarVue :title="modalDetKar.title" :boo_modal="modalDetKar.modal_form" :dataDis=modalDetKar.dataDis
        :dataSal=modalDetKar.dataSal :dataDePhar=modalDetKar.dataDePhar :dataFactura=modalDetKar.dataFactura
        :dataNoteDet=modalDetKar.dataNoteDet :namePro=modalDetKar.namePro :lotePro=modalDetKar.lotePro @closeModalDetKar="closeModalDetKar"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
  
<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import cModalDetKarVue from "../modals/Kardex/cModalDetKar.vue";
  
  export default {
      name: "kardex",
      components: {
        SweetAlert,
        cModalDetKarVue
      },
      data() {
          return {
            swal: null,
              search: "",
              dataShow: [],
              dataCxcs: [],
              selectPage: 1,
              noRecordsMessage: "",
              filter: {
                  init: "",
                  end: "",
                  monthRange: null
              },
              stepPagination: [],
              servicesProd: [],
              selectedProd: "",
              totals: [],
  
              selectedYear: null,
              nextThreeYears: [],
              months: [
                { id: 1, name: 'Enero' },
                { id: 2, name: 'Febrero' },
                { id: 3, name: 'Marzo' },
                { id: 4, name: 'Abril' },
                { id: 5, name: 'Mayo' },
                { id: 6, name: 'Junio' },
                { id: 7, name: 'Julio' },
                { id: 8, name: 'Agosto' },
                { id: 9, name: 'Septiembre' },
                { id: 10, name: 'Octubre' },
                { id: 11, name: 'Noviembre' },
                { id: 12, name: 'Diciembre' }
              ],
              showTable: false,
              //Inventario
              registerKar: [],
  
              modalDetKar: {
                  title: "DETALLE DE KARDEX",
                  dataDis: [],
                  dataFactura: [],
                  dataSal: [],
                  dataDePhar: [],
                  dataNoteDet: [],
                  namePro: "",
                  lotePro: "",
                  modal_form: false,
              },
          };
      },
      mounted() {
        this.populateNextThreeYears();
      },
      computed: {
          mtddisable() {
              return !(this.filter.monthRange !== null && this.selectedYear !== null);
          },
          cpData() {
          }
      },
      created() {
          moment.locale("es");
          this.filter.end =
              moment().format("L").substr(6, 4) +
              "-" +
              moment().format("L").substr(3, 2) +
              "-" +
              moment().format("L").substr(0, 2);
          this.filter.init = this.filter.end;
          //Inventario
          this.registerKardex();
      },
      methods: {
          ...mapActions(["get", "post"]),

          mtdGetData: function () {
              this.post({
                  url: this.$store.getters.get__url + "/Kardex/index",
                  token: this.$store.getters.get__token,
                  params: {
                      month: this.filter.monthRange,
                      anio: this.selectedYear,
                  },
              })
              .then((response) => {
                  this.dataCxcs = response.data.dataCxcs;
                  this.totals = response.data.totals;
                  if (this.dataCxcs.length === 0) {
                      this.showTable = false;
                      this.noRecordsMessage = "No se encontraron Resultados";
                      this.$refs.SweetAlert.showWarning("Sin Datos");
                  } else {
                      this.showTable = true;
                      this.noRecordsMessage = "";
                  }
              })
              .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
              });
          },
  
          //Inventario
          registerKardex: function () {
            const currentMonth = moment().month() + 1;
            const currentYear = moment().year();
              this.post({
                  url: this.$store.getters.get__url + "/Kardex/registerKardex",
                  token: this.$store.getters.get__token,
                  params: {
                    month: currentMonth,
                    anio: currentYear,
                  },
              })
              .then((response) => {
                  this.registerKar = response.data;
              })
              .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
              });
          },
  
          openModal: function (idDis, idSale, name, lote, id, idPd) {
              this.post({
                  url: this.$store.getters.get__url + "/Kardex/openDetKardex",
                  token: this.$store.getters.get__token,
                  params: {
                    idDis: idDis,
                    idSale: idSale,
                    idKa: id,
                    idPd: idPd,
                  },
              })
              .then((response) => {
                this.modalDetKar = {
                    title: "DETALLE DE KARDEX",
                    modal_form: true,
                    dataDis: response.data.dataDis,
                    dataFactura: response.data.dataFactura,
                    dataSal: response.data.dataSal,
                    dataDePhar: response.data.dataDePhar,
                    dataNoteDet: response.data.dataNoteDet,
                    namePro: name,
                    lotePro: lote,
                };
              })
              .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
              });
  
          },
  
          closeModalDetKar: function () {
              this.modalDetKar = {
                  title: "DETALLE DE KARDEX",
                  modal_form: false,
                  dataFactura: [],
                  dataDis: [],
                  dataSal: [],
                  dataDePhar: [],
                  dataNoteDet: [],
                  namePro: "",
                  lotePro: "",
              };
          },

          viewpdf: function () {
              window.open(
                  this.$store.getters.get__url +
                  "/Kardex/viewpdf/" +
                  this.filter.monthRange + "/" +
                  this.selectedYear,
                  "_blank"
              );
          },

          populateNextThreeYears() {
            const currentYear = new Date().getFullYear();
            for (let i = 0; i < 3; i++) {
              this.nextThreeYears.push(currentYear - i);
            }
          },

          returnHome: function () {
            this.$emit("returnHome");
          },

          stateType(state) {
                switch (state) {
                case 1:
                    return "FACTURA";
                case 2:
                    return "BOLETA";
                default:
                    return "NOTA DE INGRESO";
                }
            },
      },
      filters: {
          formatDate(date) {
              return date != null ? moment(date).format("DD/MM/YYYY") : "-";
          },
          formatDateTime(dateTime) {
              return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
          },
      },
  };
</script>
  
  <style scoped>
  .custom-table th,
  .custom-table td {
      font-size: 11px;
  }
  </style>
  