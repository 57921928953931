<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home col-md-12 mt-3 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="row d-flex justify-content-betwwen pb-5">
            <div class="col-xl-2 col-md-2">
                <div class="container mt-4">
                    <label for="finalizo_mes" class="mb-2"><b>MES:</b></label>
                    <select class="from-control form-control" v-model="filter_mes" @change="getDataReport">
                        <option value="0" disabled>[Seleccione...]</option>
                        <option v-for="(item, index) in months" :key="index" :value="index + 1">
                            {{ item }}
                        </option>
                    </select>
                </div>
                <div class="container mt-4">
                    <div class="mt-2">
                        <label for="finalizo_mes" class="mb-2"><b>FINALIZÓ EL MES CON:</b></label>
                        <input type="text" class="form-control" :value="amountMes" disabled>
                    </div>
                </div>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10">
                <div class="container mt-4">
                    <div class="card">
                        <div class="card-body">
                            <div style="height: 55vh;">
                                <canvas id="grafica"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Chart from 'chart.js/auto';
//import moment from "moment";
import {
    mapActions
} from "vuex";
export default {
    name: 'cTesoreria-pages-grafico',
    data() {
        return {
            labels: [],
            filter_mes: "",
            months: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            dataset1: {
                label: "S/. DEPOSITOS",
                data: [],
                borderColor: 'rgba(69, 248, 84, 0.8)',
                borderWidth: 3,
                fill: false,
                tension: 0
            },
            dataset2: {
                label: "S/. RETIROS",
                data: [],
                borderColor: 'rgba(248, 37, 37, 0.8)',
                borderWidth: 3,
                fill: false,
                tension: 0
            },
            chart: null,
            amountMes: null

        }
    },
    mounted() {
        this.getMonth();

    },
    methods: {
        ...mapActions(["get", "post"]),
        getMonth: function () {
            //mes actual
            const currentDate = new Date();
            this.filter_mes = currentDate.getMonth() + 1;
            this.getDataReport();
        },
        getDataReport: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Tesoreria/report/" + this.filter_mes,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let temp = [];
                    response.data.label.forEach(element => {
                        temp.push(element.date);
                    });
                    this.labels = temp;
                    this.dataset1.data = response.data.deposits;
                    this.dataset2.data = response.data.retirement;
                    this.renderChart();
                    this.finalizoMes(this.filter_mes);
                })
                .catch(() => {});
        },
        finalizoMes: function (mes) {
            this.get({
                    url: this.$store.getters.get__url + "/Tesoreria/endMonth/" + mes,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.amountMes = response.data;
                })
                .catch(() => {});
        },
        renderChart: function () {
            const data = {
                labels: this.labels,
                datasets: [this.dataset1, this.dataset2]
            };
            const config = {
                type: 'line',
                data: data,
                options: {
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: 'Fecha',
                                font: {
                                    size: 16
                                },
                                panding:{
                                    top: 5
                                }
                            }
                        },
                        y: {
                            title: {
                                display: true,
                                text: 'Monto (S/.)',
                                font: {
                                    size: 16
                                }
                            }
                        }
                    }
                }
            };
            const graph = document.querySelector("#grafica");

            if (this.chart) {
                this.chart.destroy();
            }

            this.chart = new Chart(graph, config);
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    }

}
</script>
