<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >

    <div class="row mx-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">DOCUMENTO</th>
                        <th scope="col">FECHA</th>
                        <th scope="col">DESTINO</th>
                        <th scope="col">PDF</th>
                      </tr>
                  </thead>
                  <tbody class="border-main">
                    <template v-if="cpData.length > 0">
                        <tr v-for="(det, index) in cpData" :key="index" class="text-center align-middle" style="font-size: 11px">
                            <td><strong>{{ det.correlative }}</strong></td>
                            <td style="white-space: nowrap;"> {{ det.date | formatDate }}</td>
                            <td>{{ det.destination}}</td>
                            <td>
                                <a @click="mtdPdf(det.id)" class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052; margin-left: 5px;">
                                    <i class="fas fa-solid fa-file-pdf"></i>
                                </a>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="6" style="font-size: 14px">No hay registros</td>
                        </tr>
                    </template>
                  </tbody>
              </table>
          </div>

      </div>
    </div>

  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "c-pharmacy-cModalMedicDespachado",
  components: {
    CModal,
  },
  data() {
    return {
      tabla: [],
    };
  },
  created () {
  },
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    producto: String,
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdClose() {
      this.$emit("mtdCloseModalDispath");
    },

    mtdPdf: function(id) {
      window.open(
          this.$store.getters.get__url + "/Pharmacy/recetasDispathPdf/" + id,
          "_blank"
      );
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY HH:mm:ss");
      } else {
        return "-";
      }
    }
  }
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>
