<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div v-if="num ==1" class="row mx-0  px-2">
        <div class="row mx-0 px-0" v-if="data.length > 0">
            <div class="col-xl-3 mb-1  px-1 " v-for="(item,index) in data" :key="index">
                <div class="text-center  text-white rounded rounded-2 btn-asistencial text-xxs h-100 border border-main border-3">
                    <button @click="addbed (item)" type="button" class="btn w-100 text-xs h-100 ">
                        {{ item.name}}
                    </button>
                </div>
            </div>
        </div>
        <div class="text-center fw-bold" v-else>
            <span>No hay registros</span>
        </div>
    </div>
    <div v-else class="row mx-0  px-2">
        <div class="row mx-0 px-0" v-if="data2.length > 0">
            <div class="col-xl-3 mb-1  px-1 " v-for="(item,index) in data2" :key="index">
                <div class="text-center  text-white rounded rounded-2 btn-asistencial text-xxs h-100 border border-main border-3">
                    <button @click="addbedsub (item)" type="button" class="btn w-100 text-xs h-100 ">
                        {{ item.name}}
                    </button>
                </div>
            </div>
        </div>
        <div class="text-center fw-bold" v-else>
            <span>No hay registros</span>
        </div>
    </div>
    <template #footer>
        <!-- <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm">
            Guardar
        </button>
        &nbsp; -->
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            swal: null,
            data: [],
            data2:[],
            num: null,
             cama:{}
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    
    props: {
        title: String,
        boo_modal: Boolean,
        bed: {
            type: Object,
            default: () => ({})
        },

    },
    watch: {
        bed: {
            handler(newVal) {
                if (Object.keys(newVal).length > 0) {
                    if (newVal.type == 3) {
                        this.mtdcamasHosp(newVal.campu_id)
                    } else if (newVal.type == 4) {
                        this.mtdcamasUci(newVal.campu_id)
                    } else if (newVal.type == 7) {
                        this.mtdcamasEmergency(newVal.campu_id)
                    }
                }

            },
            immediate: true
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalBed')
        },
        mtdcamasHosp: function (campu_id) {
            this.get({
                url: this.$store.getters.get__url + "/admision/freebedshosp/" + campu_id,
                token: this.$store.getters.get__token,

            }).then((response) => {
                this.data = response.data
                this.data2 = response.data2 
                this.num = 1
            });

        },
        mtdcamasUci: function (campu_id) {
            this.get({
                url: this.$store.getters.get__url + "/admision/freebedsUci/" + campu_id,
                token: this.$store.getters.get__token,

            }).then((response) => {
                this.data = response.data
                this.data2 = response.data2 
                this.num = 1
            });
        },
        mtdcamasEmergency: function (campu_id) {
            this.get({
                url: this.$store.getters.get__url + "/admision/freebedsEmergency/" + campu_id,
                token: this.$store.getters.get__token,

            }).then((response) => {
                this.data = response.data
                this.data2 = response.data2 
                this.num = 1
            });
        },
        addbed: function (payload) {
            this.cama = payload
            this.num = 2
        },
        addbedsub : function(payload){
            this.$emit('mtdaddbed', this.cama, this.bed.type,payload)
            this.cama = {}
            this.num = null
        }

    },
}
</script>
