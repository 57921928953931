<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label id="miSelect" for="name" class="form-label">Campus</label>
                    <select  v-model="data_detail.campu_id"  class="form-control form-control-sm" aria-label=".form-select-sm example">
                        <option value="" selected disabled>Seleccione...</option>
                        <option  v-for="(item,index) in dataCampus" :key="index" :value="item.id">{{ item.name }}</option>
                    </select>
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label id="miSelect" for="name" class="form-label">Seleccione Horario</label>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                            <label for="horaInicio" class="form-label">Desde:</label>
                            <select id="horaInicio" name="horaInicio" class="form-control form-control-sm" v-model="data_detail.horaInicio">
                                <option v-for="(option,index) in horaOptions" :key="index" :value="option.value">{{ option.label }}</option>
                            </select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                            <label for="horaFin" class="form-label">Hasta:</label>
                            <select id="horaFin" name="horaFin" class="form-control form-control-sm" v-model="data_detail.horaFin">
                                <option v-for="(option,index) in horaOptions" :key="index" :value="option.value">{{ option.label }}</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label id="miSelect" for="name" class="form-label">Rango:&nbsp;</label>
                                <div v-for="option in options" :key="option.value" class="form-check form-check-inline">
                                <input
                                    :id="'rango' + option.value"
                                    name="rango"
                                    type="radio"
                                    class="form-check-input"
                                    :value="option.value"
                                    :checked="data_detail.rango == option.value"
                                    @change="updateRango(option.value)"
                                />
                                <label class="form-check-label" :for="'rango' + option.value">{{ option.label }}min</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="mtdModifyData" :disabled="cpButton">
                {{ (num == 0 ) ? 'Registrar':'Editar' }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data_detail: {
                name: '',
                state: '',
                campu_id: '',
                horaInicio:'',
                horaFin:'',
                rango:'',
                schedule: {
                    schedule: "",
                    id: "",
                    state: "",
                    type:"",
                    campu_id:"",
                },
            },
        };
    },
    watch: {
        payload: {
            /*
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                    const scheduleArray = newVal.schedule.split(",");
                    this.data_detail.horaInicio = scheduleArray[0];
                    this.data_detail.horaFin = scheduleArray[scheduleArray.length - 1];
                    this.data_detail.rango = scheduleArray.length > 1 ? this.calculateRango(scheduleArray[0], scheduleArray[1]) : '';
                }
                }
            },
            */
            handler(newVal) {
                if (this.num === 1 && newVal) {
                    // Copiar todas las propiedades de newVal a data_detail
                    this.data_detail = {
                        ...newVal,
                        horaInicio: '',
                        horaFin: '',
                        rango: ''
                    };

                    // Actualizar horaInicio, horaFin y rango
                    const scheduleArray = newVal.schedule.split(",");
                    this.data_detail.horaInicio = scheduleArray[0];
                    this.data_detail.horaFin = scheduleArray[scheduleArray.length - 1];
                    this.data_detail.rango = scheduleArray.length > 1 ? this.calculateRango(scheduleArray[0], scheduleArray[1]) : '';
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataCampus: {
            type: Array,
            default: null
        },
        options: {
            type: Array,
            default: null
        },
        horaOptions: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
            return (
                !this.data_detail.horaInicio ||
                !this.data_detail.horaFin ||
                !this.data_detail.rango ||
                this.data_detail.horaInicio >= this.data_detail.horaFin
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail.name = '';
            this.data_detail.state = '';
            this.data_detail.campu_id = '';
            this.data_detail.horaInicio = '';
            this.data_detail.horaFin = '';
            this.data_detail.rango = '';
            this.data_detail.schedule = {
                schedule: '',
                id: '',
                state: '',
                type: '',
                campu_id: '',
            };
            this.$emit("closeModal");
        },

        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.mtdClose();
        },

        calculateRango(horaInicio, horaFin) {
            const inicio = new Date(`2000-01-01 ${horaInicio}`);
            const fin = new Date(`2000-01-01 ${horaFin}`);
            const diff = Math.abs(fin - inicio); 
            const diffMinutes = Math.floor(diff / 1000 / 60); 
            return diffMinutes.toString();
        },

        updateRango(value) {
            this.rango = value; 
        },

        mtdModifyData: function () {
            const horaInicio = document.getElementById('horaInicio').value;
            const horaFin = document.getElementById('horaFin').value;
            const rango = document.querySelector('input[name="rango"]:checked').value;
            const fechaInicio = new Date(`2000/01/01 ${horaInicio}`);
            const fechaFin = new Date(`2000/01/01 ${horaFin}`);
            const intervalos = [];
            let fechaActual = new Date(fechaInicio);

            while (fechaActual <= fechaFin) {
                intervalos.push(
                    fechaActual.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                    })
                );
                fechaActual.setMinutes(fechaActual.getMinutes() + parseInt(rango));
            }

            // Guardar los intervalos en this.schedule.schedule
            this.data_detail.schedule = intervalos.join(',');

            this.post({
                url: this.$store.getters.get__url + "/Schedule/updateSchedule",
                token: this.$store.getters.get__token,
                params: this.data_detail,
            })
            .then((response) => {
                if(response.data.state == 0){
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit("mtdGetData", this.data_detail.campu_id);
                    this.mtdClose();
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

    },
    

};
</script>