<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex px-3">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <div class="border-custom-text fw-bold">MEDIO DE PAGO</div>
            <div id="row-3" class="row d-flex mt-2 mb-3 justify-content-center">
                <div class="col-lg-2 col-md-3">
                    <button id="efectivo" :class="
                            bottonActiveM == 'efectivo'
                              ? 'myButton active-btn w-100'
                              : 'myButton w-100'
                          " @click="activarMedio('efectivo')">
                        <div :class="
                              bottonActiveM == 'efectivo'
                                ? 'myButton-icon-cxc active-ico'
                                : 'myButton-icon-cxc'
                            ">
                            <i class="far fa-money-bill-alt fa-2x mt-3"></i>
                        </div>
                        <div>Efectivo</div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-3">
                    <button id="tarjeta" :class="
                            bottonActiveM == 'tarjeta'
                              ? 'myButton active-btn w-100'
                              : 'myButton w-100'
                          " @click="activarMedio('tarjeta')">
                        <div :class="
                              bottonActiveM == 'tarjeta'
                                ? 'myButton-icon-cxc active-ico'
                                : 'myButton-icon-cxc'
                            ">
                            <i class="fas fa-credit-card fa-2x mt-3"></i>
                        </div>
                        <div>Tarjeta</div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-3">
                    <button id="deposito" :class="
                            bottonActiveM == 'deposito'
                              ? 'myButton active-btn w-100'
                              : 'myButton w-100'
                          " @click="activarMedio('deposito')">
                        <div :class="
                              bottonActiveM == 'deposito'
                                ? 'myButton-icon-cxc active-ico'
                                : 'myButton-icon-cxc'
                            ">
                            <i class="fas fa-money-check-alt fa-2x mt-3"></i>
                        </div>
                        <div>Deposito</div>
                    </button>
                </div>
                <div class="col-lg-2 col-md-3">
                    <button id="transferencia" :class="
                            bottonActiveM == 'transferencia'
                              ? 'myButton active-btn w-100'
                              : 'myButton w-100'
                          " @click="activarMedio('transferencia')">
                        <div :class="
                              bottonActiveM == 'transferencia'
                                ? 'myButton-icon-cxc active-ico'
                                : 'myButton-icon-cxc'
                            ">
                            <i class="fas fa-exchange-alt fa-2x mt-3"></i>
                        </div>
                        <div>Transferencia</div>
                    </button>
                </div>
                <!-- <div class="col-lg-2 col-md-3">
                              <button
                                id="planilla"
                                :class="
                                  bottonActiveM == 'planilla'
                                    ? 'myButton active-btn w-100'
                                    : 'myButton w-100'
                                "
                                @click="activarMedio('planilla')"
                              >
                                <div
                                  :class="
                                    bottonActiveM == 'planilla'
                                      ? 'myButton-icon-cxc active-ico'
                                      : 'myButton-icon-cxc'
                                  "
                                >
                                  <i class="fas fa-user-minus fa-2x mt-3"></i>
                                </div>
                                <div>Desc. planilla</div>
                              </button>
                            </div> -->
                <div class="col-lg-2 col-md-3">
                    <button id="mixto" :class="
                            bottonActiveM == 'mixto'
                              ? 'myButton active-btn w-100'
                              : 'myButton w-100'
                          " @click="activarMedio('mixto')">
                        <div :class="
                              bottonActiveM == 'mixto'
                                ? 'myButton-icon-cxc active-ico'
                                : 'myButton-icon-cxc'
                            ">
                            <i class="fas fa-wallet fa-2x mt-3"></i>
                        </div>
                        <div>Mixto</div>
                    </button>
                </div>
            </div>
        </div>
        <hr v-if="payment_new.type == 'garantia'" />
        <div class="col-md-12 col-lg-12 col-xs-12" v-if="payment_new.type == 'garantia'">
            <!-- <div class="border-custom-text mt-3 fw-bold">
                        TIPO DE COMPROBANTE
                      </div> -->
            <div class="row d-flex justify-content-center">
                <div class="col-md-12"></div>
                <template v-for="(comprobante, index) in dataDocumentType">
                    <div :key="index" class="col-md-4 text-center" v-if="comprobante.name == sale.voucher[0].document">
                        <button :class="computedClass(comprobante.name)" @click="activarTipo(comprobante.name)">
                            <div :class="activeClass(comprobante.name)">
                                <i class="far fa-file-invoice-dollar fa-2x mt-3"></i>
                            </div>
                            <div>{{ comprobante.name }}</div>
                        </button>
                    </div>
                </template>
            </div>
            <div class="row mt-2 px-4 d-flex">
                <div class="input-group input-group-sm mb-3">
                    <div class="input-group-text">
                        <input :disabled="disabledChkDoc" class="form-check-input mt-0" @change="mtdSelectDoc" type="checkbox" v-model="chkDoc" aria-label="Checkbox for following text input" />&nbsp;{{
                              chkDoc == true ? "A nombre del paciente " : "A nombre de: "
                            }}
                    </div>
                    <input type="text" class="form-control" v-on:keypress="isNumber($event)" @keyup="mtdSearchDocument" :maxlength="bottonActiveT == 'BOLETA' ? 8 : 11" id="txtDoc" v-model="chkPatiente.document" :disabled="chkDoc" placeholder="Documento de paciente" />
                    <input type="text" aria-label="Last name" id="txtDoc2" placeholder="Nombre de paciente" disabled class="form-control" v-model="chkPatiente.fullName" />
                </div>
                <div class="input-group input-group-sm" v-if="bottonActiveT == 'FACTURA'">
                    <div class="input-group-text">Dirección</div>
                    <input type="text" class="form-control" id="txtDoc" v-model="chkPatiente.address" :disabled="true" placeholder="Dirección" />
                </div>
            </div>
        </div>
        <hr />
        <div class="col-md-12 col-lg-12 col-xs-12">
            <div class="row mt-2 px-5 d-flex justify-content-around">
                <div :class="[bottonActiveM == 'efectivo' ? 'col-md-4' : 'col-md-3']" v-if="bottonActiveM == 'efectivo' || bottonActiveM == 'mixto'">
                    <div class="mb-3 me-1">
                        <label for="efectivo" class="form-label border-custom-text-2 fw-bold">EFECTIVO</label>
                        <input style="text-align: center" class="form-control input-1 fw-bold" type="text" v-on:keypress="isNumber($event)" @blur="mtdCalculate('efectivo')" v-model="payment_new.amount.efectivo" placeholder="S/0.00" aria-label="default input example" />
                    </div>
                </div>
                <div :class="[vuelto && bottonActiveM == 'efectivo' ? 'col-md-4' : 'col-md-3']" v-if="vuelto && bottonActiveM == 'efectivo'">
                    <div class="mb-3">
                        <label for="vuelto" class="form-label border-custom-text-2 fw-bold">VUELTO</label>
                        <input style="text-align: center" class="form-control input-1 fw-bold" type="text" disabled v-model="payment_new.vuelto" placeholder="S/0.00" aria-label="default input example" />
                    </div>
                </div>
                <div :class="[bottonActiveM == 'tarjeta' ? 'col-md-4' : 'col-md-3']" v-if="bottonActiveM == 'tarjeta' || bottonActiveM == 'mixto'">
                    <!-----------TARJETA----------------------->
                    <div class="mb-3">
                        <label for="tarjeta" class="form-label border-custom-text-2 fw-bold">TARJETA</label>
                        <input style="text-align: center" class="form-control input-1 fw-bold" type="text" @blur="mtdCalculate('tarjeta')" v-on:keypress="isNumber($event)" v-model="payment_new.amount.tarjeta" aria-label="default input example" placeholder="S/0.00" />
                    </div>
                </div>
                <div :class="[bottonActiveM == 'deposito' ? 'col-md-4' : 'col-md-3']" v-if="bottonActiveM == 'deposito' || bottonActiveM == 'mixto'">
                    <div class="mb-3">
                        <label for="deposito" class="form-label border-custom-text-2 fw-bold">DEPÓSITO</label>
                        <input style="text-align: center" class="form-control input-1 fw-bold" type="text" @blur="mtdCalculate('depostio')" v-on:keypress="isNumber($event)" v-model="payment_new.amount.deposito" aria-label="default input example" placeholder="S/0.00" />
                    </div>
                </div>
                <div :class="[bottonActiveM == 'transferencia' ? 'col-md-4' : 'col-md-3']" v-if="
                              bottonActiveM == 'transferencia' || bottonActiveM == 'mixto'
                            ">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label border-custom-text-2 fw-bold">TRANSFERENCIA</label>
                        <input style="text-align: center" class="form-control input-1 fw-bold" type="text" @blur="mtdCalculate('transferencia')" v-model="payment_new.amount.transferencia" v-on:keypress="isNumber($event)" aria-label="default input example" placeholder="S/0.00" />
                    </div>
                </div>
                <div class="col-md-3" v-if="pending">
                    <div class="mb-3">
                        <label for="pendiente" class="form-label border-custom-text-2 fw-bold">PENDIENTE</label>
                        <input style="text-align: center" class="form-control input-1 fw-bold" type="text" disabled v-model="payment_new.pending" placeholder="S/0.00" aria-label="default input example" readonly />
                    </div>
                </div>
                <div :class="[bottonActiveM !== 'mixto' ? 'col-md-4' : 'col-md-3']">
                    <div class="mb-3">
                        <label for="input_total" class="form-label border-custom-text-2 fw-bold">TOTAL</label>
                        <input style="text-align: center" class="form-control fw-bold input_total" readonly type="text" v-model="totalPay" aria-label="default input example" />
                    </div>
                </div>

            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdInsertData">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

import {
    mapActions
} from "vuex";

export default {
    name: "c-modal-cxc-pagoGarantia",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            payment_new: {
                document: "",
                type: "",
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            },
            swal: null,
            totalPay: 0.0,
            vuelto: "",
            pending: "",
            chkPatiente: {
                document: "",
                fullName: "",
                address: "",
            },
            bottonActiveT: "",
            procede: false,
            chkDoc: "",
            disabledChkDoc: "",
            bottonActiveM: ""
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payment: Object,
        sale: Object,
        dataDocumentType: Array,
        payload: Object,

    },
    watch: {
        payment: {
            handler(newVal) {
                if (newVal) {
                    this.payment_new = {
                        ...newVal
                    };
                    this.totalPay = this.payload.totalPay;
                    this.vuelto = this.payload.vuelto;
                    this.pending = this.payload.pending;
                    this.disabledChkDoc = this.payload.disabledChkDoc;
                    this.chkDoc = this.payload.chkDoc;
                    this.bottonActiveT = this.payload.bottonActiveT;
                    this.activarTipo(this.bottonActiveT);

                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdInsertData: function () {
            if (!this.procede) {
                this.$refs.SweetAlert.showWarning("El monto invalido.");
                return;
            }

            this.post({
                    url: this.$store.getters.get__url + "/cxc/store",
                    token: this.$store.getters.get__token,
                    params: {
                        bead_id: this.payload.bead_id,
                        total_pay: this.totalPay,
                        sale: this.sale,
                        payment: this.payment_new,
                        chkPatiente: this.chkPatiente,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        if (response.data != null) {
                            let res = response.data.sunat;
                            window.open(res.json.enlace_del_pdf_ticket, "_blank");
                        }
                        /** todo correcto **/
                        this.$refs.SweetAlert.showSuccess('Registro Exitoso');
                        this.$emit('mtdHideModal',0);

                    }
                })
                .catch((errors) => {});
        },
        mtdclosemodal() {
            this.payment_new = {
                document: "",
                type: "",
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            };
            this.totalPay = 0.0;
            this.chkPatiente = {
                document: "",
                fullName: "",
                address: "",
            };
            this.bottonActiveT = "",
                this.procede = false,
                this.chkDoc = "",
                this.disabledChkDoc = "",
                this.bottonActiveM = ""
            this.$emit('mtdHideModal');
        },
        computedClass: function (comprobante) {
            return this.bottonActiveT == comprobante ?
                "myButton active-btn w-75" :
                "myButton w-75";
        },
        activeClass: function (comprobante) {
            return this.bottonActiveT == comprobante ?
                "myButton-icon-2 active-ico" :
                "myButton-icon-2";
        },
        activarMedio: function (value) {
            this.payment_new.method = value;
            this.bottonActiveM = value;
            this.payment_new.amount = {
                efectivo: 0, //this.payment_new.type!='credito'?0.00:(value=='mixto'?0.00: parseFloat(this.totalPay).toFixed(2)),
                tarjeta: 0, //this.payment_new.type!='credito'?0.00:(value=='mixto'?0.00: parseFloat(this.totalPay).toFixed(2)),
                deposito: 0, //this.payment_new.type!='credito'?0.00:(value=='mixto'?0.00:parseFloat(this.totalPay).toFixed(2)),
                transferencia: 0, //this.payment_new.type!='credito'?0.00:(value=='mixto'?0.00:parseFloat(this.totalPay).toFixed(2)),
            };
            this.mtdCalculate(this.payment_new.method);
        },
        mtdCalculate: function (input) {
            let acum = this.mtdGetAcum();
            if (this.payment_new.type == "credito") {
                let dif = parseFloat(acum - this.totalPay);
                if (dif < 0) {
                    this.payment_new.pending = parseFloat(dif).toFixed(2) * -1;
                    this.vuelto = false;
                    this.pending = true;
                } else {
                    this.payment_new.vuelto = parseFloat(dif).toFixed(2);
                    this.vuelto = true;
                    this.pending = false;
                }
                if (input == "efectivo" || input == "mixto") {
                    this.procede = true;
                } else if (input == "efectivo" && input == "mixto" && dif > 0) {
                    this.procede = false;
                } else {
                    this.procede = true;
                }
            } else {
                this.procede = true;
                this.totalPay = acum;
            }
        },
        mtdGetAcum() {
            let acum = 0;
            if (this.payment_new.method == "efectivo") {
                acum = parseFloat(this.payment_new.amount.efectivo);
            } else if (this.payment_new.method == "tarjeta") {
                acum = parseFloat(this.payment_new.amount.tarjeta);
            } else if (this.payment_new.method == "deposito") {
                acum = parseFloat(this.payment_new.amount.deposito);
            } else if (this.payment_new.method == "transferencia") {
                acum = parseFloat(this.payment_new.amount.transferencia);
                // }else if (this.payment_new.method == "planilla"){
                //   acum = parseFloat(this.payment_new.amount.planilla);
            } else if (this.payment_new.method == "mixto") {
                acum =
                    parseFloat(this.payment_new.amount.efectivo) +
                    parseFloat(this.payment_new.amount.tarjeta) +
                    parseFloat(this.payment_new.amount.deposito) +
                    parseFloat(this.payment_new.amount.transferencia);
                // + parseFloat(this.payment_new.amount.planilla);
            }
            return acum;
        },
        mtdSearchDocument: function () {
            if (this.bottonActiveT == "BOLETA") {
                if (this.chkPatiente.document.length == 8 && this.chkDoc == false) {
                    this.get({
                            url: this.$store.getters.get__url + "/reniec/" + this.chkPatiente.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.client.document = "";
                                this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            } else {
                                this.chkPatiente.fullName = response.data.name + " " + response.data.last_name;
                                this.chkPatiente.address = "";
                            }
                        })
                        .catch((errors) => {});
                }
            } else if (this.bottonActiveT == "FACTURA") {
                if (this.chkPatiente.document.length == 11 && this.chkDoc == false) {
                    this.get({
                            url: this.$store.getters.get__url + "/reniec/" + this.chkPatiente.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.client.document = "";
                                this.$refs.SweetAlert.showWarning("RUC no encontrado");
                            } else {
                                this.chkPatiente.fullName = response.data.razon_social;
                                this.chkPatiente.address = response.data.direccion;
                            }
                        })
                        .catch((errors) => {});
                }
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        activarTipo: function (value) {
            this.bottonActiveT = value;
            this.payment_new.document = value;
            if (value == "BOLETA" || value == "FACTURA") {
                this.disabledChkDoc = false;
            } else {
                this.disabledChkDoc = true;
            }
            this.chkDoc = true;
            this.mtdSelectDoc();
        },
        mtdSelectDoc: function () {
            if (this.chkDoc) {
                if (this.sale.patient) {
                    this.chkPatiente.document = this.sale.patient.document;
                    this.chkPatiente.fullName = this.sale.patient.name;
                }
            } else {
                this.chkPatiente.document = "";
                this.chkPatiente.fullName = "";
            }
        },
        mtdstoredata() {
            this.$emit('mtdstoredata')
        }
    },
};
</script>
