<template>
<div class="container pt-3">
    <div id="div-content-day" class="row">
        <!---PARTE 1-->
        <div class="col-xl-2 col-lg-12 pt-2" id="div-cont-col">
            <div v-for="(item, index) in itemsNota" :key="index">
                <div class="row">
                    <div class="col-md-12 mb-1">
                        <div class="row">
                            <div class=" col-md-6 div-text text-start">{{ item }}</div>
                            <div class="col-md-6">
                                <input type="text" class="form-control form-control-input" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---PARTE 2-->
        <div class="col-xl-5 col-lg-12" id="div-cont-col">
            <div class="row pt-3">
                <div class="input-group-sm col-md-4 col-sm-6 mb-3">
                    <input type="date" class="form-control form-control-input" autocomplete="off">
                </div>
                <div class="input-group-sm col-md-6 col-sm-6 justify-content-end mb-3">
                    <input type="text" class="form-control form-control-input text-content" placeholder="Lic." autocomplete="off">
                </div>
            </div>
            <div class="row pt-4 mb-3">
                <div class="input-group">
                    <textarea class="form-control text-content" rows="5"></textarea>
                </div>
            </div>
        </div>
        <!---PARTE 3-->
        <div class="col-xl-5 col-lg-12">
            <div class="text-row text-start pt-2">
                <p class="fw-bold">Hemograma 01/01/23</p>
            </div>
            <div class="input-group pt-2">
                <textarea class="form-control text-content" rows="3"></textarea>
            </div>
            <div class="row pt-xl-2">
                    <div class="py-2">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label text-content">Encaras: </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label text-content" for="inlineCheckbox1">Si</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label text-content" for="inlineCheckbox2">No</label>
                        </div>
                    </div>
                </div>

        </div>
    </div>
    <div id="div-content-night" class="row mt-2">
        <!---PARTE 1-->
        <div class="col-xl-2 col-lg-12 pt-2" id="div-cont-col">
            <div v-for="(item, index) in itemsNota" :key="index">
                <div class="row">
                    <div class="col-md-12 mb-1">
                        <div class="row">
                            <div class=" col-md-6 div-text text-start">{{ item }}</div>
                            <div class="col-md-6">
                                <input type="text" class="form-control form-control-input" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---PARTE 2-->
        <div class="col-xl-5 col-lg-12" id="div-cont-col">
            <div class="row pt-3">
                <div class="input-group-sm col-md-4 col-sm-6 mb-3">
                    <input type="date" class="form-control form-control-input" autocomplete="off">
                </div>
                <div class="input-group-sm col-md-6 col-sm-6 justify-content-end mb-3">
                    <input type="text" class="form-control form-control-input text-content" placeholder="Lic." autocomplete="off">
                </div>
            </div>
            <div class="row pt-4 mb-3">
                <div class="input-group">
                    <textarea class="form-control text-content" rows="5"></textarea>
                </div>
            </div>
        </div>
        <!---PARTE 3-->
        <div class="col-xl-5 col-lg-12">
            <div class="text-row text-start pt-2">
                <p class="fw-bold">Hemograma 01/01/23</p>
            </div>
            <div class="input-group pt-2">
                <textarea class="form-control text-content" rows="3"></textarea>
            </div>
            <div class="row pt-xl-2">
                    <div class="py-2">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label text-content">Encaras: </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <label class="form-check-label text-content" for="inlineCheckbox1">Si</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <label class="form-check-label text-content" for="inlineCheckbox2">No</label>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-asistencial-ucineo-historia-notaenfermeria',
    components: {

    },
    data() {
        return {
            itemsNota: ['PC', 'FR', 'HGT', 'PAS', 'PAD', 'PAM', 'SAT02', 'FO2',
                'HT6', 'T', 'DIURESIS', 'DIAS VIAS CENT.', 'DIAS VIAS PERIF.'
            ]

        }
    },
    methods: {
        ...mapActions(['get', 'post']),
    }

}
</script>
