<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2 row mx-0">

        <div class="col-md-12 col-lg-12 col-xs-12 mb-2">
            <label for="name" class="mb-2">Monto a {{ type == 'Retiro' ? 'retirar' : 'depositar'}}</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 12px; border: none" class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-money-bill"></i></span>
                </div>
                <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" name="amount" v-model="movimiento.amount" placeholder="0.00" @input="validateAmount" />
            </div>
            <span class="text-danger text-alert" v-if="!isValidInput">
                {{ mtdMessage }}</span>
        </div>

        <div class="col-md-12 col-lg-12 col-xs-12 mb-2">
            <label for="name" class="mb-2">Motivo</label>
            <textarea class="form-control form-control-sm" v-model="movimiento.motive" placeholder="Motivo" rows="2" style="resize: none"></textarea>
        </div>

        <div class="col-md-12 col-lg-12 col-xs-12 mb-3">
            <label for="name" class="mb-2">{{ type == 'Retiro' ? 'Para depositar en' : 'Origen del dinero'}}</label>
            <div class="form-inline">
                <div class="form-check form-check-inline" v-if="type == 'Retiro'">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="2" v-model="movimiento.destination">
                    <label class="form-check-label" for="inlineRadio1">Caja</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="1" v-model="movimiento.destination">
                    <label class="form-check-label" for="inlineRadio2">Banco</label>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-lg-12 col-xs-12 mb-2" v-if="movimiento.destination == 1">
            <div class="row d-flex">
                <div class="col-md-4 col-lg-4 col-sm-4" v-for="(bank, index) in dataBanks" :key="index">
                    <button class="w-100 h-100" :class="index !== pos ? 'button-bank' : 'button-bank-inverse'" @click="activarTipo(index,'bank')">
                        <div>
                            <i class="far fa-university fa-2x mt-2"></i>
                        </div>
                        <div>{{ bank.name }} - {{ bank.currency[0].currency }}</div>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-lg-12 col-xs-12 mb-2" v-if="movimiento.destination == 2">
            <div class="row d-flex">
                <div class="col-md-6 col-lg-6 col-sm-6" v-for="(box, index) in dataBoxes" :key="index">
                    <button class="w-100" :class="index !== posBox ? 'button-bank' : 'button-bank-inverse'" @click="activarTipo(index,'box')">
                        <div>
                            <i class="far fa-cash-register fa-2x my-2"></i>
                        </div>
                        <div>{{ box.name }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdInsert" :disabled="mtdDisableButton || !isValidInput">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-tesoreria-cajaFuerte-addDepositedOrRetirement",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            dataBanks: [],
            dataBoxes: [],
            pos: -1,
            posBox: -1,
            movimiento: {
                amount: "",
                type: "",
                bead_id: "",
                box_id: null,
                bank_id: null,
                destination: "",
                motive: "",
            },
            isValidInput: true,
            finalBeadOrigen: true,
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        type: String,
        bead: Object,
    },
    created() {
        this.mtdGetDataBanks();
    },
    computed: {
        mtdDisableButton() {
            const disabled = this.movimiento.destination == 1 ? this.movimiento.bank_id : this.movimiento.box_id;
            return this.movimiento.amount == "" || this.movimiento.motive == "" || this.movimiento.destination == "" || disabled == null;
        },
        mtdMessage() {
            //{{ type == 'Retiro' ? 'El monto tiene que ser mayor a S/. 0.00 y menor a S/.'+ bead.final : 'El valor tiene que ser mayor que 0.00' }}
            const msgRetiro = 'El monto tiene que ser mayor a S/. 0.00 y menor a S/.' + this.bead.final;
            const msgDeposito = 'El valor tiene que ser mayor que 0.00';
            return this.type == 'Retiro' ? msgRetiro : msgDeposito;
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        activarTipo(index, option) {
            if (option == 'box') {
                const verificBead = this.dataBoxes[index].beads.length !== 0;
                if (verificBead == true) {
                    this.finalBeadOrigen = parseFloat(this.dataBoxes[index].beads[0].final).toFixed(2);
                    this.posBox = index;
                    this.pos = -1;
                    this.movimiento.box_id = this.dataBoxes[index].id;
                    this.movimiento.bank_id = null;

                } else {
                    this.$refs.SweetAlert.showWarning("No hay caja Aperturada");
                }
            } else {
                this.pos = index;
                this.posBox = -1;
                this.movimiento.bank_id = this.dataBanks[index].currency[0].id;
                this.movimiento.box_id = null;
            }
        },
        mtdGetDataBanks: function () {
            if (this.bead) {
                this.get({
                        url: this.$store.getters.get__url + "/Tesoreria/banks",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataBanks = response.data.banks;
                        this.dataBoxes = response.data.boxes;
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }
        },
        mtdclosemodal(show) {
            this.movimiento = {
                    amount: "",
                    type: "",
                    bead_id: "",
                    box_id: null,
                    motive: "",
                    bank_id: null,
                    destination: "",
                },
                this.pos = -1;
            this.posBox = -1;
            this.isValidInput = true;
            this.mtdGetDataBanks();
            this.$emit('mtdcloseModal', show);
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            // Asignar el valor sanitizado al input
            this.movimiento.amount = sanitizedValue;
            //validar que no sea mayor que el total de caja fuerte
            const final = parseFloat(this.bead.final).toFixed(2);
            const amountInput = parseFloat(sanitizedValue);
            let amount = parseFloat(sanitizedValue).toFixed(2);
            let validation = (amount > 0.00) ? (this.type == 'Retiro' ? amountInput > final : false) : true;
            if (validation == false) {
                this.movimiento.amount = sanitizedValue;
                this.isValidInput = true;

            } else {
                this.isValidInput = false;
            }
        },
        mtdInsert: function () {
            this.movimiento.bead_id = this.bead.id;
            this.movimiento.type = this.type == 'Retiro' ? 'retirement' : 'deposit';
            const amount = parseFloat(this.movimiento.amount);
            const finalBeadOrigen = parseFloat(this.finalBeadOrigen);
            let verifit = true;
            if (this.type !== 'Retiro') {
                verifit = this.movimiento.destination == 1 ? true : amount <= finalBeadOrigen;
            }
            if (verifit) {
                this.post({
                        url: this.$store.getters.get__url + "/Tesoreria/registerStrongboxMovements",
                        token: this.$store.getters.get__token,
                        params: this.movimiento,
                    })
                    .then((response) => {
                        this.$refs.SweetAlert.showSuccess( this.type + " registrado");
                        window.open(
                            this.$store.getters.get__url +
                            "/Tesoreria/viewpdf/cajaFuerte/" +
                            response.data.data.id,
                            "_blank"
                        );
                        this.mtdclosemodal(0);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            } else {
                this.$refs.SweetAlert.showWarning("Fondo insuficiente");
            }
        }

    },
};
</script>

<style scoped>
.text-alert {
    font-size: 9px;
}

.form-inline {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.button-bank {
    color: #900052;
    background-color: white;
    border-radius: 20px;
    border: 2px solid #900052;
    font-size: 13px;
}

.button-bank-inverse {
    color: white;
    background-color: #900052;
    border-radius: 20px;
    border: 2px solid white;
    font-size: 13px;
}
</style>
