<template>
    <div class="h-100 py-3 d-flex align-items-center">
        <div class="  container px-5  ">
            <div class="text-white  w-100  ">
                <div id="content-timelinetable" class="mb-2">
                    <table class=" table  table-borderless px-0 text-xs">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-hospitalizacion ">
                            <th scope="col">Fecha</th>
                            <th scope="col">Hora</th>
                            <th scope="col">Temp</th>
                            <th scope="col">PAS</th>
                            <th scope="col">PAD</th>
                            <th scope="col">PVC</th>
                            <th scope="col">PCWP</th>
                            <th scope="col">FC</th>
                            <th scope="col">Sat 02</th>
                            <th scope="col">Fi 02</th>
                            <th scope="col">Diuresis</th>
    
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row " class="">
                                <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example">
                            </th>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                            <td>
                                <input class="form-control   form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example">
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>    
    </template>
<script>
import {mapActions} from 'vuex'
export default{
    name: 'c-asistencial-sop-historia-bhe',
    data() {
        return {
            //property: 'value',
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>