<template>
<div class="p-3">
    <!-- Botones iniciales -->
    <div class="d-flex flex-row-reverse">
        <div class="mt-1 mb-0 d-inline-block btn-home">
            <button @click="mtdCloseBead(0)" class="btn btn-outline-main mb-2">
                <i :class="bead.authorize == 1 ?'fas fa-spinner' : 'fas fa-window-close'"></i> {{bead.authorize==0?' Solicitar Aprobación':
          (bead.authorize==1?"Pendiente de Aprobacion":(bead.authorize==2?"Cerrar Caja":"Corregir Cierre"))}}
            </button>
            &nbsp;
            <button type="button" class="btn btn-outline-main mb-2" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>
    <!-- Arqueo de caja -->
    <div class="row d-flex justify-content-center mt-2">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div id="div-modal-caja" class="d-flex justify-content-center">
                <div id="div-caja" class="card w-100">
                    <div class="card-header text-white h4 text-center bg-main">
                        <strong>ARQUEO DE CAJA</strong>
                    </div>
                    <div class="card-body" style="max-height: 485px; overflow-y: auto">
                        <div id="div-dataMoney" class="container">
                            <div class="row d-flex">
                                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 mt-0 text-center">
                                    <label class="form-label text-center"><strong>Billetes</strong></label>
                                    <div v-for="(item, index) in process.billetes" :key="index" class="">
                                        <div class="input-group input-group-sm mb-2">
                                            <span class="input-group-text col-4">{{ item.label }}</span>
                                            <input :disabled="bead.authorize==2" type="text" class="form-control form-control-sm" @keyup="cpContarBilletes" @keydown="filterKey" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                            <span class="input-group-text col-5">= S/. {{ item.total == 0 ? '0.00' : item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12 text-center">
                                    <label class="form-label text-center"><strong>Monedas</strong></label>
                                    <div v-for="(item, index) in process.monedas" :key="index" class="">
                                        <div class="input-group input-group-sm mb-2">
                                            <span class="input-group-text col-4">{{ item.label }}</span>
                                            <input :disabled="bead.authorize==2" type="text" class="form-control form-control-sm" @keyup="cpContarBilletes" @keydown="filterKey" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                            <span class="input-group-text col-5">= S/. {{ item.total == 0 ? '0.00' : item.total }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-12 col-lg-4 col col-sm-12 text-center">
                                    <strong> TOTAL EFECTIVO </strong>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>APERTURA: S/. {{ bead.initial }}</strong>
                                    </button>

                                    
                                </div>
                                <div class="col-md-12 col-lg-4 col col-sm-12 text-center">
                                    <strong> MOVIMIENTOS </strong>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white" @click="mtdShowModal(bead.id, 1)">
                                        <strong>DEPOSITO: S/. {{ parseFloat(bead.deposit).toFixed(2) }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white" @click="mtdShowModal(bead.id, 2)">
                                        <strong>RETIROS: S/. {{ parseFloat(bead.retirement).toFixed(2) }}</strong>
                                    </button>
                                </div>
                                <div class="col-md-12 col-lg-4 col col-sm-12 text-center">
                                    <strong> SALDOS </strong>
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>TOTAL:S/. {{ cpEfectitoTotal }}</strong>
                                    </button>

                                    <!-- <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong>Saldo General S/{{ cpSaldoGeneral }}</strong>
                                    </button> -->
                                    <button class="btn btn-sm w-100 mt-1 bg-main text-white">
                                        <strong v-if="cpSobranteFaltante < 0">
                                            Faltante S/{{ cpSobranteFaltante }}</strong>
                                        <strong v-else> Sobrante S/{{ cpSobranteFaltante }}</strong>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalMovementDetailVue :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" :type="modal.type" @closeModal="closeModal"/>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtdCloseFinal="mtdCloseFinal" @mtdSendPermissionCloseBead="mtdSendPermissionCloseBead" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModalMovementDetailVue from "../modals/cModalMovementDetail.vue";
export default {
    name: "c-admision-pages-close",
    components: {
        SweetAlert,
        cModalMovementDetailVue
    },
    props: {
        verifBead: Boolean,
        verifBox: Boolean,
        bead: Object,
    },
    data() {
        return {
            swal: null,
            process: {
                billetes: [{
                        label: "200.00",
                        total: 0,
                        key: "dosCientos",
                        value: 200,
                        cantidad: "",
                    },
                    {
                        label: "100.00",
                        total: 0,
                        key: "unCiento",
                        value: 100,
                        cantidad: "",
                    },
                    {
                        label: "50.00",
                        total: 0,
                        key: "cincuenta",
                        value: 50,
                        cantidad: "",
                    },
                    {
                        label: "20.00",
                        total: 0,
                        key: "veinte",
                        value: 20,
                        cantidad: "",
                    },
                    {
                        label: "10.00",
                        total: 0,
                        key: "diez",
                        value: 10,
                        cantidad: "",
                    },
                ],
                monedas: [{
                        label: "5.00",
                        total: 0,
                        key: "cinco",
                        value: 5,
                        cantidad: "",
                    },
                    {
                        label: "2.00",
                        total: 0,
                        key: "dos",
                        value: 2,
                        cantidad: ""
                    },
                    {
                        label: "1.00",
                        total: 0,
                        key: "uno",
                        value: 1,
                        cantidad: ""
                    },
                    {
                        label: "0.50",
                        total: 0,
                        key: "cincuentaCentimos",
                        value: 0.5,
                        cantidad: "",
                    },
                    {
                        label: "0.20",
                        total: 0,
                        key: "veinteCentimos",
                        value: 0.2,
                        cantidad: "",
                    },
                    {
                        label: "0.10",
                        total: 0,
                        key: "diezCentimos",
                        value: 0.1,
                        cantidad: "",
                    },
                ],
                observation: "",
                totEfectivo: 0,
                totTurned: 0,
                totIncome: 0,
                totExpense: 0,
                totAnulacion: 0,
                totTransferencia: 0,
                totDeposito: 0,
                totTarjeta: 0,
                totPlanilla: 0,
                totalArqueo: 0,
                retirement: 0,
                deposit: 0,
                initial: 0,
            },
            finalCash: 0,
            modal: {
                title: '',
                modal_form: false,
                payload: [],
                type: null
            },
        }
    },
    watch: {
        bead: {
            handler(newVal) {
                /* if(newVal == true){
                } */
                this.mtdProccess();
                
            },
            immediate: true
        }
    },
    computed: {
        cpEfectitoTotal() {
            
            let tot =
                parseFloat(this.bead.initial) +
                parseFloat(this.process.deposit) -
                parseFloat(this.process.retirement);
            // +
            // parseFloat(this.process.totTurned);
            this.process.totEfectivo = parseFloat(tot);
            // +
            // parseFloat(this.process.totTurned);
            this.process.totEfectivo = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpSobranteFaltante() {
            let dif =
                parseFloat(this.finalCash) -
                parseFloat(this.process.totEfectivo);
            return parseFloat(dif).toFixed(2);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdProccess(){
            let transaction = this.bead.transaction[0];
            //contar los depositos y retiros
            this.process.deposit = this.bead.deposit;
            this.process.retirement = this.bead.retirement;
            if(this.bead.authorize != 0){
            this.process = JSON.parse(transaction.json_cierre);
                this.cpContarBilletes();
            }

        },
        /**abrir CONTAR BILLETES en el arqueo*/
        cpContarBilletes: function () {
            let total = 0;
            this.process.billetes.forEach((element) => {
                let can = 0;
                if (!element.cantidad) {
                    can = 0;
                }
                can = element.cantidad != "" && element.cantidad != null ?
                    parseFloat(element.cantidad).toFixed(2) : 0;
                let tot = parseFloat(element.value).toFixed(2);
                element.total = tot == NaN ? 0 : parseFloat(can * tot).toFixed(2);
                total += parseFloat(element.total);
            });

            this.process.monedas.forEach((element) => {
                let can = 0;
                if (!element.cantidad) {
                    can = 0;
                }
                can = element.cantidad != "" && element.cantidad != null ?
                    parseFloat(element.cantidad).toFixed(2) : 0;
                let tot = parseFloat(element.value).toFixed(2);
                element.total = tot == NaN ? 0 : parseFloat(can * tot).toFixed(2);
                total += parseFloat(element.total);
            });
            this.finalCash = total;
        },
        /* Cerrar caja o pedir el permiso para el cierre de caja */
        mtdCloseBead: function (status) {
            if (this.finalCash > 0) {
                this.process.status = status;
                this.process.box = this.bead.box_id;
                this.process.vueltos = []; // falta
                this.process.initial = this.bead.initial;
                this.process.observation = "";
                this.process.bead = this.bead.id;
                if (this.bead.authorize == 0) {
                    this.mtdSendPermissionCloseBead();
                } else if (this.bead.authorize == 1) {
                    let title = "Va reenviar la Solictud de Aprobación";
                    let buttonText = "Reenviar";
                    this.$refs.SweetAlert.showActionBeads(title, buttonText, 1);
                } else if (this.bead.authorize == 2) {
                    let title = "Cerrar Caja";
                    let buttonText = "Confirmar";
                    this.$refs.SweetAlert.showActionBeads(title, buttonText, 2);
                } else {
                    let title = "Enviar Corrección para la Autorización";
                    let buttonText = "Enviar";
                    this.$refs.SweetAlert.showActionBeads(title, buttonText, 1);
                }
            } else {
                this.$refs.SweetAlert.showWarning("Digite cantidades de arqueo");
            }
        },
        mtdCloseFinal() {
             this.post({
                    url: this.$store.getters.get__url + "/Bead/closeFinal",
                    token: this.$store.getters.get__token,
                    params: {
                        process: this.process
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess("Arqueo de Caja Finalizado");
                        const beadId = this.bead.id;
                        window.open(
                            this.$store.getters.get__url + "/ReportesCajas/viewpdf/cierrecajafuerte/" + beadId,
                            "_blank"
                        );
                        this.$emit("mtdRefreshPage");
                    }

                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdSendPermissionCloseBead() {
            this.post({
                    url: this.$store.getters.get__url + "/Bead/storeClosePermission",
                    token: this.$store.getters.get__token,
                    params: {
                        process: this.process
                    },
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.$emit("mtdRefreshPage");
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },

        mtdShowModal: function (idBead, type) {
            this.post({
                url: this.$store.getters.get__url + "/Tesoreria/reportMovementsDetail",
                token: this.$store.getters.get__token,
                params: {
                    idBead: idBead,
                    type: type
                }
            })
            .then((response) => {
                this.modal.type = type;
                this.modal.title = (type == 1 ? "Detalle de Movimientos de Depósito" : "Detalle de Movimientos de Retiro");
                this.modal.payload = response.data;
                this.modal.modal_form = true;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                payload: [],
                type: null
            };
        },

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        
    }
}
</script>
