<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-1">
        <div class=" row col-xl-12  mx-0 px-0 ">
            <div class="mt-2">
                <div class="row mx-0 px-0 ">
                    <div class="col-xl-12 cl-lg-12 col-md-12 col-sm-12 px-1  mb-3 ">
                        <label for=""> Marcas </label>
                        <v-select class="text-dark form-control" label="name" :options="marcas" placeholder="- Buscar Marca (Escriba uno...) -" @search="searchmarca" v-model="selectedMarca" :reduce="(name) => name.id" required :clearable="false" @input="mtdproducto()">
                            <template slot="no-options">
                                No hay Productos para la busqueda.
                            </template>
                        </v-select>
                    </div>
                    <div class="row mx-0 px-0" v-if="selectedMarca !=''">

                        <div class="col-xl-6 px-1">
                            <h6>Medicamentos no Selecionados</h6>
                            <div class="input-group input-group mb-2">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input v-model="search" type="text" class="form-control-sm form-control" placeholder="Escriba un medicamento no seleccionado para realizar la búsqueda" @input="calculateData(0)" />
                            </div>
                            <div class="table-wrapper" style="max-height: 380px; overflow-y: auto;">
                                <div class="col-md-12 table-responsive" id="div-tabla-anular" style="font-size: 11px;">
                                    <table id="tb-anular" class="table table-bordered">
                                        <caption class="mx-0 px-0"></caption>
                                        <thead class="bg-main text-xxs">
                                            <tr class="text-white">
                                                <th scope="col" class="align-middle">N°</th>
                                                <th scope="col" class="align-middle">NOMBRE</th>

                                                <th scope="col" class="align-middle">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody class="border-main text-xxs">
                                            <tr class="tbody-table" v-for="(item, index) in productos_no_seleccionado" :key="index">
                                                <td class="align-middle">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="align-middle">
                                                    {{ item.subsubcategorie.name }}
                                                </td>

                                                <td class="align-middle text-center" @click="mtdnoselecionado(item)">
                                                    <button type="button" class=" btn btn-success btn-sm px-1 py-0 ">
                                                        <i class="fas fa-plus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="!productos_no_seleccionado.length > 0" class="text-center">
                                                <th scope="col" colspan="3">No hay registros</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 px-1">
                            <h6>Medicamentos Seleccionados</h6>
                            <div class="input-group input-group mb-2">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input v-model="search1" type="text" class="form-control-sm form-control" placeholder="Escriba un medicamento seleccionado para realizar la búsqueda" @input="calculateData(1)" />
                            </div>
                            <div class="table-wrapper" style="max-height: 450px; overflow-y: auto;">
                                <div class="col-md-12 table-responsive" id="div-tabla-anular" style="font-size: 11px;">
                                    <table id="tb-anular" class="table table-bordered">
                                        <caption class="mx-0 px-0"></caption>
                                        <thead class="bg-main text-xxs">
                                            <tr class="text-white">
                                                <th scope="col" class="align-middle">N°</th>
                                                <th scope="col" class="align-middle">NOMBRE</th>
                                                <th scope="col" class="align-middle">PRECIO</th>
                                                <th scope="col" class="align-middle">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody class="border-main text-xxs">
                                            <tr class="tbody-table" v-for="(item, index) in productos_seleccionado" :key="index">
                                                <td class="align-middle">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="align-middle">
                                                    {{ item.subsubcategorie.name}}
                                                </td>
                                                <td class="align-middle" @dblclick="mtdSelectInput(index)">
                                                    <span v-if="posEdit != index">{{ item.price_purchase}} </span>
                                                    <input v-else type="text" class="form-control text-center form-control-sm w-100" v-model="newPrice" @keyup.enter="mtdOutFocusEditPrice" @blur="mtdOutFocusEditPrice" @input="validateAmount" placeholder="0.00" />
                                                </td>
                                                <td class="align-middle text-center">
                                                    <button type="button" class=" btn btn-danger btn-sm px-1 py-0" @click="mtdselecionado(item)">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="!productos_seleccionado.length > 0" class="text-center">
                                                <th scope="col" colspan="4">No hay registros</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="mtdaddproductos">
            Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-marca",
    components: {
        CModal,
        SweetAlert

    },
    data() {
        return {
            swal: null,
            posEdit: null,
            oldPrice: null,
            productos_provider: {
                id: null,
                productoss: []
            },
            productos_seleccionado: [],
            productos_no_seleccionado: [],
            newPrice: null,
            marcas: [],
            selectedMarca: '',
            Productos: [],
            selectedProducto: '',
            marca_detail: '',
            search1: '',
            search: '',
            dataoriginal_noselecionado: [],
            dataoriginal_selecionado: []
        };
    },

    props: {
        title: String,
        boo_modal: Boolean,

        proveedor_id: {
            type: Number,
            default: null
        },

    },

    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.productos_seleccionado = []
            this.productos_no_seleccionado = []
            this.marcas = [],
                this.marca = {
                    id: '',
                    name: ''
                }
            this.search1 = '',
                this.search = '',
                this.posEdit = null;
            this.selectedMarca = ''
            this.$emit("closeModalProducto");
        },

        calculateData(item) {

            let dataProductos = (item == 0) ? this.productos_no_seleccionado : this.productos_seleccionado;
            let buscar = (item == 0) ? this.search : this.search1;
            if (buscar.length >= 2 && buscar != "") {
                let query = buscar.toString().toUpperCase();
                let filteredData = dataProductos.filter((element) => {
                    let name = element.subsubcategorie.name.toString().toUpperCase();
                    return name.includes(query);
                });

                if (item == 0) {
                    this.productos_no_seleccionado = filteredData;
                } else {
                    this.productos_seleccionado = filteredData;
                }
            } else {
                if (item == 0) {
                    this.productos_no_seleccionado = this.dataoriginal_noselecionado;
                } else {
                    this.productos_seleccionado = this.dataoriginal_selecionado;
                }
            }

        },

        searchmarca: function (search, loading) {
            if (search.length > 3) {
                this.marcas = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/logistics/indexbrand/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.marcas = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        mtdproducto: function () {

            let data = {
                marca: this.selectedMarca,
                provider_id: this.proveedor_id
            }

            this.post({
                    url: this.$store.getters.get__url + "/logistics/indexProductProvider",
                    token: this.$store.getters.get__token,
                    params: data
                })
                .then(response => {

                    this.productos_seleccionado = response.data.productos_seleccionado;
                    this.productos_no_seleccionado = response.data.productos_no_seleccionado;
                    this.dataoriginal_selecionado = response.data.productos_seleccionado
                    this.dataoriginal_noselecionado = response.data.productos_no_seleccionado;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdnoselecionado: function (item) {
            const index = this.productos_no_seleccionado.findIndex(servicio => servicio.id === item.id);
            if (index !== -1) {
                const ServicioQuitado = this.productos_no_seleccionado.splice(index, 1)[0];
                this.productos_seleccionado.push(ServicioQuitado);
            }

        },
        mtdselecionado: function (item) {
            const index = this.productos_seleccionado.findIndex(servicio => servicio.id === item.id);
            if (index !== -1) {
                const ServicioQuitado = this.productos_seleccionado.splice(index, 1)[0];
                this.productos_no_seleccionado.push(ServicioQuitado);
            }
        },
        mtdaddproductos: function () {
            this.productos_provider = {
                id: this.proveedor_id,
                brand_id: this.selectedMarca,
                productos: this.productos_seleccionado,
            }
            this.productos_seleccionado = []
            this.productos_no_seleccionado = []
            this.marcas = [],
                this.selectedMarca = ''
            this.search1 = '',
                this.search = '',
                this.$emit('mtdaddproductos', this.productos_provider);
        },
        mtdSelectInput: function (pos) {
            this.posEdit = pos;
            this.oldPrice = this.productos_seleccionado[pos].price_purchase;
            this.newPrice = this.productos_seleccionado[pos].price_purchase;

        },
        mtdOutFocusEditPrice: function () {

            if (this.oldPrice != this.newPrice && this.newPrice > 0) {
                Swal.fire({
                    icon: "warning",
                    title: "¿Desea cambiar este precio?",
                    width: "400px",
                    /** buttons */
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.mtdCommitEditPrice();
                    } else if (result.isDenied) {
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                    }
                });
            } else {
                this.posEdit = null;
                this.oldPrice = null;
                this.newPrice = null;
            }
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.newPrice = sanitizedValue;
        },
        mtdCommitEditPrice: function () {

            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/update/pricepurchase",
                    token: this.$store.getters.get__token,
                    params: {
                        detail: this.productos_seleccionado[this.posEdit].id,
                        provider: this.proveedor_id,
                        price: this.newPrice,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.productos_seleccionado[this.posEdit].price_purchase = parseFloat(this.newPrice).toFixed(2);
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                        Swal.fire({
                            icon: "success",
                            title: "Editado correctamente",
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Error",
                        text: errors,
                        icon: "error",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },

    },

};
</script>
