<template>
<div>
    <div id="div-modal-anular" class="d-flex justify-content-center">
        <div id="div-anular" class="card w-100">
            <div class="card-body w-100" style="background-color: #f2f4f4">
                <div class="row d-flex">
                    <!-- filtro por proveedores -->
                    <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 mt-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-4">Provedor</span>
                            <select id="campu_id" class="form-control form-control" v-model="filter.provider" @change="mtdSearchFilter">
                                <option selected disabled value="">Seleccione ...</option>
                                <option selected value="todos">TODOS</option>
                                <option v-for="(item, index) in dataProviders" :key="index" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- filtro por tipo -->
                    <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                        <div class="row p-2">
                            <div class="col-12">
                                <div class="row d-flex card-filter">
                                    <div class="col-md-3 col-sm-12">
                                        <span>Estado</span>
                                    </div>
                                    <div class="col-md-9 col-sm-12 d-flex align-items-center">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox1" value="1" v-model="filter.state" @change="mtdGetData">
                                            <label class="form-check-label" for="inlineCheckbox1">Vencido</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox3" value="3" v-model="filter.state" @change="mtdGetData">
                                            <label class="form-check-label" for="inlineCheckbox3">Pendiente</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox2" value="2" v-model="filter.state" @change="mtdGetData">
                                            <label class="form-check-label" for="inlineCheckbox2">Por vencer</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div :class="
                cpPagoMasivo == true
                  ? 'col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12'
                  : 'col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12'">
                        <div class=" px-0">
                            <div class="input-group input-group mb-3">
                                <span class="input-group-text text-white bg-main "><i class="fas fa-search"></i></span>
                                <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar por N°" @input="mtdSearchFilter" />
                            </div>
                        </div>
                    </div>
                    <div class="btn-home col-xs-12" :class="
                cpPagoMasivo == true
                  ? 'col-xl-2 col-lg-2 col-md-6 col-sm-12 mb-2'
                  : 'col-xl-3 col-lg-3 col-md-3 col-sm-12'">
                        <button type="button" name="accion" class="btn btn-outline-main w-100" @click="mtdOpenModalReporte">
                            <i class="fas fa-chart-bar"></i> Reporte
                        </button>
                    </div>
                    <div v-show="cpPagoMasivo == true" class="btn-home col-xs-12 mb-2" :class="
                cpPagoMasivo == true
                  ? 'col-xl-2 col-lg-2 col-md-6 col-sm-12'
                  : 'col-xl-3 col-lg-3 col-md-3 col-sm-12'">
                        <button type="button" name="accion" class="btn btn-outline-main w-100" @click="mtdPagoMasivo">
                            <i class="fas fa-money-check-alt"></i> Pago Masivo
                        </button>
                    </div>
                    <div v-if="dataorders.length > 0 && cpData.length > 0" class="d-flex justify-content-end px-2">
                        <nav aria-label="Page navigation example ">
                            <ul class="pagination mb-0 ">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                </li>
                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                </li>
                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>

                                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                    </a>
                                </li>
                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                    <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="col-md-12 table-responsive" id="div-tabla-anular">
                        <p id="mydesc"></p>
                        <table id="content-timelinetable " class="table table-bordered" aria-describedby="mydesc">
                            <caption class="py-0 my-0"></caption>
                            <thead class="bg-main">
                                <tr class="text-white text-center text-xs">
                                    <th scope="col" class="align-middle"></th>
                                    <th scope="col" class="align-middle">PROVEEDOR</th>
                                    <th scope="col" class="align-middle">RUC</th>
                                    <th scope="col" class="align-middle table-th-acciones">N°</th>
                                    <th scope="col" class="align-middle">FECHA EMISION</th>
                                    <th scope="col" class="align-middle">FECHA DE VENCIMIENTO</th>
                                    <th scope="col" class="align-middle">S/ EXONERADO</th>
                                    <th scope="col" class="align-middle">S/ INAFECTO</th>
                                    <th scope="col" class="align-middle">MONEDA</th>
                                    <th scope="col" class="align-middle">DETRACCION/ NO APLICA</th>
                                    <th scope="col" class="align-middle">S/ TOTAL</th>
                                    <th scope="col" class="align-middle">S/ PAGADO</th>
                                    <th scope="col" colspan="1" class="align-middle">S/ PENDIENTE</th>
                                    <th scope="col" colspan="1" class="align-middle">PAGOS</th>
                                </tr>
                            </thead>
                            <tbody class="border-main text-xs">
                                <tr class="tbody-table" v-for="(items, index) in cpData" :key="index">
                                    <td>
                                        <input class="form-check-input mt-0" type="checkbox" aria-label="Checkbox for following text input" v-model="items.pagoMasivo">
                                    </td>
                                    <td>
                                        {{ items.provider.name }}
                                    </td>
                                    <td>
                                        {{ items.provider.ruc }}
                                    </td>
                                    <td>{{ items.number }}</td>
                                    <td>{{ items.date | formatDate }}</td>
                                    <td>{{ items.due_date | formatDate }}  
                                        <div class="mt-1 ms-1">
                                            <span v-if="items.status == 1" class="badge text-bg-danger">Vencido</span>
                                            <span v-if="items.status == 3" class="badge text-bg-warning">Pendiente</span>
                                            <span v-if="items.status == 2" class="badge text-bg-primary">Por Vencer</span>
                                        </div>
                                    </td>
                                    <td>{{ items.exonerated }}</td>
                                    <td>{{ items.unaffected }}</td>
                                    <td>{{ items.money }}</td>
                                    <td>{{ items.detraction }}</td>
                                    <td>{{ items.total }}</td>
                                    <td>{{ items.paid == null? '0.00':items.paid}}</td>
                                    <td class="fw-bolder">
                                        {{ items.pending }}
                                    </td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center">
                                            <button class="btn btn-sm a-success text-white" @click="openModalPay(items)">
                                                <i class="fas fa-money-bill" style="font-size: 20px;"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!cpData.length > 0 && filter.state.length > 0" class="text-center">
                                    <th scope="col" colspan="14">NO SE ENCONTRARON RESULTADOS</th>
                                </tr>
                                <tr v-if="!cpData.length > 0 && filter.state.length == 0" class="text-center">
                                    <th scope="col" colspan="14">Seleccione un Estado</th>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
        <cModalRegisterPay :title="modalPay.title" :billpay="modalPay.bill" :boo_modal="modalPay.modal_form" @mtdcloseModalPay="mtdcloseModalPay" />
        <cModalReportPay :title="modalReport.title" :type="modalReport.type" :boo_modal="modalReport.modal_form" @mtdCloseModalReporte="mtdCloseModalReporte" />
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import moment from "moment";
import cModalRegisterPay from "@/components/Tesoreria/modals/cModalRegisterPay.vue";
import cModalReportPay from "@/components/Tesoreria/modals/cModalReportPay.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
export default {
    name: "c-Tesoreria-pages-Oc",
    components: {
        cModalRegisterPay,
        cModalReportPay,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data: [],
            filter: {
                provider: "todos",
                state: ["1", "2","3"],
            },
            dataShow: [],
            dataorders: [],
            totalPagesToShow: 10,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            modalViewDispatch: {
                title: "",
                modal_form: false,
            },
            modalPay: {
                title: "",
                modal_form: false,
                bill: {
                    type: "",
                    id: '',
                    number: '',
                    total: '',
                    paid: '',
                    pending: '',
                    centralCost: '',
                }
            },
            modalReport: {
                title: "",
                modal_form: false,
                type: "",
            },
            search: '',
            dataProviders: [],

        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
        this.mtdGetData();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        cpPagoMasivo() {
            return  this.dataorders.length ? this.dataorders.some(element => element.pagoMasivo === true) : false;
        }

    },
    methods: {
        ...mapActions(["get", "post"]),
        changePage(page) {
            this.$emit("changePage", page);
        },

        mtdSearchFilter() {
            this.calculateData(10);
        },
        mtdGetData: function () {
            if(this.filter.state.length > 0){
                this.get({
                        url: this.$store.getters.get__url + "/Tesoreria/ocPorPagar/"+ this.filter.state,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataorders = response.data;
                        this.fillSuppliers();
                        this.calculateData(10);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            }else{
                this.dataorders = [];
                this.calculateData(10);
            }
        },
        fillSuppliers() {
            const providerSet = new Set();
            if(this.dataorders.length > 0){
                this.dataorders.forEach(order => {
                    providerSet.add(JSON.stringify(order.provider));
                });
                this.dataProviders = Array.from(providerSet).map(provider => JSON.parse(provider));
            }
        },
        openModalPay: function (bill) {
            const type = bill.number.substring(0, 1) === 'F' ? "FACTURA" : "BOLETA";
            const title = "GENERAR PAGO A LA " + type + " " + bill.number;
            this.modalPay = {
                title: title,
                modal_form: true,
                bill: {
                    id: bill.id,
                    number: bill.number,
                    total: bill.total,
                    type: 'oc',
                    paid: bill.paid,
                    pending: bill.pending,
                    centralCost: bill.central_costs
                }
            };
        },
        mtdOpenModalReporte: function () {
            this.modalReport = {
                title: "REPORTE DE PAGOS DE FACTURAS OC",
                modal_form: true,
                type: "oc",
            };
        },
        mtdCloseModalReporte: function () {
            this.modalReport = {
                title: "",
                modal_form: false,
                type: "",
            };
        },
        mtdcloseModalPay: function () {
            this.modalPay = {
                title: "",
                modal_form: false,
                bill: {
                    id: '',
                    number: '',
                    total: '',
                    paid: '',
                    type: '',
                    pending: '',
                    centralCost: '',
                }
            };
            this.mtdGetData();
        },
        mtdPagoMasivo() {
            let paymentBill = [];
            this.dataorders.forEach(element => {
                if (element.pagoMasivo == true) {
                    let bill = {
                        id: element.id,
                        amount: element.pending
                    };
                    paymentBill.push(bill);
                }
            });
        },
        calculateData(items) {
            if (!this.dataorders.length) {
                this.dataShow = [];
            }
            let filteredData = this.dataorders;
            let selectPage = this.selectPage;

            if (this.filter.provider !== "todos") {
                filteredData = this.dataorders.filter((element) => {
                    let query = this.search;
                    let proveedor = element.provider.id;
                    let number = element.number;
                    if(this.search.length >= 2 && this.search != ""){
                        return number.includes(query) && proveedor == this.filter.provider;
                    }else{
                        return proveedor == this.filter.provider;
                    }
                });
            } else {
                if (this.search.length >= 2 && this.search != "") {
                    let query = this.search;
                    filteredData = this.dataorders.filter((element) => {
                        let number = element.number;
                        return number.includes(query);
                    });
                    selectPage = 1;
                }else{
                    filteredData = this.dataorders;
                }
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>
