<template>
<CModal @mtdclosemodal="mtd_close" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="false" :booTitile="true">
    <div class="row d-flex">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" v-if="view=='add'">
            <div class="input-group input-group-sm input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Categoría</span>
                <select class="form-control form-control-sm" v-model="filter.category" @change="mtdSelectCategory">
                    <option :value="null" disabled selected>Seleccione</option>
                    <option v-for="(item, index) in categories" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" v-if="view=='add'">
            <div class="input-group input-group-sm input-group mb-3">
                <span class="input-group-text" id="basic-addon1">SubCategoría</span>
                <select class="form-control form-control-sm" v-model="filter.subcategory" @change="mtdSelectsubCategory">
                    <option :value="null" disabled selected>Seleccione una categoria</option>
                    <option v-for="(item, index) in subcategories" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12" v-if="view=='add'">
            <div class="input-group input-group-sm input-group mb-3">
                <span class="input-group-text" id="basic-addon1">Servicio Específico</span>
                <v-select :options="subsubcategories" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="---- Seleccione una servicio especifico ----" required v-model="filter.subsubcategory" />
            </div>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12" v-if="view=='add'">
            <button class="btn btn-sm w-100 text-white" style="background: #900052" @click="mtdAddService" :disabled="cpButtonForm">
                <i class="fas fa-plus"></i> Agregar
            </button>
        </div>
        <div class="col-md-12 mt-2">
            <div class="row d-flex">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-2 mb-2">
                    <div class="input-group input-group">
                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                        <input @keyup="calculateData(7)" v-model="search" type="text" class="form-control form-control" placeholder="Buscar" />
                    </div>
                </div>

                <div class="col-md-12" id="content-timelinetable">
                    <table class="table table-bordered table-striped table-responsive">
                        <caption class="py-0 my-0"></caption>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Categoria</th>
                                <th>Subcategoria</th>
                                <th>Servicio especifico</th>
                                <th v-if="view=='add'">Borrar</th>
                            </tr>
                        </thead>
                        <tbody class="text-xs">
                            <tr v-for="(it, index) in cpData" :key="index">
                                <td>{{ mtdNumber(index) }}</td>
                                <td>{{ it.subsub_category.subategorie.categorie.name }}</td>
                                <td>{{ it.subsub_category.subategorie.name }}</td>
                                <td>{{ it.subsub_category.name }}</td>
                                <td v-if="view=='add'">
                                    <button class="btn btn-sm btn-danger" @click="mtdDelService(it.id)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-left">
                    <!-- paginado -->
                    <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination mb-1">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                </li>
                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                </li>
                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>

                                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                    </a>
                                </li>
                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                    <span class="page-link">...</span>
                                </li>
                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                    <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtd_close" color="danger" class="btn btn-danger">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <!-- <button
        @click="mtd_commit"
        :disabled="false"
        :class="btnClasses"
        color="success"
      >
        Ok
      </button> -->
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-doctor",
    components: {
        CModal,
        CButtonClose,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            filter: {
                category: null,
                subcategory: null,
                subsubcategory: null,
            },
            search: "",
            cant: 0,
            categories: [],
            subcategories: [],
            subsubcategories: [],
            data: [],
            dataShow: [],
            //paginacion
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        services: [],
        size: String,
        view: String,
        iddoctor: Number
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
        title: {
            handler(newVal) {
                this.data = this.services;
                this.calculateData(7);
                if (this.view == 'add') {
                    this.getDataCategory();
                }
            },
            immediate: true,
        },
    },
    computed: {
        //validar el filtrado de las subcategorias
        cpButtonForm: function () {
            if (
                this.filter.category != null &&
                this.filter.subcategory != null &&
                this.filter.subsubcategory != null
            )

                return false;
            return true;
        },
        btnClasses() {
            return [`btn btn-${this.color || "primary"}`];
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdSelectCategory: function () {
            this.categories.forEach((element, index) => {
                if (element.id == this.filter.category) {
                    this.subcategories = element.subcategorie;
                    this.subsubcategories = [];
                }
            });
        },
        mtdSelectsubCategory: function () {

            this.post({
                    url: this.$store.getters.get__url +
                        "/SubsubCategorie/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        subcategory: this.filter.subcategory
                    }
                })
                .then((response) => {
                    this.subsubcategories = response.data;
                    //this.categories = response.data.categories.filter(item => item.type == 'service');
                })
                .catch((errors) => {});
        },
        mtdAddService: function () {
            let boo = this.data.some(item => item.subsub_category_id === this.filter.subsubcategory);
            if (boo == false) {
                this.get({
                        url: this.$store.getters.get__url +
                            "/Doctor/add/service/" +
                            this.iddoctor +
                            "/" +
                            this.filter.subsubcategory,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                            this.data.push(response.data);
                            this.calculateData(7);
                            this.filter = {
                                category: null,
                                subcategory: null,
                                subsubcategory: null,
                            };
                        }
                    })
                    .catch((errors) => {});
            } else {
                this.$refs.SweetAlert.showWarning("Ya se ha registrado anteriormente");
            }
        },
        mtdDelService: function (delId) {
            this.get({
                    url: this.$store.getters.get__url +
                        "/Doctor/delete/service/" +
                        delId,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let temp = [];
                    if (response.statusCode == 200) {
                        this.data.forEach(element => {
                            if (element.id != delId) {
                                temp.push(element);
                            }
                        });
                    }
                    this.data = temp;
                    this.action = 'd';
                    this.$refs.SweetAlert.showSuccess("Se eliminó correctamente");
                    this.calculateData(7);
                })
                .catch((errors) => {});
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.subsub_category.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            if (this.action == 'd') {
                if (this.dataShow.length == 1) {
                    if (indexInitial > 1) {
                        indexInitial -= 1;
                    } else {
                        indexInitial = 1;
                    }
                }
            }
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        getDataCategory() {
            this.get({
                    url: this.$store.getters.get__url +
                        "/SubsubCategorie",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.categories = response.data.categories.filter(item => item.type == 'service');
                })
                .catch((errors) => {});
        },
        mtd_close() {
            this.filter = {
                category: null,
                subcategory: null,
                subsubcategory: null,
                specialty: null,
                subspecialty: null,
            };
            this.search = "";
            this.$emit("close_modal_add");
        },
        mtd_commit() {
            this.$emit("mtd_action_add");
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 7 + (index + 1)
        },
    },
};
</script>
