<template>
<div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
    name: 'SweetAlert-c',
    props: {
        swal: String,

    },
    methods: {
        showAlert(title, text, icon, accion) {
            Swal.fire({
                title: title + "--" + this.swal,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: accion,
                cancelButtonText: "Cancelar",
            });
        },
        showConfirmSimple2(title, text, icon, accion) {
            return new Promise((resolve) => {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: icon,
                    showCancelButton: true,
                    confirmButtonText: accion,
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    resolve(result);
                });
            });
        },
        showSuccess(text) {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: text,
            });
        },
        showWarning(text) {
            Swal.fire({
                icon: "warning",
                title: "Alerta",
                text: text,
            });
        },
        showInfo(text) {
            Swal.fire({
                icon: "info",
                title: "¡Atención!",
                text: text,
            });
        },
        showError(text) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: text,
            });
        },
        showDelete(index, msg) {
            Swal.fire({
                title: "¿Esta Seguro?",
                text: "¡No podras revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Eliminar"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Elimininado!",
                        text: "Su archivo ha sido eliminado.",
                        icon: "success",

                    });
                    this.$emit(msg, index)
                }
            });
        },

        showUpdateState(id, type) {
            Swal.fire({
                icon: "warning",
                title: "¿Quieres cambiar el estado a Pendiente?",
                width: "400px",
                showDenyButton: false,
                denyButtonText: `Cancelar`,
                confirmButtonText: "Cambiar a Pendiente",
                confirmButtonColor: "#d33",
                showCancelButton: true,
                cancelButtonColor: "#3085d6",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdPermissionRollback', id, result.value, type, 1)
                }
            });
        },

        showPermisions(id, type, msg, state) {
            Swal.fire({
                icon: "warning",
                title: "¿Qué accción deseas tomar para este requerimiento?",
                input: msg,
                inputAttributes: {
                    autocapitalize: "off",
                    placeholder: "OBSERVACION",
                },
                width: "400px",
                showDenyButton: true,
                denyButtonText: `Rechazar`,
                denyButtonColor: "#bb8a17",
                confirmButtonText: "Permitir",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cerrar",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdPermissionCommit', id, result.value, type, 2);
                } else if (result.isDenied) {
                    this.$emit('mtdPermissionCommit', id, result.value, type, 3)
                }
            });
        },

        showActionBeads(title, buttonText, action) {
            Swal.fire({
                title: title,
                icon: "warning",
                width: "400px",
                /** buttons */
                showDenyButton: true,
                confirmButtonText: buttonText,
                confirmButtonColor: "#900052",
                showCancelButton: false,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cerrar",
            }).then((result) => {
                if (result.isConfirmed) {
                    if (action == 1) {
                        this.$emit('mtdSendPermissionCloseBead');
                    } else {
                        this.$emit('mtdCloseFinal');
                    }
                }
            });
        },

        showUpdateTime(payload) {
            Swal.fire({
                title: "Desea aplicar el medicamento?",
                text: "¡No podrás revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cambiar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdChangenew', payload);
                }
            });
        },

        cancellation(payload) {
            Swal.fire({
                title: "Desea anular el medicamento?",
                text: "¡No podrás revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cambiar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdCancellation', payload);
                }
            });
        },

        confirmCheckin(item) {
            Swal.fire({
                title: "Desea confirmar checkin?",
                text: "Ingreso del paciente",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirmar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('confirm', item);
                }
            });
        },
        confirmCheckinSinAsis(item) {
            Swal.fire({
                title: "Desea confirmar checkin?",
                text: "Ingreso del paciente",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirmar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('confirmSinAsis', item);
                }
            });
        },
        showComment(payload) {
            Swal.fire({
                title: 'Ingrese su observación',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Escriba aquí'
                },
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cerrar',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                width: '400px',
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage('Por favor, ingrese una observación')
                    }
                    return value;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    payload.observation = result.value
                    this.$emit('mtdChangenew', payload);
                }
            });
        },
        showCommentKardex(payloadK, payloadK2) {
            let date = payloadK;
            let type = payloadK2;
            Swal.fire({
                title: 'Ingrese su descripción',
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    placeholder: 'Escriba aquí'
                },
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cerrar',
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                width: '400px',
                preConfirm: (value) => {
                    if (!value) {
                        Swal.showValidationMessage('Por favor, ingrese una descripción')
                    }
                    return value;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    payloadK = result.value;
                    this.$emit('mtdInputKardexnew', payloadK, date, type);
                }
            });
        },
        commentKardex(text) {
            let commentHTML = '';
            if (text.comment) {
                commentHTML = `<h3 style="font-size: 15px;">${text.comment}</h3>`;
            }
            Swal.fire({
                html: `<h2 style="font-size: 20px;">${text.name}</h2>
                       <h3 style="font-size: 17px;">${text.date}</h3>
                       ${commentHTML}
                       <h5 style="font-size: 11px;">Fecha de registro: ${text.date_comment}</h5>
                `,
            });
        },
        commentAnswer(text) {
            Swal.fire({
                html: `<h3 style="font-size: 17px;"> <strong> Doctor Solicitante: </strong> ${text.doctor} </h3>
                       <h3 style="font-size: 17px;"> <strong> Doctor Interconsultista: </strong> ${text.doctorI}  </h3>
                       <h3 style="font-size: 14px;"> Fecha de respuesta: ${text.fecha}</h3>
                       <h4 style="font-size: 19px;">  ${text.respuesta}</h4>`,
            });
        },
        comment(text) {
            let commentHTML = '';
            let commentHTMLglucosa = '';
            let commentHTMLinsulina = '';
            if (text.comment) {
                commentHTML = `<h3 style="font-size: 15px;">${text.comment}</h3>`;
            }
            if (text.glucosa) {
                commentHTMLglucosa = `<h3 style="font-size: 15px;"><strong>Glucosa: </strong>${text.glucosa}</h3>`;
            }
            if (text.insulina) {
                commentHTMLinsulina = `<h3 style="font-size: 15px;"><strong>Insulina: </strong>${text.insulina}</h3>`;
            }
            Swal.fire({
                html: `<h2 style="font-size: 20px;">${text.name}</h2>
                       <h3 style="font-size: 17px;">${text.date}</h3>
                       ${commentHTML}
                       ${commentHTMLglucosa}
                       ${commentHTMLinsulina}
                `,
            });
        },

        horario(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Ingrese la hora',
                html: `<input type="time" id="timeInput" class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora">`,
                focusConfirm: false,
                showCancelButton: true,
                preConfirm: () => {
                    const time = document.getElementById('timeInput').value;
                    if (!time) {
                        Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                    }
                    return time;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdhorario', result.value, item)
                }
            });
        },

        horarioPrn(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                html: `
                    <p> Se tomará fecha: ${date} </p>
                    <input type="time" id="timeInput" style="margin-top: 0px " class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora">
                `,
                // icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                preConfirm: () => {
                    const time = document.getElementById('timeInput').value;
                    if (!time) {
                        Swal.showValidationMessage('¡Necesitas ingresar una hora!');
                    }
                    return time;
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdhorario', result.value, item);
                }
            });
        },
        horarioPrnInsulina(item) {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const date = `${year}/${month}/${day}`;
            const currentTime = `${hours}:${minutes}`;

            Swal.fire({
                title: 'Información',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                html: `
                        <div style="padding:0px 25px 0px 25px">
                            <p>Se tomará la fecha: <strong>${date}</strong></p>
                            <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                                <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Hora:</strong></label>
                                <input type="time" id="timeInput" style="width: 200px; font-size: 14px; padding-top: 0px;" class="swal2-input" value="${currentTime}" placeholder="Seleccione la hora">
                            </div>
                           
                            <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                                <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                                <input type="text" id="glucosa" class="swal2-input" style="width: 200px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                            </div>
                            <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                                <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                                <input type="text" id="escala_recogida" class="swal2-input" style="width: 200px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                            </div>
                        </div>
                    `,
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;
                    const time = document.getElementById('timeInput').value;
                    if (!glucosa || !escalaRecogida || !time) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida,
                        time
                    };
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa
                    item.escala_recogida = result.value.escalaRecogida
                    this.$emit('mtdhorario', result.value.time, item);
                }
            });
        },

        showInsuline(text) {
            Swal.fire({
                title: 'Escala Correctiva',
                html: `<textarea 
            style="width: 350px; 
                   height: 120px; 
                   border: none; 
                   border-radius: 8px; 
                   resize: none; 
                   overflow: hidden; 
                   background-color: #ffffff; /* Fondo blanco */
                   color: #000; 
                   font-family: monospace;" 
            readonly>${text}</textarea>`,
            });
        },

        Showescala(item) {
            Swal.fire({
                title: 'Información',
                html: `
                  <div style="padding:0px 25px 0px 25px">
                     <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                            <input type="text" id="glucosa" class="swal2-input" style="width: 200px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                        </div>
                        <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                            <input type="text" id="escala_recogida" class="swal2-input" style="width: 200px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                        </div>

                    </div>

                    `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;

                    if (!glucosa || !escalaRecogida) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida
                    };
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa
                    item.escala_recogida = result.value.escalaRecogida
                    this.$emit('mtdaddinsulina', item);
                }
            });
        },

        Showescalaobservation(item) {
            Swal.fire({
                title: 'Información',
                html: `
                <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                    <label for="glucosa" style="margin-right: 10px; min-width: 150px;"><strong>Glucosa:</strong></label>
                    <input type="text" id="glucosa" class="swal2-input" style="width: 300px; font-size: 14px; padding-top: 0px;" placeholder="Ingrese la glucosa">
                </div>
                <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                    <label for="escala_recogida" style="margin-right: 10px; min-width: 150px;"><strong>Escala Recogida:</strong></label>
                    <input type="text" id="escala_recogida" class="swal2-input" style="width: 300px; font-size: 14px;" placeholder="Ingrese la escala recogida">
                </div>
                <div style="font-size: 14px; display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 10px;">
                    <label for="observacion" style="margin-right: 10px; min-width: 150px;"><strong>Observación:</strong></label>
                    <textarea id="observacion" class="swal2-textarea" style="width: 300px; font-size: 14px; height: 120px;" placeholder="Ingrese observación"></textarea>
                </div>
                 `,
                icon: 'info',
                confirmButtonText: 'Aceptar',
                preConfirm: () => {
                    const glucosa = document.getElementById('glucosa').value;
                    const escalaRecogida = document.getElementById('escala_recogida').value;
                    const observation = document.getElementById('observacion').value;
                    if (!glucosa || !escalaRecogida || !observation) {
                        Swal.showValidationMessage('Todos los campos son obligatorios');
                        return false;
                    }

                    return {
                        glucosa,
                        escalaRecogida,
                        observation
                    };
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    item.glucosa = result.value.glucosa
                    item.escala_recogida = result.value.escalaRecogida
                    item.observation = result.value.observation
                    this.$emit('mtdChangenew', item);
                }
            });
        },
        ViewNotesDetalle(text) {
            // let commentHTML = '';
            // if (text.comment) {
            //     commentHTML = `<h3 style="font-size: 15px;">${text.comment}</h3>`;
            // }
            Swal.fire({
                html: `
                 <div  style="padding : 0px 20px 0px 20px">
                    <h2 style="font-size: 20px;">${text.name}</h2>
                    <h3 style="font-size: 17px;">${text.date}</h3>
                    <div style=" display: flex; ">
                        <h5 style="font-size: 16px;"><strong>Zona: </strong> ${text.zona}</h5>
                        <h5 style="font-size: 16px; margin-left: 150px"> <strong>Grado: </strong> ${text.grado}</h5> 
                    </div>
                    <h5 style="font-size: 16px; text-align: left;"><strong>Detalle de la Curación:</strong></h5>
                    <textarea 
                        style="width: 450px; 
                            // height: 220px; 
                            border: none; 
                            border-radius: 8px; 
                            resize: none; 
                            overflow: hidden; 
                            background-color: #ffffff; /* Fondo blanco */
                            color: #000; 
                            font-family: monospace;
                            font-size: 16px;" 
                        readonly>${text.comment}</textarea> 
                </div>
                       
                `,
            });
        },
    },
};
</script>
