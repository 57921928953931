import { render, staticRenderFns } from "./cModalSunat.vue?vue&type=template&id=b428cd12&scoped=true"
import script from "./cModalSunat.vue?vue&type=script&lang=js"
export * from "./cModalSunat.vue?vue&type=script&lang=js"
import style0 from "./cModalSunat.vue?vue&type=style&index=0&id=b428cd12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b428cd12",
  null
  
)

export default component.exports