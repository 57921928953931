<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row d-flex">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <label for="name" class="mb-2">Medicina a Buscar</label>
                <div class="input-group ">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-prescription-bottle"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control form-control tex-center" placeholder="Ingrese un nombre para mostrar medicamentos" v-model="filter.name" />
                    <button 
                        type="button" 
                        :disabled="filter.name && filter.name.length < 2" 
                        class="btn bg-button text-white" 
                        @click="mtdSearchData"
                    >
                        BUSCAR
                    </button>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <label for="name" class="mb-2">Medicamento</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fa fa-prescription-bottle"></i></span>
                    </div>
                    <v-select :options="dataSubsubcategory" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione un medicamento --" required v-model="servico.sub_sub_category_id" @input="mtdfilterdetail" />
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Concentración</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-percent"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Concentración" v-model="servico.concentration" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Molécula</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-flask"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Molécula" v-model="servico.molecule" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Comercial</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-tags"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Comercial" v-model="servico.commercial" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Marca</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-building"></i>
                        </span>
                    </div>
                    <v-select :options="dataMarca" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione Marca --" required v-model="servico.brand_id" />
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Dosis</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-file-alt"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Presentation" v-model="servico.dosis" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Presentation</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-file-alt"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Presentation" v-model="servico.presentation" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Forma</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-file"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Forma" v-model="servico.format" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Stock-Mínimo</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-box"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Stock-Minimo" v-model="servico.minimum_stock"  @input="validateInput('minimum_stock')"/>
                </div>
            </div>
        </div>
        
        <div class="row d-flex">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Proveedor</label>
                <div class="input-group input-group-sm">
                    <div class="input-group">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text"><i class="fa fa-truck"></i></span>
                        </div>
                        <v-select :options="providers" class="form-control form-control-sm" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione un proveedor --" required v-model="servico.provider_id" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Prec. Compra</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Prec. Compra" v-model="servico.price_purcharse" step="0.1" @input="validateInput('price_purcharse')" />
                </div>
            </div>
        </div>
       

        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="isGuardarDisabled">
                <i class="fas fa-save" />&nbsp; Guardar
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            dataSubsubcategory: [],
            dataMarca:[],
            servico: {
                sub_sub_category_id: "",
                id: "",
                molecule: "",
                commercial: "",
                brand_id: "",
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                provider_id: null,
                provider_id_2: null,
                dosis: null,
                price_purcharse: 0,
            },
            filter: {
                provider: null,
                category: null,
                subcategory: null,
                specialty: null,
                name: null,
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        providers:{
            type: Array,
            default: null
        },
    },
    watch: {
        action: {
            handler(newVal) {
                this.mtdGetBrand();
            },
            immediate: true 
        },
    },
    computed: {
        isGuardarDisabled() {
            return !(
                this.servico.sub_sub_category_id &&
                this.servico.concentration &&
                this.servico.molecule &&
                this.servico.commercial &&
                this.servico.brand_id &&
                this.servico.presentation &&
                this.servico.format &&
                this.servico.minimum_stock &&
                this.servico.provider_id &&
                this.servico.dosis &&
                this.servico.price_purcharse
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdclosemodal() {
            this.servico = {
                sub_sub_category_id: "",
                id: "",
                molecule: "",
                commercial: "",
                brand_id: "",
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                provider_id: null,
                provider_id_2: null,
                dosis: null,
                price_purcharse: 0,
            },
            this.filter.name = null,
            this.$emit('closeModal');
        },

        mtdSearchData: function () {
            if (this.filter.name != null) {
                this.post({
                        url: this.$store.getters.get__url + "/Medicament/filter",
                        token: this.$store.getters.get__token,
                        params: this.filter,
                    })
                    .then((response) => {
                        this.dataSubsubcategory = response.data;
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            } else {
                this.$refs.SweetAlert.showWarning("Debe digitar  un nombre de Medicamento");
            }
        },
        mtdfilterdetail: function () {
            this.get({
                url: this.$store.getters.get__url + "/logistics/maintenance/medicament/" + this.servico.sub_sub_category_id + "/filter",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.servico = response.data.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdGetBrand: function () {
            this.get({
                url: this.$store.getters.get__url + "/Brand",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataMarca = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        validateInput(field) {
            if (this.servico[field] < 0) {
                this.servico[field] = 0;
            }
        },

        DataDetail() {
            this.$emit('Datadetail', this.servico);
            this.mtdclosemodal();
        },

    },
};
</script>
<style scoped>
.bg-button {
    background-color: #900052;
    border: 2px solid #900052;
    font-size: 14px;
    border-radius: 8px;
}

.bg-button:disabled {
    background-color: #808080; 
    border: 2px solid #808080; 
    cursor: not-allowed; 
}
</style>