<template>
    <div>
        <div class="w-100 h-100">
            <Nav :bead="bead" :rolname="rolname"/>
        </div>
        <div class="content h-100" style="background: white">
            <Home v-if="page == 'home'" :verifBox="verifBox" :verifBead="verifBead" :boxes="boxes" @changePage="mtdChangePage"  @mtdBackCampus="mtdBackCampus" @mtdRefreshPage="mtdRefreshPage" />
            <Close v-if="page == 'close'" @returnHome="returnHome" :verifBead="verifBead" :verifBox="verifBox" :bead="bead" @mtdRefreshPage="mtdRefreshPage"/>
            <PagoFactura v-if="page == 'pagoFactura'" @returnHome="returnHome" />
            <Caja v-if="page == 'cajas'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" />
            <Grafico v-if="page == 'graficos'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" />
        </div>
        <div class="footer">
            <FooterVue v-if="verifBox == true" @mtdChangePage="mtdChangePage" :pageView="page" />
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import Nav from "@/components/Tesoreria/Nav.vue";
import Home from "@/components/Tesoreria/Home.vue";
import FooterVue from "@/components/Tesoreria/Footer.vue";
import PagoFactura from '@/components/Tesoreria/pages/PagosFacturas.vue';
import Caja from '@/components/Tesoreria/pages/Caja.vue';
import Close from '@/components/Tesoreria/pages/Close.vue';
import Grafico from '@/components/Tesoreria/pages/Grafico.vue';
export default {
    name: "v-tesoreria",
    components: {
        Nav,
        Home,
        FooterVue,
        PagoFactura,
        Close,
        Caja,
        Grafico

    },
    props: {
      rolname: String,
    },
    data() {
        return {
            page: "home",
            modulo: "tesoreria",
            filter: {},
            boxes: {},
            verifBead: false,
            verifBox: true,
            bead:{},
        };
    },
    mounted() {
        this.mtdDataBox();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdDataBox: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Tesoreria/beads",
                    token: this.$store.getters.get__token,
                    params: {
                        page: this.modulo,
                        campu_id: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {
                    this.boxes = response.data;
                    if(this.boxes){
                        this.verifBead = this.boxes.beads.length == 1 ?  true : false;
                        this.bead = this.boxes.beads && this.verifBead == true  ? this.boxes.beads[0] : {};
                    }else{
                        this.verifBox = false;
                        Swal.fire({
                        icon: "warning",
                        title: "No existe Caja Fuerte",
                        showConfirmButton: false,
                        timer: 1500,
                        });
                    }
                })
                .catch((errors) => {});
        },
        returnHome: function () {
            this.page = "home";
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdChangePage: function (page) {
            if(page == 'close'){
            this.mtdDataBox();
            }
            this.page = page;
        },
        mtdRefreshPage: function(){
            this.mtdDataBox();
            this.page = "home";

        }
    },
};
</script>
    