<template>
    <div>
        <div class="px-3 py-2">
            <div class="d-flex"> 
                <div  class="btn-home mb-4 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="card-header text-white h4 text-center" style="background: #900052">
                        <strong>VERIFICACIÓN STOCK</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <!--Filtro-->
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 mt-1 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">ALMACÉN</span>
                                        <v-select
                                        class="text-dark flex-grow-1"
                                        label="name"
                                        :options="dataAlmacen"
                                        placeholder="- Seleccionar -"
                                        v-model="selectedAlma"
                                        :reduce="(name) => name.id"
                                        @input="mtdGetData"
                                        >
                                        <template slot="no-options">
                                            No hay Almacen para la busqueda.
                                        </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="card" style="padding: 5px;">
                                        <div class="d-flex align-items-center">
                                            <span class="me-2">Tipo</span>
                                            <div class="form-check form-check-inline me-3">
                                                <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox1" value="1" v-model="filterType" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineCheckbox1">Vencido</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox2" value="2" v-model="filterType" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineCheckbox2">Por vencer</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-1 col-lg-2 col-md-6 col-sm-6 col-xs-6 mb-2">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        :disabled="mtddisable"
                                        @click="viewpdf()"
                                        >
                                        <i class="fas fa-file-pdf"  style="font-size: 18px;"></i>&nbsp; Pdf
                                        </button>
                                </div>
                                <div class="col-xl-1 col-lg-2 col-md-6 col-sm-6 col-xs-6">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        :disabled="mtddisableSave"
                                        @click="saveData()"
                                        >
                                        <i class="fas fa-save"  style="font-size: 18px;"></i>&nbsp; Guardar
                                        </button>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><i class="fas fa-search"></i
                                        ></span>
                                        <input
                                        @keyup="calculateData(10)"
                                        v-model="search"
                                        type="text"
                                        class="form-control form-control"
                                        placeholder="Molécula / Comercial / Marca"
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!--Fin Filtro-->
                           <div v-if="showTable">
                             <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1" v-if="checkDate && checkDate.length !== 0">
                                 <h6 style="font-size: 13px; margin-right: 3px;">Ultima fecha de Guardado: {{ checkDate | formatDateTime }}</h6>
                             </div>
                           </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-3" id="content-timelinetable"  v-if="showTable">
                                <table  id="tb-anular" class="table table-bordered border-main align-middle">
                                    <thead>
                                        <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                            <th scope="col">COMERCIAL - MOLECULA - MARCA</th>
                                            <th scope="col">LOTE</th>
                                            <th scope="col">FECHA VCTO
                                                <i class="fa fa-arrow-up flecha" @click="sortData('date_vcto', 'asc')"></i>
                                                <i class="fa fa-arrow-down flecha" @click="sortData('date_vcto', 'desc')"></i>
                                            </th>
                                            <th scope="col" class="table-th-acciones">CANTIDAD ACTUAL</th>
                                            <th scope="col" class="table-th-acciones">CANTIDAD FISICA</th>
                                            <th scope="col" class="table-th-acciones">DIFERENCIA</th>
                                        </tr>
                                    </thead>

                                    <tbody class="custom-table">
                                            <tr v-if="cpData.length === 0">
                                                <td class="text-center align-middle" colspan="6">Sin inventario</td>
                                            </tr>
                                            <tr v-for="(movement) in cpData" :key="movement.id">
                                                <td class="text-center align-middle">{{ movement.product}}</td>
                                                <td class="text-center align-middle">{{ movement.lote ? movement.lote.toUpperCase() : '-' }}</td>
                                                <td class="text-center align-middle">{{ movement.date_vcto | formatDate }}</td>
                                                <td class="text-center align-middle">{{ movement.quantity }}</td>
                                                <td class="text-center align-middle">
                                                    <input
                                                        type="text"
                                                        class="input-p form-control form-control-sm text-center"
                                                        v-model="movement.quantity_fisica"
                                                        @keydown="filterKey"
                                                        placeholder="Ingrese Cant."
                                                        @input="validateInput(movement)"
                                                        style="font-size: 10px; padding: 0.25rem 0.5rem"
                                                    />
                                                </td>
                                                <td class="text-center align-middle">{{ movement.diferencia }}</td>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <!-- paginado -->
                                <div v-if="cpData.length > 0 && showTable" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
    
                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "Inventary",
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            showTable: false,
            dataIn: [],
            checkDate: [],
            dataAlmacen: [],
            selectedAlma: null,
            filter: {
                init: null,
                end: null,
            },
            filterType: ["1", "2"],
            sortDirection: null, 
            sortKey: null, 
            excelData: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
            // Estado de orden
            sortKey: '',
            sortOrder: '',
            variableSave: 0,
        };
    },
    watch: {
        selectedAlma(newVal) {
            if (newVal === null) {
                this.dataIn = []; 
                this.showTable = false;
            }
        },
        selectPage() {
            this.calculateData(10);  
        },
    },
    computed: {
        mtddisable() {
            return !(this.selectedAlma !== null &&  this.filter.end !== null && this.variableSave !== 0);
        },
        mtddisableSave() {
            return !(this.variableSave !== 0);
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;

        this.vercampus();
        this.mtdGetSave();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            if(this.selectedAlma == null){
                this.$refs.SweetAlert.showWarning("Seleccione un Almacén");
            }else{
                let filterType = 0; 
                if (this.filterType.includes("1") && this.filterType.includes("2")) {
                    filterType = 0;
                } else if (this.filterType.includes("1")) {
                    filterType = 1;
                } else if (this.filterType.includes("2")) {
                    filterType = 2;
                }
                this.post({
                    url: this.$store.getters.get__url + "/Inventario/verificacion",
                    token: this.$store.getters.get__token,
                    params: {
                        alma: this.selectedAlma,
                        //end: this.filter.end,
                        filterType: filterType,
                    },
                })
                .then((response) => {
                    this.dataIn = response.data;
                    if (this.dataIn.length === 0) {
                        this.showTable = false;
                        this.variableSave = 0;
                        this.$refs.SweetAlert.showWarning("Sin Datos");
                    } else {
                        this.showTable = true;
                        this.variableSave = 1;
                        this.calculateData(10);  
                        this.setDiferenciaToQuantity();
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            }
        },
        
        mtdGetSave: function () {
            this.get({
                url: this.$store.getters.get__url + "/Inventario/traerSave",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.checkDate = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            let filteredData = Object.values(this.dataIn);
            let indexInitial = this.selectPage;
            if (this.search.length >= 3 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataIn.filter((element) => {
                    let commercial = element.commercial ? element.commercial.toString().toUpperCase() : "";
                    let molecule = element.molecule ? element.molecule.toString().toUpperCase() : "";
                    let marca = element.marca ? element.marca.toString().toUpperCase() : "";
                    return commercial.includes(query) || molecule.includes(query) || marca.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        sortData(key, order) {
            if (this.sortKey && this.sortKey !== key) {
                this.sortOrder = ''; 
            }
            this.sortKey = key;
            this.sortOrder = order;

            this.dataIn.sort((a, b) => {
                let result = 0;

                // Convertir valores a números si la clave es 'price_unit' o 'valorizado'
                const valueA = key === 'price_unit' || key === 'valorizado' ? parseFloat(a[key]) : a[key];
                const valueB = key === 'price_unit' || key === 'valorizado' ? parseFloat(b[key]) : b[key];

                if (valueA < valueB) result = -1;
                if (valueA > valueB) result = 1;

                return order === 'asc' ? result : -result;
            });
            this.calculateData(10);   
        },

        setDiferenciaToQuantity() {
            Object.values(this.dataIn).forEach(movement => {
                    movement.diferencia = parseFloat(movement.quantity);
                });
        },

        clearData() {
            this.dataIn = [];   
            this.showTable = false;
        },

        viewpdf: function () {
            let filterType = 'todos'; 
            if (this.filterType.includes("1") && this.filterType.includes("2")) {
                filterType = 'todos';
            } else if (this.filterType.includes("1")) {
                filterType = 'vencidos';
            } else if (this.filterType.includes("2")) {
                filterType = 'por_vencer';
            }
            window.open(
                this.$store.getters.get__url +
                "/viewpdfVerificacion/" +
                this.selectedAlma + "/" + filterType,
                "_blank"
            );
        },

        vercampus: function () {
            this.filter.campus_id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Kardex/filterAlmacen/" +this.filter.campus_id,
                    token: this.$store.getters.get__token,

                })
                .then((response) => {
                    this.dataAlmacen = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

        filterKey: function (e) {
            if (e.key === "Tab") {
                return;
            }
            let isNumber = /^[0-9]+$/.test(e.key);
            if (!isNumber && e.key !== "Backspace") {
                e.preventDefault();
            }
        },

        validateInput: function (movement) {
            const quantity = parseFloat(movement.quantity);
            const quantity_fisica = parseFloat(movement.quantity_fisica) || 0;
            movement.diferencia = quantity - quantity_fisica;
        },

        saveData: function () {
            let filterType = 'todos'; 
            if (this.filterType.includes("1") && this.filterType.includes("2")) {
                filterType = 'todos';
            } else if (this.filterType.includes("1")) {
                filterType = 'vencidos';
            } else if (this.filterType.includes("2")) {
                filterType = 'por_vencer';
            };

            let allZero = this.dataIn.every(movement => 
                !movement.quantity_fisica || parseFloat(movement.quantity_fisica) === 0
            );

            if (allZero) {
                this.$refs.SweetAlert.showWarning("Registre al menos una cantidad diferente de 0");
                return;
            }
            let dataToSave = this.dataIn.map(movement => {
                return {
                    ...movement,
                    quantity_fisica: movement.quantity_fisica ? parseFloat(movement.quantity_fisica) : 0
                };
            });

            this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de Guardar estos Datos?","warning","Guardar") 
            .then((result) => {
                if (result.value) {


                    this.post({
                        url: this.$store.getters.get__url + "/Inventario/saveStock",
                        token: this.$store.getters.get__token,
                        params: {
                            alma: this.selectedAlma,
                            data: dataToSave
                        },
                    })
                    .then((response) => {
                        if(response.data.state == 0){
                            this.$refs.SweetAlert.showSuccess("Guardado Correctamente");
                            this.mtdGetSave();
                            this.savePdf(response.data.data.stock_check_id,this.selectedAlma, filterType);
                            // Limpiar todos los valores de quantity_fisica
                            this.dataIn.forEach(movement => {
                                movement.quantity_fisica = ''; 
                            });
                            this.setDiferenciaToQuantity();
                        }else{
                            this.$refs.SweetAlert.showError(errors);
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });

                    
                }
            });
        },

        savePdf: function(id, alma, type){
            window.open(
                this.$store.getters.get__url +
                "/viewpdfVerStock/" + id + "/" + alma + "/" + type,
                "_blank"
            );
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>

<style scoped>
.custom-table th,
.custom-table td {
    font-size: 11px;
}

.large-text {
    font-size: 9px;
}
.nowrap {
  white-space: nowrap;
}
.flecha {
    font-size: 12px; 
    cursor: pointer; 
    margin-left: 4px; 
    margin-right: 1px;
    color: #EDEAEC;
}
</style>
