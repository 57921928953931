<template>
    <div>
        <br>
    <br>
    <h1>BME</h1>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'c-asistencial-uciadulto-historia-bme',
        data () {
      return {
        //page: null,
      }
    },
    created () {
    
    },
    methods: {
      ...mapActions(['get', 'post']),
    }
    }
</script>