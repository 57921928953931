<template>
    <div class=" row container pt-3 pb-3 mx-0 px-0">
        <div class="row col-xl-8 col-lg-12 mx-0 px-1" id="">
            <div class=" col-xl-2 px-0 col-lg-4 border">
                <div class="row div-title  fw-bold text-xxxs py-1 justify-content-center mx-0">
                    <span>SIGNOS VITALES</span>
                </div>
                <div class="pt-0 pb-2  mx-0 row">
                    <div class="col-xl-12 px-2">
                        <div v-for="(item1, index) in signosVitalesUno" :key="index">
                            <div class="row pt-1 mx-0 px-0">
                                <div class="col-xl-3 col-md-1  col-sm-1 col-xs-1 px-0 div-text text-xxxs">{{ item1 }}</div>
                                <div class="col-xl-9 col-md-11 col-sm-11  px-0">
                                    <input type="text" class="form-control form-control-input" autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
            <div class="col-xl-4 col-lg-8 ">
                <div class="row border ">
                    <div class="col-md-4 px-0 div-title fw-bold d-flex  align-items-center">
                        <div class="w-100 text-xxxs ">
                            (S) SUBJETIVO
                        </div>
                    </div>
                    <div class="col-md-8 py-2 px-1">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                </div>
                <div class="row border">
                    <div class="col-md-4 px-0 div-title fw-bold d-flex  align-items-center">
                        <div class="w-100 text-xxxs">
                            (O) OBJETIVO
                        </div>
                    </div>
                    <div class="col-md-8 pt-2 px-1">
                        <div v-for="(obs, index) in observacion" :key="index" class="mb-1">
                            <div class="d-flex flex-column flex-sm-row">
                                <label class="text-content w-100 text-start mb-2 mb-sm-0 text-xxxs">{{ obs }}</label>
                                <input type="text" class="form-control form-control-input" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border">
                    <div class="col-md-4 px-0  div-title fw-bold d-flex  align-items-center">
                        <div class="w-100 text-xxxs">
                            (A) APRECIACIÓN
                        </div>
                    </div>
                    <div class="col-md-8 py-2 px-1">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 px-0 ">
                <div class="row border mx-0 ">
                    <div class="col-xl-1 col-lg-12 px-1 div-title fw-bold d-flex  align-items-center">
                        <div class="w-100 div-text px-0">
                            Plan
                        </div>
                    </div>
                    <div class=" row  col-xl-11 col-lg-12 py-0 px-1 mx-0">
                        <div class="col-xl-4 col-lg-4 col-md-4 py-1 px-1">
                            <div class="input-group input-group-size ">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control " placeholder="Laboratorio" />
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 py-1 px-1  ">
                            <div class="input-group  input-group-size ">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control " placeholder="Imagen" />
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 py-1 px-1 ">
                            <div class="input-group input-group-size ">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control " placeholder="Procedimiento/cirugía" />
                            </div>
                        </div>
                    </div>
                    <div class=" mt-1 row col-xl-12 px-0 text-center mx-0  ">
                        <div class="col-xl-6 px-0">
                            <div class="table-responsive px-0">
                                <table class="w-100">
                                    <caption class="div-text py-0"></caption>
                                    <thead class="text-xxxs div-title">
                                        <tr>
                                            <th >Fecha</th>
                                            <th >PO2</th>
                                            <th>PO2</th>
                                            <th>FO2</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody  class="text-xxxs">
                                        <tr  v-for="index in 7" :key="index" class="px-1">
                                            <td>
                                             
                                                <input type="text" class="form-control form-control-input rounded-0" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control form-control-input rounded-0" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control form-control-input rounded-0" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control form-control-input rounded-0" />
                                            </td>
                                           
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-xl-6 px-0">
                            <div class="text-xxxs  ">CURVA DE PA2/FIO2</div>
    
                        </div>
    
                    </div>
                </div>
    
            </div>
    
            <div class="col-xl-12 col-md-12 border mt-1">
                <div class="row justify-content-center">
                    <div class="col-md-2 div-title text-xxxs fw-bold d-flex align-items-center">
                        <span>ANALITICA</span>
                    </div>
                    <div class="col-md-10">
                        <div v-for="(i, index) in analitica" :key="index" class="">
                            <div class="row text-xxxs  text-start">
                                <div class="col-md-3 border">{{ i }}</div>
                                <div class="col px-0">
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </div>
                                <div class="col px-0">
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </div>
                                <div class="col px-0">
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </div>
                                <div class="col px-0">
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </div>
                                <div class="col px-0">
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </div>
                                <div class="col px-0">
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
        <!--PARTE 2-->
        <div class="row  col-xl-4 col-lg-12 mx-0 px-1">
    
            <div class="col-xl-12 col-md-12 px-0 ">
                <div class="row  mx-0 ">
                    <div class="table-responsive px-0 ">
                        <table class="table-bordered w-100">
                            <caption class="div-text py-1"></caption>
                            <thead class="div-title">
                                <tr>
                                    <th scope="col">Cultivos</th>
                                    <th scope="col">Fecha de Solicitud</th>
                                    <th scope="col">GRAM A LAS 24 HR.</th>
                                    <th scope="col">Dia 1</th>
                                    <th scope="col">Dia 2</th>
                                    <th scope="col">Dia 3</th>
                                    <th scope="col">Dia 4</th>
    
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr>
                                    <td>Hemocultivo</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
    
                                </tr>
                                <tr>
                                    <td>Urocultivo</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
    
                                </tr>
                                <tr>
                                    <td>Coprocultivo</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
    
                                </tr>
                                <tr>
                                    <td>Cultivo</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row table-emedica mx-0">
                    <div class="table-responsive px-0">
                        <table class="w-100">
                            <caption class="div-text py-0"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th scope="col">Rp.</th>
                                    <th class="w-25">Medicamentos</th>
                                    <th>Presentacion</th>
                                    <th>Dosis</th>
                                    <th>Via</th>
                                    <th>Frecuencia</th>
                                    <th>Duracion</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr v-for="index in 17" :key="index" class="px-1">
                                    <td class="border rounded-0">{{ index }}</td>
                                    <td>
                                        <select selected class="form-select rounded-0 pb-0 pt-0">
                                            <option value="">Buscar...</option>
                                            <option>Opción 1</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input rounded-0" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input rounded-0" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input rounded-0" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input rounded-0" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input rounded-0" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row border  mx-0 mt-2">
                    <div class="col-xl-4 col-md-4 div-title text-xxxs  fw-bold d-flex align-items-center">
                        <span>MÉDICO GENERAL</span>
                    </div>
                    <div class="col-xl-8 col-md-8 py-2">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                    <div class="col-xl-4 col-md-4 div-title  text-xxxs fw-bold d-flex align-items-center">
                        <span>MÉDICO ESPECIALISTA</span>
                    </div>
                    <div class="col-xl-8 col-md-8 py-2">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex'
    export default {
        name: 'c-asistencial-sop-historia-evolucionmedica',
        data() {
            return {
                signosVitalesUno: ["PC", "FR", "PA", "SO2", "FIO2"],
                signosVitalesDos: ["T*", "HGT", "DLURESIS", "BH"],
                observacion: [
                    "Estudio General",
                    "Neurologico",
                    "Respiratorio",
                    "Hemodinamico",
                    "Renal",
                    "Adbominal",
                    "Metabólico",
                ],
                analitica: [
                    "Fecha",
                    "PCR",
                    "LDH",
                    "FERRTININA",
                    "DIMERO 0",
                    "LEUCOCITOS",
                    "ABASTONADOS",
                    "SEGMENTADOS",
                    "LINFOCITOS",
                    "HB/HCTO",
                    "PLAQUETAS",
                    "TBO/TGP",
                    "TROPONINA",
                    "UREA",
                    "CREATININA",
                    "MORD4",
                    "PROCALCITONINA",
                    "TP/INR",
                    "COMPROMISO TEM",
                ],
            }
        },
        methods: {
            ...mapActions(['get', 'post']),
        },
    }
    </script>