<template>
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex p-2">
        <div class=" col-lg-3 col-md-3 col-sm-3 col-xs-6">
            <div class="row p-2">
                <div class="col-12 div-expense">
                    <div class="row d-flex justify-content-between px-1 mt-3">
                        <div class="col-md-12 text-center a-success"><i class="fas fa-money-bill"></i></div>
                        <div class="col-md-12 text-center ps-2">
                            <p class="fw-semibold">TOTAL</p>
                        </div>
                    </div>
                    <div class="row">
                        <p class="fst-italic">S/. {{ item.total }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-lg-3 col-md-3 col-sm-3 col-xs-6">
            <div class="row p-2">
                <div class="col-12 div-expense">
                    <div class="row d-flex justify-content-between px-1 mt-3">
                        <div class="col-md-12 text-center a-success"><i class="fas fa-check-circle"></i></div>
                        <div class="col-md-12 text-center ps-2">
                            <p class="fw-semibold">PAGADO</p>
                        </div>
                    </div>
                    <div class="row">
                        <p class="fst-italic">S/. {{ item.amount }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-lg-3 col-md-3 col-sm-3 col-xs-6">
            <div class="row p-2">
                <div class="col-12 div-expense">
                    <div class="row d-flex justify-content-between px-1 mt-3">
                        <div class="col-md-12 text-center a-danger"><i class="fas fa-exclamation-circle"></i></div>
                        <div class="col-md-12 text-center ps-2">
                            <p class="fw-semibold">PENDIENTE</p>
                        </div>
                    </div>
                    <div class="row">
                        <p class="fst-italic">S/. {{ item.pending }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-lg-3 col-md-3 col-sm-3 col-xs-6">
            <div class="row p-2">
                <div class="col-12 div-expense">
                    <div class="row d-flex justify-content-between px-1 mt-3">
                        <div class="col-md-12 text-center a-primary"><i class="fas fa-money-bill-wave"></i></div>
                        <div class="col-md-12 text-center ps-2">
                            <p class="fw-semibold">MONTO</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-group input-group-sm mb-2">
                            <input type="text" aria-label="shift_date" class="form-control form-control-sm" v-model="transaction.amount" placeholder="0.00" @input="validateAmount" />
                        </div>
                        <span class="text-danger text-xxs" v-if="!isValidInput">
                            {{ mtdMessage }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" :disabled="cpDisabled || !isValidInput" @click="mtdEditValor">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

import {
    mapActions
} from "vuex";

export default {
    name: "c-admision-modal-retiroDeposito",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            transaction: {
                type: 1,
                amount: "",
            },
            isValidInput: true,
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: {},
        bead: {},

    },
    computed: {
        cpDisabled() {
            return this.transaction.amount == ""
        },
        mtdMessage() {
            //{{ type == 'Retiro' ? 'El monto tiene que ser mayor a S/. 0.00 y menor a S/.'+ bead.final : 'El valor tiene que ser mayor que 0.00' }}
            const msgRetiro = 'El monto tiene que ser mayor a S/. 0.00 e igual a S/.' + this.item.pending;
            return msgRetiro;
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdSelectType: function (value) {
            this.transaction.type = value;
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            // Asignar el valor sanitizado al input
            this.transaction.amount = sanitizedValue;
            //validar que no sea mayor que el pendiente
            const final = parseFloat(this.item.pending).toFixed(2);
            const amountInput = parseFloat(sanitizedValue).toFixed(2);
            let amount = parseFloat(sanitizedValue).toFixed(2);
            let validation = (amount > 0.00) ? (amountInput !== final) : true;
            if (validation == false) {
                this.transaction.amount = sanitizedValue;
                this.isValidInput = true;

            } else {
                this.isValidInput = false;
            }
        },
        mtdEditValor() {
            let resta = parseFloat(this.item.pending) - parseFloat(this.transaction.amount);
            this.post({
                    url: this.$store.getters.get__url + "/Expense/expensecomplete",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.item.id,
                        campu_id: this.$store.getters.get__campus,
                        bead_id: this.bead.id,
                        amount: this.transaction.amount,
                        pending: resta,
                    }
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdclosemodal(0);
                    }
                })
                .catch((errors) => {});
        },
        mtdclosemodal(op) {
            this.transaction = {
                type: 1,
                amount: "",
            };
            this.$emit('mtdclosemodal',op)
        }
    },
};
</script>
