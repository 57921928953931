<template>
  <div>
    <div class="container mt-5" v-if="view == 'carrito'">
      <div
        id="div-modal-venta"
        style="margin-top: -15px"
        class="d-flex justify-content-center"
      >
        <div id="div-venta" class="card w-100">
          <div
            class="card-header text-white text-center h5"
            style="background: #900052"
          >
            <strong>AGREGAR MEDICAMENTO</strong>
          </div>
          <div class="card-body" style="background-color: #e6e6e6">
            <div class="row">
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-1">
                    <span
                      class="input-group-text"
                      style="background: #900052; color: #ffffff"
                      ><i class="fas fa-search"></i
                    ></span>
                    <input
                      type="search"
                      id="search_product"
                      class="form-control"
                      placeholder="Digitar Medicamento Similar"
                      aria-describedby="search_product"
                      v-model="name"
                      @keyup="mtd_filter_product"
                      disabled
                    />
                  </div>
                </div>

                <div class="row py-1" id="list-product">
                  <div
                    v-for="(item, index) in data_product_filter"
                    :key="index"
                    class="col-md-12 py-1 d-flex align-items-center"
                    id="item-product"
                    style="background-color: #e6e6e6; height: 80px"
                  >
                    <div
                      class="p-1 d-flex flex-column w-100 justify-content-center"
                      style="background-color: #ffffff; height: 100%"
                      @click="mtdSelectSubsubcatergorie(item)"
                    >
                      <div class="align-items-center" id="name-product">
                        <p class="d-flex align-items-center mb-0 m-1">
                          <strong
                            class="mb-0 item-name custom-tooltip d-flex justify-content-between align-items-center"
                            data-toggle="tooltip"
                            :data-placement="'top'"
                            :title="item.name"
                            :class="{
                              'text-success': item.total_quantity > 5,
                              'text-danger': item.total_quantity === 0,
                              'text-custom-light-green':
                                item.total_quantity <= 5 && item.total_quantity > 0,
                            }"
                            style="
                              width: 98%;
                              margin-left: 10px;
                              font-size: 11.5px;
                              margin-top: 10px;
                            "
                          >
                            {{ item.name }}&nbsp;
                            <template v-if="item.brand_name !== null">
                              &nbsp; ({{ item.brand_name }})
                            </template>
                            &nbsp;
                            <span style="font-weight: bold; font-size: 12.5px; text-align: right;">
                              <b>({{ item.total_quantity }})</b>
                            </span>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- carrito -->
              <div class="col-md-6 pt-3">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100"
                      style="background: #900052; color: #ffffff; padding-left: 25px"
                      ><strong>MEDICAMENTOS ESCOGIDOS</strong></span
                    >
                  </div>
                </div>
                <div class="col-md-12 list-monto">
                  <div class="container-list">
                    <div class="" v-if="carrito.length > 0">
                      <div
                        class="box item-monto"
                        v-for="(item, index) in carrito"
                        :key="index"
                      >
                        <p class="text-monto">({{ item.quantity }}) {{ item.name }}</p>
                        <div class="input-group" style="">
                          <div
                            align="right"
                            class="form-control"
                            v-if="posEditPrice != index"
                          >
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdAddQuantity(index)"
                            >
                              <i class="fas fa-plus text-white"></i>
                            </button>
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdDelQuantity(index)"
                            >
                              <i class="fas fa-minus text-white"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12"></div>
            </div>
          </div>
          <div
            class="card-footer text-white"
            style="background: #900052; font-size: 20px"
          >
            <div class="row">
              <div class="col" align="left">
                <span class="btn border border-white text-white" @click="retroceder"
                  ><strong>ATRAS</strong></span
                >
              </div>
              <div align="right" class="col">
                <span
                  class="btn border border-white text-white"
                  style="margin-left: 20px"
                  @click="mtdNextStep"
                  ><strong>OK</strong></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
  name: "c-Pharmacy-cCarroAddPd",
  components: {
    SweetAlert,
  },
  props: {
    recipeId: Number,
    molecule: String,
    dosis: String,
    presentation: String,
    name: String,
    type: Number,
    detailId: Number,
    nameMedic: Number,
    total: Number,
    dataSubSubCategories: {
        type: Array,
        default: null
    },
    data_product_filter: {
        type: Array,
        default: null
    },
  },
  data() {
    return {
      swal: null,
      view: "carrito",
      //dataSubSubCategories: [],
      //data_product_filter: [],
      search_product: "",
      carrito: [],
      item_carrito: {},
      posEditPrice: null,
      stock: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetDataSubSubCategories: function () {
      const encodedQuery = encodeURIComponent(this.search_product);
      this.post({
        url:
          this.$store.getters.get__url +
          "/Pharmacy/" +
          0 +
          "/" +
          null +
          "/subsubcategoriesCliAddPd?search_product=" +
          encodedQuery,
          params: { nameMedic: this.nameMedic},
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataSubSubCategories = response.data.data;
          this.data_product_filter = response.data.data;
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },
    retroceder() {
      this.$emit("mtdBack");
    },
    mtd_filter_product: function () {
      this.search_product = this.name;
      if (search_product.value.length >= 7 && search_product.value != "") {
        let q = search_product.value.toString().toUpperCase();
        this.mtdGetDataSubSubCategories(q);
        this.data_product_filter = [];
        let i = 0;
        this.dataSubSubCategories.forEach((element) => {
          let name = element.name.toString().toUpperCase();
          if (name.includes(q)) {
            this.data_product_filter.push(element);
          }
        });
      } else {
        this.data_product_filter = this.dataSubSubCategories;
      }
    },

    mtd_filter_producto: function () {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/mtdAddPd",
        token: this.$store.getters.get__token,
        params: {
          molecule: this.molecule,
          dosis: this.dosis,
          presentation: this.presentation,
          nameMedic: this.nameMedic,
          type: this.type,
        },
      })
      .then((response) => {
          this.dataSubSubCategories = response.data.data;
          this.data_product_filter = response.data.data;
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },

    /** carrito */
    mtdSelectSubsubcatergorie: function (item) {
      if (item.total_quantity === 0) {
        this.$refs.SweetAlert.showWarning("No puede agregar al carrito Medicamentos sin Stock");
        return;
      }
      if (this.carrito.length > 0) {
        this.$refs.SweetAlert.showWarning("Solo se puede seleccionar un Medicamento");
        return;
      }
      this.item_carrito = {
        id: item.id,
        name: item.name,
        price: item.precio_farmacia_particular,
        quantity: 1,
        subategorie: {
          id: item.subCatId,
          name: item.subCatName,
        },
        categorie: {
          id: item.catId,
          name: item.catName,
          type: item.catType,
        },
        doctor: {
          id: null,
          name: null,
        },
      };
      this.carrito.push(this.item_carrito);

      this.search_product = "";
    },

    mtdSelectSubCar: function (item) {

      this.item_carrito.dataRe.via = item.via;
      this.item_carrito.dataRe.dosis = item.dosis;
      this.item_carrito.dataRe.frecuencia = item.frecuencia;
      this.item_carrito.dataRe.duracion = item.duracion;
      this.carrito.push(this.item_carrito);
    },

    mtdAddQuantity: function (pos) {
      this.carrito[pos].quantity = this.carrito[pos].quantity + 1;
    },
    mtdDelQuantity: function (pos) {
      let cantidad = this.carrito[pos].quantity;
      if (cantidad > 1) {
        this.carrito[pos].quantity = this.carrito[pos].quantity - 1;
      } else {
        let temp = [];
        this.carrito.forEach((element, index) => {
          if (index != pos) {
            temp.push(element);
          }
        });
        this.carrito = temp;
      }
    },
    mtdNextStep: function () {
      if (this.carrito.length > 0) {
        this.$emit("mtdSendCarrito", this.carrito, this.total, this.recipeId);
        this.carrito = [];
      } else {
        this.$refs.SweetAlert.showWarning("Seleccione un Medicamento");
      }
    },
  },
};
</script>

<style scoped>
.btn-home {
  z-index: 1;
}

.text-custom-light-green {
  color: #60d004;
}


#div-venta-body {
  height: 450px;
  overflow: auto;
  box-sizing: border-box;
  padding: 1rem 1rem;
}

#div-venta-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-venta-body::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-venta-body::-webkit-scrollbar-button:increment,
#div-venta-body::-webkit-scrollbar-button {
  display: none;
}

#div-venta-body::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-venta-body::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-venta-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

#name-product {
  color: #8a8a97;
  font-size: 13px;
}

.list-doctor,
.list-monto {
  padding-right: 25px;
}

.text-doctor {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
}

.text-monto {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 0px;
}

.container-list {
  margin: 30px;
  width: 100%;
}

.box {
  margin-bottom: 10px;
  position: relative;
}

.box::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #808080;
  left: -15px;
  top: 30px;
}

.box::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #900052;
  left: -23px;
  top: 10px;
}

.item-doctor,
.item-monto {
  padding: 10px 10px;
  border: #900052 3px solid;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.item-doctor:hover {
  cursor: pointer;
  background-color: #900052;
}

.item-doctor:hover p {
  color: #ffffff !important;
}

#item-product {
  align-items: center;
}

#item-product:hover {
  cursor: pointer;
  align-items: center;
  transform: scale(1.05);
}

.item-monto .input-group-text,
.item-monto .form-control {
  background-color: #e6e6e6 !important;
  border: 0 !important;
  padding: 0px;
}

.item-monto .form-control button {
  background-color: #808080 !important;
  border: 0 !important;
}

.item-monto .form-control button:hover {
  background-color: #000000 !important;
  border: 0 !important;
}
.item-name {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-name strong {
  padding: 0px 10px;
  width: 120px;
}

.circulo {
  vertical-align: middle;
  border-style: none;
}

@media (max-width: 800px) {
  #btn-open-caja {
    justify-content: center !important;
  }

  #btn-registro-venta {
    text-align: center;
  }

  #div-tabla-venta {
    overflow-x: auto;
  }
}

@media (min-width: 800px) {
  #btn-registro-venta {
    text-align: left;
  }
}
#table-stock tbody tr:hover {
  background-color: #f3e2ec;
}
</style>
