<template>
<div>
    <div id="app" class="container mt-3">
        <div class="row mb-1 mx-0" id="boton-arrow" v-if="stateHistoryShow === 0 || showHistoryComplet ==1">
            <div class="btn-success text-start p-0">
                <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackPage">
                    <i class="fas fa-arrow-left"></i> Atr&aacute;s
                </button>
            </div>
        </div>
        <div class="row" v-if="viewinputs!==0">
            <div class="col-lg-8 col-md-12 ">
                <form class="d-flex flex-row">
                    <div class="row mb-4">
                        <div id="nombre" class="input-group-sm col-md-4 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="nombre" placeholder="Nombre y Apellido" autocomplete="off" v-model="filterHistory.name" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="edad" class="input-group-sm col-md-1 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="edad" placeholder="Edad" autocomplete="off" v-model="filterHistory.year" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_inicio" placeholder="Fecha de inicio" autocomplete="off" v-model="filterHistory.startDate" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_fin" placeholder="Fecha Fin" autocomplete="off" v-model="filterHistory.endDate" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="dias" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="dias_transcurridos" placeholder="Dias transcurridos" autocomplete="off" v-model="filterHistory.dayPassed" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mx-0 text-white h-100  " v-if="viewinputs===0">
            <div class="mx-0 col-sm-12 col-md-12 col-xl-6 h-100 order-lg-1  rounded-3 px-0 bg-blue mb-2">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center  ">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-12 order-md-1 order-lg-2">
                <div class="row" v-if="viewMedico===0">
                    <div class="col-md-10 d-inline-flex w-100">
                        <div class="input-group input-group-sm w-100">
                            <span class="input-group-text text-white bg-main" id="inputGroup-sizing-sm">Médico</span>
                            <input type="text" class="form-control text-xs" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" v-model="patient.doctor_name" disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container text-center mt-2" v-if="stateHistoryShow === 0 || showHistoryComplet ==1">
        <ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoUcineo" role="tablist">
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('historiauci')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'historiauci' }" id="hame-tab" type="button">
                    <strong class="text-xxs">HC</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('evolucionmedica')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'evolucionmedica' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">EV. MEDICA</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('rp')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'rp' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">R.P.</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('hojagrafica')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'hojagrafica' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">HOJA GRAFICA</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('notaenfermeria')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'notaenfermeria' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">NOTA ENFERMERIA</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('kardex')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'kardex' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">KARDEX</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('oxigeno')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'oxigeno' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">OXIGENO</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('bme')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'bme' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">BME</strong>
                </button>
            </li>
            <li class="nav-item-ucineo" role="presentacion">
                <button @click="changeSubPage('monitoreodinamico')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'monitoreodinamico' }" id="button-ucineo" type="button">
                    <strong class="text-xxs">MONITOREO</strong>
                </button>
            </li>
        </ul>
        <div class="tab-content mb-5 d-none d-lg-block" id="myTabContentHijo">
            <HistoriaUci :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" v-if="subpage == 'historiauci'" />
            <Evolucionmedica v-if="subpage == 'evolucionmedica'" />
            <Rp v-if="subpage == 'rp'" />
            <Hojagrafica v-if="subpage == 'hojagrafica'" />
            <Notaenfermeria v-if="subpage == 'notaenfermeria'" />
            <Kardex v-if="subpage == 'kardex'" />
            <Oxigeno v-if="subpage == 'oxigeno'" />
            <Bme v-if="subpage == 'bme'" />
            <Monitoreohemodinamico v-if="subpage == 'monitoreodinamico'" />
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="H. Clínica" component="HistoriaUci" :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></dropdown>
                <dropdown title="Evolución Médica" component="Evolucionmedica"></dropdown>
                <dropdown title="Rp" component="Rp"></dropdown>
                <dropdown title="Hoja Gráfica" component="Hojagrafica"></dropdown>
                <dropdown title="Nota de enfermeria" component="Notaenfermeria"></dropdown>
                <dropdown title="Kardex" component="Kardex"></dropdown>
                <dropdown title="Oxigeno" component="Oxigeno"></dropdown>
                <dropdown title="Bme" component="Bme"></dropdown>
                <dropdown title="Monitoreo Hemodinamico" component="Monitoreohemodinamico"></dropdown>
            </div>
        </div>
        <!--  -->

    </div>
    <div v-if="showHistoryComplet !=1">
        <Tablehistory :resultFilter="resultFilter" @historyPlanPatient="getHistoryPatient" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import HistoriaUci from '@/components/Asistencial/Ucineo/Historia/Hcucineo.vue'
import Evolucionmedica from '@/components/Asistencial/Ucineo/Historia/Emedica.vue'
import Rp from '@/components/Asistencial/Ucineo/Historia/Rp.vue'
import Hojagrafica from '@/components/Asistencial/Ucineo/Historia/Hojagrafica.vue'
import Notaenfermeria from '@/components/Asistencial/Ucineo/Historia/Notaenfermeria.vue'
import Kardex from '@/components/Asistencial/Ucineo/Historia/Kardex.vue'
import Oxigeno from '@/components/Asistencial/Ucineo/Historia/Oxigeno.vue'
import Bme from '@/components/Asistencial/Ucineo/Historia/Bme.vue'
import Monitoreohemodinamico from '@/components/Asistencial/Ucineo/Historia/Mhemodinamico.vue'
import Dropdown from '@/components/Asistencial/Ucineo/Dropdown.vue'
import Tablehistory from "@/components/Asistencial/Ucineo/TableHistory.vue";
export default {
    name: 'c-asistencial-ucineo-historia',
    components: {
        HistoriaUci,
        Evolucionmedica,
        Rp,
        Hojagrafica,
        Notaenfermeria,
        Kardex,
        Oxigeno,
        Bme,
        Monitoreohemodinamico,
        Dropdown,
        Tablehistory,
    },
    data() {
        return {
            subpage: 'historiauci',
            resultFilter: [],
            showHistoryComplet: 0,
            filterHistory: {
                dayPassed: null,
                startDate: null,
                endDate: null,
                year: null,
                name: null,
            },
            stateDisabled: false,
            patient: [],
            datapatients: {
                patient_id: '',
                model_id: '',
                patient_name: '',
                patient_year: ''
            },
            viewinputs: null,
            viewMedico: null,
            Clinicahistory: {},
        }
    },
    props: {
        stateHistoryShow: {
            type: Number,
        },
        patients: {
            type: Object,
            default: () => ({})
        },
        viewInput: {
            type: Number,
        }
    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.subpage) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.subpage) return 'color:white';
            return '';
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        getHistoryPatient: function (datos) {
            const {
                patient
            } = datos;
            this.changeSubPage('historiauci');
            this.showHistoryComplet = 1;
            this.patient = patient;
            this.mtdClear();

        },
        mtdGetDataFilter: function () {
            //Cargar data de acuerdo a lo buscado
            this.post({
                url: this.$store.getters.get__url + "/Ucineo/filter",
                token: this.$store.getters.get__token,
                params: this.filterHistory,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.resultFilter = response.data;
                }
            });
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            this.filterHistory.startDate = firstDayOfMonth.toISOString().split('T')[0];
        },
        mtdClear: function () {
            this.datapatients = {
                patient_id: this.patient.patient_id,
                model_id: this.patient.model_id,
                patient_year: this.patient.patient_year,
                patient_name: this.patient.patient_name,
            }
            this.viewinputs = 0
            this.viewMedico = 0
            this.Clinicahistory = this.patient
        },
        mtdBackPage: function () {
            
            if (this.stateHistoryShow === 1) {
                this.getFirstDayOfMonth();
                this.showHistoryComplet = 0;
                this.stateDisabled = false;
                this.mtdGetDataFilter();
                this.viewinputs=1;
            } else {
                this.$emit('changePage', 'cola');
            }

        },

    }

}
</script>
