<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row mx-0">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">#</th>
                        <th scope="col">Lote</th>
                        <th scope="col">Medicamento</th>
                        <th scope="col">Cant. Despachada</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 10px">
                    <tr v-for="(det, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{ index + 1 }}</td>
                        <td>{{ det.lote }}</td>
                        <td>{{ det.product }}</td>
                        <td>{{ det.quantity_dispatch }}</td>
                      </template>
                    </tr>
                    <tr class="text-center align-middle">
                        <th scope="col" colspan="3" style="font-style: italic; color: #900052; font-size: 14px">TOTAL</th>
                        <th scope="col" style="color: #900052; font-size: 14px">{{ totalQuantityDispatch }}</th>
                    </tr>
                  </tbody>
              </table>
          </div>

      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";
export default {
  name: "c-pharmacy-cModalDetalle",
  components: {
    CModal,
  },
  data() {
    return {
      tabla: [],
    };
  },
  created () {
  },
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show;
    },
    totalQuantityDispatch() {
      let total = 0;
      this.cpData.forEach(det => {
        total += det.quantity_dispatch;
      });
      return total;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    producto: String,
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    mtdClose() {
      this.$emit("mtdCloseModalDeta");
    },
  },
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>
