<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="col-md-12 col-lg-5 col-xs-12 mt-2 " v-for="(unit,index) in dataunits " :key="index">
                <div class="form-check">
                    <input :disabled="unit.id === 1" :value="unit.id" v-model="modalunit.unitId" class="form-check-input" type="checkbox" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{ unit.name }}
                    </label>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="addUnits">
                <i class="fas fa-save" />&nbsp; Guardar
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            unit: {
                id: "",
                name: "",
                quantity: "",
                state: "",
            },
            selectedUnits: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        action: String,
        data: {},
        dataunits: {
            type: Array,
            default: () => [],
        },
        modalunit: Object,
    },
    computed: {
        cpDisabled() {
            if (this.unit.name.length > 0 && this.unit.quantity.length > 0) return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

        mtdClose() {
            this.unit = {
                id: "",
                name: "",
                quantity: "",
                state: "",
            };
            this.$emit("closeModal");
        },

        mtdclosemodal() {
            this.unit = {
                id: "",
                name: "",
                quantity: "",
                state: "",
            };
            this.$emit('closeModal')
        },

        mtdCommitUnit: function () {
            this.$emit("mtdCommitUnit", this.unit);
            this.unit = {
                id: "",
                name: "",
                quantity: "",
                state: "",
            };
        },

        addUnits: function () {
            this.post({
                url: this.$store.getters.get__url + "/Medicament/addunits",
                token: this.$store.getters.get__token,
                params: this.modalunit,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdClose()
                } else {
                    this.$refs.SweetAlert.showError("Error al registrar");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        }
    },
    

};
</script>