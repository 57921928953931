<template>
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex p-2">
        <div class="col-md-12">
            <div class="p-4 text-center border border-1 shadow">
                <div id="chart">
                    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
    </template>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";
import VueApexCharts from 'vue-apexcharts';

export default {
    name: "c-jefatura-graficExpenseAnnual",
    components: {
        CModal,
        apexchart: VueApexCharts,
    },
    data() {
        return {
            data: [],
            meses: [],
            //grafico
            series: [{
                name: 'Pasaje',
                data: []
            }, {
                name: 'Egresos',
                data: []
            }, {
                name: 'Retiro Caja fuerte',
                data: []
            }, {
                name: 'RH',
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            total: {
                                enabled: true,
                                offsetX: 0,
                                style: {
                                    fontSize: '12px',
                                    fontWeight: 700
                                },
                                formatter: function (val) {
                                    return "S/. " +  Number(val).toFixed(2);
                                }
                            }
                        }
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: 'Gráfico de Egresos Anuales - 2024'
                },
                xaxis: {
                    categories: [],
                    labels: {
                        formatter: function (val) {
                            return val + " soles"
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'MESES'
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return Number(val).toFixed(2) + " soles"
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        box_id: Number,

    },
    computed: {

    },
    watch: {
        title: function (newData) {
            if (this.boo_modal == true) {
                this.mtdGetData();
            }
        }
    },
    created() {},
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            if (this.box_id !== 0) {
                this.get({
                        url: this.$store.getters.get__url + "/ReportesCajas/reportExpenseAnnual/" + this.box_id,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.data = response.data.totals;
                        this.meses = response.data.months;
                        this.updateChartData();

                    })
                    .catch((errors) => {});
            }
        },
        updateChartData() {
            //const methods = ['Pasajes', 'Egresos', 'Retiro Caja Fuerte', 'RH'];
            let arrayP = this.data[0].totales.map(item => parseFloat(item.total).toFixed(2));
            let arrayE = this.data[1].totales.map(item => parseFloat(item.total).toFixed(2));
            let arrayRetiro = this.data[2].totales.map(item => parseFloat(item.total).toFixed(2));
            let arrayR = this.data[3].totales.map(item => parseFloat(item.total).toFixed(2));

            this.series = [{
                    name: 'Pasaje',
                    data: arrayP
                }, {
                    name: 'Egresos',
                    data: arrayE
                }, {
                    name: 'Retiro Caja fuerte',
                    data: arrayRetiro
                }, {
                    name: 'RH',
                    data: arrayR
                }],
                this.chartOptions = {
                    ...this.chartOptions,
                    xaxis: {
                        ...this.chartOptions.xaxis,
                        categories: this.meses,
                    }
                };
            this.$nextTick(() => {
                if (this.$refs.apexChart1) {
                    this.$refs.apexChart1.updateSeries(this.series);
                    this.$refs.apexChart1.updateOptions(this.chartOptions);
                }
            });
        },
        mtdclosemodal() {
            this.$emit('mtdclosemodal')
        },
    },
};
</script>
