<template>
  <div>
      <div class="container pb-5">
          <div id="btn-open-caja" class="d-flex justify-content-end pt-1">
              <div class="btn-group" role="group">
                  <button type="button" class="btn w-100 btn-bm-noradius" @click="mtdBackCampus">
                      <i class="fas fa-recycle"></i> Cambiar de Sede
                  </button>
              </div>
          </div>
          <div class="row d-flex p-1 mt-3" id="grafico">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <div class="p-1 text-center border border-3 shadow">
                      <strong>Gráfico Anual</strong>
                      <div id="chart1">
                          <apexchart ref="apexchart1" type="bar" height="260" :options="chartOptions" :series="series"></apexchart>
                      </div>
                  </div>
                  <div class="row mt-2">
                      <div class="col-md-1">
                          <a @click="mtdshowDetail" class="text-info" title="ver datalles">
                              <i class="fas fa-arrow-down" v-if="viewTable == false"></i>
                              <i class="fas fa-arrow-up" v-if="viewTable == true"></i>
                          </a>
                      </div>
                      <div class="col-md-11 mt-1 table-responsive" v-if="viewTable == true">
                          <table class="table table-bordered">
                              <thead class="bg-main" style="font-size: 11px">
                                  <tr class="text-white text-center">
                                      <th :class="cpStyle">MES</th>
                                      <th :class="cpStyle">MONTO INICIAL</th>
                                      <th :class="cpStyle">MONTO FINAL</th>
                                      <th :class="cpStyle">DETALLE</th>
                                  </tr>
                              </thead>
                              <tbody class="" style="font-size: 11px">
                                  <tr v-for="(item, index) in data" :key="index">
                                      <td :class="cpStyleBody">{{ item.mes }}</td>
                                      <td :class="cpStyleBody">S/. {{ item.initial }}</td>
                                      <td :class="cpStyleBody">S/. {{ item.final }}</td>
                                      <td :class="cpStyleBody">
                                          <a @click="mtdGetDataMonth(index)" class="text-info">
                                              <i class="fas fa-eye"></i>
                                          </a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <div class="p-1 text-center border border-3 shadow">
                      <div class="row mb-1">
                          <div class="col-9 text-center">
                              <strong>Gráfico Semanal - Mes {{ months[mes-1] }}</strong>
                          </div>
                          <div class="col-3">
                              <button type="button" class="btn btn-info btn-sm me-1" v-if="mes-1 > 0" @click="mtdGetDataMonth(mes-2)">
                                  <i class="fas fa-chevron-left"></i>
                              </button>
                              <button type="button" class="btn btn-info btn-sm" v-if="mes < 12" @click="mtdGetDataMonth(mes)">
                                  <i class="fas fa-chevron-right"></i>
                              </button>
                          </div>
                      </div>
                      <div id="chart2">
                          <apexchart ref="apexchart2" type="bar" height="250" :options="chartOptions2" :series="seriesWeek"></apexchart>
                      </div>
                  </div>
                  <div class="row mt-2" v-if="viewTable == true">
                      <div class="col-md-12 mt-1 table-responsive">
                          <table class="table table-bordered">
                              <thead class="bg-main" style="font-size: 11px">
                                  <tr class="text-white text-center">
                                      <th :class="cpStyle">FECHA</th>
                                      <th :class="cpStyle">MONTO INICIAL</th>
                                      <th :class="cpStyle">MONTO FINAL</th>
                                  </tr>
                              </thead>
                              <tbody class="" style="font-size: 11px">
                                  <tr v-for="(item, index) in dataMes" :key="index">
                                      <td :class="cpStyleBody">{{ item.date }}</td>
                                      <td :class="cpStyleBody">S/. {{ item.initial }}</td>
                                      <td :class="cpStyleBody">S/. {{ item.final }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                  <div class="p-1 text-center border border-3 shadow">
                      <div class="row mb-1">
                          <div class="col-9 text-center">
                              <strong>Gráfico Diario - S{{ week }} - Mes {{ months[mes-1] }}</strong>
                          </div>
                          <div class="col-3">
                              <button type="button" class="btn btn-info btn-sm me-1" v-if="week-1 > 0" @click="mtdGetDataDay(week - 2)">
                                  <i class="fas fa-chevron-left"></i>
                              </button>
                              <button type="button" class="btn btn-info btn-sm" v-if="week < dataMes.length" @click="mtdGetDataDay(week)">
                                  <i class="fas fa-chevron-right"></i>
                              </button>
                          </div>
                      </div>
                      <div id="chart3">
                          <apexchart ref="apexchart3" type="bar" height="220" :options="chartOptions3" :series="seriesDays"></apexchart>
                      </div>
                  </div>
                  <div class="row mt-2" v-if="viewTable == true">
                      <div class="col-md-12 mt-1 table-responsive">
                          <table class="table table-bordered">
                              <thead class="bg-main" style="font-size: 11px">
                                  <tr class="text-white text-center">
                                      <th :class="cpStyle">FECHA</th>
                                      <th :class="cpStyle">MONTO INICIAL</th>
                                      <th :class="cpStyle">MONTO FINAL</th>
                                  </tr>
                              </thead>
                              <tbody class="" style="font-size: 11px">
                                  <tr v-for="(item, index) in dataDay" :key="index">
                                      <td :class="cpStyleBody">{{ item.date }}</td>
                                      <td :class="cpStyleBody">S/. {{ item.initial }}</td>
                                      <td :class="cpStyleBody">S/. {{ item.final }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import VueApexCharts from 'vue-apexcharts'

export default {
    data() {
        return {
            page: null,
            mes: 0,
            week: 0,
            viewTable: false,
            months: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            day_initial: "",
            day_end: "",
            data: [],
            dataMes: [],
            dataAllDayforWeek: [],
            dataDay: [],
            //grafico
            series: [{
                name: 'Monto Inicial',
                data: []
            }, {
                name: 'Monto Final',
                data: []
            }],
            seriesWeek: [{
                name: 'Monto Inicial',
                data: []
            }, {
                name: 'Monto Final',
                data: []
            }],
            seriesDays: [{
                name: 'Monto Inicial',
                data: []
            }, {
                name: 'Monto Final',
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'S/. (Soles)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "S/. " + val + " Soles"
                        }
                    }
                }
            },
            chartOptions2: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'S/. (Soles)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "S/. " + val + " Soles"
                        }
                    }
                },
                colors: ['#7892CA', '#FFAF6E']
            },
            chartOptions3: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'S/. (Soles)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "S/. " + val + " Soles"
                        }
                    }
                },
                colors: ['#54D6C1', '#FFBC43']
            },
        };
    },
    components: {
        apexchart: VueApexCharts,
    },
    watch: {
        data: function (newData) {
            this.updateChartData();
        }
    },
    created() {
        this.mtdGetData();
    },
    computed: {
        cpStyle() {
            return 'align-middle border rounded-3 border-3 bg-main border-white ';
        },
        cpStyleBody() {
            return 'border border-3 rounded-3 border-white align-middle bg-light';
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdshowDetail() {
            this.viewTable = !this.viewTable;
        },
        getCurrentYear() {
            return new Date().getFullYear();
        },
        getCurrentMonth() {
            let number_mes = new Date().getMonth();
            return this.months[number_mes];
        },
        mtdGetData: function () {
            let number_mes = new Date().getMonth();
            this.get({
                    url: this.$store.getters.get__url + "/Jefatura/reportBeads",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.data = response.data;
                    this.mtdGetDataMonth(number_mes);
                })
                .catch((errors) => {});
        },
        mtdGetDataMonth: function (index) {
            this.mes = index + 1;
            this.get({
                    url: this.$store.getters.get__url + "/Jefatura/reportBeadsMonth/" + this.mes,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataMes = response.data.data;
                    this.dataAllDayforWeek = response.data.dataDays;
                    this.week = this.dataMes[0].number_week;
                    this.dataDay = this.dataAllDayforWeek[0].days;
                    this.updateChartDataMes();
                    this.mtdGetDataDay();
                })
                .catch((errors) => {});
        },
        mtdGetDataDay: function (week) {
            let weekNew = week;
            if(!week){
                weekNew=0;
            }
            this.week = this.dataMes[weekNew].number_week;
            this.dataDay = this.dataAllDayforWeek[weekNew].days;

            this.updateChartDataDay();
        },
        updateChartData() {
            this.series = [{
                    name: 'Monto Inicial',
                    data: this.data.map(item => item.initial)
                },
                {
                    name: 'Monto Final',
                    data: this.data.map(item => item.final)
                }
            ];
            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    ...this.chartOptions.xaxis,
                    categories: this.data.map(item => item.mes)
                }
            };
            this.$nextTick(() => {
                if (this.$refs.apexChart1) {
                    this.$refs.apexChart1.updateSeries(this.series);
                    this.$refs.apexChart1.updateOptions(this.chartOptions);
                }
            });
        },
        updateChartDataMes() {
            this.seriesWeek = [{
                    name: 'Monto Inicial',
                    data: this.dataMes.map(item => item.initial)
                },
                {
                    name: 'Monto Final',
                    data: this.dataMes.map(item => item.final)
                }
            ];
            this.chartOptions2 = {
                ...this.chartOptions2,
                xaxis: {
                    ...this.chartOptions2.xaxis,
                    categories: this.dataMes.map(item => item.number_week)
                }
            };
            this.$nextTick(() => {
                if (this.$refs.apexChart2) {
                    this.$refs.apexChart2.updateSeries(this.seriesWeek);
                    this.$refs.apexChart2.updateOptions(this.chartOptions2);
                }
            });
        },
        updateChartDataDay() {
            this.seriesDays = [{
                    name: 'Monto Inicial',
                    data: this.dataDay.map(item => item.initial)
                },
                {
                    name: 'Monto Final',
                    data: this.dataDay.map(item => item.final)
                }
            ];
            this.chartOptions3 = {
                ...this.chartOptions3,
                xaxis: {
                    ...this.chartOptions3.xaxis,
                    categories: this.dataDay.map(item => item.date)
                }
            };
            this.$nextTick(() => {
                if (this.$refs.apexChart3) {
                    this.$refs.apexChart3.updateSeries(this.seriesDays);
                    this.$refs.apexChart3.updateOptions(this.chartOptions3);
                }
            });
        },
        changePage(page) {
            this.$emit("changePage", page);
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    },
};
</script>
