<template>
<div>
    <div class="px-5 pb-5 pt-2">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="w-100">
            <div class="card w-100">
                <div class="card-header text-center bg-main text-white h4">
                    <strong>CUENTAS POR PAGAR</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex justify-content-between mx-0">
                                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Año</span>
                                        <select id="campu_id" class="form-control form-control" v-model="filter.year" @change="mtdGetData">
                                            <option selected value="">Seleccione ...</option>
                                            <option v-for="(item, index) in year" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-8 col-sm-12">
                                    <div class="row p-2">
                                        <div class="col-12">
                                            <div class="row d-flex card-filter">
                                                <div class="col-lg-3 col-md-3 col-sm-12 bg-secondary-subtle">
                                                    <span>Cuenta</span>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-12 d-flex align-items-center">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox11" value="1" v-model="filter.cuenta" @change="mtdGetData" />
                                                        <label class="form-check-label" for="inlineCheckbox11">Deuda</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox12" value="2" v-model="filter.cuenta" @change="mtdGetData" />
                                                        <label class="form-check-label" for="inlineCheckbox12">Saldo a favor</label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                    <div class="row p-2">
                                        <div class="col-12">
                                            <div class="row d-flex card-filter">
                                                <div class="col-md-3 col-sm-12">
                                                    <span>Estado</span>
                                                </div>
                                                <div class="col-md-9 col-sm-12 d-flex align-items-center">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox1" value="1" v-model="filter.state" @change="mtdGetData">
                                                        <label class="form-check-label" for="inlineCheckbox1">Vencido</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox2" value="2" v-model="filter.state" @change="mtdGetData">
                                                        <label class="form-check-label" for="inlineCheckbox2">Por vencer</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--tabla-->
                        <div class="col-md-12 py-2 table-container mt-2 table-responsive" id="content-timelinetable">
                            <table class="table table-borderless " style="font-size: 12px;">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main ">
                                    <tr class="text-white text-center tr-table text-xs">
                                        <th scope="col" class="border border-white">
                                            <div>CENTRAL DE COSTOS</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>PROVEEDOR</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>N°FACTURA</div>
                                        </th>
                                        <th scope="col" class="border border-white" v-for="(mes, indexM) in month" :key="indexM">
                                            <div>{{mes.name}}</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>TOTAL</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 11px">
                                    <template v-for="(item , index) in data">
                                        <tr class="border-table">
                                            <td :rowspan="item.details.reduce((sum, proveedor) => sum + proveedor.bills.length, 0)" class="centered">{{item.central_cost}}</td>
                                            <td :rowspan="item.details[0].bills.length">{{ item.details[0].provider }}</td>
                                            <td>{{ item.details[0].bills[0].number }}</td>
                                            <td v-for="(mes, indexM) in month" class="text-center">
                                                <template v-if="mes.month == item.details[0].bills[0].month">{{item.details[0].bills[0].pending =='0.00'?" " : item.details[0].bills[0].pending}}</template>
                                            </td>
                                            <td class="fw-bold text-center align-middle" :rowspan="item.details[0].bills.length">{{ item.details[0].total.toFixed(2) }}</td>
                                        </tr>
                                        <template v-for="(itemBill, indexBill) in item.details[0].bills">
                                            <template v-if="indexBill !==0">
                                                <tr class="border-table">
                                                    <td>{{itemBill.number}}</td>
                                                    <td v-for="(mes, indexM) in month" class="text-center">
                                                        <template v-if="mes.month == itemBill.month">{{itemBill.pending =='0.00'? " " : itemBill.pending}}</template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>

                                        <template v-for="(detail,pIndex) in item.details">
                                            <template v-if="pIndex !== 0">
                                                <tr class="border-table">
                                                    <td :rowspan="detail.bills.length">{{detail.provider}}</td>
                                                    <td>{{ detail.bills[0].number }}</td>
                                                    <td v-for="(mes, indexM) in month" class="text-center">
                                                        <template v-if="mes.month == detail.bills[0].month">{{ detail.bills[0].pending =='0.00'? " " :  detail.bills[0].pending}}</template>
                                                    </td>
                                                    <td class="fw-bold text-center align-middle" :rowspan="detail.bills.length">{{detail.total.toFixed(2)}}</td>
                                                </tr>
                                            </template>
                                            <template v-if="pIndex !== 0">
                                                <template v-for="(bill, bIndex) in detail.bills">
                                                    <template v-if="bIndex !== 0">
                                                        <tr class="border-table">
                                                            <td>
                                                                {{ bill.number }}
                                                            </td>
                                                            <td v-for="(mes, indexM) in month" class="text-center">
                                                                <template v-if="mes.month == bill.month">{{ bill.pending =='0.00'? " " :  bill.pending}}</template>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                    <tr v-if="month.length >0">
                                        <td></td>
                                        <td></td>
                                        <td class="bg-main-td text-white text-center fw-bold">
                                            <div>
                                                TOTAL
                                            </div>
                                        </td>
                                        <td v-for="(mes, indexM) in month" class="border border-main fw-bold text-center bg-total">
                                            {{ mes.totalMes }}
                                        </td>
                                        <td class="border border-main fw-bold text-center bg-total">S/. {{ total }}</td>
                                    </tr>
                                    <tr v-if="data.length == 0" class="border-table">
                                        <td colspan="8" class="fw-bold text-center">No se encontraron resultados</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-pages-compras",
    data() {
        return {
            filter: {
                year: "2024",
                state: ["1", "2"],
                cuenta: ["1"]
            },
            year: ['2021', '2022', '2023', '2024'],
            month: [],
            data: [],
            total: "",

        }
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            if (this.filter.state.length > 0 && this.filter.cuenta.length > 0) {
                this.post({
                        url: this.$store.getters.get__url + "/logistics/compras/cxp",
                        token: this.$store.getters.get__token,
                        params: this.filter

                    })
                    .then((response) => {
                        this.data = response.data;
                        this.month = response.meses;
                        let total = 0;
                        this.month.forEach(element => {
                            total += parseFloat(element.totalMes);
                        });
                        this.total = total.toFixed(2);

                    })
                    .catch((errors) => {});
            } else {
                this.data = [];
                this.month = [];
                this.total = 0.00;
            }
        },
        returnHome: function () {
            this.$emit("returnHome");
        },

    }
}
</script>

<style scoped>
.card-filter {
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid rgb(209, 206, 206);
    border-radius: 5px;
}

.centered {
    vertical-align: middle;
    text-align: center;
}

.border-table>td {
    border: 1px solid #900052;
}

.bg-main-td {
    background: #900052;
}

.bg-total {
    background-color: #ced1d1d0;
}
</style>
