<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput1" class="form-label ">Ingrese Comentario:</label>
            <textarea class="form-control form-control-sm" v-model="comment" id="exampleFormControlTextarea1" rows="5"></textarea>
        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="mtdaddcomment" :disabled="mtddisable">
            Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-uciadulto-diagnostico",

    data() {
        return {
            plan_detail: {
                name: '',
                subsubcategory_id: null,
                category_id: null,
                comment: ''
            },
            swal: null,
            comment: ''
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    watch: {
        plans: {
            handler(newVal) {
                this.plan_detail = newVal
            },
            immediate: true
        }
    },
    computed: {
        mtddisable: function () {
            return !this.comment
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        plans: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalcomment')
        },
        mtdaddcomment: function () {
            this.plan_detail.comment = this.comment
            this.$emit('mtdaddComment', this.plan_detail);
            this.comment =''
        }
    },
}
</script>
