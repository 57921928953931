<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content ">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" />
        <Consulta v-if="page=='consulta'" @mtdpaciente="mtdpaciente" :patientold="patientold" @mtdpacienteold="mtdpacienteold" @mtdFinish="mtdFinishCon" />
        <Interconsulta v-if="page=='interconsulta'" :patient="patient" />
        <Hospitalizacion v-if="page=='hospitalizacion'" @mtdpaciente="mtdpaciente" :patientold="patientold" @mtdpacienteold="mtdpacienteold" @mtdFinish="mtdFinish" />
        <Uciadulto v-if="page=='uciadulto'" @mtdpaciente="mtdpaciente" :patientold="patientold" @mtdpacienteold="mtdpacienteold" @mtdFinish="mtdFinishUci" />
        <Ucineo v-if="page=='ucineo'" />
        <Sop v-if="page=='sop'" />
        <Emergencia v-if="page=='emergencia'" @mtdpaciente="mtdpaciente" :patientold="patientold" @mtdpacienteold="mtdpacienteold" @mtdFinish="mtdFinishEmergency" />
        <Laboratorio v-if="page=='laboratorio'" :patient="patient" />
        <Imagen v-if="page=='imagen'" :patient="patient" />
        <Procedimiento v-if="page=='procedimiento'" :patient="patient" />
    </div>
    <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" :patient="patient" :pagefinish ="pagefinish" />
    </div>
    <div class="load-patient" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
        <i class="far fa-user-circle"></i>
    </div>
    <div class="offcanvas offcanvas-end" data-bs-backdrop="true" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header border">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">Cola de Pacientes</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body align-center">
            <div class="home__wait ">
                <div class="home__wait-div">

                    <div class="home__wait-user">
                        <i class="far fa-user-circle"></i>
                        <ul class="list-patient">
                            <li>
                                <b>Paciente</b>
                            </li>
                            <li v-if="Object.keys(patient).length !== 0">{{patient.patient_name}}</li>
                            <li v-else-if="Object.keys(patientnew).length !== 0">{{patientnew.patient_name}}</li>
                            <li v-else>No ha seleccionado un paciente aun</li>
                            <!-- <li>Luis Fernando Mechán Llontop</li> -->
                            <li>
                                <b>Provincia</b>
                            </li>
                            <li>Chiclayo</li>
                            <li>
                                <b>Ultima cita</b>
                            </li>
                            <li> - </li>
                        </ul>
                    </div>
                    <div class="home__wait-buttons" v-if=" Object.keys(patient).length > 0">
                        <button @click="openDestino" class="btn btn-outline-atendiendo w-100 mb-2">
                            <strong>Destino</strong>
                        </button><br>
                        <button @click="mtdFinishTotal" class="btn btn-outline-atendiendo w-100">
                            <strong>Finalizar cita</strong>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
    <cModaldestino :type_history ="type_history" :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodal="mtdclosemodal" @mtdstoredata="mtdstoredata" />
    <cModalBed :bed="bed" :title="cModalBed.title" :boo_modal="cModalBed.modal_form" @mtdclosemodalBed="mtdclosemodalBed" @mtdstoredata="mtdstoredata" @mtdaddbed="mtdaddbed" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Nav from '../../components/Asistencial/Nav.vue'
import Home from '../../components/Asistencial/Home.vue'
import Consulta from '../../components/Asistencial/ConsultaView.vue'
import Interconsulta from '../../components/Asistencial/InterconsultaView.vue'
import Hospitalizacion from '../../components/Asistencial/HospitalizacionView.vue'
import Uciadulto from '../../components/Asistencial/UciadultoView.vue'
import Ucineo from '../../components/Asistencial/UcineoView.vue'
import Sop from '../../components/Asistencial/SopView.vue'
import Emergencia from '../../components/Asistencial/EmergenciaView.vue'
import Footer from '../../components/Asistencial/Footer.vue'
import Laboratorio from '../../components/Asistencial/LaboratorioView.vue'
import Imagen from '../../components/Asistencial/ImagenesView.vue'
import Procedimiento from '../../components/Asistencial/ProcedimientoView.vue'
import cModaldestino from '@/components/Asistencial/Emergency/modals/cModaldestino.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalBed from '@/components/Asistencial/Emergency/modals/cModalBed.vue'
export default {
    name: "AsistencialView",
    components: {
        Nav,
        Home,
        Consulta,
        Interconsulta,
        Hospitalizacion,
        Uciadulto,
        Ucineo,
        Sop,
        Emergencia,
        Footer,
        Laboratorio,
        Imagen,
        Procedimiento,
        cModaldestino,
        SweetAlert,
        cModalBed
    },
    data() {
        return {
            prefix: 'asistencial',
            page: 'home',
            rol_name: null,
            stateRolAdmin: false,
            patient: {},
            patientold: {},
            patientnew: {},
            modal: {
                title: "",
                modal_form: false,
            },
            swal: null,
            cModalBed: {
                title: "",
                modal_form: false,
            },
            bed: {
                campu_id: null,
                type: null,
            },
            type_history:null,
            pagefinish:''
        }
    },
    props: {
        rolname: String,
        dataRoles: Array
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function (page) {
            this.page = page;
        },
        mtdGetRolAdmin: function () {
            this.dataRoles.forEach(element => {
                if (element.role_id == 1) {
                    this.stateRolAdmin = true;
                }
            });
        },
        mtdpaciente: function (payload) {
            this.patient = payload.patient
            this.patientold = payload
        },
        mtdpacienteold: function (payload) {
            this.patientnew = payload.patient
        },

        mtdFinishTotal: function () {
            if (this.patient.type == 7) {
                this.page = 'emergencia'
            } else if (this.patient.type == 3) {
                this.page = 'hospitalizacion'
            } else if(this.patient.type == 1){
                 this.page = 'consulta'
            }else if(this.patient.type == 4){
                  this.page = 'uciadulto'
            }
            this.patient = {}
            this.patientold = {}
            this.patientnew = {}
            this.finishAndClose()
            this.pagefinish = this.page
            // this.pagefinish=''
        },
        mtdFinish: function () {
            this.patient = {}
            this.patientold = {}
            this.patientnew = {}
            this.page = 'hospitalizacion'
            this.finishAndClose()
        },
        mtdFinishUci: function () {
            this.patient = {}
            this.patientold = {}
            this.patientnew = {}
            this.page = 'uciadulto'
            this.finishAndClose()
        },
        mtdFinishEmergency: function () {
            this.patient = {}
            this.patientold = {}
            this.patientnew = {}
            this.page = 'emergencia'
            this.finishAndClose()
        },
        mtdFinishCon: function () {
            this.patient = {}
            this.patientold = {}
            this.patientnew = {}
            this.page = 'consulta'
            this.finishAndClose()
        },
        // vaciarpatient: function () {
        //     this.patient = {}
        //     this.patientold = {}
        //     this.patientnew = {}
        // },
        openDestino: function () {
            this.modal = {
                title: "DESTINO",
                modal_form: true,
            }
            this.type_history=this.patient.type
        },
        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            }
        },
        mtdstoredata: function (payload) {
            if (payload == 0) {
                let medical_id = this.patient.medical_act_id
                this.post({
                        url: this.$store.getters.get__url + "/admision/destination",
                        token: this.$store.getters.get__token,
                        params: {
                            id: medical_id
                        },
                    })
                    .then((response) => {
                        this.mtdclosemodal()
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdFinishTotal()
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }else if (payload == 1) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 3,
                }
                this.mtdclosemodal()
            }else if (payload == 2) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 4,
                }
                this.mtdclosemodal()
            }else if (payload == 3) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 7,
                }
                this.mtdclosemodal()
            }

        },
        mtdclosemodalBed: function () {
            this.cModalBed = {
                title: "",
                modal_form: false,
            }

        },
        finishAndClose() {

            let offcanvasElement = document.getElementById('offcanvasRight');
            let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
            if (offcanvasInstance) {
                offcanvasInstance.hide();
            }
        },
        mtdaddbed: function (payload, type, subsubcategories) {
            let data = {
                type: type,
                bed_id: payload.id,
                patient_id: this.patient.patient_id,
                id: this.patient.medical_act_id,
                campu_id : this.$store.getters.get__campus,
                subsubcategorie_id :subsubcategories.id
            }
            this.post({
                    url: this.$store.getters.get__url + "/admision/reservation",
                    token: this.$store.getters.get__token,
                    params: data
                })
                .then((response) => {
                    this.mtdclosemodalBed()
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdFinishTotal()
                })
                .catch((errors) => {
                    console.log(errors);
                });
        }

    }

}
</script>

<style>
.load-patient {
    position: absolute;
    right: 0;
    top: 42%;
    background: #900052;
    padding: 0.5rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    cursor: pointer;
}

.load-patient i {
    font-size: 2.3rem;
    color: #fff;
}

.home__wait {
    height: 94%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 45%;
    flex: 1 0 45%;
    color: #777;
    background: #fff;
    border: 2px solid #900052;
}

.home__wait-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0rem 1rem;
    overflow-y: auto;
}

.home__wait-user {
    margin-top: 1rem;
    text-align: center;
    flex: 1 0 auto;
}

.home__wait-user i {
    font-size: 5rem;
}

.home__wait-user .list-patient {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1.5rem;
}

.home__wait-user .list-patient li {
    font-size: 1.1rem;

}

.home__wait-user .list-patient li b {
    font-weight: 600 !important;
}

.home__wait-user .list-patient li:nth-child(even) {
    margin-bottom: 0.5rem;
}

.home__wait-buttons {
    padding-bottom: 5%;
}

.btn-outline-atendiendo {
    color: #900052 !important;
    border-color: #900052 #900052 #5d0035 !important;
}

.btn-outline-atendiendo:hover {
    background-color: #900052 !important;
    border-color: #900052 !important;
    color: #fff !important;
}
</style>
