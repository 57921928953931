<template>
    <div>
      <div class="px-4 py-3 text-center mb-3">
        <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h6 bg-main">
                    <strong>FACTURACIÓN</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="row d-flex">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                                <div class="input-group input-group">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <v-select
                                        class="text-dark form-control"
                                        label="name"
                                        :options="servicesPa"
                                        placeholder="-- Buscar Persona --"
                                        @search="searchPa"
                                        v-model="selectedPa"
                                        :reduce="(name) => name.id"
                                        required
                                        :clearable="false"
                                        @input="mtdGetData"
                                    >
                                        <template slot="no-options">
                                        No hay Pacientes para la busqueda.
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable" v-if="showTable && cpData.length !== 0">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-center text-white border-white bg-main align-middle">
                                        <th scope="col"><strong>DOCUMENTO</strong></th>
                                        <th scope="col">FECHA</th>
                                        <th scope="col">DESTINO</th>
                                        <th scope="col">TIPO DE PACIENTE</th>
                                        <th>% PAGA PACIENTE</th>
                                        <th scope="col">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <template>
                                    <tr  v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                                        <td><strong>{{item.serie}} - {{item.number}}</strong></td>
                                        <td>{{item.date | formatDateTime}}</td>
                                        <td>{{item.destination}}</td>
                                        <td>{{ item.type_patient}}</td>
                                        <td style="white-space: nowrap;">% {{ item.payment_patient}}</td>
                                        <td>
                                        <div class="d-flex d-md-block">
                                                <button
                                                    @click="mtdPdf(item.id)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                                    <i class="far fa-file-pdf"></i>
                                                </button>
                                                <button
                                                    @click="mtdAddPrice(item, index)" class="btn btn-primary btn-sm rounded-circle bootom">
                                                    <i class="fas fa-money-bill"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                  </template>
                                </tbody>
                            </table>
                        </div>
                        <!--Paginado-->
                        <div v-if="showTable && cpData.length > 0" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <cModalAddPayVue
        :title="modalPay.title"
        :boo_modal="modalPay.boo_modal"
        :item="modalPay.item"
        :saleId="modalPay.sale_id"
        :patientId="modalPay.patient_id"
        @mtdCommitEditPrice="mtdCommitEditPrice"
        @mtdClose="mtdClose"
        @mtdUpdate="mtdGetDataUp"
    />
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalAddPayVue from "../Modals/cModalAddPay.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-pages-billing",
    components:{
        SweetAlert,
        cModalAddPayVue
    },
    data() {
    return {
        swal: null,
        search: "",
        data:[],
        dataShow: [],
        servicesPa: [],
        selectedPa: "",
        showTable: false,
        modalPay: {
        title: "Editar Liquidación",
        item: [],
        boo_modal: false,
        pos: null,
        sale_id: null,
        patient_id: null,
        },
        //Pag
        page: 0,
        selectPage: 1,
        stepPagination: [],
        totalPagesToShow: 4,
    }
    },
    created() {
    },
    watch: {
    selectPage() {
        this.calculateData(10);
    },
    },
    computed: {
    cpData() {
        return this.dataShow;
    },

    limitedStepPagination() {
        const totalPages = this.stepPagination.length;
        const currentPage = this.selectPage;

        let startPage = 1;
        let endPage = totalPages;
        if (totalPages > this.totalPagesToShow) {
            const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

            if (currentPage > halfPagesToShow) {
                startPage = currentPage - halfPagesToShow;
                endPage = currentPage + halfPagesToShow - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = totalPages - this.totalPagesToShow + 1;
                }
            } else {
                endPage = this.totalPagesToShow;
            }
        }

        return Array.from({
                length: endPage - startPage + 1,
            },
            (_, i) => startPage + i
        );
    },
    },
    methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
        this.$emit("returnHome");
    },

    searchPa: function (search, loading) {
        if (search.length > 3) {
            this.showTable = false;
            this.servicesPa = [];
            this.selectedPa =  "",
            loading(true);

            this.get({
                url: this.$store.getters.get__url + "/Billing/patient/" + search,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.servicesPa = response.data;
                loading(false);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        }
    },

    mtdGetData: function() {
        this.selectedProd = "";
        this.post({
            url: this.$store.getters.get__url + "/Billing/mtdGetDispath",
            token: this.$store.getters.get__token,
            params: {
                selectedPa: this.selectedPa,
            },
        })
        .then((response) => {
            if(response.data.length > 0){
                this.data = response.data;
                this.showTable = true;
                this.calculateData(10);
            }else{
                this.showTable = false;
                this.$refs.SweetAlert.showWarning("No hay Datos");
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

    mtdGetDataUp: function(item) {
        this.selectedProd = "";
        this.post({
            url: this.$store.getters.get__url + "/Billing/mtdGetDispath",
            token: this.$store.getters.get__token,
            params: {
                selectedPa: item,
            },
        })
        .then((response) => {
            if(response.data.length > 0){
                this.data = response.data;
                this.showTable = true;
                this.calculateData(10);
            }else{
                this.showTable = false;
                this.$refs.SweetAlert.showWarning("No hay Datos");
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

    calculateData(items) {
        if (!this.data || !this.data.length) {
            return (this.dataShow = []);
        }
        let filteredData = this.data;
        let indexInitial = this.selectPage;

        if (this.selectedProd && this.selectedProd !== 'TODOS') {
            filteredData = filteredData.filter((element) => {
                return element.name === this.selectedProd;
            })
            indexInitial = 1;
        }

        let totalPages = Math.ceil(filteredData.length / items);
        this.stepPagination = Array.from({
                length: totalPages,
            },
            (_, index) => index + 1
        );
        let startIndex = (indexInitial - 1) * items;
        let endIndex = startIndex + (items - 1);
        this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        this.count = this.count + 1;
    },

    filterKey: function (e) {
        let b = false;
        b = /^[0-9]+$/.test(e.key);
        if (b == false && e.key != "Backspace") {
        e.preventDefault();
        }
    },

    mtdPdf: function(id) {
        window.open(
            this.$store.getters.get__url + "/Pharmacy/recetasDispathPdf/" + id,
            "_blank"
        );
    },

    mtdAddPrice: function(item, pos) {
        this.modalPay.title = `Editar Liquidación: ${item.serie} - ${item.number}`;
        this.modalPay.item = item.detail;
        this.modalPay.pos = pos;
        this.modalPay.sale_id = item.sale_id;
        this.modalPay.patient_id = item.patient_id;
        this.modalPay.boo_modal = true;
    },

    mtdClose: function () {
        this.modalPay = {
        title: "Editar Liquidación",
        item: [],
        boo_modal: false,
        pos: null,
        sale_id: null,
        patient_id: null,
        };
    },

    mtdCommitEditPrice: function (pos, newPrice) {
        this.get({
        url:
            this.$store.getters.get__url +
            "/Billing/editPrice/" +
            this.modalPay.item[pos].id +
            "/" +
            newPrice,
        token: this.$store.getters.get__token,
        })
        .then((response) => {
            if (response.data.state == 0) {
                this.modalPay.item[pos].price = newPrice;
                this.$refs.SweetAlert.showSuccess("Editado Correctamente");
            } else {
                this.$refs.SweetAlert.showError("Error");
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

    /** paginación */
    backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
    nextPage() {
        this.selectPage =
            this.selectPage < this.stepPagination.length ?
            this.selectPage + 1 :
            this.stepPagination.length;
    },
    selectedPage(page) {
        this.selectPage = page;
    },


    },
    filters: {
    formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatDateTime(dateTime) {
        return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
    },
    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
}
.bootom {
    background-color: #900052 !important;
    border: 0.5px solid #900052 !important;
}
</style>
