<template>
<div>
    <div class="container">
        <div class="row">
            <div id="btn-home" class="col-md-12 mt-3 mb-4 d-flex justify-content-end btn-home">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 text-center" style="background: #900052">
                    <strong>STOCK MÍNIMO</strong>
                </div>

                <div class="card-body w-100">
                    <div class="row d-flex mt-2">
                        <div class="col-xl-7 col-lg-7 col-md-12 py-2">
                            <div class="row d-flex">
                                <div class="col-lg-12 col-md-12">
                                    <div class="input-group input-group mb-2">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="search" type="text" class="form-control form-control" placeholder="Escriba un producto para realizar la búsqueda" @input="mtdGetData" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xl-2 col-lg-2 col-md-6 py-2">
                            <button type="button" class="btn btn-bm-noradius w-100" @click="mtdGetData(0)">
                                Ver Todo
                            </button>
                        </div> -->
                        <div class="col-xl-3 col-lg-3 col-md-6 py-2">
                            <div class="row d-flex">
                                <div class="col-12 d-flex align-items-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                        <label class="form-check-label" for="inlineRadio1">12</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                                        <label class="form-check-label" for="inlineRadio2">6</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                                        <label class="form-check-label" for="inlineRadio3">3</label>
                                    </div>
                                    <span>Meses</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination mb-0 ">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-md-12 mb-4" id="dtb-cxc">
                            <table id="dtb-cxc" class="table table-bordered border-main text-center align-middle">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col">PRODUCTO</th>
                                        <th scope="col">STOCK MIN.</th>
                                        <th scope="col">STOCK MAX.</th>
                                        <!-- <th scope="col">ACCIONES</th> -->
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-if="cpData.length === 0 && search.length==0">
                                        <td colspan="16" class="text-center">Escriba medicamento</td>
                                    </tr>
                                    <tr v-if="cpData.length === 0 && search.length > 7">
                                        <td colspan="16" class="text-center">No se encontraron resultados</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index">
                                        <td>{{ mtdNumber(index) }}</td>
                                        <td class="align-left">{{ item.subsubcategorie.name}} / {{item.molecule}} </td>
                                        <td>{{ item.minimum_stock == null ? '0' :  parseInt(item.minimum_stock) }}</td>
                                        <td>{{ item.max_stock == null ? '0' :  parseInt(item.max_stock)}}</td>
                                       <!--  <td>
                                            <a @click="viewStock(item)" class="btn btn-sm btn-info" type="button">
                                                <i class="fas fa-edit"></i>
                                            </a>
                                        </td> -->
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalViewStockVue :title="modalStock.title" :boo_modal="modalStock.modal_form" @closeModalStock="closeModalStock" @mtdRegisterStock="mtdRegisterStock" :item="item" />

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalViewStockVue from "../modals/StockMin/cModalViewStock.vue";
import moment from "moment";

export default {
    name: "c-logistica-pages-stockminimo",
    components: {
        cModalViewStockVue
    },
    data() {
        return {
            search: "",
            data: [],
            dataShow: [],
            totalPagesToShow: 4,
            selectPage: 1,
            noRecordsMessage: "",
            modalStock: {
                title: "Registro de Stock",
                item: {},
                modal_from: false,
                pos: null,
            },
            item: {
                id: null,
                name: '',
                min_stock: '',
                max_stock: ''
            },
            stepPagination: [],
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        //cpData() {}
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        //this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function (option) {
            if(option==0){
                this.get({
                    url: this.$store.getters.get__url + "/logistics/stock",
                    token: this.$store.getters.get__token,
                }).then((response) => {
                    this.data = response.data;
                    if (this.data.length === 0) {
                        this.dataShow = [];
                    } else {
                        this.calculateData(10);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }else{
                if (this.search.length > 7) {
                    this.selectPage = 1;
                    this.dataShow = [];
                    this.get({
                        url: this.$store.getters.get__url + "/logistics/stock/filter/" + this.search,
                        token: this.$store.getters.get__token,
                    }).then((response) => {
                        this.data = response.data;
                        if (this.data.length === 0) {
                            this.dataShow = [];
                        } else {
                            this.calculateData(10);
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },

        viewStock: function (item) {
            this.modalStock = {
                title: "Registro de Stock de " + item.subsubcategorie.name,
                modal_form: true,
                idProductDetail: item.id
            };
            this.item = item
        },
        closeModalStock: function () {
            this.modalStock = {
                title: "Registro de Stock",
                modal_form: false,
                idProductDetail: 0
            };
            this.item = {}
        },
        mtdRegisterStock: function (stock_min, stock_max) {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/stock/store",
                    token: this.$store.getters.get__token,
                    params: {
                        product_detail_id: this.modalStock.idProductDetail,
                        stock_min: stock_min,
                        stock_max: stock_max,
                    },
                })
                .then((response) => {
                    if (response.state == 0) {
                        Swal.fire({
                            icon: "success",
                            title: "Pago registrado",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.closeModalStock();
                        this.mtdGetData();
                    } else {
                        Swal.fire({
                            icon: "warning",
                            title: "Ocurrio un error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                })
                .catch((errors) => {});
        },
        changePage(page) {
            this.$emit("returnHome", page);
        },
    },

    /* filters: {
      formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
    }, */
};
</script>
