<template>
    <div>
        <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
      <div :style="mtdGetStyle('planilla')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('planilla')"><span :style="mtdGetStyleSpan('planilla')" class="btn w-100"><strong>PLANILLAS</strong></span></div>
      
    </nav>
    </div>
</template>
<script>
export default {
  name: 'FooterRecursosHumanosComponent',
  props: {
    pageView: String
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home") return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home") return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>