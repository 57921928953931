<template>
<div>
    <div id="app" class="px-2 mt-3">
        <div class="row mb-1 mx-0" id="boton-arrow" v-if="stateHistoryShow === 0 || showHistoryComplet ==1">
            <div class=" d-flex justify-content-end px-0">
                <!-- <div class="btn-success text-start ">
                    <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackPage">
                        <i class="fas fa-arrow-left"></i> Atr&aacute;s
                    </button>
                </div> -->
                <div v-if="!origin  && !dete_out">
                    <button @click="openDestino" type="button" class="btn text-white btn-sm bg-main text-xs px-5  ">
                        <i class="fas fa-search"></i> DESTINO
                    </button>
                </div>
            </div>

        </div>
        <div class="row" v-if="viewinputs!==0">
            <div class="col-lg-8 col-md-12 ">
                <form class="d-flex flex-row">
                    <div class="row mb-4">
                        <div id="nombre" class="input-group-sm col-md-4 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="nombre" placeholder="Nombre y Apellido" autocomplete="off" v-model="filterHistory.name" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="edad" class="input-group-sm col-md-1 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="edad" placeholder="Edad" autocomplete="off" v-model="filterHistory.year" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_inicio" placeholder="Fecha de inicio" autocomplete="off" v-model="filterHistory.startDate" :disabled="stateDisabled" @change="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_fin" placeholder="Fecha Fin" autocomplete="off" v-model="filterHistory.endDate" :disabled="stateDisabled" @change="mtdGetDataFilter" />
                        </div>
                        <div id="dias" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="dias_transcurridos" placeholder="Dias transcurridos" autocomplete="off" v-model="filterHistory.dayPassed" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mx-0 text-white h-100  " v-if="viewinputs===0">
            <div class="mx-0 col-sm-12 col-md-12 col-xl-6 h-100 order-lg-1  rounded-3 px-0 bg-blue mb-2">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center  ">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-12 order-md-1 order-lg-2 px-0 px-xl-1">
                <div class="row" v-if="viewMedico===0">
                    <!-- <div class="col-md-10 d-inline-flex w-100">
                        <div class="input-group input-group-sm w-100">
                            <span class="input-group-text text-white bg-main" id="inputGroup-sizing-sm">Médico</span>
                            <input type="text" class="form-control text-xs" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" v-model="patient.doctor_name" disabled />
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-1 col-md-12 order-md-1 order-lg-2 py-2 py-xl-0 px-0">
                <div class="row px-0 mx-1" v-if="viewMedico===0">
                    <div v-if="origin" class="col-md-10 d-inline-flex w-100 px-0">
                        <button @click="mtdaddhistory" type="button" class="btn text-white btn-sm bg-main text-xs w-100">
                            Guardar Historial
                        </button>
                    </div>
                    <div v-if="!origin  && !dete_out" class="col-md-10 d-inline-flex w-100 px-0">
                        <button @click="mtdaddall" type="button" class="btn text-white btn-sm bg-main text-xs w-100">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-2   mt-2" v-if="stateHistoryShow === 0 || showHistoryComplet ==1">
        <ul class=" nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoHistorial" role="tablist">
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('Hchospitalizacion')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'Hchospitalizacion' }" id="hame-tab" type="button">
                    <strong class="text-xxs">HC HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('evolucionmedica')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'evolucionmedica' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">EV. MEDICA HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('rp')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'rp' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">R.P. HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('hojagrafica')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'hojagrafica' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">HOJA GRAFICA HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('notaenfermeria')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'notaenfermeria' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">N. ENFERMERIA HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('kardex')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'kardex' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">KARDEX HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('oxigeno')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'oxigeno' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">OXIGENO HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('bme')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'bme' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">BME HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('monitoreodinamico')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'monitoreodinamico' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">MONITOREO HOSPITALIZACIÓN</strong>
                </button>
            </li>
            <li class="nav-item-h" role="presentacion">
                <button @click="changeSubPage('balanceHidrico')" :class="{ 'item-button': true, 'nav-link': true, 'active': subpage === 'balanceHidrico' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">BALANCE HIDRICO HOSP.</strong>
                </button>
            </li>
        </ul>
        <div class="tab-content mb-5 d-none d-lg-block" id="myTabContentHijo">
            <HchospitalizacionDesktop :metodoadd="metodoadd" @mtdaddexit="mtdaddexit" @changePage="changePage" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" :showHistory="showHistory" v-if="subpage == 'Hchospitalizacion'" />
            <Emedica v-if="subpage == 'evolucionmedica'" :medical_act_id="Clinicahistory.medical_act_id" :addEv="addEv" @mtdaddevexit="mtdaddevexit" :dete_out="dete_out" />
            <Rp v-if="subpage == 'rp'" :medical_act_id="Clinicahistory.medical_act_id" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :dete_out="dete_out"/>
            <Hojagrafica v-if="subpage == 'hojagrafica'" :medical_act_id="Clinicahistory.medical_act_id" :addSheet="addSheet" @mtdaddsheetexit="mtdaddsheetexit" :dete_out="dete_out" />
            <Notaenfermeria v-if="subpage == 'notaenfermeria'" :medical_act_id="Clinicahistory.medical_act_id" :dete_out="dete_out" />
            <Kardex v-if="subpage == 'kardex'" :medical_act_id="Clinicahistory.medical_act_id" :datapatients="datapatients" :dete_out="dete_out"  />
            <Oxigeno v-if="subpage == 'oxigeno'" :medical_act_id="Clinicahistory.medical_act_id" :datapatients="datapatients" />
            <Bme v-if="subpage == 'bme'" />
            <Mhemodinamico v-if="subpage == 'monitoreodinamico'" />
            <BalanceHidri v-if="subpage == 'balanceHidrico'" :medical_act_id="Clinicahistory.medical_act_id" :datapatients="datapatients"  />
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="H. Hospitalizacion" component="Hchospitalizacion" :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></dropdown>
                <dropdown title="Evoluciones Medicas" component="Evolucionmedica"></dropdown>
                <dropdown title="Hojas Graficas" component="Hojagrafica"></dropdown>
                <dropdown title="Nota de Enfermerias" component="Notaenfermeria"></dropdown>
                <dropdown title="Kardex" component="Kardex"></dropdown>
                <dropdown title="Oxigeno" component="Oxigeno"></dropdown>
                <dropdown title="Bme" component="Bme"></dropdown>
                <dropdown title="Monitoreo Dinamico" component="Mhemodinamico"></dropdown>
                <dropdown title="Rp" component="Rp"></dropdown>
                <dropdown title="Balance Hidrico" component="balanceHidrico"></dropdown>
            </div>
        </div>
        <!--  -->

    </div>
    <div v-if="showHistoryComplet !=1">
        <Tablehistory :resultFilter="resultFilter" @historyPlanPatient="getHistoryPatient" />
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
    <cModaldestino :type_history ="type_history"  :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodal="mtdclosemodal" @mtdstoredata="mtdstoredata" />
    <cModalBed :bed="bed" :title="cModalBed.title" :boo_modal="cModalBed.modal_form" @mtdclosemodalBed="mtdclosemodalBed" @mtdstoredata="mtdstoredata" @mtdaddbed="mtdaddbed" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import HchospitalizacionDesktop from '@/components/Asistencial/Hospitalizacion/Historia/HchospitalizacionDesktop.vue'
//import Hchospitalizacion from '@/components/Asistencial/Hospitalizacion/Historia/Hchospitalizacion.vue'
import Emedica from '@/components/Asistencial/Hospitalizacion/Historia/Emedica.vue'
import Rp from '@/components/Asistencial/Hospitalizacion/Historia/Rp.vue'
import Hojagrafica from '@/components/Asistencial/Hospitalizacion/Historia/Hojagrafica.vue'
import Notaenfermeria from '@/components/Asistencial/Hospitalizacion/Historia/Notaenfermeria.vue'
import Kardex from '@/components/Asistencial/Hospitalizacion/Historia/Kardex.vue'
import Oxigeno from '@/components/Asistencial/Hospitalizacion/Historia/Oxigeno.vue'
import Bme from '@/components/Asistencial/Hospitalizacion/Historia/Bme.vue'
import BalanceHidri from '@/components/Asistencial/Hospitalizacion/Historia/Balance.vue'
import Mhemodinamico from '@/components/Asistencial/Hospitalizacion/Historia/Mhemodinamico.vue'
import Dropdown from '@/components/Asistencial/Hospitalizacion/Dropdown.vue'
import Tablehistory from "@/components/Asistencial/Hospitalizacion/TableHistory.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModaldestino from '@/components/Asistencial/Emergency/modals/cModaldestino.vue';
import cModalBed from '@/components/Asistencial/Emergency/modals/cModalBed.vue'
export default {
    name: 'c-asistencial-hospitalizacion-historia',
    components: {
        HchospitalizacionDesktop,
        //Hchospitalizacion,
        Emedica,
        Rp,
        Hojagrafica,
        Notaenfermeria,
        Kardex,
        Oxigeno,
        Bme,
        Mhemodinamico,
        BalanceHidri,
        Dropdown,
        Tablehistory,
        SweetAlert,
        cModaldestino,
        cModalBed
    },
    data() {
        return {
            subpage: 'Hchospitalizacion',
            swal: null,
            resultFilter: [],
            showHistoryComplet: 0,
            filterHistory: {
                dayPassed: null,
                startDate: null,
                endDate: null,
                year: null,
                name: null,
            },
            stateDisabled: false,
            patient: [],
            datapatients: {
                patient_id: '',
                model_id: '',
                patient_name: '',
                patient_year: ''
            },
            viewinputs: null,
            viewMedico: null,
            Clinicahistory: {},
            origin: true,
            showHistory: false,
            metodoadd: null,
            addEv: null,
            addRp: null,
            addSheet: null,
            modal: {
                title: "",
                modal_form: false,
            },
            type_history: null,
            cModalBed: {
                title: "",
                modal_form: false,
            },
            bed: {
                campu_id: null,
                type: null,
            },
            dete_out: false
        }
    },
    props: {
        stateHistoryShow: {
            type: Number,
        },
        patients: {
            type: Object,
            default: () => ({})
        },
        viewInput: {
            type: Number,
        },
        newhistorial: {
            type: Object,
            default: () => ({})
        },
        newhistorialtable: {
            type: Object,
            default: () => ({})
        }
    },
    created() {
        this.getFirstDayOfMonth();

    },
    watch: {
        newhistorial: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.getHistoryPatient(newValue, 'cola');
                }
            },
            deep: true,
            immediate: true,
        },
        newhistorialtable: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.getHistoryPatient(newValue, 'table');
                }
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.subpage) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.subpage) return 'color:white';
            return '';
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        getHistoryPatient: function (datos, origin) {
            this.dete_out = datos.patient.dete_out ? true : false
            this.origin = (origin == 'cola');
            this.showHistory = (origin == 'table')
            const {
                patient
            } = datos;
            this.changeSubPage('Hchospitalizacion');
            this.showHistoryComplet = 1;
            this.patient = patient;
            this.mtdClear();

        },
        mtdGetDataFilter: function () {
            //Cargar data de acuerdo a lo buscado
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/filter",
                token: this.$store.getters.get__token,
                params: this.filterHistory,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.resultFilter = response.data;
                }
            });
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            this.filterHistory.startDate = firstDayOfMonth.toISOString().split('T')[0];
        },
        mtdClear: function () {
            this.datapatients = {
                patient_id: this.patient.patient_id,
                model_id: this.patient.model_id,
                patient_year: this.patient.patient_year,
                patient_name: this.patient.patient_name,
            }
            this.viewinputs = 0
            this.viewMedico = 0
            this.Clinicahistory = this.patient
        },
        mtdBackPage: function () {

            if (this.stateHistoryShow === 1) {
                this.getFirstDayOfMonth();
                this.showHistoryComplet = 0;
                this.stateDisabled = false;
                this.mtdGetDataFilter();
                this.viewinputs = 1;
            } else {
                this.$emit('changePage', 'cola');
            }

        },
        changePage: function () {
            this.$emit('changePage', 'cola')
        },
        mtdaddhistory: function () {
            this.metodoadd = 1
        },
        mtdaddall: function () {
            const areEqual = JSON.stringify(this.$miVariableGlobalEv) === JSON.stringify(this.$miVariableGlobalEv_plantilla);
            const areEqualrp = JSON.stringify(this.$miVariableGlobalRp) === JSON.stringify(this.$miVariableGlobalRp_plantilla);
            const areEqualsheet = JSON.stringify(this.$miVariableGlobalSheet) === JSON.stringify(this.$miVariableGlobalSheet_plantilla);
            if (!areEqual) {
                this.post({
                        url: this.$store.getters.get__url + "/Hospitalization/storeEv",
                        token: this.$store.getters.get__token,
                        params: this.$miVariableGlobalEv,
                    })
                    .then((response) => {
                        this.addEv = 1
                        if (!areEqualrp) {
                            this.mtdaddrp()
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);
                        }
                        if (!areEqualsheet) {
                            this.mtdaddsheet()
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);
                        }
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            } else {
                if (!areEqualrp) {
                    this.mtdaddrp()
                } else {
                    if (!areEqualsheet) {
                        this.mtdaddsheet()
                    } else {
                        this.$refs.SweetAlert.showWarning("Registre Ev. Medica o Rp");
                    }
                }
            }

        },
        mtdaddexit: function (item, payload) {
            this.metodoadd = null
            if (item == 1) {
                this.origin = false
                this.Clinicahistory = payload
                this.showHistory = true
                this.$emit('validation', {
                    patient: payload
                })
            }
        },
        mtdaddrp: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/storeRp",
                    token: this.$store.getters.get__token,
                    params: this.$miVariableGlobalRp,
                })
                .then((response) => { //   
                    this.addRp = 1
                    this.$refs.SweetAlert.showSuccess(response.message);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdaddsheet: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/storeSheet",
                    token: this.$store.getters.get__token,
                    params: this.$miVariableGlobalSheet,
                })
                .then((response) => {
                    this.addSheet = 1
                    this.$refs.SweetAlert.showSuccess(response.message);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdaddrpexit: function () {
            this.addRp = null
        },
        mtdaddevexit: function () {
            this.addEv = null
        },
        mtdaddsheetexit: function () {
            this.addSheet = null
        },
        openDestino: function () {
            this.modal = {
                title: "DESTINO",
                modal_form: true,
            }
            this.type_history = this.Clinicahistory.type
        },
        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            }
        },
        mtdstoredata:function(payload){
            if(payload == 0){
                let medical_id = this.Clinicahistory.medical_act_id
                this.post({
                    url: this.$store.getters.get__url + "/admision/destination",
                    token: this.$store.getters.get__token,
                    params:{
                        id:medical_id
                    },
                })
                .then((response) => {
                    this.mtdclosemodal()
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.Clinicahistory = {};
                    this.$emit('changePage','cola')
                    this.$emit('vaciarpatient')
                })
                .catch((errors) => {
                    console.log(errors);
                });
            } else if (payload == 1) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 3,
                }
                this.mtdclosemodal()
            } else if (payload == 2) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 4,
                }
                this.mtdclosemodal()
            }else if (payload == 3) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 7,
                }
                this.mtdclosemodal()
            }
            
        },
        mtdclosemodalBed: function () {
            this.cModalBed = {
                title: "",
                modal_form: false,
            }

        },
        mtdaddbed: function (payload, type,subsubcategories) {
            let data = {
                type: type,
                bed_id: payload.id,
                patient_id: this.Clinicahistory.patient_id,
                id: this.Clinicahistory.medical_act_id,
                campu_id : this.$store.getters.get__campus,
                subsubcategorie_id :subsubcategories.id
            }
            this.post({
                    url: this.$store.getters.get__url + "/admision/reservation",
                    token: this.$store.getters.get__token,
                    params: data
                })
                .then((response) => {
                    this.mtdclosemodalBed()
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.Clinicahistory = {};
                    this.$emit('changePage', 'cola')
                    this.$emit('vaciarpatient')
                })
                .catch((errors) => {
                    console.log(errors);
                });
        }
    }
}
</script>
