<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2 mb-2 ">
            <label for="" class="mb-2">Nombre:</label>
            <input class="form-control form-control-sm" v-model="unit.name" placeholder="Nombre">
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2  ">
            <label for="" class="mb-2">Cantidad de Unidades:</label>
            <input type="number" class="form-control form-control-sm" v-model="unit.quantity" placeholder="Unidad" @input="validateInput('quantity')">
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdCommitUnit" :disabled="cpDisabled">
            <i class="fas fa-save" />&nbsp; {{action=='store'?'Registrar':'Actualizar'}}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-modals-unit",
    components: {
        CModal,
    },
    data() {
        return {
            unit: {
                id: "",
                name: "",
                quantity: "",
                state: "",
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        action: String,
        data: {},
        units: Object
    },
    watch: {
        units(newValue, oldValue) {
            this.unit = newValue
        }
    },
    computed: {
        cpDisabled() {
            if (this.unit.name.length > 0 && this.unit.quantity.length > 0) return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdClose() {
            this.unit = {
                id: "",
                name: "",
                quantity: "",
                state: "",
            };

            this.$emit("closeModal");
        },
        mtdclosemodal() {
            this.unit = {
                id: "",
                name: "",
                quantity: "",
                state: "",
            };
            this.$emit('closeModal')
        },

        validateInput(field) {
            if (this.unit[field] < 0) {
                this.unit[field] = 0;
            }
        },

        mtdCommitUnit: function () {
            this.$emit("mtdCommitUnit", this.unit);
            this.unit = {
                id: "",
                name: "",
                quantity: "",
                state: "",
            };
        },
    },
};
</script>

<style scoped>
.inpt_color {
    text-align: center !important;
    background-color: cadetblue !important;
    border-radius: 20px;
    color: white;
}

.inpt_color::placeholder {
    color: white;
}
</style>
