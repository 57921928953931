<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
    class="custom-modal"
  >
    <div class="container">
      <div class="row d-flex">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="document_type">VIA</span>
            <select
              class="form-control form-control-sm"
              v-model="dataRe.via"
            >
              <option value="">Seleccione una opción</option>
              <option value="ORAL">ORAL</option>
              <option value="INTRAVENOSO">INTRAVENOSA</option>
            </select>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="document_type">DOSIS</span>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Dosis"
              v-model="dataRe.dosis"
            />
          </div>
        </div>
      </div>
      <div class="row d-flex">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="document_type">FRECUENCIA</span>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Frecuencia"
              v-model="dataRe.frecuencia"
              step="0.1"
              @input="validateInput('frecuencia')"
            />
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text" id="document_type">DURACIÓN</span>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Duración"
              v-model="dataRe.duracion"
              step="0.1"
              @input="validateInput('duracion')"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <button class="btn btn-success btn-sm" @click="registrarReci">
        <i class="fas fa-save" />&nbsp; Agregar
      </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
export default {
  name: "c-pharmacy-cModalCarroRecipe",
  components: {
    SweetAlert,
    CModal,
  },
  data() {
    return {
      swal: null,
      tabla: [],
      dataRe: {
        via: "",
        dosis: "",
        frecuencia: null,
        duracion: null,
      },
    };
  },
  created() {},
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    mtdClose() {
      this.dataRe = {
        via: "",
        dosis: "",
        frecuencia: null,
        duracion: null,
      };
      this.$emit("mtdCloseModalDeta");
    },


    validateInput(field) {
      this.dataRe[field] = this.dataRe[field].replace(/[^0-9]/g, '');
    },

    registrarReci() {
      if (this.dataRe.via.trim() == "" || this.dataRe.dosis.trim() == "") {
        this.$refs.SweetAlert.showWarning("Llene todos los campos");
        return;
      }

      if (
        this.dataRe.frecuencia === null ||
        this.dataRe.duracion === null ||
        this.dataRe.frecuencia === "" ||
        this.dataRe.duracion === "" ||
        this.dataRe.frecuencia <= 0 ||
        this.dataRe.duracion <= 0
      ) {
        this.$refs.SweetAlert.showWarning("La frecuencia y la duración deben ser mayores que 0");
        return;
      }

      const newItem = {
        ...this.item,
        ...this.dataRe,
      };

      this.$emit("registrarReci", newItem);
      this.mtdClose();
    },
  },
};
</script>
<style>
.custom-modal .modal-title {
  font-size: 13px; 
}
</style>
