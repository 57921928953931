<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div>
            <ul class="nav nav-tabs w-100" id="myTab" role="tablist">
                <li v-for="(item, index) in dataArea" :key="index" class="nav-item pt-2 me-1" role="presentation" v-if="item.id==2">
                    <button  @click="mtdSelectTab(item.id)" :class="['item-button', 'nav-link', { active: page === item.id }]" :id="'tab-' + index" type="button">
                        {{ item.name }}
                    </button>
                </li>
            </ul>
            <div class="border border-main" id="">
                <ReqFarmaciaVue v-if="page == 2" :area="2"></ReqFarmaciaVue>
                <!-- <div class="row px-4">
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 py-2 mt-3">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" />
                        </div>
                    </div>

                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 py-2 mt-3">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" />
                        </div>
                    </div>
                    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 py-2 mt-3">
                        <button @click="mtdGetdataIDfilter()" :disabled="validardate" type="button"  name="accion" class=" btn btn-sm mb-3 w-100 btn-register text-white">
                            <i class="fas fa-search"></i> Buscar
                        </button>
                    </div>
                    <div class="  py-2">
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                <thead class="bg-main" style="font-size: 12px">
                                    <tr class="text-white text-center" style="vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col">USUARIO</th>
                                        <th scope="col">FECHA</th>
                                        <th scope="col">ESTADO</th>
                                        <th scope="col" colspan="1">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody v-if=" cpData.length >0" class="border-main" style="font-size: 12px; vertical-align: middle">
                                    <tr v-for="(items, index) in  cpData" :key="index" class="tbody-table1 text-center">
                                        <td>
                                            {{ items }}
                                        </td>
                                        <td>
                                            {{ items.user.name }} {{ items.user.last_name }}
                                        </td>
                                        <td>
                                            {{ items.date }}
                                        </td>
                                        <td>
                                            <span v-if="items.state== 1" class=" fw-bold  rounded-3 px-2 bg-success text-white  py-1"> REGISTRADO </span>
                                            <span v-if="items.state== 2" class=" fw-bold  rounded-3 px-2 bg-primary text-white  py-1"> APROBADO </span>
                                            <span v-if="items.state== 3" class=" fw-bold  rounded-3 px-2 bg-warning text-white  py-1"> TERMINADO</span>
                                        </td>
                                        <td>
                                            <a @click="mtdviewmodal(items)" id="btn-remove" style="font-size: 15px">
                                                <i class="fas fa-eye"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5" class="fw-bold">Sin registros</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-center" style="align-items: center">
                            <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                    </li>
                                    <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{
                          stepPagination.length }}</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <cModalViewRequeimientoDetailsVue :dataRequerimientosDetails="dataRequerimientosDetails" @mtdcloseModal="mtdcloseModal" :title="modalRequerimientos.title" :boo_modal="modalRequerimientos.modal_form" /> -->
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalViewRequeimientoDetailsVue from '../modals/cModalViewRequeimientoDetails.vue';
import ReqFarmaciaVue from './Requeriment/ReqFarmacia.vue';
export default {
    components: {
        cModalViewRequeimientoDetailsVue,
        ReqFarmaciaVue
    },
    data() {
        return {
            page: 2,
            dataArea: [],
            // filter: {
            //     area_filter_id: 0,
            //     begindate: null,
            //     enddate: null,
            //     area_id: null,
            // },
            // posReq: 0,
            // dataRequerimientosID: [],
            // dataRequerimientosDetails: [],
            // modalRequerimientos: {
            //     title: "",
            //     modal_form: false,
            // },
            // totalPagesToShow: 7,
            // page: 0,
            // selectPage: 1,
            // stepPagination: [],
            // excel: []
        }
    },
    computed: {
        validardate() {
            if (this.filter.begindate && this.filter.enddate) {
                return false;
            }
            return true;
        },
        cpData() {
            let filteredData = this.dataRequerimientosID;
            // if (this.search.length >= 2 && this.search != "") {
            //     let query = this.search.toString().toUpperCase();
            //     filteredData = this.dataRequerimientosID.filter((element) => {
            //         let name = element.vaucher_fullname.toString().toUpperCase();
            //         return name.includes(query);
            //     });
            // }

            let totalPages = Math.ceil(filteredData.length / 10);

            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * 10;
            let endIndex = startIndex + 9;
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        this.mtdGetarea();
        // this.mtdGetdataID(1)
    },

    methods: {
        ...mapActions(["get", "post"]),

        mtdGetarea: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Area",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataArea = response.data; 
                })
        },
        returnHome() {
            this.$emit("returnHome");
        },
        mtdSelectTab: function (id) {
            this.page = id;
        },
        // mtdGetdataID: function (pos) {
        //     this.filter.area_filter_id = pos;
        //     this.posReq = pos;
        //     this.post({
        //             url: this.$store.getters.get__url + "/logistics/requerimientos/filter",
        //             token: this.$store.getters.get__token,
        //             params: this.filter,
        //         })
        //         .then((response) => {
        //             this.dataRequerimientosID = response.data;
        //         })
        //         .catch((errors) => {});
        //     let cantidad = parseInt(this.dataRequerimientosID.length / 10);
        //     let residuo = parseInt(this.dataRequerimientosID.length % 10);
        //     cantidad = residuo > 0 ? cantidad + 1 : cantidad;
        //     for (let index = 0; index < cantidad; index++) {
        //         this.stepPagination.push(index + 1);
        //     }

        //     this.filter = {
        //         begindate: null,
        //         enddate: null,
        //     }
        // },
        // mtdGetdataIDfilter: function () {

        //     this.filter.area_id = this.posReq;
        //     this.post({
        //             url: this.$store.getters.get__url + "/logistics/requerimientosdate/filter",
        //             token: this.$store.getters.get__token,
        //             params: this.filter,
        //         })
        //         .then((response) => {
        //             this.dataRequerimientosID = response.data;
    
        //         })
        //         .catch((errors) => {});
        // },
        // mtdviewmodal: function (payload) {
        //     this.modalRequerimientos = {
        //         title: "REQUERIMIENTOS",
        //         modal_form: true,
        //     }
      
        //     this.post({
        //             url: this.$store.getters.get__url + "/logistics/requerimientodetails/filter",
        //             token: this.$store.getters.get__token,
        //             params: payload,
        //         })
        //         .then((response) => {
        //             this.dataRequerimientosDetails = response.data;
       
        //         })
        //         .catch((errors) => {});
        // },
        // mtdcloseModal: function () {
        //     this.modalRequerimientos = {
        //         title: "",
        //         modal_form: false,
        //     }
        // },

        // backPage() {
        //     this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        // },
        // nextPage() {
        //     this.selectPage =
        //         this.selectPage < this.stepPagination.length ?
        //         this.selectPage + 1 :
        //         this.stepPagination.length;
        // },
        // selectedPage(page) {
        //     this.selectPage = page;
        // },

    }
}
</script>

<style>
#btn-remove {
    color: #0763BA;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-remove:hover {
    color: #03488A;
}

.btn-register {
    background: rgb(144, 0, 82) !important;
}

.btn-register:hover {
    background: #740243 !important;
}
</style>
