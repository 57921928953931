<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" 
    :fullScreen="true">
        
       
        <div class="row d-flex mt-2 mb-3" >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  type="button"
                  class="bg-main text-white btn me-2" @click="mtdModalCategory()"
                >
                  <i class="fas fa-plus"></i> Categoría
                </button>
                <button
                  type="button"
                   class="bg-main text-white btn me-2" @click="mtdModalSubCate()"
                >
                  <i class="fas fa-plus"></i> Sub Categoría
                </button>
            </div>
        </div>

        
        <div class="row d-flex">

            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Categoria</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-medkit"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example"  v-model="data_detail.categorie_id" @change="fetchSubcategories">
                        <option disabled selected value="">Seleccione...</option>
                        <option v-for="(item, index) in dataCategories" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">SubCategoria</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-briefcase-medical"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="data_detail.subcategorie_id">
                        <option disabled selected value="">Seleccione...</option>
                        <option v-for="(item, index) in dataSubSub" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Servicio Específico</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-briefcase-medical"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Concentración" v-model="data_detail.name"/>
                </div>
            </div>
        </div>

        <template v-if="data_detail.categorie_id !== 30">
        <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Especialidad</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-medkit"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example"  v-model="data_detail.specialty_id">
                        <option disabled selected value="">Seleccione...</option>
                        <option v-for="(item, index) in dataSpecialtys" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Contrato EPS</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-medkit"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="data_detail.insurance_contrat_id">
                        <option disabled selected value="">Seleccione...</option>
                        <option v-for="(item, index) in dataContracts" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Prec. Kairos</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Kairos" v-model="data_detail.kairos" step="0.1" @input="validateInput('kairos')" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Unidad</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Unidad" v-model="data_detail.unidad" step="0.1" @input="validateInput('unidad')" />
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Prec.Particular</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Particular" v-model="data_detail.particular" step="0.1" @input="validateInput('particular')" />
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
                <div class='me-lg-3' style="margin-right: 20px">
                    <label class="mt-2">&nbsp;</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckEps"  v-model="data_detail.eps"/>
                        <label class="form-check-label" for="flexSwitchCheckEps">EPS</label>
                    </div>
                </div>
                <div >
                    <label class="mt-2">&nbsp;</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckParti" v-model="data_detail.parti"/>
                        <label class="form-check-label" for="flexSwitchCheckParti">PARTICULAR</label>
                    </div>
                </div>
            </div>
        </div>
        </template>

        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
                {{ (num == 0 ) ? 'Registrar':'Editar' }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>


<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-servicioEsp",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            data_detail: {
                categorie_id: '',
                subcategorie_id: '',
                name: '',
                specialty_id: '',
                insurance_contrat_id: '',
                kairos: 0.00,
                unidad: 0,
                particular: 0.00,
                eps: false,
                parti: false,
            },
            dataSubSub: [],
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                        this.data_detail = { ...newVal };
                        this.fetchSubcategoriesEdit();
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataCategories: {
            type: Array,
            default: null
        },
        dataSpecialtys: {
            type: Array,
            default: null
        },
        dataContracts: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
            return (
                this.data_detail.name.trim() == '' ||
                this.data_detail.categorie_id == '' ||
                this.data_detail.categorie_id == null ||
                this.data_detail.subcategorie_id == '' ||
                this.data_detail.subcategorie_id == null
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                categorie_id: '',
                subcategorie_id: '',
                name: '',
                specialty_id: '',
                insurance_contrat_id: '',
                kairos: 0.00,
                unidad: 0,
                particular: 0.00,
                eps: false,
                parti: false,
            };
            this.dataSubSub= [],
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            //this.mtdClose();
        },

        mtdChangeStateEps: function () {
            this.Subsubcategory.eps = this.Subsubcategory.eps == 1 ? 0 : 1;
        },
        mtdChangeStateParti: function () {
            this.Subsubcategory.parti = this.Subsubcategory.parti == 1 ? 0 : 1;
        },
        
        validateInput(field) {
            if (this.data_detail[field] < 0) {
                this.data_detail[field] = 0;
            }
        },

        fetchSubcategoriesEdit() {
            let categoryId = this.data_detail.categorie_id;
            let selectedCategory = this.dataCategories.find(item => item.id === categoryId);
            if (selectedCategory) {
                this.dataSubSub = selectedCategory.subcategorie;
                //this.data_detail.subcategorie_id = '';
            } else {
                this.dataSubSub = [];
                this.data_detail.subcategorie_id = '';
            }
        },

        fetchSubcategories() {
            let categoryId = this.data_detail.categorie_id;
            let selectedCategory = this.dataCategories.find(item => item.id === categoryId);
            if (selectedCategory) {
                this.dataSubSub = selectedCategory.subcategorie;
                this.data_detail.subcategorie_id = '';
            } else {
                this.dataSubSub = [];
                this.data_detail.subcategorie_id = '';
            }
        },

        mtdModalCategory() {
           this.$emit("mtdModalOpenCat");
        },

        mtdModalSubCate() {
           this.$emit("mtdModalOpenSub");
        },
        
    },
    

};
</script>