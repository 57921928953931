<template>
    <div class="container text-center mt-2 mb-3" v-if="stateShow == 1">
        <div id="div-modal-anular" class="d-flex justify-content-center">
            <div class="card w-100">
                <div class="card-body w-100">
                    <div class="row">
                        <!--tabla-->
                        <div class="col-md-12 my-3" id="content-timelinetable">
                            <table class="table table-responsive table-bordered ">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main text-white">
                                    <tr>
                                        <th>N°</th>
                                        <th>HC</th>
                                        <th>FECHA</th>
                                        <th>DNI</th>
                                        <th>PACIENTE</th>
                                        <th>SERVICIO</th>
                                        <th>ACCION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-xs" v-for="(item, index) in dataShowTable" :key="index">
                                        <td class="align-left">{{ index + 1 }}</td>
                                        <td>HC-{{ item.document }}</td>
                                        <td>{{ item.date_consulting }}</td>
                                        <td class="align-left">{{ item.document }}</td>
                                        <td class="align-left">{{ item.patient_name }}</td>
                                        <td class="align-left">{{ item.subsubcategory_name }}</td>
                                        <td class="text-center">
                                            <div class="btn-success p-0">
                                                <button type="button" class="btn text-white btn-sm" @click="mtdGetDataPlanDetail(index)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--PAGINACION-->
                        <div class="d-flex justify-content-center">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPAge">Anterior</a>
                                    </li>
                                    <li class="page-item" v-for="(item, index) in stepPagination" :key="index">
                                        <a :class="selectPage == item ? 'page-link active' : 'page-link'
                                                " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="addPAge">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <!--Fin de la paginación-->
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div v-else-if="stateShow == 0">
        <div class="py-5 d-flex justify-content-center">
            <h3>No se encontraron resultados</h3>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex'
    export default {
        name: 'c-asistencial-ucineo-TableHistory',
        data() {
            return {
                page: 0,
                selectPage: 1,
                stateShow: null, //no se muestre nada al inicio
                stepPagination: [],
                dataShowTable: [],
                dataPlanDetail: [],
                codigoHistory: null,
            }
        },
        created() {
    
        },
        props: {
            resultFilter: {
                type: Array,
            },
        },
        watch: {
            resultFilter() {
                this.cpData();
            },
            selectPage() {
                this.cpData();
            }
    
        },
    
        methods: {
            ...mapActions(['get', 'post']),
    
            /* PAGINACIÓN */
            selectedPage: function (page) {
                this.selectPage = page;
            },
            backPAge: function () {
                this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
            },
            addPAge: function () {
                this.selectPage =
                    this.selectPage == this.stepPagination.length ?
                    this.stepPagination.length :
                    this.selectPage + 1;
            },
            cpData: function () {
                let bo = false;
                let fin = this.selectPage * 10 - 1;
                let ini = fin - 9;
                this.dataShowTable = [];
                this.resultFilter.forEach((element, index) => {
                    if (ini <= index && index <= fin) {
                        this.dataShowTable.push(element);
                    }
                });
                this.stateShow = this.dataShowTable.length == 0 ? 0 : 1;
    
                /** paginación */
                this.stepPagination = [];
                let cantidad =
                    !bo ?
                    parseInt(this.resultFilter.length / 10) :
                    parseInt(this.dataShowTable.length / 10);
                let residuo =
                    !bo ?
                    parseInt(this.resultFilter.length % 10) :
                    parseInt(this.dataShowTable.length % 10);
                cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                for (let index = 0; index < cantidad; index++) {
                    this.stepPagination.push(index + 1);
                }
    
            },
            mtdGetDataPlanDetail: function (pos) {
                this.$emit('historyPlanPatient', {patient: this.dataShowTable[pos]});
            },
    
        }
    }
    </script>
    