<template>
    <div>
        <div class="container">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>HORARIOS</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row mx-0">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                        <div class="input-group input-group">
                                            <span class="input-group-text" id="basic-addon1"
                                            >Campus</span
                                            >
                                            <select
                                            class="form-control form-control-sm"
                                            v-model="filter.campus_id"
                                            @change="mtdGetData()"
                                            >
                                            <option
                                                v-for="(item, index) in dataCampus"
                                                :key="index"
                                                :value="item.id"
                                            >
                                                {{ item.name }}
                                            </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead class="bg-main">
                                        <tr class=" table-th text-white text-center">
                                            <th class="table-th-number">N°</th>
                                            <th>CAMPUS</th>
                                            <th>CATEGORIA</th>
                                            <th>HORARIOS</th>
                                            <th class="table-th-number">ESTADO</th>
                                            <th class="table-th-acciones">EDITAR</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-main">
                                        <tr class="table-body text-center" v-if="cpData.length === 0">
                                            <td colspan="6" class="text-center">No hay registros</td>
                                        </tr>
                                        <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                            <td>
                                                {{index+1}}
                                            </td>
                                            <td class="">
                                                {{ item.campu_name}}
                                            </td>
                                            <td class="">
                                                <div v-if="item.type == 1">
                                                    <div>Consultorios</div>
                                                </div>
                                                <div v-if="item.type == 2">
                                                    <div>Imagenes</div>
                                                </div>
                                                <div v-if="item.type == 3">
                                                    <div>Sala de operaciones</div>
                                                </div>
                                                <div v-if="item.type == 4">
                                                    <div>Laboratorios</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center d-flex justify-content-center">
                                                    <button
                                                        class="btn btn-primary btn-sm custom-btn" style="font-size: 12px; white-space: nowrap;"
                                                        @click="mtdModalVer(item)">
                                                        <i class="fas fa-eye"></i> Ver Horario
                                                    </button>
                                                </div>
                                            </td>
                                            <td>
                                                <div id="state_speciality" v-if="item.state == 1">
                                                    <div class="bg-success text-white">Activo</div>
                                                </div>
                                                <div id="state_speciality" v-else>
                                                    <div class="bg-secondary text-white">Inactivo</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center d-flex justify-content-center">
                                                    <button
                                                        class="btn btn-sm a-success text-white" @click="mtdModalShow(1, item)">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <!-- paginado -->
                                <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
    
                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
        <cModalScheduleVue :dataCampus="dataCampus" :options="options" :horaOptions="horaOptions" 
        :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" 
        @closeModal="closeModal" @mtdGetData="updateGetData" />
        <cModalVerHorarioVue :payload="modalVer.payload" :title="modalVer.title" :boo_modal="modalVer.modal_form" :checkbox="checkbox"
        @closeModalVer="closeModalVer"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalScheduleVue from '../Modals/cModalSchedule.vue';
import cModalVerHorarioVue from '../Modals/cModalVerHorario.vue';
export default {
    name: "c-mantenedores-pages-schedule",
    components: {
        SweetAlert,
        cModalScheduleVue,
        cModalVerHorarioVue
    },
    data() {
        return {
            swal: null,
            search:"",
            dataShow: [],
            dataSchedule: [],
            dataCampus:[],
            estado: "activo",
            schedule: {
                schedule: "",
                id: "",
                state: "",
                type:"",
                campu_id:"",
            },
            filter: {
                campus_id:null
            },
            posEdit:null,
            checkbox:[],
            horaInicio:'',
            horaFin:'',
            rango:'',
            options: [ 
                { value: "15", label: "15" },
                { value: "20", label: "20" },
                { value: "30", label: "30" }
            ],
            horaOptions: [
                { value: "00:00", label: "00:00" },
                { value: "01:00", label: "01:00" },
                { value: "02:00", label: "02:00" },
                { value: "03:00", label: "03:00" },
                { value: "04:00", label: "04:00" },
                { value: "05:00", label: "05:00" },
                { value: "06:00", label: "06:00" },
                { value: "07:00", label: "07:00" },
                { value: "08:00", label: "08:00" },
                { value: "09:00", label: "09:00" },
                { value: "10:00", label: "10:00" },
                { value: "11:00", label: "11:00" },
                { value: "12:00", label: "12:00" },
                { value: "13:00", label: "13:00" },
                { value: "14:00", label: "14:00" },
                { value: "15:00", label: "15:00" },
                { value: "16:00", label: "16:00" },
                { value: "17:00", label: "17:00" },
                { value: "18:00", label: "18:00" },
                { value: "19:00", label: "19:00" },
                { value: "20:00", label: "20:00" },
                { value: "21:00", label: "21:00" },
                { value: "22:00", label: "22:00" },
                { value: "23:00", label: "23:00" },
                { value: "24:00", label: "24:00" },
            ],

            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalVer: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetCampus();
    },
    methods: {
        ...mapActions(["get", "post"]),

        //Traer Data
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Schedule/filter",
                token: this.$store.getters.get__token,
                params: this.filter,
            })
            .then((response) => {
                this.dataSchedule = response.data;
                this.calculateData(7);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdGetCampus: function () {
            this.get({
                url: this.$store.getters.get__url + "/Schedule",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataCampus= response.data;          
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            if (!this.dataSchedule || !this.dataSchedule.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataSchedule;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataSchedule.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        mtdModalShow: function (num, payload) {
            switch (payload.type) {
                case '1':
                    this.modal.title = 'Editar Horarios de Consultorios';
                    break;
                case '2':
                    this.modal.title = 'Editar Horarios de Imagenes';
                    break;
                case '3':
                    this.modal.title = 'Editar Horarios de Sala de operaciones';
                    break;
                case '4':
                    this.modal.title = 'Editar Horarios de Laboratorios';
                    break;
                default:
                    this.modal.title = 'Editar Horarios';
            }
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;
            //console.log(payload);
        },

        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },

        mtdModalVer: function (payload) {
            switch (payload.type) {
                case '1':
                    this.modalVer.title = 'Horarios de Consultorios';
                    break;
                case '2':
                    this.modalVer.title = 'Horarios de Imagenes';
                    break;
                case '3':
                    this.modalVer.title = 'Horarios de Sala de operaciones';
                    break;
                case '4':
                    this.modalVer.title = 'Horarios de Laboratorios';
                    break;
                default:
                    this.modalVer.title = 'Horarios';
            }
            this.modalVer.modal_form = true;
            this.modalVer.payload = payload;
        },

        closeModalVer: function () {
            this.modalVer = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        
        updateGetData: function (campus_id) {
            this.post({
                url: this.$store.getters.get__url + "/Schedule/filter",
                token: this.$store.getters.get__token,
                params: {
                    campus_id: campus_id,
                },
            })
            .then((response) => {
                this.dataSchedule = response.data;
                this.calculateData(7);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function(index){
            return (this.selectPage-1)*10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    }
}
</script>
    