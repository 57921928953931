<template>
  <div>
    <div class="row my-3">

      <div class="col-md-6 align-left">
        <div class="col-md-12 bg-gris-claro">
          <span class="fw-bold text-xs px-3">I. ANTES DE LA INDUCCIÓN DE LA ANESTESIA</span>
        </div>
        <div class="col-md-12 mt-1 align-center">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Con el(la) enfermero(a) y anestesiólogo, como mínimo</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1">Oservaciones</label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Ha confirmado el(la) paciente su identidad, el sitio quirúrgico, el procedimiento y consentimiento?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion1" value="si" v-model="opcionSeleccionada1">
              <label for="opcion1" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion1" value="no" v-model="opcionSeleccionada1">
              <label for="opcion1" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Se ha marcado el sitio quirúrgico?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion2" value="si" v-model="opcionSeleccionada2">
              <label for="opcion2" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion2" value="no" v-model="opcionSeleccionada2">
              <label for="opcion2" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Ha comprobado la disponibilidad de los equipos de anestesia y de la mediación anestésica?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion3" value="si" v-model="opcionSeleccionada3">
              <label for="opcion3" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion3" value="no" v-model="opcionSeleccionada3">
              <label for="opcion3" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Se ha colocado el pulsiómetro al paciente y funciona?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion4" value="si" v-model="opcionSeleccionada4">
              <label for="opcion4" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion4" value="no" v-model="opcionSeleccionada4">
              <label for="opcion4" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿El paciente tiene una alergia conocida?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion5" value="si" v-model="opcionSeleccionada5">
              <label for="opcion5" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion5" value="no" v-model="opcionSeleccionada5">
              <label for="opcion5" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Tiene el(la) paciente la vía aérea difícil / riesgo de aspiración?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion6" value="si" v-model="opcionSeleccionada6">
              <label for="opcion6" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion6" value="no" v-model="opcionSeleccionada6">
              <label for="opcion6" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">Si la respuesta es si, ¿hay instrumental de equipos o ayuda disponible?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion7" value="si" v-model="opcionSeleccionada7">
              <label for="opcion7" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion7" value="no" v-model="opcionSeleccionada7">
              <label for="opcion7" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Tiene el(la) paciente riesgo de hemoragia 500ml (7ml/kg en niños)?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion8" value="si" v-model="opcionSeleccionada8">
              <label for="opcion8" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion8" value="no" v-model="opcionSeleccionada8">
              <label for="opcion8" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">Si la respuesta es si, ¿se ha previsto la disponibilidad de sangre, plasma u otros fluidos y 2 vías de acceso IV/Central?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion9" value="si" v-model="opcionSeleccionada9">
              <label for="opcion9" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion9" value="no" v-model="opcionSeleccionada9">
              <label for="opcion9" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>

        <br>

        <div class="col-md-12 bg-gris-claro align-left">
          <span class="fw-bold text-xs px-3">II. ANTES DE LA INCISIÓN CUTÁNEA</span>
        </div>
        <div class="col-md-12 mt-1 align-center">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Con el(la) enfermero(a),anestesiólogo y cirujano</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1">Oservaciones</label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Ha confirmado el(la) paciente su identidad, el sitio quirúrgico, el procedimiento y consentimiento?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion10" value="si" v-model="opcionSeleccionada10">
              <label for="opcion10" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion10" value="no" v-model="opcionSeleccionada10">
              <label for="opcion10" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">Confirmar la identidad del/la paciente, el procedimiento y el sitio quirúrgico.</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion11" value="si" v-model="opcionSeleccionada11">
              <label for="opcion11" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion11" value="no" v-model="opcionSeleccionada11">
              <label for="opcion11" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">Confirmar si todos los miembros del equipo han cumplido correctamente con el protocolo de asepsia.</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion12" value="si" v-model="opcionSeleccionada12">
              <label for="opcion12" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion12" value="no" v-model="opcionSeleccionada12">
              <label for="opcion12" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Se ha administrado profilaxis con antibióticos en los últimos 50 minutos?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion13" value="si" v-model="opcionSeleccionada13">
              <label for="opcion13" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion13" value="no" v-model="opcionSeleccionada13">
              <label for="opcion13" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Previsión de eventos críticos</label>
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Cirujano revisa:</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1"></label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Cuáles son los pasos críticos o inesperados?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion14" value="si" v-model="opcionSeleccionada14">
              <label for="opcion14" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion14" value="no" v-model="opcionSeleccionada14">
              <label for="opcion14" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Cuánto durará la operación?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion15" value="si" v-model="opcionSeleccionada15">
              <label for="opcion15" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion15" value="no" v-model="opcionSeleccionada15">
              <label for="opcion15" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Cuánto es la pérdida de sangre prevista?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion16" value="si" v-model="opcionSeleccionada16">
              <label for="opcion16" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion16" value="no" v-model="opcionSeleccionada16">
              <label for="opcion16" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Anestesista verifica:</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1"></label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Presenta el paciente algún problema específico?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion17" value="si" v-model="opcionSeleccionada17">
              <label for="opcion17" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion17" value="no" v-model="opcionSeleccionada17">
              <label for="opcion17" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Equipo de enfermería verifica:</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1"></label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Se ha confirmado la esterilidad de ropa, instrumental y equipos (con resultados de indicadores)?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion18" value="si" v-model="opcionSeleccionada18">
              <label for="opcion18" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion18" value="no" v-model="opcionSeleccionada18">
              <label for="opcion18" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Hay dudas o problemas relacionados con ellos?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion19" value="si" v-model="opcionSeleccionada19">
              <label for="opcion19" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion19" value="no" v-model="opcionSeleccionada19">
              <label for="opcion19" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Pueden visualizarse las imágenes diagnosticadas esenciales?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion20" value="si" v-model="opcionSeleccionada20">
              <label for="opcion20" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion20" value="no" v-model="opcionSeleccionada20">
              <label for="opcion20" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>

        <br>

        <div class="col-md-12 bg-gris-claro align-left">
          <span class="fw-bold text-xs px-3">III. ANTES QUE EL PACIENTE SALGA DEL QUIRÓFANO</span>
        </div>
        <div class="col-md-12 mt-2 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">Con el(la) enfermero(a) y anestesiólogo(a) y cirujano</label>
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">El(la) enfermero(a) confirma verbalmente:</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1"></label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">El nombre del procedimiento</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion21" value="si" v-model="opcionSeleccionada21">
              <label for="opcion21" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion21" value="no" v-model="opcionSeleccionada21">
              <label for="opcion21" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">El reencuentro de instrumentos, esponjas y agujas son correctos (lectura en voz alta, incluido el nombre del paciente).</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion22" value="si" v-model="opcionSeleccionada22">
              <label for="opcion22" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion22" value="no" v-model="opcionSeleccionada22">
              <label for="opcion22" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Hay algún problema que resolver relacionados con el instrumental y los equipos?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion23" value="si" v-model="opcionSeleccionada23">
              <label for="opcion23" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion23" value="no" v-model="opcionSeleccionada23">
              <label for="opcion23" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-7 fw-bold text-xs mt-1">El(la) cirujano, anestesiólogo(a) y enfermo(a) revisan:</label>
            <div class="col-md-8 col-lg-5 m-lg-auto d-md-none d-lg-block">
              <label for="estado" class="fw-bold text-xs mt-1"></label>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-1 align-left">
          <div id="div-estado" class="form-group row">
            <label for="estado" class="col-md-12 col-lg-5 fw-bold text-xxs mt-1">¿Cuáles son los aspectos críticos de la recuperación y el tratamiento de este paciente?</label>
            <div class="col-md-4 col-lg-2 m-auto d-flex px-0">
              <input class="custom-radio" type="radio" id="opcion24" value="si" v-model="opcionSeleccionada24">
              <label for="opcion24" class="text-xxs fw-bold">SI</label>
              <input class="custom-radio" type="radio" id="opcion24" value="no" v-model="opcionSeleccionada24">
              <label for="opcion24" class="text-xxs fw-bold">NO</label>
            </div>
            <div class="col-md-8 col-lg-5 m-lg-auto">
                <input type="text" class="form-control form-control-input form-control-sm" id="estado">
            </div>
          </div>
        </div>

        <br>

        <div class="d-md-block d-lg-flex"> 
          <div class="col-md-12 col-lg-6">
            <div class="input-group mb-2 m-auto">
              <div class="input-group-prepend">
                <div class="input-group-text text-xxs fw-bold">MEDICO CIRUJANO</div>
              </div>
              <input type="text" class="form-control form-control-input form-control-sm" id="inlineFormInputGroup">
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="input-group mb-2 m-auto">
              <div class="input-group-prepend">
                <div class="input-group-text text-xxs fw-bold">CMP</div>
              </div>
              <input type="text" class="form-control form-control-input form-control-sm" id="inlineFormInputGroup">
            </div>
          </div>
        </div>
        <div class="d-md-block d-lg-flex"> 
          <div class="col-md-12 col-lg-6">
            <div class="input-group mb-2 m-auto">
              <div class="input-group-prepend">
                <div class="input-group-text text-xxs fw-bold">ANESTESIÓLOGO</div>
              </div>
              <input type="text" class="form-control form-control-input form-control-sm" id="inlineFormInputGroup">
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="input-group mb-2 m-auto">
              <div class="input-group-prepend">
                <div class="input-group-text text-xxs fw-bold">CMP</div>
              </div>
              <input type="text" class="form-control form-control-input form-control-sm" id="inlineFormInputGroup">
            </div>
          </div>
        </div>
        <div class="d-md-block d-lg-flex"> 
          <div class="col-md-12 col-lg-6">
            <div class="input-group mb-2 m-auto">
              <div class="input-group-prepend">
                <div class="input-group-text text-xxs fw-bold">ENFERMERA</div>
              </div>
              <input type="text" class="form-control form-control-input form-control-sm" id="inlineFormInputGroup">
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="input-group mb-2 m-auto">
              <div class="input-group-prepend">
                <div class="input-group-text text-xxs fw-bold">CEP</div>
              </div>
              <input type="text" class="form-control form-control-input form-control-sm" id="inlineFormInputGroup">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
export default{
    name: 'c-asistencial-sop-historia-listaverificacion',
    data() {
        return {
            opcionSeleccionada1: '',
            opcionSeleccionada2: '',
            opcionSeleccionada3: '',
            opcionSeleccionada4: '',
            opcionSeleccionada5: '',
            opcionSeleccionada6: '',
            opcionSeleccionada7: '',
            opcionSeleccionada8: '',
            opcionSeleccionada9: '',
            opcionSeleccionada10: '',
            opcionSeleccionada11: '',
            opcionSeleccionada12: '',
            opcionSeleccionada13: '',
            opcionSeleccionada14: '',
            opcionSeleccionada15: '',
            opcionSeleccionada16: '',
            opcionSeleccionada17: '',
            opcionSeleccionada18: '',
            opcionSeleccionada19: '',
            opcionSeleccionada20: '',
            opcionSeleccionada21: '',
            opcionSeleccionada22: '',
            opcionSeleccionada23: '',
            opcionSeleccionada24: '',
            opcionSeleccionada25: '',
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>