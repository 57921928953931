<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center pb-5">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>CAMAS</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex">
                        <!-- lista de sedes -->
                        <div class="col-md-12 col-lg-12 col-xs-12 mb-3 text-center">
                            <div class="d-flex justify-content-center mb-2">
                                <div class="form-inline-bed">
                                    <div class="form-check form-check-inline" v-for="(campu, index) in dataCampus" :key="index">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="campu.id" v-model="filter.campus_id" @change="mtdSelectFloors">
                                        <label class="form-check-label" for="inlineRadio1">{{ campu.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- lista de pisos -->
                        <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
                            <ul class="nav pb-0 mt-3" id="myTab" role="tablist">
                                <li v-for="(item, index) in dataFloor" :key="index" class="nav-item-floor" role="presentation">
                                    <div class="btn-group">
                                        <button @click="changeFloor(item.id)" :class="{
                          'item-button-floor': true,
                          'nav-link': true,
                          active: filter.floor_id === item.id,
                        }" id="home-tab" type="button">
                                            {{ item.name }}
                                        </button>
                                        <button type="button" id="toggleButton" :class="{
                          'btn dropdown-toggle dropdown-toggle-split text-white': true,
                          active: filter.floor_id === item.id,
                        }" data-bs-toggle="dropdown" aria-expanded="false" style="
    padding-bottom: 3px;border-bottom-width: 0px;">
                                            <span class="visually-hidden">Toggle Dropdown</span>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a @click="mtdModalShowFloor(item,'edit')" class="dropdown-item" href="javascript:void(0)">Editar</a>
                                            </li>
                                            <li>
                                                <a @click="mtdDeleteFloor(index)" class="dropdown-item" href="javascript:void(0)">Eliminar</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <!-- add floor or beds -->
                                <li class="nav-item-floor" role="presentation">
                                    <button :class="{
                        'item-button1': true,
                        'nav-link': true,
                      }" id="home-tab" type="button" @click="mtdOpenModal">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-12 mt-2" v-if="dataBedGroups.length > 0">
                            <div class="mt-4 px-4">
                                <h4>Distribucion de habitaciones</h4>
                            </div>
                            <div class="row m-2 p-2 d-flex justify-content-center border border-main">
                                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 card card-groupbed m-2 p-4" v-for="(itemg, index) in dataBedGroups" :key="index" :style="'max-height: ' +
                    180 * itemg.beds.length +
                    'px'
                    ">
                                    <div v-for="(itemd, indexb) in itemg.beds" :key="indexb">
                                        <div class="row d-flex g-0 align-items-center">
                                            <div class="col-md-7 col-sm-6">
                                                <img class="img-fluid rounded-start" src="../../../assets/cama_sin_fondo.png" alt="" @click="mtdGetDataBedID(itemd.id, 'edit')" />
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-body px-1">
                                                    <div @click="mtdDelGroup(itemg)">
                                                        <!-- v-if="itemd.status == 1" -->
                                                        <span id="cama" class="card-text h6 circle p-2 text-white" style="background: #39b54a">{{ itemd.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-12 mt-2" v-if="bedNoAgrup.length > 0">
                            <div class="mt-4 px-4">
                                <h4>Camas sin agrupar</h4>
                            </div>
                            <div class="row m-2 p-2 d-flex justify-content-center border border-main">
                                <br />
                                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 card card-groupbed m-2 p-4" v-for="(itemg, index) in bedNoAgrup" :key="index" :style="
                      'max-height: ' +
                      180 +
                      'px'
                    ">
                                    <div class="row d-flex g-0 align-items-center">
                                        <div class="col-md-6">
                                            <img class="img-fluid rounded-start" src="../../../assets/cama_sin_fondo.png" alt="" />
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card-body px-1">
                                                <div @click="mtdAddGroup(itemg)">
                                                    <span id="cama" class="card-text h6 circle p-2 text-white" style="background: #39b54a">{{ itemg.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" v-if="bedNoAgrup.length == 0 && dataBedGroups.length == 0">
                            <div class="row m-2 p-2 d-flex justify-content-center border border-main">
                                <h6>No hay habitaciones en este piso</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert"/>
        <cModalNewFloorBedVue :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodal="mtdclosemodal" @mtdstoredata="mtdstoredata"></cModalNewFloorBedVue>
        <cModalFlooreVue :title="modalFloor.title" :boo_modal="modalFloor.modal_form" :action="modalFloor.action" :dataCampu="dataCampus" :payload="modalFloor.payload" @mtdHideModalFloor="mtdHideModalFloor" @mtdCommitFloor="mtdCommitFloor"></cModalFlooreVue>
        <cModalBedVue :title="modalBed.title" :boo_modal="modalBed.modal_form" :action="modalBed.action" @mtdHideModalBed="mtdHideModalBed" :payload="modalBed.payload" :dataCampu="dataCampus" @mtdCommitBed="mtdCommitBed"></cModalBedVue>
        <cModalBedGroupVue :title="modalGroup.title" :boo_modal="modalGroup.modal_form" :action="modalGroup.action" :nameBedsGroup="modalGroup.nameBedsGroup" :messageGroup="modalGroup.messageGroup" :bedNoAgrup="modalGroup.ungroupedBeds" :item="modalGroup.item" @mtdHodeModalAddGroup="mtdHodeModalAddGroup"></cModalBedGroupVue>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalNewFloorBedVue from "@/components/Maintenance/Modals/cModalNewFloorBed.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalFlooreVue from "@/components/Maintenance/Modals/cModalFloor.vue";
import cModalBedVue from "@/components/Maintenance/Modals/cModalBed.vue";
import cModalBedGroupVue from "@/components/Maintenance/Modals/cModalBedGroup.vue";

//import cModalAlmacenesVue from '../Modals/cModalAlmacenes.vue';
export default {
    name: "c-mantenedores-pages-bed",
    components: {
        SweetAlert,
        cModalNewFloorBedVue,
        cModalFlooreVue,
        cModalBedVue,
        cModalBedGroupVue
    },
    data() {
        return {
            swal: null,
            search: "",
            dataCampus: [],
            dataFloor: [],
            dataBedGroups: [],
            bedNoAgrup: [],
            filter: {
                floor_id: null,
                campus_id: null
            },
            estado: "activo",
            posEdit: null,
            message: false,
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalBed: {
                title: '',
                modal_form: false,
                action: "",
                payload: {},
            },
            modalFloor: {
                title: '',
                modal_form: false,
                action: "",
                payload: {},
            },
            modalGroup: {
                title: "",
                modal_form: false,
                action: "",
                messageGroup: null,
                dataGroupBed: [],
                nameBedsGroup: [],
                ungroupedBeds: [],
                item: {},
            },
        };
    },
    computed: {

    },
    created() {
        this.mtdGetCampus();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetCampus: function (pos) {
            this.get({
                    url: this.$store.getters.get__url + "/Bed/campus",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataCampus = response.data;
                    if (this.dataCampus.length > 0) {
                        this.filter.campus_id = this.dataCampus[0].id;
                        if (pos !== 0) {
                            this.mtdSelectFloors();
                        }
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdSelectFloors: function () {
            this.dataFloor = [];
            this.dataBedGroups = [];
            this.bedNoAgrup = [];
            this.dataCampus.forEach(element => {
                if (element.id == this.filter.campus_id) {
                    this.dataFloor = element.floor;
                }
            });
            if (this.dataFloor.length > 0) {
                this.filter.floor_id = this.dataFloor[0].id;
                this.mtdGetData();
            }

        },

        changeFloor: function (floor_id) {
            this.filter.floor_id = floor_id;
            this.mtdGetData();
        },

        //Traer Data de camas
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Bed/filterBedbyFloor",
                    token: this.$store.getters.get__token,
                    params: this.filter
                })
                .then((response) => {
                    this.dataBedGroups = response.data.groups;
                    this.bedNoAgrup = response.data.beds;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdOpenModal: function () {
            this.modal = {
                modal_form: true,
            };
        },
        mtdclosemodal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        mtdstoredata: function (type) {
            if (type == 'bed') {
                this.modalBed = {
                    title: 'REGISTRAR CAMAS',
                    modal_form: true,
                    action: 'store',
                    payload: {},
                }
            } else {
                this.modalFloor = {
                    title: 'REGISTRAR PISO',
                    modal_form: true,
                    action: "store",
                    payload: {},
                };
            }
        },
        mtdModalShowFloor: function (item) {
            this.modalFloor = {
                title: 'EDITAR PISO',
                modal_form: true,
                action: "edit",
                payload: item,
            }
        },
        mtdHideModalFloor: function () {
            this.modalFloor = {
                title: "",
                modal_form: false,
                action: "",
                payload: {},
            };
        },
        mtdHideModalBed: function () {
            this.modalBed = {
                title: '',
                modal_form: false,
                action: '',
                payload: {},
            }
        },
        mtdCommitFloor: function () {
            this.mtdHideModalFloor();
            this.mtdGetCampus();
        },
        mtdCommitBed: function (floor_id) {
            this.mtdHideModalBed();
            this.mtdGetCampus(0);

            this.changeFloor(floor_id);
        },
        mtdDeleteFloor: function (index) {
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
                .then((result) => {
                    if (result.value) {
                        this.mtdDelete(index);
                    }
                });

        },
        mtdDelete: function (index) {
            this.post({
                    url: this.$store.getters.get__url + "/floor/destroy",
                    token: this.$store.getters.get__token,
                    params: this.dataFloor[index],
                })
                .then((response) => {
                    // Si la eliminación fue exitosa, mostrar una alerta de éxito.
                    this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                    this.mtdGetCampus();
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(error);
                });
        },
        /** grupos */
        mtdAddGroup: function (item) {
            this.modalGroup.title = "Agrupar Cama ".concat(item.name);
            let camasSinAgrupar = this.bedNoAgrup.length;
            camasSinAgrupar == 0 ?
                (this.modalGroup.messageGroup = true) :
                (this.modalGroup.messageGroup = false);
            this.mtdGetDataGroup();
            this.bedNoAgrupforModal(item.id);
            this.modalGroup.action = "store";
            this.modalGroup.modal_form = true;
            this.modalGroup.item = item;
        },
        mtdHodeModalAddGroup: function (floor_id) {
            this.modalGroup = {
                title: "",
                modal_form: false,
                action: "",
                messageGroup: null,
                dataGroupBed: [],
                nameBedsGroup: [],
            };
            if(floor_id > 0 ){
                this.mtdGetCampus(0);
                this.changeFloor(floor_id);
            }
        },
        mtdGetDataGroup: function () {
            this.post({
                    url: this.$store.getters.get__url + "/BedGroup/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.modalGroup.dataGroupBed = response.data.data;
                    this.modalGroup.nameBedsGroup = response.data.grupoConNombresBed;
                })
                .catch((errors) => {});
        },
        bedNoAgrupforModal: function (bed_id) {
            let temp = [];
            this.bedNoAgrup.forEach((element) => {
                if (element.id != bed_id) {
                    temp.push(element);
                }
            });
            this.modalGroup.ungroupedBeds = temp;
        },
        /** borrar agrupación */
        mtdDelGroup: function (item) {
            Swal.fire({
                title: "¿Desea eliminar esta cama de la agrupación?",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.post({
                            url: this.$store.getters.get__url + "/BedGroup/destroy",
                            token: this.$store.getters.get__token,
                            params: item,
                        })
                        .then((response) => {
                            if (response.statusCode == 200) {
                                this.$refs.SweetAlert.showSuccess("Eliminado correctamente");
                                this.changeFloor(item.floor_id);
                            };
                        })
                        .catch((errors) => {});
                }
            });
        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },

        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action: 'd',
                    id: payload_detail
                }

                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            this.addDatadetail(data, num_detail);
                        }
                    });
            }
        },

        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Almacen/crudAlmacen",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (num_detail == 2) {
                        this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        this.closeModal()
                        this.mtdGetData();
                    }
                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    }
}
</script>
