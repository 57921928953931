<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12">
            <!--Sede-->
            <div class="input-group input-group-sm mb-3">
                <div class="input-group-text col-md-3">
                    <span style="border: none" class="input-group-text"><i class="fa fa-hospital"></i></span> Sede
                </div>
                <select class="form-control form-control-sm" aria-label="Default select example" v-model="data_detail.campu_id" :disabled="action == 'store'?false:true">
                    <option selected disabled value="">Seleccione ...</option>
                    <option v-for="(option, index) in dataCampu" :key="index" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="input-group input-group-sm">
                <div class="input-group-text col-md-3">
                    <span style="border: none" class="input-group-text"><i class="fas fa-layer-group"></i></span> Piso
                </div>
                <input type="text" class="form-control form-control-sm" name="correo" v-model="data_detail.name" placeholder="Nombre de Piso" />
            </div>
        </div>

        <!-- <div class="col-md-12 mt-3 pt-2" v-if="action != 'store'">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            :checked="Floor.state == 1 ? true : false"
            @change="mtdChangeState"
          />
          <label class="form-check-label" for="flexSwitchCheckChecked">{{
            Floor.state == 1 ? "Activo" : "Inactivo"
          }}</label>
        </div>
        <div
          v-if="Floor.state == 0"
          class="alert alert-secondary mt-3"
          role="alert"
        >
          ¡Se cambiaran los estados de las camas tambien!
        </div>
      </div> -->
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button @click="mtdInsertDataFloor" color="danger" class="btn btn-success btn-sm">
            <i :class="action == 'store' ? 'fas fa-save' : 'fas fa-edit'" />&nbsp;{{
          action == "store" ? "Registrar" : "Editar"
        }}
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
        CButtonClose,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            data_detail: {
                campu_id: "",
                name: "",
                state: 1,
            }
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        action: String,
        dataCampu: {
            type: Array,
            default: [],
        },
        payload: {}
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.action == 'edit') {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        btnClasses() {
            return [`btn btn-${this.color || "primary"}`];
        },
        cpButtonFormFloor: function () {
            if (this.data_detail.name.length > 0 && this.data_detail.campu_id != "") return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
            this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdInsertDataFloor() {
            if (this.action == "store") {
                this.post({
                        url: this.$store.getters.get__url + "/floor/store",
                        token: this.$store.getters.get__token,
                        params: this.data_detail,
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            this.$refs.SweetAlert.showSuccess("Piso registrado correctamente");
                            this.$emit("mtdCommitFloor")
                        } else {
                            this.$refs.SweetAlert.showError(errors);
                        }
                    })
                    .catch((errors) => {});
            } else {
                this.mtdModifyData();
            }
        },
        mtdModifyData: function () {
            console.log(this.data_detail);
            this.post({
                    url: this.$store.getters.get__url + "/floor/update",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.data_detail = {
                            campu_id: "",
                            name: "",
                        };
                        this.$emit("mtdCommitFloor")
                    } else {
                        this.$refs.SweetAlert.showError(errors);
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdClose() {
            this.data_detail = {
                campu_id: "",
                name: "",
            };
            this.$emit("mtdHideModalFloor");
        },
    },
};
</script>
