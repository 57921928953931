import { render, staticRenderFns } from "./Recipes.vue?vue&type=template&id=77c0e4ca&scoped=true"
import script from "./Recipes.vue?vue&type=script&lang=js"
export * from "./Recipes.vue?vue&type=script&lang=js"
import style0 from "./Recipes.vue?vue&type=style&index=0&id=77c0e4ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c0e4ca",
  null
  
)

export default component.exports