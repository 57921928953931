<template>
<div>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100 mt-2 pb-5">
            <ul class="nav pb-0" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button @click="changePage('oc')" :class="{
                  'item-button': true,
                  'nav-link': true,
                  active: page === 'oc',
                }" id="home-tab" type="button">
                        Facturas OC
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button @click="changePage('os')" :class="{
                  'item-button': true,
                  'nav-link': true,
                  active: page === 'os',
                }" id="profile-tab" type="button">
                        Facturas OS
                    </button>
                </li>
            </ul>
            <div class="border border-main mb-4">
                <PagoOc v-if="page == 'oc' " :type="page" />
                <PagoOs v-if="page == 'os' " :type="page" />
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import PagoOc from "@/components/Tesoreria/pages/PagosFacturas/Oc.vue";
import PagoOs from "@/components/Tesoreria/pages/PagosFacturas/Os.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    data() {
        return {
            swal: null,
            page: "oc",
        };
    },
    components: {
        PagoOc,
        PagoOs,
        SweetAlert,
    },
    methods: {
        ...mapActions(["get", "post"]),
        changePage: function (page) {

            this.page = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    },
};
</script>
<style scoped>
.h4-borde {
    border-radius: 50px;
}

.item-button {
    background: #a9a9a9 !important;
    border-radius: 10px 10px 0px 0px;
    /* margin-right: 5px !important;*/
    border: none !important;
    color: white !important;
    width: 180px;
}

.item-button1 {
    background: #a9a9a9 !important;
    border-radius: 10px 10px 0px 0px;
    /* margin-right: 5px !important;*/
    border: none !important;
    color: white !important;
    width: 160px !important;
}

.nav-link:hover {
    background: #900052 !important;
}

.active {
    background-color: #900052 !important;
}

.nav-item {
    width: 185px;
}
</style>