<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row mx-0  px-2">
            <div class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata()" type="button" class="btn w-100 fw-bold">
                     FUGA
                </button>
            </div>
            <div class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata(0)" type="button" class="btn w-100 fw-bold">
                     ALTA
                </button>
            </div><div class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata()" type="button" class="btn w-100 fw-bold">
                     HOSPITALIZACION
                </button>
            </div><div class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata()" type="button" class="btn w-100 fw-bold">
                     SOP
                </button>
            </div>
        </div>
    
    </CModal>
    </template>
    
    <script>
    import CModal from "@/components/shared/modals/CModal.vue";
    
    import {
        mapActions
    } from "vuex";
    
    export default {
        name: "c-asistencial-hospitalizacion-modals-cModaldestino",
        components: {
            CModal,
        },
        data() {
            return {
    
            };
        },
        props: {
            title: String,
            boo_modal: Boolean,
    
        },
        methods: {
            ...mapActions(["get", "post"]),
            mtdclosemodal() {
                this.$emit('mtdclosemodal')
            },
            mtdstoredata( payload){
                this.$emit('mtdstoredata',payload)
            }
        },
    };
    </script>
    