<template>
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex p-2">
        <div class="col-md-12">
            <div class="p-4 text-center border border-1 shadow">
                <div id="chart">
                    <apexchart ref="apexchart1" type="line" height="320" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
    </template>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";
import VueApexCharts from 'vue-apexcharts';

export default {
    name: "c-jefatura-reporteExpense",
    components: {
        CModal,
        apexchart: VueApexCharts,
    },
    data() {
        return {
            data: [],
            //grafico
            series: [{
                    name: "High - 2013",
                    data: []
                },
                {
                    name: "Low - 2013",
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#7892CA', '#FFAF6E'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Gráfico Egresos Mes Anterior vs Mes actual',
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['Pasajes', 'Egresos', 'Retiros Caja Fuerte', 'RH'],
                    title: {
                        text: 'Tipo'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Monto S/.'
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "S/. " + val + " Soles"
                        }
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        box_id: Number,

    },
    computed: {

    },
    watch: {
        title: function (newData) {
            if (this.boo_modal == true) {
                this.mtdGetData();
            }
        }
    },
    created() {},
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            if (this.box_id !== 0) {
                this.get({
                        url: this.$store.getters.get__url + "/ReportesCajas/reportExpenseMonth/" + this.box_id,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.data = response.data;
                        this.updateChartData();

                    })
                    .catch((errors) => {});
            }
        },
        updateChartData() {
            const methods = ['passage', 'expense', 'retiro', 'receipt'];
            let array = [];
            let array1 = [];
            //verificar si vienen datos
            if (this.data[1].expenses.length == 0) {
              array = [0,0,0,0];
            }else{
              methods.forEach(element => {
                let obj = this.data[1].expenses.find(item => item.modulo === element);
                if(obj){
                  array.push(obj.total);
                }else{
                  array.push(0);
                }
              });
            }
            this.data[0].expenses.forEach(element => {
                array1.push(element.total);
            });
            this.series = [{
                    name: this.data[0].title,
                    data: array
                },
                {
                    name: this.data[1].title,
                    data: array1
                }
            ];
            this.$nextTick(() => {
                if (this.$refs.apexChart1) {
                    this.$refs.apexChart1.updateSeries(this.series);
                }
            });
        },
        mtdclosemodal() {
            this.$emit('mtdclosemodal')
        },
        mtdstoredata() {
            this.$emit('mtdstoredata')
        }
    },
};
</script>
