<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
    <div class="dropdown-header" @click="toggleDropdown()">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='Bme'">
                <Bme></Bme>
            </div>
            <div id="myTabContentHijo" v-if="component=='Evolucionmedica'">
                <Emedica></Emedica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Hchospitalizacion'">
                <Hchospitalizacion :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></Hchospitalizacion>
            </div>
            <div id="myTabContentHijo" v-if="component=='Hojagrafica'">
                <Hojagrafica></Hojagrafica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Notadeenfermeria'">
                <Notaenfermeria></Notaenfermeria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Kardex'">
                <Kardex></Kardex>
            </div>
            <div id="myTabContentHijo" v-if="component=='Oxigeno'">
                <Oxigeno></Oxigeno>
            </div>
            <div id="myTabContentHijo" v-if="component=='Mhemodinamico'">
                <Mhemodinamico></Mhemodinamico>
            </div>
            <div id="myTabContentHijo" v-if="component=='Rp'">
                <Rp></Rp>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import Bme from '@/components/Asistencial/UciAdult/Historia/Bme.vue';
import Emedica from '@/components/Asistencial/UciAdult/Historia/Emedica.vue';
import Hchospitalizacion from '@/components/Asistencial/UciAdult/Historia/Hchospitalizacion.vue';
import Hojagrafica from '@/components/Asistencial/UciAdult/Historia/Hojagrafica.vue';
import Notaenfermeria from '@/components/Asistencial/UciAdult/Historia/Notaenfermeria.vue'
import Kardex from '@/components/Asistencial/UciAdult/Historia/Kardex.vue'
import Oxigeno from '@/components/Asistencial/UciAdult/Historia/Oxigeno.vue'
import Mhemodinamico from '@/components/Asistencial/UciAdult/Historia/Mhemodinamico.vue'
import Rp from '@/components/Asistencial/UciAdult/Historia/Rp.vue'
export default {
    name: 'dropdown-component-uciadulto',
    props: {
        title: String,
        component: String,
        viewMedico: Number,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory:{
          type: Object,
            default: () => ({})
        }
    },
    components: {
        Hchospitalizacion,
        Emedica,
        Rp,
        Hojagrafica,
        Notaenfermeria,
        Kardex,
        Oxigeno,
        Bme,
        Mhemodinamico

    },
    data: () => ({
        dropdown: false
    }),
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },
    }
}
</script>
