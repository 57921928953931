<template>
<div class="row px-4">
    <div class="py-2">

        <div class="d-flex mb-3 mt-2 btn-home">
            <h6 class="mt-2 me-2 "><i class="fas fa-exclamation-circle"></i> REQUERIMIENTOS </h6>
            <button v-if="ordenCompras.length > 0" type="button" @click="mtdviewcarrito()" class="border-0 btn rounded-2 bg-main btn-ver text-white btn-sm">
                <i class="fas fa-eye"></i> Ver Carrito
            </button>
        </div>

        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                <thead class="" style="font-size: 12px">
                    <tr class="text-center bg-main text-white" style="vertical-align: middle">
                        <th scope="col">N°</th>
                        <th scope="col">USUARIO</th>
                        <th scope="col">FECHA</th>
                        <th scope="col">MOLECULA</th>
                        <th scope="col">MARCA</th>
                        <th scope="col">CANTIDAD</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col" colspan="1">ACCIONES</th>
                    </tr>
                </thead>
                <tbody v-if="data.length > 0" class="border-main" style="font-size: 12px; vertical-align: middle">
                    <tr v-for="(items, index) in data" :key="index" class="tbody-table1 text-center">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>{{ items.requirement.user.name }}</td>
                        <td>
                            {{ items.requirement.date }}
                        </td>
                        <td>
                            {{ items.productdetail.molecule }}
                        </td>
                        <td>
                            {{ items.productdetail.brand}}
                        </td>
                        <td>
                            {{ items.quantity }}
                        </td>
                        <td>
                            <span v-if="items.state == 1" class="fw-bold rounded-3 px-2 bg-success text-white py-1">
                                REGISTRADO
                            </span>
                            <span v-if="items.state == 2" class="fw-bold rounded-3 px-2 bg-primary text-white py-1">
                                APROBADO
                            </span>
                            <span v-if="items.state == 3" class="fw-bold rounded-3 px-2 bg-warning text-white py-1">
                                TERMINADO</span>
                        </td>
                        <td>
                            <a @click="mtdviewmodal(items)" id="btn-remove" style="font-size: 15px">
                                <i class="fas fa-eye"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8" class="fw-bold">Sin registros</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-center" style="align-items: center">
            <nav v-if="data.length > 0" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                    </li>
                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                    </li>
                    <li v-if="
                    limitedStepPagination[limitedStepPagination.length - 1] <
                    stepPagination.length - 1
                  " class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-if="
                    limitedStepPagination[limitedStepPagination.length - 1] !==
                    stepPagination.length
                  " class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <cModalViewRequeimientoDetailsVue :numcarrito="numcarrito" @registro-exitoso="manejarRegistroExitoso" @mtdAddItemCarrito="mtdAddItemCarrito" :carrito="carritoOc" :dataRequerimientosDetails="dataRequerimientosDetails" @mtdcloseModal="mtdcloseModal" :title="modalRequerimientos.title" :boo_modal="modalRequerimientos.modal_form" :view="modalRequerimientos.view" :medicaments="modalRequerimientos.medicaments" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalViewRequeimientoDetailsVue from "@/components/Logistica/modals/cModalViewRequeimientoDetails.vue";

export default {
    components: {
        cModalViewRequeimientoDetailsVue
    },
    props: {
        area: 0,
    },
    data() {
        return {
            data: [],
            filter: {
                area_filter_id: 0,
                molecula: "",
                proveedor: "",
                ruc: "",
            },
            data: [],
            ordendecompra: [],
            ordenCompras: [],
            modalRequerimientos: {
                title: "",
                modal_form: false,
                view: "",
                medicaments: []
            },
            carritoOc: [],
            dataRequerimientosDetails: [],
            totalPagesToShow: 7,
            selectPage: 1,
            stepPagination: [],
            cantidad: null,
            numcarrito :null
        };
    },
    created() {
        this.filter.area_filter_id = this.area;

        this.mtdGetdataIDfilter();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetdataIDfilter: function () {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/requerimientos/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(6)
                   
                })
                .catch((errors) => {});
        },
        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        mtdviewmodal: function (payload) {
           
            this.filter.id = payload.productdetail.id;
            this.cantidad = payload.quantity
            this.requerimientodetail_id= payload.id
            this.post({
                    url: this.$store.getters.get__url + "/ordendecompra/detalles/filterid",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.reformatData(response.data);

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        reformatData(data) {
            const reformattedData = [];
            data.forEach((product) => {
                if (product.proveedor) {
                    const newProduct = {
                        id: product.id,
                        molecule: product.molecule,
                        commercial: product.commercial,
                        cargado: product.cargado,
                        brand: product.brand,
                        presentation: product.presentation,
                        concentration: product.concentration,
                        format: product.format,
                        sub_sub_category_id: product.sub_sub_category_id,
                        precio_farmacia_kairos: product.precio_farmacia_kairos,
                        proveedor: {
                            id: product.proveedor.id,
                            name: product.proveedor.name,
                            ruc: product.proveedor.ruc,
                        },
                        precio_purchase: product.proveedor.price_purchase,
                        units: product.units,
                        precio_unitario: 0,
                        utilidad: parseFloat(product.precio_farmacia_kairos - product.proveedor.price_purchase).toFixed(2),
                    };
                    reformattedData.push(newProduct);
                }
                if (product.proveedor_2) {
                    const newProduct = {
                        id: product.id,
                        molecule: product.molecule,
                        commercial: product.commercial,
                        cargado: product.cargado,
                        brand: product.brand,
                        presentation: product.presentation,
                        concentration: product.concentration,
                        format: product.format,
                        sub_sub_category_id: product.sub_sub_category_id,
                        precio_farmacia_kairos: product.precio_farmacia_kairos,
                        proveedor: {
                            id: product.proveedor_2.id,
                            name: product.proveedor_2.name,
                            ruc: product.proveedor_2.ruc,
                        },
                        precio_purchase: product.proveedor_2.price_purchase,
                        precio_unitario: 0,
                        units: product.units,
                        utilidad: parseFloat(product.precio_farmacia_kairos - product.proveedor.price_purchase).toFixed(2),
                    };
                    reformattedData.push(newProduct);
                }
            });
            this.modalRequerimientos = {
                title: "REQUERIMIENTOS",
                modal_form: true,
                view: "req",
                medicaments: reformattedData
            };
            this.filter = {
                area_filter_id: 0,
                molecula: "",
                proveedor: "",
                ruc: "",
            };

        },
        mtdcloseModal: function () {
            this.modalRequerimientos = {
                title: "",
                modal_form: false,
            };
            if(this.carritoOc.length == 0){
                this.numcarrito =null
            }
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdviewcarrito() {
            this.modalRequerimientos = {
                title: "ORDENES DE COMPRA",
                modal_form: true,
            };
        },
        mtdAddItemCarrito: function (payload) {
            this.mtdcloseModal();
            let presenteProvider = false;
            let indexPresenteProvider = null;

            if (this.ordenCompras.length > 0) {
                this.ordenCompras.forEach((element, index) => {
                    if (element.cabecera.id == payload.proveedor.id) {
                        presenteProvider = true;
                        indexPresenteProvider = index;
                    }
                });
            }

            if (presenteProvider) {
                const isItemPresent = this.ordenCompras[
                    indexPresenteProvider
                ].detalle.some((detail) => detail.id === payload.id);
                if (!isItemPresent) {
                    payload.quantity = this.cantidad;
                    payload.price_purchase = payload.precio_unitario;
                    payload.precio_purchase = payload.precio_purchase;
                    payload.precio_unitario = payload.precio_purchase;
                    payload.concentrationedit = '';
                    payload.formatedit = '';
                    payload.grabado = true;
                    payload.cargado_state = payload.cargado;
                    payload.discount = null;
                    payload.price_discount = null;
                    payload.unit = payload.units[0].id;
                    if (payload.cargado === null) {
                        payload.cargado = 1
                    };
                    let ultimoDetalle = payload;
                    this.ordenCompras[indexPresenteProvider].detalle.push(ultimoDetalle);

                } else {
                    Swal.fire({
                        title: "El producto ya está en el carrito",
                        text: "error!",
                        icon: "warning",
                        //showConfirmButton: true,
                        width: "400px",
                        //padding: '50px 0',
                        //timer: 2000
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                }
            } else {
                let cabecera = {
                    id: payload.proveedor.id,
                    name: payload.proveedor.name,
                    total: 0,
                    send: 0,
                    observation: "",
                    type: 1,
                    id_requerimientodetails: this.requerimientodetail_id
                };
                payload.quantity = this.cantidad;
                payload.price_purchase = payload.precio_unitario;
                payload.precio_purchase = payload.precio_purchase
                payload.precio_unitario = payload.precio_purchase;
                payload.concentrationedit = '';
                payload.formatedit = '';
                payload.grabado = true;
                payload.cargado_state = payload.cargado;
                payload.discount = null;
                payload.price_discount = null;
                if (payload.cargado === null) {
                    payload.cargado = 1
                };
                payload.unit = payload.units[0].id;
                let detalle = [payload];
                let nuevaOrdenCompra = {
                    cabecera: cabecera,
                    detalle: detalle,
                };
                this.ordenCompras.push(nuevaOrdenCompra);
            }

            this.carritoOc = this.ordenCompras;
            this.numcarrito =this.ordenCompras.length;
            this.modalRequerimientos = {
                title: "ORDENES DE COMPRA",
                modal_form: true,
            };

        },
        manejarRegistroExitoso() {
            this.ordenCompras = [];
            this.cantidad = null
            this.mtdcloseModal();
            this.mtdGetdataIDfilter()
        }

    },
};
</script>

<style scoped>
.btn-ver:focus {
    background: #900052;
}

.btn-ver:hover {
    background: #bb026c;
}
</style>
