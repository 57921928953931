<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname" />
      </div>
      <div class="content h-100" style="background: white">
        <Home
          v-if="page == 'home'"
          @changePage="changePage"
          @mtdBackCampus="mtdBackCampus"
        />
        <Asignaciones v-if="page == 'asignaciones'" @returnHome="returnHome" />
      </div>
      <div class="footer">
        <FooterVue @changePage="changePage" :pageView="page" />
      </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import Nav from "../../components/Director/Nav.vue";
    import FooterVue from "../../components/Director/Footer.vue";
    import Home from "../../components/Director/Home.vue";
    import Asignaciones from "../../components/Director/pages/Asign.vue";

    export default {
    name: "DirectorView",
    components: {
        Nav,
        FooterVue,
        Home,
        Asignaciones
    },
    data () {
        return {
          prefix: 'director',
          page:'home',
        }
    },
    props: {
        rolname: String,
    },
    created () {
        this.rol_name = this.rolname;
    },
    methods: {
        ...mapActions(['get', 'post']),
        changePage: function (page) {
        this.page = page;
        },
        returnHome: function () {
        this.page = "home";
        },
        mtdBackCampus: function () {
        this.$emit("mtdBackCampus");
        },
        mtdBackRol: function () {
        this.$emit("mtdBackRol");
        },
    }
    
    }
</script>
  