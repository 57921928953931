<template>
    <div>
        <div class="px-3 py-2">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-2 mb-3 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h5 bg-main">
                        <strong>TAREAS ASIGNADAS</strong>
                    </div>
                    <div class="card-body w-100">
                      <div class="row d-flex">
                        <!-- POR HACER -->
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 py-2">
                          <div class="text-center card-header text-white h6 bg-main">
                              <strong>POR HACER</strong>
                          </div>
                          <div class="col-md-12 py-2">
                              <div class="row d-flex mx-0">
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1 mb-2">
                                      <div class="input-group input-group">
                                          <span class="input-group-text text-white bg-main"
                                          ><i class="fas fa-search"></i
                                          ></span>
                                          <input
                                          @keyup="calculateData()"
                                          v-model="search"
                                          type="text"
                                          class="form-control form-control"
                                          placeholder="Buscar Tarea"
                                          />
                                      </div>
                                  </div>
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="d-flex justify-content-center">
                                      <v-select
                                        id="serviceSelect"
                                        class="text-dark flex-grow-1"
                                        label="name"
                                        :options="priorityOptions"
                                        placeholder="Buscar Prioridad"
                                        v-model="selectedPriority"
                                        @input="mtdSearchFilter"
                                      />
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-12" id="content-timelinetable">
                            <div class="scrollable-container">
                              <draggable
                                :group="{ name: 'tasks' }"
                                @end="onDragHacer"
                                class="list-group"
                                id="hacer"
                              >
                                <a
                                  v-for="(item) in dataShow"
                                  :key="item.idTasks"
                                  :class="{'expired-task': item.is_expired === 1, 'selected': selectedTask === item.id }"
                                  @click="openModalHacer(item , item.tasks_adjunct)"
                                  href="#"
                                  class="list-group-item list-group-item-action flex-column align-items-start task-item table-container"
                                >
                                  <div class="d-flex w-100 justify-content-between z">
                                    <h6 class="mb-1 text-title">{{ truncateText(item.nameTasks, 25, '...') }}</h6>
                                    <div>
                                      <span v-if="item.priorityTasks == 1" class="badge bg-danger rounded-pill">ALTA</span>
                                      <span v-else-if="item.priorityTasks == 2" class="badge bg-warning rounded-pill text-black">MEDIA</span>
                                      <span v-else-if="item.priorityTasks == 3" class="badge bg-success rounded-pill">BAJA</span>
                                    </div>
                                  </div>
                                  <h6 class="mb-1" style="font-size: 11px">SUPERVISOR: {{ item.subSupervisor_name ? item.subSupervisor_name : '-' }}</h6>
                                  <p class="mb-1" style="font-size: 11px">FECHA EXPIRACIÓN: {{ item.date_expiration | formatDateTime }}</p>
                                </a>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <!-- EN CURSO -->
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 py-2">
                          <div class="text-center card-header text-white h6 bg-main">
                              <strong>EN PROCESO</strong>
                          </div>
                          <div class="col-md-12 py-2">
                            <div class="row mx-0">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1  mb-2">
                                <div class="input-group input-group">
                                  <span class="input-group-text text-white bg-main">
                                    <i class="fas fa-search"></i>
                                  </span>
                                  <input
                                    @keyup="calculateDataAsign()"
                                    v-model="searchAsign"
                                    type="text"
                                    class="form-control form-control"
                                    placeholder="Buscar Tareas"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12" id="content-timelinetable">
                            <div class="scrollable-container">
                              <draggable
                                :group="{ name: 'tasks' }"
                                @end="onDragPro"
                                class="list-group"
                                 id="curso"
                              >
                                <a
                                  v-for="(item) in dataShowAsign"
                                  :key="item.idTasks"
                                  :class="{'expired-task': item.is_expired === 1, 'selected': selectedTask === item.id }"
                                  @click="openModalPro(item, 3)"
                                  href="#"
                                  class="list-group-item list-group-item-action flex-column align-items-start task-item table-container"
                                >
                                  <div class="d-flex w-100 justify-content-between z">
                                    <h6 class="mb-1 text-title">{{ truncateText(item.nameTasks, 25, '...') }}</h6>
                                    <div>
                                      <span v-if="item.priorityTasks == 1" class="badge bg-danger rounded-pill">ALTA</span>
                                      <span v-else-if="item.priorityTasks == 2" class="badge bg-warning rounded-pill text-black">MEDIA</span>
                                      <span v-else-if="item.priorityTasks == 3" class="badge bg-success rounded-pill">BAJA</span>
                                    </div>
                                  </div>
                                  <h6 class="mb-1"  style="font-size: 11px">SUPERVISOR: {{ item.subSupervisor_name ? item.subSupervisor_name : '-' }}</h6>
                                  <p class="mb-1" style="font-size: 11px">FECHA EXPIRACIÓN: {{ item.date_expiration | formatDateTime }}</p>
                                </a>
                              </draggable>
                            </div>
                          </div>
                        </div>
                        <!-- FINALIZADO -->
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 py-2">
                          <div class="text-center card-header text-white h6 bg-main">
                              <strong>FINALIZADO</strong>
                          </div>
                          <div class="col-md-12 py-2">
                            <div class="row mx-0">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1  mb-2">
                                <div class="input-group input-group">
                                  <span class="input-group-text text-white bg-main">
                                    <i class="fas fa-search"></i>
                                  </span>
                                  <input
                                    @keyup="calculateDataTer()"
                                    v-model="searchTer"
                                    type="text"
                                    class="form-control form-control"
                                    placeholder="Buscar Tareas"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12" id="content-timelinetable">
                           <div class="scrollable-container">
                             <draggable
                                 :group="{ name: 'tasks', pull: false}"
                               @end="onDragTer"
                               class="list-group"
                                id="fin"
                             >
                               <a
                                 v-for="(item) in dataShowTer"
                                 :key="item.idTasks"
                                  :class="{'expired-task': item.is_expired === 1, 'selected': selectedTask === item.id }"
                                 @click="openModalPro(item,  2)"
                                 href="#"
                                 class="list-group-item list-group-item-action flex-column align-items-start task-item table-container"
                               >
                                 <div class="d-flex w-100 justify-content-between z">
                                   <h6 class="mb-1 text-title">{{ truncateText(item.nameTasks, 25, '...') }}</h6>
                                   <div class="d-flex align-items-center">
                                    <i v-if="item.date_approval" class="fas fa-check-circle check-icon me-1 spams"></i>
                                    <span v-if="item.priorityTasks == 1" class="badge bg-danger rounded-pill">ALTA</span>
                                    <span v-else-if="item.priorityTasks == 2" class="badge bg-warning rounded-pill text-black">MEDIA</span>
                                    <span v-else-if="item.priorityTasks == 3" class="badge bg-success rounded-pill">BAJA</span>
                                  </div>
                                 </div>
                                 <h6 class="mb-1"  style="font-size: 11px">SUPERVISOR: {{ item.subSupervisor_name ? item.subSupervisor_name : '-' }}</h6>
                                 <p class="mb-1" style="font-size: 11px">FECHA EXPIRACIÓN: {{ item.date_expiration | formatDateTime }}</p>
                               </a>
                             </draggable>
                           </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalHacerVue :payload="modal.payload" :title="modal.title"  :item="modal.item"
        :boo_modal="modal.modal_form" @closeModalHacer="closeModalHacer" @statusCurso="statusCurso"/>
        <cModalProcesoVue :payload="modalPro.payload" :title="modalPro.title" :item="modalPro.item" :num="modalPro.num"
        :boo_modal="modalPro.modal_form" @closeModalPro="closeModalPro" @registerAdvance="registerAdvance" @statusFin="statusFin"
        @openAd="openAd"/>
  
        <cModalAdFileVue :payload="modalAdFile.payload" :title="modalAdFile.title" :idAd="modalAdFile.idAd" :num="modalAdFile.num"
        :boo_modal="modalAdFile.modal_form" @closeModalAdFile="closeModalAdFile"
        @registerFile="registerFile" @deleteFile="deleteFile"  />
        <cModalAdCommentVue :payload="modalAdComment.payload" :title="modalAdComment.title" :idAd="modalAdComment.idAd" :num="modalAdComment.num"
        :boo_modal="modalAdComment.modal_form" @closeModalAdComment="closeModalAdComment" @registerComen="registerComen" @deleteComen="deleteComen"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
  
<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import cModalHacerVue from './modals/cModalHacer.vue';
  import cModalProcesoVue from './modals/cModalProceso.vue';
  import cModalAdFileVue from './modals/cModalAdFile.vue';
  import cModalAdCommentVue from './modals/cModalAdComment.vue';
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import draggable from 'vuedraggable';
  export default {
    name: "c-pages-tareas",
    components: {
      draggable,
      cModalHacerVue,
      cModalProcesoVue,
      cModalAdFileVue,
      cModalAdCommentVue,
      SweetAlert,
    },
    data() {
        return {
            swal: null,
            subSupervisor: [],
            selectedTask: null,
            selectedPriority: "",
            priorityOptions: [
              'BAJA',
             'MEDIA',
              'ALTA'
            ],
            selectedStatus: "",
            statusOptions: [
              'POR HACER',
             'EN PROCESO',
              'TERMINADO'
            ],
            search:"",
            searchAsign:"",
            searchTer:"",
            dataShow: [],
            dataShowAsign: [],
            dataShowTer: [],
            dataTask: [],
            dataAsign: [],
            dataTer: [],
  
  
            modalAdFile: {
                title: '',
                modal_form: false,
                payload: [],
                idAd: null,
                num: null,
            },
            modalAdComment: {
                title: '',
                modal_form: false,
                payload: [],
                idAd: null,
                num: null,
            },
  
            modalPro: {
                title: '',
                modal_form: false,
                payload: {},
                item: [],
                num: null,
            },
            modal: {
                title: '',
                modal_form: false,
                payload: {},
                item: [],
            },
  
            filter: {
                init: null,
                end: null,
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            //PagProceso
            pageAsign: 0,
            selectPageAsign: 1,
            stepPaginationAsign: [],
            totalPagesToShowAsign: 4,
             //PagTerminada
             pageTer: 0,
            selectPageTer: 1,
            stepPaginationTer: [],
            totalPagesToShowTer: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData();
        },
  
        selectPageAsign() {
            this.calculateDataAsign();
        },
  
        selectPageTer() {
            this.calculateDataTer();
        },
  
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        cpDataAsign() {
            return this.dataShowAsign;
        },
        cpDataTer() {
            return this.dataShowTer;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;
  
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
  
                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;
  
                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }
  
            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        limitedStepPaginationAsign() {
            const totalPages = this.stepPaginationAsign.length;
            const currentPage = this.selectPageAsign;
  
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShowAsign) {
                const halfPagesToShow = Math.floor(this.totalPagesToShowAsign / 2);
  
                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;
  
                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShowAsign + 1;
                    }
                } else {
                    endPage = this.totalPagesToShowAsign;
                }
            }
  
            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        limitedStepPaginationTer() {
            const totalPages = this.stepPaginationTer.length;
            const currentPage = this.selectPageTer;
  
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShowTer) {
                const halfPagesToShow = Math.floor(this.totalPagesToShowTer / 2);
  
                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;
  
                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShowTer + 1;
                    }
                } else {
                    endPage = this.totalPagesToShowTer;
                }
            }
  
            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        moment.locale("es");
        this.filter.end =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
  
        onDragHacer(event) {
          const draggedIndex = event.oldIndex;
          const draggedItem = this.dataShow[draggedIndex];
          const idAsign = draggedItem.idAsign;
          let status;
          if (event.to.id === 'hacer') {
              status = 1;
          } else if (event.to.id === 'curso') {
              status = 2;
          } else if (event.to.id === 'fin') {
              status = 3;
          }
          if(status != 1){
            this.updateStatusJira(idAsign, status);
          }
        },
  
        onDragPro(event) {
          const draggedIndex = event.oldIndex;
          const draggedItem = this.dataShowAsign[draggedIndex];
          const idAsign = draggedItem.idAsign;
          let status;
          if (event.to.id === 'hacer') {
              status = 1;
          } else if (event.to.id === 'curso') {
              status = 2;
          } else if (event.to.id === 'fin') {
              status = 3;
          }
          if(status != 2){
            this.updateStatusJira(idAsign, status);
          }
        },
  
        onDragTer(event) {
          const draggedIndex = event.oldIndex;
          const draggedItem = this.dataShowTer[draggedIndex];
          const idAsign = draggedItem.idAsign;
          let status;
          if (event.to.id === 'hacer') {
              status = 1;
          } else if (event.to.id === 'curso') {
              status = 2;
          } else if (event.to.id === 'fin') {
              status = 3;
          }
          if(status != 3){
            this.updateStatusJira(idAsign, status);
          }
        },
  
        updateStatusJira(idAsign, status) {
          this.post({
                url: this.$store.getters.get__url + "/Assignments/updateStatusJira",
                token: this.$store.getters.get__token,
                params: {
                    idAsign: idAsign,
                    status: status,
                },
            })
            .then((response) => {
              if(response.data.state == 0){
                this.mtdGetData();
              }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        //Traer Data
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Assignments/tasksSupervised",
                token: this.$store.getters.get__token,
                params: {
                    //init: this.filter.init,
                    //end: this.filter.end,
                    user: this.$store.getters.get__user.id,
                },
            })
            .then((response) => {
                this.dataTask = response.data.filter(task => task.status === 1);
                this.dataAsign = response.data.filter(task => task.status === 2);
                this.dataTer = response.data.filter(task => task.status === 3);
                this.calculateData();
                this.calculateDataAsign();
                this.calculateDataTer();
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        mtdSearchFilter() {
            this.calculateData();
        },
  
        mtdSearchFilterAsign() {
            this.calculateDataAsign();
        },
  
        mtdSearchFilterTer() {
            this.calculateDataTer();
        },
  
        calculateData() {
            if (!this.dataTask || !this.dataTask.length) {
                return (this.dataShow = []);
            }
            //let filteredData = this.dataTask;
            this.dataShow = [];
            let filteredData = this.dataTask.slice();
            if (this.search.length >= 3 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                //filteredData = this.dataTask.filter((element) => {
                filteredData = filteredData.filter((element) => {
                    let name = element.nameTasks.toString().toUpperCase();
                    return name.includes(query);
                });
            }
  
            /*Filtro por Prioridad*/
            const priorityMap = {
                'alta': 1,
                'media': 2,
                'baja': 3
            };
            if (this.selectedPriority) {
                const priorityValue = priorityMap[this.selectedPriority.toLowerCase()];
                filteredData = filteredData.filter((element) => {
                    return element.priorityTasks === priorityValue;
                });
            }
            //this.dataShow = filteredData.slice();
            this.dataShow = filteredData;
        },
  
        calculateDataAsign() {
            if (!this.dataAsign || !this.dataAsign.length) {
                return (this.dataShowAsign = []);
            }
            this.dataShowAsign = [];
            let filteredData = this.dataAsign;
            if (this.searchAsign.length >= 3 && this.searchAsign != "") {
                let query = this.searchAsign.toString().toUpperCase();
                filteredData = this.dataAsign.filter((element) => {
                  let name = element.nameTasks.toString().toUpperCase();
                  return name.includes(query);
                });
            }
  
  
            //this.dataShowAsign = filteredData.slice();
            this.dataShowAsign = filteredData;
  
        },
  
        calculateDataTer() {
            if (!this.dataTer || !this.dataTer.length) {
                return (this.dataShowTer = []);
            }
            this.dataShowTer = [];
            let filteredData = this.dataTer;
            if (this.searchTer.length >= 3 && this.searchTer != "") {
                let query = this.searchTer.toString().toUpperCase();
                filteredData = this.dataTer.filter((element) => {
                    let name = element.nameTasks.toString().toUpperCase();
                    return name.includes(query);
                });
            }
  
  
            //this.dataShowTer = filteredData.slice();
            this.dataShowTer = filteredData;
  
        },
  
        //Modales
        openModalHacer: function (payload, item) {
            const capitalizeFirstLetter = (string) => {
              return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
            };
            const truncateString = (string, maxLength) => {
              if (string.length > maxLength) {
                  return string.slice(0, maxLength) + '...';
              }
              return string;
            };
            const maxLength = 17;
            this.selectedTask = payload.id;
            this.modal.title = 'Detalle de Tarea: ' + capitalizeFirstLetter(truncateString(payload.nameTasks, maxLength));
            this.modal.modal_form = true;
            this.modal.payload = payload;
            this.modal.item = item;
        },
  
        closeModalHacer: function () {
            this.selectedTask = null;
            this.modal = {
                title: '',
                modal_form: false,
                payload: {},
                item: [],
            };
        },
  
        statusCurso: function(idAsign) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/updateStatusCurso/" + idAsign,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
              if(response.data.state == 0){
                this.mtdGetData();
                this.closeModalHacer();
              }else{
                this.$refs.SweetAlert.showError("Error");
              }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        openModalPro: function (payload, num) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/openModalPro/" + payload.idAsign,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
              const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
              };
              const truncateString = (string, maxLength) => {
                if (string.length > maxLength) {
                    return string.slice(0, maxLength) + '...';
                }
                return string;
              };
              const maxLength = 17;
              this.selectedTask = payload.id;
              this.modalPro.title = 'Avanzes de Tarea: ' + capitalizeFirstLetter(truncateString(payload.nameTasks, maxLength));
              this.modalPro.payload = payload;
              this.modalPro.item = response.data.assignments_advance;
              this.modalPro.num = num;
              this.modalPro.modal_form = true;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        closeModalPro: function () {
            this.selectedTask = null;
            this.modalPro = {
                title: '',
                modal_form: false,
                payload: {},
                item: [],
            };
        },
  
        registerAdvance: function (payload_detail, payload) {
          let formData = new FormData();
          formData.append("description", payload_detail.description);
          formData.append("obstacles", payload_detail.obstacles);
          formData.append("message", payload_detail.message);
          formData.append("file", payload_detail.file);
          formData.append("idAsig",  payload.idAsign);
          this.post({
              url: this.$store.getters.get__url + "/Assignments/registerAdvance",
              token: this.$store.getters.get__token,
              params: formData,
          })
          .then(response => {
            if(response.data.state == 0){
                this.$refs.SweetAlert.showSuccess("Registrado Correctamente");
                this.openModalPro(payload, 3);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }

          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        statusFin: function(idAsign, num) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/updateStatusFin/" + idAsign +  "/" + num,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
              if(response.data.state == 0){
                this.mtdGetData();
                this.closeModalPro();
              }else{
                this.$refs.SweetAlert.showError("Error");
              }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        //File y Mensajes
        openAd: function (idAd , num, type) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/advanceFileMen/" + idAd,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
              if(type == 0){
                this.modalAdFile.title = 'Archivos';
                this.modalAdFile.payload = response.data.advance_adjunct;
                this.modalAdFile.idAd = idAd;
                this.modalAdFile.num = num;
                this.modalAdFile.modal_form = true;
              }else if(type == 1){
                this.modalAdComment.title = 'Comentarios';
                this.modalAdComment.payload = response.data.advance_comment;
                this.modalAdComment.idAd = idAd;
                this.modalAdComment.num = num;
                this.modalAdComment.modal_form = true;
              }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        closeModalAdFile: function () {
            this.modalAdFile = {
                title: '',
                modal_form: false,
                payload: [],
                idAd: null,
                num: null,
            };
        },
  
        closeModalAdComment: function () {
            this.modalAdComment = {
                title: '',
                modal_form: false,
                payload: [],
                idAd: null,
                num: null,
            };
        },
  
        registerFile: function (payload_detail, idAd) {
          let formData = new FormData();
          formData.append("file", payload_detail.file);
          formData.append("idAd", idAd);
          this.post({
              url: this.$store.getters.get__url + "/Assignments/registerFileAd",
              token: this.$store.getters.get__token,
              params: formData,
          })
          .then(response => {
            if(response.data.state == 0){
                this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                this.openAd(idAd, 3, 0);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }

          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        deleteFile: function (id, idAd) {
          this.get({
              url: this.$store.getters.get__url + "/Assignments/deleteFileAd/" + id,
              token: this.$store.getters.get__token,
          })
          .then(response => {
            if(response.data.state == 0){
              this.$refs.SweetAlert.showSuccess("Eliminación Exitosa");
              this.openAd(idAd, 3, 0);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        registerComen: function (payload_detail, idAd) {
          this.post({
              url: this.$store.getters.get__url + "/Assignments/registerComenAd",
              token: this.$store.getters.get__token,
              params: {
                description: payload_detail.description,
                idAd : idAd,
              },
          })
          .then(response => {
            if(response.data.state == 0){
                this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                this.openAd(idAd, 3, 1);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }

          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        deleteComen: function (id, idAd) {
          this.get({
              url: this.$store.getters.get__url + "/Assignments/deleteComenAd/" + id,
              token: this.$store.getters.get__token,
          })
          .then(response => {
            if(response.data.state == 0){
              this.$refs.SweetAlert.showSuccess("Eliminación Exitosa");
              this.openAd(idAd, 3, 1);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
  
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
  
        //Paginacion Proceso
        backPageAsign() {
            this.selectPageAsign = this.selectPageAsign > 1 ? this.selectPageAsign - 1 : 1;
        },
        nextPageAsign() {
            this.selectPageAsign =
                this.selectPageAsign < this.stepPaginationAsign.length ?
                this.selectPageAsign + 1 :
                this.stepPaginationAsign.length;
        },
        selectedPageAsign(page) {
            this.selectPageAsign = page;
        },
  
        //Paginacion Terminado
        backPageTer() {
            this.selectPageTer = this.selectPageTer > 1 ? this.selectPageTer - 1 : 1;
        },
        nextPageTer() {
            this.selectPageTer =
                this.selectPageTer < this.stepPaginationTer.length ?
                this.selectPageTer + 1 :
                this.stepPaginationTer.length;
        },
        selectedPageTer(page) {
            this.selectPageTer = page;
        },
  
        //
        mtdNumber: function (index) {
            return index + 1 + (this.selectPage - 1) * 10;
        },
        mtdNumberAsign: function (index) {
            return index + 1 + (this.selectPageAsign - 1) * 7;
        },
        mtdNumberTer: function (index) {
            return index + 1 + (this.selectPageTer - 1) * 7;
        },
  
        returnHome: function () {
          this.$emit("mtdChangePage", 'home');
        },
        getStatusText(status) {
          switch (status) {
            case 1:
              return 'POR HACER';
            case 2:
              return 'EN PROCESO';
            case 3:
              return 'TERMINADO';
            default:
              return 'ESTADO DESCONOCIDO';
          }
        },
        getPriority(status) {
          switch (status) {
            case 1:
              return 'ALTA';
            case 2:
              return 'MEDIA';
            case 3:
              return 'BAJA';
            default:
              return 'ESTADO DESCONOCIDO';
          }
        },
        getBadgeClass(status) {
          switch (status) {
            case 1:
              return 'badge-danger';
            case 2:
              return 'badge-warning';
            case 3:
              return 'badge-success';
            default:
              return 'badge-secondary';
          }
        },
        getBadgePriority(status) {
          switch (status) {
            case 1:
              return 'badge-danger';
            case 2:
              return 'badge-warning';
            case 3:
              return 'badge-success';
            default:
              return 'badge-secondary';
          }
        },
        truncateText(text, length, suffix) {
          if (text.length > length) {
            return text.substring(0, length) + suffix;
          } else {
            return text;
          }
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
  }
</script>
<style>
  .table-th-acciones{
    width:10% !important;
  }
  .table-th-number{
    width:5% !important;
  }
  .table-th-date{
    width:20% !important;
  }
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
  }
  .acciones {
      background-color: #717171;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  
  .acciones1 {
      background-color: #195bf6;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  
  .acciones-2 {
      background-color: #e50303;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  .acciones-3 {
      background-color: #b49f03;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  .header-item {
    background-color: #900052 !important;
    border-color: #900052 !important;
  }
  
  .task-item.selected {
    background-color: #ffeaef;
  }
  .task-item:hover {
    background-color: #ffeaef;
  }
  
  .task-name {
    cursor: pointer;
  }
  .font-size{
    font-size: 13px;
  }
  .responsable-name {
    display: block;
  }
  .text-title{
    background-color: #EB5D77;
    color: white;
    font-size: 11px;
    padding: 0.3rem;
    font-weight: bold;
    border-radius: 0.50rem;
  }
  .badge-danger {
    background-color: #fa0821;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-warning {
    background-color: #f7ba03;
    color: #000000;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-success {
    background-color: #0bc837;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .form-check-label{
    white-space: nowrap;
    font-size: 12px
  }
  .scrollable-container {
    max-height: 500px;
    overflow-y: auto;
  }
  /*Si esta vencido*/
  .expired-task {
    background-color: #EDEAEC;
  }
  .expired-task .list-group-item {
    border-color: #EDEAEC;
  }
  .spams{
    font-size: 14px;
  }
  </style>
  