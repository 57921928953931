<template>
    <div>
        <div class="d-flex flex-row mb-5">
            <div id="div-listcola" class="card m-3">
                <div class="card-header text-white h6 header-main">
                    <strong>HOSPITALIZACIÓN</strong>
                </div>
                <div class="card-body" id="content-timeline">
                    <div class="row">
                        <div class="px-sm-1 px-md-3">
                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">JESUS GONZALES</span>
                                <br />
                                <span class="text-datos">8:00 a.m. - CONSULTA MEDICINA INTERNA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>
                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">ANA LUISA LLONTOP CALDERON</span>
                                <br />
                                <span class="text-datos">10:00 a.m. - CONSULTA GINECOLOGÍA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">MANUEL ALBERTO GUTIERREZ FALCON</span>
                                <br />
                                <span class="text-datos">11:00 a.m. - CONTROL</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">JUAN JOSÉ ROJAS RAMOS</span>
                                <br />
                                <span class="text-datos">11:00 a.m. - CONSULTA TRAUMATOLOGÍA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">GERSON OLIVA REMIGIO</span>
                                <br />
                                <span class="text-datos">12:00 m. - CONSULTA MEDICINA INTERNA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div id="div-listcola" class="card m-3">
                <div class="card-header text-white h6 header-main">
                    <strong>UCI ADULTO</strong>
                </div>

                <div class="card-body" id="content-timeline">
                    <div class="row">

                        <div class="px-sm-1 px-md-3">

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">JOSÉ GUILLERMO SANTISTEBAN GUERRERO</span>
                                <br />
                                <span class="text-datos">8:00 a.m. - CONSULTA MEDICINA INTERNA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">ANA LUISA LLONTOP CALDERON</span>
                                <br />
                                <span class="text-datos">10:00 a.m. - CONSULTA GINECOLOGÍA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">MANUEL ALBERTO GUTIERREZ FALCON</span>
                                <br />
                                <span class="text-datos">11:00 a.m. - CONTROL</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">JUAN JOSÉ ROJAS RAMOS</span>
                                <br />
                                <span class="text-datos">11:00 a.m. - CONSULTA TRAUMATOLOGÍA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">GERSON OLIVA REMIGIO</span>
                                <br />
                                <span class="text-datos">12:00 m. - CONSULTA MEDICINA INTERNA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div id="div-listcola" class="card m-3">
                <div class="card-header text-white h6 header-main">
                    <strong>EMERGENCIA - TRAUMASHOCK</strong>
                </div>

                <div class="card-body" id="content-timeline">
                    <div class="row">

                        <div class="px-sm-1 px-md-3">

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">JOSÉ GUILLERMO SANTISTEBAN GUERRERO</span>
                                <br />
                                <span class="text-datos">8:00 a.m. - CONSULTA MEDICINA INTERNA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">ANA LUISA LLONTOP CALDERON</span>
                                <br />
                                <span class="text-datos">10:00 a.m. - CONSULTA GINECOLOGÍA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">MANUEL ALBERTO GUTIERREZ FALCON</span>
                                <br />
                                <span class="text-datos">11:00 a.m. - CONTROL</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">JUAN JOSÉ ROJAS RAMOS</span>
                                <br />
                                <span class="text-datos">11:00 a.m. - CONSULTA TRAUMATOLOGÍA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                            <div class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                <span class="text-paciente">GERSON OLIVA REMIGIO</span>
                                <br />
                                <span class="text-datos">12:00 m. - CONSULTA MEDICINA INTERNA</span>
                                <br>
                                <span class="text-datos">Chek in:</span>
                                <br>
                                <span class="text-datos">Solicitud</span>
                                <br>
                                <span class="text-datos">Tiempo Transcurrido</span>
                                <br>
                                <span class="text-datos">Desde S/</span>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'c-asistencial-sop-cola',
    data() {
        return {
            //property: 'value',
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>