<template lang="es">
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4" id="document_type">Tipo Documento</span>
                <v-select :options="documents_type" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" required v-model="egreso_edit.document_type_id" />
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">Fecha Egreso</span>
                <input type="date" aria-label="date" class="form-control form-control-sm" required disabled v-model="egreso_edit.date" />
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text">Fecha Comprobante</span>
                <input type="date" aria-label="date_doc" class="form-control form-control-sm" required v-model="egreso_edit.date_doc" />
            </div>
        </div>

        <div class="col-md-6 col-lg-2 col-sm-6 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text" id="serie">Serie</span>
                <input type="text" class="form-control" placeholder="N° Serie" aria-describedby="serie" v-model="egreso_edit.serie" />
            </div>
        </div>

        <div class="col-md-6 col-lg-4 col-sm-6 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text" id="number">Número</span>
                <input type="text" class="form-control" placeholder="Número" aria-describedby="number" v-on:keypress="isNumber($event)" v-model="egreso_edit.number" />
            </div>
        </div>

        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="radio" aria-label="Checkbox for selecting RUC" v-model="egreso_edit.type_document" value="RUC" />
                        <label for="rucCheckbox">RUC</label>
                    </div>
                </div>
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="radio" aria-label="Checkbox for selecting DNI" v-model="egreso_edit.type_document" value="DNI" />
                        <label for="dniCheckbox">DNI</label>
                    </div>
                </div>
                <input type="text" class="form-control" placeholder="RUC/DNI" aria-describedby="document" required v-model="egreso_edit.document" @keyup="mtdSearchDocument" />
            </div>
        </div>
        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4" id="company">Razón Social / Nombre</span>
                <input disabled type="text" class="form-control" placeholder="Razón Social / Nombre" aria-describedby="company" required v-model="egreso_edit.company" />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-4 col-md-6">Monto</span>
                <input type="text" aria-label="total" placeholder="0.00" class="form-control form-control-sm" required v-on:keypress="isNumber($event)" v-model="egreso_edit.total" disabled />
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-4 col-md-6">Monto Pagado</span>
                <input type="text" aria-label="amount" placeholder="0.00" class="form-control form-control-sm" v-on:keypress="isNumber($event)" required @blur="mtdCalculate" v-model="egreso_edit.amount" disabled />
            </div>
        </div>

        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-4 col-md-6">Monto Pendiente</span>
                <input readonly type="text" aria-label="amount_pay" placeholder="0.00" class="form-control form-control-sm" v-on:keypress="isNumber($event)" v-model="egreso_edit.pending" disabled />
            </div>
        </div>
        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-4 col-md-6" id="method">Método Pago</span>
                <v-select disabled :options="methods" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" required v-model="egreso_edit.method" />
            </div>
        </div>

        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <input class="form-control" type="file" @change="handleFileUpload" name="file" id="file" ref="file" />
            </div>
        </div>

        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-3" id="description">Motivo</span>
                <textarea type="text" class="form-control" aria-label="Sizing example input" placeholder="Motivo, descripción o detalle" aria-describedby="description" v-model="egreso_edit.description"></textarea>
            </div>
        </div>
        <!-- visualizacion de archivos -->
        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <!-- Mostrar los archivos -->
            <div class=" row d-flex mx-0 px-0 mt-2">
                <div class="col-xl-4 col-md-4 col-sm-4" v-for="(file, index) in allFiles" :key="index">
                    <div @click=" mtddelete(index,'removeFile')">
                        <template v-if="isImage(file.name)">
                            <img :src="getFilePreview(file)" style="max-width: 50px; max-height: 50px;" />
                        </template>
                        <template v-else-if="isPDF(file.name)">
                            <i class="far fa-file-pdf" style="color: red; font-size: 50px;"></i>
                        </template>
                        <template v-else>
                            <i class="far fa-file"></i>
                        </template>
                        <div class="text-xs"> {{ file.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-3" id="description">Vuelto</span>
                <input type="text" placeholder="0.00" class="form-control form-control-sm" v-model="egreso_edit.vuelto" @keypress="validateNumberInput" />
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdEditEgreso">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" @removeFile="removeFile" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-jefatura-editarEgreso",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            allFiles: [],
            jsonFile: {
                file: {
                    value:[]
                }
            },
            methods: [{
                    id: "efectivo",
                    name: "EFECTIVO"
                },
                {
                    id: "tarjeta",
                    name: "TARJETA"
                },
                {
                    id: "deposito",
                    name: "DEPOSITO"
                },
                {
                    id: "mixto",
                    name: "MIXTO"
                },
            ],
            egreso_edit: {
                idRe: "",
                id: "",
                campu_id: null,
                type_document: "",
                document_type_id: null,
                date: "",
                date_doc: "",
                serie: "",
                number: "",
                document: "",
                company: "",
                total: 0.0,
                amount: 0.0,
                pending: 0.0,
                method: "",
                description: "",
                state: "",
                file: "",
                user: "",
                //RECEIPT
                patient_id: null,
                shift_date: null,
                shift: null,
                category: null,
                //FIN RECEIPT
                user_autorize: "",
                vuelto: 0
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        egreso: {},
        documents_type: [],

    },
    computed: {

    },
    watch: {
        egreso: {
            handler(newVal) {
                if (newVal) {

                    this.egreso_edit = {
                        ...newVal
                    };

                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        /** helpers */
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (
                !/\.(pdf)$/i.test(file.name) &&
                !/\.(png)$/i.test(file.name) &&
                !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)
            ) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF, PNG O JPG');
                fileInput.value = "";
            } else {
                this.egreso_edit.file = file;
            }
        },
        mtdSearchDocument: function () {
            if (!this.egreso_edit.type_document) {
                this.$refs.SweetAlert.showWarning("Seleccione RUC  o DNI");
            } else {
                if (this.egreso_edit.document.length === 8 && this.egreso_edit.type_document == "DNI") {
                    this.get({
                            url: this.$store.getters.get__url + "/reniec/" + this.egreso_edit.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.egreso_edit.document = "";
                                this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            } else {
                                this.egreso_edit.company = response.data.name;
                            }
                        })
                        .catch((errors) => {
                            console.log(errors)
                        });
                } else {
                    if (this.egreso_edit.document.length === 11 && this.egreso_edit.type_document == "RUC") {
                        this.get({
                                url: this.$store.getters.get__url + "/reniec/" + this.egreso_edit.document,
                                token: this.$store.getters.get__token,
                            })
                            .then((response) => {

                                if (response.data.boo == 3) {
                                    this.client.document = "";
                                    this.$refs.SweetAlert.showWarning("RUC no encontrado");
                                } else {
                                    this.egreso_edit.company = response.data.razon_social;
                                }
                            })
                            .catch((errors) => {
                                console.log(errors)
                            });
                    }
                }
            }
        },
        mtdEditEgreso: function () {
            /* if(this.egreso_edit.document_type_id == 3){
                this.$refs.SweetAlert.showWarning("Seleccione Tipo de Doc. diferente a Ticket");
                return;
            } */
            if (this.egreso_edit.vuelto == null || this.egreso_edit.vuelto == undefined || this.egreso_edit.vuelto == "") {
                this.egreso_edit.vuelto = 0;
            }
            /* let formData = new FormData();
            formData.append("campu_id", this.$store.getters.get__campus);
            formData.append("id", this.egreso_edit.id);
            formData.append("bead_id", this.egreso_edit.bead_id);
            formData.append("document_type_id", this.egreso_edit.document_type_id);
            formData.append("date", this.egreso_edit.date);
            formData.append("date_doc", this.egreso_edit.date_doc);
            formData.append("serie", this.egreso_edit.serie);
            formData.append("number", this.egreso_edit.number);
            formData.append("document", this.egreso_edit.document);
            formData.append("company", this.egreso_edit.company);
            formData.append("total", this.egreso_edit.total);
            formData.append("amount", this.egreso_edit.amount);
            formData.append("pending", this.egreso_edit.pending);
            formData.append("method", this.egreso_edit.method);
            formData.append("description", this.egreso_edit.description);
            formData.append("state", 2);
            formData.append("file", this.egreso_edit.file);
            formData.append("user", this.egreso_edit.user);
            formData.append("vuelto", this.egreso_edit.vuelto);
            formData.append("campu_id", this.$store.getters.get__campus); */
            this.egreso_edit.campu_id = this.$store.getters.get__campus;
            this.egreso_edit.state = 2;

            this.post({
                    url: this.$store.getters.get__url + "/Jefatura/egreso/update",
                    token: this.$store.getters.get__token,
                    params: this.egreso_edit,
                })
                .then((response) => {

                    if (response.statusCode == 200) {
                        if (this.allFiles.length > 0) {
                            this.mtdStoreFile(response.data);
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);
                        }
                        this.mtdclosemodal(0);
                    }
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdCalculate: function () {
            this.egreso_edit.pending =
                parseFloat(this.egreso_edit.total).toFixed(2) -
                parseFloat(this.egreso_edit.amount).toFixed(2);
        },
        mtdclosemodal(opt) {
            this.$refs.file.value = '';
            this.allFiles = [];
            this.$emit('mtdclosemodal', opt);
        },
        mtdstoredata() {
            this.$emit('mtdstoredata')
        },

        validateNumberInput(event) {
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        },
        //archivo
        mtdStoreFile: function (resp) {
            let formData = new FormData();
            formData.append("length", this.allFiles.length);
            formData.append("id", this.egreso_edit.id);
            this.allFiles.forEach((element, index) => {
                let temp = [];
                temp.push(element);
                formData.append("file_" + index, this.mtdArrayToFileList(temp)[0]);
            });
            this.post({
                    url: this.$store.getters.get__url + "/Jefatura/egreso/storeFiles",
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {
                    if (!response.error) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.allFiles = [];
                    } else {
                        this.$refs.SweetAlert.showWarning(response.data.error_message);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdArrayToFileList: function (arr) {
            const fileList = new DataTransfer();
            arr.forEach((file) => fileList.items.add(file));
            return fileList.files;
        },
        handleFileUpload(event) {
            const files = event.target.files;
            const allowedFormats = ['pdf', 'png', 'jpg', 'jpeg'];
            const invalidFiles = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const fileAlreadyExists = this.allFiles.some(existingFile => existingFile.name === file.name);
                if (fileAlreadyExists) {
                    continue;
                }
                if (allowedFormats.includes(fileExtension)) {
                    this.allFiles.push(file);
                } else {
                    invalidFiles.push(file.name);
                }
            }
            if (invalidFiles.length > 0) {
                let message;
                if (invalidFiles.length === 1) {
                    message = `El archivo ${invalidFiles[0]} tiene un formato no permitido.`;
                } else {
                    message = `Los siguientes archivos tienen formatos no permitidos:\n\n${invalidFiles.join('\n')}`;
                }
                this.$refs.SweetAlert.showWarning(message);

                // Limpiar el input
                // event.target.value = '';
            }
        },
        isImage(fileName) {
            const ext = fileName.split('.').pop().toLowerCase();
            return ext === 'png' || ext === 'jpg' || ext === 'jpeg';
        },
        isPDF(fileName) {
            const ext = fileName.split('.').pop().toLowerCase();
            return ext === 'pdf';
        },
        getFilePreview(file) {
            return URL.createObjectURL(file);
        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
        },
        removeFile(indexToRemove) {
            this.allFiles.splice(indexToRemove, 1);

        }
    },
};
</script>

<style scoped>
.inpt_color {
    text-align: center !important;
    background-color: cadetblue !important;
    border-radius: 20px;
    color: white;
}

.inpt_color::placeholder {
    color: white;
}
</style>
