<template>
    <div>
      <div class="container text-center">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-1 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <ul class="nav pb-0" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('egreso')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'egreso',
              }"
              id="home-tab"
              type="button"
            >
              Egreso
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('recibo')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'recibo',
              }"
              id="profile-tab"
              type="button"
            >
              R. por Honorarios
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('pasaje')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'pasaje',
              }"
              id="profile-tab"
              type="button"
            >
              Pasajes
            </button>
          </li>
        </ul>
        <div class="tab-content mb-4" id="myTabContentEgreso">
          <Egreso v-if="page == 'egreso'" :bead="bead" />
          <Recibo v-if="page == 'recibo'" :bead="bead"/>
          <Pasaje v-if="page == 'pasaje'" :bead="bead"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Egreso from "@/components/Admision/pages/Egresos/Egreso.vue";
  import Pasaje from "@/components/Admision/pages/Egresos/Pasaje.vue";
  import Recibo from "@/components/Admision/pages/Egresos/Recibo.vue";
  export default {
    name: "Egresos",
  
    components: {
      Egreso,
      Pasaje,
      Recibo,
    },
    props:{
      bead:{}
    },
  
    data() {
      return {
        page: "egreso",
      };
    },
  
    methods: {
      returnHome: function () {
        this.$emit("mtdChangePage", "home");
      },
      changePage: function (page) {
        this.page = page;
      },
    },
  };
  </script>
  