<template>
  <div>
    <div class="container ">

      <div id="div-modal-imagen" class="d-flex justify-content-center mt-5">
        <div id="div-imagen" class="card w-100">
          <div align="center" class="card-header text-white h4 w-100" style="background: #900052">
            <strong>LABORATORIO-{{ elementProcess.name }}</strong>
          </div>
          <div>
        </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                        <div class="form-check form-check-inline justify-content-end">
                            <input
                                v-model="elementProcess.reservadespues"
                                class="form-check-input"
                                type="checkbox"
                                id="checkboxreserva"
                                @click="reserva()"
                            />
                            <label class="form-check-label" for="checkboxFacturareserva">
                                Reservar Luego
                            </label>
                        </div>
              </div>
              <div class="col-md-12">
                <div class="card w-100 mt-2 border-main" style="width: 18rem;">
                  <div class="card-body">
                    <div class="col-md-12 p-0" id="div-tabla-imagen">
                      <table id="tb-cola" class="table table-sm table-bordered p-0">
                        <thead>
                          <tr align="left" class="text-white border-white" style="font-size: 12px; background: #900052">
                            <th scope="col">FECHA</th>
                            <th scope="col" :colspan="cpColspan">HORARIOS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="font-size: 12px;" v-for="(item, index) in data" :key="index">
                            <td class="py-2" style=" font-weight:bold;"><span class="rounded-pill p-1"
                                style="background: #fff;">{{ item.date }}</span></td>
                            <td class="py-2" v-for="(item2, index2) in item.hours" :key="index2">
                              <span class="rounded-pill py-1 px-2" style="background: #fff;" v-if="item2.state == 0">{{
                                item2.name }}</span>
                              <div v-if="elementProcess.data.hour == item2.name && elementProcess.data.date == item.date">
                                <div v-if="mostrar == true">
                                  <span class="rounded-pill py-1 px-2" style="background: orange; color: #fff;"
                                    v-if="elementProcess.data.hour == item2.name && elementProcess.data.date == item.date">{{ item2.name }}</span>
                                </div>
                                <div v-else>
                                  <span class="rounded-pill py-1 px-2"
                                    @click="mtdReserveHour(item.date, item2.name, index, index2)"
                                    style="background: #39b54a; color: #fff;" v-if="item2.state == 1">{{ item2.name
                                    }}</span>
                                </div>
                              </div>
                              <div v-else>
                                <span class="rounded-pill py-1 px-2"
                                  @click="mtdReserveHour(item.date, item2.name, index, index2)"
                                  style="background: #39b54a; color: #fff;" v-if="item2.state == 1">{{ item2.name
                                  }}</span>
                                <span class="rounded-pill py-1 px-2" style="background: #fcee21; color: #fff;"
                                  v-if="item2.state == 2">{{ item2.name }}</span>
                                <span class="rounded-pill py-1 px-2" style="background: #ff0000; color: #fff;"
                                  v-if="item2.state == 3">{{ item2.name }}</span>
                                <span class="rounded-pill py-1 px-2" style="background: orange; color: #fff;"
                                  v-if="item2.state == 4">{{ item2.name }}</span>
                              </div>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="card-footer text-white" style="background: #900052; font-size: 20px">
            <div class="row d-flex">
              <div class="col">
                <span class="btn border border-white text-white" @click="mtdBehindStep"><strong>ATRAS</strong></span>
              </div>
              <div align="right" class="col">
                <span class="btn border border-white text-white" @click="mtdNextStep"><strong>SIGUIENTE</strong></span>
              </div>
            </div>
          </div>
        </div>
      </div><br><br><br>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'c-admision-page-laboratory',
  data() {
    return {
      data: [],
      verifSelectHour: false,
      oldPosSelectHour: [],
      mostrar: false,
    }
  },
  props: {
    elementProcess: {},
  },
  computed: {
    cpColspan(){
      return this.data.length >0? this.data[0].hours.length:1;
    }
  },
  created() {
    this.mtdGetData();
    this.mtdCompleteData();
  },
  methods: {
    ...mapActions(['get', 'post']),
    reserva() {
    if (this.elementProcess.reservadespues===false) {
      this.elementProcess.reservadespues= true;
    } else {
      this.elementProcess.reservadespues = false; // No es necesario comparar, solo asigna el valor
    }
   
    },
    mtdGetData: function () {
      this.get({
        url:
          this.$store.getters.get__url + "/Venta/laboratorio/"+this.$store.getters.get__campus,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data[0]['calendar'];
        })
        .catch((errors) => { });
    },
    mtdCompleteData() {
      if (this.elementProcess.programado == true) {
        this.mostrar = true;
        
      }
    },
    mtdReserveHour: function (fecha, hora, pos1, pos2) {
      /** consultar con bd si aun está libre */
      if (this.verifSelectHour == true) {
        this.data[this.oldPosSelectHour[0]].hours[this.oldPosSelectHour[1]].state = 1;
      }
      this.oldPosSelectHour = [pos1, pos2];
      this.verifSelectHour = true;
      this.data[pos1].hours[pos2].state = 4;
      this.elementProcess.data = {
        date: fecha, hour: hora, room: this.tab
      }
      this.elementProcess.programado=true;
      this.mostrar=true;
    },
    mtdNextStep: function () {
      if(this.elementProcess.reservadespues===true){ 
        this.$emit('mtdNextProcess', this.elementProcess);
      }
      else{
      if (this.elementProcess.data.hour != null) {
        //this.elementProcess.programado = true;
        this.$emit('mtdNextProcess', this.elementProcess);
        this.data= [];
        this.verifSelectHour= false;
        this.oldPosSelectHour= [];
        this.mostrar= false;
        this.mtdGetData();
        this.mtdCompleteData();
      } else {
        Swal.fire({
          text: 'Seleccione un Laboratorio',
          icon: 'warning',
          confirmButtonColor: '#900052',
        });
      }

    }},
    mtdBehindStep: function () {
      //this.elementProcess.programado = false;
      this.$emit('mtdBehindProcess', this.elementProcess);
    },
  }
}
</script>

<style scoped>
#div-tabla-imagen {
  border: 1px solid #aaa;
  height: 300px;
  background: #f1f2f3;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#div-tabla-imagen::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-tabla-imagen::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-tabla-imagen::-webkit-scrollbar-button:increment,
#div-tabla-imagen::-webkit-scrollbar-button {
  display: none;
}

#div-tabla-imagen::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-tabla-imagen::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-tabla-imagen::-webkit-scrollbar-track {
  border-radius: 10px;
}

.circulo {
  vertical-align: middle;
  border-style: none;
}

#div-modal-imagen {
  margin-top: 10px;
}

@media(max-width: 800px) {

  /* #div-modal-imagen{
      margin-top: 20px;
    } */
  #btn-open-caja {
    justify-content: center !important;
  }

  #btn-registro-cola {
    text-align: center;
  }

  #div-tabla-imagen {
    overflow-x: auto
  }
}

@media(min-width: 800px) {

  /* #div-modal-imagen{
      margin-top: 65px;
    } */
  #btn-registro-cola {
    text-align: left;
  }
}</style>