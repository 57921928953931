<template>
  <div>
    <div class="container" v-if="view == 'data'">
      <div class="row">
          <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
              <button type="button" class="btn btn-outline-main" @click="returnHome">
                  <i class="fas fa-home-lg-alt"></i> Inicio
              </button>
          </div>
      </div>
      <div id="div-modal-anular" class="w-100">
        <div class="card w-100 scroll-div">
          <div
            class="card-header text-white text-center h4"
            style="background: #900052"
          >
            <strong>RECETAS</strong>
          </div>
          <div class="card-body">
            <div class="d-flex row justify-content-center align-items-center ">
              <div class="col-xl-6 col-lg-9 col-md-9 col-sm-12 col-xs-12 p-2 mt-2">
                <input
                  class="form-control form-control-lg inpunt-rounded custom-input"
                  type="text"
                  v-model="reDoc"
                  @keyup.enter="mtdSearchDocument"
                  placeholder="Buscar Paciente"
                  aria-label=".form-control-lg example"
                  :disabled="listado"
                />
              </div>
              <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-xs-2 p-2 mt-1">
                <div class="form-check custom-input">
                    <input class="form-check-input custom-checkbox" type="checkbox" value="" id="flexCheckDefault" v-model="listado">
                    <label class="form-check-label" for="flexCheckDefault">
                      Ver Recetas 
                    </label>
                </div>
              </div>
            </div>
            <!--Componente-->
            <div class="px-3 mt-4" v-if="listado">
              <cListado
                :listaRecipe="listaRecipe"
                @openRecipeDetail="openRecipeDetail"
              />
            </div>
            <!--Componente-->

            <div class="mt-4" v-if="!listado && reDoc !== ''">
              <div class="row d-flex" v-for="(patientData, patientName) in dataReDoc" :key="patientName">
                  <div class="px-sm-1 px-md-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="card-header text-white h7 header-main" style="font-size: 15px">
                          <strong>PACIENTE: {{ patientData[0].patient_name }}</strong>
                      </div>
                      <div
                          v-for="(item, index) in patientData"
                          :key="index"
                          class="p-2 mt-2 mb-2 border border-main border-start-0 rounded-end align-left d-flex justify-content-center"
                      >

                          <!-- Consultas -->
                          <div
                              v-if="item.consu == 1"
                              class="d-flex flex-column align-items-center"
                              style="margin-left: 5px; margin-right: 15px"
                          >
                              <button
                                  @click="mtdOpenRecipe(item)"
                                  class="div-button m-2 p-2 text-white"
                                  style="font-size: 11px"
                              >
                                  <strong>{{ item.consulting_room_name }}</strong>
                              </button>
                              <div class="containerSvg" style="width: 40px; height: 45px; cursor: pointer;"  @click="mtdOpenRecipe(item)">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 576 512"
                                      width="100%"
                                      height="100%"
                                  >
                                      <path
                                          fill="#900052"
                                          d="M288 115L69.5 307.7c-1.6 1.5-3.7 2.1-5.5 3.4V496a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V311.1c-1.7-1.2-3.7-1.8-5.3-3.2zm96 261a8 8 0 0 1 -8 8h-56v56a8 8 0 0 1 -8 8h-48a8 8 0 0 1 -8-8v-56h-56a8 8 0 0 1 -8-8v-48a8 8 0 0 1 8-8h56v-56a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v56h56a8 8 0 0 1 8 8zm186.7-139.7l-255.9-226a39.9 39.9 0 0 0 -53.5 0l-256 226a16 16 0 0 0 -1.2 22.6L25.5 282.7a16 16 0 0 0 22.6 1.2L277.4 81.6a16 16 0 0 1 21.2 0L527.9 283.9a16 16 0 0 0 22.6-1.2l21.4-23.8a16 16 0 0 0 -1.2-22.6z"
                                      />
                                  </svg>
                              </div>
                              <div class="fw-bold">
                                  <span style="color: #900052; font-size: 11px">{{ item.consulting_insurance }}</span>
                              </div>
                          </div>
                          <br />
                          <!-- Operaciones -->
                          <div
                              v-if="item.ope == 1"
                              class="d-flex flex-column align-items-center"
                              style="margin-left: 5px; margin-right: 15px"
                          >
                              <button
                                  @click="mtdOpenRecipe(item)"
                                  class="div-button m-2 p-2 text-white"
                                  style="font-size: 11px"
                              >
                                  <strong>{{ item.operations_room_name }}</strong>
                              </button>
                              <div class="containerSvg" style="width: 40px; height: 45px; cursor: pointer;" @click="mtdOpenRecipe(item)">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      width="100%"
                                      height="100%"
                                  >
                                      <path
                                          fill="#900052"
                                          d="M528 224H272c-8.8 0-16 7.2-16 16v144H64V144c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48h512v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V336c0-61.9-50.1-112-112-112zM136 96h126.1l27.6 55.2c5.9 11.8 22.7 11.8 28.6 0L368 51.8 390.1 96H512c8.8 0 16-7.2 16-16s-7.2-16-16-16H409.9L382.3 8.8C376.4-3 359.6-3 353.7 8.8L304 108.2l-19.9-39.8c-1.4-2.7-4.1-4.4-7.2-4.4H136c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm24 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64z"
                                      />
                                  </svg>
                              </div>
                              <div class="fw-bold">
                                  <span style="color: #900052; font-size: 11px">{{ item.operations_insurance }}</span>
                              </div>
                          </div>
                          <br />
                          <!-- Hospitalización -->
                          <div
                              v-if="item.hospi == 1"
                              class="d-flex flex-column align-items-center"
                              style="margin-left: 5px; margin-right: 15px"
                          >
                              <button
                                  @click="mtdOpenRecipe(item)"
                                  class="div-button col m-1 p-1 text-white"
                                  :style="{
                                      background: getColorByState(item.bed_status),
                                      height: 'auto',
                                      display: 'inline-block',
                                      'max-width': '100%',
                                      'text-align': 'center',
                                      'margin-bottom': '5px',
                                      'font-size': '11px'
                                  }"
                              >
                                  <strong>{{ item.bed_name }}</strong>
                              </button>
                              <div class="containerSvg" style="width: 40px; height: 45px; cursor: pointer;" @click="mtdOpenRecipe(item)">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      width="100%"
                                      height="100%"
                                  >
                                      <path
                                          fill="#900052"
                                          d="M32 32c17.7 0 32 14.3 32 32l0 256 224 0 0-160c0-17.7 14.3-32 32-32l224 0c53 0 96 43 96 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32-224 0-32 0L64 416l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"
                                      />
                                  </svg>
                              </div>
                              <div class="fw-bold">
                                  <span style="color: #900052; font-size: 11px">{{ item.hospitalization_insurance }}</span>
                              </div>
                          </div>
                          <br />
                          <!-- UciAdult -->
                          <div
                              v-if="item.uciAdult == 1"
                              class="d-flex flex-column align-items-center"
                              style="margin-left: 5px; margin-right: 15px"
                          >
                              <button
                                  @click="mtdOpenRecipe(item)"
                                  class="div-button col m-1 p-1 text-white"
                                  :style="{
                                      background: getColorByState(item.bed_status),
                                      height: 'auto',
                                      display: 'inline-block',
                                      'max-width': '100%',
                                      'text-align': 'center',
                                      'margin-bottom': '5px',
                                      'font-size': '11px'
                                  }"
                              >
                                  <strong>{{ item.bed_name }}</strong>
                              </button>
                              <div class="containerSvg" style="width: 40px; height: 45px; cursor: pointer;" @click="mtdOpenRecipe(item)">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      width="100%"
                                      height="100%"
                                  >
                                      <path
                                          fill="#900052"
                                          d="M32 32c17.7 0 32 14.3 32 32l0 256 224 0 0-160c0-17.7 14.3-32 32-32l224 0c53 0 96 43 96 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32-224 0-32 0L64 416l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"
                                      />
                                  </svg>
                              </div>
                              <div class="fw-bold">
                                  <span style="color: #900052; font-size: 11px">{{ item.uciAdult_insurance }}</span>
                              </div>
                          </div>
                          <br />
                           <!-- Emergencia -->
                           <div
                              v-if="item.emergency == 1"
                              class="d-flex flex-column align-items-center"
                              style="margin-left: 5px; margin-right: 15px"
                          >
                              <button
                                  @click="mtdOpenRecipe(item)"
                                  class="div-button col m-1 p-1 text-white"
                                  :style="{
                                      background: getColorByState(item.bed_status),
                                      height: 'auto',
                                      display: 'inline-block',
                                      'max-width': '100%',
                                      'text-align': 'center',
                                      'margin-bottom': '5px',
                                      'font-size': '11px'
                                  }"
                              >
                                  <strong>{{ item.bed_name }}</strong>
                              </button>
                              <div class="containerSvg" style="width: 40px; height: 45px; cursor: pointer;" @click="mtdOpenRecipe(item)">
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 448 512"
                                      width="100%"
                                      height="100%"
                                  >
                                      <path
                                          fill="#900052"
                                          d="M32 32c17.7 0 32 14.3 32 32l0 256 224 0 0-160c0-17.7 14.3-32 32-32l224 0c53 0 96 43 96 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32-224 0-32 0L64 416l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"
                                      />
                                  </svg>
                              </div>
                              <div class="fw-bold">
                                  <span style="color: #900052; font-size: 11px">{{ item.emergency_insurance }}</span>
                              </div>
                          </div>

                          <br />
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3" v-else-if="view == 'tablaRecipe'">
      <cRecipe
        ref="cRecipes"
        @mtdBack="mtdBack"
        @retrocederCheck="retrocederCheck"
        :recipe="recipe"
        :acto="acto"
        :actoHisto="actoHisto"
        :emit="emit"
        @updateData="mtdOpenRecipe"
      />
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cRecipe from "../Componentes/cRecipess.vue";
import cListado from "../Componentes/cListadoRecetas.vue";
export default {
name: "c-pharmacy-pages-recipes",
components: {
  SweetAlert,
  cRecipe,
  cListado
},
data() {
  return {
    swal: null,
    listado: false,
    listaRecipe: [],
    dataConsultingrooms: [],
    search: '',
    dataShowC: [],
    page: 0,
    selectPage: 1,
    stepPagination: [],
    dataConsulting: [],
    recipe: [],
    actoHisto : [],
    //Recetas
    reDoc: '',
    dataReDoc: [],
    patient: "",
    view: "data",
    emit: 1,
  };
},
created() {},
computed: {
    cpData() {},
},
watch: {
    reDoc(newVal, oldVal) {
      if (newVal == "") {
          this.mtdSearchDocument();
      }
    },
    listado(newValue) {
      if (newValue) {
        this.reDoc = '';
        this.dataReDoc = [];
        this.mtdListado();
      }
    },
},
methods: {
    ...mapActions(["get", "post"]),

    returnHome: function () {
      this.$emit("returnHome");
    },

    mtdSearchDocument: function () {
      if (this.reDoc !== "") {
          this.post({
          url: this.$store.getters.get__url + "/Pharmacy/recetas",
          token: this.$store.getters.get__token,
          params: {
              reDoc: this.reDoc,
          },
          })
          .then((response) => {
              this.dataReDoc = response.data;
              if (this.dataReDoc.length === 0 || this.reDoc == "") {
                this.$refs.SweetAlert.showWarning("No hay Paciente con esos datos");
              }
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
      } else {
          this.dataReDoc = [];
      }
    },

    getColorByState: function (state) {
      if (state === 1) {
          return "#39b54a";
      } else if (state === 2) {
          return "#e60028";
      } else if (state === 3) {
          return "#063d75";
      } else {
          return "#000000";
      }
    },

    mtdOpenRecipe: function (item, itemEmit) {
      this.post({
          url: this.$store.getters.get__url + "/Pharmacy/tableRecipe",
          token: this.$store.getters.get__token,
          params: {
            idPatient: item.patient_id,
            idActo: item.acto_id,
          },
      })
      .then((response) => {
        if(itemEmit == 2){
          this.emit = 2;
        }else{
          this.emit = 1;
        }

        this.recipe = response.data.recipe;
        this.acto = response.data.acto;
        this.actoHisto = response.data.dataActoHisto;
        if (this.acto.length === 0) {
            this.$refs.SweetAlert.showWarning("Sin Acto Medico");
        } else {
            this.view = "tablaRecipe";
        }
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },


    mtdListado: function () {
      this.post({
          url: this.$store.getters.get__url + "/Pharmacy/listadoRecipeIncomplete",
          token: this.$store.getters.get__token,
      })
      .then((response) => {
        this.listaRecipe = response.data;
      })
      .catch((errors) => {
        this.$refs.SweetAlert.showError(errors);
      });
    },

    openRecipeDetail:function(data,acto) {
      this.recipe = [data];
      this.acto = acto;
      this.emit = 2;
      this.view = "tablaRecipe";
      this.$nextTick(() => {
        if (this.$refs.cRecipes && this.$refs.cRecipes.openDetail) {
          this.$refs.cRecipes.openDetail(data);
        } else {
          console.error('El método openDetail no está disponible en el componente cRecipes');
        }
      });
    },

    mtdBack: function () {
      this.view = "data";
    },

    retrocederCheck: function () {
      this.view = "data";
      this.mtdListado();
      this.listado = true;
    },
},
};
</script>
<style scoped>
.div-button {
background: #900052;
border-radius: 15px;
border: none;
}

.scroll-div {
min-height: 150px;
max-height: 545px;
overflow-y: auto;
}

.containerSvg {
width: 90px;
height: 90px;
margin-top: 2px;
margin-bottom: 2px;
}

.div-button {
display: flex;
align-items: center;
}
.custom-input {
border-color: #EB5077;
color: #EB5077;
}

.custom-input::placeholder {
color: #EB5077;
}

.inpunt-rounded{
  border-radius: var(--bs-border-radius-2xl)!important;
  border-color: #EB5077;
  color: #EB5077;
  border: 1px solid #EB5077;
  align-items: center;
  text-align: center;
}
@media (max-width: 315px) {
.scroll-div {
  height: 470px;
}
}

@media (max-width: 575px) {
.flex-row {
  padding-top: 10px !important;
}

#div-listcola {
  width: 100% !important;
  padding: 10px !important;
  margin-bottom: 10px !important;
}

.rounded-start {
  width: 30% !important;
}

.row.d-flex.g-0.align-items-center {
  flex-wrap: nowrap;
  justify-content: space-between;
}

.img-reci {
  flex: 0 0 50%;
}

.img-fluid {
  width: 100% !important;
}
}

.custom-checkbox {
    border: 1px solid #EB5077;
  }

  .custom-checkbox:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(144, 0, 82, 0.5);
  }
</style>
