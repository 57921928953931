var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container mt-3 text-center",attrs:{"id":"app"}},[_c('div',{staticClass:"row px-3 responsive-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row mx-0"},[_c('table',{staticClass:"w-100"},[_c('caption',{staticClass:"div-text"}),_vm._m(3),_c('tbody',{staticClass:"div-text"},_vm._l((5),function(index){return _c('tr',{key:index,staticClass:"px-1"},[_c('td',{},[_vm._v(_vm._s(index))]),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true),_vm._m(8,true),_vm._m(9,true)])}),0)])]),_vm._m(10)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-12"},[_c('form',{},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"input-group-sm col-xl-5 ps-0",attrs:{"id":"fecha-hora"}},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"fecha-hora","placeholder":"Fecha y hora de realización de Rp","autocomplete":"off"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"responsive-container-div col-xl-6 col-lg-12 p-4"},[_c('div',{staticClass:"row"},[_c('table',{staticClass:"w-100"},[_c('caption',{staticClass:"div-text"}),_c('thead',{staticClass:"div-text div-title"},[_c('tr',[_c('th',{staticClass:"w-40",attrs:{"colspan":"2"}}),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Recomendaciones")])])]),_c('tbody',{staticClass:"div-text"},[_c('tr',{staticClass:"px-1 mb-1"},[_c('td',{staticClass:"encabezado-rounded fw-bold title-rp"},[_vm._v("Días hospitalizado")]),_c('td',{staticClass:"input-number"},[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"number"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])]),_c('tr',{staticClass:"px-1"},[_c('td',{staticClass:"encabezado-rounded fw-bold title-rp"},[_vm._v("Días SOP")]),_c('td',{staticClass:"input-number"},[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"number"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])]),_c('tr',{staticClass:"px-1"},[_c('td',{staticClass:"encabezado-rounded fw-bold title-rp"},[_vm._v("Días UCI")]),_c('td',{staticClass:"input-number"},[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"number"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"responsive-container-div col-xl-6 col-lg-12 p-4"},[_c('div',{staticClass:"row"},[_c('table',{staticClass:"w-100"},[_c('caption',{staticClass:"div-text"}),_c('thead',{staticClass:"div-text div-title"},[_c('tr',[_c('th',{}),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Concepto solicitud")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Fecha de solicitud")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Fecha de resultado")])])]),_c('tbody',{staticClass:"div-text"},[_c('tr',{staticClass:"px-1 mb-1"},[_c('td',{staticClass:"fw-bold title-rp-2 p-2",attrs:{"rowspan":"3"}},[_vm._v("PLAN")]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])]),_c('tr',{staticClass:"px-1"},[_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])]),_c('tr',{staticClass:"px-1"},[_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"div-text div-title"},[_c('tr',[_c('th',{staticClass:"encabezado-rounded",attrs:{"scope":"col"}},[_vm._v("Rp.")]),_c('th',{staticClass:"encabezado-rounded w-25"},[_vm._v("Medicamentos")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Presentacion")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Dosis")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Via")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Frecuencia")]),_c('th',{staticClass:"encabezado-rounded"},[_vm._v("Duracion")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('select',{staticClass:"form-select pb-0 pt-0",attrs:{"selected":""}},[_c('option',{attrs:{"value":""}},[_vm._v("Buscar...")]),_c('option',[_vm._v("Opción 1")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row my-1 mx-0"},[_c('div',{staticClass:"col-xl-2 col-lg-2 div-text h6 fw-bold d-flex align-items-center"},[_c('span',[_vm._v("MÉDICO GENERAL")])]),_c('div',{staticClass:"col-xl-3 col-lg-10 py-2"},[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})]),_c('div',{staticClass:"col-xl-2 col-lg-2 h6 fw-bold d-flex align-items-center div-text"},[_c('span',[_vm._v("MÉDICO ESPECIALISTA")])]),_c('div',{staticClass:"col-xl-3 col-lg-10 py-2"},[_c('input',{staticClass:"form-control form-control-input form-control-sm",attrs:{"type":"text"}})])])
}]

export { render, staticRenderFns }