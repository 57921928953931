<template>
<div>
    <div class="container">
        <div id="div-modal-egreso row" class="w-100">
            <div id="div-egreso" class="card-body m-2">
                <div class="row d-flex">
                    <div class="col-md-12 h2" v-if="view == 'table'">
                        <div class="d-flex justify-content-start" id="btn-registro-recibo">
                            <a href="javascript:void(0)" @click="mtdStore">
                                <i class="fas fa-plus-circle"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-md-12 p-2" id="content-timelinetable">
                        <table class="table table-bordered border-main" v-if="view == 'table'">
                            <thead>
                                <tr class="text-white border-white text-center bg-main">
                                    <th scope="col">N°</th>
                                    <th scope="col">DNI / RUC</th>
                                    <th scope="col">NOMBRES / RAZÓN SOCIAL</th>
                                    <th scope="col">SERIE - NÚMERO</th>
                                    <th scope="col">FECHA</th>
                                    <th scope="col">MÉTODO PAGO</th>
                                    <th scope="col">TOTAL</th>
                                    <th scope="col">PAGADO</th>
                                    <th scope="col">PENDIENTE</th>
                                    <th scope="col">MOTIVO</th>
                                    <th scope="col">ESTADO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataEgreso" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.document }}</td>
                                    <td>{{ item.company }}</td>
                                    <td>{{ item.serie }} - {{ item.number }}</td>
                                    <td>{{ item.date | formatDate }}</td>
                                    <td>{{ item.method }}</td>
                                    <td>S/ {{ item.total }}</td>
                                    <td>S/ {{ item.amount }}</td>
                                    <td>S/ {{ item.pending }}</td>
                                    <td>{{ item.description }}</td>
                                    <td>
                                        <div class="text-center d-flex justify-content-center text-xs">
                                            <button class="btn btn-sm a-success text-white" @click="mtdViewPdfEgreso(item.id)" v-if="item.state == 2">
                                                <i class="fas fa-check-circle"></i>
                                            </button>
                                            <button class="btn btn-sm a-danger text-white" v-if="item.state == 3">
                                                <i class="fas fa-times-circle"></i>
                                            </button>
                                            <button class="btn btn-sm a-warning text-white" v-if="item.state == 1">
                                                <i class="far fa-circle"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="dataEgreso.length == 0" class="text-center">
                                    <td colspan="11">No hay registros</td>
                                </tr>
                            </tbody>
                        </table>
                        <EgresoStore @mtdBackTable="mtdBackTable" @mtdAddEgreso="mtdAddEgreso" :bead="bead" v-else />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import EgresoStore from "./EgresoCreate.vue";
import moment from "moment";

export default {
    name: "c-admision-egreso",
    components: {
        EgresoStore
    },
    data() {
        return {
            process: {
                box: 0,
                initial: 0,
                observation: "",
                status: 0,
            },
            view: "table",
            dataEgreso: [],
            message: false,
        };
    },
    props: {
        boxes: [],
        bead: {},
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Expense/egreso",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        bead: this.bead.id
                    },
                })
                .then((response) => {
                    this.dataEgreso = response.data;
                })
                .catch((errors) => {});
        },
        retroceder() {
            this.$emit("mtdChangePage", "home");
        },
        mtdStore: function () {
            this.view = "store";
        },
        mtdBackTable: function () {
            this.view = "table";
        },
        mtdAddEgreso: function (item) {
            this.dataEgreso.push(item);
            this.mtdBackTable();
        },
        /** view pdf expense */
        mtdViewPdfEgreso: function (expense) {
            window.open(
                this.$store.getters.get__url + "/Permisions/viewpdf/expense/" + expense,
                "_blank"
            );
        },
    },
    filters: {
        // formato de fecha
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
};
</script>
