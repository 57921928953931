<template>
  <div>
    <div class="row p-2">
      <div class="col-xl-4 col-md-10 py-1">
        <div id="div-d-hospitalizado" class="form-group row align-left">
          <label for="d-hospitalizado" class="col-sm-5 color-main fw-bold">Días Hospitalizado</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="d-hospitalizado">
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-md-2"></div>

      <div class="col-xl-4 col-md-10 py-1">
        <div id="div-d-po" class="form-group row align-left">
          <label for="d-po" class="col-sm-5 color-main fw-bold">Días P.O.</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="d-po">
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-md-2"></div>
      <!---varios inputs -->
      <div class="col-xl-2 col-md-3 py-1">
        <div id="div-pas" class="form-group row align-left">
          <label for="pas" class="col-sm-5 color-main fw-bold">PAS</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="pas">
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-3 py-1">
        <div id="div-pad" class="form-group row align-left">
          <label for="pad" class="col-sm-5 color-main fw-bold">PAD</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="pad">
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-md-3 py-1">
        <div id="div-pam" class="form-group row align-left">
          <label for="pam" class="col-sm-5 color-main fw-bold">PAM</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="pam">
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-md-3 py-1">
        <div id="div-fc" class="form-group row align-left">
          <label for="fc" class="col-sm-5 color-main fw-bold">FC</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="fc">
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-md-3 py-1">
        <div id="div-fr" class="form-group row align-left">
          <label for="fr" class="col-sm-5 color-main fw-bold">FR</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="fr">
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-3 py-1">
        <div id="div-t" class="form-group row align-left">
          <label for="t" class="col-sm-5 color-main fw-bold">T*</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="t">
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-3 py-1">
        <div id="div-sato2" class="form-group row align-left">
          <label for="sato2" class="col-sm-5 color-main fw-bold">SaTO2</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="sato2">
          </div>
        </div>
      </div>
      <div class="col-xl-1 col-md-3 py-1">
        <div id="div-tio2" class="form-group row align-left">
          <label for="tio2" class="col-sm-5 color-main fw-bold">TiO2</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="tio2">
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-10 py-1">
        <div id="div-deposicion" class="form-group row align-left">
          <label for="deposicion" class="col-sm-5 color-main fw-bold">Deposiciones</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="deposicion">
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-md-2"></div>

      <div class="col-xl-4 col-md-10 py-1">
        <div id="div-orina" class="form-group row align-left">
          <label for="orina" class="col-sm-5 color-main fw-bold">Orina</label>
          <div class="col-sm-7">
              <input type="text" class="form-control form-control-sm" id="orina">
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-md-2"></div>
      <!---Tabla 1 -->
      <div class="col-xl-4 col-md-12 py-3 px-md-3">
        <div class="row">
            <table class="w-100">
              <caption class="div-text"></caption>
              <thead class="div-text div-title">
                <tr>
                  <th class="encabezado-rounded-1">PAS</th>
                  <th class="encabezado-rounded-2">PAD</th>
                  <th class="encabezado-rounded-3">FC</th>
                  <th class="encabezado-rounded-4">FR</th>
                  <th class="encabezado-rounded-5">T*</th>
                  <th class="encabezado-rounded-6">SatO2 SiO2</th>
                </tr>
              </thead>
              <tbody class="div-text">
                <tr class="px-1 mb-1">
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                </tr>
                <tr class="px-1">
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                </tr>
                <tr class="px-1">
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                  <td>
                    <input type="text" class="form-control form-control-input form-control-sm" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
      <!---Tabla 2 -->
      <div class="col-xl-2 col-md-3 py-3">
        <table class="w-100">
          <caption class="div-text"></caption>
          <thead class="div-text div-title">
            <tr>
              <th scope="row" class="encabezado-1" colspan="3">DÍA 1</th>
            </tr>
            <tr>
              <th scope="row" class="encabezado-1">M</th>
              <th scope="row" class="encabezado-1">T</th>
              <th scope="row" class="encabezado-1">N</th>
            </tr>
          </thead>
          <tbody class="div-text">
            <tr class="px-1 mb-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-xl-2 col-md-3 py-3">
        <table class="w-100">
          <caption class="div-text"></caption>
          <thead class="div-text div-title">
            <tr>
              <th scope="row" class="encabezado-1" colspan="3">DÍA 2</th>
            </tr>
            <tr>
              <th scope="row" class="encabezado-1">M</th>
              <th scope="row" class="encabezado-1">T</th>
              <th scope="row" class="encabezado-1">N</th>
            </tr>
          </thead>
          <tbody class="div-text">
            <tr class="px-1 mb-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-xl-2 col-md-3 py-3">
        <table class="w-100">
          <caption class="div-text"></caption>
          <thead class="div-text div-title">
            <tr>
              <th scope="row" class="encabezado-1" colspan="3">DÍA 3</th>
            </tr>
            <tr>
              <th scope="row" class="encabezado-1">M</th>
              <th scope="row" class="encabezado-1">T</th>
              <th scope="row" class="encabezado-1">N</th>
            </tr>
          </thead>
          <tbody class="div-text">
            <tr class="px-1 mb-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-xl-2 col-md-3 py-3">
        <table class="w-100">
          <caption class="div-text"></caption>
          <thead class="div-text div-title">
            <tr>
              <th scope="row" class="encabezado-1" colspan="3">DÍA 4</th>
            </tr>
            <tr>
              <th scope="row" class="encabezado-1">M</th>
              <th scope="row" class="encabezado-1">T</th>
              <th scope="row" class="encabezado-1">N</th>
            </tr>
          </thead>
          <tbody class="div-text">
            <tr class="px-1 mb-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
            <tr class="px-1">
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
              <td>
                <input type="text" class="form-control form-control-input form-control-sm" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  export default {
    name: 'c-asistencial-ucineo-historia-hojagrafica',
    components: {
        
    },
    data(){
        return {
            //page:null
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
    }

  }
</script>