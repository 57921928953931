<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show.sync="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :scrollable="true"
    >
      <div class="row mx-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                <table id="tb-anular" class="table table-bordered">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 13px">
                        <tr class="text-white text-center align-middle">
                          <th scope="col">#</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">Medicamento</th>
                          <th scope="col">Precio</th>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Estado</th>
                        </tr>
                    </thead>
                    <tbody class="border-main" style="font-size: 11px">
                      <tr v-if="cpData.length === 0" class="text-center">
                        <td colspan="6">Sin registros</td>
                      </tr>
                      <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                        <template>
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.quantity }}</td>
                          <td>{{ item.product_detail.subsubcategorie.name}}</td>
                          <td v-if="posEditPrice !== index" @dblclick="mtdEditPrecies(item, index)">
                            {{ item.price }}
                          </td>
                          <td v-else>
                            <input
                              type="text"
                              class="form-control-sm w-50 text-center custom-input"
                              v-model="newPrice"
                              @keyup.enter="mtdOutFocusEditPrices"
                              @blur="mtdOutFocusEditPrices"
                              @keypress="validateNumberInput"
                            />
                          </td>
                          <td>{{ (item.quantity * item.price).toFixed(2) }}</td>
                          <td>
                            <div v-if="item.state==0">
                              <span
                                class="badge rounded-pill text-bg-danger">No Registrado</span>
                            </div>
                            <div v-else>
                                <span class="badge rounded-pill text-bg-success">Registrado</span>
                            </div>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
      <template #footer>
        <button class="btn btn-primary btn-sm" @click="registerSale" v-if="!allItemsPaid">
          <i class="fas fa-save"/>&nbsp; Guardar
        </button>
      </template>
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>
  
<script>
  import CModal from "../../shared/modals/CModal.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import { mapActions } from "vuex";
  export default {
    name: "c-billing-cModalAddPay",
    components: { 
      SweetAlert,
      CModal,
    },
    data() {
      return {
        swal: null,
        tabla: [],
        detalle: [],
        posEditPrice: null,
        newPrice: null,
        oldPrice: null,
      };
    },
    created () {
    },
    computed: {
      cpData() {
        let show = [];
        this.item.forEach((element) => {
          show.push(element);
        });
        this.tabla = show;
        return show;
      },
      allItemsPaid() {
        return this.tabla.every(item => item.sale_detail && item.sale_detail.state == 1);
      },
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
      saleId: Number,
      patientId: Number,
    },
    methods: {
      ...mapActions(["get", "post"]),
  
      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },
  
      mtdEditPrecies: function (item, pos) {
        
        if (item.sale_detail && item.sale_detail.state == 1) {
          this.$refs.SweetAlert.showWarning("No puedes editar el Precio de algo ya Pagado");
          return;
        }
        
        this.posEditPrice = pos;
        this.oldPrice = this.tabla[pos].price;
        this.newPrice = this.tabla[pos].price;
      },
  
      mtdOutFocusEditPrices: function () {
        if (
            this.oldPrice !== this.newPrice &&
            this.newPrice > 0
        ) {
            Swal.fire({
            icon: "warning",
            title: "¿Desea cambiar este Precio?",
            width: "400px",
            showDenyButton: true,
            denyButtonText: `Rechazar`,
            denyButtonColor: "#bb8a17",
            confirmButtonText: "Permitir",
            confirmButtonColor: "#3085d6",
            }).then((result) => {
            if (result.isConfirmed) {
                this.$emit("mtdCommitEditPrice", this.posEditPrice, this.newPrice);
                this.posEditPrice = null;
                this.oldPrice = null;
                this.newPrice = null;
            } else if (result.isDenied) {
                this.posEditPrice = null;
                this.oldPrice = null;
                this.newPrice = null;
            }
            });
        } else {
            this.posEditPrice = null;
            this.oldPrice = null;
            this.newPrice = null;
        }
      },
  
      registerSale() {
        this.detalle = this.tabla.map(item => ({
          id: item.id,
          pdId: item.product_detail_id,
          quantity: item.quantity,
          price: item.price,
          state: item.state,
          sale_details_id: item.sale_details_id,
        }));
        
        this.$refs.SweetAlert.showConfirmSimple2("","¿Estas seguro de agregar estos Medicamentos?","warning","Aceptar") 
        .then((result) => {
            if (result.value) {
                this.post({
                    url: this.$store.getters.get__url + "/Billing/registerSale",
                    token: this.$store.getters.get__token,
                    params: {
                        campus: this.$store.getters.get__campus,
                        saleId: this.saleId,
                        patientId: this.patientId,
                        detalle: this.detalle,
                    },
                })
                .then((response) => {
                    if(response.data.state == 0){
                    this.$refs.SweetAlert.showSuccess("Agregado Correctamente");
                    this.$emit("mtdUpdate", this.patientId);
                    this.mtdClose();
                    }else{
                        this.$refs.SweetAlert.showError("Error");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            } 
        });
      },
  
      mtdClose() {
        this.tabla = [];
        this.posEditPrice= null;
        this.newPrice= null;
        this.oldPrice= null;
        this.$emit("mtdClose");
      },
    },
  };
</script>
<style scoped>
  .align-middle{
    vertical-align: middle;
  }
  
  .custom-input {
    border: 1px solid #900052;
    border-radius: 4px;
    transition: border-color 0.3s;
  }
  
  .custom-input:focus {
    border-color: #b3006b;
    outline: none;
  }
  
  .badge{
    font-size: 10px;
  }
</style>
  