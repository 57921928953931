<template>
  <div>
    <nav
      id="nav-menu"
      class="fixed-bottom py-0 d-flex justify-content-around mt-2"
      style="background: rgb(162, 162, 162)"
    >
      <div
        :style="mtdGetStyle('kardex')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('kardex')"
      >
        <span :style="mtdGetStyleSpan('kardex')" class="btn w-100">KARDEX</span>
      </div>
      <div
        :style="mtdGetStyle('kardexInterno')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('kardexInterno')"
      >
        <span :style="mtdGetStyleSpan('kardexInterno')" class="btn w-100">KARDEX INTERNO</span>
      </div>
      <div
        :style="mtdGetStyle('stockmin')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('stockmin')"
      >
        <span :style="mtdGetStyleSpan('stockmin')" class="btn w-100">STOCK MIN</span>
      </div>

      <div
        :style="mtdGetStyle('repVerificacion')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
         @click="mtdSendOption('repVerificacion')"
      >
        <span :style="mtdGetStyleSpan('repVerificacion')" class="btn w-100">HIST. DE VERIFICACIONES</span>
      </div>
      <div
        :style="mtdGetStyle('requerimiento')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('requerimiento')"
      >
        <span :style="mtdGetStyleSpan('requerimiento')" class="btn w-100">REQUERIMIENTOS</span>
      </div>
      <!-- <div
        :style="mtdGetStyle('cxc')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      >
        <span :style="mtdGetStyleSpan('cxc')" class="btn w-100">RUTA ESPERADA</span>
      </div> -->
      <!-- <div
        :style="mtdGetStyle('consultas')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      >
        <span :style="mtdGetStyleSpan('consultas')" class="btn w-100"
          >DEVOLUCIONES / CANJES</span
        >
      </div> -->
      <div
        :style="mtdGetStyle('movimiento')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('movimiento')"
      >
        <span :style="mtdGetStyleSpan('movimiento')" class="btn w-100">MOVIMIENTO</span>
      </div>
      <div
        :style="mtdGetStyle('hisMovimiento')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('hisMovimiento')"
      >
        <span :style="mtdGetStyleSpan('hisMovimiento')" class="btn w-100">HISTORIAL DE MOVIMIENTO</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarComponent",
  props: {
    verifBead: {
      type: Boolean,
      default: false,
    },
    pageView: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: null,
    };
  },
  created() {},
  methods: {
    mtdGetStyle: function (option) {
      if (
        option == this.page && this.mtdNoPages()
      )
        return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (
        option == this.page && this.mtdNoPages()
      )
        return "color:white";
      return "";
    },
    mtdSendOption: function (page) {
      this.page = page;
      this.$emit("mtdChangePage", this.page);
    },
    mtdNoPages(){
      return this.pageView != "home" &&
        this.pageView != "compra" &&
        this.pageView != "servicios" &&
        this.pageView != "inventario" &&
        this.pageView != "factura" &&
        this.pageView != "mantenimiento" &&
        this.pageView != "sire" &&
        this.pageView != "verificacion" &&
        this.pageView != "oc";
    }
  },
};
</script>

<style scoped>
#contenedor-menu {
  justify-content: center;
}

#lista_menu_home {
  font-family: Arial;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}

li {
  background-color: #900052;
  float: left;
  text-align: center;
  text-decoration: none;
}

li a {
  color: #ffffff;
  font-weight: bold;
  margin: 0;
}

.nav-footer:hover {
  color: #000000;
  /* background-color:rgb(162, 162, 162); */
  background: #900052;
}

#nav {
  position: absolute;
  float: left;
  bottom: 0px;
}
#img_logo {
  position: absolute;
  float: left;
  left: -5px;
  top: -5px;
}

@media (max-width: 800px) {
  #title-user,
  #title-cash,
  #title,
  #title-date {
    text-align: right !important;
  }
  #img_logo img {
    width: 175px !important;
  }

  li {
    float: none;
  }

  #nav-menu {
    position: inherit;
  }
  /**  */
  #nav-menu {
    flex-direction: column;
  }
}
@media (min-width: 800px) {
  #title-cash,
  #title,
  #title-date {
    text-align: center !important;
  }
  #title-user {
    text-align: right !important;
  }
}
</style>
