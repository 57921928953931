<template>
<div class="  mt-2">
    <div class="row mx-0">
        <div class="col-xl-6 col-lg-12 px-1 fw-bold">
            <div class=" div-title mx-0 px-2  text-start py-1 justify-content-center">
                <span>INGRESA CON :</span>
            </div>
            <div class="row px-1 py-2 text-xs mx-0">
                <div class="col-sm-3">
                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Vi...eriférica
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                    <div class="form-check  d-flex justify-content-between  px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Linea arterial
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Hemovac
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>

                </div>
                <div class="col-sm-4">
                    <div class="form-check  d-flex justify-content-between  px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Catéter Peridural
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>

                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Catéter Central
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Sonda Vertical
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                </div>

                <div class="col-sm-5">
                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Tubo de drenaje toráxico
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>

                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Sonda Nasogástrica drenaje
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                    <div class="form-check  d-flex justify-content-between px-0">
                        <label class="form-check-label" for="flexCheckDefault">
                            Tubo Endrotraqueal
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                </div>
                <div class="col-xl-12 mt-1">
                    <div id="div-otros" class="form-group row ">
                        <label for="otros" class="col-sm-1 text-start  fw-bold">Otros </label>
                        <div class="col-sm-11">
                            <textarea id="otros" class="form-control" rows="2" placeholder="" style="resize: none;" />
                            </div>
                    </div>
                </div>

            </div>

            <div class="table-responsive mt-2">
                    <table >
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th >FECHA</th>
                                <th>HORA</th>
                                <th>PA</th>
                                <th>PAM</th>
                                <th>FC</th>
                                <th>SAT 02%</th>
                                <th>FR</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr v-for="index in 4" :key="index" class="px-1">
                                <td >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            <div class="table-responsive mt-2">
                    <table >
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th colspan="7" > LIQUIDOS ADMINISTRADOS</th>

                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr  class="px-1">
                                <td class="div-title" >
                                   Hora Liquido
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                  TOTAL
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
            </div>
            <div class="table-responsive mt-2">
                    <table >
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th colspan="7" > LIQUIDOS ELIMINADOS</th>

                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr  class="px-1">
                                <td class="div-title" >
                                   Hora Liquido
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                  TOTAL
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>
                            <tr  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
            </div>

        </div>
        <div class="col-xl-6 col-lg-12 px-1 fw-bold text-xs">
            <div class="table-responsive ">
                    <table >
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th  > HORA</th>
                                <th colspan="7" > LIQUIDOS ELIMINADOS</th>

                            </tr>
                        </thead>
                        <tbody class="div-text">

                            <tr v-for="index in 5" :key="index"  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                            </tr>

                        </tbody>
                    </table>
            </div>
            <div class="row mx-0 mt-2">
              <div class="col-sm-6 ps-0   ">
                 <div class="row mx-0  px-0">
                    <div class=" div-title mx-0 px-1  py-1 justify-content-center">
                        <span>ESCALA DE ALDRETE</span>
                    </div>
                    <div class="row mx-0 px-1">
                      <div class="col-sm-4">
                       
                      </div>
                      <div class="col-sm-4">
                        Ingreso
                      </div>
                      <div class="col-sm-4">
                        Alta
                      </div>
                    </div>
                    <div class="row mx-0 px-1  border-bottom border-2">
                      <div class="col-sm-4 text-start px-1">
                        Sp02
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1">
                        Respiración
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1">
                        Movimiento 
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1">
                        Conciencia
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                    </div>
                    <div class="row mx-0 mt-1  px-1 ">
                      <div class="col-sm-4 text-start px-1">
                        TOTAL
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      
                    </div>
                 </div>
              </div>
              <div class="col-sm-6 pe-0 ">
                <div class="row mx-0  px-0">
                    <div class=" div-title mx-0  py-1 justify-content-center">
                        <span>OTROS</span>
                    </div>
                    <div class="row mx-0 px-1 border-bottom border-2 mt-1">
                      <div class="col-sm-4 text-start px-1 px-1 px-1">
                        Sagrado
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1">
                        Deambulación
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1 px-1">
                        Ingesta
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1 px-1">
                        Diuresis
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1 px-1">
                        Dolor
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                    </div>
                    <div class="row mx-0 mt-1  px-1 ">
                      <div class="col-sm-4 text-start px-1 px-1">
                       Bromage
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4 text-start px-1 px-1">
                        Rammsay
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      <div class="col-sm-4">
                        <input type="text" class="form-control form-control-input" autocomplete="off" />
                      </div>
                      
                    </div>
                    
                </div>
              </div>

            </div>
            <div class="table-responsive mt-3">
                    <table class=" w-100" >
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th  > HORA</th>
                                <th > MEDICAMENTO</th>
                                <th > DOSIS</th>
                            </tr>
                        </thead>
                        <tbody class="div-text">

                            <tr v-for="index in 7" :key="index"  class="px-1">
                                <td  >
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>
                                <td>
                                    <input type="text" class="form-control form-control-input rounded-0" />
                                </td>

                            </tr>

                        </tbody>
                    </table>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-asistencial-sop-historia-postanestecia',
    data() {
        return {
            //property: 'value',
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>
