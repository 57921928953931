<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0 px-1 ">
        <div class="row">
            <div class="px-1 d-flex ">
                <div class=" pe-2">
                    <input v-model="observationvalue" class="form-check-input div-text" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <div class="w-100">
                    <table class="w-100">
                        <caption class="div-text"></caption>
                        <thead class="div-text div-title">
                            <tr>
                                <th class="encabezado-rounded ps-2">Observaciones </th>
                            </tr>
                        </thead>
                        <tbody class="div-text">
                            <tr class="px-1 mb-1">
                                <td>
                                    <textarea v-model="rp_replicado.observation" class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="5" readonly></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="row  mx-0 px-0 mb-2 mt-3 text-center">
                <strong class="text-start mb-2 px-0 ">
                    Lista de Medicamentos
                </strong>
                <table class="w-100">
                    <caption class="div-text my-0 py-0"></caption>
                    <thead class=" txt-xs  div-text">
                        <tr>
                            <th scope="col"></th>
                            <th class="encabezado-rounded" scope="col">Rp.</th>
                            <th class="encabezado-rounded w-25">Medicamentos</th>
                            <th class="encabezado-rounded">Presentacion</th>
                            <th class="encabezado-rounded">Dosis</th>
                            <th class="encabezado-rounded">Via</th>
                            <th class="encabezado-rounded">Dosis de la Mezcla</th>
                            <th class="encabezado-rounded">Frecuencia</th>
                            <th class="encabezado-rounded">Intervalo</th>
                            <th class="encabezado-rounded">Duracion</th>
                            <th class="encabezado-rounded">Tipo</th>
                            <th class="encabezado-rounded">Grupo</th>
                            <th class="encabezado-rounded">PRN</th>
                        </tr>
                    </thead>
                    <tbody class="div-text" v-if=" rp_replicado.recipe && rp_replicado.recipe.length > 0 ">
                        <tr v-for="(recipe, index) in rp_replicado.recipe" :key="index" class="px-1">
                            <td class="ps-0">
                                <span> <input @change="mtdGroup(recipe)" @input="mtdGroup(recipe)" v-model="recipe.value" class="form-check-input " type="checkbox" value="" id="flexCheckDefault"></span>
                            </td>
                            <td>
                                <span>{{ index + 1 }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.molecule }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.presentation }}</span>
                            </td>
                            <td>
                                <span>{{recipe.group != 0  ?  recipe.dosis  : recipe.quantity }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.via }}</span>
                            </td>
                            <td>
                                <span>{{recipe.group == 0 ? (recipe.type == 1 ? 'SIN DOSIS': '-') : recipe.quantity}}</span>
                            </td>
                            <td>
                                <span>{{ recipe.quantity }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.frequency }}</span>
                            </td>

                            <td>
                                <span>{{ recipe.duration }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.type == 2 ? 'INSUMO' : 'MEDICAMENTO' }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.group == 0 ? (recipe.type == 1 ? 'SIN GRUPO': '-') : 'GRUPO ' + recipe.group  }}</span>
                            </td>
                            <td>
                                <span v-if="recipe.type == 1 ">
                                    <div class="form-check form-switch">
                                        <input disabled="true" v-model="recipe.prn" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                                        <label class="form-check-label" for="flexSwitchCheckDefault">{{ recipe.prn ? 'SI':'NO' }}</label>
                                    </div>
                                </span>
                                <span v-else>-</span>
                            </td>

                        </tr>
                    </tbody>
                    <tbody class=" text-center" v-else>
                        <tr>
                            <td colspan="12" class=" text-center">
                                <strong> No hay resultados</strong>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <div v-if="insulina_estado && estado_insuline " class=" mx-0 px-2 col-xl-12 mt-3 mb-2 d-flex ">
                <div class=" pe-3">

                </div>
                <table class="w-100">
                    <caption class="div-text py-0"></caption>
                    <thead class="div-text div-title">
                        <tr>
                            <th class="encabezado-rounded ps-2"> Insulina </th>
                        </tr>
                    </thead>
                    <tbody class="div-text">
                        <tr class="px-1 mb-1">
                            <td>
                                <textarea v-model="rp_replicado.insuline" class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="3" readonly></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class=" mx-0 px-1 col-xl-12 mt-3 mb-2 d-flex">
                <div class=" pe-2">
                    <input v-model="recomendationvalue" class="form-check-input div-text" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <table class="w-100">
                    <caption class="div-text py-0"></caption>
                    <thead class="div-text div-title">
                        <tr>
                            <th class="encabezado-rounded ps-2"> Indicaciones y/o Recomendaciones </th>
                        </tr>
                    </thead>
                    <tbody class="div-text">
                        <tr class="px-1 mb-1">
                            <td>
                                <textarea v-model="rp_replicado.recomendation" class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="3" readonly></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class=" mx-0 px-1  col-xl-12 d-flex">
                <div class=" pe-2">
                    <input v-model="dietavalue" class="form-check-input div-text" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <table class="w-100">
                    <caption class="div-text py-0"></caption>
                    <thead class="div-text div-title">
                        <tr>
                            <th class="encabezado-rounded ps-2"> Dieta </th>
                        </tr>
                    </thead>
                    <tbody class="div-text">
                        <tr class="px-1 mb-1">
                            <td>
                                <textarea v-model="rp_replicado.dieta" readonly class="form-control form-control-sm" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button @click="mtdadd" class="btn btn-success btn-sm">
            Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            swal: null,
            data: [],
            observationvalue: true,
            recomendationvalue: true,
            dietavalue: true,
            estado_insuline: true,
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    computed: {

    },
    props: {
        title: String,
        boo_modal: Boolean,
        rp_replicado: {
            type: Object,
            default: () => ({})
        },
        insulina_estado: {
            type: Boolean,
            default: false
        }

    },
    watch: {

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalrp')
        },
        mtdadd: function () {

            let observation = ''
            let recomendation = ''
            let dieta = ''
            let recipe = []
            let insuline = ''
            if (this.observationvalue) {
                observation = this.rp_replicado.observation
            }
            if (this.recomendationvalue) {
                recomendation = this.rp_replicado.recomendation
            }
            if (this.dietavalue) {
                dieta = this.rp_replicado.dieta
            }
            this.rp_replicado.recipe.forEach(element => {
                if (element.value) {
                    recipe.push(element)
                }
            });
            let num = 0
            recipe.forEach(item3 => {
                if (item3.type == 1 && item3.molecule.toLowerCase().includes('insulina')) {
                   num++ 
                }
            })
            if(num != 0){
                insuline = this.rp_replicado.insuline
            }
            let rppantilla = {
                observation: observation,
                recomendation: recomendation,
                dieta: dieta,
                recipe: recipe,
                insuline: insuline
            }

            this.$emit('mtdchangerp', rppantilla)
            this.estado_insuline = true
        },
        mtdGroup: function (payload) {
            let state = payload.value
            if (payload.group != 0) {
                this.rp_replicado.recipe.forEach(item => {
                    if (item.group == payload.group) {
                        item.value = state
                    }
                })
            }
            this.estado_insuline = false
            let recipe = [];
            recipe = this.rp_replicado.recipe
            recipe.forEach(item2 => {
                if (item2.type == 1 && item2.molecule.toLowerCase().includes('insulina') && item2.value) {
                    this.estado_insuline = true
                }
            })

        }

    },
}
</script>
