<template>
  <div>
      <div class="container">      
          <div class="d-flex"> 
              <div  class="btn-home mb-4 mt-3 ms-auto">
                  <button type="button" class="btn btn-outline-main" @click="returnHome">
                      <i class="fas fa-home-lg-alt"></i> Inicio
                  </button>
              </div>
          </div>

          <ul class="nav pb-0 mt-3" id="myTab" role="tablist">
              <li class="nav-item " role="presentation">
                  <button
                    @click="changePage('register')" 
                    :class="{'item-button':true, 'nav-link': true, 'active': page === 'register' }"
                    id="home-tab" 
                    type="button" 
                   >
                     <strong>Registrar Dev.</strong> 
                  </button>
              </li>
              <li class="nav-item" role="presentation">
                  <button
                    @click="changePage('view')" 
                    :class="{'item-button':true, 'nav-link': true, 'active': page === 'view' }"
                    id="home-tab" 
                    type="button" 
                   >
                      <strong>Ver Dev.</strong> 
                  </button>
              </li>
          </ul>
          <div class="tab-content border border-main mb-4 p-2" style="height: 50%;" >
              <br>
              <RegisterVue v-if="page == 'register'" />
              <ViewVue v-if="page == 'view'" />
          </div>
      </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RegisterVue from "./Devoluciones/RegisterDev.vue";
import ViewVue from "./Devoluciones/ReportDev.vue";
export default {

name:"devoluciones",
components: {RegisterVue, ViewVue},
data(){
  return{
      page: "register",
  }
},
created() {
},
methods:{
  ...mapActions(["get", "post"]),
  changePage: function (page) {
    this.page = page;
  },
  returnHome: function () {
    this.$emit("returnHome");
  },
}
}
</script>

<style scoped>
.h4-borde{
  border-radius: 50px;
}
#myTabContent{
  border:#900052 1.9px solid;
}

.item-button{
  background: #A9A9A9 !important;
  border-radius: 10px 10px 0px 0px;   
  border: none !important;
  color:white !important;
  width: 155px;   
}
.item-button1{
  background: #A9A9A9 !important;
  border-radius: 10px 10px 0px 0px;   
  border: none !important;
  color:white !important;
  width: 160px !important;    
}

.nav-link:hover{
  background:#900052 !important
}
.active {
background-color: #900052 !important ;

}
.nav-item{
  width: 159.39px;
}
</style>