<template>
  <div>
    <div class="w-100 h-100">
      <Nav />
    </div>
    <div class="content h-100 mt-3">
      <div class="container">
        <div v-if="dataRoles.length>1" class="d-flex flex-row-reverse pt-2">
            <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackRol">
              <i class="fas fa-recycle"></i> Cambiar de rol
            </button>
        </div>
        <div class="row" id="div_conte2">
          <div id="div_conte" class="col-md-8 mx-auto">
            <div class="bg-secondary text-white bg-opacity-10">
              <div class="text-center py-4 px-5" id="scroll-div">
                <div class="row">
                    <h4 class="text-dark fw-bold">SELECCIONA UNA SEDE</h4>
                </div>
                <div class="row">
                  <button
                    v-for="(item, index) in dataCampus"
                    :key="index"
                    class="div-campus fw-bold col m-2 p-2 text-white"
                    @click="mtdSelectCampus(item.id)"
                  >
                    {{ item.name }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/Asistencial/Nav.vue";

export default {
  name: "v-campus",
  components: {
    Nav,
  },
  props: {
    dataCampus: [],
    dataRoles:[]
  },
  created() {

  },
  methods: {
    mtdSelectCampus: function (id) {
      this.$emit("mtdSelectCampus", id);
    },
    mtdBackRol: function(){
        this.$emit("mtdBackRol");
    }
  },
};
</script>