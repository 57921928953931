<template>
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex">
        <div class="col-md-12 col-lg-12 col-xs-12">
            <span>Tipo de permiso</span>
            <div class="row d-flex justify-content-center">
                <div class="col-xl-4 col-md-4 form-check form-check-inline">
                    <input class="form-check-input" type="radio" checked name="flexRadioDefault" :value="1" v-model="transaction.type" />
                    <label class="form-check-label"> Devolucion </label>
                </div>
                <div class="col-xl-4 col-md-4 form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" :value="2" v-model="transaction.type" />
                    <label class="form-check-label"> Boletear </label>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <div class="align-left my-1 mb-1">
                <label for="">Número de Documento</label>
            </div>
            <div class="input-group input-group-sm px-2">
                <select class="form-control form-control-sm" v-model="transaction.serie">
                    <option v-for="(item, index) in serieVouchers" :key="index" :value="item.serie">
                        {{ item.serie }}
                    </option>
                </select>
                <input type="text" placeholder="Número" v-model="transaction.number" aria-label="Número" class="form-control" @blur="mtdVerificNumber" />
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 text-center mt-2">
            <div class="align-left my-1 mb-1">
                <label for="">Motivo:</label>
            </div>
            <div class="px-2">
                <textarea class="form-control form-control-sm px-2" v-model="transaction.motive" placeholder="Motivo" rows="4"></textarea>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdSendPermission" :disabled="cpDisabled">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

import {
    mapActions
} from "vuex";

export default {
    name: "c-gerencia-modal-anular",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            transaction: {
                type: "1",
                serie: "",
                number: "",
                motive: "",
                id: null
            },
            boo: true,
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        serieVouchers: Array

    },
    watch: {
        title() {
            this.transaction.serie = this.serieVouchers[0].serie;
        },
    },
    computed: {
        cpDisabled() {
            return this.transaction.motive == "" || this.transaction.number == "" || this.transaction.serie == "";

        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdSelectType: function (value) {
            this.transaction.type = value;
        },
        mtdSendPermission: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/anular/store",
                    token: this.$store.getters.get__token,
                    params: this.transaction
                })
                .then((response) => {
                    if(response.statusCode == 200){
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdclosemodal(0);
                    }
                })
                .catch((errors) => {});
        },
        mtdVerificNumber() {
            if (this.transaction.number !== "" && this.transaction.serie !== "") {
                this.post({
                        url: this.$store.getters.get__url + "/Admision/anular/verificVoucher",
                        token: this.$store.getters.get__token,
                        params: this.transaction,
                    })
                    .then((response) => {
                        if (response.data) {
                            this.transaction.id = response.data.id;
                            this.boo = false;
                        } else {
                            this.boo = true;
                            this.transaction.number = "";
                            this.$refs.SweetAlert.showInfo('No se encontró el Voucher');
                        }
                    })
                    .catch((errors) => {
                        //this.$refs.SweetAlert.showError(errors);
                    });
            }
        },
        mtdclosemodal(op) {
            this.transaction = {
                type: "1",
                serie: "",
                number: "",
                motive: "",
                id: null
            };
            this.$emit('mtdclosemodal',op)
        },
        mtdstoredata() {
            this.$emit('mtdstoredata')
        }
    },
};
</script>
