<template>
<div class="px-4 pt-2 pb-5">
    <div class="row">
        <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>

    <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
            <div class="card-header text-white h4 bg-main align-center">
                <strong>PERMISOS DE ANULACIONES</strong>
            </div>
            <div class="card-body w-100">
                <div class="row d-flex">
                    <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text text-white bg-main cursor-pointer" @click="mtdGetData">
                                <i class="fas fa-search"></i>&nbsp;&nbsp;Filtrar
                            </span>
                            <input type="text" class="form-control form-control-sm" placeholder="Buscar serie y N° - motivo - usuario" v-model="search" />
                        </div>
                    </div>

                    <div class="col-md-12 mt-2" id="content-timelinetable">
                        <table id="tb-anular" class="table table-bordered border-main">
                            <caption class="py-0 my-0"></caption>
                            <thead>
                                <tr class="align-center text-white border-white bg-main">
                                    <th style="vertical-align: middle" scope="col">N°</th>
                                    <th style="vertical-align: middle" scope="col">
                                        USUARIO QUE REGISTRA
                                    </th>
                                    <th style="vertical-align: middle" scope="col">FECHA</th>
                                    <th style="vertical-align: middle" scope="col">
                                        COMPROBANTE
                                    </th>
                                    <th style="vertical-align: middle" scope="col">
                                        SERIE Y N°
                                    </th>
                                    <th style="vertical-align: middle" scope="col">MONTO</th>
                                    <th style="vertical-align: middle" scope="col">MOTIVO</th>
                                    <th style="vertical-align: middle" scope="col">
                                        USUARIO AUTORIZA
                                    </th>
                                    <th style="vertical-align: middle" scope="col">ACCIÓN</th>
                                    <th style="vertical-align: middle" scope="col" colspan="1">
                                        ENVIAR
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-xs">
                                <tr v-if="cpData.length === 0">
                                    <td colspan="10" class="text-center">No hay datos disponibles</td>
                                </tr>
                                <tr v-for="(item, index) in cpData" :key="index">
                                    <th style="vertical-align:middle;" scope="row">{{ mtdNumber(index) }}</th>
                                    <td style="vertical-align:middle;">{{ item.user.name }} {{ item.user.last_name }}</td>
                                    <td class="align-center">
                                        {{ item.date | formatDate }} {{ item.hour | formatTime }}
                                    </td>
                                    <td class="align-center">{{ item.voucher.document }}</td>
                                    <td class="align-center">{{ item.voucher.serie }}-{{ item.voucher.number }}</td>
                                    <td class="align-center">
                                        S/ {{ parseFloat(item.voucher.amount).toFixed(2) }}
                                    </td>
                                    <td style="vertical-align:middle;">{{ item.motive_cancel }}</td>
                                    <td style="vertical-align:middle;">
                                        {{
                        item.user_autoriza_id
                          ? item.user_autoriza.name +
                            " " +
                            item.user_autoriza.last_name
                          : "-"
                      }}
                                    </td>
                                    <td class="align-center" v-html="mtdGetDays(item)"></td>
                                    <td class="align-center">
                                        <a href="javascript:void(0)" v-if="item.state == 2" onclick="" id="btn-autorizar-anular">
                                            <i class="fas fa-check-circle text-success"></i>
                                        </a>
                                        <a href="#" v-if="item.state == 3" id="btn-error-anular">
                                            <i class="fas fa-times-circle text-danger"></i>
                                        </a>
                                        <a href="javascript:void(0)" v-if="item.state == 1" id="btn-pediente-anular" @click="mtdModalProcess(item, index)">
                                            <i class="far fa-circle text-warning"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--paginate-->
                    <div class="d-flex justify-content-center" style="align-items: center">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPAge">Anterior</a>
                                </li>
                                <li class="page-item" v-for="(item, index) in stepPagination" :key="index">
                                    <a :class="
                        selectPage == item ? 'page-link active' : 'page-link'
                      " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="addPAge">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <cModalProccessAnularVue :title="modalProccess.title" :boo_modal="modalProccess.modal_form" :data="modalProccess.data" @mtdclosemodal="closeModalProccess" @mtdSendPermission="mtdSendPermission" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from 'vuex'
import moment from "moment";
import cModalProccessAnularVue from "../modal/cModalProccessAnular.vue";

export default {
    name: "c-gerencia-anulaciones",
    components: {
        cModalProccessAnularVue,
        SweetAlert
    },
    comments: {
        SweetAlert
    },
    data() {
        return {
            //page: 'permiso_anulacion',
            hoy: null,
            filter: {
                begindate: null,
                enddate: null,
            },
            swal: null,
            data: [],
            dataShow: [],
            noRecordsMessage: "",
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            pos: null,
            modalProccess: {
                title: "",
                modal_form: false,
                data: {
                    type: "",
                    motive: "",
                    days: 0,
                },
            }
        }
    },
    computed: {
        cpData() {
            // Filtrar los datos según la búsqueda
            let filteredData = this.data.filter((element) => {
                let doc = element.voucher.document.toString().toUpperCase();
                let name = element.user.name.toString().toUpperCase();
                let serie = element.voucher.serie.toString().toUpperCase();
                let number = element.voucher.number.toString().toUpperCase();
                let query = this.search.toString().toUpperCase();

                return (
                    doc.includes(query) ||
                    name.includes(query) ||
                    serie.includes(query) ||
                    number.includes(query)
                );
            });

            // Paginar los datos
            let start = (this.selectPage - 1) * 5;
            let end = this.selectPage * 5;
            return filteredData.slice(start, end);
        },
        //no se está utilizando ?
        cpTotalFiltro() {
            let tot = 0;
            this.dataShow.forEach((element) => {
                tot = parseFloat(tot) + parseFloat(element.amount);
            });
            return parseFloat(tot).toFixed(2);
        },
    },
    created() {
        moment.locale("es");
        this.filter.enddate =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.begindate = this.filter.enddate;
        this.hoy = this.filter.enddate;
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            this.selectPage = 1;
            this.stepPagination = [];
            this.data = [];
            this.dataShow = [];
            this.post({
                    url: this.$store.getters.get__url + "/Gerencia/anular",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.data = response.data.data;
                    /** paginación */
                    let cantidad = parseInt(this.data.length / 5);
                    let residuo = parseInt(this.data.length % 5);
                    cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                    for (let index = 0; index < cantidad; index++) {
                        this.stepPagination.push(index + 1);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdModalProcess: function (item, pos) {
            this.modalProccess.title = "PROCESAR ANULACIÓN";
            this.modalProccess.modal_form = true;
            this.modalProccess.data.id = item.id;
            this.modalProccess.data.type = item.type;
            this.pos = pos;
            this.modalProccess.data.days = moment(this.hoy).diff(
                moment(item.voucher.date),
                "days"
            );
        },
        closeModalProccess: function () {
            this.pos = null;
            this.modalProccess = {
                title: "",
                modal_form: false,
                data: {
                    id: null,
                    type: "",
                    motive: "",
                },
            };
        },
        mtdSendPermission: function (payload) {
            this.modalProccess.data.type = payload.type;
            this.modalProccess.data.motive = payload.motive;
            // console.log(this.modalProccess);
            this.post({
                    url: this.$store.getters.get__url + "/Gerencia/anular/store",
                    token: this.$store.getters.get__token,
                    params: this.modalProccess,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        if (this.modalProccess.data.days > 3) {
                            let res = response.data.sunat;
                            //console.log(response.data)

                            if (res && res.json && res.json.enlace_del_pdf_ticket) {
                                window.open(res.json.enlace_del_pdf_ticket, '_blank');
                            }
                        }
                        this.closeModalProccess();
                        this.mtdGetData();

                        this.$refs.SweetAlert.showSuccess(response.message);

                    } else {

                        this.$refs.SweetAlert.showError(response.message);

                    }
                })

                .catch((errors) => {
                    console.log(errors);
                });
        },

        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page;
        },
        backPAge: function () {
            this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
        },
        addPAge: function () {
            this.selectPage =
                this.selectPage == this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        /** diferencia de fecha */
        mtdGetDays: function (data) {
            if (data.state == 2) {
                return "-";
            } else {
                let dif = moment(this.hoy).diff(moment(data.voucher.date), "days");
                if (dif > 3) {
                    return '<span class="badge text-bg-info">Anular - Nota de Credito</span>';
                } else {
                    return '<span class="badge text-bg-info">Anular</span>';
                }
            }
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
