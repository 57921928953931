<template>
    <div>
        <div class="px-3 py-2">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>ASIGNACIONES</strong>
                    </div>
                    <div class="card-body w-100">
                      <div class="row">
                        <div :class="{'with-line': cpData.length > 0}" class="col-xl-6 col-lg-12 col-md-12 col-sm-12 py-2">
                          <div class="col-md-12 py-2">
                              <div class="row d-flex mx-0 mb-1">
                                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 px-1 mb-2">
                                      <div class="input-group input-group-sm w-100  ">
                                          <span class="input-group-text ">Desde</span>
                                          <input type="date"
                                          @input="mtdGetData()" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init"/>
                                      </div>
                                  </div>
                                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 px-1 mb-2">
                                      <div class="input-group input-group-sm  w-100 ">
                                          <span class="input-group-text ">Hasta</span>
                                          <input  @input="mtdGetData()"
                                          type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end"/>
                                      </div>
                                  </div>
                                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 btn-home px-1 py-xl-0 my-xl-0 mb-2">
                                      <button
                                          type="button"
                                          class="btn btn-bm-noradius w-100 btn-sm"
                                          @click="mtdModalShowRe(0,null)"
                                      >
                                          <i class="fas fa-plus"></i> Nueva
                                      </button>
                                  </div>
                              </div>
                              <div class="row d-flex mx-0">
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1 mb-2">
                                      <div class="input-group input-group">
                                          <span class="input-group-text text-white bg-main"
                                          ><i class="fas fa-search"></i
                                          ></span>
                                          <input
                                          @keyup="calculateData(7)"
                                          v-model="search"
                                          type="text"
                                          class="form-control form-control"
                                          placeholder="Buscar Tarea"
                                          />
                                      </div>
                                  </div>
                                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="d-flex justify-content-center">
                                      <v-select
                                        id="serviceSelect"
                                        class="text-dark flex-grow-1"
                                        label="name"
                                        :options="priorityOptions"
                                        placeholder="- Buscar Prioridad -"
                                        v-model="selectedPriority"
                                        @input="mtdSearchFilter"
                                      />
                                    </div>
                                  </div>
                              </div>
                          </div>
  
                          <div class="col-md-12" id="content-timelinetable">
                            <ul class="list-group">
                              <li class="list-group-item d-flex justify-content-between align-items-center" v-if="cpData.length === 0">
                               Sin Registros
                              </li>
                              <li class="list-group-item d-flex justify-content-between align-items-center task-item table-container font-size"
                                  v-for="(item, index) in cpData"
                                  :key="index"
                                  :class="{ 'selected': selectedTask === item.id }"
                                  @click="mtdModalShow(1, item, item.id)"
                                  style="cursor: pointer;"> 
                                <div>
                                  <small>{{ item.date_registration | formatDateTime }}</small>
                                  <p class="mb-1">{{ item.name }}</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span v-if="item.status == 0" class="badge bg-info rounded-pill me-1">ASIGNADO</span>
                                    <span v-if="item.priority == 1" class="badge bg-danger rounded-pill">ALTA</span>
                                    <span v-else-if="item.priority == 2" class="badge bg-warning rounded-pill text-black">MEDIA</span>
                                    <span v-else-if="item.priority == 3" class="badge bg-success rounded-pill">BAJA</span>
                                </div>
                              </li>
                            </ul>
                          </div>
  
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                              <!-- paginado -->
                              <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                  <nav aria-label="Page navigation example">
                                      <ul class="pagination mb-1">
                                          <li class="page-item">
                                              <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                          </li>
                                          <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                              <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                          </li>
                                          <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                              <span class="page-link">...</span>
                                          </li>
  
                                          <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                              <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                              </a>
                                          </li>
                                          <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                              <span class="page-link">...</span>
                                          </li>
                                          <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                              <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                              </a>
                                          </li>
                                          <li class="page-item">
                                              <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                          </li>
                                      </ul>
                                  </nav>
                              </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 py-2">
                          <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1  mb-2">
                                <div class="input-group input-group">
                                  <span class="input-group-text text-white bg-main">
                                    <i class="fas fa-search"></i>
                                  </span>
                                  <input
                                    @keyup="calculateDataAsign(6)"
                                    v-model="searchAsign"
                                    type="text"
                                    class="form-control form-control"
                                    placeholder="Buscar Responsable"
                                  />
                                </div>
                              </div>
                              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                <div class="d-flex justify-content-center">
                                  <v-select
                                    id="serviceSelect"
                                    class="text-dark flex-grow-1"
                                    label="name"
                                    :options="statusOptions"
                                    placeholder="- Buscar Estado -"
                                    v-model="selectedStatus"
                                    @input="mtdSearchFilterAsign"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
  
                          <div class="col-md-12" id="content-timelinetable">
                            <div class="list-group">
                              <div
                                class="list-group-item list-group-item-action flex-column align-items-start"
                                v-if="cpDataAsign.length === 0"
                              >
                                <div class="d-flex w-100 justify-content-center">
                                  <p class="mb-1">Sin Responsables Asignados</p>
                                </div>
                              </div>
                              <a
                                v-for="(item, index) in cpDataAsign"
                                :key="index"
                                :class="{ 'selected': selectedTask === item.id }"
                                @click="mtdModalAsign(item.id)"
                                href="#"
                                class="list-group-item list-group-item-action flex-column align-items-start task-item table-container"
                              >
                                <div class="d-flex w-100 justify-content-between z">
                                  <h6 class="mb-1 text-title">{{ item.tasks_name }}</h6>
                                  <div class="d-flex align-items-center">
                                    <i v-if="item.date_approval" class="fas fa-check-circle check-icon me-1 spams"></i>
                                    <span :class="getBadgeClass(item.status)" class="badge badge-pill spams" style="font-size: 10px">{{ getStatusText(item.status) }}</span>
                                  </div>
                                </div>
                                <h6 class="mb-1"  style="font-size: 10px">SUPERVISOR: {{ item.subSupervisor_name ? item.subSupervisor_name : '-' }}</h6>
                                <div v-if="item.date !== null">
                                  <h6 v-if="item.date.date_progress" class="mb-1" style="font-size: 10px">
                                    FECHA: {{ item.date.date_progress | formatDateTime }}
                                  </h6>
                                  <h6 v-if="item.date.date_finished" class="mb-1" style="font-size: 10px">
                                    FECHA: {{ item.date.date_finished | formatDateTime }}
                                  </h6>
                                  <h6 v-if="item.date.date_approval" class="mb-1" style="font-size: 10px">
                                    FECHA: {{ item.date.date_approval | formatDateTime }}
                                  </h6>
                                </div>
                                <p class="mb-1">
                                  <span v-for="(responsable, i) in item.supervised_ids" :key="i" class="responsable-name" style="font-size: 10px">
                                    {{ responsable }}
                                  </span>
                                </p>
                              </a>
                            </div>
                          </div>
  
                          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 text-center">
                              <!-- paginado -->
                              <div v-if="cpDataAsign.length > 0" class="d-flex justify-content-center mb-2">
                                  <nav aria-label="Page navigation example">
                                      <ul class="pagination mb-1">
                                          <li class="page-item">
                                              <a class="page-link page" href="javascript:void(0)" @click="backPageAsign">Anterior</a>
                                          </li>
                                          <li v-if="limitedStepPaginationAsign[0] !== 1" class="page-item">
                                              <a :class="selectPageAsign === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPageAsign(1)">1</a>
                                          </li>
                                          <li v-if="limitedStepPaginationAsign[0] > 2" class="page-item disabled">
                                              <span class="page-link">...</span>
                                          </li>
  
                                          <li v-for="(item, index) in limitedStepPaginationAsign" :key="index" class="page-item">
                                              <a :class="selectPageAsign === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPageAsign(item)">{{ item }}
                                              </a>
                                          </li>
                                          <li v-if=" limitedStepPaginationAsign[limitedStepPaginationAsign.length - 1] <stepPaginationAsign.length - 1" class="page-item disabled">
                                              <span class="page-link">...</span>
                                          </li>
                                          <li v-if=" limitedStepPaginationAsign[limitedStepPaginationAsign.length - 1] !== stepPaginationAsign.length" class="page-item">
                                              <a :class="selectPageAsign === limitedStepPaginationAsign.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPageAsign(stepPaginationAsign.length)">{{ stepPaginationAsign.length }}
                                              </a>
                                          </li>
                                          <li class="page-item">
                                              <a class="page-link page" href="javascript:void(0)" @click="nextPageAsign">Siguiente</a>
                                          </li>
                                      </ul>
                                  </nav>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
  
        </div>
        <cModalTasksVue :num="modal.num" :payload="modal.payload" :title="modal.title" :idTask="modal.idTask" :subSupervisor="subSupervisor" :user="modal.user"
        :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" @openArchivos="openListPdf"/>
        <cModalTasksReVue :num="modalRe.num" :payload="modalRe.payload" :title="modalRe.title"
        :boo_modal="modalRe.modal_form" @closeModal="closeModalRe" @Datadetail="Datadetail" @openArchivos="openListPdf"/>
        <cModalTasksPdfVue :payload="modalPdf.payload" :title="modalPdf.title" :idTask="modalPdf.idTask"
        :boo_modal="modalPdf.modal_form" @closeModalPdf="closeModalPdf" @registerFile="registerFile" @deleteFile="deleteFile"/>
        <cModalAsignVue :payload="modalAsign.payload" :title="modalAsign.title" :idAsign="modalAsign.idAsign" :status="modalAsign.status" :date_approval="modalAsign.date_approval"
        :boo_modal="modalAsign.modal_form" @closeModalAsign="closeModalAsign"  @openAd="openAd"  @aproRe="aproRe"  @openPer="openPer"/>
  
        <cModalAsignFileVue :payload="modalAsignFile.payload" :title="modalAsignFile.title"
        :boo_modal="modalAsignFile.modal_form" @closeModalAsignFile="closeModalAsignFile" :idAd="modalAsignFile.idAd"/>
        <cModalAsignCommentVue :payload="modalAsignComment.payload" :title="modalAsignComment.title" :idAd="modalAsignComment.idAd" :apro="modalAsignComment.apro"
        :boo_modal="modalAsignComment.modal_form" @closeModalAsignComment="closeModalAsignComment" @registerComen="registerComen" @deleteComen="deleteComen"/>
        <cModalPerVue :title="modalPer.title" :id="modalPer.id" :idAsign="modalPer.idAsign" :boo_modal="modalPer.modal_form" :payload="modalPer.payload" @closeModalPer="closeModalPer" @rePer="rePer"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
  
<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import cModalTasksVue from './../modals/cModalTasks.vue';
  import cModalTasksReVue from './../modals/cModalTasksRe.vue';
  import cModalTasksPdfVue from './../modals/cModalTasksPdf.vue';
  import cModalAsignVue from './../modals/cModalAsign.vue';
  
  import cModalAsignFileVue from './../modals/cModalAsignFile.vue';
  import cModalAsignCommentVue from './../modals/cModalAsignComment.vue';
  import cModalPerVue from './../modals/cModalPer.vue';
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  export default {
    name: "c-pages-asignaciones",
    components: {
      cModalTasksVue,
      cModalTasksReVue,
      cModalTasksPdfVue,
      cModalAsignVue,
      cModalAsignFileVue,
      cModalAsignCommentVue,
      cModalPerVue,
      SweetAlert,
    },
    data() {
        return {
            swal: null,
            subSupervisor: [],
            selectedTask: null,
            selectedPriority: "",
            priorityOptions: [
              'BAJA',
             'MEDIA',
              'ALTA'
            ],
            selectedStatus: "",
            statusOptions: [
              'POR HACER',
             'EN PROCESO',
              'FINALIZADO'
            ],
            search:"",
            searchAsign:"",
            dataShow: [],
            dataShowAsign: [],
            dataTask: [],
            dataAsign: [],
  
            modalPer: {
                title: '',
                modal_form: false,
                id: null,
                idAsign: null,
                payload: {},
            },
  
            modalAsignFile: {
                title: '',
                modal_form: false,
                payload: [],
                idAd: null
            },
            modalAsignComment: {
                title: '',
                modal_form: false,
                payload: [],
                idAd: null,
                apro: null
            },
            modalAsign: {
                title: '',
                modal_form: false,
                payload: [],
                idAsign: null,
                status: null,
                date_approval: null
            },
            modalPdf: {
                title: '',
                modal_form: false,
                payload: [],
                idTask: null
            },
            modalRe: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
                idTask: null,
                user: null,
            },
            filter: {
                init: null,
                end: null,
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            //PagAsign
            pageAsign: 0,
            selectPageAsign: 1,
            stepPaginationAsign: [],
            totalPagesToShowAsign: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
  
        selectPageAsign() {
            this.calculateDataAsign(6);
        },
  
    },
    computed: {
        showSupervisorText() {
          const userId = this.$store.getters.get__user.id;
          return userId === 1 || userId === 7;
        },
  
        cpData() {
            return this.dataShow;
        },
        cpDataAsign() {
            return this.dataShowAsign;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;
  
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
  
                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;
  
                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }
  
            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        limitedStepPaginationAsign() {
            const totalPages = this.stepPaginationAsign.length;
            const currentPage = this.selectPageAsign;
  
            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShowAsign) {
                const halfPagesToShow = Math.floor(this.totalPagesToShowAsign / 2);
  
                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;
  
                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShowAsign + 1;
                    }
                } else {
                    endPage = this.totalPagesToShowAsign;
                }
            }
  
            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        moment.locale("es");
        this.filter.end =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        this.mtdGetData();
        this.mtdGetAsign();
    },
    methods: {
        ...mapActions(["get", "post"]),
  
        //Traer Data
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Assignments/tasks",
                token: this.$store.getters.get__token,
                params: {
                    init: this.filter.init,
                    end: this.filter.end,
                },
            })
            .then((response) => {
                this.dataTask = response.data.data;
                this.subSupervisor = response.data.subSupervisor;
                this.calculateData(7);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        mtdGetAsign: function () {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/index",
                token: this.$store.getters.get__token,
            })
          .then((response) => {
              this.dataAsign = response.data;
              this.calculateDataAsign(6);
          })
          .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
          });
        },
  
        mtdSearchFilter() {
            this.calculateData(7);
        },
  
        mtdSearchFilterAsign() {
            this.calculateDataAsign(6);
        },
  
        calculateData(items) {
            if (!this.dataTask || !this.dataTask.length) {
                return (this.dataShow = []);
            }
            //let filteredData = this.dataTask;
            this.dataShow = [];
            let filteredData = this.dataTask.slice();
            let indexInitial = this.selectPage;
            if (this.search.length >= 3 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                //filteredData = this.dataTask.filter((element) => {
                filteredData = filteredData.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }
  
            /*Filtro por Prioridad*/
            const priorityMap = {
                'alta': 1,
                'media': 2,
                'baja': 3
            };
            if (this.selectedPriority) {
                const priorityValue = priorityMap[this.selectedPriority.toLowerCase()];
                filteredData = filteredData.filter((element) => {
                    return element.priority === priorityValue;
                });
            }
  
            let totalPages = Math.ceil(filteredData.length / items);
            if (totalPages < indexInitial) {
                indexInitial = totalPages;
            }
  
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
  
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.currentPage = indexInitial;
            this.totalPages = totalPages;
            this.totalItems = filteredData.length;
        },
  
        calculateDataAsign(items) {
            if (!this.dataAsign || !this.dataAsign.length) {
                return (this.dataShowAsign = []);
            }
            //let filteredData = this.dataAsign;
            this.dataShowAsign = [];
            let filteredData = this.dataAsign.slice();
            let indexInitial = this.selectPageAsign;
            if (this.searchAsign.length >= 3 && this.searchAsign != "") {
                let query = this.searchAsign.toString().toUpperCase();
                //filteredData = this.dataAsign.filter((element) => {
                filteredData = filteredData.filter((element) => {
                    //let name = element.tasks_name.toString().toUpperCase();
                    //return name.includes(query);
                    let supervisedIds = element.supervised_ids.map(id => id.toString().toUpperCase());
                    return supervisedIds.some(id => id.includes(query));
                });
                indexInitial = 1;
            }
  
            /*Filtro por Estado*/
            const priorityMap = {
                'por hacer': 1,
                'en proceso': 2,
                'finalizado': 3
            };
            if (this.selectedStatus) {
                const priorityValue = priorityMap[this.selectedStatus.toLowerCase()];
                filteredData = filteredData.filter((element) => {
                    return element.status === priorityValue;
                });
            }
  
            let totalPages = Math.ceil(filteredData.length / items);
            if (totalPages < indexInitial) {
                indexInitial = totalPages;
            }
            this.stepPaginationAsign = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
  
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShowAsign = filteredData.slice(startIndex, endIndex + 1);
            this.currentPage = indexInitial;
            this.totalPages = totalPages;
            this.totalItems = filteredData.length;
            //this.dataShowAsign = filteredData.slice(startIndex, endIndex + 1);
            //this.countAsign = this.countAsign + 1;
        },
  
        mtdModalShowRe: function (num, payload) {
            this.modalRe.title = (num == 0) ? 'Registrar Tarea' : 'Editar Tarea';
            this.modalRe.modal_form = true;
            this.modalRe.num = num;
            this.modalRe.payload = payload;
        },
  
        mtdModalShow: function (num, payload, id) {
            const capitalizeFirstLetter = (string) => {
              return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
            };
            const truncateString = (string, maxLength) => {
              if (string.length > maxLength) {
                  return string.slice(0, maxLength) + '...';
              }
              return string;
            };
            const maxLength = 20;
            this.selectedTask = id;
            this.modal.title = (num == 0) ? 'Registrar Tarea' : 'Tarea: ' + capitalizeFirstLetter(truncateString(payload.name, maxLength));
            this.modal.num = num;
            this.modal.payload = payload;
            this.modal.idTask = id;
            this.modal.user = this.$store.getters.get__user.id;
            this.modal.modal_form = true;
        },
  
        closeModal: function () {
            this.selectedTask = null;
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            };
        },
  
        closeModalRe: function () {
            this.modalRe = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
  
        Datadetail: function (payload_detail, num_detail) {
          if (num_detail == 0) {
              payload_detail.action = 'c';
              this.addDatadetail(payload_detail, num_detail);
          } else if (num_detail == 1) {
              payload_detail.action = 'u';
              this.addDatadetail(payload_detail, num_detail);
          } else {
              let data = {
                  action : 'd',
                  id : payload_detail
              }
              this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de Eliminar esta Tarea?","warning","Eliminar") 
                .then((result) => {
                    if (result.value) {
                        this.addDatadetail(data, num_detail);
                    }
                });
          }
        },
  
        addDatadetail: function (payload_detail, num_detail) {
          let formData = new FormData();
          //validacion
          let subSupervisorId = payload_detail.subSupervisor_id;
          if (subSupervisorId === null || subSupervisorId === undefined || subSupervisorId === '') {
              subSupervisorId = 0;
          }
          formData.append("subSupervisor_id", subSupervisorId);
          //fin
          formData.append("action", payload_detail.action);
          formData.append("id", payload_detail.id);
          formData.append("name", payload_detail.name);
          formData.append("description", payload_detail.description);
          formData.append("priority", payload_detail.priority);
          formData.append("file", payload_detail.file);
          formData.append("idAsig", payload_detail.idAsig);
          formData.append("date_expiration", payload_detail.date_expiration);
          formData.append("days_extension", payload_detail.days_extension);
          formData.append("responsables", JSON.stringify(payload_detail.responsables));
          this.post({
              url: this.$store.getters.get__url + "/Assignments/crudTasks",
              token: this.$store.getters.get__token,
              params: formData,
          })
          .then(response => {
              if (num_detail == 2) {
                  this.$refs.SweetAlert.showSuccess("El registro ha sido Eliminado");
                  this.mtdGetData();
              } else {
                    this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                  this.closeModal()
                  this.mtdGetData();
                  this.mtdGetAsign();
              }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        //Modal Tareas-Archivos
        openListPdf: function (id) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/getListPdf/"  + id,
                token: this.$store.getters.get__token,
            })
          .then((response) => {
              this.modalPdf.title = 'Listado de Archivos';
              this.modalPdf.payload = response.data;
              this.modalPdf.idTask = id;
              this.modalPdf.modal_form = true;
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
        },
  
        closeModalPdf: function () {
            this.modalPdf = {
                title: '',
                modal_form: false,
                payload: [],
                idTask: null
            }
        },
  
        registerFile: function (payload_detail, idTask) {
          let formData = new FormData();
          formData.append("file", payload_detail.file);
          formData.append("idTask", idTask);
          this.post({
              url: this.$store.getters.get__url + "/Assignments/registerFile",
              token: this.$store.getters.get__token,
              params: formData,
          })
          .then(response => {
            if(response.data.state == 0){
                this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                this.openListPdf(idTask);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        deleteFile: function (id, idTask) {
          this.get({
              url: this.$store.getters.get__url + "/Assignments/deleteFile/" + id,
              token: this.$store.getters.get__token,
          })
          .then(response => {
            if(response.data.state == 0){
              this.$refs.SweetAlert.showSuccess("Eliminación Exitosa");
              this.openListPdf(idTask);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        //Asignaciones
        mtdModalAsign: function(id) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/asignAdvance/"  + id,
                token: this.$store.getters.get__token,
            })
          .then((response) => {
              const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
              };
              const truncateString = (string, maxLength) => {
                if (string.length > maxLength) {
                    return string.slice(0, maxLength) + '...';
                }
                return string;
              };
              const maxLength = 17;
              const tituloTarea = response.task.tasks_name;
              this.selectedTask = id;
              this.modalAsign.title = 'Avanzes de Asignación de Tarea: ' + capitalizeFirstLetter(truncateString(tituloTarea, maxLength));
              //this.modalAsign.title = 'Avanzes de Asignación';
              this.modalAsign.payload = response.data;
              this.modalAsign.idAsign = id;
              this.modalAsign.status = response.task.status;
              this.modalAsign.date_approval = response.task.date_approval;
              this.modalAsign.modal_form = true;
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
        },
  
        closeModalAsign: function () {
          this.selectedTask = null;
            this.modalAsign = {
                title: '',
                modal_form: false,
                payload: [],
                idAsign: null,
                status: null,
            }
        },
  
        aproRe: function(idAsign, action) {
          /*0 (Aprobar) / 1 (Rechazar)*/
          this.post({
              url: this.$store.getters.get__url + "/Assignments/aproReAsign",
              token: this.$store.getters.get__token,
              params: {
                idAsign: idAsign,
                action : action,
              },
          })
          .then(response => {
            if(response.data.state == 0){
              this.$refs.SweetAlert.showSuccess("Asinacion Aprobada");
            }else{
              this.$refs.SweetAlert.showSuccess("Asinacion Rechazada");
            }
            this.mtdGetAsign();
            this.closeModalAsign();
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        //Asignaciones Archivos y Mensajes
        openAd: function (idAd , type, apro) {
          this.get({
                url: this.$store.getters.get__url + "/Assignments/advanceFileMen/" + idAd,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
              if(type == 0){
                this.modalAsignFile.title = 'Archivos';
                this.modalAsignFile.modal_form = true;
                this.modalAsignFile.payload = response.data.advance_adjunct;
                this.modalAsignFile.idAd = idAd;
              }else if(type == 1){
                this.modalAsignComment.title = 'Comentarios de Asignación';
                this.modalAsignComment.modal_form = true;
                this.modalAsignComment.payload = response.data.advance_comment;
                this.modalAsignComment.idAd = idAd;
                this.modalAsignComment.apro = apro;
              }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
  
        closeModalAsignFile: function () {
            this.modalAsignFile = {
                title: '',
                modal_form: false,
                payload: [],
            }
        },
        closeModalAsignComment: function () {
            this.modalAsignComment = {
                title: '',
                modal_form: false,
                payload: [],
            }
        },
  
        registerComen: function (payload_detail, idAd, apro) {
          this.post({
              url: this.$store.getters.get__url + "/Assignments/registerComen",
              token: this.$store.getters.get__token,
              params: {
                description: payload_detail.description,
                idAd : idAd,
              },
          })
          .then(response => {
            if(response.data.state == 0){
                this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                this.openAd(idAd, 1, apro);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        deleteComen: function (id, idAd, apro) {
          this.get({
              url: this.$store.getters.get__url + "/Assignments/deleteComenAd/" + id,
              token: this.$store.getters.get__token,
          })
          .then(response => {
            if(response.data.state == 0){
              this.$refs.SweetAlert.showSuccess("Eliminación Exitosa");
              this.openAd(idAd, 1, apro);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        //Porcentaje
        openPer: function (id, idAsign, item) {
          this.modalPer.title = 'Agregar Porcentaje';
          this.modalPer.modal_form = true;
          this.modalPer.id = id;
          this.modalPer.idAsign = idAsign;
          this.modalPer.payload = item;
        },
  
        closeModalPer: function () {
            this.modalPer = {
                title: '',
                modal_form: false,
                id: null,
                idAsign: null,
                payload: {},
            };
        },
  
        rePer: function (payload_detail, id, idAsign) {
          this.post({
              url: this.$store.getters.get__url + "/Assignments/registerPercentage",
              token: this.$store.getters.get__token,
              params: {
                percentage: payload_detail.percentage,
                id : id,
              },
          })
          .then(response => {
            if(response.data.state == 0){
              this.$refs.SweetAlert.showSuccess("Porcentaje Actualizado");
              this.closeModalPer();
              this.mtdModalAsign(idAsign);
            }else{
                this.$refs.SweetAlert.showError("Error");
            }
          })
          .catch(errors => {
            this.$refs.SweetAlert.showError(errors);
          })
        },
  
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
  
        //Paginacion Asign
        backPageAsign() {
            this.selectPageAsign = this.selectPageAsign > 1 ? this.selectPageAsign - 1 : 1;
        },
        nextPageAsign() {
            this.selectPageAsign =
                this.selectPageAsign < this.stepPaginationAsign.length ?
                this.selectPageAsign + 1 :
                this.stepPaginationAsign.length;
        },
        selectedPageAsign(page) {
            this.selectPageAsign = page;
        },
  
        mtdNumber: function (index) {
            return index + 1 + (this.selectPage - 1) * 10;
        },
        mtdNumberAsign: function (index) {
            return index + 1 + (this.selectPageAsign - 1) * 7;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        getStatusText(status) {
          switch (status) {
            case 1:
              return 'POR HACER';
            case 2:
              return 'EN PROCESO';
            case 3:
              return 'FINALIZADO';
            default:
              return 'ESTADO DESCONOCIDO';
          }
        },
        getBadgeClass(status) {
          switch (status) {
            case 1:
              return 'badge-danger';
            case 2:
              return 'badge-warning';
            case 3:
              return 'badge-success';
            default:
              return 'badge-secondary';
          }
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
  }
</script>
<style>
  .table-th-acciones{
    width:10% !important;
  }
  .table-th-number{
    width:5% !important;
  }
  .table-th-date{
    width:20% !important;
  }
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
  }
  .acciones {
      background-color: #717171;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  
  .acciones1 {
      background-color: #195bf6;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  
  .acciones-2 {
      background-color: #e50303;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  .acciones-3 {
      background-color: #b49f03;
      border-radius: 10px;
      width: 15px;
      font-size: 12px;
  }
  .header-item {
    background-color: #900052 !important;
    border-color: #900052 !important;
  }
  
  .task-item.selected {
    background-color: #ffebf7;
  }
  .task-item:hover {
    background-color: #ffebf7;
  }
  
  .task-name {
    cursor: pointer;
  }
  .font-size{
    font-size: 13px;
  }
  .responsable-name {
    display: block;
  }
  .text-title{
    background-color: #EB5D77;
    color: white;
    font-size: 11px;
    padding: 0.3rem;
    font-weight: bold;
    border-radius: 0.50rem;
  }
  .badge-danger {
    background-color: #fa0821;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-warning {
    background-color: #f7ba03;
    color: #000000;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-success {
    background-color: #0bc837;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .form-check-label{
    white-space: nowrap;
    font-size: 12px
  }
  .spams{
    font-size: 14px;
  }
  @media (min-width: 1200px){
  .with-line {
    position: relative;
  }
  .with-line::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #900052;
  }
  }
</style>
  