<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-1">
        <div class=" row col-xl-12  mx-0 px-0 ">
            <div class="mt-4">
                <div class="row mx-0 px-0 mt-2">
                    <div class="col-xl-6 px-1">
                        <h6>Servicios no Selecionados</h6>
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main text-xxs">
                                    <tr class="text-white">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">SERVICIO ESPECIFICO</th>

                                        <th scope="col" class="align-middle">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(item, index) in servicios_no_seleccionado" :key="index">
                                        <td class="align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="align-middle">
                                            {{ item.name }}
                                        </td>

                                        <td class="align-middle text-center" @click="mtdnoselecionado(item)">
                                            <button type="button" class=" btn btn-success btn-sm px-1 py-0 ">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!servicios_no_seleccionado.length > 0" class="text-center">
                                        <th scope="col" colspan="3">No hay registros</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-xl-6 px-0">
                        <h6>Servicios Seleccionados</h6>
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main text-xxs">
                                    <tr class="text-white">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">NAME</th>
                                        <th scope="col" class="align-middle">PRECIO</th>
                                        <th scope="col" class="align-middle">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(item, index) in servicios_seleccionado" :key="index">
                                        <td class="align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="align-middle">
                                            {{ item.name}}
                                        </td>
                                        <td class="align-middle" @dblclick="mtdSelectInput(index)">
                                            <span v-if="posEdit != index">{{ item.price_purchase}} </span>
                                            <input v-else type="text" class="form-control text-center form-control-sm w-100" v-model="newPrice" @keyup.enter="mtdOutFocusEditPrice" @blur="mtdOutFocusEditPrice" @input="validateAmount" placeholder="0.00" />
                                        </td>
                                        <td class="align-middle text-center">
                                            <button type="button" class=" btn btn-danger btn-sm px-1 py-0" @click="mtdselecionado(item)">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!servicios_seleccionado.length > 0" class="text-center">
                                        <th scope="col" colspan="4">No hay registros</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="mtdaddservicios">
            Registrar
        </button>
        &nbsp;
    </template>

</CModal>
</template>

<script>
import CModal from "../../../shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-marca",
    components: {
        CModal,

    },
    data() {
        return {
            posEdit: null,
            oldPrice: null,
            services_provider: {
                id: null,
                products: []
            },
            servicios_seleccionado: [],
            servicios_no_seleccionado: [],
            newPrice: null
        };
    },

    props: {
        title: String,
        boo_modal: Boolean,

        provider_id: {
            type: Number,
            default: null
        },

    },
    watch: {
        provider_id(newValue, oldValue) {
            if (newValue !== null) {
                this.mtdservicio();
            }
        }
    },

    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.servicios_seleccionado = []
            this.servicios_no_seleccionado = []
            this.marca = {
                id: '',
                name: ''
            }
            this.posEdit = null;
            this.$emit("closeModalService");
        },

        mtdservicio: function () {
          
            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/indexServiceProvider",
                    token: this.$store.getters.get__token,
                    params: {
                        provider_id: this.provider_id,
                    }
                })
                .then(response => {

                    this.servicios_seleccionado = response.data.servicios_seleccionado;
                    this.servicios_no_seleccionado = response.data.servicios_no_seleccionado;
                })
                .catch(error => {
                   
                })
        },
        mtdnoselecionado: function (item) {
            const index = this.servicios_no_seleccionado.findIndex(servicio => servicio.id === item.id);
            if (index !== -1) {
                const ServicioQuitado = this.servicios_no_seleccionado.splice(index, 1)[0];
                this.servicios_seleccionado.push(ServicioQuitado);
            }

        },
        mtdselecionado: function (item) {
            const index = this.servicios_seleccionado.findIndex(servicio => servicio.id === item.id);
            if (index !== -1) {
                const ServicioQuitado = this.servicios_seleccionado.splice(index, 1)[0];
                this.servicios_no_seleccionado.push(ServicioQuitado);
            }
        },
        mtdaddservicios: function () {
            this.services_provider = {
                id: this.provider_id,
                services: this.servicios_seleccionado,
            }
            this.servicios_seleccionado = []
            this.servicios_no_seleccionado = []

            this.$emit('mtdaddservicios', this.services_provider);
        },
        mtdSelectInput: function (pos) {
            this.posEdit = pos;
            this.oldPrice = this.servicios_seleccionado[pos].price_purchase;
            this.newPrice = this.servicios_seleccionado[pos].price_purchase;
      
        },
        mtdOutFocusEditPrice: function () {
   
            if (this.oldPrice != this.newPrice && this.newPrice > 0) {
                Swal.fire({
                    icon: "warning",
                    title: "¿Desea cambiar este precio?",
                    width: "400px",
                    /** buttons */
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.mtdCommitEditPrice();
                    } else if (result.isDenied) {
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                    }
                });
            } else {
                this.posEdit = null;
                this.oldPrice = null;
                this.newPrice = null;
            }
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.newPrice = sanitizedValue;
        },
        mtdCommitEditPrice: function () {
             
            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/update/priceservice",
                    token: this.$store.getters.get__token,
                    params: {
                        service: this.servicios_seleccionado[this.posEdit].id,
                        provider: this.provider_id,
                        price: this.newPrice,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.servicios_seleccionado[this.posEdit].price_purchase = parseFloat(this.newPrice).toFixed(2);
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                        Swal.fire({
                            icon: "success",
                            title: "Editado correctamente",
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "",
                            icon: "error",
                            // width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Error",
                        text: errors,
                        icon: "error",
                        // width: "400px",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },

    },

};
</script>
