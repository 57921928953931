<template>
    <div>
        <div class="px-5 pt-2 pb-5">
        <div id="div-modal-anular" class="d-flex justify-content-center mt-4">
            <div class="card w-100 text-center">
                <div class="card-header text-white bg-main h4">
                    <div style="position: relative;">
                        <div class="">
                            <strong>PASAJES</strong>
                        </div>
                        <div class="btn-home-jefatura" style="right: 0;top: 0;position: absolute;">
                            <button type="button" class="btn btn-sm" @click="returnHome">
                                <i class="fas fa-home-lg-alt"></i> Inicio
                            </button>
                        </div>
                    </div>
                </div>
                    <div class="card-body w-100">
                        <div class="row d-flex mt-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 py-2">
                                <div class="row d-flex">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-3">Desde</span>
                                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="startdate" v-model="filter.date_start" @change="mtdGetData" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-3">Hasta</span>
                                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0 ">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
    
                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
    
                            <div class="col-md-12 mb-4" id="dtb-cxc">
                                <table id="dtb-cxc" class="table table-bordered border-main text-center align-middle">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                            <th scope="col">N°</th>
                                            <th scope="col">FECHA</th>
                                            <th scope="col">CORRELATIVO</th>
                                            <th scope="col">MONTO</th>
                                            <th scope="col">PDF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="cpData.length === 0">
                                            <td colspan="16" class="text-center">No se encontraron resultados</td>
                                        </tr>
                                        <tr v-for="(item, index) in cpData" :key="index">
                                            <td>{{ mtdNumber(index) }}</td>
                                            <td >{{ item.date | formatDate }} </td>
                                            <td>{{ item.correlative }}</td>
                                            <td>{{ item.total_amount }}</td>
                                            <td>
                                                <button @click="mtdViewPdf(item)" class="btn btn-sm btn-primary text-white">
                                                    <i class="fa fa-file-pdf"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
    
                                </table>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import moment from "moment";
    export default {
        name: "c-jefaAdmision-passage",
        data() {
            return {
                filter: {
                    date_start: "",
                    date_end: "",
                },
                data: [],
                dataShow: [],
                //paginado
                totalPagesToShow: 4,
                selectPage: 1,
                stepPagination: [],
            }
        },
        watch: {
            selectPage() {
                this.calculateData(10);
            },
    
        },
        created() {
            this.getFirstDayOfMonth();
        },
        computed: {
            //cpData() {}
            cpData() {
                return this.dataShow;
            },
            limitedStepPagination() {
                const totalPages = this.stepPagination.length;
                const currentPage = this.selectPage;
    
                let startPage = 1;
                let endPage = totalPages;
                if (totalPages > this.totalPagesToShow) {
                    const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
    
                    if (currentPage > halfPagesToShow) {
                        startPage = currentPage - halfPagesToShow;
                        endPage = currentPage + halfPagesToShow - 1;
    
                        if (endPage > totalPages) {
                            endPage = totalPages;
                            startPage = totalPages - this.totalPagesToShow + 1;
                        }
                    } else {
                        endPage = this.totalPagesToShow;
                    }
                }
    
                return Array.from({
                        length: endPage - startPage + 1,
                    },
                    (_, i) => startPage + i
                );
            },
        },
        methods: {
        ...mapActions(["get", "post"]),
            getFirstDayOfMonth: function () {
                const currentDate = new Date();
                const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
                const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
                this.filter.date_end = today.toISOString().split('T')[0];
                this.mtdGetData();
            },
            mtdGetData: function () {
                this.post({
                        url: this.$store.getters.get__url +
                            "/Jefatura/listPassage",
                        token: this.$store.getters.get__token,
                        params: this.filter,
                    })
                    .then((response) => {
                        this.data = response.data;
                        this.calculateData(10);
                    })
                    .catch((errors) => {});
            },
            mtdViewPdf: function (item) {
                    window.open(
                        this.$store.getters.get__url + "/Jefatura/pasaje/" + item.date,
                        "_blank"
                    );
                
            },
            //paginado
            mtdNumber: function (index) {
                return (index + 1) + ((this.selectPage - 1) * 10);
            },
            calculateData(items) {
                if (!this.data || !this.data.length) {
                    return (this.dataShow = []);
                }
                let filteredData = this.data;
                let totalPages = Math.ceil(filteredData.length / items);
                this.stepPagination = Array.from({
                        length: totalPages,
                    },
                    (_, index) => index + 1
                );
                let startIndex = (this.selectPage - 1) * items;
                let endIndex = startIndex + (items - 1);
                this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            },
    
            backPage() {
                this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
            },
            nextPage() {
                this.selectPage =
                    this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
            },
            selectedPage(page) {
                this.selectPage = page;
            },
            returnHome: function () {
                this.$emit("returnHome");
            },
        },
        filters: {
            formatDate(date) {
                return date != null ? moment(date).format("DD/MM/YYYY") : "-";
            },
        },
    
    }
    </script>
    