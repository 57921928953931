<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="false" :booTitile="false">
    <div class="row mx-0  px-2" v-if="show == 'buttons'">
        <div class=" col-xl-12 text-center btn btn-info btn-sm mb-3">
            <button @click="mtdstoredata('floor')" type="button" class="btn w-100 py-0">
                Nuevo Piso
            </button>
        </div>
        <div class=" col-xl-12 text-center btn btn-info btn-sm">
            <button @click="mtdstoredata('bed')" type="button" class="btn w-100 py-0">
                Nueva cama
            </button>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

    
<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";

export default {
    name: "c-mantenimiento-camas",
    components: {
        CModal,
    },
    data() {
        return {
            show: "buttons",
            type: 0,
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodal')
        },
        mtdstoredata(type) {
            this.$emit('mtdstoredata',type)
        }
    },
};
</script>
