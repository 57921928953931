<template>
<div>
    <div class="row px-3 mt-3 text-xs">
        <div class="row mx-0 col-md-12 ">
            <div class=" col-xl-12 px-0 col-lg-12">
                <table class="table table-bordered table-sm">
                    <caption class="py-0"></caption>
                    <thead class="text-center">
                        <tr>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro">Rp. Indicaciones Médicas</th>
                            <th scope="col" class="text-primary-hover bg-gris-claro">Dosis</th>
                            <th scope="col" class="text-primary-hover bg-gris-claro">Via</th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Mañana </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Tarde </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Noche </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="">1</td>
                            <td class="w-20"><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                        </tr>
                        <tr>
                            <td class="">2</td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                        </tr>
                        <tr>
                            <td class="">3</td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                        </tr>
                        <tr>
                            <td class="">ID</td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td class=""></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td class=""></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td class=""></td>
                        </tr>
                    </tbody>
                </table>

                <table class="table  table-bordered table-sm">
                    <caption class="py-0"></caption>
                    <thead class="  text-center">
                        <tr>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro w-75">Recomendaciones del médico</th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Mañana </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Tarde </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Noche </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="">1</td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                        </tr>
                        <tr>
                            <td class="">2</td>
                            <td><input class="form-control form-control-sm" type="text" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td class=""></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td class=""></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td class=""></td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered table-sm">
                    <caption class="py-0"></caption>
                    <thead class="text-center">
                        <tr>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Mañana </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Tarde </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Noche </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" rowspan="2" class="text-primary-hover text-center align-middle bg-gris-claro">Control Glicemias... HGT</th>
                            <td class="bg-gris-claro">Valor</td>
                            <td> <input class="form-control form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                        </tr>
                        <tr>
                            <td class="bg-gris-claro">Hora</td>
                            <td> <input class="form-control   form-control-sm " type="text" placeholder="" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered table-sm">
                    <caption class="py-0"></caption>
                    <thead class="text-center">
                        <tr>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Mañana </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Tarde </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Noche </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" rowspan="2" class="text-primary-hover text-center align-middle bg-gris-claro">Oxigenoterapia</th>
                            <td class="bg-gris-claro">Dispositivo</td>
                            <td> <input class="form-control form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr-td border-tr"></td>
                            <td class="border-tr"></td>
                        </tr>
                        <tr>
                            <td class="bg-gris-claro">Litros / FiO2</td>
                            <td> <input class="form-control form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered table-sm">
                    <caption class="py-0"></caption>
                    <thead class="text-center">
                        <tr>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Mañana </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Tarde </th>
                            <th scope="col" colspan="3" class="text-white bg-main"> Noche </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" rowspan="1" class="text-primary-hover text-center align-middle bg-gris-claro">Dieta</th>
                            <td> <input class="form-control form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-bordered table-sm">
                    <caption class="py-0"></caption>
                    <thead class="text-center">
                        <tr>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" class="text-primary-hover bg-gris-claro"></th>
                            <th scope="col" colspan="3" class="text-white bg-main w-10"> Mañana </th>
                            <th scope="col" colspan="3" class="text-white bg-main w-10"> Tarde </th>
                            <th scope="col" colspan="3" class="text-white bg-main w-10"> Noche </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" rowspan="1" class="text-primary-hover text-center align-middle bg-gris-claro">Cuidados de enfermería</th>
                            <td> <input class="form-control form-control-sm" type="text" placeholder="" aria-label=".form-control-sm example"></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                            <td class="border-tr-td"></td>
                            <td class="border-tr-td"></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

            </div>

        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-asistencial-ucineo-historia-kardex',
    components: {

    },
    data() {
        return {
            //page:null
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
    }

}
</script>
