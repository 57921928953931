<template>
    <div>
        <div id="app" class="container mt-3 text-center ">
            <div class="row px-3 responsive-container ">
                <div class="col-xl-12">
                    <form class="">
                        <div class="row mx-0">
                            <div id="fecha-hora" class="input-group-sm col-xl-5 ps-0">
                                <input type="text" class="form-control form-control-sm" id="fecha-hora" placeholder="Fecha y hora de realización de Rp" autocomplete="off">
                            </div>
                        </div>
                    </form>
                </div>
    
                <div class="  responsive-container-div col-xl-6 col-lg-12 p-4">
                    <div class="row">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th colspan="2" class="w-40"></th>
                                    <th class="encabezado-rounded">Recomendaciones</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
                                    <td class="encabezado-rounded fw-bold title-rp">Días hospitalizado</td>
                                    <td class="input-number">
                                        <input type="number" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                                <tr class="px-1">
                                    <td class="encabezado-rounded fw-bold title-rp">Días SOP</td>
                                    <td class="input-number">
                                        <input type="number" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                                <tr class="px-1">
                                    <td class="encabezado-rounded fw-bold title-rp">Días UCI</td>
                                    <td class="input-number">
                                        <input type="number" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    
                <div class=" responsive-container-div col-xl-6 col-lg-12 p-4">
                    <div class="row">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class=""></th>
                                    <th class="encabezado-rounded">Concepto solicitud</th>
                                    <th class="encabezado-rounded">Fecha de solicitud</th>
                                    <th class="encabezado-rounded">Fecha de resultado</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr class="px-1 mb-1">
                                    <td rowspan="3" class="fw-bold title-rp-2 p-2">PLAN</td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                                <tr class="px-1">
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                                <tr class="px-1">
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
    
                <div class="col-md-12">
                    <div class="row mx-0">
                        <table class="w-100">
                            <caption class="div-text"></caption>
                            <thead class="div-text div-title">
                                <tr>
                                    <th class="encabezado-rounded" scope="col">Rp.</th>
                                    <th class="encabezado-rounded w-25">Medicamentos</th>
                                    <th class="encabezado-rounded">Presentacion</th>
                                    <th class="encabezado-rounded">Dosis</th>
                                    <th class="encabezado-rounded">Via</th>
                                    <th class="encabezado-rounded">Frecuencia</th>
                                    <th class="encabezado-rounded">Duracion</th>
                                </tr>
                            </thead>
                            <tbody class="div-text">
                                <tr v-for="index in 5" :key="index" class="px-1">
                                    <td class="">{{ index }}</td>
                                    <td>
                                        <select selected class="form-select pb-0 pt-0">
                                            <option value="">Buscar...</option>
                                            <option>Opción 1</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-input form-control-sm" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row my-1 mx-0">
                        <div class="col-xl-2 col-lg-2 div-text  h6 fw-bold d-flex align-items-center">
                            <span>MÉDICO GENERAL</span>
                        </div>
                        <div class="col-xl-3 col-lg-10 py-2">
                            <input type="text" class="form-control form-control-input form-control-sm" />
                        </div>
                        <div class="col-xl-2 col-lg-2 h6 fw-bold d-flex align-items-center div-text">
                            <span>MÉDICO ESPECIALISTA</span>
                        </div>
                        <div class="col-xl-3 col-lg-10 py-2">
                            <input type="text" class="form-control form-control-input form-control-sm" />
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
    </template>
<script>
import {mapActions} from 'vuex'
export default{
    name: 'c-asistencial-sop-historia-rp',
    data() {
        return {
            //property: 'value',
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>