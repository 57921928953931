<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
      <div class="px-3 mb-1" v-if="num == 3">
          <div class="row d-flex mx-0 px-0">
            <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="description" class="form-label">Descripción</label>
                <textarea class="form-control form-control-sm" v-model="data_detail.description" placeholder="Descripción" rows="1"></textarea>
            </div>
            <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="description" class="form-label">Obstaculos</label>
                <textarea class="form-control form-control-sm" v-model="data_detail.obstacles" placeholder="Obstaculos" rows="1"></textarea>
            </div>
          </div>
          <div class="row d-flex mx-0 px-0">
              <div class="mb-2 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 px-1">
                  <label for="description" class="form-label">Mensaje (Opcional)</label>
                  <textarea class="form-control form-control-sm" v-model="data_detail.message" placeholder="Mensaje" rows="1"></textarea>
              </div>
              <div class="mb-2 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="name" class="form-label">Archivo (Opcional)</label>
                <div class="input-group input-group-sm">
                  <input
                    @change="getFile"
                    type="file"
                    class="form-control"
                    id="file"
                    lang="es"
                    name="file"
                    ref="fileInput"
                  />
                </div>
              </div>
              <div class="mt-1 mb-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-xs-12 px-1 d-flex align-items-end">
                <button class="btn btn-primary btn-sm w-100" :disabled="cpButton" @click="registerAdvance">
                  <i class="fa fa-save"></i> Guardar
                </button>
              </div>
          </div>
          <div class="row mx-0 px-0 mt-2">
            <hr>
          </div>
      </div>

      <div class="row mx-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                <table id="tb-anular" class="table table-bordered">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 12px">
                        <tr class="text-white text-center align-middle">
                          <th scope="col">N°</th>
                          <th scope="col">Fecha</th>
                          <th scope="col">Descripción</th>
                          <th scope="col">Obstaculos</th>
                          <th scope="col">Detalle</th>
                        </tr>
                    </thead>
                    <tbody class="border-main" style="font-size: 11px">
                      <tr class="table-body text-center" v-if="item.length === 0">
                          <td colspan="6" class="text-center">No hay registros</td>
                      </tr>
                      <tr v-for="(item, index) in item" :key="index" class="text-center align-middle">
                        <template>
                          <td>{{index + 1}}</td>
                          <td>{{item.date_registration | formatDateTime}}</td>
                          <td>{{item.description}}</td>
                          <td>{{item.obstacles}}</td>
                          <td class="" style="white-space: nowrap;">
                            <a class="accion-AsignFile text-center px-2 py-2 me-1" href="#" @click="openAdFile(item.id, num)">
                                <i class="fas fa-solid fa-file text-white"></i>
                            </a>
                            <a class="accion-AsignComment text-center px-2 py-2" href="#" @click="openAdComent(item.id, num)">
                              <i class="fas fa-comment text-white"></i>
                            </a>
                        </td>
                        </template>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
      <template #footer>
        <fieldset class="btn-group" aria-labelledby="button-group-label" v-if="num == 3">
          <button class="btn btn-success btn-sm" @click="statusFin">
            <strong>{{ (num == 3) ? 'A FINALIZADO' : 'A EN PROCESO' }}</strong>
          </button>
        </fieldset>
      </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
  mapActions
} from "vuex";
import moment from "moment";

export default {
  name: "c-maintenance-modal-documento",
  components: {
      CModal,
  },
  data() {
      return {
          servicesPa: [],
          selectedPa: "",
          data_detail: {
              description: '',
              obstacles: '',
              message: '',
              file: '',
          },
      };
  },
  watch: {
    /*
      item: {
          handler(newVal) {
            if (newVal) {
                this.data_detail = {
                    ...newVal
                };
            }
          },
          immediate: true,
          deep: true,
      },
      */
  },
  props: {
      title: String,
      boo_modal: Boolean,
      num: Number,
      payload: {
          type: Object,
          default: null
      },
      item: {
          type: Array,
          default: () => []
      },
  },
  computed: {
      cpButton() {
              return !(this.data_detail.description !='' && this.data_detail.obstacles !='');

      }
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.mtdClear();
          this.$emit("closeModalPro");
      },

      mtdClear(){
          this.data_detail.description ='';
          this.data_detail.obstacles ='';
          this.data_detail.message ='';
          this.data_detail.file ='';
      },

      getFile(e) {
        let fileInput = this.$refs.fileInput;
        let file = e.target.files[0];
        if (
          !/\.(pdf)$/i.test(file.name) &&
          !/\.(png)$/i.test(file.name) &&
          !/\.(jpg)$/i.test(file.name) &&  !/\.(jpeg)$/i.test(file.name)
        ) {
          Swal.fire({
            text: "Seleccione un archivo formato PDF, PNG O JPG",
            icon: "info",
            confirmButtonColor: "#900052",
          });
          fileInput.value = '';
          this.data_detail.file = '';
        } else {
          this.data_detail.file = file;
        }
      },


      registerAdvance() {
          this.$emit("registerAdvance", this.data_detail, this.payload);
          this.mtdClear();
          let fileInput = this.$refs.fileInput;
          fileInput.value = '';
          //this.mtdClose();
      },

      statusFin() {
        if(this.num == 3){
          Swal.fire({
            title: "¿Seguro de cambiar esta Tarea a FINALIZADA?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Cambiar",
            width: "400px",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit("statusFin", this.payload.idAsign, 3);
            }
          });
        }else{
          Swal.fire({
            title: "¿Deseas volver esta Tarea a EN PROCESO?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Cambiar",
            width: "400px",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit("statusFin", this.payload.idAsign, 2);
            }
          });
        }

      },

      openAdFile(idAd, num){
        this.$emit("openAd", idAd, num, 0);
      },

      openAdComent(idAd, num){
        this.$emit("openAd", idAd, num, 1);
      },


  },
  filters: {
      formatDate(date) {
          return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatDateTime(dateTime) {
          return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
      },
  },
};
</script>
<style>
.custom-button {
    font-size: 10px;
    padding: 7px;
    border-radius: 70%;
    border: 1px solid #ccc;
}
.accion-2 {
    background-color: #e50303;
    border-radius: 10px;
    width: 15px;
    font-size: 10px;
}
</style>
