<template>
  <div>
      <div id="div-anular" class="px-2 w-100">
        <div class="card-body w-100">
          <div class="row">
            <div class="row d-flex">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                    <div class="input-group input-group">
                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                        <v-select
                          class="text-dark form-control"
                          label="name"
                          :options="servicesPac"
                          placeholder="- Buscar Paciente (Escriba uno...) -"
                          @search="searchPac"
                          v-model="selectedPac"
                          :reduce="(name) => name.id"
                          required
                          :clearable="false"
                          @input="mtdGetData"
                        >
                          <template slot="no-options">
                            No hay Pacientes para la busqueda.
                          </template>
                        </v-select>
                    </div>
                </div>
            </div>

            <div class="mt-2"></div>

            <div class="d-flex justify-content-center mt-2" v-if="showTable && cpData.length !== 0">
              <div class="w-100 pt-2" style="background-color: #ffffff">
                <div class="cuerpo">
                  <div class="col-md-12 mt-2" id="content-timelinetable">
                    <table id="tb-cxc" class="table table-bordered border-main">
                        <thead>
                            <tr class="text-center text-white border-white bg-main align-middle">
                                <th scope="col">N°</th>
                                <th scope="col">FECHA</th>
                                <th scope="col">NOTA DE CREDITO</th>
                                <th scope="col">ACCION</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr  v-for="(item, index) in cpData" :key="index" class="text-center font-size">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.date}}</td>
                            <td>{{ item.correlative}}</td>
                            <td>
                              <a @click="mtdPdf(item.id)" class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052; margin-left: 5px;">
                                  <i class="fas fa-solid fa-file-pdf"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!--pag-->
            <div v-if="cpData.length !== 0" class="d-flex justify-content-center px-1 mb-4">
                <nav aria-label="Page navigation example">
                    <ul class="pagination mb-1">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="
                  selectPage === 1 ? 'page-link active' : 'page-link'
                " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="
                selectPage === item ? 'page-link active' : 'page-link'
              " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if="
              limitedStepPagination[
                limitedStepPagination.length - 1
              ] <
              stepPagination.length - 1
            " class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if="
              limitedStepPagination[
                limitedStepPagination.length - 1
              ] !== stepPagination.length
            " class="page-item">
                            <a :class="
                selectPage === limitedStepPagination.length + 2
                  ? 'page-link active'
                  : 'page-link'
              " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>

          </div>
        </div>
      </div>
      <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
  name: "c-oftalmologia-modal-detalle",
  components: {
    SweetAlert,
  },
  data() {
    return {
      swal: null,
      item: [],
      search: "",
      dataDev:[],
      dataShow: [],
      dataProd: [],
      selectedProd: "",
      servicesPac: [],
      selectedPac: "",
      showTable: false,

      //Pag
      page: 0,
      selectPage: 1,
      stepPagination: [],
      totalPagesToShow: 4,
      showSecondSelect: false,
    };
  },
  props: {
  },
  created() {
    //this.mtdOpenDev();
  },
  watch: {
    selectPage() {
        this.calculateData(10);
    },
  },
  computed: {
    cpData() {
      return this.dataShow;
    },

    limitedStepPagination() {
        const totalPages = this.stepPagination.length;
        const currentPage = this.selectPage;

        let startPage = 1;
        let endPage = totalPages;
        if (totalPages > this.totalPagesToShow) {
            const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

            if (currentPage > halfPagesToShow) {
                startPage = currentPage - halfPagesToShow;
                endPage = currentPage + halfPagesToShow - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = totalPages - this.totalPagesToShow + 1;
                }
            } else {
                endPage = this.totalPagesToShow;
            }
        }

        return Array.from({
                length: endPage - startPage + 1,
            },
            (_, i) => startPage + i
        );
    },
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.$emit("mtdCloseModalView");
    },

    mtdPdf: function(id) {
      window.open(
          this.$store.getters.get__url + "/Pharmacy/return/noteCrePdf/" + id,
          "_blank"
      );
    },

    searchPac: function (search, loading) {
      if (search.length > 2) {
        this.servicesPac = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/Pharmacy/return/searchDevoluPaNote/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.servicesPac = response.data;
            loading(false);
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
      }
    },

    mtdGetData: function() {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/return/modalDev",
        token: this.$store.getters.get__token,
        params: {
          selectedPa: this.selectedPac,
        },
      })
      .then((response) => {
        if(response.data.length > 0){
        this.showTable = true;
          this.item = response.data;
          this.calculateData(10);
        }else{
          this.showTable = false;
          this.$refs.SweetAlert.showWarning("No hay Devoluciones");
        }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    calculateData(items) {
        if (!this.item || !this.item.length) {
            return (this.dataShow = []);
        }
        let filteredData = this.item;
        let indexInitial = this.selectPage;

        let totalPages = Math.ceil(filteredData.length / items);
        this.stepPagination = Array.from({
                length: totalPages,
            },
            (_, index) => index + 1
        );
        let startIndex = (indexInitial - 1) * items;
        let endIndex = startIndex + (items - 1);
        this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        this.count = this.count + 1;
    },

     /** paginación */
     backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
      nextPage() {
          this.selectPage =
              this.selectPage < this.stepPagination.length ?
              this.selectPage + 1 :
              this.stepPagination.length;
      },
      selectedPage(page) {
          this.selectPage = page;
      },
  },

};
</script>

<style scoped>
.font-size{
  font-size: 12px;
  vertical-align: middle;
}
</style>
