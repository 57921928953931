<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        
        <div class="row px-5 mb-2">
            <table class="table table-bordered border-main mb-0 px-0 text-xs">
                <caption class=" my-0 py-0"></caption>
                <thead class="">
                    <tr class="text-white border-white text-center bg-main">
                        <th scope="col">N°</th>
                        <th scope="col">ENFERMERA</th>
                        <th scope="col">FECHA INICIO</th>
                        <th scope="col">FECHA CAMBIO</th>
                    </tr>
                </thead>
                <tbody v-if="this.dataProcInvasivo.length > 0">
                    <tr v-for="(proc, index) in this.dataProcInvasivo" :key="index" class="px-1 border-bottom">
                        <td class="text-center"><span>{{ index + 1 }}</span></td>
                        <td class="text-center"><span>{{ proc.nurse.name }} {{ proc.nurse.last_name }}</span></td>
                        <td class="text-center"><span>{{ formatDate(proc.date) }} | {{ proc.hour }}</span></td>
                        <td class="text-center"><span>{{ formatDate(proc.date_end) }} | {{ proc.hour_end }}</span></td>
                    </tr>
                </tbody>
                <tbody class=" text-center" v-else>
                <tr>
                    <td colspan="19" class=" text-center">
                    <strong> No hay resultados</strong>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
import moment from "moment";

export default {
    name: "c-medico-modal-proc-invasivo-view",

    data() {
        return {
            swal: null,
            validation: false,
            dataProcInvasivo: []
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id_medical: {
            type: Number,
            default: 0
        },
        patient_id: {
            type: Number,
            default: 0
        },
        type_kardex: {
            type: String,
            default: ''
        }
    },
    watch: {
        id_medical: {
            handler: function (newValue) {
                if (newValue != null && newValue != 0) {
                    this.mtdtypepatient();
                    this.mtdGetData();
                }
            },
            deep: true,
            immediate: true,
        },
        patient_id: {
            handler: function (newValue) {
                
            },
            deep: true,
            immediate: true,
        },
        type_kardex: {
            handler: function (newValue) {
                
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
    },
    mounted() {
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/procInvasivo",
                token: this.$store.getters.get__token,
                params:{
                    medical_act_id: this.id_medical,
                    type_kardex: this.type_kardex
                },
            })
            .then((response) => {
                this.dataProcInvasivo = response.data;
            })
            .catch((errors) => {
                console.log(errors);
            });
        },
        mtdclosemodal() {
            this.$emit('mtdclosemodalprocinvasivoview');
        },
        mtdtypepatient: function () {
            this.post({
                url: this.$store.getters.get__url + "/Hospitalization/salePatient",
                token: this.$store.getters.get__token,
                params: {
                    patient_id: this.patient_id,
                    medical_act_id: this.id_medical
                },
            })
            .then((response) => {
                this.sale = response.data,
                this.type_patient = response.data.type_patient
                this.insurance_id = response.data.insurance_id
            })
            .catch((errors) => {
                console.log(errors);
            });
        },
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "";
        }
    },
}
</script>