<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
    :fullScreen="true"
  >
    <div class="row mx-0 mt-2">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">#</th>
                        <th scope="col">MOLECULA</th>
                        <th scope="col">COMERCIAL</th>
                        <th scope="col">MARCA</th>
                        <th scope="col">PRESENTACION</th>
                        <th scope="col">DOSIS</th>
                        <th scope="col">CANTIDAD ENTRANTE</th>
                        <th scope="col" style="white-space: nowrap;">PRECIO CON IGV</th>
                        <th scope="col">LOTE</th>
                        <th scope="col">CODIGO DE BARRAS</th>
                        <th scope="col" style="white-space: nowrap;">F. VENCIMIENTO</th>
                        <th scope="col" style="white-space: nowrap;">REG. SANITARIO</th>
                        <!--
                        <th scope="col" style="white-space: nowrap;">SECTOR</th>
                        -->
                        <th scope="col" style="white-space: nowrap;">S/ SUBTOTAL</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 10px">
                    <tr v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                      <template>
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.product.molecule }}</td>
                        <td>{{ item.product.commercial }}</td>
                        <td>{{ item.product.brand ? item.product.brand.name : '-' }}</td>
                        <td>{{ item.product.presentation }}</td>
                        <td>{{ item.product.dosis }}</td>
                        <td>{{ item.quantity_receipt }}</td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.lote }}</td>
                        <td>{{ item.barcode }}</td>
                        <td>{{ item.date | formatDate}}</td>
                        <td>{{ item.register_sanitary ? item.register_sanitary : '-'}}</td>
                        <!--
                        <td>{{ item.sector_id ? item.sector.name : '-' }}</td>
                        -->
                        <td>S/ {{ (item.price * item.quantity_receipt).toFixed(2) }}</td>
                      </template>
                    </tr>
                  </tbody>
              </table>
          </div>
      </div>
    </div>
    <template #footer>
        <button type="button" class="btn btn-register text-white" @click="aprobarDetalle" v-if="state == 1">
            <i class="fas fa-thumbs-up"></i> APROBAR
        </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
  name: "c-pharmacy-cModalDetalle",
  components: {
    SweetAlert,
    CModal,
  },
  data() {
    return {
        swal: null,
      tabla: [],
    };
  },
  created () {
  },
  computed: {
    cpData() {
      let show = [];
      this.item.forEach((element) => {
        show.push(element);
      });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    idNote: Number,
    state: Number,
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.$emit("closeModal");
    },

    aprobarDetalle(){
        this.$refs.SweetAlert.showConfirmSimple2("¿Estás seguro de aceptar está Nota de Ingreso?","Esto móvera Stocks","warning","Aceptar") 
        .then((result) => {
            if (result.value) {
                this.$emit("updatePermiso", this.idNote);
            }
        });
    },

  },
  filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>
