<template>
    <div>
        <div class="card-bod mt-2">
            <div class="row tab-content pb-3" id="camas">
                <div class="tab-pane fade show active" role="tabpanel">
                    <div align="center" class="row m-2 p-2 d-flex justify-content-center">
                        <div v-if="Object.keys(dataBedGroupsD).length === 0" class="col-12">
                            <div class="card mb-3">
                                <div class="card-header text-white h6 header-main">
                                    <h6>NO HAY RESERVAS</h6>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div v-for="(group, key) in dataBedGroupsD" :key="key" 
                            :class=" Object.keys(dataBedGroupsD).length === 1 ? 'col-md-12' : Object.keys(dataBedGroupsD).length === 2 ? 'col-md-6' : 'col-md-4' ">
                                <div class="card mb-3">
                                    <div class="card-header text-white h6 header-main">
                                        <strong>{{ group[0].consultorio }}</strong>
                                    </div>
                                    <div class="card-body" id="content-timeline">
                                        <div v-for="(item, index) in group" :key="index" class="p-3 mb-3 mt-3 border border-main border-start-0 rounded-end hover-effect"  
                                        style="cursor: pointer;" @click="mtdGetDataMedicalAct(item.patient_id, item.reservation_id, item.consulting_id, item.medical_act_id , item.status_history)">
                                            <span class="text-paciente">{{ item.patientName }}</span>
                                            <br />
                                            <span class="text-datos">{{ item.hora }} - {{ item.specialty }}</span>
                                            <br>
                                        </div>
                                        <div v-if="group.length === 0" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end">
                                            <div class="color-main">
                                                <h6>NO HAY RESERVAS</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    name: 'c-consulta-cola',
    data() {
        return {
            tab: 0,
            dataFloor: [],
            dataBedGroupsD: [],
            verifSelectBed: false,
            oldPosSelectBed: [],
            mostrar: false,
            clinica_history: {
                id: null,
                user_id: null,
                biological_function: [{
                        id: 1,
                        label: "Apetito",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "Sed",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "Orina",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Heces",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Sueño",
                        value: ''
                    },

                ],
                physical_exam: [{
                        id: 1,
                        label: "Estado general",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "SNC",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "Respiratorio",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Tórax Cv Hemo",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Abdominal",
                        value: ''
                    },
                    {
                        id: 6,
                        label: "Renal",
                        value: ''
                    },
                    {
                        id: 7,
                        label: "Otros",
                        value: ''
                    }
                ],
                lab_question: '',
                symploms: [{
                        id: 1,
                        label: "Tos sin flema",
                        state: false
                    },
                    {
                        id: 2,
                        label: "Tos con flema",
                        state: false
                    },
                    {
                        id: 3,
                        label: "Moco",
                        state: false
                    },
                    {
                        id: 4,
                        label: "Dolor de pecho",
                        state: false
                    },
                    {
                        id: 5,
                        label: "Dolor de cabeza",
                        state: false
                    },
                    {
                        id: 6,
                        label: "Dolor de espalda",
                        state: false
                    },
                    {
                        id: 7,
                        label: "Nauseas / vómitos x/día",
                        state: false
                    },
                    {
                        id: 8,
                        label: "Diarrea x/día",
                        state: false
                    },
                    {
                        id: 9,
                        label: "Otros",
                        state: false,
                        value: ''
                    }
                ],
                vital_sings: [{
                        id: 1,
                        label: "FC",
                        value: ''
                    },
                    {
                        id: 2,
                        label: "T (°C)",
                        value: ''
                    },
                    {
                        id: 3,
                        label: "FR",
                        value: ''
                    },
                    {
                        id: 4,
                        label: "SATO2",
                        value: ''
                    },
                    {
                        id: 5,
                        label: "HGT",
                        value: ''
                    },
                    {
                        id: 6,
                        label: "FIO 2",
                        value: ''
                    },
                    {
                        id: 7,
                        label: "CAS",
                        value: ''
                    },
                    {
                        id: 8,
                        label: "PAS",
                        value: ''
                    },
                    {
                        id: 9,
                        label: "PAD",
                        value: ''
                    }
                ],
                fe: '',
                fi: '',
                course: '',
                story: '',
                another_file: '',
                diagnostics: [],
                plan: [],
                recipe: [],
                recomendations: '',
                physical_exam_text: '',
                background: [{
                        id: 1,
                        label: "DM",
                        state: false,
                        value: ''
                    },
                    {
                        id: 2,
                        label: "AR",
                        state: false,
                        value: ''
                    },
                    {
                        id: 3,
                        label: "HTA",
                        state: false,
                        value: ''
                    },
                    {
                        id: 4,
                        label: "Otro",
                        state: false,
                        value: ''
                    },
                    {
                        id: 5,
                        label: "Alergias",
                        state: false,
                        value: ''
                    },
                    {
                        id: 6,
                        label: "Cirugías",
                        state: false,
                        value: ''
                    },
                    {
                        id: 7,
                        label: "Medicamentos Frecuentes",
                        state: false,
                        value: ''
                    }
                ],
                patient_id: null,
                model_id: null,
                medical_act_id: null,
                dieta:'',
                insuline:'',
                grupo:'',
                factor:''
            },
            patient_id: null,
            reservation_id: null,
            consulting_id: null,
            medical_act_id: null
        }
    },
    created() {
        this.mtdSelectGroup();
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdGetDataMedicalAct: function (patient_id, reservation_id, consulting_id, medical_act_id = 0 , status) {
            this.patient_id = patient_id;
            this.reservation_id = reservation_id;
            this.consulting_id = consulting_id;
            this.medical_act_id = medical_act_id;
            if (status == 3) {
                this.mtdViewAct(this.patient_id, this.medical_act_id);
            } else {
                this.mtdNewAct(this.patient_id, this.reservation_id, this.consulting_id, this.medical_act_id);
            }

        },
        mtdNewAct: function (patient_id, reservation_id, consulting_id, medical_act_id) {
            this.post({
                    url: this.$store.getters.get__url + "/Consultation/add",
                    token: this.$store.getters.get__token,
                    params: {
                        clinica_history: this.clinica_history,
                        patient_id: this.patient_id,
                        reservation_id: this.reservation_id,
                        consulting_id: this.consulting_id,
                        medical_act_id: this.medical_act_id
                    }
                })
                .then((response) => {
                    this.data = response.data;
                    this.patient_id = null;
                    this.$emit('mtdHistorial', {
                        patient: this.data
                    })
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        mtdViewAct: function (patient_id, medical_act_id) {
             this.get({
                    url: this.$store.getters.get__url + "/Consultation/history/"+ medical_act_id,
                    token: this.$store.getters.get__token,   
                })
                .then((response) => {
                     let newhistory = response.data;
                    this.$emit('mtdHistorialtable', {
                        patient:newhistory 
                    })
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdSelectGroup: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Consultation/bedgroup/filterCon",
                    token: this.$store.getters.get__token,
                    params: {
                        //floor_id: id,
                        campu_id: this.$store.getters.get__campus,
                        user_id: this.$store.getters.get__user.id
                    },
                })
                .then((response) => {
                    this.dataBedGroupsD = response.data;
                    //this.tab = id;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdSendOption: function (page) {
            this.page = page;
            this.$emit('mtdChangePage', this.page)
        },
    }
}
</script>

<style scoped>
.nav-link:hover {
    color: #ffffff !important;
}

.nav-link {
    color: #900052 !important;
    font-weight: bold;
}

.nav-link.active {
    background: #900052 !important;
    color: #ffffff !important;
}

.circle {
    background: lightblue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.superior-derecha {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.libre {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
}

.reservado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
}

.ocupado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}
.limpieza {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: blue;
  border-radius: 50%;
}
.hover-effect {
    transition: background-color 0.3s, color 0.3s;
}

.hover-effect:hover {
    background-color: #f4869a;
}
.hover-effect:hover .text-paciente,
.hover-effect:hover .text-datos {
    color: white;
}
#content-timeline {
  border: 1px solid #900052;
  height: 315px;
  overflow:auto;
  box-sizing: border-box;
  padding:0 1rem;
}
</style>
