<template>
<div>
    <div class="p-4" v-if="view == 'data'">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="viewReportes('reporte')">
                    <i class="fas fa-table"></i> Reportes
                </button>
                &nbsp;
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-lg-5">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>CXC</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-5">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input @keyup="calculateData(10)" v-model="search" type="text" class="form-control form-control-sm" placeholder="Buscar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-md-12 mb-lg-4" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center text-xs">
                                        <th class="">N°</th>
                                        <th>PERIODO DEL SERVICIO</th>
                                        <th>TIPO</th>
                                        <th class="table-th-name">PACIENTE</th>
                                        <th>LIQUIDACION DEL SERVICIO</th>
                                        <th>AMORTIZADO</th>
                                        <th>PENDIENTE</th>
                                        <th>ESTADO</th>
                                        <th class="table-th-number">ACCION</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="9" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index)}}
                                        </td>
                                        <td>
                                            <span v-if="
                          item.date_ind == null &&
                          item.date_end == null &&
                          posEditPeriod != index
                        " class="badge text-bg-primary" @click="mtdSetPeriodo(item, index)">Agregar periodo
                                            </span>
                                            <span v-else @click="mtdSetPeriodo(item, index)">Desde {{ item.date_ind | formatDate }} hasta
                                                {{ item.date_out | formatDate }}</span>
                                        </td>
                                        <td>
                                            {{ item.type.toUpperCase() }}
                                        </td>
                                        <td>
                                            {{ item.patient.document }}-{{ item.patient.name }}
                                        </td>
                                        <td>
                                            S/{{ item.total }}
                                        </td>
                                        <td>
                                            S/{{ item.amount }}
                                        </td>
                                        <td class="text-danger">
                                            S/{{
                          item.pending < 0
                            ? parseFloat(item.pending * -1).toFixed(2)
                            : parseFloat(item.pending).toFixed(2)
                        }}
                                        </td>
                                        <td>
                                            <div class="" v-if="item.pending > 0">
                                                <div class="text-danger"><strong>DEBE</strong></div>
                                            </div>
                                            <div class="" v-else-if="item.pending < 0">
                                                <div class="text-success">
                                                    <strong>DEVOLUCIÓN</strong>
                                                </div>
                                            </div>
                                            <div class="" v-else>
                                                <div class="text-success">
                                                    <strong>-</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-evenly">
                                                <button v-if="item.type == 'garantia'" class="btn btn-sm px-1 a-success text-white" @click="mtdGetDataID(item, index, item.type)">
                                                    <i class="far fa-money-bill-alt"></i>
                                                </button>
                                                <button v-else class="btn btn-sm px-1 a-success text-white" @click="mtdViewPdfLiquidacion(item)">
                                                    <i class="far fa-file-pdf"></i>
                                                </button>
                                                <button class="btn btn-sm px-1 a-primary text-white" @click="mtdAddService(item)" title="Agregar Servicios">
                                                    <i class="far fa-plus"></i>
                                                </button>
                                                <button class="btn btn-sm px-1 a-warning text-white" @click="mtdToChange(item)" title="Canjear documento">
                                                    <i class="far fa-recycle"></i>
                                                </button>
                                                <button class="btn btn-sm px-1 btn-patch  text-white" @click="mtdEditCarrito(item, index)">
                                                    <i class="far fa-edit"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="container" v-else-if="view == 'carrito'">
        <CarritoCxcVue :type_patient="carrito.type_patient" @mtdBack="mtdBack" @mtdSendCarrito="mtdSendCarrito" :amount="carrito.amount" :type="carrito.type_patient" :insurance="carrito.insurance"  :payment_eps="carrito.payment_eps">
        </CarritoCxcVue>
    </div>
    <div class="px-5" v-else>
        <CxcReportesVue @viewReportes="viewReportes"> </CxcReportesVue>
    </div>
    <cModalpagoGarantia :title="modal.title" :boo_modal="modal.boo_modal" :payload="modal.payload" :payment="payment" :sale="sale" :dataDocumentType="dataDocumentType" @mtdHideModal="mtdHideModal" />
    <cModalCanjeGarantiaVue :title="modalCanje.title" :boo_modal="modalCanje.boo_modal" :item="modalCanje.item" :type="modalCanje.type" :patient="modalCanje.patient" :pending="modalCanje.pending" @mtdCloseModalCanje="mtdCloseModalCanje" @mtdCommitCanjear="mtdCommitCanjear" />
    <cModalPeriodoVue :title="modalFechas.title" :boo_modal="modalFechas.boo_modal" :item="modalFechas.item" :date_end="modalFechas.date_end" :date_ind="modalFechas.date_ind" @mtdEditValor="mtdEditValor" @mtdCloseModalPeriodo="mtdCloseModalPeriodo" />
    <cModalEditCarritoVue :title="modalEdit.title" :boo_modal="modalEdit.boo_modal" :item="modalEdit.item" :permissionValue="permissionValue" :permissionEli="permissionEli" @mtdCloseModalEdiatCarrito="mtdCloseModalEdiatCarrito" @mtdCommitDelCarrito="mtdCommitDelCarrito" @mtdCommitEditQuantity="mtdCommitEditQuantity" @mtdCommitEditPrice="mtdCommitEditPrice" />
    <cModalAddDataPdfLiquidacionVue :title="modalPpdfLiqui.title" :boo_modal="modalPpdfLiqui.boo_modal" :doctors="modalPpdfLiqui.doctors" @closeModalPdfLiquidacion="closeModalPdfLiquidacion" @mtdSendDataPdfLiquidacion="mtdSendDataPdfLiquidacion" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import CarritoCxcVue from "../pages/Cxc/CarritoCxc.vue";
import cModalCanjeGarantiaVue from "../modal/cModalCanjeGarantia.vue";
import CxcReportesVue from "../pages/Cxc/Reporte.vue";
import cModalPeriodoVue from "../modal/cModalPeriodo.vue";
import cModalEditCarritoVue from "../modal/cModalEditCarrito.vue";
import cModalAddDataPdfLiquidacionVue from "../modal/cModalAddDataPdfLiquidacion.vue";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalpagoGarantia from "../modal/cModalpagoGarantia.vue";
export default {
    name: "c-admision-pages-cxc",
    components: {
        SweetAlert,
        CarritoCxcVue,
        cModalpagoGarantia,
        cModalCanjeGarantiaVue,
        cModalPeriodoVue,
        cModalEditCarritoVue,
        cModalAddDataPdfLiquidacionVue,
        CxcReportesVue
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
                filter_area_name: "ADMISION",
            },
            disabledChkDoc: true,
            chkDoc: false,
            chkPatiente: {
                document: "",
                fullName: "",
                address: "",
            },
            dataDocumentType: [],
            view: "data",
            swal: null,
            search: "",
            dataShow: [],
            modalRegCancel: {
                title: "",
                modal_form: false,
                data: {
                    serie: null,
                    number: null,
                    motive: null,
                    type: null,
                },
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            /* migracion */
            payment: {
                document: "",
                method: "",
                type: "",
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            },
            sale: {
                id: "",
                patient_id: "",
                total: "",
                pending: "",
                state: "",
            },
            modal: {
                title: "",
                action: "",
                boo_modal: false,
                title: "",
                payload: {}
            },
            posEdit: null,
            noRecordsMessage: "",
            vuelto: false,
            pending: false,
            totalPay: 0.0,
            posEditPeriod: null,
            newAdmissionPeriod: null,
            /** carrito */
            carrito: {
                type_patient: "",
                data: [],
                saleId: null,
                amount: [0, 0, 0],
                insurance: null,
                payment_eps: null
            },
            /** canje */
            modalCanje: {
                title: "Canjear documento",
                item: [],
                boo_modal: false,
                pending: null,
                bead: null,
                method: null,
                sustento: null,
                type: null,
                patient: null,
                sale: null,
                amount: null,
            },
            /** canje */
            modalFechas: {
                title: "Periodo del servicio",
                item: [],
                boo_modal: false,
                date_ind: null,
                date_end: null,
            },
            datePos: 0,
            /** editar carrito */
            modalEdit: {
                title: "Editar Liquidación",
                item: [],
                boo_modal: false,
                pos: null,
            },
            /** pdf  */
            doctors: [],
            modalPpdfLiqui: {
                title: "Datos para el pdf de liquidación",
                boo_modal: false,
                doctors: [{
                    name: "",
                    id: "",
                }, ],
                sale: null,
            },
            permissionValue: null,
            permissionEli: null,
        };
    },
    props: {
        bead: {},
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        //this.mtdGetData();
        this.getFirstDayOfMonth();
        this.mtdGetDataPerAndData();
        this.mtdGetDataComprobantes();

    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        //Traer Data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/cxc",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        init: this.filter.begindate,
                        end: this.filter.enddate,
                    },
                })
                .then((response) => {
                    const filteredData = response.data.data.filter(item => {
                        if (item.voucher && item.voucher.length > 0) {
                            return item.voucher[0].state !== 2 || item.type !== 'garantia';
                        } else {
                            return true;
                        }
                    });
                    this.dataShow = [];
                    this.selectPage = 1;
                    this.doctors = response.data.doctors;
                    this.data = filteredData;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.patient.name.toString().toUpperCase();
                    let number = element.patient.document.toString().toUpperCase();
                    let serie = element.type.toString().toUpperCase();
                    return name.includes(query) || number.includes(query) || serie.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        /* modales y funciones */
        mtdGetDataPerAndData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Gerencia/otrosPermisos",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.permissionValue = response.data[0].permission;
                    this.permissionEli = response.data[3].permission;

                })
                .catch((errors) => {});
        },
        mtdGetDataComprobantes: function () {
            this.post({
                    url: this.$store.getters.get__url + "/VoucherType/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.dataDocumentType = response.data.data;
                })
                .catch((errors) => {});
        },
        mtdGetDataID: function (item, pos, type) {

            this.bottonActiveT = item.voucher[0].document;
            this.sale = item;
            this.totalPay = type == "credito" ? this.sale.pending : 0.0;
            this.mtdModalShow("charge", pos, type);
            // this.get({
            //   url: this.$store.getters.get__url + "/admision/cxc/sale/" + id ,
            //   token: this.$store.getters.get__token,
            // })
            //   .then((response) => {

            //     this.sale = response;
            //     this.totalPay = this.sale.pending;
            //     this.mtdModalShow("charge",pos);
            //   })
            //   .catch((errors) => {});
        },
        mtdModalShow: function (action, pos, type) {
            this.modal.title = type == "credito" ? "Pagar CXC" : "Agregar Garantía";
            this.modal.action = action;
            this.posEdit = pos;
            this.payment = {
                document: "",
                method: "",
                document: "",
                type: type,
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            };
            this.modal.payload = {
                vuelto: this.vuelto,
                pending: this.pending,
                totalPay: this.totalPay,
                disabledChkDoc: this.disabledChkDoc,
                chkDoc: this.chkDoc,
                bottonActiveT: this.bottonActiveT,
                bead_id: this.bead.id,
            }
            this.modal.boo_modal = true;
            //cambiado por el modal this.activarTipo(this.bottonActiveT);
        },
        mtdHideModal: function () {
            this.modal.title = "";
            this.modal.action = "";
            this.modal.boo_modal = false;
            this.payment = {
                document: "",
                type: "",
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            };
            this.posEdit = null;
            this.mtdGetData();
        },

        mtdSelectDoc: function () {
            if (this.chkDoc) {
                this.chkPatiente.document = this.sale.patient.document;
                this.chkPatiente.fullName = this.sale.patient.name;
            } else {
                this.chkPatiente.document = "";
                this.chkPatiente.fullName = "";
            }

        },
        activarTipo: function (value) {
            this.bottonActiveT = value;
            this.payment.document = value;
            if (value == "BOLETA" || value == "FACTURA") {
                this.disabledChkDoc = false;
            } else {
                this.disabledChkDoc = true;
            }
            this.chkDoc = true;
            this.mtdSelectDoc();
        },

        /** set periodo alta */
        mtdSetPeriodo: function (item, pos) {
            this.modalFechas.item = item;
            this.modalFechas.boo_modal = true;
            this.modalFechas.date_ind = item.date_ind;
            this.modalFechas.date_end = item.date_out;
            this.datePos = pos;
        },
        mtdCloseModalPeriodo: function () {
            this.datePos = 0;
            this.modalFechas = {
                title: "Periodo del servicio",
                item: [],
                boo_modal: false,
                date_ind: null,
                date_end: null,
            };
        },
        mtdEditValor: function (ind, end) {
            this.post({
                    url: this.$store.getters.get__url + "/cxc/update/periodoalta",
                    token: this.$store.getters.get__token,
                    params: {
                        date_ind: ind,
                        date_end: end,
                        sale: this.modalFechas.item.id,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        /** todo correcto **/
                        this.$refs.SweetAlert.showSuccess("Fecha registrada");
                        this.dataShow[this.datePos].date_ind = ind;
                        this.dataShow[this.datePos].date_out = end;
                        this.mtdCloseModalPeriodo();
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        /** agregar servicios */
        mtdAddService: function (item) {
            this.carrito.type_patient = item.type_patient;
            this.carrito.saleId = item.id;
            this.carrito.amount = [item.total, item.amount, item.pending];
            this.carrito.insurance = item.insurance_id;
            this.carrito.payment_eps = item.payment_eps;
            this.view = "carrito";
        },
        mtdBack: function () {
            this.carrito.type_patient = "";
            this.carrito.data = [];
            this.carrito.saleId = null;
            this.carrito.amount = [0, 0, 0];
            this.carrito.payment_eps = null
            this.view = "data";
        },
        mtdSendCarrito: function (carrito) {
            this.carrito.data = carrito;
            this.post({
                    url: this.$store.getters.get__url + "/cxc/add/carrito",
                    token: this.$store.getters.get__token,
                    params: this.carrito,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdBack();
                        this.mtdGetData();
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto",
                            text: "Error!",
                            icon: "error",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        /** para canjear documento */
        mtdToChange: function (item) {
            if (item.total > 0) {
                this.modalCanje.total = item.total;
                this.modalCanje.item = item.voucher;
                this.modalCanje.type = item.type;
                this.modalCanje.patient = item.patient;
                this.modalCanje.boo_modal = true;
                this.modalCanje.pending = item.pending;
                this.modalCanje.bead = this.bead;
                this.modalCanje.sale = item;
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "No hay liquidacion del servicio",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        },
        mtdCloseModalCanje: function () {
            this.modalCanje = {
                title: "Canjear documento",
                item: [],
                boo_modal: false,
                pending: null,
                bead: null,
                method: null,
                sustento: null,
                type: null,
                patient: null,
                sale: null,
                amount: null,
            };
        },
        mtdCommitCanjear: function (method, sustento, type, typePago, client, amount) {
            let sus = sustento;
            this.modalCanje.sustento = sustento;
            this.modalCanje.method = method;
            this.modalCanje.amount = amount;
            if (type == "garantia") {
                this.post({
                        url: this.$store.getters.get__url + "/cxc/canje/garantia",
                        token: this.$store.getters.get__token,
                        params: this.modalCanje,
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            if (response.data != null) {
                                let res = response.data.sunat;
                                window.open(res.json.enlace_del_pdf_ticket, "_blank");
                                if (this.modalCanje.pending < 0) {
                                    this.mtdSendNota(res.idVoucherAplicaNota);
                                }
                            }
                            this.mtdCloseModalCanje();
                            this.mtdGetData();
                            Swal.fire({
                                title: "Registro Exitoso",
                                text: "Perfecto!",
                                icon: "success",
                                width: "400px",
                                confirmButtonColor: "rgb(170, 2, 95)",
                            });
                        } else {
                            Swal.fire({
                                title: "Registro Incorrecto",
                                text: "Error!",
                                icon: "error",
                                width: "400px",
                                confirmButtonColor: "rgb(170, 2, 95)",
                            });
                        }
                    })
                    .catch((errors) => {
                        Swal.fire({
                            title: "Registro Incorrecto",
                            text: errors,
                            icon: "error",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    });
            } else {

                this.post({
                        url: this.$store.getters.get__url + "/cxc/canje/credito",
                        token: this.$store.getters.get__token,
                        params: {
                            sale: this.modalCanje.sale.id,
                            typePago: typePago,
                            client: client,
                            method: method,
                            bead: this.modalCanje.bead.id,
                            amount: amount,
                        },
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            if (response.data != null) {
                                let res = response.data.sunat;
                                window.open(res.json.enlace_del_pdf_ticket, "_blank");
                            }
                            this.mtdCloseModalCanje();
                            this.mtdGetData();
                            Swal.fire({
                                title: "Registro Exitoso",
                                text: "Perfecto!",
                                icon: "success",
                                width: "400px",
                                confirmButtonColor: "rgb(170, 2, 95)",
                            });
                        } else {
                            Swal.fire({
                                title: "Registro Incorrecto",
                                text: "Error!",
                                icon: "error",
                                width: "400px",
                                confirmButtonColor: "rgb(170, 2, 95)",
                            });
                        }
                    })
                    .catch((errors) => {
                        Swal.fire({
                            title: "Registro Incorrecto",
                            text: errors,
                            icon: "error",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    });
            }
        },
        mtdSendNota: function (voucher) {
            this.get({
                    url: this.$store.getters.get__url + "/send/nota/" + voucher,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {

                    if (response.statusCode == 200) {
                        if (response.json != null) {
                            window.open(response.json.enlace_del_pdf_ticket, "_blank");
                        }
                        Swal.fire({
                            title: "Nota de credito Generada",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto",
                            text: "Error!",
                            icon: "error",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Registro Incorrecto",
                        text: errors,
                        icon: "error",
                        width: "400px",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },
        /** reportes */
        viewReportes: function (name) {
            this.view = name;
        },
        /** editar  carrito */
        mtdEditCarrito: function (item, pos) {
            this.modalEdit.item = item.detail;
            this.modalEdit.boo_modal = true;
            this.modalEdit.pos = pos;
        },
        mtdCloseModalEdiatCarrito: function () {
            this.modalEdit = {
                title: "Editar Liquidación",
                item: [],
                boo_modal: false,
                pos: null,
            };
        },
        mtdCommitDelCarrito: function (detId) {
            Swal.fire({
                title: "¿Estás seguro que deseas eliminar este item?",
                // text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Eliminar",
                width: "400px",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.mtdCommitDel(detId);
                }
            });
        },

        mtdCommitDel: function (detId) {
            this.get({
                    url: this.$store.getters.get__url + "/cxc/deletecarrito/" + detId,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        let temp = [];
                        let del = {};
                        this.modalEdit.item.forEach((element) => {
                            if (element.id != detId) {
                                temp.push(element);
                            } else {
                                del = element;
                            }
                        });
                        this.modalEdit.item = temp;
                        this.dataShow[this.modalEdit.pos].detail = temp;
                        this.dataShow[this.modalEdit.pos].total =
                            parseFloat(this.dataShow[this.modalEdit.pos].total).toFixed(2) -
                            parseFloat(del.quantity * del.price).toFixed(2);
                        this.dataShow[this.modalEdit.pos].pending =
                            parseFloat(this.dataShow[this.modalEdit.pos].pending).toFixed(2) -
                            parseFloat(del.quantity * del.price).toFixed(2);
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "",
                            icon: "error",
                            // width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Error",
                        text: errors,
                        icon: "error",
                        // width: "400px",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },
        /** editar cantidad */
        mtdCommitEditQuantity: function (pos, quantity) {

            this.get({
                    url: this.$store.getters.get__url +
                        "/cxc/editquantity/" +
                        this.modalEdit.item[pos].id +
                        "/" +
                        quantity,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        let oldCantidad = parseFloat(this.modalEdit.item[pos].quantity);
                        this.modalEdit.item[pos].quantity = quantity;
                        let dif =
                            parseFloat(this.modalEdit.item[pos].price) *
                            (parseFloat(quantity) - parseFloat(oldCantidad));
                        let newTotal =
                            parseFloat(this.dataShow[this.modalEdit.pos].total) + parseFloat(dif);
                        let newPending =
                            parseFloat(this.dataShow[this.modalEdit.pos].pending) + parseFloat(dif);
                        this.dataShow[this.modalEdit.pos].total = parseFloat(newTotal).toFixed(2);
                        this.dataShow[this.modalEdit.pos].pending = parseFloat(newPending).toFixed(2);
                        Swal.fire({
                            title: "Correcto!!",
                            text: "Editado correctamente",
                            icon: "success",
                            // width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "",
                            icon: "error",
                            // width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Error",
                        text: errors,
                        icon: "error",
                        // width: "400px",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },
        /** editar precio*/
        mtdCommitEditPrice: function (pos, newPrice) {
            this.get({
                    url: this.$store.getters.get__url +
                        "/cxc/editprice/" +
                        this.modalEdit.item[pos].id +
                        "/" +
                        newPrice,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        let oldCantidad = parseFloat(this.modalEdit.item[pos].quantity);
                        let oldPrice = parseFloat(this.modalEdit.item[pos].price);
                        this.modalEdit.item[pos].price = newPrice;
                        let dif = parseFloat(newPrice) - oldPrice;
                        let totaldif = dif * oldCantidad;
                        let newTotal =
                            parseFloat(this.dataShow[this.modalEdit.pos].total) + parseFloat(totaldif);
                        let newPending =
                            parseFloat(this.dataShow[this.modalEdit.pos].pending) +
                            parseFloat(totaldif);
                        this.dataShow[this.modalEdit.pos].total = parseFloat(newTotal).toFixed(2);
                        this.dataShow[this.modalEdit.pos].pending = parseFloat(newPending).toFixed(2);
                        Swal.fire({
                            title: "Correcto!!",
                            text: "Editado correctamente",
                            icon: "success",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Error",
                        text: errors,
                        icon: "error",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },

        /** pdf de liquidación */
        mtdViewPdfLiquidacion: function (item) {
            if (item.liquidacion != null && item.liquidacion.length > 0) {
                window.open(
                    this.$store.getters.get__url + "/cxc/viewpdf/gastos/" + item.id,
                    "_blank"
                );
            } else {
                this.modalPpdfLiqui.boo_modal = true;
                this.modalPpdfLiqui.doctors = this.doctors;
                this.modalPpdfLiqui.sale = item.id;
                this.$refs.SweetAlert.showWarning("Faltan Datos");

            }
        },
        closeModalPdfLiquidacion: function () {
            this.modalPpdfLiqui = {
                title: "Datos para el pdf de liquidación",
                boo_modal: false,
                doctors: [{
                    name: "",
                    id: "",
                }, ],
                sale: null,
            };
        },
        mtdSendDataPdfLiquidacion: function (payload) {
            payload.sale = this.modalPpdfLiqui.sale;
            this.post({
                    url: this.$store.getters.get__url + "/cxc/pdfliqui",
                    token: this.$store.getters.get__token,
                    params: payload,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        window.open(
                            this.$store.getters.get__url +
                            "/cxc/viewpdf/gastos/" +
                            response.data.sale_id,
                            "_blank"
                        );
                    } else {
                        this.$refs.SweetAlert.showWarning("Error al generar pdf");
                    }
                    this.closeModalPdfLiquidacion();
                    this.mtdGetData();
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
        /** fin */
        //metodo obtener data: aun no se usa
        mtdGetDataCharge: function () {
            this.post({
                    url: this.$store.getters.get__url + "/cxc/charge",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataCharges = response.data;
                })
                .catch((errors) => {});
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>
