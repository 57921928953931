<template>
  <div class="home" id="div_home" v-if="rol != null">
    <div class="" v-if="campus != null">
      <div v-if="rol == 1" class="">
        <GerenciaComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol == 2" class="">
        <AdmisionComponet :rolname="rolname" @mtdBackCampus="mtdBackCampus"/>
      </div>
      <div v-else-if="rol == 4" class="">
        <MaintenanceComponent @mtdBackCampus="mtdBackCampus" />
      </div>
      <div v-else-if="rol == 5" class="">
        <LogisticaComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus" />
      </div>
      <div v-else-if="rol == 6 || rol == 7" class="">
        <AsistencialComponent :rolname="rolname" :dataRoles="dataRoles" />
      </div>
      <div v-else-if="rol == 8" class="">
        <AdministracionComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles" />
      </div>
      <div v-else-if="rol == 9" class="">
        <JefeAdmisionComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus" />
      </div>
      <div v-else-if="rol == 10" class="">
        <FarmaciaComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus"/>
      </div>
      <div v-else-if="rol == 11" class="">
        <RhComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus"/>
      </div>
      <div v-else-if="rol == 12" class="">
        <TesoreriaComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus"/>
      </div>
      <div v-else-if="rol == 13" class="">
        <FacturacionComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus"/>
      </div>
      <div v-else-if="rol == 14" class="">
        <DirectorComponent :rolname="rolname" @mtdBackCampus="mtdBackCampus" />
      </div>
    </div>
    <div class="" v-else>
      <CampusComponent
        :dataRoles="dataRoles"
        :dataCampus="dataCampus"
        @mtdSelectCampus="mtdSelectCampus"
        @mtdBackRol="mtdBackRol"
      />
    </div>
  </div>
  <div class="" v-else>
    <RolesVue :dataRoles="dataRoles" @mtdSelectRol="mtdSelectRol" v-if="page != 'tareas_asignadas'"/>
    <FooterVue  @mtdChangePage="mtdChangePage" :pageView="page" @mtdBackCampus="mtdBackCampus" />
    <TareasVue v-if="page == 'tareas_asignadas'"  @mtdChangePage="mtdChangePage"  />
  </div>
</template>

<script>
import CampusComponent from "./Campus/index.vue";
import RolesVue from "./Rol/index.vue";
import GerenciaComponent from './Gerencia/GerenciaView.vue';
import MaintenanceComponent from "./Maintenance/MaintenanceView.vue";
import AsistencialComponent from './Asistencial/AsistencialView.vue';
import LogisticaComponent from './Logistica/LogisticaView.vue';
import FarmaciaComponent from './Farmacia/FarmaciaView.vue';
import JefeAdmisionComponent from "./JefeAdmision/JefeAdmisionView.vue";
import AdmisionComponet from './Admision/AdmisionView.vue'
import TesoreriaComponent from "./Tesoreria/TesoreriaView.vue";
import AdministracionComponent from "./Administracion/AdministracionView.vue"
import FacturacionComponent from "./Facturacion/FacturacionView.vue";
import DirectorComponent from "./Director/DirectorView.vue";
import FooterVue from "@/views/FooterView.vue";
import TareasVue from "../components/Tareas/Tareas.vue";
import RhComponent from "./Rh/RecursosHView.vue"; 
import { mapActions } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    CampusComponent,
    RolesVue,
    GerenciaComponent,
    MaintenanceComponent,
    AsistencialComponent,
    LogisticaComponent,
    FarmaciaComponent,
    JefeAdmisionComponent,
    AdmisionComponet,
    TesoreriaComponent,
    AdministracionComponent,
    FacturacionComponent,
    DirectorComponent,
    FooterVue,
    TareasVue,
    RhComponent
  },
  data () {
    return {
      page: "home",
      rol: null,
      rolname: null,
      dataCampus: [],
      campus: null,
      dataRoles: [],
    }
  },
  created () {
    const user = this.$store.getters.get__user

    if (user.roles.length == 1) {
      this.rol = user.roles[0].role_id;
      this.rolname = user.roles[0].role.name;
    } else {
      this.dataRoles = user.roles;
      this.rolname = '';
    }
    this.dataCampus = this.$store.getters.get__datacampus;
    this.mtdgetcheck();
  },
  methods: {
    ...mapActions(['get', 'post']),

    mtdSelectCampus: function (id) {
      this.campus = id;
      this.$store.commit("mt_set_campus", id);
    },
    mtdSelectRol: function (id, name_rol) {
      this.rol = id;
      this.rolname = name_rol;
      this.mtdgetcheck();
    },
    mtdBackRol: function () {
      this.rol = null;
    },
    mtdBackCampus: function () {
      this.campus = null;
      this.$store.commit("mt_set_campus", null);
    },
    mtdgetcheck: function () {
      this.get({
          url: this.$store.getters.get__url + "/Authentication/check",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.check = response.data.check;
          if(this.check){
            if(this.rol == 1){
              this.$router.push('/gerencia')
            }else
            if(this.rol == 2){
              this.$router.push('/admision')
            }else
            if(this.rol == 4){
              this.$router.push('/maintenance')
            }else
            if(this.rol == 5){
              this.$router.push('/logistica')
            }else
            if(this.rol == 6){
              this.$router.push('/medico')
            }else
            if(this.rol == 7){
              this.$router.push('/enfermera')
            }else
            if(this.rol == 8){
              this.$router.push('/administracion')
            }else
            if(this.rol == 9){
              this.$router.push('/jefatura')
            }else
            if(this.rol == 10){
              this.$router.push('/farmacia')
            }else
            if(this.rol == 11){
              this.$router.push('/rh')
            }else
            if(this.rol == 12){
              this.$router.push('/tesoreria')
            }else
            if(this.rol == 13){
              this.$router.push('/facturacion')
            }else
            if(this.rol == 14){
              this.$router.push('/director')
            }else{
              this.$router.push('/home')
            }
          }else{
            this.$router.push('/login')
          }
        })
    },
    mtdChangePage: function (page) {
      this.page = page;
    },
  }
}
</script>