var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container text-center"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"btn-home col-md-12 mt-3 mb-1 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-outline-main",attrs:{"type":"button"},on:{"click":_vm.returnHome}},[_c('i',{staticClass:"fas fa-home-lg-alt"}),_vm._v(" Inicio ")])])]),_c('ul',{staticClass:"nav pb-0",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{class:{
            'item-button': true,
            'nav-link': true,
            active: _vm.page === 'egreso',
          },attrs:{"id":"home-tab","type":"button"},on:{"click":function($event){return _vm.changePage('egreso')}}},[_vm._v(" Egreso ")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{class:{
            'item-button': true,
            'nav-link': true,
            active: _vm.page === 'recibo',
          },attrs:{"id":"profile-tab","type":"button"},on:{"click":function($event){return _vm.changePage('recibo')}}},[_vm._v(" R. por Honorarios ")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('button',{class:{
            'item-button': true,
            'nav-link': true,
            active: _vm.page === 'pasaje',
          },attrs:{"id":"profile-tab","type":"button"},on:{"click":function($event){return _vm.changePage('pasaje')}}},[_vm._v(" Pasajes ")])])]),_c('div',{staticClass:"tab-content mb-4",attrs:{"id":"myTabContentEgreso"}},[(_vm.page == 'egreso')?_c('Egreso',{attrs:{"bead":_vm.bead}}):_vm._e(),(_vm.page == 'recibo')?_c('Recibo',{attrs:{"bead":_vm.bead}}):_vm._e(),(_vm.page == 'pasaje')?_c('Pasaje',{attrs:{"bead":_vm.bead}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }