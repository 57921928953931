<template>
    <div class="container pt-3 px-xl-5 px-mb-2">
        <div class="row">
            <!--Columna 1-->
            <div class="col-xl-4 col-md-12">
                <!--fila 1-->
                <div>
                    <div class="row div-title fw-bold py-1 justify-content-center">
                        <span>DATOS CLÍNICOS</span>
                    </div>
                    <div class="row py-2">
                        <div class="d-flex flex-column flex-sm-row pb-2">
                            <label class="text-content w-100 text-start mb-2 mb-sm-0">Tiempo de enfermedad</label>
                            <input type="text" class="form-control form-control-input" />
                        </div>
                        <div class="d-flex flex-column flex-sm-row">
                            <label class="text-content w-100 text-start mb-2 mb-sm-0">Motivo de hospitalizacion</label>
                            <input type="text" class="form-control form-control-input" />
                        </div>
                    </div>
                    <div class="row text-content text-start">
                        <span>Diagnostico</span>
                    </div>
                    <div class="row pt-1 mb-3">
                        <div class="input-group">
                            <textarea class="form-control text-content" rows="2"></textarea>
                        </div>
                    </div>
                </div>
                <!--fila 2-->
                <div>
                    <div class="row div-title fw-bold py-1 justify-content-center">
                        <span>TRATAMIENTO</span>
                    </div>
                    <div class="row py-2">
                        <div class="d-flex flex-column flex-sm-row pb-2">
                            <label class="text-content w-100 text-start mb-2 mb-sm-0">Tipo de tratamiento</label>
                            <input type="text" class="form-control form-control-input" />
                        </div>
                        <div class="d-flex flex-column flex-sm-row">
                            <label class="text-content w-100 text-start mb-2 mb-sm-0">Motivo de hospitalizacion</label>
                            <input type="text" class="form-control form-control-input" />
                        </div>
                    </div>
                    <div>
                        <div class="row text-content text-start">
                            <span>Procedimiento</span>
                        </div>
                        <div class="row pt-1 mb-3">
                            <div class="input-group">
                                <textarea class="form-control text-content" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row text-content text-start">
                            <span>Otras cirugias a realizar</span>
                        </div>
                        <div class="row pt-1 mb-3">
                            <div class="input-group">
                                <textarea class="form-control text-content" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row text-content text-start">
                            <span>Procedimiento</span>
                        </div>
                        <div class="row pt-2 mb-3">
                            <div class="input-group">
                                <textarea class="form-control text-content" rows="1"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-1"></div>
            <!--Columna 3-->
            <div class="col-xl-7 col-md-12">
                <!--Parte 1-->
                <div class="mb-2">
                    <div class="row div-title fw-bold py-1 justify-content-center">
                        <span>REQUERIMIENTO QUIRÚRGICO</span>
                    </div>
                    <div class="row">
                        <div class="col-xl-5 col-md-12">
                            <div v-for="(item, index) in checklist" :key="index">
                                <div class="form-check form-check-reverse">
                                    <input class="form-check-input align-middle" type="checkbox" value=""
                                        :id="'defaultCheck' + index" />
                                    <div class="w-75 text-start">
                                        <label class="form-check-label text-content" :for="'defaultCheck' + index">
                                            {{ item }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-column flex-sm-row pt-1">
                                    <label class="text-content w-100 text-start mb-sm-0">Fecha Sugerida de Reserva</label>
                                    <input type="date" class="form-control form-control-input w-75" />
                            </div>
                            <div class="d-flex flex-column flex-sm-row pt-1">
                                    <label class="text-content w-100 text-start mb-sm-0">Hora Sugerida de Reserva</label>
                                    <input type="text" class="form-control form-control-input w-75" />
                            </div>
                            <div class="d-flex flex-column flex-sm-row pt-1">
                                    <label class="text-content w-100 text-start mb-sm-0">Duracion en minutos</label>
                                    <input type="number" class="form-control form-control-input w-75" />
                                </div>
                        </div>
                        <div class="col-xl-7 col-md-12">
                            <div v-for="(item, index) in checklist1" :key="index">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-check form-check-reverse">
                                            <input class="form-check-input align-middle" type="checkbox" value=""
                                                :id="'defaultCheck1' + index" />
                                            <div class="w-100 text-start">
                                                <label class="form-check-label text-content" :for="'defaultCheck1' + index">
                                                    {{ item }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 ps-xl-0 pt-1">
                                        <div class="d-flex flex-column flex-sm-row">
                                            <input type="text" class="form-control form-control-input"
                                                :for="'defaultCheck1' + index" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" v-for="(item, field) in formFields" :key="field">
                                    <div class="d-flex flex-column flex-sm-row pt-1">
                                        <label class="text-content w-100 text-start mb-sm-0">{{item.label}}</label>
                                        <input type="text" class="form-control form-control-input" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="d-flex flex-column flex-sm-row pt-1">
                                    <label class="text-content w-100 text-start mb-sm-0">Requerimiento de Sangre</label>
                                    <input type="text" class="form-control form-control-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Parte 2-->
                <div class="mb-2">
                    <div class="row div-title fw-bold py-1 justify-content-center">
                        <span>OTROS REQUERIMIENTOS</span>
                    </div>
                    <div class="row pt-2 mb-3">
                        <div class="input-group">
                            <textarea class="form-control text-content" rows="4"></textarea>
                        </div>
                    </div>
                </div>
                <!--Parte 3-->
                <div class="row border mt-2">
                    <div class="col-xl-2 col-md-4 div-title fw-bold d-flex align-items-center">
                        <span>MÉDICO GENERAL</span>
                    </div>
                    <div class="col-xl-5 col-md-8 py-2">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                    <div class="col-xl-1 col-md-4 div-title fw-bold d-flex align-items-center">
                        <span>CMP</span>
                    </div>
                    <div class="col-xl-4 col-md-8 py-2">
                        <input type="text" class="form-control form-control-input" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "c-asistencial-sop-historia-ordendesop",
    data() {
        return {
            checklist: [
                "Sala de Operaciones",
                "Sala de Procedimientos",
                "Material Osteosintesis",
                "Recuperacion",
                "Uci",
                "Analisis Pre Operatorios",
                "Riesgo quirurgico",
            ],
            checklist1: [
                "Cirujano",
                "1er ayudante",
                "2do ayudante",
                "Anestesiologo",
                "1er instrumentista",
                "2do instrumentista",
            ],
            formFields: [
                { label: "Anestesia", field: "anestesia" },
                { label: "Patología", field: "patologia" },
                { label: "Lateralidad", field: "lateralidad" },
                { label: "Prioridad", field: "prioridad" },
            ],
        };
    },
    methods: {
        ...mapActions(["get", "post"]),
    },
};
</script>
