<template>
<div>
    <div class="row mt-2 px-3">
        <div class="col-md-12">
            <div class="row d-flex">
                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-12 col-xs-12 px-1 mb-2">
                    <div class="input-group input-group">
                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                        <input v-model="search" @keyup.enter="mtdGetData" @input="mtdGetData" type="text" class="form-control form-control" placeholder="Buscar" />
                    </div>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 btn-home mb-2">
                    <button type="button" class="btn btn-bm-noradius w-100" @click="mtdShowModal(0,null)">
                        <i class="fas fa-plus"></i> Nuevo
                    </button>
                </div>
                <div class="col-xl-1 col-lg-2 col-md-3 col-sm-6 col-xs-6 btn-home">
                    <button type="button" class="btn btn-bm-noradius w-100" @click="mtdGetData(0)">
                        Ver todo
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-2 px-0" id="content-timelinetable">
            <table id="tb-cxc" class="table table-bordered border-main">
                <caption class="py-0 my-0"></caption>
                <thead class="bg-main">
                    <tr class=" table-th text-white text-center text-xs">
                        <th class="table-th-number">N°</th>
                        <th class="table-th-name">NOMBRE</th>
                        <th>RUC</th>
                        <th>DIRECCIÓN</th>
                        <th>TELEFONO</th>
                        <th>CORREO</th>
                        <th>REPRESENTANTE</th>
                        <th>ESTADO</th>
                        <th class="table-th-number">ACCIONES</th>
                    </tr>
                </thead>
                <tbody class="border-main">
                    <tr class="table-body text-center text-xs" v-if="cpData.length === 0">
                        <td colspan="9" class="text-center">No hay registros</td>
                    </tr>
                    <tr class="table-body text-center text-xs" v-for="(item, index) in cpData" :key="index">
                        <td>
                            {{mtdNumber(index) }}
                        </td>
                        <td>{{ item.name ? item.name : '-'  }}</td>
                        <td>{{ item.ruc }}</td>
                        <td>{{ item.address }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.vendor }}</td>
                        <td>
                            <div id="state_speciality" v-if="item.state == 1">
                                <div class="bg-success text-white">Activo</div>
                            </div>
                            <div id="state_speciality" v-else>
                                <div class="bg-secondary text-white">Inactivo</div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="p-0 d-flex px-2 justify-content-arround">
                                <a @click="mtdShowModal(1,item)" class="a-success px-1" title="Editar">
                                    <i class="fas fa-edit"></i>
                                </a>
                                <a @click="mtdBeforeDelete(item.id)" class="a-danger px-1" title="Eliminar">
                                    <i class="fas fa-trash-alt"></i>
                                </a>
                                <a @click="mtdModalCost(item)" class="a-warning px-1" title="Central de costos">
                                    <i class="fas fa-chart-pie"></i>
                                </a>
                                <a @click="openmodalProducto(item.id)" title="Ver Medicamentos" class="btn-Medicamento">
                                    <i class="fas fa-prescription-bottle"></i>
                                </a>
                                <a @click="openmodal(item.id)" title="Ver Servicios" class="a-primary px-1">
                                    <i class="fas fa-tag"></i>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                <nav aria-label="Page navigation example">
                    <ul class="pagination mb-1">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
    <cModalProveedorVue :num="modal.num" :page="modal.page" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="addDatadetail" />
    <cModalServicioProviderVue @mtdaddservicios="mtdaddservicios" :provider_id="provider_id" :title="modalService.title" :boo_modal="modalService.modal_form" @closeModalService="closeModalService" />
    <cModalProductoProviderVue @mtdaddproductos="mtdaddproductos" :proveedor_id="proveedor_id" :title="modalProducto.title" :boo_modal="modalProducto.modal_form" @closeModalProducto="closeModalProducto" />
    <cModalProviderCostVue :dataCentral="dataCentral" :userId="modalCost.userId" :payload="modalCost.payload" :title="modalCost.title" :boo_modal="modalCost.modal_form" @closeModalCost="closeModalCost" @mtdGetData="mtdGetData" />
</div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalServicioProviderVue from '../../modals/Mantenimiento/cModalServicioProvider.vue';
import cModalProductoProviderVue from '../../modals/Mantenimiento/cModalProductoProvider.vue';
import cModalProviderCostVue from '../../modals/Mantenimiento/cModalProviderCost.vue';
import cModalProveedorVue from '@/components/Maintenance/Modals/cModalProveedor.vue';

import {
    mapActions
} from "vuex";
export default {
    name: "logis-maintenance-provider",
    components: {
        SweetAlert,
        cModalServicioProviderVue,
        cModalProductoProviderVue,
        cModalProviderCostVue,
        cModalProveedorVue,
    },
    data() {
        return {
            swal: null,
            dataProvider: [],
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            estado: "activo",
            search: "",
            provider: {
                id: "",
                name: "",
                ruc: "",
                address: "",
                state: "",
                type: 1,
                vendor: "",
                phone: "",
                email: ""
            },
            posEdit: null,
            noRecordsMessage: "",
            checkbox: [],
            checkbox1: [],
            provider_id: null,
            modal: {
                title: '',
                modal_form: false,
                payload: {},
                num: 0,
                page: ""
            },
            modalService: {
                title: '',
                modal_form: false
            },
            modalProducto: {
                title: '',
                modal_form: false
            },
            modalCost: {
                title: '',
                modal_form: false,
                userId: null,
                payload: [],
            },
            dataCentral: [],
            /* paginacion */
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 3,
            proveedor_id: null
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        //metodo obtener data
        mtdGetData: function (op) {
            if (op == 0) {
                this.get({
                        url: this.$store.getters.get__url + "/logistics/maintenance/provider",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataProvider = response.data;
                        this.calculateData(10);
                    })
                    .catch((errors) => {});
            } else {
                if (this.search.length > 4) {
                    this.get({
                            url: this.$store.getters.get__url + "/logistics/maintenance/provider/" + this.search,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {
                            this.dataProvider = response.data;
                            this.selectPage = 1;
                            this.calculateData(10);
                        })
                        .catch((errors) => {});
                }
            }
        },
        //data show
        calculateData(items) {
            if (!this.dataProvider || !this.dataProvider.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataProvider;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataProvider.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },
        //modal Nuevo o editar
        mtdShowModal: function (opt, item) {
            let title = opt == 1 ? 'EDITAR PROVEEDOR' : 'REGISTRAR PROVEEDOR';
            if(item){
                if(item.account_number == null){
                item.account_number = [];
            }
            }
            this.modal = {
                title: title,
                modal_form: true,
                payload: item,
                page: "Logistica",
                num: opt,
            };
        },
        closeModal: function () {
            this.modal = {
                title: "",
                modal_form: false,
                payload: {},
                num: 0,
            };
        },
        addDatadetail: function (payload_detail, num_detail) {
            payload_detail.page = this.modal.page;
            if (num_detail == 0) {
                payload_detail.action = 'c';
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
            }
            this.post({
                    url: this.$store.getters.get__url + "/Provider/crudProvider",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        if (this.search.length > 0) {
                            this.mtdGetData();
                        } else {
                            this.mtdGetData(0);
                        }
                    }

                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },
        //eliminar proveedor
        mtdBeforeDelete: function (id) {
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
                .then((result) => {
                    if (result.value) {
                        this.mtdDeleteData(id);
                    }
                });
        },
        mtdDeleteData: function (id) {
            this.post({
                    url: this.$store.getters.get__url + "/Provider/crudProvider",
                    token: this.$store.getters.get__token,
                    params: {
                        id: id
                    },
                })
                .then((response) => {
                    // Si la eliminación fue exitosa, mostrar una alerta de éxito.
                    this.$refs.SweetAlert.showSuccess(response.message);
                    if (this.search.length > 0) {
                        this.mtdGetData();
                    } else {
                        this.mtdGetData(0);
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError('Error');

                });
        },
        //Modal de servicios
        openmodal: function (payload) {
            this.modalService = {
                title: 'Listado de Servicios',
                modal_form: true,
            };
            this.provider_id = payload
        },
        closeModalService: function () {
            this.modalService = {
                title: '',
                modal_form: false,
            }
            this.provider_id = null

        },
        mtdaddservicios: function (services) {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/addServiceProvider",
                    token: this.$store.getters.get__token,
                    params: services,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdGetData();
                    this.closeModalService()

                })
                .catch(error => {
                    console.log(error);
                })

        },
        /* Productos */
        openmodalProducto: function (payload) {
            this.modalProducto = {
                title: 'Listado de Productos',
                modal_form: true
            }
            this.proveedor_id = payload
        },
        closeModalProducto: function () {
            this.modalProducto = {
                title: '',
                modal_form: false
            }
        },
        mtdaddproductos: function (productos) {

            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/addProductProvider",
                    token: this.$store.getters.get__token,
                    params: productos,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdGetData();
                    this.closeModalProducto()

                })
                .catch(error => {
                    console.log(error);
                })
        },
        //Central de costos
        mtdGetDataCost: function () {
            this.get({
                    url: this.$store.getters.get__url + "/logistics/maintenance/provider/centralCost/index",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataCentral = response.data;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdModalCost: function (payload) {
            this.modalCost.title = 'Central de Costo';
            this.mtdGetDataCost();
            payload.central_has.forEach(element => {
                this.modalCost.payload.push(element.central.id);
            });
            this.modalCost.userId = payload.id;
            this.modalCost.modal_form = true;
        },

        closeModalCost: function (op) {
            this.modalCost = {
                title: '',
                modal_form: false,
                userId: null,
                payload: [],
                page: "",
            };
            if(op == 0){
                if (this.search.length > 4){
                    this.mtdGetData();
                }else{
                    this.mtdGetData(0);
                }
            }
        },
        /* Paginacion */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
    }
}
</script>
