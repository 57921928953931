<template>
<div>
    <div id="div-modal-anular" class="d-flex justify-content-center">
        <div id="div-anular" class="card w-100">
            <div class="card-body w-100">
                <div class="row">
                    <div class="col-12">
                        <div class="row d-flex justify-content-center px-1">
                            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12 py-2 mt-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-4">Desde: </span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdShowData()" />
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-xs-12 py-2 mt-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-4">Hasta</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" @change="mtdShowData()" />
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 mt-2">
                                    <div class="row p-2">
                                        <div class="col-12">
                                            <div class="row d-flex card-filter">
                                                <div class="col-lg-3 col-md-3 col-sm-12 bg-secondary-subtle">
                                                    <span>Estado Pago:</span>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-12 d-flex align-items-center">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox11" value="0" v-model="filter.statePage" @change="mtdShowData" />
                                                        <label class="form-check-label" for="inlineCheckbox11">Sin Pagar</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox11" value="2" v-model="filter.statePage" @change="mtdShowData" />
                                                        <label class="form-check-label" for="inlineCheckbox11">Pagado parcialmente</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox12" value="1" v-model="filter.statePage" @change="mtdShowData" />
                                                        <label class="form-check-label" for="inlineCheckbox12">Pagado</label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                            </div>
                            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div class="row p-2">
                                        <div class="col-12">
                                            <div class="row d-flex card-filter">
                                                <div class="col-lg-3 col-md-3 col-sm-12 bg-secondary-subtle">
                                                    <span>Despachos:</span>
                                                </div>
                                                <div class="col-lg-9 col-md-9 col-sm-12 d-flex align-items-center">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox11" value="1" v-model="filter.state" @change="mtdShowData" />
                                                        <label class="form-check-label" for="inlineCheckbox11">Sin despachar</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox12" value="2" v-model="filter.state" @change="mtdShowData" />
                                                        <label class="form-check-label" for="inlineCheckbox12">Parcialmente</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineCheckbox13" value="3" v-model="filter.state" @change="mtdShowData" />
                                                        <label class="form-check-label" for="inlineCheckbox13">Completo</label>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row d-flex">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-2">
                                <!--Paginado-->
                                <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--tabla-->
                    <div class="col-md-12 table-container mt-2 table-responsive" id="content-timelinetable">
                        <table class="table table-bordered text-xs">
                            <caption class="py-0 my-0"></caption>
                            <thead class="bg-main ">
                                <tr class="text-white text-center tr-table text-xs">
                                    <th scope="col" class="border border-white">
                                        <div>N°</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>SERIE-NUMERO</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>ORDEN DE COMPRA</div>
                                    </th>
                                    <th scope="col " class="border border-white">
                                        <div>FECHA DE {{ type == 'oc' ? 'OC':'OS' }}</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>PROVEEDOR</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>TIPO</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>FECHA DE REGISTRO</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>FECHA DE VENCIMIENTO</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>MONEDA</div>
                                    </th>
                                    <th scope="col" class="border border-white">
                                        <div>TOTAL</div>
                                    </th>
                                    <th class="border border-white">
                                        <div>ESTADO PAGO</div>
                                    </th>
                                    <th class="border border-white">
                                        <div>ESTADO DESPACHOS</div>
                                    </th>
                                    <th scope="col" class="border border-white" v-if="type == 'oc'">
                                        <div>DESPACHOS</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="border-0 text-xs" v-for="(item, index) in cpData" :key="index" :class="getRowClass(item)">
                                    <td class="border">
                                        <div>{{ mtdNumber(index) }}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{item.number}}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{ mtdGenerateCode(item.purcharseorder.id) }}</div>
                                    </td>
                                    <td class="border">
                                        <div> {{ item.purcharseorder.date | formatDate }}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{item.provider.name}}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{ item.type == 1 ? 'FACTURA' : (item.type == 2 && type == 'oc' ? 'BOLETA' : 'RECIBO H.') }}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{item.date | formatDate}}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{item.due_date | formatDate}}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{item.money}}</div>
                                    </td>
                                    <td class="border">
                                        <div>{{item.total}}</div>
                                    </td>
                                    <td class="border">
                                        <div class="text-center">
                                            <span v-if="item.state == 0" class="badge text-bg-danger text-xxs">Sin pagar</span>
                                            <span v-if="item.state == 1 || item.state == 3" class="badge text-bg-success text-xxs">Pagado</span>
                                            <span v-if="item.state == 2" class="badge text-bg-warning text-xxs">Pagado Parcialmente</span>
                                        </div>
                                    </td>
                                    <td class="border">
                                        <div class="text-center">
                                            <span v-if="item.dispatchState == 3" class="badge text-bg-success text-xxs">Completo</span>
                                            <span v-if="item.dispatchState == 1" class="badge text-bg-danger text-xxs">Sin despachar</span>
                                            <span v-if="item.dispatchState == 2" class="badge text-bg-warning text-xxs">Parcialmente</span>
                                        </div>
                                    </td>
                                    <td class="border">
                                        <div class="text-center my-2">
                                            <a class="view-dispatch px-2 py-2" href="#" @click="mtdGetDispatches(item)">
                                                <i class="fas fa-dolly text-white"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="cpData.length == 0" class="border">
                                    <td colspan="16" class="fw-bold text-center">No hay Facturas registradas en estas fechas</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalViewDispatch :title="modalViewDispatch.title" :dataDispatches="dataDispatches" :boo_modal="modalViewDispatch.modal_form" @mtdCloseModalViewDispatches="mtdCloseModalViewDispatches" />
</div>
</template>

<script>
import moment from "moment";
import {
    mapActions
} from "vuex";
import cModalViewDispatch from "@/components/Logistica/modals/Oc/cModalViewDispatch.vue";
export default {
    name: "c-logistica-modals-report",
    data() {
        return {
            filter: {
                begindate: "",
                enddate: "",
                statePage: ["0","1","2"],
                state: ["1", "2","3"],
            },
            search: '',
            data: [],
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 5,
            modalViewDispatch: {
                title: "",
                modal_form: false,
            },
            dataDispatches: []
        };
    },
    components: {
        cModalViewDispatch,
    },
    props: {
        type: String,
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    watch: {
        type: {
            handler(newVal) {
                this.getFirstDayOfMonth();
                // Realiza cualquier transformación necesaria en los datos aquí
                this.mtdShowData();
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        },
        selectPage() {
            this.calculateData(10);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getRowClass(item) {
            return {
                'table-success': item.purcharseorder.state === 0,
            };
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        mtdShowData: function () {
            if (this.type !== '') {
                this.post({
                    url: this.$store.getters.get__url + "/logistics/" + this.type + "/report/bills",
                    token: this.$store.getters.get__token,
                    params: this.filter

                }).then((response) => {
                    this.data = response.data;
                    this.calculateData(10);
                });
            }

        },

        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = lastDayOfMonth.toISOString().split('T')[0];
        },
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                const changepage = this.selectPage;
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let number = element.number.toString().toUpperCase();
                    return number.includes(query);
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdGenerateCode(item) {
            if (0 < item && item < 10) {
                return this.type.toUpperCase() + "-000" + item;
            }

            if (9 < item && item < 100) {
                return this.type.toUpperCase() + "-00" + item;
            }
            if (99 < item && item < 1000) {
                return this.type.toUpperCase() + "-0" + item;
            }
            if (999 < item && item < 10000) {
                return this.type.toUpperCase() + "-" + item;
            }
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        mtdGetDispatches: function (item) {
           
            const type = item.number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
            const title = "DESPACHOS DE LA " + type + " " + item.number;
            this.get({
                    url: this.$store.getters.get__url +
                        "/logistics/oc/getDispatches/" +
                        item.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataDispatches = response.data
                    if (this.dataDispatches.length > 0) {
                        this.modalViewDispatch = {
                            title: title,
                            modal_form: true,
                        };
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "No hay despachos para mostrar",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                })
                .catch((errors) => {});
        },
        mtdCloseModalViewDispatches: function () {
            this.modalViewDispatch = {
                title: '',
                modal_form: false,
            };
            this.dataDispatches = [];
        },

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },

};
</script>
