<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">

    <div class="row">
        <div v-for="(item, index) in dataCentral" :key="index" class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
            <div class="checkbox-grid">
                <label class="checkbox-label">
                    <input type="checkbox" :value="item.id" class="form-check-input" v-model="data_detail.checkbox" />
                    <span class="ms-2">{{ item.name }}</span>
                </label>
            </div>
        </div>
    </div>

    <template #footer>
        <button class="btn btn-success btn-sm" @click="mtdAddRolCommit" :disabled="cpButton">
            Editar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            data_detail: {
                checkbox: [],
                userId: null,
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                //if(this.num == 1){
                if (newVal) {
                    this.data_detail = {
                        checkbox: newVal
                    };
                    //}
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        userId: Number,
        payload: {
            type: Array,
            default: null
        },
        dataCentral: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
            return this.data_detail.checkbox.length === 0;
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose(op) {
            this.data_detail.userId = null;
            this.data_detail.checkbox = [];
            this.$emit("closeModalCost",op);
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail)
            this.mtdClose();
        },

        mtdAddRolCommit: function () {
            this.data_detail.userId = this.userId;
            this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/provider/addCentral",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess("Editado Correctamente");
                        //this.$emit("mtdGetData");
                        this.mtdClose(0);
                    } else {
                        this.$refs.SweetAlert.showWarning("Ocurrió un error");
                    }
                })
                .catch((errors) => {
                    console.log(errors)
                });
        },
    },

};
</script>
