<template>
    <div>
        <div class="container">
            <div id="div-modal-anular " class="w-100">
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2 mt-3">
                            <div class="d-flex">
                            <div class="input-group input-group mb-3">
                                <span
                                class="input-group-text text-white"
                                style="background: #900052"
                                ><i class="fas fa-search"></i
                                ></span>
                                <input
                           
                              
                                type="text"
                                class="form-control form-control"
                                placeholder="Buscar"
                                />
                            </div>
                            <button
                                type="button"
                                class="btn btn-bm-noradius"
                                
                                data-bs-toggle="modal"
                                data-bs-target="#new"
                            >
                                <i class="fas fa-plus"></i> Nueva
                            </button>
                            </div>
                        </div>

                        <!--tabla-->
                        <div class="col-md-12" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-borderless">
                            <thead>
                                <tr 
                                align="center" 
                                class="text-white tr-table"
                                >
                                <th scope="col">
                                    <div>NOMBRE Y APELLIDO</div>
                                </th>
                                <th scope="col" class="table-th">
                                    <div>ESPECIALIDAD</div>
                                </th>
                                <th scope="col" class="table-th-state">
                                    <div>SUB. ESPECIALIDAD</div>
                                </th>
                                <th scope="col" class="table-th-state">
                                    <div>CMP</div>
                                </th>
                                <th scope="col" class="table-th-state">
                                    <div>RNE</div>
                                </th>
                                <th scope="col" colspan="1">
                                    <div>ACCIONES</div>
                                </th>
                                </tr>
                            </thead>
                            
                            <tbody >
                               <!-- <tr >
                                    <td class="text-center" colspan="4">No hay registros.</td>
                                </tr>-->
                                <tr 
                                class="tbody-table"
                                >
                                    <td scope="row">
                                        <div>Nombre y Apellido</div>
                                    </td>
                                    <td>
                                        <div>Especialidad</div>
                                    </td>
                                    <td >
                                        <div >Sub. Especialidad</div>
                                    </td>
                                    <td >
                                        <div >CMP</div>
                                    </td>
                                    <td >
                                        <div >RNE</div>
                                    </td>
                                    <td class="text-center">
                                        <div class="p-0 td-div-a">
                                        <a
                                            id="btn-edit"
                                            data-bs-toggle="modal"
                                            data-bs-target="#new"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </a>
                                        
                                        <a  id="btn-remove">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>
                                        </div>
                                    </td>   
                                </tr>
                                <tr >
                               <!--  <td class="text-center" colspan="5">
                                    No hay resultados en la busqueda
                                </td>-->
                                </tr>
                                
                            </tbody>
                            
                            </table>
                            
                        </div>
                        <!--tabla-->


                    </div>
                </div>
                
            </div>   
        </div>
    </div>
    
</template>
<script>
export default {
    
}
</script>
<style scoped>
  #btn-edit {
      color: #39b54a;
      font-size: 25px;
      margin: 0px 2px;
    }
    #btn-edit:hover {
      color: #217b2d;
    }
    #btn-remove {
      color: #ff0000;
      font-size: 25px;
      margin: 0px 2px;
    }
    #btn-remove:hover {
      color: #a30404;
    }
    .btn-bm-noradius {
      margin-left: 10px;
      width: 150px;
      height: 38px;
    }
    .btn-bm-noradius:hover {
      background: rgb(170, 2, 95);
      background-color: rgb(170, 2, 95);
      color: white;
    }
    .tr-table > th > div {
      background: #900052;
      border-radius: 7px;
      padding: 0px;
      margin: 4px;
      height: 45px;
      padding-top: 10px;
    }
    .tr-table > th {
      padding: 0px;
    }
    .tbody-table > td > div {
      background: #eaecee;
      border-radius: 15px;
      padding: 0px;
      margin: 4px;
      height: 45px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
    }
    .tbody-table > td {
      padding: 0px;
    }
    .tbody-table > td > div > div {
      border-radius: 10px;
    }
</style>