<template>
    <div>
        <div class="px-4 text-center">
            <div>
                <div class="d-flex title-option">
                    <div class="btn-home mt-3 mb-2 ms-auto">
                        <button type="button" class="btn btn-outline-main" @click="returnHome()">
                            <i class="fas fa-home-lg-alt"></i> Inicio
                        </button>
                    </div>
                </div>
                <div id="myTabContent" >
                    <div class="row d-flex mb-2 mx-0 py-2 px-1">
                        <div class="col-xl-6 col-lg-12">
                            <div class="row d-flex justify-content-start">
                                <div class="col-md-12 rounded-3 px-0 bg-blue my-2">
                                    <div class="row mx-0">
                                        <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                                            <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                                            <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-if="patient">{{ patient.patient_name }}</span>
                                            <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-else></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 px-0">
                                    <div class="mb-2">
                                        <table class="table table-bordered border-main mb-0 px-0">
                                            <caption></caption>
                                            <thead>
                                                <tr class="text-white border-white text-center bg-main text-xxs">
                                                    <th scope="col">N°</th>
                                                    <th scope="col">CONCEPTO</th>
                                                    <th scope="col">F. SOLICITUD</th>
                                                    <th scope="col">F. RESULTADO</th>
                                                    <th scope="col">MÉDICO SOLICITA</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="data.length >0" class="text-xxs">
                                                <tr v-for="(item , index) in data" :key="index">
                                                    <th scope="row" class="align-left"> {{index + 1 }}</th>
                                                    <td class="concept">
                                                        <div class="left"><span>{{item.product_name}}</span></div>
                                                        <div class="right" @click="showDetail(index)"><span><a><i class="color-main fas fa-solid fa-eye"></i></a></span>
                                                        </div>
                                                    </td>
                                                    <td>{{item.date_solicitud}}</td>
                                                    <td>{{item.date_result}}</td>
                                                    <td class="align-left">{{item.doctor_name}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else class="text-xxs">
                                                <tr >
                                                    <td class="text-center   " colspan="5">  <strong> No hay resultados </strong></td>
                                                  
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-12 px-0" v-if="isVisibleArray[pos] == true">
                            <div id="content-timelinetable">
                                <div class="bg-main">
                                    <div class="py-2 text-white">
                                        <h5>{{ data[pos].product_name }}</h5>
                                    </div>
                                </div>
                                <div id="div-graph" class="c-app align-items-center">
                                    
                                    <div class="d-grid gap-2 mx-auto text-center col-sm-12 col-xs-12">
                                   
                                        <iframe v-if="data[pos].file" :src="'https://clinicabm.v2.howertpi.com/assets/'+data[pos].file" width="100%" height="450px" title="Pdf"></iframe>
                                        <div v-else>No hay Resultados</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-1 p-2 mb-3">
                                <div id="content-timelinetable" class="px-2">
                                    <div id="div-graph" class="c-app align-items-center">
                                        <div class="d-grid p-2 col-md-12">
                                            <div class="form-group">
                                                <label class="label-modal w-100 text-start mb-2">Observaciones:</label>
                                                <textarea v-if="!data[pos].comment" rows="3" class="form-control" v-model="item.comment"></textarea>
                                                <textarea v-else rows="5" class="form-control" v-model="data[pos].comment" disabled></textarea>
                                            </div>
                                            <div class="row w-100">
                                                <div class="mt-2 col-12" v-if="!data[pos].comment">
                                                    <button class="btn btn-sm btn-success ml-auto" @click="mtdSave" :disabled="!data[pos].file">Guardar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <SweetAlert :swal="swal" ref="SweetAlert" />
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from 'vuex'
    import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
    export default {
        name: 'c-medico-laboratorio',
        components: {
            SweetAlert
        },
        data() {
            return {
                page: 'laboratorio',
                stateHistory: null,
                viewInput: null,
                isVisibleArray: [],
                isVisibleGraph: false,
                pos: -1,
                showGraph: false,
                data: [],
                swal: null,
                item: {
                    comment: null,
                    id: null,
    
                }
            }
        },
        props: {
            stateRolAdmin: Boolean,
            patient: {
                type: Object,
            }
        },
        mounted() {
    
            this.$set(this.isVisibleArray, 0, true);
        },
        watch: {
            patient: {
                handler(newVal) {
                    this.mtdGetData();
                    for (let i = 0; i < this.lengthArray; i++) {
                        let atributo = false;
                        i == 0 ? this.isVisibleArray.push(true) : this.isVisibleArray.push(atributo);
                    }
                },
                immediate: true
            }
        },
        computed: {
            lengthArray() {
                return this.data.length;
            }
        },
        methods: {
            ...mapActions(['get', 'post']),
           
            mtdGetData: function () {
                this.get({
                        url: this.$store.getters.get__url + "/Hospitalization/getPlanDetailPendients/" + this.patient.medical_act_id + "/2/1",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            mtdSave: function () {
                this.item.id = this.data[this.pos].id;
                this.item.case = 2;
                this.post({
                        url: this.$store.getters.get__url + "/Admision/editHistoryPlanDetail",
                        token: this.$store.getters.get__token,
                        params: this.item,
                    })
                    .then((response) => {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdGetData();
                        this.$set(this.isVisibleArray, this.pos, true);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            returnHome() {
                window.location.reload();
            },
            showDetail(pos) {
                this.$set(this.isVisibleArray, this.pos, false);
                this.pos = pos;
                this.$set(this.isVisibleArray, pos, true);
            }
    
        }
    }
    </script>
    