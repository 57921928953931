<template>
    <CModal
    @mtdclosemodal="mtd_close"
      :show ="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :fullScreen="true"
    >
        <div class="row d-flex">
            <div v-for="(item, index) in dataEquipe" :key="index" class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text col-10" id="inputGroup-sizing-sm">{{item.name.substring(0,80) }}</span>
                    <input v-on:keypress="isNumber($event)" style="text-align: right" type="text" class="form-control col-2" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" v-model="item.amount">
                </div>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-primary btn-sm" @click="mtd_commit" :disabled="false" :class="btnClasses">
                <i class="fas fa-save"></i>&nbsp;Guardar
            </button>
            &nbsp;
        </template>
        </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";

export default {
    name: "c-vigilancia-modal-note",
    components: {
        CModal,
    },
    data() {
        return {
        user: null,
        note: "",
        };
    },
    created() {
        this.user =
        this.$store.getters.get__user.name +
        " " +
        this.$store.getters.get__user.last_name;
    },
    props: {
        btn_cancel: Boolean,
        btn_ok: Boolean,
        title: String,
        boo_modal: Boolean,
        size: String,
        dataEquipe:Array
    },
    computed: {
        btnClasses() {
            return [`btn btn-${this.color || "primary"}`];
        },

        cp_button() {
            return 0;
        },
    },
    methods: {
        mtd_close() {
            this.$emit("close_modal_equipe");
        },
        mtd_commit() {
            this.$emit("mtd_action_equipe",this.dataEquipe);
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
};
</script>
  