<template>
<div>
    <div class="px-3 py-2">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="w-100">
            <div class="card w-100">
                <div class="card-header text-center bg-main text-white h4">
                    <strong>COMPRAS</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-xs-6 py-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-lg-4 col-md-3">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.date_start" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-xs-6 py-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-lg-4 col-md-3">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12 py-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input type="text" aria-label="shift_date" class="form-control form-control-sm" placeholder="Buscar por Razon Social / N° Factura" v-model="search" @input="calculateData(10)"/>
                                    </div>
                                </div>
                                <!-- <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">Año</span>
                                        <select id="campu_id" class="form-control form-control" v-model="filter.year" @change="mtdGetData">
                                            <option selected value="">Seleccione ...</option>
                                            <option v-for="(item, index) in year" :key="index" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!-- <div class="col-xl-8 col-lg-4 col-md-8 col-sm-12 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-6">Metodo de Pago</span>
                                        <v-select class="form-control form-control-sm" :items="year" :reduce="(value) => value" label="value" placeholder="-- Seleccione uno --" v-model="optionSelect" multiple></v-select>
                                    </div>
                                </div> -->

                            </div>
                        </div>
                        <div class="col-md-12 py-2">
                            <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-end  mb-2 ml-5">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!--tabla-->
                        <div class="col-md-12 py-2 table-container table-responsive" id="content-timelinetable">
                            <table class="table table-borderless " style="font-size: 12px;">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main ">
                                    <tr class="text-white text-center tr-table text-xs">
                                        <th scope="col" class="border border-white">
                                            <div>N°</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>FECHA EMISION</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>FECHA VEC.</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>N°FACTURA</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>RUC</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>RAZON SOCIAL/NOMBRE</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>TOTAL</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>METODO DE PAGO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>DETRACCION</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>MONEDA</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in cpData" :key="index" class="border-table">
                                        <td>{{ mtdNumber(index) }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.due_date }}</td>
                                        <td>{{ item.number }}</td>
                                        <td>{{ item.provider.ruc }}</td>
                                        <td>{{ item.provider.name }}</td>
                                        <td>{{ item.total }}</td>
                                        <td>{{ item.method_pay.length == 0 ? 'Sin pagar' :item.method_pay.length > 1 ? 'Mixto' : item.method_pay[0].method_pay  }}</td>
                                        <td>{{ item.detraccion }}</td>
                                        <td>{{ item.money }}</td>
                                    </tr>
                                    <tr class="boder-table text-center" v-if="cpData.length == 0">
                                        <td colspan="10">No se encontraron resultados</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-pages-compras",
    data() {
        return {
            filter: {
                year: "2024",
                date_start: null,
                date_end: null
            },
            year: ['2021', '2022', '2023', '2024'],
            optionSelect: [],
            options: [{
                    value: 'opcion1',
                    text: 'Opción 1'
                },
                {
                    value: 'opcion2',
                    text: 'Opción 2'
                },
                {
                    value: 'opcion3',
                    text: 'Opción 3'
                },
            ],
            month: [],
            data: [],
            dataShow: [],
            search: "",
            /* paginacion */
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 3,

        }
    },
    created() {
        this.getFirstDayOfMonth();
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), 0, 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/compras/resumenCompras",
                    token: this.$store.getters.get__token,
                    params: this.filter

                })
                .then((response) => {
                    this.data = response.data;
                    this.month = response.meses;
                    this.calculateData(10);
                })
                .catch((errors) => {});
        },
        /** pagination */
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let number = element.number.toString().toUpperCase();
                    //let ruc = element.provider.ruc.toString().toUpperCase();
                    let razon_social = element.provider.name.toString().toUpperCase();
                    return number.includes(query) ||  razon_social.includes(query) ;
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        returnHome: function () {
            this.$emit("returnHome");
        },

    }
}
</script>

<style scoped>
li>a {
    font-size: 13px;
}

.border-table>td {
    border: 1px solid #900052;
}
</style>
