<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname" />
      </div>
      <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus = "mtdBackCampus"/>
        <PermisionsVue v-if="page == 'permiso_caja'" @returnHome="returnHome" />
        <AnulacionesVue v-if="page == 'permiso_anulacion'" @returnHome="returnHome" />
        <CajaFuerteVue v-if="page == 'per_caja_fuerte'" @returnHome="returnHome" />
        <LogisticaVue v-if="page == 'permiso_logistica'" @returnHome="returnHome" />
        <RepCajaVue v-if="page == 'reporte_caja'" @returnHome="returnHome" />
        <RepIngresoEgreso v-if="page == 'inreso_egreso'" @returnHome="returnHome" />
        <OtrosPermiso v-if="page == 'otro_permiso'" @returnHome="returnHome" />
      </div>
      <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" />
      </div>
    </div>
</template>
  
  <script>
  import { mapActions } from 'vuex'
  import Home from "@/components/Gerencia/Home.vue";
  import Nav from "../../components/Gerencia/Nav.vue";
  import Footer from "../../components/Gerencia/Footer.vue";
  import PermisionsVue from "@/components/Gerencia/pages/PermisionsView.vue";
  import AnulacionesVue from "@/components/Gerencia/pages/AnulacionesView.vue";
  import LogisticaVue from "@/components/Gerencia/pages/LogisticaView.vue";
  import RepCajaVue from "@/components/JefeAdmision/pages/ReporteCajaView.vue";
  import RepIngresoEgreso from "@/components/JefeAdmision/pages/EgresoIngreso.vue";
  import OtrosPermiso from "@/components/Gerencia/pages/OtrosPermisoView.vue";
  import CajaFuerteVue from '../../components/Gerencia/pages/PermisoCierreCaja.vue';
  
  export default {
    name: "GerenciaView",
    components: {
      Nav,
      Home,
      Footer,
      PermisionsVue,
      AnulacionesVue,
      RepCajaVue,
      RepIngresoEgreso,
      OtrosPermiso,
      LogisticaVue,
      CajaFuerteVue
    },
  
    data() {
      return {
        prefix: 'gerencia',
        page:'home',
        rol_name: null,
        stateRolAdmin: false,
      };
    },
    props: {
      rolname: String,
      dataRoles: Array,
      campu: Number,
    },
    created () {
      this.rol_name = this.rolname;
      this.mtdGetRolAdmin();
    },
    methods: {
      ...mapActions(['get', 'post']),
      mtdChangePage: function(page){
        this.page = page;
      },
      returnHome: function () {
        this.page = "home";
      },
      mtdGetRolAdmin:function(){
        this.dataRoles.forEach(element => {
          if(element.role_id==1){
            this.stateRolAdmin = true;
          }
        });
      },
      mtdBackCampus:function(){
        this.$emit('mtdBackCampus');
      }
    }
  };
</script>
