<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center header-container">
        <div style="font-size: 18px; font-weight: bold">
          {{ title }}: <span style="font-size: 14px">{{ item.name }}</span> (<span style="font-size: 14px">{{ item.total_quantity}}</span>)
        </div>
        <button @click="mtdClose" class="btn text-white close-button float-right ml-auto">
          <i class="fas fa-times" style="font-size: 12px"></i>
        </button>
      </div>
    </template>

    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="input-group input-group-sm mb-3">
            <span class="input-group-text col-md-4" id="document_type">CANTIDAD</span>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="Ingrese Cantidad"
              v-model="dataRe.frecuencia"
              @input="validateInput('frecuencia')"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <button class="btn btn-success btn-sm" @click="registrarReci">
        <i class="fas fa-save" />&nbsp; Agregar
      </button>
    </template>
    <cModalLoteVue
      ref="modalLoteComponent"
      :title="modalLote.title"
      :boo_modal="modalLote.boo_modal"
      :item="modalLote.item"
      :total="modalLote.total"
      :producto="modalLote.producto"
      :idProducto="modalLote.idProducto"
      :idRecipeDetail="modalLote.idRecipeDetail"
      :price="modalLote.price"
      :id="modalLote.id"
      :location="modalLote.location"

      :price_v="modalLote.price_v"
      :obs="modalLote.obs"
      :descuento="modalLote.descuento"
      :subtotal="modalLote.subtotal"
      :tipodescuento="modalLote.tipodescuento"
      :montodescuento="modalLote.montodescuento"
      @mtdCloseModalLote="mtdCloseModalLote"
      @datos-agregados="handleDatosAgregados"
    />
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import cModalLoteVue from "./cModalLoteVenta.vue";
import { mapActions } from "vuex";
export default {
  name: "c--modal-carroVenta",
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
  },
  components: {
    CModal,
    cModalLoteVue,
  },
  data() {
    return {
      tabla: [],
      dataRe: {
        frecuencia: null,
        //duracion: null,
        state: 1,
      },
      modalLote: {
        title: "Despachar",
        item: [],
        boo_modal: false,
        pos: null,
        total: null,
        producto: "",
        idProducto: null,
        idRecipeDetail: null,
        price: null,
        id: null,
        location: null,

        price_v: null,
        obs:"",
        descuento: "",
        subtotal: null,
        tipodescuento: "",
        montodescuento: "",
      },
    };
  },
  created() {
  },
  computed: {
    cpData() {
      return this.item.map((element) => element);
    },
    isDisabled() {
      if(this.item.productDetail && this.item.productDetail.presentacion.toUpperCase().includes('GOTA')){
        this.dataRe.frecuencia = 1
      }
    return (
      (this.item.productDetail && this.item.productDetail.presentacion.toUpperCase().includes('GOTA'))
    );
  }
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    mtdClose() {
      this.dataRe = {
        frecuencia: null,
        //duracion: null,
        state: 1,
      };
      this.$emit("mtdCloseModalDeta");
    },

    validateInputa(field) {
      if (this.dataRe[field] < 0) {
        this.dataRe[field] = 0;
      }
    },

    validateInput(field) {
      // Elimina los caracteres no numéricos del valor actual
      this.dataRe[field] = this.dataRe[field].replace(/[^0-9]/g, '');
    },

    registrarReci() {
      if (
        this.dataRe.frecuencia === null ||
        this.dataRe.frecuencia === "" ||
        this.dataRe.frecuencia <= 0 ||
        this.dataRe.frecuencia > this.item.total_quantity
      ) {
        if (this.dataRe.frecuencia > this.item.total_quantity) {
          Swal.fire({
            text: "La Cantidad ingresada es mayor que la cantidad total disponible",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
        } else {
          Swal.fire({
            text: "La Cantidad debe ser mayor que 0",
            icon: "warning",
            confirmButtonColor: "#900052",
          });

        }
        return;
      }

      const newItem = {
        ...this.item,
        ...this.dataRe,
      };

      //this.$emit("registrarReci", newItem);
      this.traerLote(newItem, newItem.id);
      //this.mtdClose();
    },

    traerLote(item, pos) {
      this.modalLote.boo_modal = true;
      this.modalLote.pos = pos;
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/stockProductId",
        params: { productdetail: item.product_details },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 0 && response.data.data.length > 0) {
            this.modalLote.item = response.data.data;
            this.modalLote.total = item.frecuencia && item.state === 1
                ? Math.ceil(item.frecuencia)
                : item.pending;
            this.modalLote.producto = item.name;
            this.modalLote.idProducto = item.product_details;
            //this.modalLote.idRecipeDetail = item.id;
            this.modalLote.price = item.precio_farmacia_particular;
            this.modalLote.id = item.id;
            this.modalLote.location = item.location;

            //Descuento
            this.modalLote.price_v= item.precio_farmacia_particular,
            this.modalLote.obs= "",
            this.modalLote.descuento= "0",
            this.modalLote.subtotal= item.precio_farmacia_particular*item.frecuencia,
            this.modalLote.tipodescuento= "porcentaje",
            this.modalLote.montodescuento= "0",

            this.$nextTick(() => {
              this.$refs.modalLoteComponent.validarAutomaticamente();
            });
          } else {
            this.modalLote.boo_modal = false;
            Swal.fire({
              text: "Sin Stocks",
              icon: "warning",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            title: "Error",
            text: errors,
            icon: "error",
            confirmButtonColor: "rgb(170, 2, 95)",
          });
        });
    },

    mtdCloseModalLote: function (item) {
      this.modalLote = {
        title: "Despachar",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    handleDatosAgregados(datos) {
        this.$emit("registrarReci", datos);
        this.mtdClose();
    },
  },
};
</script>
<style scoped>
#content-timeline {
  border: 1px solid #aaa;
  height: 100px;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#content-timeline::-webkit-scrollbar {
  -webkit-appearance: none;
}

#content-timeline::-webkit-scrollbar:vertical {
  width: 10px;
}

#content-timeline::-webkit-scrollbar-button:increment,
#content-timeline::-webkit-scrollbar-button {
  display: none;
}

#content-timeline::-webkit-scrollbar:horizontal {
  height: 10px;
}

#content-timeline::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#content-timeline::-webkit-scrollbar-track {
  border-radius: 10px;
}

.header-main {
  background-color: #900052;
}

@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}

.text-doctor {
  font-weight: bold;
  font-size: 14px;
  color: #900052;
}
.selected {
  background-color: yellow;
}

.table-th-state {
  width: 20% !important;
}

#tb-lote th {
  font-size: 9.5px;
}

#tb-lote td {
  font-size: 11px;
}

.error-message {
  color: red;
  font-size: 9px;
}

/* X a la derecha*/
.header-container {
  width: 100%;
  margin-right: 10px;
}

.close-button {
  border: none;
  background-color: transparent;
}

.modal-body {
  margin-right: 20px;
}

@media (max-width: 767px) {
  #table-body {
    font-size: 10px !important;
  }
}
</style>
