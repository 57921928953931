<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
      <div class="px-3">
          <div class="row d-flex mx-0 px-0 mb-1">
              <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                  <label for="name" class="form-label">Nombre</label>
                  <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder="Nombre">
              </div>
              <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                <label class="form-label">Prioridad</label>
                <div class="d-flex justify-content-center">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="priority"
                      id="priority1"
                      value="1"
                      v-model="data_detail.priority">
                    <label class="form-check-label" for="priority1">Alta</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="priority"
                      id="priority2"
                      value="2"
                      v-model="data_detail.priority">
                    <label class="form-check-label" for="priority2">Media</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="priority"
                      id="priority3"
                      value="3"
                      v-model="data_detail.priority">
                    <label class="form-check-label" for="priority3">Baja</label>
                  </div>
                </div>
              </div>
          </div>
          <div class="row d-flex mx-0 px-0">
              <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                  <label for="description" class="form-label">Descripción</label>
                  <textarea class="form-control form-control-sm" v-model="data_detail.description" placeholder="Descripción" rows="1"></textarea>
              </div>
              <div class="mb-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 px-1">
                <label for="name" class="form-label">Archivo (Opcional)</label>
                <div class="input-group input-group-sm">
                  <input
                    @change="getFile"
                    type="file"
                    class="form-control"
                    id="file"
                    lang="es"
                    name="file"
                    ref="fileInput"
                  />
                </div>
              </div>
          </div>
      </div>
      <template #footer>
        <fieldset class="btn-group" aria-labelledby="button-group-label">
          <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            <strong>{{ (num == 0) ? 'Registrar' : 'Guardar' }}</strong>
          </button>
        </fieldset>
      </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
  mapActions
} from "vuex";

export default {
  name: "c-maintenance-modal-documento",
  components: {
      CModal,
  },
  data() {
      return {
          data_detail: {
              name: '',
              priority: '',
              description: '',
              file: '',
          },
      };
  },
  watch: {
      payload: {
          handler(newVal) {
              if(this.num == 1){
                  if (newVal) {
                  this.data_detail = {
                      ...newVal
                  };
              }
              }
          },
          immediate: true,
      },
  },
  props: {
      title: String,
      boo_modal: Boolean,
      num: Number,
      payload: {
          type: Object,
          default: null
      },
  },
  computed: {
      cpButton() {
              return !(this.data_detail.name !='' && this.data_detail.priority !='' && this.data_detail.description !='');

      }
  },
  methods: {
      ...mapActions(["get", "post"]),
      mtdClose() {
          this.data_detail.name ='';
          this.data_detail.priority ='';
          this.data_detail.description ='';
          this.data_detail.file = '';
          let fileInput = this.$refs.fileInput;
          fileInput.value = '';
          this.$emit("closeModal");
      },

      getFile(e) {
        let fileInput = this.$refs.fileInput;
        let file = e.target.files[0];
        if (
          !/\.(pdf)$/i.test(file.name) &&
          !/\.(png)$/i.test(file.name) &&
          !/\.(jpg)$/i.test(file.name) &&  !/\.(jpeg)$/i.test(file.name)
        ) {
          Swal.fire({
            text: "Seleccione un archivo formato PDF, PNG O JPG",
            icon: "info",
            confirmButtonColor: "#900052",
          });
          fileInput.value = '';
        } else {
          this.data_detail.file = file;
        }
      },

      openArchivos() {
          this.$emit('openArchivos', this.idTask);
      },
      openAsignacion() {
          this.$emit('openAsignacion', this.idTask);
      },

      DataDetail() {
          this.$emit('Datadetail', this.data_detail, this.num);
          this.mtdClose();
      },
  },


};
</script>
