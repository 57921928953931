<template>
    <div>
        <div class="px-5 pt-2 pb-5">
            <div id="div-modal-cxc" class="d-flex justify-content-center mt-4">
                <div id="div-cxc" class="card w-100 text-center">
                    <div class="card-header text-white bg-main h4">
                    <div style="position: relative;">
                        <div class="">
                            <strong>PERMISOS DE CAJA</strong>
                        </div>
                        <div class="btn-home-jefatura" style="right: 0;top: 0;position: absolute;">
                            <button type="button" class="btn btn-sm" @click="returnHome">
                                <i class="fas fa-home-lg-alt"></i> Inicio
                            </button>
                        </div>
                    </div>
                </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 mt-2" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class=" text-center text-white border-white bg-main">
                                            <th scope="col">N°</th>
                                            <th scope="col">USUARIO SOLICITANTE</th>
                                            <th scope="col">CAJA</th>
                                            <th scope="col">TIPO</th>
                                            <th scope="col">MONTO</th>
                                            <th scope="col">ESTADO</th>
                                            <th scope="col">ACCIÓN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in cpData" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td class="text-center">
                                                {{ item.user.last_name }} {{ item.user.name }}
                                            </td>
                                            <td class="text-center">
                                                {{ item.caja }}
                                            </td>
                                            <td>
                                                {{
                                                    JSON.parse(item.data).type == 1
                                                        ? "DEPÓSITO"
                                                        : JSON.parse(item.data).type == 2
                                                            ? "RETIRO"
                                                            : JSON.parse(item.data).type == 3
                                                                ? "EGRESO"
                                                                : JSON.parse(item.data).type == 4
                                                                    ? "RECIBO"
                                                                    : "PLANILLA-PASAJES"
                                                }}
                                            </td>
                                            <td class=" text-end">
                                                {{
                                                    parseFloat(JSON.parse(item.data).amount).toFixed(2)
                                                }}
                                            </td>
                                            <td class="text-center">
                                                <span v-if="item.state == 3" class="badge text-bg-danger">Permiso
                                                    denegado</span>
                                                <span v-else-if="item.state == 2" class="badge text-bg-success">Permiso
                                                    otorgado</span>
                                                <span v-else class="badge text-bg-warning">Pendiente</span>
                                            </td>
                                            <td>
                                                <div class="text-center d-flex justify-content-center">
                                                    <button v-if="item.state == 1" @click="mtdPermission(item.id, JSON.parse(item.data).type)"
                                                    class="btn btn-sm btn-info text-white mx-1">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button v-if="JSON.parse(item.data).type == 3"
                                                    @click="mtdViewPdfAdjunto(JSON.parse(item.data))"
                                                    class="btn btn-sm btn-secondary text-white mx-1">
                                                    <i class="fas fa-folder-open"></i>
                                                </button>
                                                <button @click="mtdViewPdf(item)"
                                                    class="btn btn-sm btn-primary text-white mx-1">
                                                    <i class="fa fa-file-pdf"></i>
                                                </button>
                                                <button v-if="item.state !== 1" @click="
                                                    mtdChangeToPending(
                                                        item.id,
                                                        JSON.parse(item.data).type
                                                    )
                                                    " class="btn btn-sm btn-warning text-white">
                                                    <i class="fa fa-reply"></i>
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="cpData.length == 0">
                                            <td class="text-center" colspan="7">No hay permisos registrados
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center px-3 mt-3">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)"
                                                @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index"
                                            class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                            class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                            class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)"
                                                @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)"
                                                @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @mtdPermissionRollback="mtdPermissionRollback"
            @mtdPermissionCommit="mtdPermissionCommit" />
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: "c-jefatura-permisos",
    components: {
        SweetAlert,
    },
    data() {
        return {
            search: "",
            dataShow: [],
            data: [],
            totalPagesToShow: 10,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            swal: null,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url +
                    "/Permisions/permisoscaja/" +
                    this.$store.getters.get__campus,
                token: this.$store.getters.get__token,
                params: this.filter,
            })
                .then((response) => {
                    if (response.states == 1) {
                        this.$refs.SweetAlert.showInfo('No hay cajas aperturadas');  
                    } else {
                        this.data = response.data.data;
                        this.calculateData(10);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                length: totalPages,
            },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdPermission: function (id, type) {
            if (type == 3 || type == 4 || type == 5) {
                this.$refs.SweetAlert.showPermisions(id, type, 'textarea', true);
            } else {
                this.$refs.SweetAlert.showPermisions(id, type, false, false);
            }
        },
        mtdPermissionCommit: function (id, obs, type, state) {
            this.post({
                url: this.$store.getters.get__url + "/Permisions/commitpermision/" + id,
                token: this.$store.getters.get__token,
                params: {
                    obs: obs,
                    state: state,
                    type: type,
                },
            })
                .then((response) => {
                    if (response.statusCode == 200) {
                        if ((type == 3 || type == 4 || type == 5) && state == 2) {
                            window.open(
                                this.$store.getters.get__url +
                                "/Permisions/viewpdf/expense/" +
                                response.data.id,
                                "_blank"
                            );
                        }
                        this.$refs.SweetAlert.showSuccess(state == 2 ? "Permiso otorgado" : "Permiso denegado");

                        if (type == 5 && state == 2) {
                            const today = new Date();
                            const formattedDate = today.toISOString().split('T')[0];
                            window.open(
                                this.$store.getters.get__url + "/Jefatura/pasaje/"+formattedDate,
                                "_blank"
                            );
                        }
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showWarning("No se puedo registrar.");
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdPermissionRollback: function (id, obs, type, state) {
            this.post({
                url: this.$store.getters.get__url + "/Permisions/commitrollback/" + id,
                token: this.$store.getters.get__token,
                params: {
                    obs: obs,
                    state: state,
                    type: type,
                },
            })
                .then((response) => {
                    if (response.data.state == 0) {
                        this.$refs.SweetAlert.showSuccess(state == 2 ? "Permiso otorgado" : "Permiso denegado");
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showWarning("No se puedo registrar.");
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdViewPdfAdjunto: function (item) {
            this.get({
                url: this.$store.getters.get__url +
                    "/Permisions/getadjunto/expense/" +
                    item.expense,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    if (response.url != null && response.url.length > 0) {
                        window.open(
                            "https://clinicabm.v2.howertpi.com/assets/" + response.url,
                            "_blank"
                        );
                    } else {
                        this.$refs.SweetAlert.showWarning("No tiene documento adjunto.");
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdViewPdf: function (expense) {
            let item = JSON.parse(expense.data);
            if (item.type == 1) {
                window.open(
                    this.$store.getters.get__url + "/Permisions/viewpdf/ingreso/" + expense.id,
                    "_blank"
                );
            } else {
                window.open(
                    this.$store.getters.get__url + "/Permisions/viewpdf/expense/" + item.expense,
                    "_blank"
                );
            }
        },
        mtdChangeToPending: function (id, type) {
            this.$refs.SweetAlert.showUpdateState(id, type);
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    }
}
</script>